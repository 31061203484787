<template>
  <router-link
    class="header-menu"
    :class="{icon: type==='icon', text: type==='text'}"
    :to="{name: routeName}"
    @click.native.prevent="onClick">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    type: String,
    auth: {
      type: Boolean,
      default: false
    },
    routeName: String
  },
  computed: {
    loggedIn () {
      return this.$store.getters.getLoginState
    }
  },
  methods: {
    onClick () {
      if (!this.auth || this.loggedIn) {
        this.$router.push({name: this.routeName}).catch(err => err)
      } else {
        this.$router.push({name: 'login'})
      }
    }
  }
}
</script>

<style lang="less">
.header-menu {
  padding: 0 10px;
  text-align: center;
  color: #f2f4f5;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .12);
  font-size: 15px;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  display: inline-block;
  position: relative;
  transition: all .2s ease-in-out;
  &.text {
    white-space: nowrap;
    height: 43px;
    line-height: 43px;
    &:hover {
      background: #2277da;
      color: #fff;
      transition: all .2s ease-in-out;
    }
  }
  &.active {
    background: #2277da;
  }
}
.header-menus {
  &:hover {
    .sub-menus {
      top: 60px;
      display: block;
      transition: all .2s ease-in-out;
    }
  }
}
</style>
