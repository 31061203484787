<template>
  <modal v-model="showModal" title="选择Item" width="650" @on-ok="onConfirm" :styles="{top: '60px'}">
    <tree
      ref="tree"
      :title="title"
      :value="fieldNavs"
      :data="fieldList"
      :height="550"></tree>
  </modal>
</template>

<script>
import Modal from '../../../common/modal/modal.vue'
import Tree from '../field-nav/field-tree'

export default {
  components: { Modal, Tree },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    fieldNavs: {
      type: Array,
      default: function () {
        return []
      }
    },
    fieldList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      showModal: this.show
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    }
  },
  methods: {
    onConfirm () {
      this.$emit('on-confirm-ok', this.$refs.tree.getFieldNavs())
    }
  }
}
</script>

<style lang="css">
</style>
