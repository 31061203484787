<template>
  <div class="panel-field">
    <!-- <div class="map-edit-title">dataset 编辑</div> -->
    <y-form ref="itemForm" :model="item" :rules="itemRule" label-width="150px" item-width="80%">
      <div class="dataset-form-row-3">
        <form-item label="名称" prop="name" labelWidth="60px">
          <y-input v-model="item.name"></y-input>
        </form-item>
        <form-item label="类型" prop="type" labelWidth="60px">
          <y-input v-model="item.type"></y-input>
        </form-item>
        <form-item label="数据源" prop="datasourceId" labelWidth="60px">
          <y-select v-model="item.datasourceId" :data="datasourceList" clearable valueField="id" attrInValue @on-select-change="onDatasourceChange"></y-select>
        </form-item>
      </div>
      <form-item label="患者">
        <patient-select v-model="item.patientList"></patient-select>
      </form-item>
      <callFunc-list v-model="item.callFunc" label-width="120px" item-width="80%"
        @on-json-preview="onJsonPreview"
        @on-callfunc-add="onShowCallfuncAdd"></callFunc-list>
      <form-item label="rowToCol">
        <row2col v-model="item.rowToCol" :data="colList" @on-loadcollist="onLoadColList" @on-rol2col-json-preview="onRol2colJsonPreview"></row2col>
      </form-item>
      <div class="dataset-form-row-3">
        <form-item label="projectId" prop="projectId" labelWidth="70px" itemWidth="75%">
          <y-input v-model="item.projectId" disabled></y-input>
        </form-item>
        <form-item label="modelId" prop="modelId" labelWidth="70px" itemWidth="75%">
          <div style="display:flex;">
            <y-input v-model="item.modelId" style="margin-right:10px;" disabled></y-input>
            <y-button @click.native="onShowCrftableWin">选择</y-button>
            </div>
        </form-item>
        <form-item label="collection" prop="collection" labelWidth="75px"  itemWidth="70%">
          <y-input v-model="item.collection" disabled></y-input>
        </form-item>
      </div>
      <div class="dataset-form-row-2">
        <form-item label="reportDateField">
          <y-select v-model="item.reportDateField" :data="colList"></y-select>
        </form-item>
        <form-item label="dateFormat">
          <y-input v-model="item.dateFormat"></y-input>
        </form-item>
      </div>
      <form-item label="patientFieldMap">
        <keyval-select v-model="item.patientFieldMap" :datakey="patientColumns" :datavalue="colList"></keyval-select>
      </form-item>
      <form-item label="fieldMapping" prop="fieldMapping">
        <fieldmapping-list v-model="item.fieldMapping"
          @on-fieldmapping-delete="onFieldMappingDelete"
          @on-fieldmapping-edit="onFieldMappingEdit"
          @on-show-fieldmapping-addwin="onShowFieldMappingAddWin"
          @on-table-preview="onTablePreview"></fieldmapping-list>
      </form-item>
    </y-form>
    <div class="map-edit-save">
      <y-button type="primary" @click.native="onSaveClick">更新</y-button>
    </div>
    <field-tree-map-win ref="fieldTreeMap" :show="showFieldTreeMapWin" :title="currentCrfttableTitle" :modelId="currentModelId"
      @on-hide="showFieldTreeMapWin=false"
      @on-confirm-ok="onFieldMappingAdd"></field-tree-map-win>
    <crftable-select-win :modelId="currentModelId" :crftableList="crftableList" :show="showCrftableSelectWin" @on-hide="showCrftableSelectWin=false" @on-confirm-ok="onCrftableSelect"></crftable-select-win>
    <callfunc-add-win :callFnList="callFnList" :show="showCallFnAdd" @on-hide="showCallFnAdd=false" @on-confirm-ok="onCallFuncSelect"></callfunc-add-win>
    <fieldmapping-edit-win v-model="currentEditMapping" :show="showEditMappingWin" @on-hide="showEditMappingWin=false"></fieldmapping-edit-win>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import YButton from '../../common/button/button'
import Emitter from '../../common/mixins/emitter'
import CallFuncList from './callFunc-list.vue'
import PatientSelect from './patient-select.vue'
import Row2col from './row2col.vue'
import KeyvalSelect from './keyval-select.vue'
import FieldmappingList from './fieldmapping-list.vue'
import FieldTreeMapWin from '../win/field-tree-map-win.vue'
import CrftableSelectWin from '../win/crftable-select-win.vue'
import CallfuncAddWin from '../win/callfunc-add-win.vue'
import FieldmappingEditWin from '../win/fieldmapping-edit-win.vue'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: { YForm, FormItem, YSelect, YInput, YButton, CallFuncList, PatientSelect, Row2col, KeyvalSelect, FieldmappingList, FieldTreeMapWin, CrftableSelectWin, CallfuncAddWin, FieldmappingEditWin },
  mixins: [Emitter],
  props: {
    data: {
      type: Object,
      default () { return {} }
    },
    datasourceList: {
      type: Array,
      default () { return [] }
    },
    crftableList: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      showModal: this.show,
      item: {
        name: '',
        type: '',
        datasourceId: '',
        datasourceName: '',
        callFunc: [],
        rowToCol: {},
        projectId: '',
        modelId: '',
        collection: '',
        reportDateField: '',
        dateFormat: '',
        patientFieldMap: {},
        fieldMapping: []
      },
      sqlTypeList: ['mysql', 'sqlserver', 'mongodb', 'oracle'],
      itemRule: {
        name: [{required: true, message: '不能为空', trigger: 'blur'}],
        datasourceId: [{required: true, message: '不能为空', trigger: 'blur'}],
        projectId: [{required: true, message: '不能为空', trigger: 'blur'}],
        modelId: [{required: true, message: '不能为空', trigger: 'blur'}],
        collection: [{required: true, message: '不能为空', trigger: 'blur'}],
        fieldMapping: [{required: true, message: '不能为空', trigger: 'blur'}]
      },
      showCrftableSelectWin: false,
      currentCrfttableTitle: '',
      currentModelId: '',

      showFieldTreeMapWin: false,
      showCallFnAdd: false,

      colList: [],
      patientColumns: [],
      callFnList: [],
      currentEditMapping: {},
      showEditMappingWin: false
    }
  },
  watch: {
    data (val) {
      if (val && val.modelId !== this.currentModelId) {
        this.item = val
        // 设置初始的 modelId
        this.currentCrfttableTitle = val.name // name 是模型中的name, 和 crftable中的可能不一致
        this.currentModelId = val.modelId
        // this.loadColList() // 需要先 选患者否者没意义
        this.loadPatientColumns()
        this.loadCallFuncList()
      }
    }
  },
  methods: {
    onFieldMappingDelete (index) {
      if (index > -1) {
        this.item.fieldMapping.splice(index, 1)
      }
    },
    onFieldMappingEdit (fieldMappingItem) {
      this.currentEditMapping = fieldMappingItem
      this.showEditMappingWin = true
    },
    onFieldMappingAdd (item) {
      this.item.fieldMapping.push(item)
    },
    onShowFieldMappingAddWin () {
      this.showFieldTreeMapWin = true
    },
    onShowCallfuncAdd () {
      this.showCallFnAdd = true
    },
    onDatasourceChange (val) {
      if (val && val.name) {
        this.item.datasourceName = val.name
        this.loadCallFuncList()
      }
    },
    onShowCrftableWin () {
      this.showCrftableSelectWin = true
    },
    onCrftableSelect (val) {
      if (val.collection && val.modelId) {
        this.item.modelId = val.modelId
        this.item.collection = val.collection
        this.currentCrfttableTitle = val.title
        this.currentModelId = val.modelId
        this.$refs.fieldTreeMap.loadFieldList()
      }
    },
    onCallFuncSelect (val) {
      if (val) {
        this.item.callFunc.push(val)
      }
    },
    createColumns () {
      const cols = []
      cols.push({key: 'SampleTime', title: 'SampleTime', width: 150})
      cols.push({key: 'name', title: 'name', width: 150})
      cols.push({key: 'uniqueCode', title: 'uniqueCode', width: 150})
      // fieldmapping
      function hasColumn (key) {
        for (const col of cols) {
          if (col.key === key) {
            return true
          }
        }
        return false
      }
      if (Array.isArray(this.item.fieldMapping)) {
        this.item.fieldMapping.forEach(field => {
          // 判断 fieldName是否已经重复
          if (field.childField && !hasColumn(field.childField)) {
            cols.push({key: field.childField, title: field.title, width: 500})
          } else if (!hasColumn(field.fieldName)) {
            cols.push({key: field.fieldName, title: field.title, width: 150})
          }
        })
      }
      return cols
    },
    loadColList () {
      this.$http.post(`${this.httpRoot}/wsdl/preview/col/list`, {
        datasourceId: this.item.datasourceId,
        callFunc: this.item.callFunc,
        patientList: this.item.patientList
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.colList = res.colList || []
          if (!this.colList.length) {
            this.$Message.warning('colList: []')
          } else {
            this.$Message.success(`colList: [${res.colList.length}]`)
          }
        } else {
          this.$Modal.error({
            title: 'colList 加载报错',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadPatientColumns () {
      this.$http.get(`${this.httpRoot}/patient/import/columns`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.patientColumns = res.columns || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadCallFuncList () {
      this.$http.get(`${this.httpRoot}/wsdl/callfn/list`, authenticate({
        params: {datasourceId: this.item.datasourceId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.callFnList = res.callFnList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onSaveClick () {
      this.dispatch('DataExtract', 'on-dataset-save', JSON.parse(JSON.stringify(this.item)))
    },
    onJsonPreview (callFunc) {
      this.$emit('on-json-preview', this.item.datasourceId, callFunc, this.item.patientList)
    },
    onTablePreview () {
      const columns = this.createColumns()
      this.$emit('on-table-preview', this.item.datasourceId, this.item.callFunc, this.item.patientList, this.item.rowToCol, this.item.fieldMapping, columns)
    },
    onLoadColList () {
      this.loadColList()
    },
    onRol2colJsonPreview () {
      const callFunc = this.item.callFunc
      this.$emit('on-json-preview', this.item.datasourceId, callFunc, this.item.patientList, this.item.rowToCol)
    }
  }
}
</script>

<style lang="less">
.panel-field {
  padding-top: 20px;
}
.map-edit-save {
  margin-bottom: 30px;
}
.dataset-form-row-2 {
  display: flex;
  .form-item {
    width: 44.5%;
  }
}
.dataset-form-row-3 {
  display: flex;
  margin-left: 90px;
  .form-item {
    width: 32%;
  }
}
</style>
