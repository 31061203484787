<template>
  <table cellspacing="0" cellpadding="0" border="0" :style="styles">
    <colgroup>
      <col v-for="(column, index) in columns" :key="column.key" :width="setCellWidth(column, index, true)"/>
    </colgroup>
    <thead>
      <tr>
        <th v-for="column in columns" :key="column.key" :class="alignCls(column)">
          <div class="table-cell" :class="{'table-hidden': !fixed && column.fixed && (column.fixed === 'left' || column.fixed === 'right')}">
            <template v-if="showSelectAll && column.type === 'selection'">
              <checkbox v-model="isSelectAll" @change="onSelectAllChange"></checkbox>
            </template>
            <template v-if="column.editable">
              <span class="header-editor ionic-icon ionic-icon-ios-compose-outline" @click.stop="onEdit(column)"></span>
            </template>
            <template v-else>
              <span></span>
            </template>
          </div>
        </th>
      </tr>
    </thead>
  </table>
</template>

<script>
import Checkbox from './checkbox'
import styleMethods from './mixin'

export default {
  name: 'TableHeaderEditor',
  components: {Checkbox},
  mixins: [styleMethods],
  props: {
    styleObject: Object,
    columns: Array,
    columnsWidth: {},
    noborder: {
      type: Boolean,
      default: false
    },
    showSelectAll: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: [Boolean, String],
      default: false
    }
  },
  data () {
    return {
      isSelectAll: false,
      store: {}
    }
  },
  computed: {
    // set width of the table-header
    styles () {
      const style = Object.assign({}, this.styleObject)
      const width = this.$parent.bodyHeight === 0
        ? parseInt(this.styleObject.width)
        : this.$parent.bodyHeight < this.$parent.bodyRealHeight && !this.fixed
          ? parseInt(this.styleObject.width) + parseInt(this.$parent.scrollBarWidth)
          : parseInt(this.styleObject.width)
      style.width = `${width}px`
      return style
    }
  },
  created () {
    this.store = this.$parent.tableStore
  },
  methods: {
    updateSelectAll (isSelectAll) {
      this.isSelectAll = isSelectAll
    },
    onSelectAllChange () {
      this.$parent.selectAll(this.isSelectAll)
    },
    onEdit (column) {
      this.$emit('on-column-edit', column)
    }
  }
}
</script>

<style lang="less">
.table-header-edit {
  position: relative;
  .title {
    position: absolute;
    top: 0;
    left: 40px;
    font-size: 12px;
    background: transparent;
    padding: 0;
    height: 32px;
    line-height: 32px;
    max-width: 150px;
    overflow: hidden;
  }
}
.table-cell {
  .header-editor {
    display: inline-block;
    height: 31px;
    line-height: 31px;
    &.ionic-icon {
      font-size: 22px;
    }
    &:hover {
      color: #3a90e4;
      cursor: pointer;
    }
  }
}
</style>
