<template>
  <div class="root-container" :style="{width: width}">
    <collapse-panel :title="title" v-model="containers.expanded"
      v-show="containers.items && containers.items.length">
      <div class="samplebox-container__box">
        <div :class="[(item.exboxType==='samplebox' || item.exboxType==='cardbox')?'samplebox-container__item':'strawbox-container__item',
          {enable: item.enable, selected: item.selected}]"
          v-for="item in containers.items"
          :key="item.id"
          @click.stop="onClick(item)">
          <div v-if="item.exboxType==='samplebox'" class="container-img-fridge" :title="item.label"></div>
          <div v-if="item.exboxType==='cardbox'" class="container-img-card" :title="item.label"></div>
          <div v-else class="container-img-strawbox" :title="item.label">
            <template v-if="item.strawboxCount>0">
              <div class="strawbox-img" v-for="c in item.strawboxCount" :key="c"></div>
            </template>
            <template v-else>
              <div class="strawbox-img" v-for="c in 4" :key="c"></div>
            </template>
          </div>
        </div>
      </div>
    </collapse-panel>
  </div>
</template>

<script>
import CollapsePanel from '../transitions/collapse-panel'
export default {
  name: 'Samplebox',
  components: { CollapsePanel },
  props: {
    width: String,
    containers: {
      type: Object,
      default: function () {
        return {
          items: [],
          type: 'fridge',
          expanded: false,
          loaded: false
        }
      }
    }
  },
  data () {
    return {
      title: '样本盒'
    }
  },
  computed: {
    currentPath () {
      return this.$store.getters.getCurrentPath
    }
  },
  methods: {
    onClick (item) {
      if (this.currentPath.indexOf('sampleTransSelect') >= 0) return
      if (item.enable) {
        item.select(true)
        this.$emit('on-samplebox-selected', item)
      }
    }
  }
}
</script>

<style lang="less">
@import "../../../assets/ivu/mixins/clear";
.samplebox-container__box {
  display: flex;
  position: relative;
  flex-direction: row;
  // justify-content: space-around;
  flex-wrap: wrap;
  .samplebox-container__item {
    padding: 5px 10px;
    line-height: 15px;
    flex: 0 0 auto;
    &:after {
      .clearFloat();
    }
    &.selected {
      background: rgba(67, 186, 255, 0.3);
    }
    &.enable {
      .container-img-fridge {
        background: url(../../../assets/imgs/container/subpage-box-blue.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .container-img-card {
        background: url(../../../assets/imgs/container/cardbox.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      &:hover {
        background: rgba(67, 186, 255, 0.3);
        transition: all 0.3s;
      }
    }
    .container-img-fridge {
      height: 29px;
      width: 29px;
      float: left;
      background: url(../../../assets/imgs/container/subpage-box-grey.png) no-repeat;
      background-size: 100% 100%;
    }
    .container-img-card {
      height: 29px;
      width: 29px;
      float: left;
      background: url(../../../assets/imgs/container/cardbox.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  .strawbox-container__item {
    padding: 5px 10px;
    line-height: 15px;
    flex: 0 0 auto;
    &:after {
      .clearFloat();
    }
    &.selected {
      background: rgba(67, 186, 255, 0.3);
    }
    &.enable {
      .strawbox-img {
        background: url(../../../assets/imgs/container/complexbox-blue.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      &:hover {
        background: rgba(67, 186, 255, 0.3);
        transition: all 0.3s;
      }
    }
    .strawbox-img {
      height: 6px;
      width: 29px;
      background: url(../../../assets/imgs/container/complexbox-grey.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}

</style>
