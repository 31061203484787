<template>
  <div class="root-container" :style="{width: width}">
    <collapse-panel :title="title" v-model="containers.expanded"
      v-show="containers.items && containers.items.length">
      <div class="strawbox-container__box">
        <div class="strawbox-container__item"
          :class="{enable: item.enable, selected: item.selected}"
          v-for="item in containers.items"
          :key="item.id"
          @click.stop="onClick(item)">
          <div class="container-img-fridge" :title="item.label">
          </div>
        </div>
        <div class="disabled-mask" v-if="currentPath.indexOf('codeLocate') >= 0"></div>
      </div>
    </collapse-panel>
  </div>
</template>

<script>
import CollapsePanel from '../transitions/collapse-panel'
export default {
  name: 'Strawbox',
  components: { CollapsePanel },
  props: {
    width: String,
    containers: {
      type: Object,
      default: function () {
        return {
          items: [],
          type: 'fridge',
          expanded: false,
          loaded: false
        }
      }
    }
  },
  data () {
    return {
      title: '麦管样本盒'
    }
  },
  computed: {
    currentPath () {
      return this.$store.getters.getCurrentPath
    }
  },
  methods: {
    onClick (item) {
      if (this.currentPath.indexOf('sampleTransSelect') >= 0) return
      if (item.enable) {
        item.select(true)
        this.$emit('on-strawbox-selected', item)
      }
    }
  }
}
</script>

<style lang="less">
@import "../../../assets/ivu/mixins/clear";
.strawbox-container__box {
  display: flex;
  position: relative;
  flex-direction: row;
  // justify-content: space-around;
  flex-wrap: wrap;
  .strawbox-container__item {
    padding: 5px 10px;
    line-height: 15px;
    flex: 0 0 auto;
    &:after {
      .clearFloat();
    }
    &.selected {
      background: rgba(67, 186, 255, 0.3);
    }
    &.enable {
      .container-img-fridge {
        background: url(../../../assets/imgs/container/strawbox-blue.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      &:hover {
        background: rgba(67, 186, 255, 0.3);
        transition: all 0.3s;
      }
    }
  }
  .container-img-fridge {
    height: 29px;
    width: 29px;
    float: left;
    background: url(../../../assets/imgs/container/strawbox-grey.png) no-repeat;
    background-size: 100% 100%;
  }
}

</style>
