<template>
  <form :class="['form', `form-label-${labelPosition}`, {'form-inline': inline}]">
    <slot></slot>
  </form>
</template>

<script>
import { oneOf } from '../../../utils/assist'

export default {
  name: 'Form',
  props: {
    model: Object,
    rules: Object,
    labelWidth: [String, Number],
    itemWidth: [String, Number],
    labelPosition: {
      validator (value) {
        return oneOf(value, ['left', 'right', 'top'])
      },
      default: 'right'
    },
    inline: {
      type: Boolean,
      default: false
    },
    showMessage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      fields: [],
      isValid: true
    }
  },
  watch: {
    rules () {
      this.validate()
    }
  },
  created () {
    this.$on('on-form-item-add', (field) => {
      if (field) this.fields.push(field)
      return false
    })
    this.$on('on-form-item-remove', (field) => {
      if (field.prop) this.fields.splice(this.fields.indexOf(field), 1)
      return false
    })
  },
  methods: {
    resetFields () {
      this.fields.forEach(field => {
        field.resetField()
      })
    },
    validate (callback) {
      let valid = true
      let count = 0
      this.fields.forEach(field => {
        field.validate('', errors => {
          if (errors) {
            valid = false
          }
          if (typeof callback === 'function' && ++count === this.fields.length) {
            callback(valid)
          }
        })
      })
      this.isValid = valid
    },
    validateField (prop, cb) {
      const field = this.fields.filter(field => field.prop === prop)[0]
      if (!field) { throw new Error('[Form warn]: must call validateField with valid prop string!') }

      field.validate('', cb)
    }
  }
}
</script>
