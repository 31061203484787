import objectAssign from '../../../../utils/merge'
import Freezer from './freezer'
export default class ContainerStore {
  constructor (options, $container, loadFn) {
    this.$container = $container
    this.loadFn = loadFn
    this.loading = false
    this.data = [] // my loaded data from options
    this.parent = null // used for stop path collect
    this.containerPath = []
    this.containerMap = {}
    this.freezerContainer = {
      items: [], // {id, type, label, index, enable, capacity}: freezer
      expanded: false,
      loaded: false,
      type: ''
    }
    this.shelfContainer = {
      items: [], // {id, type, label, level, enable, capacity}: shelf
      expanded: false,
      loaded: false,
      type: ''
    }
    this.layerContainer = {
      items: [], // {id, type, label, layer, enable, capacity}: layer
      expanded: false,
      loaded: false,
      type: ''
    }
    this.sampleboxContainer = {
      items: [], // {id, type, label, index, enable, capacity}: samplebox
      expanded: false,
      loaded: false,
      type: ''
    }
    this.strawboxContainer = {
      items: [], // {id, type, label, layer, enable, capacity}: strawbox
      expanded: false,
      loaded: false,
      type: ''
    }

    for (let name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name]
      }
    }
    if (Array.isArray(this.data) && this.data.length) {
      this.setChildren(this.data)
      this.freezerContainer.loaded = true
    }
  }

  loadFreezerContainer (callback) {
    if (!this.freezerContainer.loaded && !this.loading) {
      this.loading = true
      const resolveSuccess = (items) => {
        this.loading = false
        this.setChildren(items)
        this.freezerContainer.loaded = true
        if (items && items.length && items[0].type) {
          this.freezerContainer.type = items[0].type
        }
        this.$container.$emit('on-freezer-load-success')
      }
      const resolveFaild = () => {
        this.loading = false
      }
      if (typeof this.loadFn === 'function') {
        this.loadFn.call(this.$container, this, resolveSuccess, resolveFaild, 'freezerContainer')
      }
    } else {
      this.$container.$emit('on-freezer-load-success')
    }
  }

  setChildren (items) {
    this.freezerContainer.items = items.map((item) => {
      return new Freezer(objectAssign(item, {store: this, parent: this}))
    })
    this.registContainer(this.freezerContainer.items)
    this.freezerContainer.loaded = true
  }

  updateSelectedFreezer (selectedFreezerId) {
    // 清除其它 freezer 选中状态
    this.freezerContainer.items.forEach((item) => {
      if (item.id !== selectedFreezerId) {
        item.select(false)
      }
    })
  }

  updateContainerPath (container) {
    let _container = container
    this.containerPath = []
    while (_container) {
      this.containerPath.unshift(_container)
      _container = _container.parent
    }
  }

  registContainer (items) {
    items.forEach((item) => {
      this.containerMap[item.id] = item
    })
  }

  selectChainContainersById (id) {
    const path = []
    let container = this.containerMap[id]
    while (container && !container.freezerContainer) {
      path.unshift(container)
      container = container.parent
    }
    path.forEach((item) => {
      item.select(true)
    })
  }

  selectById (id) {
    // let container = this.getCurrentContainerById(id)
    let container = this.getContainerById(id)
    if (container && container.enable) {
      container.select(true)
    }
  }

  reSelectById (id) {
    let container = this.getContainerById(id)
    if (container && container.enable) {
      container.select(true, true)
    }
  }

  reSelectChainContainersById (id) {
    let container = this.getContainerById(id)
    if (container && container.enable) {
      container.select(true, true)

      var pContainer = container.parent
      while (pContainer && pContainer._type) {
        if (pContainer._type === 'freezer') {
          this.shelfContainer.items = pContainer.shelfContainer || []
          this.shelfContainer.loaded = true
        } else if (pContainer._type === 'shelf') {
          this.layerContainer.items = pContainer.layerContainer || []
          this.layerContainer.loaded = true
        } else if (pContainer._type === 'layer') {
          this.sampleboxContainer.items = pContainer.sampleboxContainer || []
          this.sampleboxContainer.loaded = true
        } else if (pContainer._type === 'samplebox' && pContainer.exboxType === 'strawbox') {
          this.strawboxContainer.items = pContainer.strawboxContainer || []
          this.strawboxContainer.loaded = true
        }
        pContainer = pContainer.parent
      }
    }
  }

  getContainerById (id) {
    return this.containerMap[id]
  }

  getCurrentContainerById (id) {
    // const container = this.containerMap[id]
    // if (container) container.select(true)
    // 由于测试，containerMap 因重复 id 被覆盖，现从当前容器id中查找
    let container
    const findContainer = function (items) {
      const containers = items || []
      for (let i = 0, len = containers.length; i < len; i++) {
        if (containers[i].id === id) {
          return containers[i]
        }
      }
      return null
    }
    if (!container) container = findContainer(this.freezerContainer.items)
    if (!container) container = findContainer(this.shelfContainer.items)
    if (!container) container = findContainer(this.layerContainer.items)
    if (!container) container = findContainer(this.sampleboxContainer.items)
    if (!container) container = findContainer(this.strawboxContainer.items)
    return container
  }

  destroy () {
    this.$container = null
    this.loadFn = null
    this.loading = false
    this.data = []
    this.parent = null
    this.containerPath = []
    this.containerMap = {}
    this.freezerContainer = {}
    this.shelfContainer = {}
    this.layerContainer = {}
    this.sampleboxContainer = {}
    this.strawboxContainer = {}
  }
}
