<template>
  <modal v-model="showModal" title="添加 pickerData" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <div class="picker-col" v-for="(col, index) in data" :key="'col'+index">
      <div class="picker-row" v-for="(row, index) in col" :key="'row'+index">
        <span class="label">名称:</span><input class="input" v-model="row.name"/>
        <span class="label">值:</span><input class="input" v-model="row.value"/>
        <span class="label delete" @click.stop="deletePicker(row)">删除</span>
      </div>
    </div>
    <div class="picker-col new" v-for="(col, index) in data" :key="index">
      <div class="picker-row">
        <span class="add-btn" @click.stop="addPicker(index)">
          <span class="ionic-icon ionic-icon-plus">{{index + 1}}</span>
        </span>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
export default {
  components: {Modal},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default () {
        return [[]]
      }
    }
  },
  data () {
    return {
      showModal: this.show
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      if (!this.validate()) {
        this.$Message.warning('请完成数据填写')
        return false
      }
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.data)
    },
    validate () {
      let valid = true
      this.data.forEach(col => {
        if (Array.isArray(col)) {
          col.forEach(row => {
            if (!(row.name && row.value)) {
              valid = false
            }
          })
        }
      })
      return valid
    },
    deletePicker (row) {
      this.data.forEach(col => {
        if (Array.isArray(col)) {
          const index = col.indexOf(row)
          if (index > -1) {
            col.splice(index, 1)
          }
        }
      })
    },
    addPicker (index) {
      const row = {name: '', value: ''}
      this.data[index] = this.data[index] || []
      this.data[index].push(row)
    }
  }
}
</script>

<style lang="less">
.picker-row {
  .label {
    width: 15px;
    padding-right: 5px;
    &.delete {
      color: #999;
      cursor: pointer;
      &:hover {
        color: #3399ff;
      }
    }
  }
  .input {
    width: 35%;
    margin-right: 15px;
  }
  .add-btn {
    margin-left: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    border: 1px dotted #666;
    display: inline-block;
    width: 80%;
    text-align: center;
    &:hover {
      border-color: #3399ff;
      color: #3399ff;
    }
  }
}
.picker-col.new {
  margin-top: 10px;
}
</style>
