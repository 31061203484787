/*
清除缓存
*/

function updateStorageItem (key, item) {
  localStorage.setItem(key, JSON.stringify(item));
}

function getStorageItem (item='cache') {
  let cache = null;
  try {
    cache = JSON.parse(localStorage.getItem(item));
  } catch (e) {
    cache = null;
  }
  return cache
}

var formDisabled = {
  methods: {
    getDisabled () {
      let disabled = false;
      const auth = getStorageItem('auth');
      if (auth && auth.role && auth.role.indexOf('doctor') >= 0 &&
          this.role && this.role.indexOf('doctor') >= 0) {
        disabled = false;
      }
      return disabled
    }
  }
};

var isNumber = {
  methods: {
    isNumber (n) {
      return n !== '' && !isNaN(n) && isFinite(n)
    }
  }
};

function broadcast (componentName, eventName, ...params) {
  this.$children.forEach(child => {
    var childName = child.$options.name;

    if (childName === componentName) {
      setTimeout(() => {
        child.$emit(eventName, ...params);
      }, 0);
      // child.$emit.apply(child, [eventName, ...params])
    } else {
      broadcast.apply(child, [componentName, eventName, ...params]);
    }
  });
}
var Emitter = {
  methods: {
    dispatch (componentName, eventName, ...params) {
      var parent = this.$parent || this.$root;
      var parentName = parent.$options.name;

      while (parent && (!parentName || parentName !== componentName)) {
        parent = parent.$parent;

        if (parent) {
          parentName = parent.$options.name;
        }
      }
      if (parent) {
        setTimeout(() => {
          parent.$emit(eventName, ...params);
        }, 0);
        // parent.$emit.apply(parent, [eventName, ...params])
      }
    },
    broadcast (componentName, eventName, ...params) {
      broadcast.call(this, componentName, eventName, ...params);
    }
  }
};

//
var script = {
  name: 'QInput',
  mixins: [isNumber, formDisabled, Emitter],
  props: {
    value: {},
    item: Object,
    role: String,
    scoreIndex: String
  },
  data () {
    return {
      currentValue: this.getValue(this.value),
      styleObject: {},
      warn: false,
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
    },
    currentValue (val, oldVal) {
      this.$emit('on-change', this.getParsedVal(val), this.item.key);
      if (this.item.triggerArg) {
        this.dispatch('QuestItemInput', 'on-computed-trigger');
      }
      if (this.scoreIndex) {
        this.$emit('on-score-change', {value: this.getParsedVal(val), scoreIndex: this.scoreIndex}, this.item.key);
      }
      // 整数处理
      setTimeout(() => {
        // console.log(oldVal, val)
        if (this.item.valueType === 'integer' && this.isNumber(val) && String(val) !== String(oldVal)) {
          this.currentValue = parseInt(val) || 0;
        }
      }, 0);
    }
  },
  created () {
    if (this.item.triggerKeyList && this.item.triggerKeyList.length) {
      this.$on('on-computed-triggered', () => {
        const valList = [];
        const keyList = this.item.triggerKeyList;
        keyList.forEach(key => {
          let val = this.$parent.getValueByKey(key);
          valList.push(val);
        });
        let computedFn = (new Function(this.item.triggerComputed))();
        try {
          this.currentValue = computedFn(valList);
        } catch (error) {
          console.log('triggerComputed: ', error);
          this.currentValue = '';
        }
      });
    }
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      // Number(undefined) = NaN, Number(0.0) = 0, Number('') = 0, Number('abc') = NaN
      if (Number(data[this.item.key]) === 0) return data[this.item.key] // 解决值是零或空字符的情况
      return data[this.item.key] || ''
    },
    onKeyup () {
      if (this.$refs.textarea.clientHeight < this.$refs.textarea.scrollHeight) {
        this.styleObject = {
          height: `${this.$refs.textarea.scrollHeight}px`
        };
      }
    },
    onFocus () {
      this.warn = false;
    },
    getParsedVal (val) {
      if (this.item.valueType === 'integer' && this.isNumber(val)) {
        return parseInt(val) || 0
      } else if (this.item.valueType === 'number' && this.isNumber(val)) {
        return Number(val) || 0
      }
      return val
    },
    clearValid () {
      this.warn = false;
    },
    validate () {
      if (!this.item.required) return true
      if (!this.currentValue) {
        this.warn = true;
        return false
      }
      this.warn = false;
      return true
    }
  }
};

/* script */
            const __vue_script__ = script;
            
/* template */
var __vue_render__ = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer",
      class: { "input-inline": _vm.item.type === "input_inline" },
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      !(_vm.item.type === "input_inline")
        ? _c("span", { staticClass: "quest-label title" }, [
            _vm._v(_vm._s(_vm.item.label || _vm.item.title))
          ])
        : _vm._e(),
      _vm._v(" "),
      !(_vm.item.type === "input_inline")
        ? _c("div", { staticClass: "quest-input" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentValue,
                  expression: "currentValue"
                }
              ],
              ref: "textarea",
              class: { warn: _vm.warn },
              style: _vm.styleObject,
              attrs: { placeholder: "请填写...", disabled: _vm.disabled },
              domProps: { value: _vm.currentValue },
              on: {
                keyup: _vm.onKeyup,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.currentValue = $event.target.value;
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.type === "input_inline"
        ? _c("div", { staticClass: "quest-input" }, [
            _c("span", { staticClass: "quest-label title" }, [
              _vm._v(_vm._s(_vm.item.label || _vm.item.title))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentValue,
                  expression: "currentValue"
                }
              ],
              ref: "input",
              class: { warn: _vm.warn },
              attrs: {
                placeholder: _vm.item.placeholder,
                disabled: _vm.disabled
              },
              domProps: { value: _vm.currentValue },
              on: {
                focus: _vm.onFocus,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.currentValue = $event.target.value;
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "input-inline-unit" }, [
              _vm._v(_vm._s(_vm.item.unit))
            ])
          ])
        : _vm._e()
    ]
  )
};
var __vue_staticRenderFns__ = [];
__vue_render__._withStripped = true;

  /* style */
  const __vue_inject_styles__ = undefined;
  /* scoped */
  const __vue_scope_id__ = undefined;
  /* functional template */
  const __vue_is_functional_template__ = false;
  /* component normalizer */
  function __vue_normalize__(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var QInput = __vue_normalize__(
    { render: __vue_render__, staticRenderFns: __vue_staticRenderFns__ },
    __vue_inject_styles__,
    __vue_script__,
    __vue_scope_id__,
    __vue_is_functional_template__);

//
var script$1 = {
  name: 'InputNumber',
  mixins: [isNumber, formDisabled, Emitter],
  props: {
    value: {},
    item: Object,
    role: String,
    scoreIndex: String
  },
  data () {
    return {
      currentValue: this.getValue(this.value),
      warn: false,
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
    },
    currentValue (val, oldVal) {
      this.$emit('on-change', this.getParsedVal(val), this.item.key);
      if (this.scoreIndex) {
        this.$emit('on-score-change', {value: this.getParsedVal(val), scoreIndex: this.scoreIndex}, this.item.key);
      }
      // 整数处理
      setTimeout(() => {
        // console.log(oldVal, val)
        if (!this.isNumber(val) && (this.item.valueType === 'number' || this.item.valueType === 'integer') && String(val) !== String(oldVal)) {
          this.currentValue = '';
        }
        if (this.item.valueType === 'integer' && this.isNumber(val) && String(val) !== String(oldVal)) {
          this.currentValue = parseInt(val) || 0;
        }
      }, 0);
    }
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      // Number(undefined) = NaN, Number(0.0) = 0, Number('') = 0, Number('abc') = NaN
      if (Number(data[this.item.key]) === 0) return data[this.item.key] // 解决值是零或空字符的情况
      return data[this.item.key] || ''
    },
    onFocus () {
      this.warn = false;
    },
    getParsedVal (val) {
      if (!this.isNumber(val) && (this.item.valueType === 'number' || this.item.valueType === 'integer')) {
        return ''
      } else if (this.item.valueType === 'integer' && this.isNumber(val)) {
        return parseInt(val) || 0
      } else if (this.item.valueType === 'number' && this.isNumber(val)) {
        return Number(val) || 0
      }
      return val
    },
    clearValid () {
      this.warn = false;
    },
    validate () {
      if (!this.item.required) return true
      if (!this.currentValue) {
        this.warn = true;
        return false
      }
      this.warn = false;
      return true
    }
  }
};

/* script */
            const __vue_script__$1 = script$1;
            
/* template */
var __vue_render__$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer input-inline",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c("div", { staticClass: "quest-input" }, [
        _c("span", { staticClass: "quest-label title" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentValue,
              expression: "currentValue"
            }
          ],
          ref: "input",
          class: { warn: _vm.warn },
          attrs: { placeholder: _vm.item.placeholder, disabled: _vm.disabled },
          domProps: { value: _vm.currentValue },
          on: {
            focus: _vm.onFocus,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.currentValue = $event.target.value;
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "input-inline-unit" }, [
          _vm._v(_vm._s(_vm.item.unit))
        ])
      ])
    ]
  )
};
var __vue_staticRenderFns__$1 = [];
__vue_render__$1._withStripped = true;

  /* style */
  const __vue_inject_styles__$1 = undefined;
  /* scoped */
  const __vue_scope_id__$1 = undefined;
  /* functional template */
  const __vue_is_functional_template__$1 = false;
  /* component normalizer */
  function __vue_normalize__$1(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-number.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputNumber = __vue_normalize__$1(
    { render: __vue_render__$1, staticRenderFns: __vue_staticRenderFns__$1 },
    __vue_inject_styles__$1,
    __vue_script__$1,
    __vue_scope_id__$1,
    __vue_is_functional_template__$1);

//
var script$2 = {
  mixins: [isNumber, formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      currentValue: this.getValue(this.value),
      inputWarn: {},
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
    },
    currentValue (val, oldval) {
      // 存在原始没有value值，初始化currentValue:{k_14966171x001x007x001: "",k_14966171x001x007x002: ""}
      if (JSON.stringify(val) !== JSON.stringify(oldval)) {
        this.$emit('on-change', val, this.item.key);
      }
    }
  },
  mounted () {
    this.initialInputWarn();
  },
  methods: {
    getValue (data) {
      if (!data) return {}
      const dataobj = data[this.item.key] || {};
      const children = this.item.children || [];
      children.forEach(child => {
        dataobj[child.key] = dataobj[child.key] || '';
      });
      return dataobj
    },
    onFocus () {
      this.validate();
    },
    onBlur () {
      //
    },
    onChange () {
      //
    },
    onInputChange () {
      this.$emit('on-change', this.getParsedKeyVal(this.currentValue), this.item.key);
    },
    getParsedKeyVal (val) {
      let keyVal = Object.assign({}, val);
      Object.keys(keyVal).forEach(key => {
        this.item.children.forEach(child => {
          if (child.key === key && child.valueType === 'integer' && this.isNumber(keyVal[key])) {
            keyVal[key] = parseInt(keyVal[key]) || 0;
          } else if (child.key === key && child.valueType === 'number' && this.isNumber(keyVal[key])) {
            keyVal[key] = Number(keyVal[key]) || 0;
          }
        });
      });
      return keyVal
    },
    initialInputWarn () {
      const children = this.item.children || [];
      children.forEach(child => {
        child.placeholder = '';
        this.$set(this.inputWarn, child.key, false);
      });
    },
    clearValid () {
      const children = this.item.children || [];
      children.forEach(child => {
        child.placeholder = '';
        this.$set(this.inputWarn, child.key, false);
      });
    },
    validate () {
      let flag = true;
      const children = this.item.children || [];
      children.forEach(child => {
        const childVal = String(this.currentValue[child.key] || '');
        if (!(childVal && childVal.length) && child.required) {
          flag = false;
          this.$set(this.inputWarn, child.key, true);
          child.placeholder = '请填写...';
        } else {
          this.$set(this.inputWarn, child.key, false);
          child.placeholder = '';
        }
      });
      return flag
    }
  }
};

/* script */
            const __vue_script__$2 = script$2;
            
/* template */
var __vue_render__$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      staticClass: "quest-item-answer input-inline",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c(
        "div",
        { staticClass: "quest-input" },
        [
          _c("span", { staticClass: "quest-label title" }, [
            _vm._v(_vm._s(_vm.item.label || _vm.item.title))
          ]),
          _vm._v(" "),
          _vm._l(_vm.item.children, function(child, index) {
            return [
              child.label || child.title
                ? _c(
                    "div",
                    {
                      key: "label" + child.key,
                      staticClass: "input-inline-label"
                    },
                    [_vm._v(_vm._s(child.label || child.title))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentValue[child.key],
                    expression: "currentValue[child.key]"
                  }
                ],
                key: "input" + child.key,
                ref: "input_" + child.key,
                refInFor: true,
                class: { warn: _vm.inputWarn[child.key] },
                attrs: {
                  placeholder: child.placeholder,
                  disabled: _vm.disabled
                },
                domProps: { value: _vm.currentValue[child.key] },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  change: _vm.onChange,
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.currentValue, child.key, $event.target.value);
                    },
                    _vm.onInputChange
                  ]
                }
              }),
              _vm._v(" "),
              child.unit
                ? _c(
                    "div",
                    {
                      key: "unit" + child.key,
                      staticClass: "input-inline-unit"
                    },
                    [_vm._v(_vm._s(child.unit))]
                  )
                : _vm._e(),
              _vm._v(" "),
              index < _vm.item.children.length - 1 && _vm.item.relationSymbol
                ? _c("div", {
                    key: "symbol" + child.key,
                    staticClass: "input-inline-symbol",
                    domProps: { innerHTML: _vm._s(_vm.item.relationSymbol) }
                  })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ]
  )
};
var __vue_staticRenderFns__$2 = [];
__vue_render__$2._withStripped = true;

  /* style */
  const __vue_inject_styles__$2 = undefined;
  /* scoped */
  const __vue_scope_id__$2 = undefined;
  /* functional template */
  const __vue_is_functional_template__$2 = false;
  /* component normalizer */
  function __vue_normalize__$2(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-multi.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputMulti = __vue_normalize__$2(
    { render: __vue_render__$2, staticRenderFns: __vue_staticRenderFns__$2 },
    __vue_inject_styles__$2,
    __vue_script__$2,
    __vue_scope_id__$2,
    __vue_is_functional_template__$2);

//
var script$3 = {
  mixins: [formDisabled],
  props: {
    value: {},
    item: Object,
    role: String,
    scoreIndex: String
  },
  data () {
    return {
      currentKey: this.getChildKey(this.value),
      idRand: Math.random().toString().slice(2),
      warn: false,
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentKey = this.getChildKey(val);
    },
    currentKey (val) {
      this.clearValid();
      if (val) {
        this.$emit('on-change', {key: val, value: this.getChildVal(val)}, this.item.key);
      } else {
        // 清除
        this.$emit('on-change', '', this.item.key);
      }
      if (val && this.scoreIndex) {
        this.$emit('on-score-change', {value: this.getChildVal(val), scoreIndex: this.scoreIndex}, this.item.key);
      }
    }
  },
  methods: {
    getChildKey (data) {
      if (!data) return {}
      const dataobj = data[this.item.key] || {};
      let key = dataobj['key'] || '';
      return key
    },
    getChildVal (childKey) {
      if (!this.item.children) return ''
      let val = '';
      this.item.children.forEach(child => {
        if (child.key === childKey) {
          val = child.value;
        }
      });
      return val
    },
    clearValid () {
      this.warn = false;
    },
    validate () {
      if (!this.item.required) return true
      if (!this.currentKey) {
        this.warn = true;
        return false
      }
      this.warn = false;
      return true
    },
    toggleClick (child, e) {
      if (child.key === this.currentKey) {
        e.preventDefault();
        this.currentKey = '';
      }
    }
  }
};

/* script */
            const __vue_script__$3 = script$3;
            
/* template */
var __vue_render__$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer",
      class: { "input-inline": _vm.item.type === "input_sselect" },
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c(
        "div",
        {
          staticClass: "quest-input",
          class: { "multi-line": _vm.item.type === "input_sselect_multi_line" }
        },
        [
          _c("span", { staticClass: "quest-label title" }, [
            _vm._v(_vm._s(_vm.item.label || _vm.item.title))
          ]),
          _vm._v(" "),
          _vm._l(_vm.item.children, function(child, index) {
            return [
              _c(
                "label",
                {
                  key: child.key,
                  staticClass: "input-sselect",
                  class: {
                    "multi-line": _vm.item.type === "input_sselect_multi_line"
                  },
                  attrs: { for: "_id_" + index + _vm.idRand }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "quest-radio-outer",
                      class: { checked: child.key === _vm.currentKey }
                    },
                    [
                      _c("span", {
                        staticClass: "quest-radio-inner",
                        class: { warn: _vm.warn },
                        on: {
                          click: function($event) {
                            $event.stopPropagation();
                            return _vm.toggleClick(child, $event)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentKey,
                            expression: "currentKey"
                          }
                        ],
                        staticClass: "quest-radio",
                        attrs: {
                          type: "radio",
                          id: "_id_" + index + _vm.idRand,
                          disabled: _vm.disabled
                        },
                        domProps: {
                          value: child.key,
                          checked: _vm._q(_vm.currentKey, child.key)
                        },
                        on: {
                          change: function($event) {
                            _vm.currentKey = child.key;
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "quest-label" }, [
                    _vm._v(_vm._s(child.label || child.title))
                  ])
                ]
              )
            ]
          })
        ],
        2
      )
    ]
  )
};
var __vue_staticRenderFns__$3 = [];
__vue_render__$3._withStripped = true;

  /* style */
  const __vue_inject_styles__$3 = undefined;
  /* scoped */
  const __vue_scope_id__$3 = undefined;
  /* functional template */
  const __vue_is_functional_template__$3 = false;
  /* component normalizer */
  function __vue_normalize__$3(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-sselect.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputSselect = __vue_normalize__$3(
    { render: __vue_render__$3, staticRenderFns: __vue_staticRenderFns__$3 },
    __vue_inject_styles__$3,
    __vue_script__$3,
    __vue_scope_id__$3,
    __vue_is_functional_template__$3);

var sselectInputValid = {
  methods: {
    initialInputWarn () {
      this.warnContent = '未完成选择';
      this.showWarn = false;

      let children = this.item.children || [];
      children.forEach(child => {
        child.placeholder = '';
        this.$set(this.inputWarn, child.key, false);
      });
    },
    valid () {
      // 是否必选
      this.showWarn = false;
      if (!this.item.required && !this.currentKey) {
        this.warn = false;
        return true
      } else if (this.item.required && !this.currentKey) {
        this.warn = true;
        this.warnContent = '未完成选择';
        this.showWarn = true;
        return false
      }
      // 有选择就必填
      const children = this.item.children || [];
      children.forEach(child => {
        const childVal = String(this.inputKeyVal[child.key] || '');
        if (child.required && this.currentKey === child.key && !(childVal && childVal.length)) {
          this.$set(this.inputWarn, child.key, true);
          child.placeholder = '请填写...';
          //
          this.warnContent = '未完成填写';
          this.showWarn = true;
        }
      });
      if (this.showWarn) {
        return false
      }
      return true
    }
  }
};

//
var script$4 = {
  mixins: [formDisabled, sselectInputValid],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      currentKey: this.getChildKey(this.value),
      inputKeyVal: this.getChildInput(this.value),
      idRand: Math.random().toString().slice(2),
      warn: false,
      inputWarn: {},
      showWarn: false,
      warnContent: '选择未完成',
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentKey = this.getChildKey(val);
      this.inputKeyVal = this.getChildInput(val);
    },
    currentKey (val) {
      // 其它输入项置空
      Object.keys(this.inputKeyVal).forEach(key => {
        if (key !== val) {
          this.inputKeyVal[key] = '';
        }
      });
      this.clearValid();
      this.emitChange(val);
      this.initialInputWarn();
    }
  },
  methods: {
    getChildKey (data) {
      if (!data) return {}
      const dataobj = data[this.item.key] || {};
      let key = dataobj['key'] || '';
      return key
    },
    getChildVal (childKey) {
      if (!this.item.children) return ''
      let val = '';
      this.item.children.forEach(child => {
        if (child.key === childKey) {
          val = child.value;
        }
      });
      return val
    },
    getChildInput (data) {
      if (!data) return {}
      let inputKeyVal = {};
      this.item.children.forEach(child => {
        if (child.type === 'sselect_input_inline') {
          inputKeyVal[child.key] = '';
        }
      });
      const dataobj = data[this.item.key] || {};
      let key = dataobj['key'] || '';
      let selfInput = dataobj['selfInput'] || '';
      if (key && selfInput) {
        inputKeyVal[key] = selfInput;
      }
      return inputKeyVal
    },
    clearValid () {
      this.warn = false;
    },
    validate () {
      return this.valid()
    },
    onFocus (child) {
      this.initialInputWarn();
      this.currentKey = child.key;
    },
    onBlur () {
      this.validate();
    },
    onChange () {
      //
    },
    onChildInputChange () {
      this.emitChange(this.currentKey);
    },
    emitChange (key) {
      if (key) {
        if (this.inputKeyVal[key] || this.inputKeyVal[key] === '') {
          this.$emit('on-change', {key, value: this.getChildVal(key), selfInput: this.inputKeyVal[key]}, this.item.key);
        } else {
          this.$emit('on-change', {key, value: this.getChildVal(key)}, this.item.key);
        }
      } else {
        // 清除
        this.$emit('on-change', '', this.item.key);
      }
    },
    toggleClick (child, e) {
      if (child.key === this.currentKey) {
        e.preventDefault();
        this.currentKey = '';
      }
    }
  }
};

/* script */
            const __vue_script__$4 = script$4;
            
/* template */
var __vue_render__$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c(
        "div",
        { staticClass: "quest-input multi-line" },
        [
          _c("span", { staticClass: "quest-label title" }, [
            _vm._v(_vm._s(_vm.item.label || _vm.item.title))
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWarn,
                  expression: "showWarn"
                }
              ],
              staticClass: "quest-tip-warn"
            },
            [
              _c("i", { staticClass: "weui-icon-warn" }),
              _vm._v(_vm._s(_vm.warnContent))
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.item.children, function(child, index) {
            return [
              _c(
                "label",
                {
                  key: child.key,
                  staticClass: "input-sselect-input",
                  attrs: { for: "_id_" + index + _vm.idRand }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "quest-radio-outer",
                      class: { checked: child.key === _vm.currentKey }
                    },
                    [
                      _c("span", {
                        staticClass: "quest-radio-inner",
                        class: { warn: _vm.warn },
                        on: {
                          click: function($event) {
                            $event.stopPropagation();
                            return _vm.toggleClick(child, $event)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentKey,
                            expression: "currentKey"
                          }
                        ],
                        staticClass: "quest-radio",
                        attrs: {
                          type: "radio",
                          id: "_id_" + index + _vm.idRand,
                          disabled: _vm.disabled
                        },
                        domProps: {
                          value: child.key,
                          checked: _vm._q(_vm.currentKey, child.key)
                        },
                        on: {
                          change: function($event) {
                            _vm.currentKey = child.key;
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "quest-label" }, [
                    _vm._v(_vm._s(child.label || child.title))
                  ]),
                  _vm._v(" "),
                  child.type === "sselect_input_inline"
                    ? _c("div", { staticClass: "input-inline" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.inputKeyVal[child.key],
                              expression: "inputKeyVal[child.key]"
                            }
                          ],
                          class: { warn: _vm.inputWarn[child.key] },
                          attrs: {
                            placeholder: child.placeholder,
                            disabled: _vm.disabled
                          },
                          domProps: { value: _vm.inputKeyVal[child.key] },
                          on: {
                            focus: function($event) {
                              return _vm.onFocus(child)
                            },
                            blur: _vm.onBlur,
                            change: _vm.onChange,
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.inputKeyVal,
                                  child.key,
                                  $event.target.value
                                );
                              },
                              _vm.onChildInputChange
                            ]
                          }
                        }),
                        _vm._v(" "),
                        child.unit
                          ? _c(
                              "div",
                              {
                                key: "unit" + child.key,
                                staticClass: "input-inline-unit"
                              },
                              [_vm._v(_vm._s(child.unit))]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ]
              )
            ]
          })
        ],
        2
      )
    ]
  )
};
var __vue_staticRenderFns__$4 = [];
__vue_render__$4._withStripped = true;

  /* style */
  const __vue_inject_styles__$4 = undefined;
  /* scoped */
  const __vue_scope_id__$4 = undefined;
  /* functional template */
  const __vue_is_functional_template__$4 = false;
  /* component normalizer */
  function __vue_normalize__$4(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-sselect-input.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputSselectInput = __vue_normalize__$4(
    { render: __vue_render__$4, staticRenderFns: __vue_staticRenderFns__$4 },
    __vue_inject_styles__$4,
    __vue_script__$4,
    __vue_scope_id__$4,
    __vue_is_functional_template__$4);

//
var script$5 = {
  mixins: [formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      currentKey: this.getKeys(this.value),
      idRand: Math.random().toString().slice(2),
      warn: false,
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      let keys = this.getKeys(val);
      let equal = true;
      if (keys.length !== this.currentKey.length) {
        this.currentKey = keys;
      } else {
        for (let i = 0; i < keys.length; i++) {
          if (keys[i] !== this.currentKey[i]) {
            equal = false;
            break
          }
        }
        if (!equal) {
          this.currentKey = keys;
        }
      }
    },
    currentKey (val) {
      this.validate();
      this.emitChange(val);
    }
  },
  methods: {
    getKeys (data) {
      const keys = [];
      let _data = data[this.item.key];
      if (!Array.isArray(_data)) return keys
      _data.forEach(select => {
        keys.push(select.key);
      });
      return keys
    },
    emitChange (val) {
      const selectedKeys = val || [];
      const keyVals = [];
      selectedKeys.forEach(key => {
        let val = '';
        this.item.children.forEach(item => {
          if (item.key === key) {
            val = item.value;
          }
        });
        keyVals.push({key, value: val});
      });
      this.$emit('on-change', keyVals, this.item.key);
    },
    clearValid () {
      this.warn = false;
    },
    validate () {
      if (!this.item.required) return true
      if (this.currentKey && this.currentKey.length > 0) {
        this.warn = false;
        return true
      } else if (this.$device.isDesktop) {
        this.warn = false;
        return true
      }
      this.warn = true;
      return false
    }
  }
};

/* script */
            const __vue_script__$5 = script$5;
            
/* template */
var __vue_render__$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c(
        "div",
        { staticClass: "quest-input multi-line" },
        [
          _c("span", { staticClass: "quest-label title" }, [
            _vm._v(_vm._s(_vm.item.label || _vm.item.title))
          ]),
          _vm._v(" "),
          _vm._l(_vm.item.children, function(child, index) {
            return [
              _c(
                "label",
                {
                  key: child.key,
                  staticClass: "input-sselect multi-line",
                  attrs: { for: "_id_" + index + _vm.idRand }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentKey,
                        expression: "currentKey"
                      }
                    ],
                    staticClass: "quest-checkbox",
                    attrs: {
                      type: "checkbox",
                      id: "_id_" + index + _vm.idRand,
                      disabled: _vm.disabled
                    },
                    domProps: {
                      value: child.key,
                      checked: Array.isArray(_vm.currentKey)
                        ? _vm._i(_vm.currentKey, child.key) > -1
                        : _vm.currentKey
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.currentKey,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false;
                        if (Array.isArray($$a)) {
                          var $$v = child.key,
                            $$i = _vm._i($$a, $$v);
                          if ($$el.checked) {
                            $$i < 0 && (_vm.currentKey = $$a.concat([$$v]));
                          } else {
                            $$i > -1 &&
                              (_vm.currentKey = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)));
                          }
                        } else {
                          _vm.currentKey = $$c;
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "weui-icon-checkbox" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "quest-label" }, [
                    _vm._v(_vm._s(child.label || child.title))
                  ])
                ]
              )
            ]
          })
        ],
        2
      )
    ]
  )
};
var __vue_staticRenderFns__$5 = [];
__vue_render__$5._withStripped = true;

  /* style */
  const __vue_inject_styles__$5 = undefined;
  /* scoped */
  const __vue_scope_id__$5 = undefined;
  /* functional template */
  const __vue_is_functional_template__$5 = false;
  /* component normalizer */
  function __vue_normalize__$5(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-mselect.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputMselect = __vue_normalize__$5(
    { render: __vue_render__$5, staticRenderFns: __vue_staticRenderFns__$5 },
    __vue_inject_styles__$5,
    __vue_script__$5,
    __vue_scope_id__$5,
    __vue_is_functional_template__$5);

//
var script$6 = {
  mixins: [formDisabled],
  props: {
    item: Object,
    value: {},
    index: [String, Number],
    role: String
  },
  data () {
    const data = this.getNewData(this.value);
    let selectedKey = data.currentKey;
    return {
      currentKey: selectedKey || [],
      idRand: Math.random().toString().slice(2),
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      const data = this.getNewData(val);
      if (this.currentKey[0] !== data.currentKey[0]) {
        this.currentKey = data.currentKey;
      }
    },
    currentKey (val) {
      this.$emit('on-change', {key: val[0], value: this.item.value}, this.item.key);
      this.$emit('on-validate', true);
    }
  },
  methods: {
    getNewData (data) {
      if (!data) return {}
      const valobj = data[this.item.key];
      let selectedKey;
      if (valobj) {
        selectedKey = valobj['key'];
      }
      return {
        currentKey: (selectedKey ? [selectedKey] : [])
      }
    }
  }
};

/* script */
            const __vue_script__$6 = script$6;
            
/* template */
var __vue_render__$6 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer",
      attrs: { for: "_id_" + _vm.index + _vm.idRand }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentKey,
            expression: "currentKey"
          }
        ],
        staticClass: "quest-checkbox",
        attrs: {
          type: "checkbox",
          id: "_id_" + _vm.index + _vm.idRand,
          disabled: _vm.disabled
        },
        domProps: {
          value: _vm.item.key,
          checked: Array.isArray(_vm.currentKey)
            ? _vm._i(_vm.currentKey, _vm.item.key) > -1
            : _vm.currentKey
        },
        on: {
          change: function($event) {
            var $$a = _vm.currentKey,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;
            if (Array.isArray($$a)) {
              var $$v = _vm.item.key,
                $$i = _vm._i($$a, $$v);
              if ($$el.checked) {
                $$i < 0 && (_vm.currentKey = $$a.concat([$$v]));
              } else {
                $$i > -1 &&
                  (_vm.currentKey = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.currentKey = $$c;
            }
          }
        }
      }),
      _vm._v(" "),
      _c("i", { staticClass: "weui-icon-checkbox" }),
      _vm._v(" "),
      _c("span", { staticClass: "quest-label" }, [
        _vm._v(_vm._s(_vm.item.label || _vm.item.title))
      ])
    ]
  )
};
var __vue_staticRenderFns__$6 = [];
__vue_render__$6._withStripped = true;

  /* style */
  const __vue_inject_styles__$6 = undefined;
  /* scoped */
  const __vue_scope_id__$6 = undefined;
  /* functional template */
  const __vue_is_functional_template__$6 = false;
  /* component normalizer */
  function __vue_normalize__$6(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\check.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var Check = __vue_normalize__$6(
    { render: __vue_render__$6, staticRenderFns: __vue_staticRenderFns__$6 },
    __vue_inject_styles__$6,
    __vue_script__$6,
    __vue_scope_id__$6,
    __vue_is_functional_template__$6);

//
var script$7 = {
  mixins: [formDisabled],
  props: {
    item: Object,
    value: {},
    index: [String, Number],
    role: String
  },
  data () {
    const data = this.getNewData(this.value);
    let selectedKey = data.currentKey;
    let inputVal = data.inputValue;
    return {
      currentKey: selectedKey || [],
      inputValue: inputVal || '',
      idRand: Math.random().toString().slice(2),
      styleObject: {},
      warn: false,
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      const data = this.getNewData(val);
      if (this.currentKey[0] !== data.currentKey[0]) {
        this.currentKey = data.currentKey;
      }
      if (this.inputValue !== data.inputValue) {
        this.inputValue = data.inputValue;
      }
    },
    currentKey (val) {
      this.clearValid();
      if (val && val[0]) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.textarea && this.$refs.textarea.focus();
          }, 50);
        });
      }
      this.$emit('on-change', {key: val[0], value: this.item.value, selfInput: this.inputValue}, this.item.key);
    },
    inputValue (val) {
      this.$emit('on-validate', this.validate());
      this.$emit('on-change', {key: this.currentKey[0], value: this.item.value, selfInput: val}, this.item.key);
    }
  },
  methods: {
    getNewData (data) {
      if (!data) return {}
      const valobj = data[this.item.key];
      let selectedKey;
      let inputVal;
      if (valobj) {
        selectedKey = valobj['key'];
        inputVal = valobj['selfInput'];
      }
      return {
        currentKey: (selectedKey ? [selectedKey] : []),
        inputValue: (inputVal || '')
      }
    },
    onKeyup () {
      if (this.$refs.textarea.clientHeight < this.$refs.textarea.scrollHeight) {
        this.styleObject = {
          height: `${this.$refs.textarea.scrollHeight}px`
        };
      }
    },
    onFocus (item) {
      if (!this.currentKey.length ||
          (this.currentKey.length && this.currentKey[0] !== item.key)) {
        this.currentKey = [item.key];
      }
    },
    clearValid () {
      this.warn = false;
      this.item.placeholder = '';
    },
    validate () {
      if (this.currentKey.indexOf(this.item.key) >= 0) {
        if (this.item.required && !this.inputValue) {
          this.warn = true;
          this.item.placeholder = '请输入...';
          return false
        } else {
          this.clearValid();
          return true
        }
      } else {
        this.clearValid();
        return true
      }
    }
  }
};

/* script */
            const __vue_script__$7 = script$7;
            
/* template */
var __vue_render__$7 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer",
      attrs: { for: "_id_" + _vm.index + _vm.idRand }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentKey,
            expression: "currentKey"
          }
        ],
        staticClass: "quest-checkbox",
        attrs: {
          type: "checkbox",
          id: "_id_" + _vm.index + _vm.idRand,
          disabled: _vm.disabled
        },
        domProps: {
          value: _vm.item.key,
          checked: Array.isArray(_vm.currentKey)
            ? _vm._i(_vm.currentKey, _vm.item.key) > -1
            : _vm.currentKey
        },
        on: {
          change: function($event) {
            var $$a = _vm.currentKey,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;
            if (Array.isArray($$a)) {
              var $$v = _vm.item.key,
                $$i = _vm._i($$a, $$v);
              if ($$el.checked) {
                $$i < 0 && (_vm.currentKey = $$a.concat([$$v]));
              } else {
                $$i > -1 &&
                  (_vm.currentKey = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.currentKey = $$c;
            }
          }
        }
      }),
      _vm._v(" "),
      _c("i", { staticClass: "weui-icon-checkbox" }),
      _vm._v(" "),
      _c("span", { staticClass: "quest-label" }, [
        _vm._v(_vm._s(_vm.item.label || _vm.item.title))
      ]),
      _vm._v(" "),
      _vm.currentKey.indexOf(_vm.item.key) >= 0
        ? _c(
            "div",
            {
              staticClass: "select-input",
              style: _vm.styleObject,
              on: {
                click: function($event) {
                  $event.stopPropagation();
                  $event.preventDefault();
                  return (function() {
                    return false
                  })()
                }
              }
            },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue"
                  }
                ],
                ref: "textarea",
                class: { warn: _vm.warn },
                style: _vm.styleObject,
                attrs: {
                  placeholder: _vm.item.placeholder,
                  disabled: _vm.disabled
                },
                domProps: { value: _vm.inputValue },
                on: {
                  keyup: _vm.onKeyup,
                  focus: function($event) {
                    $event.stopPropagation();
                    return _vm.onFocus(_vm.item)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.inputValue = $event.target.value;
                  }
                }
              })
            ]
          )
        : _vm._e()
    ]
  )
};
var __vue_staticRenderFns__$7 = [];
__vue_render__$7._withStripped = true;

  /* style */
  const __vue_inject_styles__$7 = undefined;
  /* scoped */
  const __vue_scope_id__$7 = undefined;
  /* functional template */
  const __vue_is_functional_template__$7 = false;
  /* component normalizer */
  function __vue_normalize__$7(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\check-input.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var CheckInput = __vue_normalize__$7(
    { render: __vue_render__$7, staticRenderFns: __vue_staticRenderFns__$7 },
    __vue_inject_styles__$7,
    __vue_script__$7,
    __vue_scope_id__$7,
    __vue_is_functional_template__$7);

//
var script$8 = {
  mixins: [isNumber, formDisabled],
  props: {
    item: Object,
    value: {},
    index: [String, Number],
    role: String
  },
  data () {
    const data = this.getNewData(this.value);
    let selectedKey = data.currentKey;
    let inputVal = data.inputValue;
    return {
      currentKey: selectedKey || [],
      inputValue: inputVal || '',
      idRand: Math.random().toString().slice(2),
      warn: false,
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      const data = this.getNewData(val);
      if (this.currentKey[0] !== data.currentKey[0]) {
        this.currentKey = data.currentKey;
        if (this.inputValue !== data.inputValue) {
          this.inputValue = data.inputValue;
        }
      }
    },
    currentKey (val) {
      this.clearValid();
      if (val && val[0]) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.input && this.$refs.input.focus();
          }, 50);
        });
      }
      this.$emit('on-change', {
        key: val[0],
        value: this.item.value,
        selfInput: this.getParsedValue(this.inputValue)
      }, this.item.key);
    },
    inputValue (val) {
      this.$emit('on-validate', this.validate());
      this.$emit('on-change', {
        key: this.currentKey[0],
        value: this.item.value,
        selfInput: this.getParsedValue(val)
      }, this.item.key);
    }
  },
  methods: {
    getNewData (data) {
      if (!data) return {}
      const valobj = data[this.item.key];
      let selectedKey;
      let inputVal;
      if (valobj) {
        selectedKey = valobj['key'];
        inputVal = valobj['selfInput'];
      }
      return {
        currentKey: (selectedKey ? [selectedKey] : []),
        inputValue: (inputVal || '')
      }
    },
    onFocus (item) {
      if (!this.currentKey.length ||
          (this.currentKey.length && this.currentKey[0] !== item.key)) {
        this.currentKey = [item.key];
      }
    },
    getParsedValue (val) {
      if (val && this.item.valueType === 'integer' && this.isNumber(val)) {
        return parseInt(val) || 0
      } else if (val && this.item.valueType === 'number' && this.isNumber(val)) {
        return Number(val) || 0
      }
      return val
    },
    clearValid () {
      this.warn = false;
      this.item.placeholder = '';
    },
    validate () {
      if (this.currentKey.indexOf(this.item.key) >= 0) {
        let strVal = String(this.inputValue);
        if (this.item.required && !(strVal && strVal.length)) {
          this.warn = true;
          this.item.placeholder = '请输入...';
          return false
        } else {
          this.clearValid();
          return true
        }
      } else {
        this.clearValid();
        return true
      }
    }
  }
};

/* script */
            const __vue_script__$8 = script$8;
            
/* template */
var __vue_render__$8 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer input-inline",
      attrs: { for: "_id_" + _vm.index + _vm.idRand }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentKey,
            expression: "currentKey"
          }
        ],
        staticClass: "quest-checkbox",
        attrs: {
          type: "checkbox",
          id: "_id_" + _vm.index + _vm.idRand,
          disabled: _vm.disabled
        },
        domProps: {
          value: _vm.item.key,
          checked: Array.isArray(_vm.currentKey)
            ? _vm._i(_vm.currentKey, _vm.item.key) > -1
            : _vm.currentKey
        },
        on: {
          change: function($event) {
            var $$a = _vm.currentKey,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;
            if (Array.isArray($$a)) {
              var $$v = _vm.item.key,
                $$i = _vm._i($$a, $$v);
              if ($$el.checked) {
                $$i < 0 && (_vm.currentKey = $$a.concat([$$v]));
              } else {
                $$i > -1 &&
                  (_vm.currentKey = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.currentKey = $$c;
            }
          }
        }
      }),
      _vm._v(" "),
      _c("i", { staticClass: "weui-icon-checkbox" }),
      _vm._v(" "),
      _c("span", { staticClass: "quest-label" }, [
        _vm._v(_vm._s(_vm.item.label || _vm.item.title))
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mselect-input-inline",
          on: {
            click: function($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return (function() {
                return false
              })()
            }
          }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue"
              }
            ],
            ref: "input",
            class: { warn: _vm.warn },
            attrs: {
              placeholder: _vm.item.placeholder,
              disabled: _vm.disabled
            },
            domProps: { value: _vm.inputValue },
            on: {
              focus: function($event) {
                $event.stopPropagation();
                return _vm.onFocus(_vm.item)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.inputValue = $event.target.value;
              }
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "input-inline-unit" }, [
        _vm._v(_vm._s(_vm.item.unit))
      ])
    ]
  )
};
var __vue_staticRenderFns__$8 = [];
__vue_render__$8._withStripped = true;

  /* style */
  const __vue_inject_styles__$8 = undefined;
  /* scoped */
  const __vue_scope_id__$8 = undefined;
  /* functional template */
  const __vue_is_functional_template__$8 = false;
  /* component normalizer */
  function __vue_normalize__$8(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\check-input-inline.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var CheckInputInline = __vue_normalize__$8(
    { render: __vue_render__$8, staticRenderFns: __vue_staticRenderFns__$8 },
    __vue_inject_styles__$8,
    __vue_script__$8,
    __vue_scope_id__$8,
    __vue_is_functional_template__$8);

//
var script$9 = {
  components: {Check, CheckInput, CheckInputInline},
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      selected: this.getSelectedKeyVal(this.value),
      warn: false
    }
  },
  watch: {
    value (val) {
      this.selected = this.getSelectedKeyVal(val);
    }
  },
  methods: {
    getSelectedKeyVal (data) {
      let _data = data || {};
      let keyVals = _data[this.item.key] || [];
      return this.mapArray2Object(keyVals)
    },
    onSelectChange (valobj, key) {
      if (valobj.key) {
        this.selected[key] = valobj;
      } else if (!valobj.key && this.selected[key]) {
        delete this.selected[key]; // 无法触发 selected watcher
      }
      this.$emit('on-change', this.mapObject2Array(this.selected), this.item.key);
    },
    mapArray2Object (data) {
      if (!Array.isArray(data)) return {}
      let obj = {};
      data.forEach(item => {
        obj[item.key] = item;
      });
      return obj
    },
    mapObject2Array (data) {
      let array = [];
      Object.keys(data || {}).forEach(key => {
        array.push(data[key]);
      });
      return array
    },
    clearValid () {
      this.warn = false;
    },
    isValidated () {
      return this.validate()
    },
    validate () {
      if (Object.keys(this.selected || {}).length === 0 && this.$device.isDesktop) {
        return true
      } else if (Object.keys(this.selected || {}).length === 0) {
        this.warn = true;
        return false
      }
      let result = true;
      if (this.$refs.inputInline) {
        this.$refs.inputInline.forEach(item => {
          if (!item.validate()) { result = false; }
        });
      }
      if (this.$refs.input) {
        this.$refs.input.forEach(item => {
          if (!item.validate()) { result = false; }
        });
      }
      if (!result) {
        this.warn = true;
      } else {
        this.warn = false;
      }
      return result
    }
  }
};

/* script */
            const __vue_script__$9 = script$9;
            
/* template */
var __vue_render__$9 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c(
        "div",
        { staticClass: "quest-input multi-line" },
        [
          _c("span", { staticClass: "quest-label title" }, [
            _vm._v(_vm._s(_vm.item.label || _vm.item.title))
          ]),
          _vm._v(" "),
          _vm._l(_vm.item.children, function(child, index) {
            return [
              child.type === "mselect_input_inline"
                ? _c("check-input-inline", {
                    key: index,
                    ref: "inputInline",
                    refInFor: true,
                    attrs: {
                      index: index,
                      role: _vm.role,
                      item: child,
                      value: _vm.selected
                    },
                    on: {
                      "on-validate": _vm.isValidated,
                      "on-change": _vm.onSelectChange
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              child.type === "mselect_input"
                ? _c("check-input", {
                    key: index,
                    ref: "input",
                    refInFor: true,
                    attrs: {
                      index: index,
                      role: _vm.role,
                      item: child,
                      value: _vm.selected
                    },
                    on: {
                      "on-validate": _vm.isValidated,
                      "on-change": _vm.onSelectChange
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              child.type === "mselect"
                ? _c("check", {
                    key: index,
                    attrs: {
                      index: index,
                      role: _vm.role,
                      item: child,
                      value: _vm.selected
                    },
                    on: {
                      "on-validate": _vm.isValidated,
                      "on-change": _vm.onSelectChange
                    }
                  })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ]
  )
};
var __vue_staticRenderFns__$9 = [];
__vue_render__$9._withStripped = true;

  /* style */
  const __vue_inject_styles__$9 = undefined;
  /* scoped */
  const __vue_scope_id__$9 = undefined;
  /* functional template */
  const __vue_is_functional_template__$9 = false;
  /* component normalizer */
  function __vue_normalize__$9(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-mselect-input.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputMselectInput = __vue_normalize__$9(
    { render: __vue_render__$9, staticRenderFns: __vue_staticRenderFns__$9 },
    __vue_inject_styles__$9,
    __vue_script__$9,
    __vue_scope_id__$9,
    __vue_is_functional_template__$9);

//
var script$a = {
  mixins: [isNumber, formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      currentValue: this.getValue(this.value),
      inputWarn: {},
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
    },
    currentValue (val, oldval) {
      // 存在原始没有value值，初始化currentValue:{k_14966171x001x007x001: "",k_14966171x001x007x002: ""}
      if (JSON.stringify(val) !== JSON.stringify(oldval)) {
        this.$emit('on-change', val, this.item.key);
      }
    }
  },
  mounted () {
    this.initialInputWarn();
  },
  methods: {
    getValue (data) {
      if (!data) return {}
      const dataobj = data[this.item.key] || {};
      const children = this.item.children || [];
      children.forEach(child => {
        dataobj[child.key] = dataobj[child.key] || '';
      });
      return dataobj
    },
    onFocus () {
      this.validate();
    },
    onBlur () {
      //
    },
    onChange () {
      //
    },
    onInputChange () {
      this.$emit('on-change', this.getParsedKeyVal(this.currentValue), this.item.key);
    },
    getParsedKeyVal (val) {
      let keyVal = Object.assign({}, val);
      Object.keys(keyVal).forEach(key => {
        this.item.children.forEach(child => {
          if (child.key === key && child.valueType === 'integer' && this.isNumber(keyVal[key])) {
            keyVal[key] = parseInt(keyVal[key]) || 0;
          } else if (child.key === key && child.valueType === 'number' && this.isNumber(keyVal[key])) {
            keyVal[key] = Number(keyVal[key]) || 0;
          }
        });
      });
      return keyVal
    },
    initialInputWarn () {
      const children = this.item.children || [];
      children.forEach(child => {
        child.placeholder = '';
        this.$set(this.inputWarn, child.key, false);
      });
    },
    clearValid () {
      const children = this.item.children || [];
      children.forEach(child => {
        child.placeholder = '';
        this.$set(this.inputWarn, child.key, false);
      });
    },
    validate () {
      let flag = true;
      const children = this.item.children || [];
      children.forEach(child => {
        const childVal = String(this.currentValue[child.key] || '');
        if (!(childVal && childVal.length) && child.required) {
          flag = false;
          this.$set(this.inputWarn, child.key, true);
          child.placeholder = '请填写...';
        } else {
          this.$set(this.inputWarn, child.key, false);
          child.placeholder = '';
        }
      });
      return flag
    }
  }
};

/* script */
            const __vue_script__$a = script$a;
            
/* template */
var __vue_render__$a = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      staticClass: "quest-item-answer",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c(
        "div",
        { staticClass: "quest-input-multi-line" },
        [
          _c("span", { staticClass: "quest-label title" }, [
            _vm._v(_vm._s(_vm.item.label || _vm.item.title))
          ]),
          _vm._v(" "),
          _vm._l(_vm.item.children, function(child, index) {
            return [
              _c(
                "div",
                {
                  key: child.key,
                  staticClass: "input-multi-line",
                  style: _vm.$device.isDesktop ? child.desktop : {}
                },
                [
                  child.label || child.title
                    ? _c("div", { staticClass: "input-inline-label" }, [
                        _vm._v(_vm._s(child.label || child.title))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentValue[child.key],
                        expression: "currentValue[child.key]"
                      }
                    ],
                    ref: "input_" + child.key,
                    refInFor: true,
                    class: { warn: _vm.inputWarn[child.key] },
                    attrs: {
                      placeholder: child.placeholder,
                      disabled: _vm.disabled
                    },
                    domProps: { value: _vm.currentValue[child.key] },
                    on: {
                      focus: _vm.onFocus,
                      blur: _vm.onBlur,
                      change: _vm.onChange,
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentValue,
                            child.key,
                            $event.target.value
                          );
                        },
                        _vm.onInputChange
                      ]
                    }
                  }),
                  _vm._v(" "),
                  child.unit
                    ? _c("div", { staticClass: "input-inline-unit" }, [
                        _vm._v(_vm._s(child.unit))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  index < _vm.item.children.length - 1 &&
                  _vm.item.relationSymbol
                    ? _c("div", {
                        staticClass: "input-inline-symbol",
                        domProps: { innerHTML: _vm._s(_vm.item.relationSymbol) }
                      })
                    : _vm._e()
                ]
              )
            ]
          })
        ],
        2
      )
    ]
  )
};
var __vue_staticRenderFns__$a = [];
__vue_render__$a._withStripped = true;

  /* style */
  const __vue_inject_styles__$a = undefined;
  /* scoped */
  const __vue_scope_id__$a = undefined;
  /* functional template */
  const __vue_is_functional_template__$a = false;
  /* component normalizer */
  function __vue_normalize__$a(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-multi-line.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputMultiLine = __vue_normalize__$a(
    { render: __vue_render__$a, staticRenderFns: __vue_staticRenderFns__$a },
    __vue_inject_styles__$a,
    __vue_script__$a,
    __vue_scope_id__$a,
    __vue_is_functional_template__$a);

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var script$b = {
  name: 'picker-item',
  props: {
    data: Array,
    level: Number,
    classname: String,
    tempIndex: { // translate的缓存
      type: Number,
      default: null
    }
  },
  data () {
    return {
      items: this.data,
      temp: this.tempIndex,
      $scrollable: '',
      offset: 3,        // 列表初始化时的偏移量（列表初始化时，选项是聚焦在中间的，通过offset强制往上挪3项，以达到初始选项是为顶部的那项）
      rowHeight: 34,    // 列表每一行的高度
      bodyHeight: 7 * 34,
      start: 0,                        // 保存开始按下的位置
      end: 0,                          // 保存结束时的位置
      startTime: 0,                    // 开始触摸的时间
      translate: 0,                    // 缓存 translate
      points: [],                      // 记录移动点
      windowHeight: window.innerHeight // 屏幕的高度
    }
  },
  watch: {
    data (val) {
      this.resetItems(val);
    },
    items (val) {
      if (val) {
        this.$emit('on-before-change', this.level, this.items);
        this.scrollReady();
      }
    }
  },
  created () {
    // 在父组件中初始化子组件 temp index
    // this.$emit('on-before-change', this.level, this.items)
    // 父组件组件还未初始化完成，挪到 mounted 钩子函数中去
  },
  mounted () {
    this.scrollTempIndex();
  },
  methods: {
    resetItems (data) {
      this.items = data;
    },
    scrollTempIndex () {
      // 在父组件中初始化子组件 temp index
      this.$emit('on-before-change', this.level, this.items);
      // 根据初始化好的 temp index 滚动到相应位置
      this.scrollReady();
    },
    resetTempIndex (index) {
      this.temp = index;
    },
    scrollReady () {
      this.$scrollable = this.$el.querySelector('.weui-picker__content');
      // 首次触发选中事件
      // 如果有缓存的选项，则用缓存的选项，否则使用中间值。
      if (this.temp !== null && this.temp < this.items.length) {
        const index = this.temp;
        // this.onChange.call(this, this.items[index], index)
        this.$emit('on-change', this.level, this.items[index], index);
        this.translate = (this.offset - index) * this.rowHeight;
      } else {
        const index = this.getDefaultIndex(this.items);
        // this.onChange.call(this, this.items[index], index)
        this.$emit('on-change', this.level, this.items[index], index);
        this.translate = this.getDefaultTranslate(this.offset, this.rowHeight, this.items);
      }
      this.setTranslate(this.$scrollable, this.translate);
    },
    ontouchstart (event) {
      this.start = event.changedTouches[0].pageY;
      this.startTime = +new Date();
    },
    ontouchmove (event) {
      this.end = event.changedTouches[0].pageY;
      const diff = this.end - this.start;

      this.setTransition(this.$scrollable, 0);
      this.setTranslate(this.$scrollable, (this.translate + diff));
      this.startTime = +new Date();
      this.points.push({time: this.startTime, y: this.end});
      if (this.points.length > 40) {
        this.points.shift();
      }

      event.preventDefault();
    },
    ontouchend (event) {
      /**
       * 思路:
       * 0. touchstart 记录按下的点和时间
       * 1. touchmove 移动时记录前 40个经过的点和时间
       * 2. touchend 松开手时, 记录该点和时间. 如果松开手时的时间, 距离上一次 move时的时间超过 100ms, 那么认为停止了, 不执行惯性滑动
       *    如果间隔时间在 100ms 内, 查找 100ms 内最近的那个点, 和松开手时的那个点, 计算距离和时间差, 算出速度
       *    速度乘以惯性滑动的时间, 例如 300ms, 计算出应该滑动的距离
       */
      const endTime = new Date().getTime();
      this.end = event.changedTouches[0].pageY;
      const relativeY = this.windowHeight - (this.bodyHeight / 2);

      // 如果上次时间距离松开手的时间超过 100ms, 则停止了, 没有惯性滑动
      if (endTime - this.startTime > 100) {
        // 如果end和start相差小于10，则视为
        if (Math.abs(this.end - this.start) > 10) {
          this.stop(this.end - this.start);
        } else {
          this.stop(relativeY - this.end);
        }
      } else {
        if (Math.abs(this.end - this.start) > 10) {
          const endPos = this.points.length - 1;
          let startPos = endPos;
          for (let i = endPos; i > 0 && this.startTime - this.points[i].time < 100; i--) {
            startPos = i;
          }

          if (startPos !== endPos) {
            const ep = this.points[endPos];
            const sp = this.points[startPos];
            const t = ep.time - sp.time;
            const s = ep.y - sp.y;
            const v = s / t; // 出手时的速度
            const diff = v * 150 + (this.end - this.start); // 滑行 150ms,这里直接影响“灵敏度”
            this.stop(diff);
          } else {
            this.stop(0);
          }
        } else {
          this.stop(relativeY - this.end);
        }
      }
    },
    stop (diff) {
      this.translate += diff;

      // 移动到最接近的那一行
      this.translate = Math.round(this.translate / this.rowHeight) * this.rowHeight;
      const max = this.getMax(this.offset, this.rowHeight);
      const min = this.getMin(this.offset, this.rowHeight, this.items.length);
      // 不要超过最大值或者最小值
      if (this.translate > max) {
        this.translate = max;
      }
      if (this.translate < min) {
        this.translate = min;
      }

      // 如果是 disabled 的就跳过
      let index = this.offset - this.translate / this.rowHeight;
      while (!!this.items[index] && this.items[index].disabled) {
        diff > 0 ? ++index : --index;
      }
      this.translate = (this.offset - index) * this.rowHeight;
      this.setTransition(this.$scrollable, 0.3);
      this.setTranslate(this.$scrollable, this.translate);

      // 触发选择事件
      // this.onChange.call(this, this.items[index], index)
      if (this.temp !== index) {
        this.temp = index;
        this.$emit('on-change', this.level, this.items[index], index);
      }
    },
    setTransition ($target, time) {
      $target.style.webkitTransition = `all ${time}s`;
      $target.style.transform = `all ${time}s`;
    },
    setTranslate ($target, diff) {
      $target.style.webkitTransform = `translate3d(0, ${diff}px, 0)`;
      $target.style.transform = `translate3d(0, ${diff}px, 0)`;
    },
    /**
     * @desc get index of middle item
     * @param items
     * @returns {number}
     */
    getDefaultIndex (items) {
      let current = Math.floor(items.length / 2);
      let count = 0;
      while (!!items[current] && items[current].disabled) {
        current = ++current % items.length;
        count++;

        if (count > items.length) {
          throw new Error('No selectable item.')
        }
      }
      return current
    },
    getDefaultTranslate (offset, rowHeight, items) {
      const currentIndex = this.getDefaultIndex(items);
      return (offset - currentIndex) * rowHeight
    },
    getMax (offset, rowHeight) {
      return offset * rowHeight
    },
    getMin (offset, rowHeight, length) {
      return -(rowHeight * (length - offset - 1))
    }
  }
};

/* script */
            const __vue_script__$b = script$b;
            
/* template */
var __vue_render__$b = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    { staticClass: "weui-picker__group", class: [_vm.classname] },
    [
      _c("div", { staticClass: "weui-picker__indicator" }),
      _vm._v(" "),
      _c("div", {
        staticClass: "weui-picker__mask",
        on: {
          touchstart: _vm.ontouchstart,
          touchmove: _vm.ontouchmove,
          touchend: function($event) {
            $event.stopPropagation();
            return _vm.ontouchend($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "weui-picker__content" },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "weui-picker__item",
              class: { "weui-picker__item_disabled": item.disabled }
            },
            [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
          )
        }),
        0
      )
    ]
  )
};
var __vue_staticRenderFns__$b = [];
__vue_render__$b._withStripped = true;

  /* style */
  const __vue_inject_styles__$b = undefined;
  /* scoped */
  const __vue_scope_id__$b = undefined;
  /* functional template */
  const __vue_is_functional_template__$b = false;
  /* component normalizer */
  function __vue_normalize__$b(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\picker-item.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var PickerItem = __vue_normalize__$b(
    { render: __vue_render__$b, staticRenderFns: __vue_staticRenderFns__$b },
    __vue_inject_styles__$b,
    __vue_script__$b,
    __vue_scope_id__$b,
    __vue_is_functional_template__$b);

//

var script$c = {
  name: 'picker',
  components: {PickerItem},
  props: {
    id: String,
    /* ['value1/name1', 'value2/name2'] */
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    show: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: 'yxy-picker'
    },
    columns: Number,
    /* [[1,2,3],[a,b,c]] */
    data: {
      type: Array,
      default: function () {
        return [[]]
      }
    }
  },
  data () {
    return {
      currentValue: [],
      tempValue: [],
      valueChanged: false,
      lineTemp: [],
      lastItem: null
    }
  },
  computed: {
    dataCol () {
      let cols = this.data.slice(0) || [];
      let depth = this.depthOf(this.data[0][0]);
      if (depth < this.columns) {
        depth = this.columns;
      }
      while (depth > 1) {
        cols.push([]);
        --depth;
      }
      return cols
    },
    isMulti () {
      if (this.data.length > 1 && Array.isArray(this.data[1])) {
        return true
      }
      return false
    }
  },
  watch: {
    value (val) {
      if (val) {
        this.tempValue = val;
      }
    },
    tempValue (val) {
      this.$emit('input', val);
    },
    show (val) {
      if (val) {
        this.$emit('on-show', true);
      }
    }
  },
  created () {
    if (this.value) {
      this.tempValue = this.value;
    }
  },
  methods: {
    onConfirm () {
      this.$emit('on-hide', false);
      if (this.valueChanged) {
        this.valueChanged = false;
        this.tempValue = this.currentValue.slice(0);
        this.$nextTick(() => {
          this.$emit('on-change', this.currentValue);
        });
      } else {
        // 当天日期无修改也需要触发 zh_qd 2020/6/15
        this.tempValue = this.currentValue.slice(0);
        this.$nextTick(() => {
          this.$emit('on-change', this.currentValue);
        });
      }
    },
    onCancel () {
      this.$emit('on-hide', false);
    },
    onHide () {
      this.$emit('on-hide', false);
    },
    onChange (level, item, index) {
      // 为当前的 currentValue 赋值。
      if (item) {
        this.currentValue[level] = item.value;
      } else {
        this.currentValue[level] = null;
      }
      this.lineTemp[level] = index;

      this.valueChanged = false;
      this.currentValue.forEach((itemVal, index) => {
        if (this.tempValue[index] !== itemVal) {
          this.valueChanged = true;
        }
      });

      // if (this.isMulti && this.valueChanged) {
      //   this.$emit('on-change', this.currentValue)
      // } else {
      if (!this.isMulti) {
        /**
         * @子列表处理
         * 1. 在没有子列表，或者值列表的数组长度为0时，隐藏掉子列表。
         * 2. 滑动之后发现重新有子列表时，再次显示子列表。
         *
         * @回调处理
         * 1. 因为滑动实际上是一层一层传递的：父列表滚动完成之后，会call子列表的onChange，从而带动子列表的滑动。
         * 2. 所以，使用者的传进来onChange回调应该在最后一个子列表滑动时再call
         */
        let $items = this.$el.querySelectorAll('.weui-picker__group');
        let len = ($items && $items.length) || 0;
        if (item) this.lastItem = item;
        if (item && item.children && item.children.length > 0) {
          if (level + 1 < len) {
            $items[level + 1].style.display = 'block';
          }
          // 不是多列的情况下才继续处理children
          if (!this.isMulti && this.$refs['scroller-' + (level + 1)]) {
            this.$refs['scroller-' + (level + 1)][0].resetItems(item.children);
          }
        } else if (!item && level < len && this.lastItem) {
          // 处理因异步加载多列数据，Vue通过虚拟Dom变化检测刷新剩余列，无法通过列级联关系来刷新剩余列
          item = this.lastItem;
          if (!this.isMulti && this.$refs['scroller-' + level] && item.children) {
            this.$refs['scroller-' + level][0].resetItems(item.children);
          }
        } else {
          // 如果子列表test不通过，子孙列表都隐藏。
          for (var i = 0; i < len; i++) {
            if (i > level) {
              $items[i].style.display = 'none';
            }
          }

          this.currentValue.splice(level + 1);

          // if (this.valueChanged) {
          //   this.$emit('on-change', this.currentValue)
          // }
        }
      }
    },
    initTemp (level, items) {
      if (this.lineTemp[level] === undefined &&
          this.tempValue && this.tempValue[level] !== undefined) {
        // 没有缓存选项，而且存在 tempValue
        const defaultVal = this.tempValue[level];
        let index = 0;
        let len = items.length;

        for (; index < len; ++index) {
          if (defaultVal === items[index].value) break
        }
        if (index < len) {
          this.lineTemp[level] = index;
        } else {
          console.warn('Picker has not match defaultValue: ' + defaultVal);
        }
        if (this.$refs['scroller-' + level]) {
          this.$refs['scroller-' + level][0].resetTempIndex(index);
        }
      }
    },
    getTempIndex (index) {
      return this.lineTemp[index] || null
    },
    depthOf (obj) {
      let depth = 1;
      if (obj && obj.children && obj.children[0]) {
        depth = this.depthOf(obj.children[0]) + 1;
      }
      return depth
    },
    beforeDestroy () {}
  }
};

/* script */
            const __vue_script__$c = script$c;
            
/* template */
var __vue_render__$c = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      class: _vm.className
    },
    [
      _c("div", {
        staticClass: "weui-mask",
        class: [_vm.show ? "weui-animate-fade-in" : "weui-animate-fade-out"],
        on: {
          click: function($event) {
            $event.stopPropagation();
            return _vm.onHide($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "weui-picker",
          class: [
            _vm.show ? "weui-animate-slide-up" : "weui-animate-slide-down"
          ]
        },
        [
          _c("div", { staticClass: "weui-picker__hd" }, [
            _c(
              "a",
              {
                staticClass: "weui-picker__action",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    $event.stopPropagation();
                    return _vm.onCancel($event)
                  }
                }
              },
              [_vm._v("取消")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "weui-picker__action",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    $event.stopPropagation();
                    return _vm.onConfirm($event)
                  }
                }
              },
              [_vm._v("确定")]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "weui-picker__bd" },
            _vm._l(_vm.dataCol, function(items, index) {
              return _c("picker-item", {
                key: index,
                ref: "scroller-" + index,
                refInFor: true,
                attrs: {
                  data: items,
                  level: index,
                  tempIndex: _vm.getTempIndex(index)
                },
                on: {
                  "on-before-change": _vm.initTemp,
                  "on-change": _vm.onChange
                }
              })
            }),
            1
          )
        ]
      )
    ]
  )
};
var __vue_staticRenderFns__$c = [];
__vue_render__$c._withStripped = true;

  /* style */
  const __vue_inject_styles__$c = undefined;
  /* scoped */
  const __vue_scope_id__$c = undefined;
  /* functional template */
  const __vue_is_functional_template__$c = false;
  /* component normalizer */
  function __vue_normalize__$c(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\picker.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var Picker = __vue_normalize__$c(
    { render: __vue_render__$c, staticRenderFns: __vue_staticRenderFns__$c },
    __vue_inject_styles__$c,
    __vue_script__$c,
    __vue_scope_id__$c,
    __vue_is_functional_template__$c);

var clickoutside = {
  bind (el, binding, vnode) {
    function documentHandler (e) {
      if (el.contains(e.target)) {
        return false
      }
      if (binding.expression) {
        binding.value(e);
      }
    }
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener('click', documentHandler);
  },
  update () {
    //
  },
  unbind (el, binding) {
    document.removeEventListener('click', el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  }
};

//
var script$d = {
  name: 'SelectPicker',
  directives: { clickoutside },
  props: {
    value: {},
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: function () {
        return [[]]
      }
    },
    labelField: String,
    valueField: String
  },
  data () {
    return {
      showList: this.show,
      currentValue: this.value,
      currentLabel: ''
    }
  },
  computed: {
    pickerData () {
      if (Array.isArray(this.data) && this.data.length >= 1 && !Array.isArray(this.data[0])) {
        const dataA = [];
        for (let i = 0, len = this.data.length; i < len; i++) {
          dataA.push({label: this.getLabelField(this.data[i]), value: this.getValueField(this.data[i])});
        }
        return dataA
      } else if (Array.isArray(this.data) && this.data.length === 1 && Array.isArray(this.data[0])) {
        const datas = [];
        for (let i = 0, len = this.data[0].length; i < len; i++) {
          datas.push({label: this.getLabelField(this.data[0][i]), value: this.getValueField(this.data[0][i])});
        }
        return datas
      } else if (Array.isArray(this.data) && this.data.length === 2 && Array.isArray(this.data[0]) && Array.isArray(this.data[1])) {
        const data0 = this.data[0];
        const data1 = this.data[1];
        const dataR = [];
        for (let i = 0, li = data0.length; i < li; i++) {
          for (let j = 0, lj = data1.length; j < lj; j++) {
            dataR.push({label: this.getLabelField(data0[i]) + ',' + this.getLabelField(data1[j]), value: this.getValueField(data0[i]) + ',' + this.getValueField(data1[j])});
          }
        }
        return dataR
      }
      return []
    }
  },
  watch: {
    show (val) {
      this.showList = val;
    },
    value (val) {
      this.currentValue = val;
    },
    currentValue (val) {
      this.$emit('input', val);
      this.$emit('on-change', val);
    }
  },
  methods: {
    onItemClick (item) {
      this.currentValue = item.value;
      this.currentLabel = item.label;
      this.$emit('on-hide', false);
    },
    handleClose () {
      this.$emit('on-hide', false);
    },
    getLabelField (data = {}) {
      if (this.labelField && data[this.labelField]) {
        return data[this.labelField]
      } else if (typeof data === 'string') {
        return data
      } else {
        return data.name || data.label || data.title || ''
      }
    },
    getValueField (data = {}) {
      if (this.valueField && data[this.valueField]) {
        return data[this.valueField]
      } else if (typeof data === 'string') {
        return data
      } else if (typeof data.value === 'boolean') {
        return data.value
      } else {
        return data.value || data.id || data.code
      }
    }
  }
};

/* script */
            const __vue_script__$d = script$d;
            
/* template */
var __vue_render__$d = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "ul",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: _vm.handleClose,
          expression: "handleClose"
        }
      ],
      staticClass: "select-picker-list",
      class: [_vm.showList ? "slid-down" : "slid-up", { hide: !_vm.showList }]
    },
    _vm._l(_vm.pickerData, function(item) {
      return _c(
        "li",
        {
          key: item.value,
          staticClass: "select-picker-item",
          on: {
            click: function($event) {
              $event.stopPropagation();
              return _vm.onItemClick(item)
            }
          }
        },
        [_c("span", [_vm._v(_vm._s(item.label))])]
      )
    }),
    0
  )
};
var __vue_staticRenderFns__$d = [];
__vue_render__$d._withStripped = true;

  /* style */
  const __vue_inject_styles__$d = undefined;
  /* scoped */
  const __vue_scope_id__$d = undefined;
  /* functional template */
  const __vue_is_functional_template__$d = false;
  /* component normalizer */
  function __vue_normalize__$d(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\select-picker.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var SelectPicker = __vue_normalize__$d(
    { render: __vue_render__$d, staticRenderFns: __vue_staticRenderFns__$d },
    __vue_inject_styles__$d,
    __vue_script__$d,
    __vue_scope_id__$d,
    __vue_is_functional_template__$d);

//
var script$e = {
  components: {Picker, SelectPicker},
  mixins: [formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      styleObject: {},
      showPicker: false,
      pickerValue: this.getPickerValue(this.value),
      warn: false,
      disabled: this.getDisabled()
    }
  },
  computed: {
    pickerShowValue () {
      return this.pickerValue.toString()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.pickerValue = this.getPickerValue(val);
    },
    pickerShowValue (val) {
      this.$emit('on-change', val, this.item.key);
    }
  },
  methods: {
    getPickerValue (data) {
      let _data = data || {};
      let pickerVal = _data[this.item.key];

      if (!pickerVal) {
        return []
      } else {
        return pickerVal.split(',')
      }
    },
    onPickerInputClick () {
      if (this.disabled) return
      this.showPicker = true;
    },
    onFocus () {
      this.warn = false;
    },
    onPickerChange (val) {
      this.validate();
    },
    onShow (isShow) {
      this.showPicker = isShow;
    },
    onHide (isShow) {
      this.showPicker = isShow;
    },
    clearValid () {
      this.warn = false;
    },
    validate () {
      if (!this.item.required) return true
      if (!this.pickerShowValue) {
        this.warn = true;
        return false
      }
      this.warn = false;
      return true
    }
  }
};

/* script */
            const __vue_script__$e = script$e;
            
/* template */
var __vue_render__$e = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer input-inline",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c("div", { staticClass: "quest-input" }, [
        _c("span", { staticClass: "quest-label title" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "input-picker bottom-line",
            class: { warn: _vm.warn },
            on: {
              click: function($event) {
                $event.stopPropagation();
                return _vm.onPickerInputClick($event)
              },
              focus: _vm.onFocus
            }
          },
          [
            _vm._v("\n      " + _vm._s(_vm.pickerShowValue) + "\n      "),
            _vm.$device.isDesktop
              ? _c("select-picker", {
                  attrs: {
                    show: _vm.showPicker,
                    data: _vm.item.picker && _vm.item.picker.data
                  },
                  on: {
                    "on-show": _vm.onShow,
                    "on-hide": _vm.onHide,
                    "on-change": _vm.onPickerChange
                  },
                  model: {
                    value: _vm.pickerValue,
                    callback: function($$v) {
                      _vm.pickerValue = $$v;
                    },
                    expression: "pickerValue"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "input-inline-unit" }, [
          _vm._v(_vm._s(_vm.item.unit))
        ])
      ]),
      _vm._v(" "),
      !_vm.$device.isDesktop
        ? _c("picker", {
            attrs: {
              show: _vm.showPicker,
              data: _vm.item.picker && _vm.item.picker.data
            },
            on: {
              "on-show": _vm.onShow,
              "on-hide": _vm.onHide,
              "on-change": _vm.onPickerChange
            },
            model: {
              value: _vm.pickerValue,
              callback: function($$v) {
                _vm.pickerValue = $$v;
              },
              expression: "pickerValue"
            }
          })
        : _vm._e()
    ],
    1
  )
};
var __vue_staticRenderFns__$e = [];
__vue_render__$e._withStripped = true;

  /* style */
  const __vue_inject_styles__$e = undefined;
  /* scoped */
  const __vue_scope_id__$e = undefined;
  /* functional template */
  const __vue_is_functional_template__$e = false;
  /* component normalizer */
  function __vue_normalize__$e(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-picker.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputPicker = __vue_normalize__$e(
    { render: __vue_render__$e, staticRenderFns: __vue_staticRenderFns__$e },
    __vue_inject_styles__$e,
    __vue_script__$e,
    __vue_scope_id__$e,
    __vue_is_functional_template__$e);

function authenticate (options) {
  // in main.js 全局默认配置'Authorization': 'Basic YXBpOnBhc3N3b3Jk'
  const token = localStorage.getItem('token');
  if (!token) {
    return Object.assign({}, {timeout: 60000}, options)
  } else {
    let option = {
      headers: {
        'X-Requested-With': null,
        'Authorization': `Digest ${token}`
      },
      timeout: 60000
    };
    return Object.assign({}, option, options)
  }
}

function logout (cb) {
  localStorage.clear();
  if (cb) {
    cb();
  }
}

function catchExpired (err, vm) {
  if (err.status === 401) {
    logout();
    vm && vm.$router.push({name: 'login', query: {status: 'expired'}}).catch(err => err);
  } else if (err.status === 404) {
    vm.$Modal.error({
      title: '网络无法连接',
      content: `<p>${err.status} ${err.statusText}</p>
                <p>${err.body}</p>`
    });
  } else if (err.status === 403) {
    vm.$Modal.error({
      title: '服务无法访问',
      content: `<p>您没有权限进行该操作</p>`
    });
  } else if (err.status === 0) {
    setTimeout(() => {
      vm.$Message.error('网络状况不佳，请刷新页面，查看任务是否完成', 3);
    }, 500);
  }
}

//
var script$f = {
  components: {SelectPicker},
  mixins: [formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      styleObject: {},
      showPicker: false,
      query: this.getDictValue(this.value),
      queryChangeByPicker: false,
      queryChangeByHandle: true,
      pickerValue: this.getDictValue(this.value),
      dictValue: this.getDictValue(this.value),
      pickerData: [],
      warn: false,
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.pickerValue = this.getDictValue(val);
      this.dictValue = this.getDictValue(val);
    },
    query (val) {
      if (!this.queryChangeByPicker) {
        this.queryChangeByHandle = true;
        // this.pickerValue = val
        this.showPicker = true;
        this.loadData(val);
      } else {
        this.queryChangeByHandle = false;
      }
    },
    dictValue (val) {
      if (val) {
        this.$emit('on-change', val, this.item.key);
      }
    }
  },
  methods: {
    getDictValue (data) {
      let _data = data || {};
      let pickerVal = _data[this.item.key];

      return pickerVal || ''
    },
    loadData (query) {
      this.$http.get(`${this.httpRoot}/${this.item.loadDataPath}`, authenticate({
        params: Object.assign({}, {query}, this.item.params)
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.pickerData = this.getReturnData(res);
        }
      })
      .catch(err => {
        catchExpired(err, this);
      });
    },
    onFocus () {
      this.warn = false;
      if (this.disabled) return
      if (this.queryChangeByHandle) {
        this.showPicker = true;
      }
    },
    onPickerChange (val) {
      if (val) {
        this.dictValue = val;
      }
      this.queryChangeByPicker = true;
      if (this.$refs.selectPicker) {
        this.query = this.$refs.selectPicker.currentLabel;
      }
      this.$nextTick(() => {
        this.queryChangeByPicker = false;
      });
      this.validate();
    },
    onShow (isShow) {
      this.showPicker = isShow;
    },
    onHide (isShow) {
      this.showPicker = isShow;
      // if (this.query && this.dictValue && this.query !== this.dictValue) {
      //   this.query = this.dictValue
      // }
    },
    clearValid () {
      this.warn = false;
    },
    validate () {
      if (!this.item.required) return true
      if (!this.dictValue) {
        this.warn = true;
        return false
      }
      this.warn = false;
      return true
    },
    getReturnData (res) {
      let data = [];
      Object.keys(res || {}).forEach(prop => {
        if (prop !== 'status' && prop !== 'statusText' && Array.isArray(res[prop])) {
          data = res[prop];
        }
      });
      return data
    }
  }
};

/* script */
            const __vue_script__$f = script$f;
            
/* template */
var __vue_render__$f = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer input-inline",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c("div", { staticClass: "quest-input" }, [
        _c("span", { staticClass: "quest-label title" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "input-picker",
            on: {
              click: function($event) {
                $event.stopPropagation();
                return (function() {
                  return false
                })()
              }
            }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.query,
                  expression: "query"
                }
              ],
              staticClass: "picker-input",
              class: { warn: _vm.warn },
              attrs: {
                placeholder: _vm.item.placeholder,
                disabled: _vm.disabled
              },
              domProps: { value: _vm.query },
              on: {
                focus: _vm.onFocus,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.query = $event.target.value;
                }
              }
            }),
            _vm._v(" "),
            _vm.$device.isDesktop
              ? _c("select-picker", {
                  ref: "selectPicker",
                  attrs: {
                    show: _vm.showPicker,
                    data: _vm.pickerData,
                    labelField: _vm.item.labelField,
                    valueField: _vm.item.valueField
                  },
                  on: {
                    "on-show": _vm.onShow,
                    "on-hide": _vm.onHide,
                    "on-change": _vm.onPickerChange
                  },
                  model: {
                    value: _vm.pickerValue,
                    callback: function($$v) {
                      _vm.pickerValue = $$v;
                    },
                    expression: "pickerValue"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.item.unit
          ? _c("div", { staticClass: "input-inline-unit" }, [
              _vm._v(_vm._s(_vm.item.unit))
            ])
          : _vm._e()
      ])
    ]
  )
};
var __vue_staticRenderFns__$f = [];
__vue_render__$f._withStripped = true;

  /* style */
  const __vue_inject_styles__$f = undefined;
  /* scoped */
  const __vue_scope_id__$f = undefined;
  /* functional template */
  const __vue_is_functional_template__$f = false;
  /* component normalizer */
  function __vue_normalize__$f(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-dict.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputDict = __vue_normalize__$f(
    { render: __vue_render__$f, staticRenderFns: __vue_staticRenderFns__$f },
    __vue_inject_styles__$f,
    __vue_script__$f,
    __vue_scope_id__$f,
    __vue_is_functional_template__$f);

const getDayCountOfMonth = function (year, month) {
  if (month === 3 || month === 5 || month === 8 || month === 10) {
    return 30
  }

  if (month === 1) {
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      return 29
    } else {
      return 28
    }
  }

  return 31
};

const getFirstDayOfMonth = function (date) {
  const temp = new Date(date.getTime());
  temp.setDate(1);
  return temp.getDay()
};

const initTimeDate = function (isLastMonth) {
  let date = new Date();
  if (isLastMonth) {
    let year = date.getFullYear();
    let month = date.getMonth();
    if (month > 1) {
      month -= 1;
    } else if (month === 0) {
      year -= 1;
      month = 11;
    }
    date = new Date(year, month, 1);
  }
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date
};

//

const clearHours = function (time) {
  const cloneDate = new Date(time);
  cloneDate.setHours(0, 0, 0, 0);
  return cloneDate.getTime()
};

var script$g = {
  props: {
    date: {},
    year: {},
    month: {},
    disabledDate: {},
    value: {
      type: [String, Object],
      default: ''
    }
  },
  data () {
    return {
      readCells: []
    }
  },
  watch: {
    cells: {
      handler (cells) {
        this.readCells = cells;
      },
      immediate: true
    }
  },
  computed: {
    cells () {
      const date = new Date(this.year, this.month, 1);
      let day = getFirstDayOfMonth(date);    // day of first day
      day = (day === 0 ? 7 : day);
      const today = clearHours(new Date());    // timestamp of today
      const selectDay = clearHours(new Date(this.value));    // timestamp of selected day

      const dateCountOfMonth = getDayCountOfMonth(date.getFullYear(), date.getMonth());
      const dateCountOfLastMonth = getDayCountOfMonth(date.getFullYear(), (date.getMonth() === 0 ? 11 : date.getMonth() - 1));

      const disabledDate = this.disabledDate;

      let cells = [];
      const cellTmpl = {
        text: '',
        type: '',
        selected: false,
        disabled: false,
        range: false,
        start: false,
        end: false
      };
      if (day !== 7) {
        for (let i = 0; i < day; i++) {
          const cell = Object.assign({}, cellTmpl);
          cell.type = 'prev-month';
          cell.text = dateCountOfLastMonth - (day - 1) + i;

          const time = this.getPrevMothDayTime(cell.text);

          cell.disabled = typeof disabledDate === 'function' && disabledDate(new Date(time));
          cells.push(cell);
        }
      }

      for (let i = 1; i <= dateCountOfMonth; i++) {
        const cell = Object.assign({}, cellTmpl);
        const time = clearHours(new Date(this.year, this.month, i));
        cell.type = time === today ? 'today' : 'normal';
        cell.text = i;
        cell.selected = time === selectDay;
        cell.disabled = typeof disabledDate === 'function' && disabledDate(new Date(time));

        cells.push(cell);
      }

      const nextMonthCount = 42 - cells.length;
      for (let i = 1; i <= nextMonthCount; i++) {
        const cell = Object.assign({}, cellTmpl);
        cell.type = 'next-month';
        cell.text = i;

        const time = this.getNextMonthDayTime(cell.text);

        cell.disabled = typeof disabledDate === 'function' && disabledDate(new Date(time));
        cells.push(cell);
      }

      return cells
    }
  },
  methods: {
    getDateOfCell (cell) {
      let year = this.year;
      let month = this.month;
      let day = cell.text;

      const date = this.date;
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      if (cell.type === 'prev-month') {
        if (month === 0) {
          month = 11;
          year--;
        } else {
          month--;
        }
      } else if (cell.type === 'next-month') {
        if (month === 11) {
          month = 0;
          year++;
        } else {
          month++;
        }
      }

      return new Date(year, month, day, hours, minutes, seconds)
    },
    handleClick (event) {
      const target = event.target;
      if (target.tagName === 'EM') {
        const cell = this.cells[parseInt(event.target.getAttribute('index'))];
        if (cell.disabled) return

        const newDate = this.getDateOfCell(cell);

        this.$emit('on-pick', newDate);
      }
      this.$emit('on-pick-click');
    },
    getPrevMothDayTime (day) {
      let prevMonth = this.month - 1;
      let prevYear = this.year;
      if (this.month === 0) {
        prevMonth = 11;
        prevYear -= 1;
      }
      return clearHours(new Date(prevYear, prevMonth, day))
    },
    getNextMonthDayTime (day) {
      let nextMonth = this.month + 1;
      let nextYear = this.year;
      if (this.month === 11) {
        nextMonth = 0;
        nextYear += 1;
      }
      return clearHours(new Date(nextYear, nextMonth, day))
    },
    getCellCls (cell) {
      return {
        'date-picker-cells-cell-selected': cell.selected || cell.start || cell.end,
        'date-picker-cells-cell-disabled': cell.disabled,
        'date-picker-cells-cell-today': cell.type === 'today',
        'date-picker-cells-cell-prev-month': cell.type === 'prev-month',
        'date-picker-cells-cell-next-month': cell.type === 'next-month',
        'date-picker-cells-cell-range': cell.range && !cell.start && !cell.end
      }
    }
  }
};

/* script */
            const __vue_script__$g = script$g;
            
/* template */
var __vue_render__$g = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      staticClass: "date-picker-cells",
      on: {
        click: function($event) {
          $event.stopPropagation();
          return _vm.handleClick($event)
        }
      }
    },
    [
      _c("div", { staticClass: "date-picker-cells-header" }, [
        _c("span", [_vm._v(_vm._s("日"))]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s("一"))]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s("二"))]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s("三"))]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s("四"))]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s("五"))]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s("六"))])
      ]),
      _vm._v(" "),
      _vm._l(_vm.readCells, function(cell, index) {
        return _c(
          "span",
          {
            key: index,
            staticClass: "date-picker-cells-cell",
            class: _vm.getCellCls(cell)
          },
          [_c("em", { attrs: { index: index } }, [_vm._v(_vm._s(cell.text))])]
        )
      })
    ],
    2
  )
};
var __vue_staticRenderFns__$g = [];
__vue_render__$g._withStripped = true;

  /* style */
  const __vue_inject_styles__$g = undefined;
  /* scoped */
  const __vue_scope_id__$g = undefined;
  /* functional template */
  const __vue_is_functional_template__$g = false;
  /* component normalizer */
  function __vue_normalize__$g(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\datepicker\\date-table.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var DateTable = __vue_normalize__$g(
    { render: __vue_render__$g, staticRenderFns: __vue_staticRenderFns__$g },
    __vue_inject_styles__$g,
    __vue_script__$g,
    __vue_scope_id__$g,
    __vue_is_functional_template__$g);

//
//
//
//
//
//
//
//
//
//
//
//

var script$h = {
  props: {
    date: {},
    year: {},
    disabledDate: {},
    selectionMode: {
      default: 'year'
    }
  },
  computed: {
    startYear () {
      return Math.floor(this.year / 10) * 10
    },
    cells () {
      let cells = [];
      const cellTmpl = {
        text: '',
        selected: false,
        disabled: false
      };

      for (let i = 0; i < 10; i++) {
        const cell = JSON.parse(JSON.stringify(cellTmpl));
        cell.text = this.startYear + i;

        const date = new Date(this.date);
        date.setFullYear(cell.text);
        cell.disabled = typeof this.disabledDate === 'function' && this.disabledDate(date) && this.selectionMode === 'year';

        cell.selected = Number(this.year) === cell.text;
        cells.push(cell);
      }

      return cells
    }
  },
  methods: {
    nextTenYear () {
      this.$emit('on-pick', Number(this.year) + 10, false);
    },
    prevTenYear () {
      this.$emit('on-pick', Number(this.year) - 10, false);
    },
    handleClick (event) {
      const target = event.target;
      if (target.tagName === 'EM') {
        const cell = this.cells[parseInt(event.target.getAttribute('index'))];
        if (cell.disabled) return

        this.$emit('on-pick', cell.text);
      }
      this.$emit('on-pick-click');
    }
  }
};

/* script */
            const __vue_script__$h = script$h;
            
/* template */
var __vue_render__$h = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      staticClass: "date-picker-cells date-picker-cells-year",
      on: { click: _vm.handleClick }
    },
    _vm._l(_vm.cells, function(cell, index) {
      return _c(
        "span",
        {
          key: index,
          staticClass: "date-picker-cells-cell",
          class: {
            "date-picker-cells-cell-selected": cell.selected,
            "date-picker-cells-cell-disabled": cell.disabled
          }
        },
        [_c("em", { attrs: { index: index } }, [_vm._v(_vm._s(cell.text))])]
      )
    }),
    0
  )
};
var __vue_staticRenderFns__$h = [];
__vue_render__$h._withStripped = true;

  /* style */
  const __vue_inject_styles__$h = undefined;
  /* scoped */
  const __vue_scope_id__$h = undefined;
  /* functional template */
  const __vue_is_functional_template__$h = false;
  /* component normalizer */
  function __vue_normalize__$h(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\datepicker\\year-table.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var YearTable = __vue_normalize__$h(
    { render: __vue_render__$h, staticRenderFns: __vue_staticRenderFns__$h },
    __vue_inject_styles__$h,
    __vue_script__$h,
    __vue_scope_id__$h,
    __vue_is_functional_template__$h);

//
//
//
//
//
//
//
//
//
//
//
//

var script$i = {
  props: {
    date: {},
    month: {
      type: Number
    },
    disabledDate: {},
    selectionMode: {
      default: 'month'
    }
  },
  data () {
    return {
      months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    }
  },
  computed: {
    cells () {
      let cells = [];
      const cellTmpl = {
        text: '',
        selected: false,
        disabled: false
      };

      for (let i = 0; i < 12; i++) {
        const cell = JSON.parse(JSON.stringify(cellTmpl));
        cell.text = i + 1;

        const date = new Date(this.date);
        date.setMonth(i);
        cell.disabled = typeof this.disabledDate === 'function' && this.disabledDate(date) && this.selectionMode === 'month';

        cell.selected = Number(this.month) === i;
        cells.push(cell);
      }

      return cells
    }
  },
  methods: {
    handleClick (event) {
      const target = event.target;
      if (target.tagName === 'EM') {
        const index = parseInt(event.target.getAttribute('index'));
        const cell = this.cells[index];
        if (cell.disabled) return

        this.$emit('on-pick', index);
      }
      this.$emit('on-pick-click');
    }
  }
};

/* script */
            const __vue_script__$i = script$i;
            
/* template */
var __vue_render__$i = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      staticClass: "date-picker-cells date-picker-cells-month",
      on: { click: _vm.handleClick }
    },
    _vm._l(_vm.cells, function(cell, index) {
      return _c(
        "span",
        {
          key: index,
          staticClass: "date-picker-cells-cell",
          class: {
            "date-picker-cells-cell-selected": cell.selected,
            "date-picker-cells-cell-disabled": cell.disabled
          }
        },
        [
          _c("em", { attrs: { index: index } }, [
            _vm._v(_vm._s(_vm.months[index]))
          ])
        ]
      )
    }),
    0
  )
};
var __vue_staticRenderFns__$i = [];
__vue_render__$i._withStripped = true;

  /* style */
  const __vue_inject_styles__$i = undefined;
  /* scoped */
  const __vue_scope_id__$i = undefined;
  /* functional template */
  const __vue_is_functional_template__$i = false;
  /* component normalizer */
  function __vue_normalize__$i(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\datepicker\\month-table.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var MonthTable = __vue_normalize__$i(
    { render: __vue_render__$i, staticRenderFns: __vue_staticRenderFns__$i },
    __vue_inject_styles__$i,
    __vue_script__$i,
    __vue_scope_id__$i,
    __vue_is_functional_template__$i);

var Options = {
  props: {
    disabledHours: {
      type: Array,
      default () {
        return []
      }
    },
    disabledMinutes: {
      type: Array,
      default () {
        return []
      }
    },
    disabledSeconds: {
      type: Array,
      default () {
        return []
      }
    },
    hideDisabledOptions: {
      type: Boolean,
      default: false
    }
  }
};

// 判断参数是否是其中之一
function oneOf (value, validList) {
    for (let i = 0; i < validList.length; i++) {
        if (value === validList[i]) {
            return true;
        }
    }
    return false;
}

// For Modal scrollBar hidden
let cached;
function getScrollBarSize (fresh) {
    if (fresh || cached === undefined) {
        const inner = document.createElement('div');
        inner.style.width = '100%';
        inner.style.height = '200px';

        const outer = document.createElement('div');
        const outerStyle = outer.style;

        outerStyle.position = 'absolute';
        outerStyle.top = 0;
        outerStyle.left = 0;
        outerStyle.pointerEvents = 'none';
        outerStyle.visibility = 'hidden';
        outerStyle.width = '200px';
        outerStyle.height = '150px';
        outerStyle.overflow = 'hidden';

        outer.appendChild(inner);

        document.body.appendChild(outer);

        const widthContained = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        let widthScroll = inner.offsetWidth;

        if (widthContained === widthScroll) {
            widthScroll = outer.clientWidth;
        }

        document.body.removeChild(outer);

        cached = widthContained - widthScroll;
    }
    return cached;
}

// firstUpperCase
function firstUpperCase(str) {
    return str.toString()[0].toUpperCase() + str.toString().slice(1);
}

function typeOf(obj) {
    const toString = Object.prototype.toString;
    const map = {
        '[object Boolean]'  : 'boolean',
        '[object Number]'   : 'number',
        '[object String]'   : 'string',
        '[object Function]' : 'function',
        '[object Array]'    : 'array',
        '[object Date]'     : 'date',
        '[object RegExp]'   : 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]'     : 'null',
        '[object Object]'   : 'object'
    };
    return map[toString.call(obj)];
}

// deepCopy
function deepCopy(data) {
    const t = typeOf(data);
    let o;

    if (t === 'array') {
        o = [];
    } else if ( t === 'object') {
        o = {};
    } else {
        return data;
    }

    if (t === 'array') {
        for (let i = 0; i < data.length; i++) {
            o.push(deepCopy(data[i]));
        }
    } else if ( t === 'object') {
        for (let i in data) {
            o[i] = deepCopy(data[i]);
        }
    }
    return o;
}

// scrollTop animation
function scrollTop(el, from = 0, to, duration = 500) {
    if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = (
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            function (callback) {
                return window.setTimeout(callback, 1000/60);
            }
        );
    }
    const difference = Math.abs(from - to);
    const step = Math.ceil(difference / duration * 50);

    function scroll(start, end, step) {
        if (start === end) return;

        let d = (start + step > end) ? end : start + step;
        if (start > end) {
            d = (start - step < end) ? end : start - step;
        }

        if (el === window) {
            window.scrollTo(d, d);
        } else {
            el.scrollTop = d;
        }
        window.requestAnimationFrame(() => scroll(d, end, step));
    }
    scroll(from, to, step);
}

//

var script$j = {
  mixins: [Options],
  props: {
    hours: {
      type: [Number, String],
      default: 0
    },
    minutes: {
      type: [Number, String],
      default: 0
    },
    seconds: {
      type: [Number, String],
      default: 0
    },
    showSeconds: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      compiled: false
    }
  },
  computed: {
    hoursList () {
      let hours = [];
      const hourTmpl = {
        text: 0,
        selected: false,
        disabled: false,
        hide: false
      };

      for (let i = 0; i < 24; i++) {
        const hour = deepCopy(hourTmpl);
        hour.text = i;

        if (this.disabledHours.length && this.disabledHours.indexOf(i) > -1) {
          hour.disabled = true;
          if (this.hideDisabledOptions) hour.hide = true;
        }
        if (this.hours === i) hour.selected = true;
        hours.push(hour);
      }

      return hours
    },
    minutesList () {
      let minutes = [];
      const minuteTmpl = {
        text: 0,
        selected: false,
        disabled: false,
        hide: false
      };

      for (let i = 0; i < 60; i++) {
        const minute = deepCopy(minuteTmpl);
        minute.text = i;

        if (this.disabledMinutes.length && this.disabledMinutes.indexOf(i) > -1) {
          minute.disabled = true;
          if (this.hideDisabledOptions) minute.hide = true;
        }
        if (this.minutes === i) minute.selected = true;
        minutes.push(minute);
      }

      return minutes
    },
    secondsList () {
      let seconds = [];
      const secondTmpl = {
        text: 0,
        selected: false,
        disabled: false,
        hide: false
      };

      for (let i = 0; i < 60; i++) {
        const second = deepCopy(secondTmpl);
        second.text = i;

        if (this.disabledSeconds.length && this.disabledSeconds.indexOf(i) > -1) {
          second.disabled = true;
          if (this.hideDisabledOptions) second.hide = true;
        }
        if (this.seconds === i) second.selected = true;
        seconds.push(second);
      }

      return seconds
    }
  },
  watch: {
    hours (val) {
      if (!this.compiled) return
      this.scroll('hours', val);
    },
    minutes (val) {
      if (!this.compiled) return
      this.scroll('minutes', val);
    },
    seconds (val) {
      if (!this.compiled) return
      this.scroll('seconds', val);
    }
  },
  mounted () {
    this.updateScroll();
    this.$nextTick(() => {
      this.compiled = true;
    });
  },
  methods: {
    getCellCls (cell) {
      return {
        'time-picker-cells-cell-selected': cell.selected,
        'time-picker-cells-cell-disabled': cell.disabled
      }
    },
    handleClickHours (event) {
      this.handleClick('hours', event);
    },
    handleClickMinutes (event) {
      this.handleClick('minutes', event);
    },
    handleClickSeconds (event) {
      this.handleClick('seconds', event);
    },
    handleClick (type, event) {
      const target = event.target;
      if (target.tagName === 'LI') {
        const cell = this[`${type}List`][parseInt(event.target.getAttribute('index'))];
        if (cell.disabled) return
        const data = {};
        data[type] = cell.text;
        this.$emit('on-change', data);
      }
      this.$emit('on-pick-click');
    },
    scroll (type, index) {
      const from = this.$refs[type].scrollTop;
      const to = 24 * this.getScrollIndex(type, index);
      scrollTop(this.$refs[type], from, to, 500);
    },
    getScrollIndex (type, index) {
      const Type = firstUpperCase(type);
      const disabled = this[`disabled${Type}`];
      if (disabled.length && this.hideDisabledOptions) {
        let _count = 0;
        disabled.forEach(item => item <= index ? _count++ : '');
        index -= _count;
      }
      return index
    },
    updateScroll () {
      const times = ['hours', 'minutes', 'seconds'];
      this.$nextTick(() => {
        times.forEach(type => {
          this.$refs[type].scrollTop = 24 * this.getScrollIndex(type, this[type]);
        });
      });
    },
    formatTime (text) {
      return text < 10 ? '0' + text : text
    }
  }
};

/* script */
            const __vue_script__$j = script$j;
            
/* template */
var __vue_render__$j = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      staticClass: "time-picker-cells",
      class: { "time-picker-cells-with-seconds": _vm.showSeconds }
    },
    [
      _c("div", { ref: "hours", staticClass: "time-picker-cells-list" }, [
        _c(
          "ul",
          {
            staticClass: "time-picker-cells-ul",
            on: { click: _vm.handleClickHours }
          },
          _vm._l(_vm.hoursList, function(item, index) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !item.hide,
                    expression: "!item.hide"
                  }
                ],
                key: index,
                staticClass: "time-picker-cells-cell",
                class: _vm.getCellCls(item),
                attrs: { index: index }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.formatTime(item.text)) + "\n      "
                )
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { ref: "minutes", staticClass: "time-picker-cells-list" }, [
        _c(
          "ul",
          {
            staticClass: "time-picker-cells-ul",
            on: { click: _vm.handleClickMinutes }
          },
          _vm._l(_vm.minutesList, function(item, index) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !item.hide,
                    expression: "!item.hide"
                  }
                ],
                key: index,
                staticClass: "time-picker-cells-cell",
                class: _vm.getCellCls(item),
                attrs: { index: index }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.formatTime(item.text)) + "\n      "
                )
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSeconds,
              expression: "showSeconds"
            }
          ],
          ref: "seconds",
          staticClass: "time-picker-cells-list"
        },
        [
          _c(
            "ul",
            {
              staticClass: "time-picker-cells-ul",
              on: { click: _vm.handleClickSeconds }
            },
            _vm._l(_vm.secondsList, function(item, index) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !item.hide,
                      expression: "!item.hide"
                    }
                  ],
                  key: index,
                  staticClass: "time-picker-cells-cell",
                  class: _vm.getCellCls(item),
                  attrs: { index: index }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.formatTime(item.text)) +
                      "\n      "
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    ]
  )
};
var __vue_staticRenderFns__$j = [];
__vue_render__$j._withStripped = true;

  /* style */
  const __vue_inject_styles__$j = undefined;
  /* scoped */
  const __vue_scope_id__$j = undefined;
  /* functional template */
  const __vue_is_functional_template__$j = false;
  /* component normalizer */
  function __vue_normalize__$j(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\datepicker\\time-spinner.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var TimeSpinner = __vue_normalize__$j(
    { render: __vue_render__$j, staticRenderFns: __vue_staticRenderFns__$j },
    __vue_inject_styles__$j,
    __vue_script__$j,
    __vue_scope_id__$j,
    __vue_is_functional_template__$j);

var Mixin = {
  methods: {
    iconBtnCls (direction, type = '') {
      return [
        'picker-panel-icon-btn',
        `date-picker-${direction}-btn`,
        `date-picker-${direction}-btn-arrow${type}`
      ]
    },
    handleShortcutClick (shortcut) {
      if (shortcut.value) this.$emit('on-pick', shortcut.value());
      if (shortcut.onClick) shortcut.onClick(this);
    },
    handlePickClear () {
      this.$emit('on-pick-clear');
    },
    handlePickSuccess () {
      this.$emit('on-pick-success');
    },
    handlePickClick () {
      this.$emit('on-pick-click');
    }
  }
};

//

const months = ['1 月', '2 月', '3 月', '4 月', '5 月', '6 月', '7 月', '8 月', '9 月', '10 月', '11 月', '12 月'];

var script$k = {
  mixins: [ Mixin ],
  components: { TimeSpinner },

  data () {
    return {
      date: initTimeDate(),
      value: '',
      showDate: false,
      format: 'hh:mm:ss',
      hours: '',
      minutes: '',
      seconds: '',
      disabledHours: [],
      disabledMinutes: [],
      disabledSeconds: [],
      hideDisabledOptions: false,
      confirm: false
    }
  },
  computed: {
    showSeconds () {
      return (this.format || '').indexOf('ss') !== -1
    },
    visibleDate () {
      const date = this.date;
      const month = date.getMonth();
      const tYear = '年';
      const tMonth = months[month];
      const tDay = '日';
      return `${date.getFullYear()}${tYear} ${tMonth} ${date.getDate()} ${tDay}`
    }
  },
  watch: {
    value (newVal) {
      if (!newVal) return
      newVal = new Date(newVal);
      if (!isNaN(newVal)) {
        this.date = newVal;
        this.handleChange({
          hours: newVal.getHours(),
          minutes: newVal.getMinutes(),
          seconds: newVal.getSeconds()
        }, false);
      }
    }
  },
  methods: {
    handleClear () {
      this.date = initTimeDate();
      this.hours = '';
      this.minutes = '';
      this.seconds = '';
    },
    handleChange (date, emit = true) {
      if (date.hours !== undefined) {
        this.date.setHours(date.hours);
        this.hours = this.date.getHours();
      }
      if (date.minutes !== undefined) {
        this.date.setMinutes(date.minutes);
        this.minutes = this.date.getMinutes();
      }
      if (date.seconds !== undefined) {
        this.date.setSeconds(date.seconds);
        this.seconds = this.date.getSeconds();
      }
      if (emit) this.$emit('on-pick', this.date, true);
    },
    updateScroll () {
      this.$refs.timeSpinner.updateScroll();
    }
  }
};

/* script */
            const __vue_script__$k = script$k;
            
/* template */
var __vue_render__$k = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "picker-panel-body-wrapper" }, [
    _c("div", { staticClass: "picker-panel-body" }, [
      _c("div", { staticClass: "time-picker-header" }, [
        _vm._v(_vm._s(_vm.visibleDate))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "picker-panel-content" },
        [
          _c("time-spinner", {
            ref: "timeSpinner",
            attrs: {
              "show-seconds": _vm.showSeconds,
              hours: _vm.hours,
              minutes: _vm.minutes,
              seconds: _vm.seconds,
              "disabled-hours": _vm.disabledHours,
              "disabled-minutes": _vm.disabledMinutes,
              "disabled-seconds": _vm.disabledSeconds,
              "hide-disabled-options": _vm.hideDisabledOptions
            },
            on: {
              "on-change": _vm.handleChange,
              "on-pick-click": _vm.handlePickClick
            }
          })
        ],
        1
      )
    ])
  ])
};
var __vue_staticRenderFns__$k = [];
__vue_render__$k._withStripped = true;

  /* style */
  const __vue_inject_styles__$k = undefined;
  /* scoped */
  const __vue_scope_id__$k = undefined;
  /* functional template */
  const __vue_is_functional_template__$k = false;
  /* component normalizer */
  function __vue_normalize__$k(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\datepicker\\time.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var TimePicker = __vue_normalize__$k(
    { render: __vue_render__$k, staticRenderFns: __vue_staticRenderFns__$k },
    __vue_inject_styles__$k,
    __vue_script__$k,
    __vue_scope_id__$k,
    __vue_is_functional_template__$k);

//
//
//
//

var script$l = {
  props: {
    type: String,
    size: [Number, String],
    color: String
  }
};

/* script */
            const __vue_script__$l = script$l;
            
/* template */
var __vue_render__$l = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("i", {
    class: ["ionic-icon", "ionic-icon-" + _vm.type],
    style: { color: _vm.color, fontSize: _vm.size ? _vm.size + "px" : false }
  })
};
var __vue_staticRenderFns__$l = [];
__vue_render__$l._withStripped = true;

  /* style */
  const __vue_inject_styles__$l = undefined;
  /* scoped */
  const __vue_scope_id__$l = undefined;
  /* functional template */
  const __vue_is_functional_template__$l = false;
  /* component normalizer */
  function __vue_normalize__$l(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\icon\\icon.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var Icon = __vue_normalize__$l(
    { render: __vue_render__$l, staticRenderFns: __vue_staticRenderFns__$l },
    __vue_inject_styles__$l,
    __vue_script__$l,
    __vue_scope_id__$l,
    __vue_is_functional_template__$l);

//

var script$m = {
  components: { Icon },
  props: {
    type: {
      validator (value) {
        return oneOf(value, ['primary', 'ghost', 'dashed', 'text', 'info', 'success', 'warning', 'error'])
      }
    },
    shape: {
      validator (value) {
        return oneOf(value, ['circle', 'circle-outline'])
      }
    },
    size: {
      validator (value) {
        return oneOf(value, ['small', 'large'])
      }
    },
    loading: Boolean,
    disabled: Boolean,
    htmlType: {
      validator (value) {
        return oneOf(value, ['button', 'submit', 'reset'])
      },
      default: 'button'
    },
    emphasis: {
      type: Boolean,
      default: false
    },
    icon: String,
    long: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showSlot: true
    }
  },
  mounted () {
    this.showSlot = this.$refs.slot.innerHTML.replace(/\n/g, '').replace(/<!--[\w\W\r\n]*?-->/gmi, '') !== '';
  }
};

/* script */
            const __vue_script__$m = script$m;
            
/* template */
var __vue_render__$m = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "button",
    {
      class: [
        "btn",
        ((_obj = {
          emphasis: _vm.emphasis,
          "btn-long": _vm.long,
          "btn-loading": _vm.loading,
          "btn-icon-only": !_vm.showSlot && (!!_vm.icon || _vm.loading)
        }),
        (_obj["btn-" + _vm.type] = !!_vm.type),
        (_obj["btn-" + _vm.shape] = !!_vm.shape),
        (_obj["btn-" + _vm.size] = !!_vm.size),
        _obj)
      ],
      attrs: { type: _vm.htmlType, disabled: _vm.disabled }
    },
    [
      _vm.loading
        ? _c("Icon", { staticClass: "load-loop", attrs: { type: "load-c" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.icon && !_vm.loading
        ? _c("Icon", { attrs: { type: _vm.icon } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSlot
        ? _c("span", { ref: "slot" }, [_vm._t("default")], 2)
        : _vm._e()
    ],
    1
  )
  var _obj;
};
var __vue_staticRenderFns__$m = [];
__vue_render__$m._withStripped = true;

  /* style */
  const __vue_inject_styles__$m = undefined;
  /* scoped */
  const __vue_scope_id__$m = undefined;
  /* functional template */
  const __vue_is_functional_template__$m = false;
  /* component normalizer */
  function __vue_normalize__$m(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\button\\button.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var YButton = __vue_normalize__$m(
    { render: __vue_render__$m, staticRenderFns: __vue_staticRenderFns__$m },
    __vue_inject_styles__$m,
    __vue_script__$m,
    __vue_scope_id__$m,
    __vue_is_functional_template__$m);

//

var script$n = {
  components: { YButton },
  props: {
    showTime: {
      type: Boolean,
      default: false
    },
    isTime: {
      type: Boolean,
      default: false
    },
    timeDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClear () {
      this.$emit('on-pick-clear');
    },
    handleSuccess () {
      this.$emit('on-pick-success');
    },
    handleToggleTime () {
      if (this.timeDisabled) return
      this.$emit('on-pick-toggle-time');
    }
  }
};

/* script */
            const __vue_script__$n = script$n;
            
/* template */
var __vue_render__$n = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    { staticClass: "picker-confirm" },
    [
      _vm.showTime
        ? _c(
            "span",
            {
              class: { "picker-confirm-time-disabled": _vm.timeDisabled },
              on: { click: _vm.handleToggleTime }
            },
            [
              _vm.isTime
                ? [_vm._v(_vm._s("选择日期"))]
                : [_vm._v(_vm._s("选择时间"))]
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "y-button",
        {
          attrs: { size: "small", type: "text" },
          nativeOn: {
            click: function($event) {
              $event.stopPropagation();
              return _vm.handleClear($event)
            }
          }
        },
        [_vm._v(_vm._s("清空"))]
      ),
      _vm._v(" "),
      _c(
        "y-button",
        {
          attrs: { size: "small", type: "primary" },
          nativeOn: {
            click: function($event) {
              $event.stopPropagation();
              return _vm.handleSuccess($event)
            }
          }
        },
        [_vm._v(_vm._s("确定"))]
      )
    ],
    1
  )
};
var __vue_staticRenderFns__$n = [];
__vue_render__$n._withStripped = true;

  /* style */
  const __vue_inject_styles__$n = undefined;
  /* scoped */
  const __vue_scope_id__$n = undefined;
  /* functional template */
  const __vue_is_functional_template__$n = false;
  /* component normalizer */
  function __vue_normalize__$n(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\datepicker\\confirm.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var Confirm = __vue_normalize__$n(
    { render: __vue_render__$n, staticRenderFns: __vue_staticRenderFns__$n },
    __vue_inject_styles__$n,
    __vue_script__$n,
    __vue_scope_id__$n,
    __vue_is_functional_template__$n);

//

const months$1 = ['1 月', '2 月', '3 月', '4 月', '5 月', '6 月', '7 月', '8 月', '9 月', '10 月', '11 月', '12 月'];

var script$o = {
  name: 'DatePicker',
  mixins: [ Mixin ],
  components: { DateTable, YearTable, MonthTable, TimePicker, Confirm },
  props: {
    type: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd'
    }
  },
  data () {
    return {
      currentView: 'date',
      date: initTimeDate(),
      value: '',
      showTime: true,
      selectionMode: 'day',
      disabledDate: '',
      year: null,
      month: null,
      confirm: false,
      isTime: false
    }
  },
  computed: {
    yearLabel () {
      const tYear = '年';
      const year = this.year;
      if (!year) return ''
      if (this.currentView === 'year') {
        const startYear = Math.floor(year / 10) * 10;
        return `${startYear}${tYear} - ${startYear + 9}${tYear}`
      }
      return `${year}${tYear}`
    },
    monthLabel () {
      const month = this.month;
      return months$1[month]
    }
  },
  watch: {
    value (newVal) {
      if (!newVal) return
      newVal = new Date(newVal);
      if (!isNaN(newVal)) {
        this.date = newVal;
        this.year = newVal.getFullYear();
        this.month = newVal.getMonth();
      }
      if (this.showTime) this.$refs.timePicker.value = newVal;
    },
    date (val) {
      if (this.showTime) this.$refs.timePicker.date = val;
    },
    format (val) {
      if (this.showTime) this.$refs.timePicker.format = val;
    },
    currentView (val) {
      if (val === 'time') this.$refs.timePicker.updateScroll();
    }
  },
  mounted () {
    if (this.selectionMode === 'month') {
      this.currentView = 'month';
    }

    if (this.date && !this.year) {
      this.year = this.date.getFullYear();
      this.month = this.date.getMonth();
    }
    if (this.type !== 'datetime') {
      this.showTime = false;
    }
    if (this.showTime) {
      this.$refs.timePicker.date = this.date;
      this.$refs.timePicker.value = this.value;
      this.$refs.timePicker.format = this.format;
      this.$refs.timePicker.showDate = true;
    }
  },
  methods: {
    resetDate () {
      this.date = new Date(this.date);
    },
    handleClear () {
      const date = new Date();
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      this.date = date;
      this.$emit('on-pick', '');
      if (this.showTime) this.$refs.timePicker.handleClear();
    },
    initView () {
      if (this.selectionMode === 'day') {
        this.currentView = 'date';
      }
    },
    resetView (reset = false) {
      if (this.currentView !== 'time' || reset) {
        if (this.selectionMode === 'month') {
          this.currentView = 'month';
        } else if (this.selectionMode === 'year') {
          this.currentView = 'year';
        } else {
          this.currentView = 'date';
        }
      }

      this.year = this.date.getFullYear();
      this.month = this.date.getMonth();
      if (reset) this.isTime = false;
    },
    prevYear () {
      if (this.currentView === 'year') {
        this.$refs.yearTable.prevTenYear();
      } else {
        this.year--;
        this.date.setFullYear(this.year);
        this.resetDate();
      }
    },
    nextYear () {
      if (this.currentView === 'year') {
        this.$refs.yearTable.nextTenYear();
      } else {
        this.year++;
        this.date.setFullYear(this.year);
        this.resetDate();
      }
    },
    prevMonth () {
      this.month--;
      if (this.month < 0) {
        this.month = 11;
        this.year--;
      }
    },
    nextMonth () {
      this.month++;
      if (this.month > 11) {
        this.month = 0;
        this.year++;
      }
    },
    showYearPicker () {
      this.currentView = 'year';
    },
    showMonthPicker () {
      this.currentView = 'month';
    },
    handleToggleTime () {
      if (this.currentView === 'date') {
        this.currentView = 'time';
        this.isTime = true;
      } else if (this.currentView === 'time') {
        this.currentView = 'date';
        this.isTime = false;
      }
    },
    handleYearPick (year, close = true) {
      this.year = year;
      if (!close) return

      this.date.setFullYear(year);
      if (this.selectionMode === 'year') {
        this.$emit('on-pick', new Date(year, 0, 1));
      } else {
        this.currentView = 'month';
      }

      this.resetDate();
    },
    handleMonthPick (month) {
      this.month = month;
      const selectionMode = this.selectionMode;
      if (selectionMode !== 'month') {
        this.date.setMonth(month);
        this.currentView = 'date';
        this.resetDate();
      } else {
        this.date.setMonth(month);
        this.year && this.date.setFullYear(this.year);
        this.resetDate();
        const value = new Date(this.date.getFullYear(), month, 1);
        this.$emit('on-pick', value);
      }
    },
    handleDatePick (value) {
      if (this.selectionMode === 'day') {
        this.$emit('on-pick', new Date(value.getTime()));
        this.date.setFullYear(value.getFullYear());
        this.date.setMonth(value.getMonth());
        this.date.setDate(value.getDate());
        // 更新 value 让 date-table 重新 render cell
        // 更新 timePicker.value
        this.value = value.format(this.format);
      }

      this.resetDate();
    },
    handleTimePick (date) {
      this.handleDatePick(date);
    }
  }
};

/* script */
            const __vue_script__$o = script$o;
            
/* template */
var __vue_render__$o = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "picker-panel-body-wrapper" }, [
    _c(
      "div",
      { staticClass: "picker-panel-body" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentView !== "time",
                expression: "currentView !== 'time'"
              }
            ],
            staticClass: "date-picker-header"
          },
          [
            _c(
              "span",
              {
                class: _vm.iconBtnCls("prev", "-double"),
                on: { click: _vm.prevYear }
              },
              [_c("i", { staticClass: "ionic-icon ionic-icon-ios-arrow-left" })]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentView === "date",
                    expression: "currentView === 'date'"
                  }
                ],
                class: _vm.iconBtnCls("prev"),
                on: { click: _vm.prevMonth }
              },
              [_c("i", { staticClass: "ionic-icon ionic-icon-ios-arrow-left" })]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "date-picker-header-label",
                on: { click: _vm.showYearPicker }
              },
              [_vm._v(_vm._s(_vm.yearLabel))]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentView === "date",
                    expression: "currentView === 'date'"
                  }
                ],
                staticClass: "date-picker-header-label",
                on: { click: _vm.showMonthPicker }
              },
              [_vm._v(_vm._s(_vm.monthLabel))]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                class: _vm.iconBtnCls("next", "-double"),
                on: { click: _vm.nextYear }
              },
              [
                _c("i", {
                  staticClass: "ionic-icon ionic-icon-ios-arrow-right"
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentView === "date",
                    expression: "currentView === 'date'"
                  }
                ],
                class: _vm.iconBtnCls("next"),
                on: { click: _vm.nextMonth }
              },
              [
                _c("i", {
                  staticClass: "ionic-icon ionic-icon-ios-arrow-right"
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "picker-panel-content" },
          [
            _c("date-table", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentView === "date",
                  expression: "currentView === 'date'"
                }
              ],
              attrs: {
                year: _vm.year,
                month: _vm.month,
                date: _vm.date,
                value: _vm.value,
                "selection-mode": _vm.selectionMode,
                "disabled-date": _vm.disabledDate
              },
              on: {
                "on-pick": _vm.handleDatePick,
                "on-pick-click": _vm.handlePickClick
              }
            }),
            _vm._v(" "),
            _c("year-table", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentView === "year",
                  expression: "currentView === 'year'"
                }
              ],
              ref: "yearTable",
              attrs: {
                year: _vm.year,
                date: _vm.date,
                "selection-mode": _vm.selectionMode,
                "disabled-date": _vm.disabledDate
              },
              on: {
                "on-pick": _vm.handleYearPick,
                "on-pick-click": _vm.handlePickClick
              }
            }),
            _vm._v(" "),
            _c("month-table", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentView === "month",
                  expression: "currentView === 'month'"
                }
              ],
              ref: "monthTable",
              attrs: {
                month: _vm.month,
                date: _vm.date,
                "selection-mode": _vm.selectionMode,
                "disabled-date": _vm.disabledDate
              },
              on: {
                "on-pick": _vm.handleMonthPick,
                "on-pick-click": _vm.handlePickClick
              }
            }),
            _vm._v(" "),
            _c("time-picker", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentView === "time",
                  expression: "currentView === 'time'"
                }
              ],
              ref: "timePicker",
              on: {
                "on-pick": _vm.handleTimePick,
                "on-pick-click": _vm.handlePickClick
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.type === "datetime"
          ? _c("Confirm", {
              attrs: { showTime: _vm.showTime, isTime: _vm.isTime },
              on: {
                "on-pick-toggle-time": _vm.handleToggleTime,
                "on-pick-clear": _vm.handlePickClear,
                "on-pick-success": _vm.handlePickSuccess
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
};
var __vue_staticRenderFns__$o = [];
__vue_render__$o._withStripped = true;

  /* style */
  const __vue_inject_styles__$o = undefined;
  /* scoped */
  const __vue_scope_id__$o = undefined;
  /* functional template */
  const __vue_is_functional_template__$o = false;
  /* component normalizer */
  function __vue_normalize__$o(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\datepicker\\date.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var YDate = __vue_normalize__$o(
    { render: __vue_render__$o, staticRenderFns: __vue_staticRenderFns__$o },
    __vue_inject_styles__$o,
    __vue_script__$o,
    __vue_scope_id__$o,
    __vue_is_functional_template__$o);

//
var script$p = {
  components: {YDate},
  props: {
    value: {},
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'date'
    }
  },
  data () {
    let format = this.type === 'datetime' ? 'yyyy-MM-dd hh:mm:ss' : 'yyyy-MM-dd';
    return {
      visible: false,
      format: format,
      currentValue: this.value || ''
    }
  },
  watch: {
    value (val) {
      if (val) {
        this.currentValue = val;
      }
    },
    currentValue (val) {
      this.$emit('input', val);
      this.$emit('on-change', val);
    },
    show (val) {
      this.visible = val;
    },
    visible (val) {
      if (val && this.$refs.date) {
        this.$refs.date.value = this.currentValue;
        this.$refs.date.initView();
      }
    }
  },
  methods: {
    onPickChange (date, visible = false) {
      this.currentValue = date;
      if (typeof date === 'object') {
        this.currentValue = date.format(this.format);
      }
      if (!visible && this.type !== 'datetime') {
        this.visible = visible;
        this.$emit('on-hide');
      }
    },
    onPickClick () {
      //
    },
    onPickSuccess () {
      this.onPickConfirm();
    },
    onPickClear () {
      this.$refs.date.handleClear();
      this.visible = false;
      this.internalValue = '';
      this.currentValue = '';
      this.$emit('on-hide');
    },
    onPickConfirm () {
      this.visible = false;
      this.$emit('on-hide');
    }
  }
};

/* script */
            const __vue_script__$p = script$p;
            
/* template */
var __vue_render__$p = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      staticClass: "pc-date-picker-dropdown"
    },
    [
      _c("y-date", {
        ref: "date",
        attrs: { type: _vm.type, format: _vm.format },
        on: {
          "on-pick": _vm.onPickChange,
          "on-pick-click": _vm.onPickClick,
          "on-pick-clear": _vm.onPickClear,
          "on-pick-confirm": _vm.onPickConfirm,
          "on-pick-success": _vm.onPickSuccess
        }
      })
    ],
    1
  )
};
var __vue_staticRenderFns__$p = [];
__vue_render__$p._withStripped = true;

  /* style */
  const __vue_inject_styles__$p = undefined;
  /* scoped */
  const __vue_scope_id__$p = undefined;
  /* functional template */
  const __vue_is_functional_template__$p = false;
  /* component normalizer */
  function __vue_normalize__$p(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\datepicker\\pc-date-picker.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var PcDatePicker = __vue_normalize__$p(
    { render: __vue_render__$p, staticRenderFns: __vue_staticRenderFns__$p },
    __vue_inject_styles__$p,
    __vue_script__$p,
    __vue_scope_id__$p,
    __vue_is_functional_template__$p);

//
var script$q = {
  name: 'date-picker',
  components: {Picker},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    minYear: {
      type: Number,
      default: 1949
    },
    maxYear: {
      type: Number,
      default: new Date().getFullYear()
    },
    value: [String, Array]
  },
  data () {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return {
      dateValue: [year, month, day]
    }
  },
  computed: {
    data () {
      return this.getData()
    }
  },
  watch: {
    value (val) {
      if (val && val !== this.array2String(this.dateValue)) {
        this.dateValue = this.string2Array(this.value);
        // the dateValue changed will be watched
      }
    },
    dateValue (val) {
      // [1991, 6, 9] => 1991-06-09
      this.$emit('input', this.array2String(val));
      // created 以及其它修改后 都将触发
      // this.$nextTick(() => {
      //   this.$emit('on-change', this.array2String(val))
      // })
    }
  },
  created () {
    if (this.value) {
      // 1991-06-09 => [1991, 6, 9]
      this.dateValue = this.string2Array(this.value);
    }
  },
  methods: {
    onShow (isShow) {
      this.$emit('on-show', isShow);
    },
    onHide (isShow) {
      this.$emit('on-hide', isShow);
    },
    onChange (val) {
      this.$emit('on-change', this.array2String(val));
    },
    getData () {
      const date = [[]];
      const daysTotal = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; // 所有月份的天数
      for (let i = this.minYear; i <= this.maxYear; i++) {
        const months = [];
        if ((i % 4 === 0 && i % 100 !== 0) || i % 400 === 0) {           // 判定为闰年
          daysTotal[1] = 29;
        } else {
          daysTotal[1] = 28;
        }
        for (let j = 0; j < 12; j++) {
          const dates = [];
          for (let k = 1; k < daysTotal[j] + 1; k++) {
            const _date = {
              name: k + '日',
              value: k
            };
            dates.push(_date);
          }
          months.push({
            name: j + 1 + '月',
            value: j + 1,
            children: dates
          });
        }

        const year = {
          name: i + '年',
          value: i,
          children: months
        };

        date[0].push(year);
      }
      return date
    },
    array2String (date) {
      let year = date[0] || 1997;
      let month = date[1] || 1;
      let day = date[2] || 1;
      year = year.toString();
      if (month > 0 && month < 10) {
        month = '0' + month;
      } else {
        month = month.toString();
      }
      if (day > 0 && day < 10) {
        day = '0' + day;
      } else {
        day = day.toString();
      }
      return [year, month, day].join('-')
    },
    string2Array (date) {
      if (Array.isArray(date)) return date
      let ary = date.split('-');
      ary[0] = parseInt(ary[0]);
      ary[1] = parseInt(ary[1]);
      ary[2] = parseInt(ary[2]);
      return ary
    }
  }
};

/* script */
            const __vue_script__$q = script$q;
            
/* template */
var __vue_render__$q = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("picker", {
    attrs: { show: _vm.show, data: _vm.data },
    on: {
      "on-show": _vm.onShow,
      "on-hide": _vm.onHide,
      "on-change": _vm.onChange
    },
    model: {
      value: _vm.dateValue,
      callback: function($$v) {
        _vm.dateValue = $$v;
      },
      expression: "dateValue"
    }
  })
};
var __vue_staticRenderFns__$q = [];
__vue_render__$q._withStripped = true;

  /* style */
  const __vue_inject_styles__$q = undefined;
  /* scoped */
  const __vue_scope_id__$q = undefined;
  /* functional template */
  const __vue_is_functional_template__$q = false;
  /* component normalizer */
  function __vue_normalize__$q(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\date-picker.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var DatePicker = __vue_normalize__$q(
    { render: __vue_render__$q, staticRenderFns: __vue_staticRenderFns__$q },
    __vue_inject_styles__$q,
    __vue_script__$q,
    __vue_scope_id__$q,
    __vue_is_functional_template__$q);

//

var script$r = {
  name: 'picker',
  components: {PickerItem},
  props: {
    id: String,
    /* [2019, 1, 1] */
    dateValue: {
      type: Array,
      default: function () {
        return []
      }
    },
    /* [0, 1, 0] */
    timeValue: {
      type: Array,
      default: function () {
        return []
      }
    },
    show: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: 'yxy-picker'
    },
    columns: Number,
    /* [[{name: '2019年', value: 2019, children: [{name: '1月', value: 1, children: [{name: '1日', value: 1}]}]]] */
    dateData: {
      type: Array,
      default: function () {
        return [[]]
      }
    },
    /* [[1,2,3],[a,b,c],[A,B,C]] */
    timeData: {
      type: Array,
      default: function () {
        return [[]]
      }
    }
  },
  data () {
    return {
      currentDate: [],
      tempDate: [],
      currentTime: [],
      tempTime: [],
      dateChanged: false,
      timeChanged: false,
      tempDateIndex: [],
      tempTimeIndex: [],
      lastItem: null,
      showDate: true,
      showTime: false
    }
  },
  computed: {
    dateCol () {
      let cols = this.dateData.slice(0) || [];
      let depth = this.depthOf(this.dateData[0][0]);
      if (depth < this.columns) {
        depth = this.columns;
      }
      while (depth > 1) {
        cols.push([]);
        --depth;
      }
      return cols
    },
    isMulti () {
      if (this.dateData.length > 1 && Array.isArray(this.dateData[1])) {
        return true
      }
      return false
    }
  },
  watch: {
    dateValue (val) {
      if (val && JSON.parse(JSON.stringify(val)) !== JSON.parse(JSON.stringify(this.tempDate))) {
        this.tempDate = val;
        // 从新设置选中日期
        this.updateTempDateIndex();
      }
    },
    timeValue (val) {
      if (val && JSON.parse(JSON.stringify(val)) !== JSON.parse(JSON.stringify(this.timeValue))) {
        this.tempTime = val;
        // 从新设置选中时间
        this.updateTempTimeIndex();
      }
    },
    show (val) {
      if (val) {
        this.$emit('on-show', true);
      }
    }
  },
  created () {
    if (this.dateValue) {
      this.tempDate = this.dateValue;
    }
    if (this.timeValue) {
      this.tempTime = this.timeValue;
    }
  },
  methods: {
    onConfirm () {
      this.$emit('on-hide', false);
      if (!this.dateChanged && !this.timeChanged) {
        this.$emit('on-none-change');
      }
      if (this.dateChanged) {
        this.dateChanged = false;
        this.tempDate = this.currentDate.slice(0);
        this.$emit('on-date-change', this.tempDate);
      }
      if (this.timeChanged) {
        this.timeChanged = false;
        this.tempTime = this.currentTime.slice(0);
        this.$emit('on-time-change', this.tempTime);
      }
    },
    onCancel () {
      this.$emit('on-hide', false);
    },
    onHide () {
      this.$emit('on-hide', false);
    },
    onDateChange (level, item, index) {
      // 为当前的 currentDate 赋值。
      if (item) {
        this.currentDate[level] = item.value;
      } else {
        this.currentDate[level] = null;
      }
      this.tempDateIndex[level] = index;

      this.dateChanged = false;
      this.currentDate.forEach((itemVal, index) => {
        if (this.tempDate[index] !== itemVal) {
          this.dateChanged = true;
        }
      });

      // if (this.isMulti && this.dateChanged) {
      //   this.$emit('on-change', this.currentDate)
      // } else {
      if (!this.isMulti) {
        /**
         * @子列表处理
         * 1. 在没有子列表，或者值列表的数组长度为0时，隐藏掉子列表。
         * 2. 滑动之后发现重新有子列表时，再次显示子列表。
         *
         * @回调处理
         * 1. 因为滑动实际上是一层一层传递的：父列表滚动完成之后，会call子列表的onDateChange，从而带动子列表的滑动。
         * 2. 所以，使用者的传进来onDateChange回调应该在最后一个子列表滑动时再call
         */
        let $items = this.$el.querySelectorAll('.weui-picker__group.date');
        let len = ($items && $items.length) || 0;
        if (item) this.lastItem = item;
        if (item && item.children && item.children.length > 0) {
          if (level + 1 < len) {
            $items[level + 1].style.display = 'block';
          }
          // 不是多列的情况下才继续处理children
          if (!this.isMulti && this.$refs['scroller-date-' + (level + 1)]) {
            this.$refs['scroller-date-' + (level + 1)][0].resetItems(item.children);
          }
        } else if (!item && level < len && this.lastItem) {
          // 处理因异步加载多列数据，Vue通过虚拟Dom变化检测刷新剩余列，无法通过列级联关系来刷新剩余列
          item = this.lastItem;
          if (!this.isMulti && this.$refs['scroller-date-' + level] && item.children) {
            this.$refs['scroller-date-' + level][0].resetItems(item.children);
          }
        } else {
          // 如果子列表test不通过，子孙列表都隐藏。
          for (var i = 0; i < len; i++) {
            if (i > level) {
              $items[i].style.display = 'none';
            }
          }

          this.currentDate.splice(level + 1);
        }
      }
    },
    onTimeChange (level, item, index) {
      // 为当前的 currentTime 赋值。
      if (item) {
        this.currentTime[level] = item.value;
      } else {
        this.currentTime[level] = null;
      }
      this.tempTimeIndex[level] = index;

      this.timeChanged = false;
      this.currentTime.forEach((itemVal, index) => {
        if (this.tempTime[index] !== itemVal) {
          this.timeChanged = true;
        }
      });
    },
    initTempDateIndex (level, items) {
      if (this.tempDate && this.tempDate[level] !== undefined) {
        // 没有缓存选项，而且存在 tempDate
        const defaultVal = this.tempDate[level];
        let index = 0;
        let len = items.length;

        for (; index < len; ++index) {
          if (defaultVal === items[index].value) break
        }
        if (index < len) {
          this.tempDateIndex[level] = index;
        } else {
          console.warn('Picker has not match defaultValue: ' + defaultVal);
        }
        if (this.$refs['scroller-date-' + level]) {
          this.$refs['scroller-date-' + level][0].resetTempIndex(index);
        }
      }
    },
    initTempTimeIndex (level, items) {
      if (this.tempTime && this.tempTime[level] !== undefined) {
        // 没有缓存选项，而且存在 tempTime
        const defaultVal = this.tempTime[level];
        let index = 0;
        let len = items.length;

        for (; index < len; ++index) {
          if (defaultVal === items[index].value) break
        }
        if (index < len) {
          this.tempTimeIndex[level] = index;
        } else {
          console.warn('Picker has not match defaultValue: ' + defaultVal);
        }
        if (this.$refs['scroller-time-' + level]) {
          this.$refs['scroller-time-' + level][0].resetTempIndex(index);
        }
      }
    },
    updateTempDateIndex () {
      // 级联列表更新index
      // 先更新第一列，后面几列由于数据刷新后自动在mounted后触发事件on-before-change处理函数initTempDateIndex更新
      // level 只有 0
      this.dateData.forEach((items, level) => {
        if (this.tempDate && this.tempDate[level] !== undefined) {
          const defaultVal = this.tempDate[level];
          let index = 0;
          let len = items.length;

          for (; index < len; ++index) {
            if (defaultVal === items[index].value) break
          }
          if (index < len) {
            this.tempDateIndex[level] = index;
          } else {
            console.warn('Picker has not match defaultValue: ' + defaultVal);
          }
          if (this.$refs['scroller-date-' + level]) {
            this.$refs['scroller-date-' + level][0].resetTempIndex(index);
            this.$refs['scroller-date-' + level][0].scrollReady();
          }
        }
      });
    },
    updateTempTimeIndex () {
      this.timeData.forEach((items, level) => {
        if (this.tempTime && this.tempTime[level] !== undefined) {
          const defaultVal = this.tempTime[level];
          let index = 0;
          let len = items.length;
          for (; index < len; ++index) {
            if (defaultVal === items[index].value) break
          }
          if (index < len) {
            this.tempTimeIndex[level] = index;
          } else {
            console.warn('Picker has not match defaultValue: ' + defaultVal);
          }
          if (this.$refs['scroller-time-' + level]) {
            this.$refs['scroller-time-' + level][0].resetTempIndex(index);
            this.$refs['scroller-time-' + level][0].scrollReady();
          }
        }
      });
    },
    getTempDateIndex (index) {
      return this.tempDateIndex[index] || null
    },
    getTempTimeIndex (index) {
      return this.tempTimeIndex[index] || null
    },
    depthOf (obj) {
      let depth = 1;
      if (obj && obj.children && obj.children[0]) {
        depth = this.depthOf(obj.children[0]) + 1;
      }
      return depth
    },
    onToggleDateTime () {
      this.showDate = !this.showDate;
      this.showTime = !this.showTime;
    },
    beforeDestroy () {}
  }
};

/* script */
            const __vue_script__$r = script$r;
            
/* template */
var __vue_render__$r = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      class: _vm.className
    },
    [
      _c("div", {
        staticClass: "weui-mask",
        class: [_vm.show ? "weui-animate-fade-in" : "weui-animate-fade-out"],
        on: {
          click: function($event) {
            $event.stopPropagation();
            return _vm.onHide($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "weui-picker",
          class: [
            _vm.show ? "weui-animate-slide-up" : "weui-animate-slide-down"
          ]
        },
        [
          _c("div", { staticClass: "weui-picker__hd" }, [
            _c(
              "a",
              {
                staticClass: "weui-picker__action",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    $event.stopPropagation();
                    return _vm.onCancel($event)
                  }
                }
              },
              [_vm._v("取消")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "weui-picker__action",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    $event.stopPropagation();
                    return _vm.onToggleDateTime($event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.showDate ? "选择时间" : "选择日期"))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "weui-picker__action",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    $event.stopPropagation();
                    return _vm.onConfirm($event)
                  }
                }
              },
              [_vm._v("确定")]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDate,
                  expression: "showDate"
                }
              ],
              staticClass: "weui-picker__bd"
            },
            _vm._l(_vm.dateCol, function(items, index) {
              return _c("picker-item", {
                key: index,
                ref: "scroller-date-" + index,
                refInFor: true,
                attrs: {
                  classname: "date",
                  data: items,
                  level: index,
                  tempIndex: _vm.getTempDateIndex(index)
                },
                on: {
                  "on-before-change": _vm.initTempDateIndex,
                  "on-change": _vm.onDateChange
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTime,
                  expression: "showTime"
                }
              ],
              staticClass: "weui-picker__bd"
            },
            _vm._l(_vm.timeData, function(items, index) {
              return _c("picker-item", {
                key: index,
                ref: "scroller-time-" + index,
                refInFor: true,
                attrs: {
                  data: items,
                  level: index,
                  tempIndex: _vm.getTempTimeIndex(index)
                },
                on: {
                  "on-before-change": _vm.initTempTimeIndex,
                  "on-change": _vm.onTimeChange
                }
              })
            }),
            1
          )
        ]
      )
    ]
  )
};
var __vue_staticRenderFns__$r = [];
__vue_render__$r._withStripped = true;

  /* style */
  const __vue_inject_styles__$r = undefined;
  /* scoped */
  const __vue_scope_id__$r = undefined;
  /* functional template */
  const __vue_is_functional_template__$r = false;
  /* component normalizer */
  function __vue_normalize__$r(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\picker-datetime.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var PickerDatetime = __vue_normalize__$r(
    { render: __vue_render__$r, staticRenderFns: __vue_staticRenderFns__$r },
    __vue_inject_styles__$r,
    __vue_script__$r,
    __vue_scope_id__$r,
    __vue_is_functional_template__$r);

//
var script$s = {
  name: 'datetime-picker',
  components: {PickerDatetime},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    minYear: {
      type: Number,
      default: 1949
    },
    maxYear: {
      type: Number,
      default: new Date().getFullYear()
    },
    value: String
  },
  data () {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return {
      dateValue: [year, month, day],
      timeValue: [0, 0, 0],
      datetimeValue: this.value || this.getTodayString(),
      timeData: this.getTimeData()
    }
  },
  computed: {
    dateData () {
      return this.getDateData()
    }
  },
  watch: {
    value (val) {
      if (val !== this.datetimeValue) {
        this.datetimeValue = val;
        this.updateValue(this.datetimeValue);
      }
    }
  },
  created () {
    this.updateValue(this.datetimeValue);
  },
  methods: {
    onShow (isShow) {
      this.$emit('on-show', isShow);
    },
    onHide (isShow) {
      this.$emit('on-hide', isShow);
    },
    updateValue (value) {
      if (value) {
        // 1991-06-09 12:08:56 => [1991, 6, 9] [12, 08, 56]
        let ary = value.split(' ');
        if (ary[0]) {
          this.dateValue = this.string2Array(ary[0], '-');
        }
        if (ary[1]) {
          this.timeValue = this.string2Array(ary[1], ':');
        }
      }
    },
    onNoneChange () {
      this.emitChange();
    },
    onDateChange (val) {
      this.dateValue = val;
      this.emitChange();
    },
    onTimeChange (val) {
      this.timeValue = val;
      this.emitChange();
    },
    emitChange () {
      let dateStr = this.array2String(this.dateValue, '-');
      let timeStr = this.array2String(this.timeValue, ':');
      let datetimeStr = dateStr + ' ' + timeStr;
      this.datetimeValue = datetimeStr;
      this.$emit('input', datetimeStr);
      this.$emit('on-change', datetimeStr);
    },
    getDateData () {
      const date = [[]];
      const daysTotal = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; // 所有月份的天数
      for (let i = this.minYear; i <= this.maxYear; i++) {
        const months = [];
        if ((i % 4 === 0 && i % 100 !== 0) || i % 400 === 0) {           // 判定为闰年
          daysTotal[1] = 29;
        } else {
          daysTotal[1] = 28;
        }
        for (let j = 0; j < 12; j++) {
          const dates = [];
          for (let k = 1; k < daysTotal[j] + 1; k++) {
            const _date = {
              name: k + '日',
              value: k
            };
            dates.push(_date);
          }
          months.push({
            name: j + 1 + '月',
            value: j + 1,
            children: dates
          });
        }

        const year = {
          name: i + '年',
          value: i,
          children: months
        };

        date[0].push(year);
      }
      return date
    },
    getTimeData () {
      const date = [];
      const hours = [];
      const minutes = [];
      const seconds = [];

      for (let i = 0; i < 24; i++) {
        hours.push({name: '' + i + '时', value: i});
      }
      for (let i = 0; i < 60; i++) {
        minutes.push({name: '' + i + '分', value: i});
      }
      for (let i = 0; i < 60; i++) {
        seconds.push({name: '' + i + '秒', value: i});
      }

      date.push(hours);
      date.push(minutes);
      date.push(seconds);

      return date
    },
    getTodayString () {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return this.array2String([year, month, day], '-') + ' 00:00:00'
    },
    array2String (date, joinChar) {
      let one = date[0] || 0;  // year hours
      let two = date[1] || 0;  // month minutes
      let three = date[2] || 0;  // day seconds
      //
      if (one >= 0 && one < 10) {
        one = '0' + one;
      } else {
        one = one.toString();
      }
      if (two >= 0 && two < 10) {
        two = '0' + two;
      } else {
        two = two.toString();
      }
      if (three >= 0 && three < 10) {
        three = '0' + three;
      } else {
        three = three.toString();
      }
      return [one, two, three].join(joinChar || '-')
    },
    string2Array (date, spliter) {
      if (Array.isArray(date)) return date
      let ary = date.split(spliter || '-');
      ary[0] = parseInt(ary[0]);
      ary[1] = parseInt(ary[1]);
      ary[2] = parseInt(ary[2]);
      return ary
    }
  }
};

/* script */
            const __vue_script__$s = script$s;
            
/* template */
var __vue_render__$s = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("picker-datetime", {
    attrs: {
      show: _vm.show,
      dateData: _vm.dateData,
      timeData: _vm.timeData,
      dateValue: _vm.dateValue,
      timeValue: _vm.timeValue
    },
    on: {
      "on-show": _vm.onShow,
      "on-hide": _vm.onHide,
      "on-none-change": _vm.onNoneChange,
      "on-date-change": _vm.onDateChange,
      "on-time-change": _vm.onTimeChange
    }
  })
};
var __vue_staticRenderFns__$s = [];
__vue_render__$s._withStripped = true;

  /* style */
  const __vue_inject_styles__$s = undefined;
  /* scoped */
  const __vue_scope_id__$s = undefined;
  /* functional template */
  const __vue_is_functional_template__$s = false;
  /* component normalizer */
  function __vue_normalize__$s(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\datetime-picker.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var DatetimePicker = __vue_normalize__$s(
    { render: __vue_render__$s, staticRenderFns: __vue_staticRenderFns__$s },
    __vue_inject_styles__$s,
    __vue_script__$s,
    __vue_scope_id__$s,
    __vue_is_functional_template__$s);

//
var script$t = {
  components: {PcDatePicker, DatePicker, DatetimePicker},
  directives: { clickoutside },
  mixins: [formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      currentValue: this.getValue(this.value),
      styleObject: {},
      showPicker: false,
      pickerValue: this.getPickerValue(this.value),
      changedByPcPicker: false,
      warn: false,
      pickerAlignTop: false,
      disabled: this.getDisabled()
    }
  },
  computed: {
    pickerShowValue () {
      return this.pickerValue.toString()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
      this.pickerValue = this.getPickerValue(val);
    },
    pickerValue (val) {
      this.currentValue = val;
    },
    currentValue (val) {
      this.$emit('on-change', val, this.item.key);
    },
    pickerShowValue (val) {
      if (!this.$device.isDesktop) {
        this.$emit('on-change', val, this.item.key);
      }
    }
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      return data[this.item.key] || ''
    },
    getPickerValue (data) {
      let _data = data || {};
      let pickerVal = _data[this.item.key];

      return pickerVal || '' // daterange
    },
    onPickerInputClick () {
      if (this.disabled) return
      if (!this.changedByPcPicker) {
        this.showPicker = true;
        this.resolvePickPos();
      } else {
        setTimeout(() => {
          // fix bug #can't close picker when select a day#
          this.changedByPcPicker = false;
        });
      }
    },
    onPickerInputClickDesktop () {
      if (this.disabled) return
      this.showPicker = true;
      this.resolvePickPos();
    },
    onFocus () {
      this.warn = false;
    },
    onPcPickChange (val) {
      this.changedByPcPicker = true;
    },
    onPickerChange (val) {
      this.validate();
    },
    onShow (isShow) {
      this.showPicker = isShow || true;
    },
    onHide (isShow) {
      this.showPicker = isShow || false;
    },
    handleClose () {
      this.showPicker = false;
    },
    clearValid () {
      this.warn = false;
    },
    validate () {
      if (!this.item.required) return true
      if (this.$device.isDesktop && !this.currentValue) {
        this.warn = true;
        return false
      } else if (!this.$device.isDesktop && !this.pickerValue) {
        this.warn = true;
        return false
      }
      this.warn = false;
      return true
    },
    resolvePickPos () {
      let rect = this.$el.getBoundingClientRect();
      if (window.innerHeight - rect.bottom < 250) {
        this.pickerAlignTop = true;
      } else {
        this.pickerAlignTop = false;
      }
    }
  }
};

/* script */
            const __vue_script__$t = script$t;
            
/* template */
var __vue_render__$t = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer input-inline",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c("div", { staticClass: "quest-input" }, [
        _c("span", { staticClass: "quest-label title" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        !_vm.$device.isDesktop
          ? _c(
              "div",
              {
                staticClass: "input-picker bottom-line",
                class: { warn: _vm.warn },
                on: {
                  click: function($event) {
                    $event.stopPropagation();
                    return _vm.onPickerInputClick($event)
                  },
                  focus: _vm.onFocus
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.pickerShowValue) + "\n    ")]
            )
          : _c(
              "div",
              {
                directives: [
                  {
                    name: "clickoutside",
                    rawName: "v-clickoutside",
                    value: _vm.handleClose,
                    expression: "handleClose"
                  }
                ],
                staticClass: "input-picker",
                on: {
                  click: function($event) {
                    $event.stopPropagation();
                    return _vm.onPickerInputClick($event)
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentValue,
                      expression: "currentValue"
                    }
                  ],
                  ref: "input",
                  staticClass: "picker-input",
                  class: { warn: _vm.warn },
                  attrs: {
                    placeholder: _vm.item.placeholder,
                    disabled: _vm.disabled
                  },
                  domProps: { value: _vm.currentValue },
                  on: {
                    focus: _vm.onFocus,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.currentValue = $event.target.value;
                    }
                  }
                }),
                _vm._v(" "),
                _c("pc-date-picker", {
                  class: { "align-top": _vm.pickerAlignTop },
                  attrs: {
                    show: _vm.showPicker,
                    type:
                      _vm.item.type === "input_inline_datetime"
                        ? "datetime"
                        : "date"
                  },
                  on: {
                    "on-change": _vm.onPcPickChange,
                    "on-hide": _vm.onHide
                  },
                  model: {
                    value: _vm.pickerValue,
                    callback: function($$v) {
                      _vm.pickerValue = $$v;
                    },
                    expression: "pickerValue"
                  }
                })
              ],
              1
            ),
        _vm._v(" "),
        _c("div", { staticClass: "input-inline-unit" }, [
          _vm._v(_vm._s(_vm.item.unit))
        ])
      ]),
      _vm._v(" "),
      !_vm.$device.isDesktop && _vm.item.type === "input_inline_datetime"
        ? _c("datetime-picker", {
            attrs: { show: _vm.showPicker, maxYear: _vm.item.maxYear },
            on: {
              "on-show": _vm.onShow,
              "on-hide": _vm.onHide,
              "on-change": _vm.onPickerChange
            },
            model: {
              value: _vm.pickerValue,
              callback: function($$v) {
                _vm.pickerValue = $$v;
              },
              expression: "pickerValue"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.$device.isDesktop && _vm.item.type === "input_inline_date"
        ? _c("date-picker", {
            attrs: { show: _vm.showPicker, maxYear: _vm.item.maxYear },
            on: {
              "on-show": _vm.onShow,
              "on-hide": _vm.onHide,
              "on-change": _vm.onPickerChange
            },
            model: {
              value: _vm.pickerValue,
              callback: function($$v) {
                _vm.pickerValue = $$v;
              },
              expression: "pickerValue"
            }
          })
        : _vm._e()
    ],
    1
  )
};
var __vue_staticRenderFns__$t = [];
__vue_render__$t._withStripped = true;

  /* style */
  const __vue_inject_styles__$t = undefined;
  /* scoped */
  const __vue_scope_id__$t = undefined;
  /* functional template */
  const __vue_is_functional_template__$t = false;
  /* component normalizer */
  function __vue_normalize__$t(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-date.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputDate = __vue_normalize__$t(
    { render: __vue_render__$t, staticRenderFns: __vue_staticRenderFns__$t },
    __vue_inject_styles__$t,
    __vue_script__$t,
    __vue_scope_id__$t,
    __vue_is_functional_template__$t);

//
var script$u = {
  name: 'date-range',
  components: {Picker},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    minYear: {
      type: Number,
      default: 0
    },
    maxYear: {
      type: Number,
      default: 24
    },
    value: [String, Array]
  },
  data () {
    return {
      dateValue: []
    }
  },
  computed: {
    data () {
      return this.getData()
    }
  },
  watch: {
    value (val) {
      if (val && val !== this.array2String(this.dateValue)) {
        this.dateValue = this.string2Array(this.value);
        // the dateValue changed will be watched
      }
    },
    dateValue (val) {
      // [08, 21, 09] => 08:21:09
      this.$emit('input', this.array2String(val));
      // created 以及其它修改后 都将触发
      // this.$nextTick(() => {
      //   this.$emit('on-change', this.array2String(val))
      // })
    }
  },
  created () {
    if (this.value) {
      // 10:06:09 => [10, 6, 9]
      this.dateValue = this.string2Array(this.value);
    }
  },
  methods: {
    onShow (isShow) {
      this.$emit('on-show', isShow);
    },
    onHide (isShow) {
      this.$emit('on-hide', isShow);
    },
    onChange (val) {
      this.$emit('on-change', this.array2String(val));
    },
    getData () {
      const date = [];
      const years = [];
      const months = [];
      const days = [];

      for (let i = this.minYear; i < this.maxYear; i++) {
        years.push({name: '' + i + '年', value: i});
      }
      for (let i = 0; i < 12; i++) {
        months.push({name: '' + i + '个月', value: i});
      }
      for (let i = 0; i < 31; i++) {
        days.push({name: '' + i + '天', value: i});
      }

      date.push(years);
      date.push(months);
      date.push(days);

      return date
    },
    array2String (date) {
      let hour = date[0] || 0;
      let minute = date[1] || 0;
      let second = date[2] || 0;

      if (hour >= 0 && hour < 10) {
        hour = '0' + hour;
      } else {
        hour = hour.toString();
      }
      if (minute >= 0 && minute < 10) {
        minute = '0' + minute;
      } else {
        minute = minute.toString();
      }
      if (second >= 0 && second < 10) {
        second = '0' + second;
      } else {
        second = second.toString();
      }
      return [hour, minute, second].join(':')
    },
    string2Array (date) {
      if (Array.isArray(date)) return date
      let ary = date.split(':');
      ary[0] = parseInt(ary[0]);
      ary[1] = parseInt(ary[1]);
      ary[2] = parseInt(ary[2]);
      return ary
    }
  }
};

/* script */
            const __vue_script__$u = script$u;
            
/* template */
var __vue_render__$u = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("picker", {
    attrs: { show: _vm.show, data: _vm.data },
    on: {
      "on-show": _vm.onShow,
      "on-hide": _vm.onHide,
      "on-change": _vm.onChange
    },
    model: {
      value: _vm.dateValue,
      callback: function($$v) {
        _vm.dateValue = $$v;
      },
      expression: "dateValue"
    }
  })
};
var __vue_staticRenderFns__$u = [];
__vue_render__$u._withStripped = true;

  /* style */
  const __vue_inject_styles__$u = undefined;
  /* scoped */
  const __vue_scope_id__$u = undefined;
  /* functional template */
  const __vue_is_functional_template__$u = false;
  /* component normalizer */
  function __vue_normalize__$u(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\daterange-picker.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var DaterangePicker = __vue_normalize__$u(
    { render: __vue_render__$u, staticRenderFns: __vue_staticRenderFns__$u },
    __vue_inject_styles__$u,
    __vue_script__$u,
    __vue_scope_id__$u,
    __vue_is_functional_template__$u);

//
var script$v = {
  components: {DaterangePicker},
  mixins: [formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      currentValue: this.getValue(this.value),
      styleObject: {},
      showPicker: false,
      pickerValue: this.getPickerValue(this.value),
      warn: false,
      disabled: this.getDisabled()
    }
  },
  computed: {
    pickerShowValue () {
      return this.pickerValue.toString()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
      this.pickerValue = this.getPickerValue(val);
    },
    currentValue (val) {
      this.$emit('on-change', val, this.item.key);
    },
    pickerShowValue (val) {
      this.$emit('on-change', val, this.item.key);
    }
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      return data[this.item.key] || ''
    },
    getPickerValue (data) {
      let _data = data || {};
      let pickerVal = _data[this.item.key];

      return pickerVal || '' // daterange
    },
    onPickerInputClick () {
      if (this.disabled) return
      this.showPicker = true;
    },
    onFocus () {
      this.warn = false;
    },
    onPickerChange (val) {
      this.validate();
    },
    onShow (isShow) {
      this.showPicker = isShow;
    },
    onHide (isShow) {
      this.showPicker = isShow;
    },
    clearValid () {
      this.warn = false;
    },
    validate () {
      if (!this.item.required) return true
      if (this.$device.isDesktop && !this.currentValue) {
        this.warn = true;
        return false
      } else if (!this.$device.isDesktop && !this.pickerShowValue) {
        this.warn = true;
        return false
      }
      this.warn = false;
      return true
    }
  }
};

/* script */
            const __vue_script__$v = script$v;
            
/* template */
var __vue_render__$v = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer input-inline",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c("div", { staticClass: "quest-input" }, [
        _c("span", { staticClass: "quest-label title" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        !_vm.$device.isDesktop
          ? _c(
              "div",
              {
                staticClass: "input-picker bottom-line",
                class: { warn: _vm.warn },
                on: {
                  click: function($event) {
                    $event.stopPropagation();
                    return _vm.onPickerInputClick($event)
                  },
                  focus: _vm.onFocus
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.pickerShowValue) + "\n    ")]
            )
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentValue,
                  expression: "currentValue"
                }
              ],
              ref: "input",
              class: { warn: _vm.warn },
              attrs: {
                placeholder: _vm.item.placeholder,
                disabled: _vm.disabled
              },
              domProps: { value: _vm.currentValue },
              on: {
                focus: _vm.onFocus,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.currentValue = $event.target.value;
                }
              }
            }),
        _vm._v(" "),
        _c("div", { staticClass: "input-inline-unit" }, [
          _vm._v(_vm._s(_vm.item.unit))
        ])
      ]),
      _vm._v(" "),
      !_vm.$device.isDesktop
        ? _c("daterange-picker", {
            attrs: {
              show: _vm.showPicker,
              maxYear: _vm.item.daterange && _vm.item.daterange.h
            },
            on: {
              "on-show": _vm.onShow,
              "on-hide": _vm.onHide,
              "on-change": _vm.onPickerChange
            },
            model: {
              value: _vm.pickerValue,
              callback: function($$v) {
                _vm.pickerValue = $$v;
              },
              expression: "pickerValue"
            }
          })
        : _vm._e()
    ],
    1
  )
};
var __vue_staticRenderFns__$v = [];
__vue_render__$v._withStripped = true;

  /* style */
  const __vue_inject_styles__$v = undefined;
  /* scoped */
  const __vue_scope_id__$v = undefined;
  /* functional template */
  const __vue_is_functional_template__$v = false;
  /* component normalizer */
  function __vue_normalize__$v(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-daterange.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputDaterange = __vue_normalize__$v(
    { render: __vue_render__$v, staticRenderFns: __vue_staticRenderFns__$v },
    __vue_inject_styles__$v,
    __vue_script__$v,
    __vue_scope_id__$v,
    __vue_is_functional_template__$v);

//

var script$w = {
  components: { Icon, YButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: true
    },
    maskClosable: {
      type: Boolean,
      default: true
    },
    title: String,
    width: {
      type: [Number, String],
      default: 520
    },
    onBeforeOk: Function,
    okText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    loading: {
      type: Boolean,
      default: false
    },
    styles: Object,
    className: String,
    closeClass: String,
    // for instance
    footerHide: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    isGlobal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      wrapShow: false,
      buttonLoading: false,
      visible: this.value
    }
  },
  computed: {
    mainStyles () {
      let style = {};
      const styleWidth = { width: this.width ? `${this.width}px` : false };
      const customStyle = this.styles ? this.styles : {};

      Object.assign(style, styleWidth, customStyle);
      return style
    }
  },
  watch: {
    value (val) {
      this.visible = val;
    },
    visible (val) {
      if (val === false) {
        this.buttonLoading = false;
        this.timer = setTimeout(() => {
          this.wrapShow = false;
          this.removeScrollEffect();
        }, 300);
      } else {
        if (this.timer) clearTimeout(this.timer);
        this.wrapShow = true;
        if (!this.scrollable) {
          this.addScrollEffect();
        }
      }
    },
    loading (val) {
      if (!val) {
        this.buttonLoading = false;
      }
    },
    scrollable (val) {
      if (!val) {
        this.addScrollEffect();
      } else {
        this.removeScrollEffect();
      }
    }
  },
  mounted () {
    if (this.visible) {
      this.wrapShow = true;
    }
    // ESC close
    document.addEventListener('keydown', this.EscClose);
  },
  methods: {
    close () {
      this.visible = false;
      this.$emit('input', false);
      this.$emit('on-cancel');
    },
    mask () {
      if (this.maskClosable) {
        this.close();
      }
    },
    handleWrapClick (event) {
      // use indexOf,do not use === ,because modal-wrap can have other custom className
      const className = event.target.getAttribute('class');
      if (className && className.indexOf('modal-wrap') > -1) this.mask();
    },
    cancel () {
      this.close();
    },
    ok () {
      if (typeof this.onBeforeOk === 'function' && !this.onBeforeOk()) return
      if (this.loading) {
        this.buttonLoading = true;
      } else {
        this.visible = false;
        this.$emit('input', false);
      }
      this.$emit('on-ok');
    },
    EscClose (e) {
      if (this.visible && this.closable) {
        if (e.keyCode === 27) {
          this.close();
        }
      }
    },
    checkScrollBar () {
      let fullWindowWidth = window.innerWidth;
      if (!fullWindowWidth) { // workaround for missing window.innerWidth in IE8
        const documentElementRect = document.documentElement.getBoundingClientRect();
        fullWindowWidth = documentElementRect.right - Math.abs(documentElementRect.left);
      }
      this.bodyIsOverflowing = document.body.clientWidth < fullWindowWidth;
      if (this.bodyIsOverflowing) {
        this.scrollBarWidth = getScrollBarSize();
      }
    },
    setScrollBar () {
      if (this.bodyIsOverflowing && this.scrollBarWidth !== undefined) {
        document.body.style.paddingRight = `${this.scrollBarWidth}px`;
      }
    },
    resetScrollBar () {
      document.body.style.paddingRight = '';
    },
    addScrollEffect () {
      this.checkScrollBar();
      this.setScrollBar();
      document.body.style.overflow = 'hidden';
    },
    removeScrollEffect () {
      document.body.style.overflow = '';
      this.resetScrollBar();
    }
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.EscClose);
    this.removeScrollEffect();
  }
};

/* script */
            const __vue_script__$w = script$w;
            
/* template */
var __vue_render__$w = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "span",
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible"
            }
          ],
          staticClass: "modal-mask",
          on: { click: _vm.mask }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-wrap",
          class: ((_obj = { "modal-hidden": !_vm.wrapShow }),
          (_obj["" + _vm.className] = !!_vm.className),
          _obj),
          on: { click: _vm.handleWrapClick }
        },
        [
          _c("transition", { attrs: { name: "ease" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.visible,
                    expression: "visible"
                  }
                ],
                staticClass: "modal",
                style: _vm.mainStyles
              },
              [
                _c("div", { staticClass: "modal-content" }, [
                  _vm.closable
                    ? _c(
                        "a",
                        {
                          staticClass: "modal-close",
                          class: ((_obj$1 = {}),
                          (_obj$1["" + _vm.closeClass] = !!_vm.closeClass),
                          _obj$1),
                          on: { click: _vm.close }
                        },
                        [
                          _vm._t("close", [
                            _c("Icon", { attrs: { type: "ios-close-empty" } })
                          ])
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isGlobal && (_vm.$slots.header || _vm.title)
                    ? _c(
                        "div",
                        { staticClass: "modal-header" },
                        [
                          _vm._t("header", [
                            _c("div", { staticClass: "modal-header-inner" }, [
                              _vm._v(_vm._s(_vm.title))
                            ])
                          ])
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-body" },
                    [_vm._t("default")],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.footerHide
                    ? _c(
                        "div",
                        { staticClass: "modal-footer" },
                        [
                          _vm._t("footer", [
                            _c(
                              "y-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.cancel($event)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.cancelText))]
                            ),
                            _vm._v(" "),
                            _c(
                              "y-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.buttonLoading
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.ok($event)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.okText))]
                            )
                          ])
                        ],
                        2
                      )
                    : _vm._e()
                ])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
  var _obj;
  var _obj$1;
};
var __vue_staticRenderFns__$w = [];
__vue_render__$w._withStripped = true;

  /* style */
  const __vue_inject_styles__$w = undefined;
  /* scoped */
  const __vue_scope_id__$w = undefined;
  /* functional template */
  const __vue_is_functional_template__$w = false;
  /* component normalizer */
  function __vue_normalize__$w(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\modal\\modal.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var Modal = __vue_normalize__$w(
    { render: __vue_render__$w, staticRenderFns: __vue_staticRenderFns__$w },
    __vue_inject_styles__$w,
    __vue_script__$w,
    __vue_scope_id__$w,
    __vue_is_functional_template__$w);

//

var script$x = {
  components: { Icon },
  props: {
    percent: {
      type: Number,
      default: 0
    },
    showValue: [String, Number],
    status: {
      validator (value) {
        return oneOf(value, ['normal', 'active', 'wrong', 'success'])
      },
      default: 'normal'
    },
    hideInfo: {
      type: Boolean,
      default: false
    },
    strokeColor: {
      type: String,
      default: '#2db7f5'
    },
    strokeWidth: {
      type: Number,
      default: 10
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentStatus: this.status
    }
  },
  computed: {
    isStatus () {
      return this.currentStatus === 'wrong' || this.currentStatus === 'success'
    },
    statusIcon () {
      let type = '';
      switch (this.currentStatus) {
        case 'wrong':
          type = 'ios-close';
          break
        case 'success':
          type = 'ios-checkmark';
          break
      }
      return type
    }
  },
  watch: {
    percent (val, oldVal) {
      if (val < oldVal) {
        this.handleStatus(true);
      } else {
        this.handleStatus();
      }
    },
    status (val) {
      this.currentStatus = val;
    }
  },
  created () {
    this.handleStatus();
  },
  methods: {
    handleStatus (isDown) {
      if (isDown) {
        this.currentStatus = 'normal';
        this.$emit('on-status-change', 'normal');
      } else {
        if (parseInt(this.percent, 10) === 100) {
          this.currentStatus = 'success';
          this.$emit('on-status-change', 'success');
        }
      }
    }
  }
};

/* script */
            const __vue_script__$x = script$x;
            
/* template */
var __vue_render__$x = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      staticClass: "progress",
      class: [
        "progress-" + _vm.currentStatus,
        { "progress-show-info": _vm.hideInfo },
        { "progress-vertical": _vm.vertical }
      ]
    },
    [
      _c("div", { staticClass: "progress-outer" }, [
        _c("div", { staticClass: "progress-inner" }, [
          _vm.vertical
            ? _c("div", {
                staticClass: "progress-bg",
                style: {
                  backgroundColor: _vm.strokeColor,
                  width: _vm.strokeWidth + "px",
                  height: _vm.percent + "%"
                }
              })
            : _c("div", {
                staticClass: "progress-bg",
                style: {
                  backgroundColor: _vm.strokeColor,
                  width: _vm.percent + "%",
                  height: _vm.strokeWidth + "px"
                }
              })
        ])
      ]),
      _vm._v(" "),
      !_vm.hideInfo
        ? _c(
            "span",
            { staticClass: "progress-text" },
            [
              _vm._t("default", [
                _vm.isStatus
                  ? _c(
                      "span",
                      { staticClass: "progress-text-inner" },
                      [_c("icon", { attrs: { type: _vm.statusIcon } })],
                      1
                    )
                  : _c("span", { staticClass: "progress-text-inner" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.showValue || _vm.percent) +
                          "%\n      "
                      )
                    ])
              ])
            ],
            2
          )
        : _vm._e()
    ]
  )
};
var __vue_staticRenderFns__$x = [];
__vue_render__$x._withStripped = true;

  /* style */
  const __vue_inject_styles__$x = undefined;
  /* scoped */
  const __vue_scope_id__$x = undefined;
  /* functional template */
  const __vue_is_functional_template__$x = false;
  /* component normalizer */
  function __vue_normalize__$x(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\progress\\progress.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var YProgress = __vue_normalize__$x(
    { render: __vue_render__$x, staticRenderFns: __vue_staticRenderFns__$x },
    __vue_inject_styles__$x,
    __vue_script__$x,
    __vue_scope_id__$x,
    __vue_is_functional_template__$x);

//
var script$y = {
  name: 'UploadList',
  components: { Icon, YProgress },
  props: {
    files: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    handleClick (file) {
      this.$emit('on-file-click', file);
    },
    handlePreview (file) {
      this.$emit('on-file-preview', file);
    },
    handleRemove (file) {
      this.$emit('on-file-remove', file);
    },
    format (file) {
      const format = file.name.split('.').pop().toLocaleLowerCase() || '';
      let type = 'document';
      if (['gif', 'jpg', 'jpeg', 'png', 'bmp', 'webp'].indexOf(format) > -1) {
        type = 'image';
      }
      if (['mp4', 'm3u8', 'rmvb', 'avi', 'swf', '3gp', 'mkv', 'flv'].indexOf(format) > -1) {
        type = 'ios-film';
      }
      if (['mp3', 'wav', 'wma', 'ogg', 'aac', 'flac'].indexOf(format) > -1) {
        type = 'ios-musical-notes';
      }
      if (['doc', 'txt', 'docx', 'pages', 'epub', 'pdf'].indexOf(format) > -1) {
        type = 'document-text';
      }
      if (['numbers', 'csv', 'xls', 'xlsx'].indexOf(format) > -1) {
        type = 'stats-bars';
      }
      if (['keynote', 'ppt', 'pptx'].indexOf(format) > -1) {
        type = 'ios-videocam';
      }
      return type
    },
    parsePercentage (val) {
      return parseInt(val, 10)
    }
  }
};

/* script */
            const __vue_script__$y = script$y;
            
/* template */
var __vue_render__$y = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "ul",
    { staticClass: "upload-list" },
    _vm._l(_vm.files, function(file, index) {
      return _c(
        "li",
        {
          key: file.name + index,
          staticClass: "upload-list-file",
          class: { "upload-list-file-finish": file.status === "finished" },
          on: {
            click: function($event) {
              return _vm.handleClick(file)
            }
          }
        },
        [
          _c(
            "span",
            {
              on: {
                click: function($event) {
                  return _vm.handlePreview(file)
                }
              }
            },
            [
              _c("Icon", { attrs: { type: _vm.format(file) } }),
              _vm._v(" " + _vm._s(file.name) + "\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: file.status === "finished",
                expression: "file.status === 'finished'"
              }
            ],
            staticClass: "upload-list-remove",
            attrs: { type: "ios-close-empty" },
            nativeOn: {
              click: function($event) {
                return _vm.handleRemove(file)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              file.showProgress
                ? _c("y-progress", {
                    attrs: {
                      "stroke-width": 2,
                      percent: _vm.parsePercentage(file.percentage),
                      status:
                        file.status === "finished" && file.showProgress
                          ? "success"
                          : "normal"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    }),
    0
  )
};
var __vue_staticRenderFns__$y = [];
__vue_render__$y._withStripped = true;

  /* style */
  const __vue_inject_styles__$y = undefined;
  /* scoped */
  const __vue_scope_id__$y = undefined;
  /* functional template */
  const __vue_is_functional_template__$y = false;
  /* component normalizer */
  function __vue_normalize__$y(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\upload\\upload-list.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var UploadList = __vue_normalize__$y(
    { render: __vue_render__$y, staticRenderFns: __vue_staticRenderFns__$y },
    __vue_inject_styles__$y,
    __vue_script__$y,
    __vue_scope_id__$y,
    __vue_is_functional_template__$y);

// https://github.com/ElemeFE/element/blob/dev/packages/upload/src/ajax.js

function getError (action, option, xhr) {
  const msg = `fail to post ${action} ${xhr.status}'`;
  const err = new Error(msg);
  err.status = xhr.status;
  err.method = 'post';
  err.url = action;
  return err
}

function getBody (xhr) {
  const text = xhr.responseText || xhr.response;
  if (!text) {
    return text
  }

  try {
    return JSON.parse(text)
  } catch (e) {
    return text
  }
}

function upload (option) {
  if (typeof XMLHttpRequest === 'undefined') {
    return
  }

  const xhr = new XMLHttpRequest();
  const action = option.action;

  if (xhr.upload) {
    xhr.upload.onprogress = function progress (e) {
      if (e.total > 0) {
        e.percent = e.loaded / e.total * 100;
      }
      option.onProgress(e);
    };
  }

  const formData = new FormData();

  if (option.data) {
    Object.keys(option.data).map(key => {
      formData.append(key, option.data[key]);
    });
  }

  formData.append(option.filename, option.file);

  xhr.onerror = function error (e) {
    option.onError(e);
  };

  xhr.onload = function onload () {
    if (xhr.status < 200 || xhr.status >= 300) {
      return option.onError(getError(action, option, xhr), getBody(xhr))
    }

    option.onSuccess(getBody(xhr));
  };

  xhr.open('post', action, true);

  if (option.withCredentials && 'withCredentials' in xhr) {
    xhr.withCredentials = true;
  }

  const headers = option.headers || {};

  // if (headers['X-Requested-With'] !== null) {
  //   xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  // }

  for (let item in headers) {
    if (headers.hasOwnProperty(item) && headers[item] !== null) {
      xhr.setRequestHeader(item, headers[item]);
    }
  }
  xhr.send(formData);
}

function broadcast$1 (componentName, eventName, ...params) {
  this.$children.forEach(child => {
    var childName = child.$options.name;

    if (childName === componentName) {
      child.$emit(eventName, ...params);
      // child.$emit.apply(child, [eventName, ...params])
    } else {
      broadcast$1.apply(child, [componentName, eventName, ...params]);
    }
  });
}
var Emitter$1 = {
  methods: {
    dispatch (componentName, eventName, ...params) {
      var parent = this.$parent || this.$root;
      var parentName = parent.$options.name;

      while (parent && (!parentName || parentName !== componentName)) {
        parent = parent.$parent;

        if (parent) {
          parentName = parent.$options.name;
        }
      }
      if (parent) {
        parent.$emit(eventName, ...params);
        // parent.$emit.apply(parent, [eventName, ...params])
      }
    },
    broadcast (componentName, eventName, ...params) {
      broadcast$1.call(this, componentName, eventName, ...params);
    }
  }
};

//

var script$z = {
  name: 'Upload',
  mixins: [ Emitter$1 ],
  components: { UploadList },
  props: {
    action: {
      type: String,
      required: true
    },
    headers: {
      type: Object,
      default () {
        return {}
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    name: {
      type: String,
      default: 'file'
    },
    withCredentials: {
      type: Boolean,
      default: false
    },
    showUploadList: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      validator (value) {
        return oneOf(value, ['select', 'drag'])
      },
      default: 'select'
    },
    format: {
      type: Array,
      default () {
        return []
      }
    },
    accept: {
      type: String
    },
    maxSize: {
      type: Number
    },
    beforeUpload: Function,
    onProgress: {
      type: Function,
      default () {
        return {}
      }
    },
    onSuccess: {
      type: Function,
      default () {
        return {}
      }
    },
    onError: {
      type: Function,
      default () {
        return {}
      }
    },
    onRemove: {
      type: Function,
      default () {
        return {}
      }
    },
    onPreview: {
      type: Function,
      default () {
        return {}
      }
    },
    onExceededSize: {
      type: Function,
      default () {
        return {}
      }
    },
    onFormatError: {
      type: Function,
      default () {
        return {}
      }
    },
    defaultFileList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      dragOver: false,
      fileList: [],
      tempIndex: 1
    }
  },
  watch: {
    defaultFileList: {
      immediate: true,
      handler (fileList) {
        this.fileList = fileList.map(item => {
          item.status = 'finished';
          item.percentage = 100;
          item.uid = Date.now() + this.tempIndex++;
          return item
        });
      }
    }
  },
  methods: {
    handleClick () {
      this.$refs.input.click();
    },
    handleChange (e) {
      const files = e.target.files;
      if (!files) {
        return
      }
      this.uploadFiles(files);
      this.$refs.input.value = null;
    },
    onDrop (e) {
      this.dragOver = false;
      this.uploadFiles(e.dataTransfer.files);
    },
    uploadFiles (files) {
      let postFiles = Array.prototype.slice.call(files);
      if (!this.multiple) postFiles = postFiles.slice(0, 1);
      if (postFiles.length === 0) return
      postFiles.forEach(file => {
        this.upload(file);
      });
    },
    upload (file) {
      if (!this.beforeUpload) {
        return this.post(file)
      }
      const before = this.beforeUpload(file);
      if (before && before.then) {
        before.then(processedFile => {
          if (Object.prototype.toString.call(processedFile) === '[object File]') {
            this.post(processedFile);
          } else {
            this.post(file);
          }
        }, () => {
          // this.$emit('cancel', file)
        });
      } else if (before !== false) {
        this.post(file);
      } else ;
    },
    post (file) {
      // check format
      if (this.format.length) {
        const _fileFormat = file.name.split('.').pop().toLocaleLowerCase();
        const checked = this.format.some(item => item.toLocaleLowerCase() === _fileFormat);
        if (!checked) {
          this.onFormatError(file, this.fileList);
          return false
        }
      }
      // check maxSize
      if (this.maxSize) {
        if (file.size > this.maxSize * 1024) {
          this.onExceededSize(file, this.fileList);
          return false
        }
      }
      this.handleStart(file);
      let formData = new FormData();
      formData.append(this.name, file);
      upload({
        headers: this.headers,
        withCredentials: this.withCredentials,
        file: file,
        data: this.data,
        filename: this.name,
        action: this.action,
        onProgress: e => {
          this.handleProgress(e, file);
        },
        onSuccess: res => {
          this.handleSuccess(res, file);
        },
        onError: (err, response) => {
          this.handleError(err, response, file);
        }
      });
    },
    handleStart (file) {
      file.uid = Date.now() + this.tempIndex++;
      const _file = {
        status: 'uploading',
        name: file.name,
        size: file.size,
        percentage: 0,
        uid: file.uid,
        showProgress: true
      };
      this.fileList.push(_file);
    },
    getFile (file) {
      const fileList = this.fileList;
      let target;
      fileList.every(item => {
        target = file.uid === item.uid ? item : null;
        return !target
      });
      return target
    },
    handleProgress (e, file) {
      const _file = this.getFile(file);
      this.onProgress(e, _file, this.fileList);
      _file.percentage = e.percent || 0;
    },
    handleSuccess (res, file) {
      const _file = this.getFile(file);
      if (_file) {
        _file.status = 'finished';
        _file.response = res;
        this.onSuccess(res, _file, this.fileList);
        setTimeout(() => {
          _file.showProgress = false;
        }, 1000);
        this.dispatch('FormItem', 'on-form-change', _file);
      }
    },
    handleError (err, response, file) {
      const _file = this.getFile(file);
      const fileList = this.fileList;
      _file.status = 'fail';
      fileList.splice(fileList.indexOf(_file), 1);
      this.onError(err, response, file);
    },
    handleRemove (file) {
      const fileList = this.fileList;
      fileList.splice(fileList.indexOf(file), 1);
      this.onRemove(file, fileList);
    },
    handlePreview (file) {
      if (file.status === 'finished') {
        this.onPreview(file);
      }
    },
    clearFiles () {
      this.fileList = [];
    }
  }
};

/* script */
            const __vue_script__$z = script$z;
            
/* template */
var __vue_render__$z = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    { staticClass: "upload" },
    [
      _c(
        "div",
        {
          staticClass: "upload",
          class: {
            "upload-select": this.type === "select",
            "upload-drag": this.type === "drag",
            "upload-dragOver": this.type === "drag" && this.dragOver
          },
          on: {
            click: _vm.handleClick,
            drop: function($event) {
              $event.preventDefault();
              return _vm.onDrop($event)
            },
            dragover: function($event) {
              $event.preventDefault();
              _vm.dragOver = true;
            },
            dragleave: function($event) {
              $event.preventDefault();
              _vm.dragOver = false;
            }
          }
        },
        [
          _c("input", {
            ref: "input",
            staticClass: "upload-input",
            attrs: { type: "file", multiple: _vm.multiple, accept: _vm.accept },
            on: { change: _vm.handleChange }
          }),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("tip"),
      _vm._v(" "),
      _vm.showUploadList
        ? _c("upload-list", {
            attrs: { files: _vm.fileList },
            on: {
              "on-file-remove": _vm.handleRemove,
              "on-file-preview": _vm.handlePreview
            }
          })
        : _vm._e()
    ],
    2
  )
};
var __vue_staticRenderFns__$z = [];
__vue_render__$z._withStripped = true;

  /* style */
  const __vue_inject_styles__$z = undefined;
  /* scoped */
  const __vue_scope_id__$z = undefined;
  /* functional template */
  const __vue_is_functional_template__$z = false;
  /* component normalizer */
  function __vue_normalize__$z(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\upload\\upload.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var Upload = __vue_normalize__$z(
    { render: __vue_render__$z, staticRenderFns: __vue_staticRenderFns__$z },
    __vue_inject_styles__$z,
    __vue_script__$z,
    __vue_scope_id__$z,
    __vue_is_functional_template__$z);

//
var script$A = {
  components: { Modal, YProgress, Upload },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {},
    title: {
      type: String,
      default: '编辑上传文件'
    },
    label: {
      type: String,
      default: '文件描述：'
    }
  },
  data () {
    return {
      headers: authenticate().headers,
      showModal: this.show,
      file: this.value || {name: '', url: '', originalUrl: '', desc: ''},

      oldFileUrl: this.getFileUrl(this.value),
      oldFileDesc: this.getFileDesc(this.value),
      showFormatError: false,
      showExceeded: false,
      showError: false,

      percent: 0,
      showProgress: false
    }
  },
  computed: {
    fileRoot () {
      return this.$store.state.url && this.$store.state.url.fileRoot
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val;
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val);
      } else {
        this.showError = false;
        this.showProgress = false;
        this.showFormatError = false;
        this.showExceeded = false;
        this.updateFile(this.value);
      }
    },
    value (val) {
      this.file = val;
      this.updateFile(val);
    }
  },
  methods: {
    onBeforeConfirm () {
      if (this.file.url !== this.oldFileUrl) return true
      if (this.file.url && this.file.desc !== this.oldFileDesc) return true
      return false
    },
    updateFile (val) {
      this.oldFileUrl = this.getFileUrl(val);
      this.oldFileDesc = this.getFileDesc(val);
    },
    getFileUrl (val) {
      let file = val || {};
      return file.url || ''
    },
    getFileDesc (val) {
      let file = val || {};
      return file.desc || ''
    },
    onConfirm () {
      this.$emit('on-confirm-ok', JSON.parse(JSON.stringify(this.file)));
    },
    uploadSuccess (res, file) {
      this.file.url = res.url;
      this.file.originalUrl = res.originalUrl;
      this.file.name = file.name;
      this.showProgress = false;
    },
    uploadProgress (e, file) {
      this.percent = e.percent;
    },
    uploadExceeded (file) {
      this.showExceeded = true;
    },
    uploadFormatError () {
      this.showFormatError = true;
    },
    uploadError () {
      this.showError = true;
    },
    uploadBefore () {
      this.showProgress = true;
      this.showExceeded = false;
      this.showFormatError = false;
    }
  }
};

/* script */
            const __vue_script__$A = script$A;
            
/* template */
var __vue_render__$A = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "modal",
    {
      staticClass: "file-edit-win",
      attrs: {
        maskClosable: false,
        title: _vm.file.name || _vm.title,
        width: "550",
        onBeforeOk: _vm.onBeforeConfirm
      },
      on: { "on-ok": _vm.onConfirm },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v;
        },
        expression: "showModal"
      }
    },
    [
      _c("div", { staticClass: "file-overview-image-container" }, [
        _vm.file.url
          ? _c("div", { staticClass: "file-overview-image" }, [
              _c("img", { attrs: { src: _vm.file.url } })
            ])
          : _c(
              "div",
              { staticClass: "file-overview-image upload" },
              [
                _c(
                  "upload",
                  {
                    ref: "upload",
                    staticClass: "file-overview-image-upload",
                    attrs: {
                      action: _vm.fileRoot + "/auth/image/upload",
                      headers: _vm.headers,
                      maxSize: 10240 * 5,
                      format: ["jpg", "jpeg", "png"],
                      showUploadList: false,
                      beforeUpload: _vm.uploadBefore,
                      onExceededSize: _vm.uploadExceeded,
                      onProgress: _vm.uploadProgress,
                      onSuccess: _vm.uploadSuccess,
                      onError: _vm.uploadError,
                      onFormatError: _vm.uploadFormatError
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "file-overview-image-upload-inner" },
                      [
                        _c("span", {
                          staticClass: "ionic-icon ionic-icon-ios-cloud-upload"
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "tip" }, [
                          _vm._v("点击选择文件")
                        ])
                      ]
                    )
                  ]
                )
              ],
              1
            )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "file-tip" },
        [
          _vm.showProgress
            ? _c("y-progress", {
                attrs: { percent: _vm.percent, strokeWidth: 4 }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showError
            ? _c("div", { staticClass: "img-error" }, [
                _vm._v("文件上传出错，请重新打开页面上传")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showExceeded
            ? _c("div", { staticClass: "img-exceed" }, [
                _vm._v("文件大小不能超过50M")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showFormatError
            ? _c("div", { staticClass: "img-format-error" }, [
                _vm._v("图片格式错误, 请上传jpg, jpeg, png格式图片")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "file-edit-image" }, [
        _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
        _vm._v(" "),
        _c("span", { staticClass: "input-contianer" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.file.desc,
                expression: "file.desc"
              }
            ],
            domProps: { value: _vm.file.desc },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.file, "desc", $event.target.value);
              }
            }
          })
        ])
      ])
    ]
  )
};
var __vue_staticRenderFns__$A = [];
__vue_render__$A._withStripped = true;

  /* style */
  const __vue_inject_styles__$A = undefined;
  /* scoped */
  const __vue_scope_id__$A = undefined;
  /* functional template */
  const __vue_is_functional_template__$A = false;
  /* component normalizer */
  function __vue_normalize__$A(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\upload-img-win.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var UploadImgWin = __vue_normalize__$A(
    { render: __vue_render__$A, staticRenderFns: __vue_staticRenderFns__$A },
    __vue_inject_styles__$A,
    __vue_script__$A,
    __vue_scope_id__$A,
    __vue_is_functional_template__$A);

//
var script$B = {
  components: {UploadImgWin},
  mixins: [formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      viewStyle: 'oneline', // 'grid'
      showUploadWin: false,
      currentValue: this.getValue(this.value),
      disabled: this.getDisabled(),
      currentImage: {name: '', url: '', originalUrl: '', desc: ''}
    }
  },
  computed: {
    fileRoot () {
      return this.$store.state.url && this.$store.state.url.fileRoot
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
    },
    currentValue (val) {
      this.$emit('on-change', this.currentValue, this.item.key);
    }
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      return data[this.item.key] || ''
    },
    onShowUpload () {
      this.currentImage = {name: '', url: '', originalUrl: '', desc: ''};
      this.showUploadWin = true;
    },
    onImageClick (item) {
      this.currentImage = item;
      this.showUploadWin = true;
    },
    onUploaded (val) {
      let idx = -1;
      this.currentValue = this.currentValue || [];
      this.currentValue.forEach((item, index) => {
        if (item.url === val.url) {
          idx = index;
        }
      });
      if (idx < 0) {
        this.currentValue.push(val);
      } else {
        this.currentValue.splice(idx, 1, val);
      }
    },
    onDownload (item) {
      window.open(item.originalUrl);
    },
    onDelete (item) {
      this.$Modal.confirm({
        content: '确认删除影像文件？',
        onOk: () => {
          this.deleteFile(item, () => {
            let idx = -1;
            this.currentValue.forEach((_item, index) => {
              if (_item.url === item.url) {
                idx = index;
              }
            });
            if (idx > -1) {
              this.currentValue.splice(idx, 1);
            }
          });
        }
      });
    },
    deleteFile (val, callback) {
      this.$http.post(`${this.fileRoot}/auth/image/delete`, {url: val.url}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback();
          }
        }
      })
      .catch(err => {
        catchExpired(err, this);
      });
    }
  }
};

/* script */
            const __vue_script__$B = script$B;
            
/* template */
var __vue_render__$B = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      staticClass: "quest-item-answer",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c("div", { staticClass: "quest-overview-header input-head" }, [
        _c("a", { staticClass: "quest-label title" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "quest-overview-toolbar input-head" }, [
          _c("span", {
            staticClass: "ionic-icon ionic-icon-android-upload",
            on: { click: _vm.onShowUpload }
          }),
          _vm._v(" "),
          _vm.$device.isDesktop
            ? _c("span", {
                staticClass: "ionic-icon ionic-icon-more",
                on: {
                  click: function($event) {
                    _vm.viewStyle = "oneline";
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$device.isDesktop
            ? _c("span", {
                staticClass: "ionic-icon ionic-icon-grid",
                on: {
                  click: function($event) {
                    _vm.viewStyle = "grid";
                  }
                }
              })
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "quest-overview-body input-body",
          class: ["" + _vm.viewStyle]
        },
        [
          _vm.currentValue && _vm.currentValue.length
            ? _c(
                "div",
                {
                  staticClass: "quest-overview-image-container",
                  style: {
                    width:
                      _vm.viewStyle === "oneline"
                        ? _vm.currentValue.length * 200 + "px"
                        : "auto"
                  }
                },
                _vm._l(_vm.currentValue, function(item) {
                  return _c(
                    "div",
                    { key: item.url, staticClass: "quest-overview-image" },
                    [
                      _c("div", { staticClass: "quest-overview-image-inner" }, [
                        _c(
                          "div",
                          {
                            staticClass: "overview-image-box",
                            on: {
                              click: function($event) {
                                $event.stopPropagation();
                                return _vm.onImageClick(item)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: { src: item.url, title: item.desc }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "overview-image-desc" }, [
                          _c(
                            "span",
                            {
                              staticClass: "filename",
                              attrs: { title: item.name }
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "filedesc" }, [
                            _vm._v(_vm._s(item.desc))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "overview-image-toolbar" }, [
                          _c("span", {
                            staticClass:
                              "ionic-icon ionic-icon-ios-cloud-download-outline",
                            on: {
                              click: function($event) {
                                $event.stopPropagation();
                                return _vm.onDownload(item)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass:
                              "ionic-icon ionic-icon-ios-trash-outline",
                            on: {
                              click: function($event) {
                                $event.stopPropagation();
                                return _vm.onDelete(item)
                              }
                            }
                          })
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("upload-img-win", {
        attrs: {
          show: _vm.showUploadWin,
          title: "编辑上传影像",
          label: "影像描述："
        },
        on: {
          "on-hide": function($event) {
            _vm.showUploadWin = false;
          },
          "on-confirm-ok": _vm.onUploaded
        },
        model: {
          value: _vm.currentImage,
          callback: function($$v) {
            _vm.currentImage = $$v;
          },
          expression: "currentImage"
        }
      })
    ],
    1
  )
};
var __vue_staticRenderFns__$B = [];
__vue_render__$B._withStripped = true;

  /* style */
  const __vue_inject_styles__$B = undefined;
  /* scoped */
  const __vue_scope_id__$B = undefined;
  /* functional template */
  const __vue_is_functional_template__$B = false;
  /* component normalizer */
  function __vue_normalize__$B(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-image.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputImage = __vue_normalize__$B(
    { render: __vue_render__$B, staticRenderFns: __vue_staticRenderFns__$B },
    __vue_inject_styles__$B,
    __vue_script__$B,
    __vue_scope_id__$B,
    __vue_is_functional_template__$B);

//
var script$C = {
  components: { Modal, YProgress, Upload },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {}
  },
  data () {
    return {
      headers: authenticate().headers,
      showModal: this.show,
      file: this.value || {name: '', size: '', time: '', url: ''},

      oldFileName: this.getFileName(this.value),
      oldFileUrl: this.getFileUrl(this.value),
      showError: false,

      percent: 0,
      showProgress: false
    }
  },
  computed: {
    fileRoot () {
      return this.$store.state.url && this.$store.state.url.fileRoot
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val;
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val);
      } else {
        this.file = {name: '', size: '', time: '', url: ''};
        this.showError = false;
        this.showProgress = false;
        this.updateFile(this.value);
      }
    },
    value (val) {
      this.file = val;
      this.updateFile(val);
    }
  },
  methods: {
    onBeforeConfirm () {
      if (this.file.name !== this.oldFileName) return true
      if (this.file.name && this.file.url !== this.oldFileUrl) return true
      return false
    },
    updateFile (val) {
      this.oldFileName = this.getFileName(val);
      this.oldFileUrl = this.getFileUrl(val);
    },
    getFileName (val) {
      let file = val || {};
      return file.name || ''
    },
    getFileUrl (val) {
      let file = val || {};
      return file.url || ''
    },
    onConfirm () {
      this.$emit('on-confirm-ok', JSON.parse(JSON.stringify(this.file)));
    },
    uploadSuccess (res, file) {
      this.file.name = file.name;
      this.file.size = Math.round((file.size / 1024) * 100) / 100 + 'KB';
      this.file.time = new Date().format('yyyy-MM-dd hh:mm:ss');
      this.file.url = res.url;
      this.showProgress = false;
    },
    uploadProgress (e, file) {
      this.percent = e.percent;
    },
    uploadError () {
      this.showError = true;
    },
    uploadBefore () {
      this.showProgress = true;
    }
  }
};

/* script */
            const __vue_script__$C = script$C;
            
/* template */
var __vue_render__$C = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "modal",
    {
      staticClass: "file-edit-win",
      attrs: {
        maskClosable: false,
        title: "上传文件",
        width: "330",
        onBeforeOk: _vm.onBeforeConfirm
      },
      on: { "on-ok": _vm.onConfirm },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v;
        },
        expression: "showModal"
      }
    },
    [
      _c("div", { staticClass: "file-overview-container" }, [
        _vm.file.name
          ? _c("div", { staticClass: "file-overview" }, [
              _c("div", { staticClass: "file-overview-upload-inner" }, [
                _c(
                  "div",
                  { staticClass: "file-overview-upload-inner-content" },
                  [
                    _c("span", [_vm._v("已上传文件：")]),
                    _c("span", [_vm._v(_vm._s(_vm.file.name))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "file-overview-upload-inner-content" },
                  [
                    _c("span", [_vm._v("文件大小：")]),
                    _c("span", [_vm._v(_vm._s(_vm.file.size))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "file-overview-upload-inner-content" },
                  [
                    _c("span", [_vm._v("上传时间：")]),
                    _c("span", [_vm._v(_vm._s(_vm.file.time))])
                  ]
                )
              ])
            ])
          : _c(
              "div",
              { staticClass: "file-overview" },
              [
                _c(
                  "upload",
                  {
                    ref: "upload",
                    staticClass: "file-overview-upload",
                    attrs: {
                      action: _vm.fileRoot + "/auth/file/upload",
                      headers: _vm.headers,
                      showUploadList: false,
                      beforeUpload: _vm.uploadBefore,
                      onProgress: _vm.uploadProgress,
                      onSuccess: _vm.uploadSuccess,
                      onError: _vm.uploadError
                    }
                  },
                  [
                    _c("div", { staticClass: "file-overview-upload-inner" }, [
                      _c("span", {
                        staticClass: "ionic-icon ionic-icon-ios-cloud-upload"
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "tip" }, [_vm._v("点击选择文件")])
                    ])
                  ]
                )
              ],
              1
            )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "file-tip" },
        [
          _vm.showProgress
            ? _c("y-progress", {
                attrs: { percent: _vm.percent, strokeWidth: 4 }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showError
            ? _c("div", { staticClass: "upload-error" }, [
                _vm._v("文件上传出错，请重新打开页面上传")
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
};
var __vue_staticRenderFns__$C = [];
__vue_render__$C._withStripped = true;

  /* style */
  const __vue_inject_styles__$C = undefined;
  /* scoped */
  const __vue_scope_id__$C = undefined;
  /* functional template */
  const __vue_is_functional_template__$C = false;
  /* component normalizer */
  function __vue_normalize__$C(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\upload-file-win.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var UploadFileWin = __vue_normalize__$C(
    { render: __vue_render__$C, staticRenderFns: __vue_staticRenderFns__$C },
    __vue_inject_styles__$C,
    __vue_script__$C,
    __vue_scope_id__$C,
    __vue_is_functional_template__$C);

//
var script$D = {
  components: {UploadFileWin},
  mixins: [formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      viewStyle: 'oneline', // 'grid' 'list'
      showUploadWin: false,
      currentValue: this.getValue(this.value),
      disabled: this.getDisabled()
    }
  },
  computed: {
    fileRoot () {
      return this.$store.state.url && this.$store.state.url.fileRoot
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
    },
    currentValue (val) {
      this.$emit('on-change', this.currentValue, this.item.key);
    }
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      return data[this.item.key] || ''
    },
    onShowUpload () {
      this.showUploadWin = true;
    },
    onUploaded (val) {
      let idx = -1;
      this.currentValue = this.currentValue || [];
      this.currentValue.forEach((item, index) => {
        if (item.url === val.url) {
          idx = index;
        }
      });
      if (idx < 0) {
        this.currentValue.push(val);
      } else {
        this.currentValue.splice(idx, 1, val);
      }
    },
    onDownload (item) {
      window.open(item.url);
    },
    onDelete (item) {
      this.$Modal.confirm({
        content: '确认删除该文件？',
        onOk: () => {
          this.deleteFile(item, () => {
            let idx = -1;
            this.currentValue.forEach((_item, index) => {
              if (_item.url === item.url) {
                idx = index;
              }
            });
            if (idx > -1) {
              this.currentValue.splice(idx, 1);
            }
          });
        }
      });
    },
    deleteFile (val, callback) {
      this.$http.post(`${this.fileRoot}/auth/file/delete`, {url: val.url}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback();
          }
        }
      })
      .catch(err => {
        catchExpired(err, this);
      });
    }
  }
};

/* script */
            const __vue_script__$D = script$D;
            
/* template */
var __vue_render__$D = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      staticClass: "quest-item-answer",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c("div", { staticClass: "quest-overview-header input-head" }, [
        _c("a", { staticClass: "quest-label title" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "quest-overview-toolbar input-head" }, [
          _c("span", {
            staticClass: "ionic-icon ionic-icon-android-upload",
            on: { click: _vm.onShowUpload }
          }),
          _vm._v(" "),
          _vm.$device.isDesktop
            ? _c("span", {
                staticClass: "ionic-icon ionic-icon-more",
                on: {
                  click: function($event) {
                    _vm.viewStyle = "oneline";
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$device.isDesktop
            ? _c("span", {
                staticClass: "ionic-icon ionic-icon-grid",
                on: {
                  click: function($event) {
                    _vm.viewStyle = "grid";
                  }
                }
              })
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "quest-overview-body input-body",
          class: ["" + _vm.viewStyle]
        },
        _vm._l(_vm.currentValue, function(item) {
          return _c(
            "div",
            {
              key: item.name + item.size + item.time,
              staticClass: "quest-overview-file"
            },
            [
              _c("div", { staticClass: "overview-file-item" }, [
                _c("span", { staticClass: "overview-item-name" }, [
                  _vm._v(_vm._s(item.name))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "overview-item-size" }, [
                  _vm._v(_vm._s(item.size))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "overview-item-time" }, [
                  _vm._v(_vm._s(item.time))
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ionic-icon ionic-icon-ios-trash-outline",
                  on: {
                    click: function($event) {
                      $event.stopPropagation();
                      return _vm.onDelete(item)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "ionic-icon ionic-icon-ios-cloud-download-outline",
                  on: {
                    click: function($event) {
                      $event.stopPropagation();
                      return _vm.onDownload(item)
                    }
                  }
                })
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("upload-file-win", {
        attrs: { show: _vm.showUploadWin },
        on: {
          "on-hide": function($event) {
            _vm.showUploadWin = false;
          },
          "on-confirm-ok": _vm.onUploaded
        }
      })
    ],
    1
  )
};
var __vue_staticRenderFns__$D = [];
__vue_render__$D._withStripped = true;

  /* style */
  const __vue_inject_styles__$D = undefined;
  /* scoped */
  const __vue_scope_id__$D = undefined;
  /* functional template */
  const __vue_is_functional_template__$D = false;
  /* component normalizer */
  function __vue_normalize__$D(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-file.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputFile = __vue_normalize__$D(
    { render: __vue_render__$D, staticRenderFns: __vue_staticRenderFns__$D },
    __vue_inject_styles__$D,
    __vue_script__$D,
    __vue_scope_id__$D,
    __vue_is_functional_template__$D);

//
var script$E = {
  name: 'time-range',
  components: {Picker},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    minHour: {
      type: Number,
      default: 0
    },
    maxHour: {
      type: Number,
      default: 24
    },
    value: [String, Array]
  },
  data () {
    return {
      dateValue: []
    }
  },
  computed: {
    data () {
      return this.getData()
    }
  },
  watch: {
    value (val) {
      if (val && val !== this.array2String(this.dateValue)) {
        this.dateValue = this.string2Array(this.value);
        // the dateValue changed will be watched
      }
    },
    dateValue (val) {
      // [08, 21, 09] => 08:21:09
      this.$emit('input', this.array2String(val));
      // created 以及其它修改后 都将触发
      // this.$nextTick(() => {
      //   this.$emit('on-change', this.array2String(val))
      // })
    }
  },
  created () {
    if (this.value) {
      // 08:21:09 => [08, 21, 09]
      this.dateValue = this.string2Array(this.value);
    }
  },
  methods: {
    onShow (isShow) {
      this.$emit('on-show', isShow);
    },
    onHide (isShow) {
      this.$emit('on-hide', isShow);
    },
    onChange (val) {
      this.$emit('on-change', this.array2String(val));
    },
    getData () {
      const date = [];
      const hours = [];
      const minutes = [];
      const seconds = [];

      for (let i = this.minHour; i < this.maxHour; i++) {
        hours.push({name: '' + i + '小时', value: i});
      }
      for (let i = 0; i < 60; i++) {
        minutes.push({name: '' + i + '分', value: i});
      }
      for (let i = 0; i < 60; i++) {
        seconds.push({name: '' + i + '秒', value: i});
      }

      date.push(hours);
      date.push(minutes);
      date.push(seconds);

      return date
    },
    array2String (date) {
      let hour = date[0] || 0;
      let minute = date[1] || 0;
      let second = date[2] || 0;

      if (hour >= 0 && hour < 10) {
        hour = '0' + hour;
      } else {
        hour = hour.toString();
      }
      if (minute >= 0 && minute < 10) {
        minute = '0' + minute;
      } else {
        minute = minute.toString();
      }
      if (second >= 0 && second < 10) {
        second = '0' + second;
      } else {
        second = second.toString();
      }
      return [hour, minute, second].join(':')
    },
    string2Array (date) {
      if (Array.isArray(date)) return date
      let ary = date.split(':');
      ary[0] = parseInt(ary[0]);
      ary[1] = parseInt(ary[1]);
      ary[2] = parseInt(ary[2]);
      return ary
    }
  }
};

/* script */
            const __vue_script__$E = script$E;
            
/* template */
var __vue_render__$E = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("picker", {
    attrs: { show: _vm.show, data: _vm.data },
    on: {
      "on-show": _vm.onShow,
      "on-hide": _vm.onHide,
      "on-change": _vm.onChange
    },
    model: {
      value: _vm.dateValue,
      callback: function($$v) {
        _vm.dateValue = $$v;
      },
      expression: "dateValue"
    }
  })
};
var __vue_staticRenderFns__$E = [];
__vue_render__$E._withStripped = true;

  /* style */
  const __vue_inject_styles__$E = undefined;
  /* scoped */
  const __vue_scope_id__$E = undefined;
  /* functional template */
  const __vue_is_functional_template__$E = false;
  /* component normalizer */
  function __vue_normalize__$E(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\common\\picker\\timerange-picker.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var TimerangePicker = __vue_normalize__$E(
    { render: __vue_render__$E, staticRenderFns: __vue_staticRenderFns__$E },
    __vue_inject_styles__$E,
    __vue_script__$E,
    __vue_scope_id__$E,
    __vue_is_functional_template__$E);

//
var script$F = {
  components: {TimerangePicker},
  mixins: [formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      currentValue: this.getValue(this.value),
      styleObject: {},
      showPicker: false,
      pickerValue: this.getPickerValue(this.value),
      warn: false,
      disabled: this.getDisabled()
    }
  },
  computed: {
    pickerShowValue () {
      return this.pickerValue.toString()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
      this.pickerValue = this.getPickerValue(val);
    },
    currentValue (val) {
      this.$emit('on-change', val, this.item.key);
    },
    pickerShowValue (val) {
      this.$emit('on-change', val, this.item.key);
    }
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      return data[this.item.key] || ''
    },
    getPickerValue (data) {
      let _data = data || {};
      let pickerVal = _data[this.item.key];

      return pickerVal || '' // timerange
    },
    onPickerInputClick () {
      if (this.disabled) return
      this.showPicker = true;
    },
    onFocus () {
      this.warn = false;
    },
    onPickerChange (val) {
      this.validate();
    },
    onShow (isShow) {
      this.showPicker = isShow;
    },
    onHide (isShow) {
      this.showPicker = isShow;
    },
    clearValid () {
      this.warn = false;
    },
    validate () {
      if (!this.item.required) return true
      if (this.$device.isDesktop && !this.currentValue) {
        this.warn = true;
        return false
      } else if (!this.$device.isDesktop && !this.pickerShowValue) {
        this.warn = true;
        return false
      }
      this.warn = false;
      return true
    }
  }
};

/* script */
            const __vue_script__$F = script$F;
            
/* template */
var __vue_render__$F = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer input-inline",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c("div", { staticClass: "quest-input" }, [
        _c("span", { staticClass: "quest-label title" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        !_vm.$device.isDesktop
          ? _c(
              "div",
              {
                staticClass: "input-picker bottom-line",
                class: { warn: _vm.warn },
                on: {
                  click: function($event) {
                    $event.stopPropagation();
                    return _vm.onPickerInputClick($event)
                  },
                  focus: _vm.onFocus
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.pickerShowValue) + "\n    ")]
            )
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentValue,
                  expression: "currentValue"
                }
              ],
              ref: "input",
              class: { warn: _vm.warn },
              attrs: {
                placeholder: _vm.item.placeholder,
                disabled: _vm.disabled
              },
              domProps: { value: _vm.currentValue },
              on: {
                focus: _vm.onFocus,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.currentValue = $event.target.value;
                }
              }
            }),
        _vm._v(" "),
        _c("div", { staticClass: "input-inline-unit" }, [
          _vm._v(_vm._s(_vm.item.unit))
        ])
      ]),
      _vm._v(" "),
      !_vm.$device.isDesktop
        ? _c("timerange-picker", {
            attrs: {
              show: _vm.showPicker,
              maxHour: _vm.item.timerange && _vm.item.timerange.h
            },
            on: {
              "on-show": _vm.onShow,
              "on-hide": _vm.onHide,
              "on-change": _vm.onPickerChange
            },
            model: {
              value: _vm.pickerValue,
              callback: function($$v) {
                _vm.pickerValue = $$v;
              },
              expression: "pickerValue"
            }
          })
        : _vm._e()
    ],
    1
  )
};
var __vue_staticRenderFns__$F = [];
__vue_render__$F._withStripped = true;

  /* style */
  const __vue_inject_styles__$F = undefined;
  /* scoped */
  const __vue_scope_id__$F = undefined;
  /* functional template */
  const __vue_is_functional_template__$F = false;
  /* component normalizer */
  function __vue_normalize__$F(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-timerange.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputTimerange = __vue_normalize__$F(
    { render: __vue_render__$F, staticRenderFns: __vue_staticRenderFns__$F },
    __vue_inject_styles__$F,
    __vue_script__$F,
    __vue_scope_id__$F,
    __vue_is_functional_template__$F);

//
//
//
//
//
//
//
//
//
//
//
//
//

var script$G = {
  name: 'InputShowScore',
  props: {
    value: {},
    item: Object
  },
  data () {
    return {
      currentValue: this.getValue(this.value),
      tipContent: this.getChildTip(this.value)
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
    },
    currentValue (val) {
      this.$emit('on-change', val, this.item.key);
      this.tipContent = this.getChildTip(val);
    }
  },
  created () {
    this.$on('on-score-update', (scores) => {
      try {
        let scoreFn = (new Function(this.item.fn))();
        let scoreVal = '';
        if (scores && Object.keys(scores).length) {
          scoreVal = scoreFn(scores);
          scoreVal = this.decimal2(scoreVal);
        }
        scoreVal = String(scoreVal);
        if (this.item.valueType === 'number') {
          scoreVal = Number(scoreVal);
        }
        this.currentValue = scoreVal;
      } catch (e) {}
    });
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      return data[this.item.key] || ''
    },
    getChildTip (val) {
      if (!val) return ''

      let tipContent = '';
      const scoreVal = Number(val);
      const children = this.item.children || [];
      children.forEach(child => {
        let min = Number(child.min);
        let max = Number(child.max);
        if (scoreVal >= min && scoreVal <= max) {
          tipContent = child.title;
        }
      });
      return tipContent
    },
    decimal2 (val) {
      let _val = Number(val);
      let valParts = _val.toString().split('.');
      if (valParts.length === 2 && valParts[1].length > 2) {
        _val = Math.floor(_val * 100) / 100;
      }
      return _val
    }
  }
};

/* script */
            const __vue_script__$G = script$G;
            
/* template */
var __vue_render__$G = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer input-inline",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c("div", { staticClass: "quest-input" }, [
        _c("span", { staticClass: "quest-label input-inline" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "input-picker score" }, [
          _vm._v(_vm._s(_vm.currentValue))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-inline-unit" }, [
          _vm._v(_vm._s(_vm.item.unit))
        ])
      ]),
      _vm._v(" "),
      _vm.item.children
        ? _c("div", { staticClass: "quest-tip" }, [
            _vm.tipContent
              ? _c("span", { staticClass: "quest-label" }, [
                  _vm._v(_vm._s(_vm.tipContent))
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
};
var __vue_staticRenderFns__$G = [];
__vue_render__$G._withStripped = true;

  /* style */
  const __vue_inject_styles__$G = undefined;
  /* scoped */
  const __vue_scope_id__$G = undefined;
  /* functional template */
  const __vue_is_functional_template__$G = false;
  /* component normalizer */
  function __vue_normalize__$G(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-show-score.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputShowScore = __vue_normalize__$G(
    { render: __vue_render__$G, staticRenderFns: __vue_staticRenderFns__$G },
    __vue_inject_styles__$G,
    __vue_script__$G,
    __vue_scope_id__$G,
    __vue_is_functional_template__$G);

//
//
//
//
//
//
//
//

var script$H = {
  props: {
    item: Object
  }
};

/* script */
            const __vue_script__$H = script$H;
            
/* template */
var __vue_render__$H = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c("div", { staticClass: "quest-tip" }, [
        _c("span", { staticClass: "quest-label" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ])
      ])
    ]
  )
};
var __vue_staticRenderFns__$H = [];
__vue_render__$H._withStripped = true;

  /* style */
  const __vue_inject_styles__$H = undefined;
  /* scoped */
  const __vue_scope_id__$H = undefined;
  /* functional template */
  const __vue_is_functional_template__$H = false;
  /* component normalizer */
  function __vue_normalize__$H(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-show-tip.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputShowTip = __vue_normalize__$H(
    { render: __vue_render__$H, staticRenderFns: __vue_staticRenderFns__$H },
    __vue_inject_styles__$H,
    __vue_script__$H,
    __vue_scope_id__$H,
    __vue_is_functional_template__$H);

//
var script$I = {
  name: 'InputUnitRef',
  mixins: [isNumber, formDisabled, Emitter],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      currentValue: this.getValue(this.value),
      warn: false,
      disabled: this.getDisabled(),
      currentUnitRef: this.getUnitRef(this.value)
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
      this.currentUnitRef = this.getUnitRef(val);
    },
    currentValue (val, oldVal) {
      this.$emit('on-change', {value: this.getParsedVal(val), unit: this.currentUnitRef.unit}, this.item.key);
      // 整数,小数处理
      setTimeout(() => {
        // console.log(oldVal, val)
        if (!this.isNumber(val) && (this.item.valueType === 'number' || this.item.valueType === 'integer') && String(val) !== String(oldVal)) {
          this.currentValue = '';
        }
        if (this.isNumber(val) && this.item.valueType === 'integer' && String(val) !== String(oldVal)) {
          this.currentValue = parseInt(val) || 0;
        }
      }, 0);
    },
    currentUnitRef (val) {
      if (this.currentValue && val && val.unit) {
        this.$emit('on-change', {value: this.getParsedVal(this.currentValue), unit: val.unit}, this.item.key);
      }
    }
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      const dataobj = data[this.item.key] || {};
      let val = dataobj['value'] || '';
      return val
    },
    getUnitRef (data) {
      if (!data && Array.isArray(this.item.unitRef) && this.item.unitRef.lnegth) {
        return this.item.unitRef[0]
      }
      const dataobj = data[this.item.key] || {};
      let unit = dataobj['unit'] || '';
      let unitRef = {};
      if (unit && Array.isArray(this.item.unitRef) && this.item.unitRef.length) {
        this.item.unitRef.forEach(ui => {
          if (ui.unit === unit) {
            unitRef = ui;
          }
        });
      } else {
        unitRef = this.item.unitRef[0];
      }
      return unitRef
    },
    unitChange (unitRef) {
      this.currentUnitRef = unitRef;
    },
    onFocus () {
      this.warn = false;
    },
    getParsedVal (val) {
      if (!this.isNumber(val) && (this.item.valueType === 'number' || this.item.valueType === 'integer')) {
        return ''
      } else if (this.item.valueType === 'integer' && this.isNumber(val)) {
        return parseInt(val) || 0
      } else if (this.item.valueType === 'number' && this.isNumber(val)) {
        return Number(val) || 0
      }
      return val
    },
    clearValid () {
      this.warn = false;
    },
    validate () {
      if (!this.item.required) return true
      if (!this.currentValue) {
        this.warn = true;
        return false
      }
      this.warn = false;
      return true
    }
  }
};

/* script */
            const __vue_script__$I = script$I;
            
/* template */
var __vue_render__$I = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "label",
    {
      staticClass: "quest-item-answer input-inline",
      style: _vm.$device.isDesktop ? _vm.item.desktop : {}
    },
    [
      _c("div", { staticClass: "quest-input" }, [
        _c("span", { staticClass: "quest-label title" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentValue,
              expression: "currentValue"
            }
          ],
          ref: "input",
          class: { warn: _vm.warn },
          attrs: { placeholder: _vm.item.placeholder, disabled: _vm.disabled },
          domProps: { value: _vm.currentValue },
          on: {
            focus: _vm.onFocus,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.currentValue = $event.target.value;
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "input-inline-unit" }, [
          _c("span", { staticClass: "arrow-down" }),
          _vm._v(" "),
          _c("span", { staticClass: "unit" }, [
            _vm._v(_vm._s(_vm.currentUnitRef.unit))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "ref" }, [
            _vm._v(_vm._s(_vm.currentUnitRef.rmindesc))
          ]),
          _vm._v(" "),
          _vm.currentUnitRef.rmaxdesc
            ? _c("span", { staticClass: "sep" }, [_vm._v("-")])
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "ref" }, [
            _vm._v(_vm._s(_vm.currentUnitRef.rmaxdesc))
          ]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.item.unitRef, function(ui, index) {
              return _c(
                "li",
                {
                  key: index,
                  on: {
                    click: function($event) {
                      $event.stopPropagation();
                      return _vm.unitChange(ui)
                    }
                  }
                },
                [_vm._v(_vm._s(ui.unit))]
              )
            }),
            0
          )
        ])
      ])
    ]
  )
};
var __vue_staticRenderFns__$I = [];
__vue_render__$I._withStripped = true;

  /* style */
  const __vue_inject_styles__$I = undefined;
  /* scoped */
  const __vue_scope_id__$I = undefined;
  /* functional template */
  const __vue_is_functional_template__$I = false;
  /* component normalizer */
  function __vue_normalize__$I(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-unit-ref.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputUnitRef = __vue_normalize__$I(
    { render: __vue_render__$I, staticRenderFns: __vue_staticRenderFns__$I },
    __vue_inject_styles__$I,
    __vue_script__$I,
    __vue_scope_id__$I,
    __vue_is_functional_template__$I);

var inputValid = {
  methods: {
    clearValid () {
      if (this.$refs.input) {
        this.$refs.input.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputNumber) {
        this.$refs.inputNumber.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputGroup) {
        this.$refs.inputGroup.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputMulti) {
        this.$refs.inputMulti.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputMultiLine) {
        this.$refs.inputMultiLine.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputSselect) {
        this.$refs.inputSselect.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputSselectInput) {
        this.$refs.inputSselectInput.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputMselect) {
        this.$refs.inputMselect.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputMselectInput) {
        this.$refs.inputMselectInput.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputUnitRef) {
        this.$refs.inputUnitRef.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputPicker) {
        this.$refs.inputPicker.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputDict) {
        this.$refs.inputDict.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputDate) {
        this.$refs.inputDate.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputDaterange) {
        this.$refs.inputDaterange.forEach(item => {
          item.clearValid();
        });
      }
      if (this.$refs.inputTimerange) {
        this.$refs.inputTimerange.forEach(item => {
          item.clearValid();
        });
      }
    },
    validate () {
      this.showWarn = false;
      let result = true;
      if (this.$refs.input) {
        this.$refs.input.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputNumber) {
        this.$refs.inputNumber.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputGroup) {
        this.$refs.inputGroup.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputMulti) {
        this.$refs.inputMulti.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputMultiLine) {
        this.$refs.inputMultiLine.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputSselect) {
        this.$refs.inputSselect.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputSselectInput) {
        this.$refs.inputSselectInput.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputMselect) {
        this.$refs.inputMselect.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputMselectInput) {
        this.$refs.inputMselectInput.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputUnitRef) {
        this.$refs.inputUnitRef.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputPicker) {
        this.$refs.inputPicker.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputDict) {
        this.$refs.inputDict.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputDate) {
        this.$refs.inputDate.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputDaterange) {
        this.$refs.inputDaterange.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.inputTimerange) {
        this.$refs.inputTimerange.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (!result) {
        this.showWarn = true;
      }
      return result
    }
  }
};

//
var script$J = {
  components: {QInput, InputNumber, InputMulti, InputSselect, InputSselectInput, InputMselect, InputMselectInput, InputUnitRef, InputMultiLine, InputPicker, InputDict, InputDate, InputDaterange, InputTimerange},
  mixins: [inputValid],
  props: {
    value: {},
    item: {},
    role: String
  },
  data () {
    return {
      inputed: this.value || {}
    }
  },
  watch: {
    value (val) {
      this.inputed = val;
    }
  },
  methods: {
    onChange (inputVal, itemKey) {
      this.$emit('on-change', inputVal, itemKey);
    }
  }
};

/* script */
            const __vue_script__$J = script$J;
            
/* template */
var __vue_render__$J = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    { staticClass: "quest-group" },
    [
      _c("div", { staticClass: "quest-group-title" }, [
        _vm._v("\n    " + _vm._s(_vm.item.title) + "\n  ")
      ]),
      _vm._v(" "),
      _vm._l(_vm.item.group, function(gitem, index) {
        return [
          gitem.type === "input_multi_inline"
            ? _c("input-multi", {
                key: index,
                ref: "inputMulti",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_multi_line"
            ? _c("input-multi-line", {
                key: index,
                ref: "inputMultiLine",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_sselect" ||
          gitem.type === "input_sselect_multi_line"
            ? _c("input-sselect", {
                key: index,
                ref: "inputSselect",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_sselect_input"
            ? _c("input-sselect-input", {
                key: index,
                ref: "inputSselectInput",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_mselect"
            ? _c("input-sselect", {
                key: index,
                ref: "inputMselect",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_mselect"
            ? _c("input-mselect", {
                key: index,
                ref: "inputMselect",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_mselect_input"
            ? _c("input-mselect-input", {
                key: index,
                ref: "inputMselectInput",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_unit_ref"
            ? _c("input-unit-ref", {
                key: index,
                ref: "inputUnitRef",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_inline" || gitem.type === "input"
            ? _c("q-input", {
                key: index,
                ref: "input",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_number"
            ? _c("input-number", {
                key: index,
                ref: "inputNumber",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_dict"
            ? _c("input-dict", {
                key: index,
                ref: "inputDict",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_inline_picker"
            ? _c("input-picker", {
                key: index,
                ref: "inputPicker",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_inline_date" ||
          gitem.type === "input_inline_datetime"
            ? _c("input-date", {
                key: index,
                ref: "inputDate",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_inline_daterange"
            ? _c("input-daterange", {
                key: index,
                ref: "inputDaterange",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          gitem.type === "input_inline_timerange"
            ? _c("input-timerange", {
                key: index,
                ref: "inputTimerange",
                refInFor: true,
                attrs: { role: _vm.role, item: gitem },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.inputed,
                  callback: function($$v) {
                    _vm.inputed = $$v;
                  },
                  expression: "inputed"
                }
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
};
var __vue_staticRenderFns__$J = [];
__vue_render__$J._withStripped = true;

  /* style */
  const __vue_inject_styles__$J = undefined;
  /* scoped */
  const __vue_scope_id__$J = undefined;
  /* functional template */
  const __vue_is_functional_template__$J = false;
  /* component normalizer */
  function __vue_normalize__$J(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-group.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var InputGroup = __vue_normalize__$J(
    { render: __vue_render__$J, staticRenderFns: __vue_staticRenderFns__$J },
    __vue_inject_styles__$J,
    __vue_script__$J,
    __vue_scope_id__$J,
    __vue_is_functional_template__$J);

//
var script$K = {
  name: 'QuestItemInput',
  components: {QInput, InputNumber, InputMulti, InputSselect, InputSselectInput, InputMselect, InputMselectInput, InputMultiLine, InputPicker, InputDict, InputDate, InputDaterange, InputTimerange, InputImage, InputFile, InputShowScore, InputShowTip, InputUnitRef, InputGroup},
  mixins: [inputValid, Emitter],
  props: {
    role: String,
    title: String,
    value: {},
    items: Array,
    triggeredItems: Array,
    warn: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    copyable: {
      type: Boolean,
      default: false
    },
    removeable: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    calcScore: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputed: this.value || {},
      showWarn: this.warn,
      warnContent: '未完成填写',
      hasDataChange: false,
      scores: {}
    }
  },
  watch: {
    value (val) {
      this.inputed = val || {}; // fix 新的输入没有值，不用触发更新 2022.5.19
    },
    warn (val) {
      this.showWarn = !!val;
    }
  },
  created () {
    this.$on('on-computed-trigger', () => {
      this.broadcast('QInput', 'on-computed-triggered');
    });
    this.$on('on-score-change', (scores) => {
      this.broadcast('InputShowScore', 'on-score-update', scores);
    });
  },
  methods: {
    isValidated () {
      return this.validate()
    },
    onInputChange (inputVal, itemKey) {
      this.hasDataChange = true;
      if (itemKey) this.inputed[itemKey] = inputVal;
      if (inputVal === '' || (Array.isArray(inputVal) && !inputVal.length) || (inputVal && inputVal.value === '')) {
        delete this.inputed[itemKey];
      }
      this.validate();
      this.$emit('input', this.inputed);
      this.$emit('on-change', this.inputed);
    },
    onCancel () {
      if (this.required) return
      this.clearValid();
      this.showWarn = false;
    },
    onCopyField () {
      this.$emit('on-copy');
    },
    onRemoveField () {
      this.$emit('on-remove');
    },
    getValueByKey (key) {
      return this.inputed[key] || ''
    },
    onScoreChange (valueIndex, itemKey) {
      if (this.calcScore) {
        this.scores[valueIndex.scoreIndex] = Number(valueIndex.value);
        console.log('score is ', this.scores);
        if (this.hasAllScoreSelect(this.scores)) {
          this.$emit('on-score-change', this.scores);
        }
      }
    },
    hasAllScoreSelect (score) {
      let modelScoreCount = 0;
      this.items.forEach(item => {
        if (item.scoreIndex) {
          modelScoreCount++;
        }
      });
      if (Object.keys(score || {}).length === modelScoreCount) {
        return true
      }
      return false
    }
  }
};

/* script */
            const __vue_script__$K = script$K;
            
/* template */
var __vue_render__$K = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "quest-item-input" }, [
    _vm.title && !_vm.hideTitle
      ? _c("div", { staticClass: "quest-item-title" }, [
          _c(
            "a",
            { staticClass: "quest-title", class: { warn: _vm.showWarn } },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWarn,
                  expression: "showWarn"
                }
              ],
              staticClass: "quest-tip-warn"
            },
            [
              _c("i", { staticClass: "weui-icon-warn" }),
              _vm._v(_vm._s(_vm.warnContent))
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWarn,
                  expression: "showWarn"
                }
              ],
              staticClass: "quest-tip-cancel",
              on: {
                click: function($event) {
                  $event.stopPropagation();
                  return _vm.onCancel($event)
                }
              }
            },
            [_c("i", { staticClass: "weui-icon-cancel" })]
          ),
          _vm._v(" "),
          _vm.copyable
            ? _c("span", {
                staticClass: "quest-copy ionic-icon ionic-icon-ios-copy",
                on: {
                  click: function($event) {
                    $event.stopPropagation();
                    return _vm.onCopyField($event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.removeable
            ? _c("span", {
                staticClass:
                  "quest-copy ionic-icon ionic-icon-ios-close-outline",
                on: {
                  click: function($event) {
                    $event.stopPropagation();
                    return _vm.onRemoveField($event)
                  }
                }
              })
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "quest-item-answer-list" },
      [
        _vm._l(_vm.items, function(item) {
          return [
            item.type === "input_multi_inline"
              ? _c("input-multi", {
                  key: item.key,
                  ref: "inputMulti",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_multi_line"
              ? _c("input-multi-line", {
                  key: item.key,
                  ref: "inputMultiLine",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_sselect" ||
            item.type === "input_sselect_multi_line"
              ? _c("input-sselect", {
                  key: item.key,
                  ref: "inputSselect",
                  refInFor: true,
                  attrs: {
                    role: _vm.role,
                    item: item,
                    scoreIndex: item.scoreIndex
                  },
                  on: {
                    "on-score-change": _vm.onScoreChange,
                    "on-change": _vm.onInputChange
                  },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_sselect_input"
              ? _c("input-sselect-input", {
                  key: item.key,
                  ref: "inputSselectInput",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_mselect"
              ? _c("input-mselect", {
                  key: item.key,
                  ref: "inputMselect",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_mselect_input"
              ? _c("input-mselect-input", {
                  key: item.key,
                  ref: "inputMselectInput",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_unit_ref"
              ? _c("input-unit-ref", {
                  key: item.key,
                  ref: "inputUnitRef",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_inline" || item.type === "input"
              ? _c("q-input", {
                  key: item.key,
                  ref: "input",
                  refInFor: true,
                  attrs: {
                    role: _vm.role,
                    item: item,
                    scoreIndex: item.scoreIndex
                  },
                  on: {
                    "on-score-change": _vm.onScoreChange,
                    "on-change": _vm.onInputChange
                  },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_number"
              ? _c("input-number", {
                  key: item.key,
                  ref: "inputNumber",
                  refInFor: true,
                  attrs: {
                    role: _vm.role,
                    item: item,
                    scoreIndex: item.scoreIndex
                  },
                  on: {
                    "on-score-change": _vm.onScoreChange,
                    "on-change": _vm.onInputChange
                  },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_dict"
              ? _c("input-dict", {
                  key: item.key,
                  ref: "inputDict",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_inline_picker"
              ? _c("input-picker", {
                  key: item.key,
                  ref: "inputPicker",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_inline_date" ||
            item.type === "input_inline_datetime"
              ? _c("input-date", {
                  key: item.key,
                  ref: "inputDate",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_inline_daterange"
              ? _c("input-daterange", {
                  key: item.key,
                  ref: "inputDaterange",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_inline_timerange"
              ? _c("input-timerange", {
                  key: item.key,
                  ref: "inputTimerange",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_image"
              ? _c("input-image", {
                  key: item.key,
                  ref: "inputImage",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_file"
              ? _c("input-file", {
                  key: item.key,
                  ref: "inputFile",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_show_score"
              ? _c("input-show-score", {
                  key: item.key,
                  ref: "inputShowScore",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_show_tip"
              ? _c("input-show-tip", { key: item.key, attrs: { item: item } })
              : _vm._e(),
            _vm._v(" "),
            item.type === "group"
              ? _c("input-group", {
                  key: item.key,
                  ref: "inputGroup",
                  refInFor: true,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.inputed,
                    callback: function($$v) {
                      _vm.inputed = $$v;
                    },
                    expression: "inputed"
                  }
                })
              : _vm._e()
          ]
        })
      ],
      2
    )
  ])
};
var __vue_staticRenderFns__$K = [];
__vue_render__$K._withStripped = true;

  /* style */
  const __vue_inject_styles__$K = undefined;
  /* scoped */
  const __vue_scope_id__$K = undefined;
  /* functional template */
  const __vue_is_functional_template__$K = false;
  /* component normalizer */
  function __vue_normalize__$K(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\quest-item-input.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var QuestItemInput = __vue_normalize__$K(
    { render: __vue_render__$K, staticRenderFns: __vue_staticRenderFns__$K },
    __vue_inject_styles__$K,
    __vue_script__$K,
    __vue_scope_id__$K,
    __vue_is_functional_template__$K);

var sselectValid = {
  methods: {
    initialInputWarn () {
      this.warnContent = '未完成选择';
      this.showWarn = false;

      this.items.forEach(item => {
        let children = item.children || [];
        let subChildren = item.subChildren || [];
        children.forEach(child => {
          child.placeholder = '';
          this.$set(this.inputWarn, child.key, false);
        });
        subChildren.forEach(child => {
          child.placeholder = '';
          this.$set(this.inputWarn, child.key, false);
        });
      });
    },
    valid () {
      this.showWarn = false;
      const currentItem = this.getCurrentItem();
      if (!currentItem && this.$device.isDesktop) {
        return true
      } else if (!currentItem) {
        this.warnContent = '未完成选择';
        this.showWarn = true;
        return false
      }
      const children = currentItem.children || [];
      const subChildren = currentItem.subChildren || [];
      children.forEach(child => {
        const childVal = String(this.childKeyVal[child.key] || '');
        if (child.required && !(childVal && childVal.length)) {
          this.$set(this.inputWarn, child.key, true);
          child.placeholder = '请填写...';
          //
          this.warnContent = '未完成填写';
          this.showWarn = true;
        }
      });
      subChildren.forEach(child => {
        const childVal = String(this.subChildKeyVal[child.key] || '');
        if (child.required && !(childVal && childVal.length)) {
          this.$set(this.inputWarn, child.key, true);
          child.placeholder = '请填写...';
          //
          this.warnContent = '未完成填写';
          this.showWarn = true;
        }
      });
      if (this.showWarn) {
        return false
      }
      return true
    }
  }
};

function typeOf$1 (obj) {
  const toString = Object.prototype.toString;
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  };
  return map[toString.call(obj)]
}

var equal = {
  methods: {
    equals (a, b) {
      // first compare array, because typeof [] === 'object'
      if (Array.isArray(a) && Array.isArray(b)) {
        return this.equalArray(a, b)
      } else if (typeof a === 'object' && typeof b === 'object') {
        return this.equalObj(a, b)
      } else if (a === b) {
        return true
      } else {
        return false
      }
    },
    equalObj (newObj, oldObj) {
      let equal = true;
      let props = Object.keys(newObj || {});
      // fix remove attr
      let propsOld = Object.keys(oldObj || {});
      if (propsOld.length !== props.length) {
        return false
      }
      for (let i = 0, len = props.length; i < len; i++) {
        let prop = props[i];
        let newVal = newObj[prop];
        let oldVal = oldObj[prop];
        if (!this.equals(newVal, oldVal)) {
          equal = false;
          break
        }
      }
      return equal
    },
    equalArray (newArray, oldArray) {
      let newA = newArray || [];
      let oldA = oldArray || [];
      if (newA.length !== oldA.length) return false

      let equal = true;
      for (let i = 0, len = newA.length; i < len; i++) {
        let newVal = newA[i];
        let oldVal = oldA[i];
        if (!this.equals(newVal, oldVal)) {
          equal = false;
          break
        }
      }
      return equal
    },
    equalAttr (newObj, oldObj, attr) {
      newObj = newObj || {};
      oldObj = oldObj || {};
      return this.equals(newObj[attr], oldObj[attr])
    },
    deepCopy (data) {
      const t = typeOf$1(data);
      let o;

      if (t === 'array') {
        o = [];
      } else if (t === 'object') {
        o = {};
      } else {
        return data
      }

      if (t === 'array') {
        for (let i = 0; i < data.length; i++) {
          o.push(this.deepCopy(data[i]));
        }
      } else if (t === 'object') {
        for (let i in data) {
          o[i] = this.deepCopy(data[i]);
        }
      }
      return o
    }
  }
};

//
var script$L = {
  mixins: [equal, isNumber, sselectValid, formDisabled],
  props: {
    role: String,
    title: String,
    items: Array,
    value: {},
    triggeredItems: Array,
    scoreIndex: String,
    warn: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentKey: this.getKey(this.value),
      childKeyVal: this.getChildKeyVal(this.value),
      subChildKeyVal: this.getSubChildKeyVal(this.value),
      idRand: Math.random().toString().slice(2),
      showWarn: this.warn,
      warnContent: '未完成选择',
      inputWarn: {},
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val, oldVal) {
      this.currentKey = this.getKey(val);
      if (this.currentKeyHasChildren() && !this.equalAttr(val, oldVal, 'childKeyVal')) {
        this.childKeyVal = this.getChildKeyVal(val);
      }
      if (this.currentKeyHasSubChildren() && !this.equalAttr(val, oldVal, 'subchildKeyVal')) {
        this.subChildKeyVal = this.getSubChildKeyVal(val);
      }
    },
    currentKey (val, oldKey) {
      this.deleteChildKeyVal(oldKey);
      this.deleteSubchildKeyVal(oldKey);
      this.emitChange(val);
      this.initialInputWarn();
    },
    warn (val) {
      this.showWarn = !!val;
    }
  },
  methods: {
    getKey (pdata) {
      let data = {};
      if (pdata) data = JSON.parse(JSON.stringify(pdata));
      const value = data['key'] = data['key'] || '';
      return value
    },
    getChildKeyVal (pdata) {
      let data = {};
      if (pdata) data = JSON.parse(JSON.stringify(pdata));
      const childobj = data['childKeyVal'] = data['childKeyVal'] || {};
      return Object.assign({}, childobj)
    },
    getSubChildKeyVal (pdata) {
      let data = {};
      if (pdata) data = JSON.parse(JSON.stringify(pdata));
      const subchildobj = data['subchildKeyVal'] = data['subchildKeyVal'] || {};
      return Object.assign({}, subchildobj)
    },
    deleteChildKeyVal (key) {
      this.items.forEach(item => {
        if (item.key === key && item.children) {
          item.children.forEach(child => {
            delete this.childKeyVal[child.key];
          });
        }
      });
    },
    deleteSubchildKeyVal (key) {
      this.items.forEach(item => {
        if (item.key === key && item.subChildren) {
          item.subChildren.forEach(subchild => {
            delete this.subChildKeyVal[subchild.key];
          });
        }
      });
    },
    currentKeyHasChildren () {
      let found = false;
      this.items.forEach(item => {
        if (item.key === this.currentKey && item.children) {
          found = true;
        }
      });
      return found
    },
    currentKeyHasSubChildren () {
      let found = false;
      this.items.forEach(item => {
        if (item.key === this.currentKey && item.subChildren) {
          found = true;
        }
      });
      return found
    },
    getCurrentItem () {
      let currentItem;
      this.items.forEach(item => {
        if (item.key === this.currentKey) {
          currentItem = item;
        }
      });
      return currentItem
    },
    validate () {
      return this.valid()
    },
    isValidated () {
      return this.validate()
    },
    onFocus (item) {
      this.initialInputWarn();
      this.currentKey = item.key;
    },
    onBlur () {
      this.validate();
    },
    onChange () {
      //
    },
    onChildInputChange () {
      this.emitChange(this.currentKey);
    },
    onSubChildInputChange () {
      this.emitChange(this.currentKey);
    },
    emitChange (currentKey) {
      if (!this.currentKey) {
        this.$emit('input', {});
        this.$emit('on-change', {});
      } else {
        const val = this.getValue(currentKey);
        if (this.currentKeyHasChildren() && this.currentKeyHasSubChildren()) {
          this.$emit('input', {
            key: currentKey,
            value: val,
            childKeyVal: this.getParsedChildKeyVal(this.childKeyVal),
            subchildKeyVal: this.getParsedSubChildKeyVal(this.subChildKeyVal)
          });
          this.$emit('on-change', {
            key: currentKey,
            value: val,
            childKeyVal: this.getParsedChildKeyVal(this.childKeyVal),
            subchildKeyVal: this.getParsedSubChildKeyVal(this.subChildKeyVal)
          });
        } else if (this.currentKeyHasChildren() && !this.currentKeyHasSubChildren()) {
          this.$emit('input', {
            key: currentKey,
            value: val,
            childKeyVal: this.getParsedChildKeyVal(this.childKeyVal)
          });
          this.$emit('on-change', {
            key: currentKey,
            value: val,
            childKeyVal: this.getParsedChildKeyVal(this.childKeyVal)
          });
        } else if (this.scoreIndex) {
          this.$emit('input', {key: currentKey, value: val, scoreIndex: this.scoreIndex});
          this.$emit('on-change', {key: currentKey, value: val, scoreIndex: this.scoreIndex});
        } else {
          this.$emit('input', {key: currentKey, value: val});
          this.$emit('on-change', {key: currentKey, value: val});
        }
      }
    },
    getValue (key) {
      let val = '';
      this.items.forEach(item => {
        if (key === item.key) {
          val = item.value;
        }
      });
      return val
    },
    getParsedChildKeyVal (val) {
      let childKeyVal = Object.assign({}, val);
      Object.keys(childKeyVal).forEach(key => {
        this.items.forEach(item => {
          if (item.key === this.currentKey && item.children) {
            item.children.forEach(child => {
              if (child.key === key && child.valueType === 'integer' && this.isNumber(childKeyVal[key])) {
                childKeyVal[key] = parseInt(childKeyVal[key]) || 0;
              } else if (child.key === key && child.valueType === 'number' && this.isNumber(childKeyVal[key])) {
                childKeyVal[key] = Number(childKeyVal[key]) || 0;
              } else if (child.key === key && child.valueType === 'number' && childKeyVal[key] === '') {
                delete childKeyVal[key];
              }
            });
          }
        });
      });
      return childKeyVal
    },
    getParsedSubChildKeyVal (val) {
      let subChildKeyVal = Object.assign({}, val);
      Object.keys(subChildKeyVal).forEach(key => {
        this.items.forEach(item => {
          if (item.key === this.currentKey && item.subChildren) {
            item.subChildren.forEach(child => {
              if (child.key === key && child.valueType === 'integer' && this.isNumber(subChildKeyVal[key])) {
                subChildKeyVal[key] = parseInt(subChildKeyVal[key]) || 0;
              } else if (child.key === key && child.valueType === 'number' && this.isNumber(subChildKeyVal[key])) {
                subChildKeyVal[key] = Number(subChildKeyVal[key]) || 0;
              } else if (child.key === key && child.valueType === 'number' && subChildKeyVal[key] === '') {
                delete subChildKeyVal[key];
              }
            });
          }
        });
      });
      return subChildKeyVal
    },
    toggleClick (item, e) {
      if (item.key === this.currentKey) {
        e.preventDefault();
        this.currentKey = '';
      }
    }
  }
};

/* script */
            const __vue_script__$L = script$L;
            
/* template */
var __vue_render__$L = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "quest-item-select" }, [
    _c("div", { staticClass: "quest-item-title" }, [
      _c("a", { staticClass: "quest-title", class: { warn: _vm.showWarn } }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showWarn,
              expression: "showWarn"
            }
          ],
          staticClass: "quest-tip-warn"
        },
        [
          _c("i", { staticClass: "weui-icon-warn" }),
          _vm._v(_vm._s(_vm.warnContent))
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "quest-item-answer-list" },
      [
        _vm._l(_vm.items, function(item, idxx) {
          return [
            _c(
              "label",
              {
                key: "sselect" + item.key,
                staticClass: "quest-item-answer",
                class: {
                  inline: _vm.inline || item.type === "sselect_inline",
                  "sselect-input-inline": item.type === "sselect_input"
                },
                style: _vm.$device.isDesktop ? item.desktop : {},
                attrs: { for: "_id_" + idxx + _vm.idRand }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "quest-radio-outer",
                    class: { checked: item.key === _vm.currentKey }
                  },
                  [
                    _c("span", {
                      staticClass: "quest-radio-inner",
                      on: {
                        click: function($event) {
                          $event.stopPropagation();
                          return _vm.toggleClick(item, $event)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentKey,
                          expression: "currentKey"
                        }
                      ],
                      staticClass: "quest-radio",
                      attrs: {
                        type: "radio",
                        id: "_id_" + idxx + _vm.idRand,
                        disabled: _vm.disabled
                      },
                      domProps: {
                        value: item.key,
                        checked: _vm._q(_vm.currentKey, item.key)
                      },
                      on: {
                        change: function($event) {
                          _vm.currentKey = item.key;
                        }
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "quest-label" }, [
                  _vm._v(_vm._s(item.label || item.title))
                ]),
                _vm._v(" "),
                _vm._l(item.children, function(child, index) {
                  return [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.childKeyVal[child.key],
                          expression: "childKeyVal[child.key]"
                        }
                      ],
                      key: child.key,
                      staticClass: "input-inline",
                      class: { warn: _vm.inputWarn[child.key] },
                      attrs: {
                        placeholder: child.placeholder,
                        disabled: _vm.disabled
                      },
                      domProps: { value: _vm.childKeyVal[child.key] },
                      on: {
                        focus: function($event) {
                          return _vm.onFocus(item)
                        },
                        blur: _vm.onBlur,
                        change: _vm.onChange,
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.childKeyVal,
                              child.key,
                              $event.target.value
                            );
                          },
                          _vm.onChildInputChange
                        ]
                      }
                    }),
                    _vm._v(" "),
                    child.unit
                      ? _c(
                          "div",
                          {
                            key: "unit" + child.key,
                            staticClass: "input-inline-unit"
                          },
                          [_vm._v(_vm._s(child.unit))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    index < item.children.length - 1 && item.relationSymbol
                      ? _c("div", {
                          key: "symbol" + child.key,
                          staticClass: "input-inline-symbol",
                          domProps: { innerHTML: _vm._s(item.relationSymbol) }
                        })
                      : _vm._e()
                  ]
                })
              ],
              2
            ),
            _vm._v(" "),
            item.subChildren
              ? _c(
                  "div",
                  {
                    key: "subchildren" + item.key,
                    staticClass: "quest-radio-subchildren",
                    style: _vm.$device.isDesktop ? item.desktop : {}
                  },
                  [
                    _vm._l(item.subChildren, function(subchild, idx) {
                      return [
                        _c(
                          "span",
                          { key: subchild.key, staticClass: "quest-label" },
                          [_vm._v(_vm._s(subchild.label || subchild.title))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.subChildKeyVal[subchild.key],
                              expression: "subChildKeyVal[subchild.key]"
                            }
                          ],
                          key: subchild.key,
                          staticClass: "input-inline",
                          class: { warn: _vm.inputWarn[subchild.key] },
                          attrs: {
                            placeholder: subchild.placeholder,
                            disabled: _vm.disabled
                          },
                          domProps: { value: _vm.subChildKeyVal[subchild.key] },
                          on: {
                            focus: function($event) {
                              return _vm.onFocus(item)
                            },
                            blur: _vm.onBlur,
                            change: _vm.onChange,
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.subChildKeyVal,
                                  subchild.key,
                                  $event.target.value
                                );
                              },
                              _vm.onSubChildInputChange
                            ]
                          }
                        }),
                        _vm._v(" "),
                        subchild.unit
                          ? _c(
                              "div",
                              {
                                key: "unit" + subchild.key,
                                staticClass: "input-inline-unit"
                              },
                              [_vm._v(_vm._s(subchild.unit))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        idx < item.subChildren.length - 1 &&
                        item.subRelationSymbol
                          ? _c("div", {
                              key: "symbol" + subchild.key,
                              staticClass: "input-inline-symbol",
                              domProps: {
                                innerHTML: _vm._s(item.subRelationSymbol)
                              }
                            })
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              : _vm._e()
          ]
        })
      ],
      2
    )
  ])
};
var __vue_staticRenderFns__$L = [];
__vue_render__$L._withStripped = true;

  /* style */
  const __vue_inject_styles__$L = undefined;
  /* scoped */
  const __vue_scope_id__$L = undefined;
  /* functional template */
  const __vue_is_functional_template__$L = false;
  /* component normalizer */
  function __vue_normalize__$L(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\quest-item-sselect.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var QuestItemSselect = __vue_normalize__$L(
    { render: __vue_render__$L, staticRenderFns: __vue_staticRenderFns__$L },
    __vue_inject_styles__$L,
    __vue_script__$L,
    __vue_scope_id__$L,
    __vue_is_functional_template__$L);

//
var script$M = {
  mixins: [formDisabled],
  props: {
    role: String,
    title: String,
    items: Array,
    value: [String, Array],
    triggeredItems: Array,
    warn: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentKey: this.getKeys(this.value) || [],
      idRand: Math.random().toString().slice(2),
      showTip: this.warn,
      tipContent: '您还未完成选择',
      styleObject: {},
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      let keys = this.getKeys(val);
      let equal = true;
      if (keys.length !== this.currentKey.length) {
        this.currentKey = keys;
      } else {
        for (let i = 0; i < keys.length; i++) {
          if (keys[i] !== this.currentKey[i]) {
            equal = false;
            break
          }
        }
        if (!equal) {
          this.currentKey = keys;
        }
      }
    },
    currentKey (val) {
      this.validate();
      this.emitChange(val);
    },
    warn (val) {
      this.showTip = !!val;
    }
  },
  methods: {
    getKeys (data) {
      const keys = [];
      if (!Array.isArray(data)) return keys
      data.forEach(select => {
        keys.push(select.key);
      });
      return keys
    },
    validate () {
      if (this.currentKey && this.currentKey.length > 0) {
        this.showTip = false;
        return true
      } else if (this.$device.isDesktop) {
        this.showTip = false;
        return true
      }
      this.showTip = true;
      return false
    },
    isValidated () {
      return this.validate()
    },
    emitChange (val) {
      const selectedKeys = val || [];
      const keyVals = [];
      selectedKeys.forEach(key => {
        let val = '';
        this.items.forEach(item => {
          if (item.key === key) {
            val = item.value;
          }
        });
        keyVals.push({key, value: val});
      });
      this.$emit('input', keyVals);
      this.$emit('on-change', keyVals);
    }
  }
};

/* script */
            const __vue_script__$M = script$M;
            
/* template */
var __vue_render__$M = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "quest-item-select" }, [
    _c("div", { staticClass: "quest-item-title" }, [
      _c("a", { staticClass: "quest-title", class: { warn: _vm.showTip } }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTip,
              expression: "showTip"
            }
          ],
          staticClass: "quest-tip-warn"
        },
        [
          _c("i", { staticClass: "weui-icon-warn" }),
          _vm._v(_vm._s(_vm.tipContent))
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "quest-item-answer-list" },
      _vm._l(_vm.items, function(item, idxx) {
        return _c(
          "label",
          {
            key: item.key,
            staticClass: "quest-item-answer",
            class: { inline: _vm.inline },
            attrs: { for: "_id_" + idxx + _vm.idRand }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentKey,
                  expression: "currentKey"
                }
              ],
              staticClass: "quest-checkbox",
              attrs: {
                type: "checkbox",
                id: "_id_" + idxx + _vm.idRand,
                disabled: _vm.disabled
              },
              domProps: {
                value: item.key,
                checked: Array.isArray(_vm.currentKey)
                  ? _vm._i(_vm.currentKey, item.key) > -1
                  : _vm.currentKey
              },
              on: {
                change: function($event) {
                  var $$a = _vm.currentKey,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = item.key,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && (_vm.currentKey = $$a.concat([$$v]));
                    } else {
                      $$i > -1 &&
                        (_vm.currentKey = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.currentKey = $$c;
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("i", { staticClass: "weui-icon-checkbox" }),
            _vm._v(" "),
            _c("span", { staticClass: "quest-label" }, [
              _vm._v(_vm._s(item.label || item.title))
            ])
          ]
        )
      }),
      0
    )
  ])
};
var __vue_staticRenderFns__$M = [];
__vue_render__$M._withStripped = true;

  /* style */
  const __vue_inject_styles__$M = undefined;
  /* scoped */
  const __vue_scope_id__$M = undefined;
  /* functional template */
  const __vue_is_functional_template__$M = false;
  /* component normalizer */
  function __vue_normalize__$M(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\quest-item-mselect.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var QuestItemMselect = __vue_normalize__$M(
    { render: __vue_render__$M, staticRenderFns: __vue_staticRenderFns__$M },
    __vue_inject_styles__$M,
    __vue_script__$M,
    __vue_scope_id__$M,
    __vue_is_functional_template__$M);

//
var script$N = {
  mixins: [isNumber, formDisabled],
  props: {
    item: Object,
    value: {},
    index: [String, Number],
    role: String
  },
  data () {
    const data = this.getNewData(this.value);
    let selectedKey = data.currentKey;
    let childKeyVal = data.childKeyVal;
    return {
      currentKey: selectedKey || [],
      childKeyVal: childKeyVal || {},
      idRand: Math.random().toString().slice(2),
      inputWarn: {},
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      const data = this.getNewData(val);
      if (this.currentKey[0] !== data.currentKey[0]) {
        this.currentKey = data.currentKey;
        this.childKeyVal = data.childKeyVal;
      }
    },
    currentKey (val) {
      this.$emit('on-change', {
        key: val[0],
        value: this.item.value,
        childKeyVal: this.getParsedKeyVal(this.childKeyVal),
        childKeyTitle: this.getKeyTitle(this.childKeyVal)  // 打补丁，用于数据导出
      }, this.item.key);
      this.$emit('on-validate', this.validate());
    }
  },
  mounted () {
    this.initialInputWarn();
  },
  methods: {
    getNewData (data) {
      if (!data) return {}
      const valobj = data[this.item.key];
      let selectedKey;
      let childKeyVal;
      if (valobj) {
        selectedKey = valobj['key'];
        childKeyVal = valobj['childKeyVal'];
      }
      return {
        currentKey: (selectedKey ? [selectedKey] : []),
        childKeyVal: (childKeyVal || {})
      }
    },
    getParsedKeyVal (val) {
      let keyVal = Object.assign({}, val);
      Object.keys(keyVal).forEach(key => {
        this.item.children.forEach(child => {
          if (child.key === key && child.valueType === 'integer' && this.isNumber(keyVal[key])) {
            keyVal[key] = parseInt(keyVal[key]) || 0;
          } else if (child.key === key && child.valueType === 'number' && this.isNumber(keyVal[key])) {
            keyVal[key] = Number(keyVal[key]) || 0;
          }
        });
      });
      return keyVal
    },
    getKeyTitle (val) {
      let keyTitle = Object.assign({}, val);
      Object.keys(keyTitle).forEach(key => {
        this.item.children.forEach(child => {
          if (child.key === key) {
            keyTitle[key] = child.title;
          }
        });
      });
      return keyTitle
    },
    onFocus (item) {
      if (!this.currentKey.length ||
        (this.currentKey.length && this.currentKey[0] !== item.key)) {
        this.currentKey = [item.key];
      }
    },
    onBlur () {
      //
    },
    onChange () {
      //
    },
    onInputChange () {
      this.$emit('on-validate', this.validate());
      this.$emit('on-change', {
        key: this.currentKey[0],
        value: this.item.value,
        childKeyVal: this.getParsedKeyVal(this.childKeyVal),
        childKeyTitle: this.getKeyTitle(this.childKeyVal)  // 打补丁，用于数据导出
      }, this.item.key);
    },
    initialInputWarn () {
      const children = this.item.children || [];
      children.forEach(child => {
        child.placeholder = '';
        this.$set(this.inputWarn, child.key, false);
      });
    },
    validate () {
      if (this.currentKey.indexOf(this.item.key) >= 0) {
        let result = false;
        const children = this.item.children || [];
        children.forEach(child => {
          const childVal = String(this.childKeyVal[child.key] || '');
          if (childVal && childVal.length) {
            result = true;
          }
        });
        children.forEach(child => {
          // const childVal = this.childKeyVal[child.key]
          // if (!(childVal && childVal.length)) {
          if (result) {
            this.$set(this.inputWarn, child.key, false);
            child.placeholder = '';
          } else {
            this.$set(this.inputWarn, child.key, true);
            child.placeholder = '请填写...';
          }
        });
        if (result) {
          this.initialInputWarn();
          return true
        }
        return false
      } else {
        this.initialInputWarn();
        return true
      }
    }
  }
};

/* script */
            const __vue_script__$N = script$N;
            
/* template */
var __vue_render__$N = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    { staticClass: "mselect-input-multi" },
    [
      _c(
        "label",
        {
          staticClass: "quest-item-answer input-inline",
          attrs: { for: "_id_" + _vm.index + _vm.idRand }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currentKey,
                expression: "currentKey"
              }
            ],
            staticClass: "quest-checkbox",
            attrs: {
              type: "checkbox",
              id: "_id_" + _vm.index + _vm.idRand,
              disabled: _vm.disabled
            },
            domProps: {
              value: _vm.item.key,
              checked: Array.isArray(_vm.currentKey)
                ? _vm._i(_vm.currentKey, _vm.item.key) > -1
                : _vm.currentKey
            },
            on: {
              change: function($event) {
                var $$a = _vm.currentKey,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = _vm.item.key,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.currentKey = $$a.concat([$$v]));
                  } else {
                    $$i > -1 &&
                      (_vm.currentKey = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.currentKey = $$c;
                }
              }
            }
          }),
          _vm._v(" "),
          _c("i", { staticClass: "weui-icon-checkbox" }),
          _vm._v(" "),
          _c("span", { staticClass: "quest-label" }, [
            _vm._v(_vm._s(_vm.item.label || _vm.item.title))
          ])
        ]
      ),
      _vm._v(" "),
      _vm.currentKey.indexOf(_vm.item.key) >= 0
        ? [
            _vm._l(_vm.item.children, function(child, index) {
              return [
                _c("div", { key: index, staticClass: "input-multi-line" }, [
                  child.label || child.title
                    ? _c("div", { staticClass: "input-inline-label" }, [
                        _vm._v(_vm._s(child.label || child.title))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.childKeyVal[child.key],
                        expression: "childKeyVal[child.key]"
                      }
                    ],
                    ref: "input_" + child.key,
                    refInFor: true,
                    class: { warn: _vm.inputWarn[child.key] },
                    attrs: {
                      placeholder: _vm.item.placeholder,
                      disabled: _vm.disabled
                    },
                    domProps: { value: _vm.childKeyVal[child.key] },
                    on: {
                      focus: function($event) {
                        return _vm.onFocus(_vm.item)
                      },
                      blur: _vm.onBlur,
                      change: _vm.onChange,
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.childKeyVal,
                            child.key,
                            $event.target.value
                          );
                        },
                        _vm.onInputChange
                      ]
                    }
                  }),
                  _vm._v(" "),
                  child.unit
                    ? _c("div", { staticClass: "input-inline-unit" }, [
                        _vm._v(_vm._s(child.unit))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  index < _vm.item.children.length - 1 &&
                  _vm.item.relationSymbol
                    ? _c("div", {
                        staticClass: "input-inline-symbol",
                        domProps: { innerHTML: _vm._s(_vm.item.relationSymbol) }
                      })
                    : _vm._e()
                ])
              ]
            })
          ]
        : _vm._e()
    ],
    2
  )
};
var __vue_staticRenderFns__$N = [];
__vue_render__$N._withStripped = true;

  /* style */
  const __vue_inject_styles__$N = undefined;
  /* scoped */
  const __vue_scope_id__$N = undefined;
  /* functional template */
  const __vue_is_functional_template__$N = false;
  /* component normalizer */
  function __vue_normalize__$N(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\check-input-multi.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var CheckInputMulti = __vue_normalize__$N(
    { render: __vue_render__$N, staticRenderFns: __vue_staticRenderFns__$N },
    __vue_inject_styles__$N,
    __vue_script__$N,
    __vue_scope_id__$N,
    __vue_is_functional_template__$N);

//
var script$O = {
  mixins: [isNumber, formDisabled],
  props: {
    item: Object,
    value: {},
    index: [String, Number],
    role: String
  },
  data () {
    const data = this.getNewData(this.value);
    let selectedKey = data.currentKey;
    let inputVal = data.inputValue;
    let childKeyVal = data.childKeyVal;
    return {
      currentKey: selectedKey || [],
      inputValue: inputVal || '',
      childKeyVal: childKeyVal || {},
      idRand: Math.random().toString().slice(2),
      inputWarn: {},
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      const data = this.getNewData(val);
      if (this.currentKey[0] !== data.currentKey[0]) {
        this.currentKey = data.currentKey;
        this.childKeyVal = data.childKeyVal;
        if (this.inputValue !== data.inputValue) {
          this.inputValue = data.inputValue;
        }
      }
    },
    currentKey (val) {
      this.$emit('on-change', {
        key: val[0],
        value: this.item.value,
        selfInput: this.getParsedValue(this.inputValue),
        childKeyVal: this.getParsedKeyVal(this.childKeyVal),
        childKeyTitle: this.getKeyTitle(this.childKeyVal)
      }, this.item.key);
      this.$emit('on-validate', this.validate());
    },
    inputValue (val) {
      this.$emit('on-validate', this.validate());
      this.$emit('on-change', {
        key: this.currentKey[0],
        value: this.item.value,
        selfInput: this.getParsedValue(val),
        childKeyVal: this.getParsedKeyVal(this.childKeyVal),
        childKeyTitle: this.getKeyTitle(this.childKeyVal)
      }, this.item.key);
    }
  },
  mounted () {
    this.initialInputWarn();
  },
  methods: {
    getNewData (data) {
      if (!data) return {}
      const valobj = data[this.item.key];
      let selectedKey;
      let inputVal;
      let childKeyVal;
      if (valobj) {
        selectedKey = valobj['key'];
        inputVal = valobj['selfInput'];
        childKeyVal = valobj['childKeyVal'];
      }
      return {
        currentKey: (selectedKey ? [selectedKey] : []),
        inputValue: (inputVal || ''),
        childKeyVal: (childKeyVal || {})
      }
    },
    getParsedValue (val) {
      if (val && this.item.valueType === 'integer' && this.isNumber(val)) {
        return parseInt(val) || 0
      } else if (val && this.item.valueType === 'number' && this.isNumber(val)) {
        return Number(val) || 0
      }
      return val
    },
    getParsedKeyVal (val) {
      let keyVal = Object.assign({}, val);
      Object.keys(keyVal).forEach(key => {
        this.item.children.forEach(child => {
          if (child.key === key && child.valueType === 'integer' && this.isNumber(keyVal[key])) {
            keyVal[key] = parseInt(keyVal[key]) || 0;
          } else if (child.key === key && child.valueType === 'number' && this.isNumber(keyVal[key])) {
            keyVal[key] = Number(keyVal[key]) || 0;
          }
        });
      });
      return keyVal
    },
    getKeyTitle (val) {
      let keyTitle = Object.assign({}, val);
      Object.keys(keyTitle).forEach(key => {
        this.item.children.forEach(child => {
          if (child.key === key) {
            keyTitle[key] = child.title;
          }
        });
      });
      return keyTitle
    },
    onFocus (item) {
      if (!this.currentKey.length ||
          (this.currentKey.length && this.currentKey[0] !== item.key)) {
        this.currentKey = [item.key];
      }
    },
    onBlur () {
      //
    },
    onChange () {
      //
    },
    onInputChange () {
      this.$emit('on-validate', this.validate());
      this.$emit('on-change', {
        key: this.currentKey[0],
        value: this.item.value,
        selfInput: this.inputValue,
        childKeyVal: this.childKeyVal,
        childKeyTitle: this.getKeyTitle(this.childKeyVal)
      }, this.item.key);
    },
    initialInputWarn () {
      const children = this.item.children || [];
      children.forEach(child => {
        child.placeholder = '';
        this.$set(this.inputWarn, child.key, false);
      });
    },
    validate () {
      if (this.currentKey.indexOf(this.item.key) >= 0) {
        let result = false;
        const children = this.item.children || [];
        children.forEach(child => {
          const childVal = String(this.childKeyVal[child.key] || '');
          if (childVal && childVal.length) {
            result = true;
          }
        });
        children.forEach(child => {
          // const childVal = this.childKeyVal[child.key]
          // if (!(childVal && childVal.length)) {
          if (result) {
            this.$set(this.inputWarn, child.key, false);
            child.placeholder = '';
          } else {
            this.$set(this.inputWarn, child.key, true);
            child.placeholder = '请填写...';
          }
        });
        if (result) {
          this.initialInputWarn();
          return true
        }
        return false
      } else {
        this.initialInputWarn();
        return true
      }
    }
  }
};

/* script */
            const __vue_script__$O = script$O;
            
/* template */
var __vue_render__$O = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    { staticClass: "mselect-input-multi" },
    [
      _c(
        "label",
        {
          staticClass: "quest-item-answer input-inline",
          attrs: { for: "_id_" + _vm.index + _vm.idRand }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currentKey,
                expression: "currentKey"
              }
            ],
            staticClass: "quest-checkbox",
            attrs: {
              type: "checkbox",
              id: "_id_" + _vm.index + _vm.idRand,
              disabled: _vm.disabled
            },
            domProps: {
              value: _vm.item.key,
              checked: Array.isArray(_vm.currentKey)
                ? _vm._i(_vm.currentKey, _vm.item.key) > -1
                : _vm.currentKey
            },
            on: {
              change: function($event) {
                var $$a = _vm.currentKey,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = _vm.item.key,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.currentKey = $$a.concat([$$v]));
                  } else {
                    $$i > -1 &&
                      (_vm.currentKey = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.currentKey = $$c;
                }
              }
            }
          }),
          _vm._v(" "),
          _c("i", { staticClass: "weui-icon-checkbox" }),
          _vm._v(" "),
          _c("span", { staticClass: "quest-label" }, [
            _vm._v(_vm._s(_vm.item.label || _vm.item.title))
          ]),
          _vm._v(" "),
          _vm.currentKey.indexOf(_vm.item.key) >= 0
            ? _c(
                "div",
                {
                  staticClass: "mselect-input-inline",
                  on: {
                    click: function($event) {
                      $event.stopPropagation();
                      $event.preventDefault();
                      return (function() {
                        return false
                      })()
                    }
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inputValue,
                        expression: "inputValue"
                      }
                    ],
                    ref: "input",
                    attrs: {
                      placeholder: _vm.item.placeholder,
                      disabled: _vm.disabled
                    },
                    domProps: { value: _vm.inputValue },
                    on: {
                      focus: function($event) {
                        return _vm.onFocus(_vm.item)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.inputValue = $event.target.value;
                      }
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentKey.indexOf(_vm.item.key) >= 0
            ? _c("div", { staticClass: "input-inline-unit" }, [
                _vm._v(_vm._s(_vm.item.unit))
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.currentKey.indexOf(_vm.item.key) >= 0
        ? [
            _vm._l(_vm.item.children, function(child, index) {
              return [
                _c("div", { key: index, staticClass: "input-multi-line" }, [
                  child.label || child.title
                    ? _c("div", { staticClass: "input-inline-label" }, [
                        _vm._v(_vm._s(child.label || child.title))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.childKeyVal[child.key],
                        expression: "childKeyVal[child.key]"
                      }
                    ],
                    ref: "input_" + child.key,
                    refInFor: true,
                    class: { warn: _vm.inputWarn[child.key] },
                    attrs: {
                      placeholder: _vm.item.placeholder,
                      disabled: _vm.disabled
                    },
                    domProps: { value: _vm.childKeyVal[child.key] },
                    on: {
                      focus: function($event) {
                        return _vm.onFocus(_vm.item)
                      },
                      blur: _vm.onBlur,
                      change: _vm.onChange,
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.childKeyVal,
                            child.key,
                            $event.target.value
                          );
                        },
                        _vm.onInputChange
                      ]
                    }
                  }),
                  _vm._v(" "),
                  child.unit
                    ? _c("div", { staticClass: "input-inline-unit" }, [
                        _vm._v(_vm._s(child.unit))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  index < _vm.item.children.length - 1 &&
                  _vm.item.relationSymbol
                    ? _c("div", {
                        staticClass: "input-inline-symbol",
                        domProps: { innerHTML: _vm._s(_vm.item.relationSymbol) }
                      })
                    : _vm._e()
                ])
              ]
            })
          ]
        : _vm._e()
    ],
    2
  )
};
var __vue_staticRenderFns__$O = [];
__vue_render__$O._withStripped = true;

  /* style */
  const __vue_inject_styles__$O = undefined;
  /* scoped */
  const __vue_scope_id__$O = undefined;
  /* functional template */
  const __vue_is_functional_template__$O = false;
  /* component normalizer */
  function __vue_normalize__$O(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\check-input-multi-self-input.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var CheckInputMultiSelfInput = __vue_normalize__$O(
    { render: __vue_render__$O, staticRenderFns: __vue_staticRenderFns__$O },
    __vue_inject_styles__$O,
    __vue_script__$O,
    __vue_scope_id__$O,
    __vue_is_functional_template__$O);

//
var script$P = {
  name: 'QuestItemMselectInput',
  components: { Check, CheckInput, CheckInputInline, CheckInputMulti, CheckInputMultiSelfInput },
  mixins: [Emitter],
  props: {
    role: String,
    title: String,
    items: Array,
    value: {},
    warn: {
      type: Boolean,
      default: false
    },
    triggeredItems: Array,
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: this.mapArray2Object(this.value) || {},
      showItem: !(this.triggeredItems && this.triggeredItems.length > 0) || (this.value && this.value.length > 0),
      showWarn: this.warn,
      warnContent: '未完成选择'
    }
  },
  watch: {
    value (val) {
      this.selected = this.mapArray2Object(val);
    },
    warn (val) {
      this.showWarn = !!val;
    }
  },
  created () {
    if (this.triggeredItems) {
      this.$on('on-triggered', (triggerId, status) => {
        if (this.triggeredItems.indexOf(triggerId) >= 0 && status) {
          this.showItem = true;
        } else if (this.triggeredItems.indexOf(triggerId) >= 0 && !status) {
          this.showItem = false;
        }
      });
    }
  },
  methods: {
    validate () {
      if (this.triggeredItems && this.triggeredItems.length) return true // 由触发显示，可能条件不足被隐藏了，所以一律不校验
      if (Object.keys(this.selected || {}).length === 0 && this.$device.isDesktop) {
        return true
      } else if (this.required && Object.keys(this.selected || {}).length === 0) {
        this.warnContent = '未完成选择';
        this.showWarn = true;
        return false
      }
      let result = true;
      if (this.$refs.inputMultiSelfInput) {
        this.$refs.inputMultiSelfInput.forEach(item => {
          if (!item.validate()) { result = false; }
        });
      }
      if (this.$refs.inputMulti) {
        this.$refs.inputMulti.forEach(item => {
          if (!item.validate()) { result = false; }
        });
      }
      if (this.$refs.inputInline) {
        this.$refs.inputInline.forEach(item => {
          if (!item.validate()) { result = false; }
        });
      }
      if (this.$refs.input) {
        this.$refs.input.forEach(item => {
          if (!item.validate()) { result = false; }
        });
      }
      if (!result) {
        this.warnContent = '未完成填写';
        this.showWarn = true;
      } else {
        this.showWarn = false;
      }
      return result
    },
    isValidated () {
      return this.validate()
    },
    onSelectChange (valobj, key) {
      if (valobj.key) {
        this.selected[key] = valobj;
        // this.validate()
      } else if (!valobj.key && this.selected[key]) {
        delete this.selected[key]; // 无法触发 selected watcher
        // this.validate()
      }
      this.$emit('input', this.mapObject2Array(this.selected));
      this.$emit('on-change', this.mapObject2Array(this.selected));
    },
    onCancel () {
      if (this.required) return
      this.selected = {};
      this.$emit('input', []);
      this.$emit('on-change', []);
      this.showWarn = false;
    },
    mapArray2Object (data) {
      if (!Array.isArray(data)) return {}
      let obj = {};
      data.forEach(item => {
        obj[item.key] = item;
      });
      return obj
    },
    mapObject2Array (data) {
      let array = [];
      Object.keys(data || {}).forEach(key => {
        array.push(data[key]);
      });
      return array
    }
  }
};

/* script */
            const __vue_script__$P = script$P;
            
/* template */
var __vue_render__$P = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showItem,
          expression: "showItem"
        }
      ],
      staticClass: "quest-item-select"
    },
    [
      _c("div", { staticClass: "quest-item-title" }, [
        _c("a", { staticClass: "quest-title", class: { warn: _vm.showWarn } }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showWarn,
                expression: "showWarn"
              }
            ],
            staticClass: "quest-tip-warn"
          },
          [
            _c("i", { staticClass: "weui-icon-warn" }),
            _vm._v(_vm._s(_vm.warnContent))
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showWarn,
                expression: "showWarn"
              }
            ],
            staticClass: "quest-tip-cancel",
            on: {
              click: function($event) {
                $event.stopPropagation();
                return _vm.onCancel($event)
              }
            }
          },
          [_c("i", { staticClass: "weui-icon-cancel" })]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "quest-item-answer-list" },
        [
          _vm._l(_vm.items, function(item, index) {
            return [
              item.type === "mselect_input_multi_self_input"
                ? _c("check-input-multi-self-input", {
                    key: item.key,
                    ref: "inputMultiSelfInput",
                    refInFor: true,
                    attrs: {
                      index: index,
                      role: _vm.role,
                      item: item,
                      value: _vm.selected
                    },
                    on: {
                      "on-validate": _vm.isValidated,
                      "on-change": _vm.onSelectChange
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              item.type === "mselect_input_multi"
                ? _c("check-input-multi", {
                    key: item.key,
                    ref: "inputMulti",
                    refInFor: true,
                    attrs: {
                      index: index,
                      role: _vm.role,
                      item: item,
                      value: _vm.selected
                    },
                    on: {
                      "on-validate": _vm.isValidated,
                      "on-change": _vm.onSelectChange
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              item.type === "mselect_input_inline"
                ? _c("check-input-inline", {
                    key: item.key,
                    ref: "inputInline",
                    refInFor: true,
                    attrs: {
                      index: index,
                      role: _vm.role,
                      item: item,
                      value: _vm.selected
                    },
                    on: {
                      "on-validate": _vm.isValidated,
                      "on-change": _vm.onSelectChange
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              item.type === "mselect_input"
                ? _c("check-input", {
                    key: item.key,
                    ref: "input",
                    refInFor: true,
                    attrs: {
                      index: index,
                      role: _vm.role,
                      item: item,
                      value: _vm.selected
                    },
                    on: {
                      "on-validate": _vm.isValidated,
                      "on-change": _vm.onSelectChange
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              item.type === "mselect"
                ? _c("check", {
                    key: item.key,
                    attrs: {
                      index: index,
                      role: _vm.role,
                      item: item,
                      value: _vm.selected
                    },
                    on: {
                      "on-validate": _vm.isValidated,
                      "on-change": _vm.onSelectChange
                    }
                  })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ]
  )
};
var __vue_staticRenderFns__$P = [];
__vue_render__$P._withStripped = true;

  /* style */
  const __vue_inject_styles__$P = undefined;
  /* scoped */
  const __vue_scope_id__$P = undefined;
  /* functional template */
  const __vue_is_functional_template__$P = false;
  /* component normalizer */
  function __vue_normalize__$P(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\quest-item-mselect-input.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var QuestItemMselectInput = __vue_normalize__$P(
    { render: __vue_render__$P, staticRenderFns: __vue_staticRenderFns__$P },
    __vue_inject_styles__$P,
    __vue_script__$P,
    __vue_scope_id__$P,
    __vue_is_functional_template__$P);

//
//
//
//
//
//
//
//
//
//
//
//
//

var script$Q = {
  name: 'ShowScore',
  props: {
    value: {},
    item: Object
  },
  data () {
    return {
      currentValue: this.getValue(this.value),
      tipContent: this.getChildTip(this.value)
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
    },
    currentValue (val) {
      this.$emit('on-change', val, this.item.key);
      this.tipContent = this.getChildTip(val);
      try {
        let triggerFn = (new Function(this.item.trigger))();
        if (triggerFn(val)) {
          this.$emit('on-trigger', this.item.key, true);
        } else {
          this.$emit('on-trigger', this.item.key, false);
        }
      } catch (e) {}
    }
  },
  created () {
    this.$on('on-score-update', (scores) => {
      try {
        let scoreFn = (new Function(this.item.fn))();
        let scoreVal = '';
        if (Array.isArray(scores) && scores.length) {
          scoreVal = scoreFn(scores);
          scoreVal = this.decimal2(scoreVal);
        }
        scoreVal = String(scoreVal);
        if (this.item.valueType === 'number') {
          scoreVal = Number(scoreVal);
        }
        this.currentValue = scoreVal;
      } catch (e) {}
    });
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      if (data[this.item.key] === 0) return 0  // fig bug score is 0
      return data[this.item.key] || ''
    },
    getChildTip (val) {
      if (!val) return ''

      let tipContent = '';
      const scoreVal = Number(val);
      const children = this.item.children || [];
      children.forEach(child => {
        let min = Number(child.min);
        let max = Number(child.max);
        if (scoreVal >= min && scoreVal <= max) {
          tipContent = child.title;
        }
      });
      return tipContent
    },
    decimal2 (val) {
      let _val = Number(val);
      let valParts = _val.toString().split('.');
      if (valParts.length === 2 && valParts[1].length > 2) {
        _val = Math.floor(_val * 100) / 100;
      }
      return _val
    }
  }
};

/* script */
            const __vue_script__$Q = script$Q;
            
/* template */
var __vue_render__$Q = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("label", { staticClass: "quest-item-answer" }, [
    _c(
      "div",
      {
        staticClass: "quest-input",
        class: { "input-inline": _vm.item.type === "show_score" }
      },
      [
        _c("span", { staticClass: "quest-label input-inline" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "input-picker score" }, [
          _vm._v(_vm._s(_vm.currentValue))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-inline-unit" }, [
          _vm._v(_vm._s(_vm.item.unit))
        ])
      ]
    ),
    _vm._v(" "),
    _vm.item.children
      ? _c("div", { staticClass: "quest-tip" }, [
          _vm.tipContent
            ? _c("span", { staticClass: "quest-label" }, [
                _vm._v(_vm._s(_vm.tipContent))
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
};
var __vue_staticRenderFns__$Q = [];
__vue_render__$Q._withStripped = true;

  /* style */
  const __vue_inject_styles__$Q = undefined;
  /* scoped */
  const __vue_scope_id__$Q = undefined;
  /* functional template */
  const __vue_is_functional_template__$Q = false;
  /* component normalizer */
  function __vue_normalize__$Q(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\show-score.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var ShowScore = __vue_normalize__$Q(
    { render: __vue_render__$Q, staticRenderFns: __vue_staticRenderFns__$Q },
    __vue_inject_styles__$Q,
    __vue_script__$Q,
    __vue_scope_id__$Q,
    __vue_is_functional_template__$Q);

//
//
//
//
//
//
//
//

var script$R = {
  props: {
    item: Object
  }
};

/* script */
            const __vue_script__$R = script$R;
            
/* template */
var __vue_render__$R = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("label", { staticClass: "quest-item-answer" }, [
    _c("div", { staticClass: "quest-tip" }, [
      _c("span", { staticClass: "quest-label" }, [
        _vm._v(_vm._s(_vm.item.label || _vm.item.title))
      ])
    ])
  ])
};
var __vue_staticRenderFns__$R = [];
__vue_render__$R._withStripped = true;

  /* style */
  const __vue_inject_styles__$R = undefined;
  /* scoped */
  const __vue_scope_id__$R = undefined;
  /* functional template */
  const __vue_is_functional_template__$R = false;
  /* component normalizer */
  function __vue_normalize__$R(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\show-tip.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var ShowTip = __vue_normalize__$R(
    { render: __vue_render__$R, staticRenderFns: __vue_staticRenderFns__$R },
    __vue_inject_styles__$R,
    __vue_script__$R,
    __vue_scope_id__$R,
    __vue_is_functional_template__$R);

//
var script$S = {
  name: 'QuestItemShow',
  mixins: [Emitter],
  components: {ShowScore, ShowTip},
  props: {
    role: String,
    title: String,
    value: {},
    items: Array,
    warn: {
      type: Boolean,
      default: false
    },
    triggeredItems: Array
  },
  data () {
    return {
      showData: this.value || {},
      showTip: this.warn,
      tipContent: '您还未完成填写'
    }
  },
  computed: {
    show () {
      return Object.keys(this.showData || {}).length > 0
    }
  },
  watch: {
    value (val) {
      this.showData = val || {}; // fix 新的输入没有值，不用触发更新 2022.5.19
    },
    warn (val) {
      this.showTip = !!val;
    }
  },
  methods: {
    onScoreChange (val, key) {
      this.$set(this.showData, key, val);
      this.$emit('input', this.showData);
      this.$emit('on-change', this.showData);
    },
    onTrigger (itemId, status) {
      this.dispatch('QuestItemList', 'on-trigger', itemId, status);
    }
  }
};

/* script */
            const __vue_script__$S = script$S;
            
/* template */
var __vue_render__$S = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "quest-item-input" }, [
    _vm.title
      ? _c("div", { staticClass: "quest-item-title" }, [
          _c(
            "a",
            { staticClass: "quest-title", class: { warn: _vm.showTip } },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTip,
                  expression: "showTip"
                }
              ],
              staticClass: "quest-tip-warn"
            },
            [
              _c("i", { staticClass: "weui-icon-warn" }),
              _vm._v(_vm._s(_vm.tipContent))
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "quest-item-answer-list" },
      [
        _vm._l(_vm.items, function(item) {
          return [
            item.type === "show_score"
              ? _c("show-score", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show,
                      expression: "show"
                    }
                  ],
                  key: "score" + item.key,
                  attrs: { item: item },
                  on: {
                    "on-change": _vm.onScoreChange,
                    "on-trigger": _vm.onTrigger
                  },
                  model: {
                    value: _vm.showData,
                    callback: function($$v) {
                      _vm.showData = $$v;
                    },
                    expression: "showData"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "show_tip"
              ? _c("show-tip", { key: "tip" + item.key, attrs: { item: item } })
              : _vm._e()
          ]
        })
      ],
      2
    )
  ])
};
var __vue_staticRenderFns__$S = [];
__vue_render__$S._withStripped = true;

  /* style */
  const __vue_inject_styles__$S = undefined;
  /* scoped */
  const __vue_scope_id__$S = undefined;
  /* functional template */
  const __vue_is_functional_template__$S = false;
  /* component normalizer */
  function __vue_normalize__$S(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\quest-item-show.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var QuestItemShow = __vue_normalize__$S(
    { render: __vue_render__$S, staticRenderFns: __vue_staticRenderFns__$S },
    __vue_inject_styles__$S,
    __vue_script__$S,
    __vue_scope_id__$S,
    __vue_is_functional_template__$S);

//

var script$T = {
  mixins: [Emitter, equal],
  props: {
    crfId: String,
    modelId: String,
    questId: String,
    patientId: String
  },
  data () {
    return {
      role: '',
      valueId: '',
      collection: '',
      hasScore: false,

      questItemList: [],
      questItemData: {},
      questItemDataInital: {},
      hasDataChange: false
    }
  },
  created () {
    this.$on('on-score-change', (score) => {
      this.broadcast('ShowScore', 'on-score-update', score);
    });
  },
  mounted () {
    const item = this.getQuestListItem(this.modelId, this.questId);
    this.valueId = item.valueId;
    this.collection = item.collection;
    this.role = item.role;
    this.hasScore = item.hasScore;

    this.loadItemList();
    if (this.crfId !== 'unknown') {
      // 用于 manage, 不加载数据
      this.loadItemData();
    }
  },
  methods: {
    loadItemList () {
      let fieldListPath = 'crf/crfTable/field/list';
      if (!this.crfId || this.crfId === 'unknown') {
        fieldListPath = 'crf/crfTable/field/list/all';
      }
      this.$http.get(`${this.httpRoot}/${fieldListPath}`,
        authenticate({params: {id: this.modelId, crfId: this.crfId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.questItemList = res.fieldList;
        }
      })
      .catch(err => {
        catchExpired(err, this);
      });
    },
    loadItemData () {
      this.$http.get(`${this.httpRoot}/followup/data/detail`,
        authenticate({params: {collection: this.collection, valueId: this.valueId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$nextTick(() => {
            this.questItemDataInital = JSON.parse(JSON.stringify(res.itemData)); // this.deepCopy(res.itemData)
            this.questItemData = res.itemData;
          });
        }
      })
      .catch(err => {
        catchExpired(err, this);
      });
    },
    onShowChange (val) {
      console.log('on-show-change');
      console.log(this.questItemData, val);
      this.hasDataChange = (JSON.stringify(this.questItemData) !== JSON.stringify(this.questItemDataInital)); // !this.equals(this.questItemData, this.questItemDataInital)
    },
    onChange (val) {
      console.log(this.questItemData, val);
      this.hasDataChange = (JSON.stringify(this.questItemData) !== JSON.stringify(this.questItemDataInital)); // !this.equals(this.questItemData, this.questItemDataInital)
      this.$emit('on-data-change', this.hasDataChange);
      this.$emit('on-validate');
      // 根据 questItemList 中的评分项算分
      if (this.hasScore) {
        let score = this.getScore();
        console.log('score is ', score);
        if (this.hasAllScoreSelect(score)) {
          this.$emit('on-score-change', score);
        }
      }
    },
    onQuestItemListDataChange (hasChange) {
      if (hasChange) {
        this.hasDataChange = true;
      }
    },
    clearItemData () {
      this.questItemData = {};
    },
    submitItemData (successCallback, failedCallback) {
      this.showLoading = true;
      // fix 2022.8.11 保存数据后，记录最新的拷贝数据
      this.questItemDataInital = JSON.parse(JSON.stringify(this.questItemData));
      const patientId = this.patientId || getStorageItem('patientId');
      this.$http.post(`${this.httpRoot}/followup/data/update`, {
        followupId: this.questId,
        collection: this.collection,
        modelId: this.modelId,
        valueId: this.valueId,
        itemData: this.questItemData,
        patientId: patientId
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false;
          if (res.valueId) {
            this.updateValueId(res.valueId, this.modelId, this.questId);
          }
          if (!this.$refs.sselectShow || (this.$refs.sselectShow && !this.$refs.sselectShow.length)) {
            if (typeof successCallback === 'function') {
              successCallback();
            }
          }
        } else {
          if (typeof failedCallback === 'function') {
            failedCallback(res.statusText);
          }
        }
      })
      .catch(err => {
        this.showLoading = false;
        catchExpired(err, this);
        if (typeof failedCallback === 'function') {
          failedCallback(`${err.status} ${err.statusText} ${err.body}`);
        }
      });
      if (this.$refs.sselectShow) {
        this.$refs.sselectShow.forEach(ss => {
          ss.submitQuestLists(successCallback, failedCallback);
        });
      }
    },
    getQuestListItem (modelId, questId) {
      let _item = {};
      const followupItems = getStorageItem('followupItems_' + questId) || [];
      followupItems.forEach(item => {
        if (item.modelId === modelId) {
          _item = item;
        }
      });
      return _item
    },
    updateValueId (valueId, modelId, questId) {
      this.valueId = valueId;
      const followupItems = getStorageItem('followupItems_' + questId) || [];
      followupItems.forEach(item => {
        if (item.modelId === modelId) {
          item.valueId = valueId;
        }
      });
      updateStorageItem('followupItems_' + questId, followupItems);
    },
    getQuestItemByKey (key) {
      let _item;
      this.questItemList.forEach(item => {
        if (item.key === key) {
          _item = item;
        }
      });
      return _item
    },
    getScore () {
      let score = [];
      Object.keys(this.questItemData || {}).forEach(key => {
        const item = this.getQuestItemByKey(key);
        if (item && item.fieldType === 'score') {
          score.push({'v': Number(this.questItemData[key].value), 'i': Number(this.questItemData[key].scoreIndex || '')});
        }
      });
      return score
    },
    hasAllScoreSelect (score) {
      let modelScoreCount = 0;
      this.questItemList.forEach(item => {
        if (item.fieldType === 'score') {
          modelScoreCount++;
        }
      });
      if (score.length === modelScoreCount) {
        return true
      }
      return false
    },
    validate () {
      let result = true;
      if (this.$refs.input) {
        this.$refs.input.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.sselectShow) {
        this.$refs.sselectShow.forEach(item => {
          if (item.validate && !item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.sselect) {
        this.$refs.sselect.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.mselect) {
        this.$refs.mselect.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      if (this.$refs.mselectInput) {
        this.$refs.mselectInput.forEach(item => {
          if (!item.validate()) {
            result = false;
          }
        });
      }
      return result
    }
  },
  components: {
    QuestItemSselect,
    QuestItemMselect,
    QuestItemMselectInput,
    QuestItemInput,
    QuestItemShow
  }
};

/* script */
            const __vue_script__$T = script$T;
            
/* template */
var __vue_render__$T = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    [
      _vm._l(_vm.questItemList, function(item) {
        return [
          item.type === "input"
            ? _c("quest-item-input", {
                key: item.key,
                ref: "input",
                refInFor: true,
                attrs: {
                  role: _vm.role,
                  title: item.title,
                  items: item.items,
                  hideTitle: item.hideTitle,
                  triggeredItems: item.triggeredItems
                },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.questItemData[item.key],
                  callback: function($$v) {
                    _vm.$set(_vm.questItemData, item.key, $$v);
                  },
                  expression: "questItemData[item.key]"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          item.type === "sselect" || item.type === "sselect_inline"
            ? _c("quest-item-sselect", {
                key: item.key,
                ref: "sselect",
                refInFor: true,
                attrs: {
                  role: _vm.role,
                  inline: item.type === "sselect_inline",
                  title: item.title,
                  items: item.items,
                  triggeredItems: item.triggeredItems,
                  scoreIndex: item.scoreIndex
                },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.questItemData[item.key],
                  callback: function($$v) {
                    _vm.$set(_vm.questItemData, item.key, $$v);
                  },
                  expression: "questItemData[item.key]"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          item.type === "sselect_show_quest"
            ? _c("quest-item-sselect-show", {
                key: item.key,
                ref: "sselectShow",
                refInFor: true,
                attrs: {
                  inline: item.type === "sselect_inline",
                  role: _vm.role,
                  title: item.title,
                  items: item.items,
                  questId: _vm.questId,
                  patientId: _vm.patientId,
                  crfId: _vm.crfId,
                  triggeredItems: item.triggeredItems
                },
                on: {
                  "on-data-change": _vm.onQuestItemListDataChange,
                  "on-change": _vm.onChange
                },
                model: {
                  value: _vm.questItemData[item.key],
                  callback: function($$v) {
                    _vm.$set(_vm.questItemData, item.key, $$v);
                  },
                  expression: "questItemData[item.key]"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          item.type === "mselect"
            ? _c("quest-item-mselect", {
                key: item.key,
                ref: "mselect",
                refInFor: true,
                attrs: {
                  role: _vm.role,
                  title: item.title,
                  items: item.items,
                  triggeredItems: item.triggeredItems
                },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.questItemData[item.key],
                  callback: function($$v) {
                    _vm.$set(_vm.questItemData, item.key, $$v);
                  },
                  expression: "questItemData[item.key]"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          item.type === "mselect_input"
            ? _c("quest-item-mselect-input", {
                key: item.key,
                ref: "mselectInput",
                refInFor: true,
                attrs: {
                  role: _vm.role,
                  title: item.title,
                  items: item.items,
                  triggeredItems: item.triggeredItems
                },
                on: { "on-change": _vm.onChange },
                model: {
                  value: _vm.questItemData[item.key],
                  callback: function($$v) {
                    _vm.$set(_vm.questItemData, item.key, $$v);
                  },
                  expression: "questItemData[item.key]"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          item.type === "show"
            ? _c("quest-item-show", {
                key: item.key,
                attrs: {
                  role: _vm.role,
                  title: item.title,
                  triggeredItems: item.triggeredItems,
                  items: item.items
                },
                on: { "on-change": _vm.onShowChange },
                model: {
                  value: _vm.questItemData[item.key],
                  callback: function($$v) {
                    _vm.$set(_vm.questItemData, item.key, $$v);
                  },
                  expression: "questItemData[item.key]"
                }
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
};
var __vue_staticRenderFns__$T = [];
__vue_render__$T._withStripped = true;

  /* style */
  const __vue_inject_styles__$T = undefined;
  /* scoped */
  const __vue_scope_id__$T = undefined;
  /* functional template */
  const __vue_is_functional_template__$T = false;
  /* component normalizer */
  function __vue_normalize__$T(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\quest-item-list.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var QuestItemList = __vue_normalize__$T(
    { render: __vue_render__$T, staticRenderFns: __vue_staticRenderFns__$T },
    __vue_inject_styles__$T,
    __vue_script__$T,
    __vue_scope_id__$T,
    __vue_is_functional_template__$T);

//
var script$U = {
  components: { QuestItemList },
  mixins: [sselectValid, formDisabled],
  props: {
    role: String,
    title: String,
    items: Array,
    crfId: String,
    questId: String,
    patientId: String,
    value: {},
    triggeredItems: Array,
    warn: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentKey: this.getKey(this.value),
      idRand: Math.random().toString().slice(2),
      showWarn: this.warn,
      warnContent: '未完成选择',
      inputWarn: {},
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      this.currentKey = this.getKey(val);
    },
    currentKey (val) {
      this.emitChange(val);
      this.initialInputWarn();
    },
    warn (val) {
      this.showWarn = !!val;
    }
  },
  methods: {
    getKey (pdata) {
      let data = {};
      if (pdata) data = JSON.parse(JSON.stringify(pdata));
      const value = data['key'] = data['key'] || '';
      return value
    },
    getCurrentItem () {
      let currentItem;
      this.items.forEach(item => {
        if (item.key === this.currentKey) {
          currentItem = item;
        }
      });
      return currentItem
    },
    validate () {
      this.showWarn = false;
      let result = this.valid();
      if (this.$refs.questLists) {
        this.$refs.questLists.forEach(quest => {
          let key = quest.$el.parentNode.getAttribute('index');
          if (key === this.currentKey) {
            result = !!quest.validate();
            if (!result) {
              this.showWarn = true;
            }
          }
        });
      }
      return result
    },
    isValidated () {
      return this.validate()
    },
    emitChange (currentKey) {
      if (!this.currentKey) {
        this.$emit('input', {});
        this.$emit('on-change', {});
      } else {
        const val = this.getValue(currentKey);
        this.$emit('input', {key: currentKey, value: val});
        this.$emit('on-change', {key: currentKey, value: val});
      }
    },
    getValue (key) {
      let val = '';
      this.items.forEach(item => {
        if (key === item.key) {
          val = item.value;
        }
      });
      return val
    },
    onQuestItemListDataChange (hasChange) {
      if (hasChange) {
        this.$emit('on-data-change', hasChange);
      }
    },
    submitQuestLists (successCallback, failedCallback) {
      if (!this.$refs.questLists) {
        if (typeof successCallback === 'function') {
          successCallback();
        }
        return
      }
      this.$refs.questLists.forEach(quest => {
        let key = quest.$el.parentNode.getAttribute('index');
        let hasCalled = false;  // 防止存在有选项没有关联questLists，又恰好选了该选项
        if (key === this.currentKey) {
          hasCalled = true;
          quest.submitItemData(successCallback, failedCallback);
        } else {
          // 清除另一个选择关联表的数据，并保存
          hasCalled = true;
          quest.clearItemData();
          quest.submitItemData(successCallback, failedCallback);
        }
        if (!hasCalled && typeof successCallback === 'function') {
          successCallback();
        }
      });
    },
    toggleClick (item, e) {
      if (item.key === this.currentKey) {
        e.preventDefault();
        this.currentKey = '';
      }
    }
  }
};

/* script */
            const __vue_script__$U = script$U;
            
/* template */
var __vue_render__$U = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "quest-item-select" }, [
    _c("div", { staticClass: "quest-item-title" }, [
      _c("a", { staticClass: "quest-title", class: { warn: _vm.showWarn } }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showWarn,
              expression: "showWarn"
            }
          ],
          staticClass: "quest-tip-warn"
        },
        [
          _c("i", { staticClass: "weui-icon-warn" }),
          _vm._v(_vm._s(_vm.warnContent))
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "quest-item-answer-list" },
      [
        _vm._l(_vm.items, function(item, idxx) {
          return [
            _c(
              "label",
              {
                key: "sselect" + item.key,
                staticClass: "quest-item-answer",
                class: {
                  inline: _vm.inline || item.type === "sselect_inline",
                  "sselect-input-inline": item.type === "sselect_input"
                },
                attrs: { for: "_id_" + idxx + _vm.idRand }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "quest-radio-outer",
                    class: { checked: item.key === _vm.currentKey }
                  },
                  [
                    _c("span", {
                      staticClass: "quest-radio-inner",
                      on: {
                        click: function($event) {
                          $event.stopPropagation();
                          return _vm.toggleClick(item, $event)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentKey,
                          expression: "currentKey"
                        }
                      ],
                      staticClass: "quest-radio",
                      attrs: {
                        type: "radio",
                        id: "_id_" + idxx + _vm.idRand,
                        disabled: _vm.disabled
                      },
                      domProps: {
                        value: item.key,
                        checked: _vm._q(_vm.currentKey, item.key)
                      },
                      on: {
                        change: function($event) {
                          _vm.currentKey = item.key;
                        }
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "quest-label" }, [
                  _vm._v(_vm._s(item.label || item.title))
                ])
              ]
            )
          ]
        }),
        _vm._v(" "),
        _vm._l(_vm.items, function(item) {
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.key === _vm.currentKey,
                    expression: "item.key === currentKey"
                  }
                ],
                key: "quest_item_list" + item.key,
                staticClass: "quest-table",
                attrs: { index: item.key }
              },
              [
                item.modelId
                  ? _c("quest-item-list", {
                      ref: "questLists",
                      refInFor: true,
                      attrs: {
                        modelId: item.modelId,
                        questId: _vm.questId,
                        patientId: _vm.patientId,
                        crfId: _vm.crfId
                      },
                      on: {
                        "on-validate": _vm.isValidated,
                        "on-data-change": _vm.onQuestItemListDataChange
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        })
      ],
      2
    )
  ])
};
var __vue_staticRenderFns__$U = [];
__vue_render__$U._withStripped = true;

  /* style */
  const __vue_inject_styles__$U = undefined;
  /* scoped */
  const __vue_scope_id__$U = undefined;
  /* functional template */
  const __vue_is_functional_template__$U = false;
  /* component normalizer */
  function __vue_normalize__$U(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\quest-item-sselect-show.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var questItemSselectShow = __vue_normalize__$U(
    { render: __vue_render__$U, staticRenderFns: __vue_staticRenderFns__$U },
    __vue_inject_styles__$U,
    __vue_script__$U,
    __vue_scope_id__$U,
    __vue_is_functional_template__$U);

//
var script$V = {
  components: {UploadImgWin},
  mixins: [formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      viewStyle: 'oneline', // 'grid'
      showUploadWin: false,
      currentValue: this.getValue(this.value),
      disabled: this.getDisabled(),
      currentImage: {name: '', url: '', originalUrl: '', desc: ''}
    }
  },
  computed: {
    fileRoot () {
      return this.$store.state.url && this.$store.state.url.fileRoot
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
    },
    currentValue (val) {
      this.$emit('on-change', this.currentValue, this.item.key);
    }
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      return data[this.item.key] || ''
    },
    onShowUpload () {
      this.currentImage = {name: '', url: '', originalUrl: '', desc: ''};
      this.showUploadWin = true;
    },
    onImageClick (item) {
      this.currentImage = item;
      this.showUploadWin = true;
    },
    onUploaded (val) {
      let idx = -1;
      this.currentValue = this.currentValue || [];
      this.currentValue.forEach((item, index) => {
        if (item.url === val.url) {
          idx = index;
        }
      });
      if (idx < 0) {
        this.currentValue.push(val);
      } else {
        this.currentValue.splice(idx, 1, val);
      }
    },
    onDownload (item) {
      window.open(item.originalUrl);
    },
    onDelete (item) {
      this.$Modal.confirm({
        content: '确认删除影像文件？',
        onOk: () => {
          this.deleteFile(item, () => {
            let idx = -1;
            this.currentValue.forEach((_item, index) => {
              if (_item.url === item.url) {
                idx = index;
              }
            });
            if (idx > -1) {
              this.currentValue.splice(idx, 1);
            }
          });
        }
      });
    },
    deleteFile (val, callback) {
      this.$http.post(`${this.fileRoot}/auth/image/delete`, {url: val.url}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback();
          }
        }
      })
      .catch(err => {
        catchExpired(err, this);
      });
    }
  }
};

/* script */
            const __vue_script__$V = script$V;
            
/* template */
var __vue_render__$V = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    { staticClass: "quest-item-answer" },
    [
      _c("div", { staticClass: "quest-overview-header" }, [
        _c("a", { staticClass: "quest-overview-title" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "quest-overview-toolbar" }, [
          _c("span", {
            staticClass: "ionic-icon ionic-icon-android-upload",
            on: { click: _vm.onShowUpload }
          }),
          _vm._v(" "),
          _vm.$device.isDesktop
            ? _c("span", {
                staticClass: "ionic-icon ionic-icon-more",
                on: {
                  click: function($event) {
                    _vm.viewStyle = "oneline";
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$device.isDesktop
            ? _c("span", {
                staticClass: "ionic-icon ionic-icon-grid",
                on: {
                  click: function($event) {
                    _vm.viewStyle = "grid";
                  }
                }
              })
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "quest-overview-body", class: ["" + _vm.viewStyle] },
        [
          _vm.currentValue && _vm.currentValue.length
            ? _c(
                "div",
                {
                  staticClass: "quest-overview-image-container",
                  style: {
                    width:
                      _vm.viewStyle === "oneline"
                        ? _vm.currentValue.length * 200 + "px"
                        : "auto"
                  }
                },
                _vm._l(_vm.currentValue, function(item) {
                  return _c(
                    "div",
                    { key: item.url, staticClass: "quest-overview-image" },
                    [
                      _c("div", { staticClass: "quest-overview-image-inner" }, [
                        _c(
                          "div",
                          {
                            staticClass: "overview-image-box",
                            on: {
                              click: function($event) {
                                $event.stopPropagation();
                                return _vm.onImageClick(item)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: { src: item.url, title: item.desc }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "overview-image-desc" }, [
                          _c(
                            "span",
                            {
                              staticClass: "filename",
                              attrs: { title: item.name }
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "filedesc" }, [
                            _vm._v(_vm._s(item.desc))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "overview-image-toolbar" }, [
                          _c("span", {
                            staticClass:
                              "ionic-icon ionic-icon-ios-cloud-download-outline",
                            on: {
                              click: function($event) {
                                $event.stopPropagation();
                                return _vm.onDownload(item)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass:
                              "ionic-icon ionic-icon-ios-trash-outline",
                            on: {
                              click: function($event) {
                                $event.stopPropagation();
                                return _vm.onDelete(item)
                              }
                            }
                          })
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("upload-img-win", {
        attrs: {
          show: _vm.showUploadWin,
          title: "编辑上传影像",
          label: "影像描述："
        },
        on: {
          "on-hide": function($event) {
            _vm.showUploadWin = false;
          },
          "on-confirm-ok": _vm.onUploaded
        },
        model: {
          value: _vm.currentImage,
          callback: function($$v) {
            _vm.currentImage = $$v;
          },
          expression: "currentImage"
        }
      })
    ],
    1
  )
};
var __vue_staticRenderFns__$V = [];
__vue_render__$V._withStripped = true;

  /* style */
  const __vue_inject_styles__$V = undefined;
  /* scoped */
  const __vue_scope_id__$V = undefined;
  /* functional template */
  const __vue_is_functional_template__$V = false;
  /* component normalizer */
  function __vue_normalize__$V(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\upload-image.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var UploadImage = __vue_normalize__$V(
    { render: __vue_render__$V, staticRenderFns: __vue_staticRenderFns__$V },
    __vue_inject_styles__$V,
    __vue_script__$V,
    __vue_scope_id__$V,
    __vue_is_functional_template__$V);

//
var script$W = {
  components: {UploadFileWin},
  mixins: [formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      viewStyle: 'oneline', // 'grid' 'list'
      showUploadWin: false,
      currentValue: this.getValue(this.value),
      disabled: this.getDisabled()
    }
  },
  computed: {
    fileRoot () {
      return this.$store.state.url && this.$store.state.url.fileRoot
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
    },
    currentValue (val) {
      this.$emit('on-change', this.currentValue, this.item.key);
    }
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      return data[this.item.key] || ''
    },
    onShowUpload () {
      this.showUploadWin = true;
    },
    onUploaded (val) {
      let idx = -1;
      this.currentValue = this.currentValue || [];
      this.currentValue.forEach((item, index) => {
        if (item.url === val.url) {
          idx = index;
        }
      });
      if (idx < 0) {
        this.currentValue.push(val);
      } else {
        this.currentValue.splice(idx, 1, val);
      }
    },
    onDownload (item) {
      window.open(item.url);
    },
    onDelete (item) {
      this.$Modal.confirm({
        content: '确认删除该文件？',
        onOk: () => {
          this.deleteFile(item, () => {
            let idx = -1;
            this.currentValue.forEach((_item, index) => {
              if (_item.url === item.url) {
                idx = index;
              }
            });
            if (idx > -1) {
              this.currentValue.splice(idx, 1);
            }
          });
        }
      });
    },
    deleteFile (val, callback) {
      this.$http.post(`${this.fileRoot}/auth/file/delete`, {url: val.url}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback();
          }
        }
      })
      .catch(err => {
        catchExpired(err, this);
      });
    }
  }
};

/* script */
            const __vue_script__$W = script$W;
            
/* template */
var __vue_render__$W = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c(
    "div",
    { staticClass: "quest-item-answer" },
    [
      _c("div", { staticClass: "quest-overview-header" }, [
        _c("a", { staticClass: "quest-overview-title" }, [
          _vm._v(_vm._s(_vm.item.label || _vm.item.title))
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "quest-overview-toolbar" }, [
          _c("span", {
            staticClass: "ionic-icon ionic-icon-android-upload",
            on: { click: _vm.onShowUpload }
          }),
          _vm._v(" "),
          _vm.$device.isDesktop
            ? _c("span", {
                staticClass: "ionic-icon ionic-icon-more",
                on: {
                  click: function($event) {
                    _vm.viewStyle = "oneline";
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$device.isDesktop
            ? _c("span", {
                staticClass: "ionic-icon ionic-icon-grid",
                on: {
                  click: function($event) {
                    _vm.viewStyle = "grid";
                  }
                }
              })
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "quest-overview-body", class: ["" + _vm.viewStyle] },
        _vm._l(_vm.currentValue, function(item) {
          return _c(
            "div",
            {
              key: item.name + item.size + item.time,
              staticClass: "quest-overview-file"
            },
            [
              _c("div", { staticClass: "overview-file-item" }, [
                _c("span", { staticClass: "overview-item-name" }, [
                  _vm._v(_vm._s(item.name))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "overview-item-size" }, [
                  _vm._v(_vm._s(item.size))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "overview-item-time" }, [
                  _vm._v(_vm._s(item.time))
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ionic-icon ionic-icon-ios-trash-outline",
                  on: {
                    click: function($event) {
                      $event.stopPropagation();
                      return _vm.onDelete(item)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "ionic-icon ionic-icon-ios-cloud-download-outline",
                  on: {
                    click: function($event) {
                      $event.stopPropagation();
                      return _vm.onDownload(item)
                    }
                  }
                })
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("upload-file-win", {
        attrs: { show: _vm.showUploadWin },
        on: {
          "on-hide": function($event) {
            _vm.showUploadWin = false;
          },
          "on-confirm-ok": _vm.onUploaded
        }
      })
    ],
    1
  )
};
var __vue_staticRenderFns__$W = [];
__vue_render__$W._withStripped = true;

  /* style */
  const __vue_inject_styles__$W = undefined;
  /* scoped */
  const __vue_scope_id__$W = undefined;
  /* functional template */
  const __vue_is_functional_template__$W = false;
  /* component normalizer */
  function __vue_normalize__$W(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\upload-file.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var UploadFile = __vue_normalize__$W(
    { render: __vue_render__$W, staticRenderFns: __vue_staticRenderFns__$W },
    __vue_inject_styles__$W,
    __vue_script__$W,
    __vue_scope_id__$W,
    __vue_is_functional_template__$W);

//
var script$X = {
  components: {UploadImage, UploadFile, InputDate, QInput},
  mixins: [Emitter],
  props: {
    role: String,
    title: String,
    items: Array,
    value: {},
    triggeredItems: Array
  },
  data () {
    return {
      upload: this.value || {}
    }
  },
  watch: {
    value (val) {
      this.upload = val || {}; // fix 新的输入没有值，不用触发更新 2022.5.19
    }
  },
  methods: {
    onUploadChange (uploadVal, itemKey) {
      this.upload[itemKey] = uploadVal;
      if (uploadVal === '') {
        delete this.upload[itemKey];
      }
      this.$emit('input', this.upload);
      this.$emit('on-change', this.upload);
      this.dispatch('QuestItemList', 'on-submit');
    },
    onInputChange (inputVal, itemKey) {
      if (itemKey) this.upload[itemKey] = inputVal;
      if (inputVal === '') {
        delete this.upload[itemKey];
      }
      this.$emit('input', this.upload);
      this.$emit('on-change', this.upload);
    }
  }
};

/* script */
            const __vue_script__$X = script$X;
            
/* template */
var __vue_render__$X = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "quest-item-input" }, [
    _c("div", { staticClass: "quest-item-title" }, [
      _c("a", { staticClass: "quest-title" }, [_vm._v(_vm._s(_vm.title))])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "quest-item-answer-list" },
      [
        _vm._l(_vm.items, function(item) {
          return [
            item.type === "image"
              ? _c("upload-image", {
                  key: item.key,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onUploadChange },
                  model: {
                    value: _vm.upload,
                    callback: function($$v) {
                      _vm.upload = $$v;
                    },
                    expression: "upload"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "file"
              ? _c("upload-file", {
                  key: item.key,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onUploadChange },
                  model: {
                    value: _vm.upload,
                    callback: function($$v) {
                      _vm.upload = $$v;
                    },
                    expression: "upload"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_inline_date"
              ? _c("input-date", {
                  key: item.key,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.upload,
                    callback: function($$v) {
                      _vm.upload = $$v;
                    },
                    expression: "upload"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "input_inline" || item.type === "input"
              ? _c("q-input", {
                  key: item.key,
                  attrs: { role: _vm.role, item: item },
                  on: { "on-change": _vm.onInputChange },
                  model: {
                    value: _vm.upload,
                    callback: function($$v) {
                      _vm.upload = $$v;
                    },
                    expression: "upload"
                  }
                })
              : _vm._e()
          ]
        })
      ],
      2
    )
  ])
};
var __vue_staticRenderFns__$X = [];
__vue_render__$X._withStripped = true;

  /* style */
  const __vue_inject_styles__$X = undefined;
  /* scoped */
  const __vue_scope_id__$X = undefined;
  /* functional template */
  const __vue_is_functional_template__$X = false;
  /* component normalizer */
  function __vue_normalize__$X(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\quest-item-upload.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var questItemUpload = __vue_normalize__$X(
    { render: __vue_render__$X, staticRenderFns: __vue_staticRenderFns__$X },
    __vue_inject_styles__$X,
    __vue_script__$X,
    __vue_scope_id__$X,
    __vue_is_functional_template__$X);

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var script$Y = {
};

/* script */
            const __vue_script__$Y = script$Y;
            
/* template */
var __vue_render__$Y = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _vm.$device.isDesktop
    ? _c("div", { staticClass: "quest-item-input" }, [_vm._m(0)])
    : _vm._e()
};
var __vue_staticRenderFns__$Y = [
  function() {
    var _vm = this;
    var _h = _vm.$createElement;
    var _c = _vm._self._c || _h;
    return _c("div", { staticClass: "quest-table-header" }, [
      _c("div", { staticClass: "quest-table-inner" }, [
        _c("div", { staticClass: "quest-table-row" }, [
          _c("div", { staticClass: "quest-table-col header title" }, [
            _c("div", { staticClass: "content" }, [_vm._v("指标名称")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "quest-table-col header value" }, [
            _c("div", { staticClass: "content" }, [_vm._v("指标编码")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "quest-table-col header table-input" }, [
            _c("div", { staticClass: "content" }, [_vm._v("结果")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "quest-table-col header unit" }, [
            _c("div", { staticClass: "content" }, [_vm._v("单位")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "quest-table-col header ref" }, [
            _c("div", { staticClass: "content" }, [_vm._v("正常参考值")])
          ])
        ])
      ])
    ])
  }
];
__vue_render__$Y._withStripped = true;

  /* style */
  const __vue_inject_styles__$Y = undefined;
  /* scoped */
  const __vue_scope_id__$Y = undefined;
  /* functional template */
  const __vue_is_functional_template__$Y = false;
  /* component normalizer */
  function __vue_normalize__$Y(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\quest-item-input-table-header.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var questItemInputTableHeader = __vue_normalize__$Y(
    { render: __vue_render__$Y, staticRenderFns: __vue_staticRenderFns__$Y },
    __vue_inject_styles__$Y,
    __vue_script__$Y,
    __vue_scope_id__$Y,
    __vue_is_functional_template__$Y);

//
var script$Z = {
  mixins: [isNumber, formDisabled],
  props: {
    value: {},
    item: Object,
    role: String
  },
  data () {
    return {
      currentValue: this.getValue(this.value),
      styleObject: {},
      disabled: this.getDisabled()
    }
  },
  watch: {
    value (val) {
      // fix 新的输入没有值 2022.5.19
      this.currentValue = this.getValue(val);
    },
    currentValue (val) {
      this.$emit('on-change', this.getParsedVal(val), this.item.key);
    }
  },
  methods: {
    getValue (data) {
      if (!data) return ''
      var val = data[this.item.key];
      if (val === 0) {
        return 0
      } else {
        return val || ''
      }
    },
    getParsedVal (val) {
      if (this.item.valueType === 'integer' && this.isNumber(val)) {
        return parseInt(val) || 0
      } else if (this.item.valueType === 'number' && this.isNumber(val)) {
        return Number(val) || 0
      }
      return val
    }
  }
};

/* script */
            const __vue_script__$Z = script$Z;
            
/* template */
var __vue_render__$Z = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "quest-input" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.currentValue,
          expression: "currentValue"
        }
      ],
      ref: "input",
      attrs: { placeholder: _vm.item.placeholder, disabled: _vm.disabled },
      domProps: { value: _vm.currentValue },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.currentValue = $event.target.value;
        }
      }
    })
  ])
};
var __vue_staticRenderFns__$Z = [];
__vue_render__$Z._withStripped = true;

  /* style */
  const __vue_inject_styles__$Z = undefined;
  /* scoped */
  const __vue_scope_id__$Z = undefined;
  /* functional template */
  const __vue_is_functional_template__$Z = false;
  /* component normalizer */
  function __vue_normalize__$Z(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\input-table.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var QInput$1 = __vue_normalize__$Z(
    { render: __vue_render__$Z, staticRenderFns: __vue_staticRenderFns__$Z },
    __vue_inject_styles__$Z,
    __vue_script__$Z,
    __vue_scope_id__$Z,
    __vue_is_functional_template__$Z);

//
var script$_ = {
  components: {QInput: QInput$1},
  props: {
    role: String,
    title: String,
    value: {},
    items: Array,
    triggeredItems: Array,
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputed: this.value || {}
    }
  },
  watch: {
    value (val) {
      this.inputed = val || {}; // fix 新的输入没有值，不用触发更新 2022.5.19
    }
  },
  methods: {
    onInputChange (inputVal, itemKey) {
      this.hasDataChange = true;
      this.inputed[itemKey] = inputVal;
      if (inputVal === '') {
        delete this.inputed[itemKey];
      }
      this.$emit('input', this.inputed);
      this.$emit('on-change', this.inputed);
    }
  }
};

/* script */
            const __vue_script__$_ = script$_;
            
/* template */
var __vue_render__$_ = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _vm.$device.isDesktop
    ? _c("div", { staticClass: "quest-item-input" }, [
        _vm.title
          ? _c("div", { staticClass: "quest-table-header" }, [
              _c("div", { staticClass: "quest-table-title" }, [
                _c("a", { staticClass: "quest-title" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "quest-table" }, [
          _c(
            "div",
            { staticClass: "quest-table-inner" },
            _vm._l(_vm.items, function(item) {
              return _c(
                "div",
                { key: item.key, staticClass: "quest-table-row" },
                [
                  _c("div", { staticClass: "quest-table-col title" }, [
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(item.title || item.label))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "quest-table-col value" }, [
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(item.icode)),
                      !item.icode ? _c("span", [_vm._v(" ")]) : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "quest-table-col table-input" }, [
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        item.type === "input_inline" || item.type === "input"
                          ? _c("q-input", {
                              ref: "input",
                              refInFor: true,
                              attrs: { role: _vm.role, item: item },
                              on: { "on-change": _vm.onInputChange },
                              model: {
                                value: _vm.inputed,
                                callback: function($$v) {
                                  _vm.inputed = $$v;
                                },
                                expression: "inputed"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "quest-table-col unit" }, [
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(item.unit)),
                      !item.unit ? _c("span", [_vm._v(" ")]) : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "quest-table-col ref" }, [
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(item.ref))
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ])
      ])
    : _vm._e()
};
var __vue_staticRenderFns__$_ = [];
__vue_render__$_._withStripped = true;

  /* style */
  const __vue_inject_styles__$_ = undefined;
  /* scoped */
  const __vue_scope_id__$_ = undefined;
  /* functional template */
  const __vue_is_functional_template__$_ = false;
  /* component normalizer */
  function __vue_normalize__$_(
    template, style, script,
    scope, functional, moduleIdentifier,
    createInjector, createInjectorSSR
  ) {
    const component = (typeof script === 'function' ? script.options : script) || {};

    // For security concerns, we use only base name in production mode.
    component.__file = "D:\\git_gitlab\\yxy-biobank\\src\\components\\question\\quest-item-input-table.vue";

    if (!component.render) {
      component.render = template.render;
      component.staticRenderFns = template.staticRenderFns;
      component._compiled = true;

      if (functional) component.functional = true;
    }

    component._scopeId = scope;

    return component
  }
  /* style inject */
  
  /* style inject SSR */
  

  
  var questItemInputTable = __vue_normalize__$_(
    { render: __vue_render__$_, staticRenderFns: __vue_staticRenderFns__$_ },
    __vue_inject_styles__$_,
    __vue_script__$_,
    __vue_scope_id__$_,
    __vue_is_functional_template__$_);

export { QuestItemInput, questItemInputTable as QuestItemInputTable, questItemInputTableHeader as QuestItemInputTableHeader, QuestItemMselect, QuestItemMselectInput, QuestItemShow, QuestItemSselect, questItemSselectShow as QuestItemSselectShow, questItemUpload as QuestItemUpload };
