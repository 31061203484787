<template>
  <div class="dataset-ds">
    <div class="ds-head">
      <y-button type="primary" icon="plus" @click.native.stop="onDatasourceAdd">添加数据源</y-button>
    </div>
    <div class="ds-table">
      <y-table :columns="columns" :data="datasourceList" ref="table"></y-table>
    </div>
    <!-- <div class="ds-head">
      <y-button type="primary" icon="plus" @click.native.stop="onDatatableAdd">添加数据表</y-button>
    </div>
    <div class="ds-table">
      <y-table :columns="columns2" :data="datatableList" ref="table2"></y-table>
    </div> -->
    <datasource-edit-win :show="showAddDatasourceWin" @on-hide="showAddDatasourceWin=false" @on-confirm-ok="onAddDatasource"></datasource-edit-win>
    <datasource-edit-win :value="currentDatasource" :show="showEditDatasourceWin" @on-hide="showEditDatasourceWin=false" @on-confirm-ok="onUpdateDatasource"></datasource-edit-win>
    <!-- <datatable-edit-win :datasourceList="datasourceList" :show="showAddDatatableWin" @on-hide="showAddDatatableWin=false" @on-confirm-ok="onAddDatatable"></datatable-edit-win> -->
    <!-- <datatable-edit-win :value="currentDatatable" :datasourceList="datasourceList" :show="showEditDatatableWin" @on-hide="showEditDatatableWin=false" @on-confirm-ok="onUpdateDatatable"></datatable-edit-win> -->
    <!-- <preview-win :show="showPreview" @on-hide="showPreview=false" :data="previewData"></preview-win> -->
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)">{{loadingText}}</loading>
  </div>
</template>
<script>
import YButton from '../common/button/button'
import YTable from '../common/table/table'
import Loading from '../common/loading/loading'
import DatasourceColumn from './mixins/datasourceColumn'
import DatatableColumn from './mixins/datatableColumn'
import Mixin from './mixins/mixin'
import DatasourceEditWin from './win/datasource-edit-win'
// import DatatableEditWin from './win/datatable-edit-win'
// import PreviewWin from './win/preview-win'

// import Vue from 'vue'
// import DataList from './data-list'
// Vue.component('DataList', DataList)

export default {
  components: {YButton, YTable, Loading, DatasourceEditWin},
  mixins: [DatasourceColumn, DatatableColumn, Mixin],
  data () {
    return {
      datasourceList: [],

      loadingText: '',
      showLoading: false,

      currentDatasource: {},
      showAddDatasourceWin: false,
      showEditDatasourceWin: false
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    }
  },
  watch: {
    projectId (val) {
      if (val) {
        this.loadDatasourceList()
      }
    }
  },
  mounted () {
    this.loadDatasourceList()
    // this.loadDatatableList()
  },
  methods: {
    onDatasourceAdd () {
      this.showAddDatasourceWin = true
    },
    onAddDatasource (data) {
      this.addDatasource(data, () => {
        this.loadDatasourceList()
      })
    },
    onUpdateDatasource (data) {
      this.updateDatasource(data, () => {
        this.loadDatasourceList()
      })
    },
    edit (_idx) {
      this.currentDataIdx = _idx
      let data = this.$refs.table.getDataById(_idx)
      this.currentDatasource = data
      this.showEditDatasourceWin = true
    },
    delete (_idx) {
      this.currentDataIdx = _idx
      let data = this.$refs.table.getDataById(_idx)
      this.$Modal.confirm({
        title: '删除数据源',
        content: '<p>您确定删除选定的数据源？</p>',
        onOk: () => {
          this.deleteDatasource(data.id, () => {
            this.loadDatasourceList()
          })
        }
      })
    }
  }
}
</script>
<style lang="less">
.ds-head {
  padding: 10px 15px;
}
.ds-table {
  padding: 0 15px;
}
</style>
