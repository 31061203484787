<template>
  <div class="container-list">
    <freezer-container :containers="containerStore.freezerContainer"
      :width="width" @on-freezer-selected="onFreezerSelected"></freezer-container>
    <shelf-container :containers="containerStore.shelfContainer"
      :width="width" @on-shelf-selected="onShelfSelected"
      @on-shelf-section-selected="onShelfSectionSelected"
      @on-shelf-section-unselected="onShelfSectionUnselected"></shelf-container>
    <layer-container :containers="containerStore.layerContainer"
      :width="width" @on-layer-selected="onLayerSelected"></layer-container>
    <samplebox-container :containers="containerStore.sampleboxContainer"
      :width="width" @on-samplebox-selected="onSampleboxSelected"></samplebox-container>
    <strawbox-container :containers="containerStore.strawboxContainer"
      :width="width" @on-strawbox-selected="onStrawboxSelected"></strawbox-container>
    <loading v-model="containerStore.loading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>

<script>
import ContainerStore from './model/container-store'
import { copyContainers, copyContainer } from './model/util'
import FreezerContainer from './freezer-container'
import ShelfContainer from './shelf-container'
import LayerContainer from './layer-container'
import SampleboxContainer from './samplebox-container'
import StrawboxContainer from './strawbox-container'
import Loading from '../loading/loading'
import { authenticate, catchExpired } from '../../../utils/auth'

export default {
  name: 'Container',
  props: {
    data: Array,
    async: {
      type: Boolean,
      default: false
    },
    storeContainers: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '200px'
    }
  },
  data () {
    return {
      containerStore: {
        freezerContainer: {items: []},
        shelfContainer: {items: []},
        layerContainer: {items: []},
        sampleboxContainer: {items: []},
        strawboxContainer: {items: []}
      }
    }
  },
  watch: {
    data (val) {
      if (Array.isArray(val)) {
        this.containerStore = new ContainerStore({$container: this, loadFn: this.loadData, data: this.data})
        this.containerStore.loadFreezerContainer()
      }
    }
  },
  created () {
    if (!this.async) {
      this.containerStore = new ContainerStore({$container: this, loadFn: this.loadData, data: this.data})
      this.containerStore.loadFreezerContainer()
    }
    this.$on('on-path-change', () => {
      this.$parent.$parent.containerPath = this.containerStore.containerPath
    })
    this.$on('on-freezer-load-success', () => {
      this.$parent.$parent.$emit('on-container-load-success')
    })
    this.$on('on-freezer-code-selected', (item) => {
      this.$store.dispatch('updateCachedContainers', {containers: copyContainers(this.containerStore.freezerContainer.items), item: copyContainer(item)})
    })
    this.$on('on-shelf-code-selected', (item) => {
      this.$store.dispatch('updateCachedContainers', {containers: copyContainers(this.containerStore.freezerContainer.items), item: copyContainer(item)})
    })
    this.$on('on-layer-code-selected', (item) => {
      this.$store.dispatch('updateCachedContainers', {containers: copyContainers(this.containerStore.freezerContainer.items), item: copyContainer(item)})
    })
    this.$on('on-samplebox-code-selected', (item) => {
      this.$store.dispatch('updateCachedContainers', {containers: copyContainers(this.containerStore.freezerContainer.items), item: copyContainer(item)})
    })
    this.$on('on-strawbox-code-selected', (item) => {
      this.$store.dispatch('updateCachedContainers', {containers: copyContainers(this.containerStore.freezerContainer.items), item: copyContainer(item)})
    })
  },
  methods: {
    onFreezerSelected (freezer) {
      this.$parent.$parent.$emit('on-freezer-selected', {parentId: freezer.id, rootId: freezer.rootId, parentCode: freezer.siteCode, type: freezer.type})
    },
    onShelfSelected (shelf) {
      this.$parent.$parent.$emit('on-shelf-selected', {parentId: shelf.id, rootId: shelf.rootId, parentCode: shelf.siteCode})
    },
    onShelfSectionSelected (section) {
      this.$parent.$parent.$emit('on-shelf-section-selected', section)
    },
    onShelfSectionUnselected () {
      this.$parent.$parent.$emit('on-shelf-section-unselected')
    },
    onLayerSelected (layer) {
      this.$parent.$parent.$emit('on-layer-selected', {parentId: layer.id, rootId: layer.rootId, parentCode: layer.siteCode})
    },
    onSampleboxSelected (samplebox) {
      this.$parent.$parent.$emit('on-samplebox-selected', {parentId: samplebox.id, rootId: samplebox.rootId, parentCode: samplebox.siteCode, type: samplebox.type, label: samplebox.label, leafboxType: samplebox._type, exboxType: samplebox.exboxType})
    },
    onStrawboxSelected (strawbox) {
      this.$parent.$parent.$emit('on-strawbox-selected', {parentId: strawbox.id, rootId: strawbox.rootId, parentCode: strawbox.siteCode, type: strawbox.type, label: strawbox.label, leafboxType: strawbox._type})
    },
    setContainersFromStore () {
      this.containerStore = new ContainerStore({$container: this, loadFn: this.loadData, data: this.$store.state.container.cachedContainers})
      this.$parent.$parent.$emit('on-container-load-success')
    },
    selectChainContainersById (id) {
      this.containerStore.selectChainContainersById(id)
    },
    reSelectChainContainersById (id) {
      this.containerStore.reSelectChainContainersById(id)
    },
    selectById (id) {
      this.containerStore.selectById(id)
    },
    reSelectById (id) {
      this.containerStore.reSelectById(id)
    },
    getFreezerById (id) {
      return this.containerStore.getContainerById(id)
    },
    updateContainerPathById (id) {
      let container = this.containerStore.getContainerById(id)
      if (container) {
        this.containerStore.updateContainerPath(container)
        this.$emit('on-path-change')
      }
    },
    // projectId manage 加的参数，container, freezer-panel, shelf-panel, layer-panel, strawbox-panel, samplebox-panel
    loadData (containerInst, successCallback, faildCallback, containerType, parentId) {
      this.$http.get(`${this.httpRoot}/container/list`, authenticate({params: {type: containerType, parentId, projectId: this.$store.state.project.projectId}}))
      // this.$http.get(`${this.httpRoot}/container/list`) // loaded all container at onece
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && typeof successCallback === 'function') {
          successCallback.call(containerInst, res.containerList || [])
        } else if (typeof faildCallback === 'function') {
          faildCallback.call(containerInst)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        faildCallback.call(containerInst)
      })
    }
  },
  beforeDestroy () {
    if (this.containerStore.destroy) {
      this.containerStore.destroy()
    }
    this.containerStore = null
  },
  components: {
    FreezerContainer,
    ShelfContainer,
    LayerContainer,
    SampleboxContainer,
    StrawboxContainer,
    Loading
  }
}
</script>

<style lang="less">
.container-list {
  position: relative;
}
</style>
