<template>
  <modal v-model="showModal" title="添加 crfTable CRF表" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="currentDataForm" :model="currentData" :rules="currentDataRule" label-width="100px" item-width="250px">
      <form-item label="唯一编码" prop="modelId">
        <y-input v-model="currentData.modelId" disabled></y-input>
      </form-item>
      <form-item label="显示名称" prop="title">
        <y-input v-model="currentData.title"></y-input>
      </form-item>
      <form-item label="集合名称" prop="collection">
        <y-input v-model="currentData.collection"></y-input>
      </form-item>
      <!-- <form-item label="角色">
        <y-select v-model="currentData.role" :data="roleList" multiple></y-select>
      </form-item> -->
      <form-item label="图标">
        <y-select v-model="currentData.icon" :data="iconList" clearable showIcon></y-select>
      </form-item>
      <!-- <form-item label="是否可见" prop="visible">
        <checklist inline v-model="currentData.visible" :items="visibleList"></checklist>
      </form-item> -->
      <form-item label="是否显示分数" prop="hasScore">
        <checklist inline v-model="currentData.hasScore" :items="scoreList"></checklist>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import Checklist from '../../common/checklist/checklist'

export default {
  components: { Modal, YForm, FormItem, YSelect, YInput, Checklist },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: this.show,
      currentData: {
        modelId: '',
        title: '',
        collection: '',
        icon: '',
        hasScore: false
      },
      roleList: [
        {name: '医生', value: 'doctor'},
        {name: '患者', value: 'patient'},
        {name: '助理', value: 'assistant'}
      ],
      iconList: [
        {name: 'user-md', value: 'user-md', icon: 'user-md'},
        {name: 'venus-double', value: 'venus-double', icon: 'venus-double'},
        {name: 'heart-o', value: 'heart-o', icon: 'heart-o'},
        {name: 'cut', value: 'cut', icon: 'cut'},
        {name: 'medkit', value: 'medkit', icon: 'medkit'},
        {name: 'h-square', value: 'h-square', icon: 'h-square'},
        {name: 'hospital-o', value: 'hospital-o', icon: 'hospital-o'},
        {name: 'heartbeat', value: 'heartbeat', icon: 'heartbeat'},
        {name: 'stethoscope', value: 'stethoscope', icon: 'stethoscope'}
      ],
      visibleList: [
        {key: '1', title: '可见', value: true},
        {key: '0', title: '不可见', value: false}
      ],
      scoreList: [
        {key: '1', title: '显示', value: true},
        {key: '0', title: '不显示', value: false}
      ],
      currentDataRule: {
        modelId: [{required: true, message: '不能为空', trigger: 'change'}],
        title: [{required: true, message: '不能为空', trigger: 'blur'}],
        collection: [{required: true, message: '不能为空', trigger: 'blur'}],
        hasScore: [{type: 'boolean', required: true, message: '不能为空', trigger: 'change'}]
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
        this.setKey()
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.currentDataForm.validate()
      if (!this.$refs.currentDataForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.recoverData(this.currentData))
    },
    setKey () {
      // new Date(Number('15' + new Date().getTime().toString().slice(2, 10) + 000)).toISOString()  // 8位id
      // new Date(Number('1' + new Date().getTime().toString().slice(1, 10) + 000)).toISOString()  // 9位id
      this.currentData.modelId = 'q_' + new Date().getTime().toString().slice(1, 10)
    },
    recoverData (data) {
      var cData = Object.assign({}, data)
      cData.collection = 'crf_' + cData.collection
      return cData
    }
  }
}
</script>

<style lang="css">
</style>
