<template>
  <div class="mycontainers">
    <div class="panel">
      <panel-container
        :containers="freezerList"
        :showDeleteMask="showDeleteMask"
        :showAdd="currentPath.indexOf('myContainers') >= 0"
        @on-show-delete="onShowDeleteWin"
        @on-show-auth="onShowAuthWin"
        @on-show-edit="onShowEditWin"
        @on-calc-vol="onCalcVol"
        @on-calc-samplenum="onCalcSamplenum"
        @on-show-add="onShowModal">
      </panel-container>
    </div>
    <edit-menu :active="freezerEditActive" @on-edit-click="onContainerEdit('freezer')"></edit-menu>
    <freezer-add v-if="currentPath.indexOf('myContainers') >= 0"
      v-model="newFreezer"
      :show="showModal"
      @on-hide="showModal=false"
      @on-add-ok="onFreezerAdd"></freezer-add>
    <delete-win
      v-model="deletePermanent"
      :show="showDeleteWin"
      @on-hide="showDeleteWin=false"
      @on-delete-ok="onFreezerDelete"></delete-win>
    <auth-win
      v-model="currentGroupCodeList"
      :show="showAuthWin"
      @on-hide="showAuthWin=false"
      @on-confirm-ok="onContainerAuth"></auth-win>
    <edit-win
      containerType="freezer"
      v-model="editContainer"
      :show="showEditWin"
      @on-hide="showEditWin=false"
      @on-confirm-ok="onContainerUpdate"></edit-win>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>

<script>
import loadFreezerList from '../mixins/loadFreezerList'
import editMixin from '../common/container/mixins/editMixin'
import PanelContainer from './panel-container'
import FreezerAdd from './freezerAdd'
import DeleteWin from '../common/container/delete/deleteWin'
import AuthWin from '../common/container/auth/auth-win'
import EditWin from '../common/container/edit/edit-win'
import EditMenu from '../common/container/delete/edit-menu'
import Loading from '../common/loading/loading.vue'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: { PanelContainer, FreezerAdd, DeleteWin, AuthWin, EditWin, EditMenu, Loading },
  mixins: [loadFreezerList, editMixin],
  data () {
    return {
      containerType: 'freezer',
      newFreezer: {
        projectId: '',
        type: '',
        code: '',
        zone: 1,
        label: '',
        desc: ''
      },
      showLoading: false
    }
  },
  computed: {
    freezerList () {
      return this.$store.state.container.freezerList
    },
    currentPath () {
      return this.$store.getters.getCurrentPath
    },
    projectId () {
      return this.$store.state.project.projectId
    }
  },
  watch: {
    projectId (val) {
      if (val) {
        this.loadFreezerList()
      }
    }
  },
  created () {
    if (!this.freezerList || !this.freezerList.length) {
      this.loadFreezerList()
    }
  },
  methods: {
    onFreezerAdd (append) {
      const freezer = Object.assign({}, this.newFreezer, append)
      let path = append ? 'container/add' : 'container/freezer/add'
      this.$http.post(`${this.httpRoot}/${path}`, freezer, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          // 清除容器选择记忆的缓存
          this.$store.dispatch('updateCachedContainers', {containers: [], item: {}})
          this.$Message.success('创建容器成功')
          this.loadFreezerList()
        } else {
          this.$Modal.error({
            title: '创建容器失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('创建容器失败')
      })
    },
    onFreezerDelete () {
      let path = this.deletePermanent ? '/permanent' : ''
      this.$http.post(`${this.httpRoot}/container/freezer/delete${path}`, {
        id: this.deleteContainer.id,
        rootId: this.deleteContainer.rootId,
        siteCode: this.deleteContainer.siteCode
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('删除容器成功')
          this.loadFreezerList()
          // 清除容器选择记忆的缓存
          this.$store.dispatch('updateCachedContainers', {containers: [], item: {}})
        } else {
          this.$Modal.error({
            title: '删除容器失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('删除容器失败')
      })
    }
  }
}
</script>

<style lang="less">
.mycontainers {
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding: 15px;
  position: relative;
  background: #E8E8E8;
  .container {
    font-size: 12px;
  }
  .container-add {
    width: 80px;
    height: 100px;
    transition: all .3s;
    &:hover {
      cursor: pointer;
      .container-add-plus {
        display: block;
      }
    }
  }
  .container-add-plus {
    border: 2px dotted #ccc;
    font-size: 32px;
    height: 100px;
    line-height: 96px;
    color: #bdbdbd;
    text-align: center;
    display: none;
    transition: all .3s;
  }
  .edit-menus {
    top: 10px;
  }
}
</style>
