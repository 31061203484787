<template>
  <div class="project-page">
    <div class="project_edit-btn">
      <y-button type="primary" style="margin-right:10px;" icon="plus" @click.native.stop="onProjectAdd">添加</y-button>
      <!-- <y-button icon="close" @click.native.stop="onProjectDelete">删除</y-button> -->
      <y-button icon="ios-cloud-upload" @click.native.stop="onProjectImport">导入</y-button>
    </div>
    <div class="project_edit-table">
      <y-table :columns="columns" :data="projectList" :height="tableHeight" ref="table"></y-table>
    </div>
    <project-edit-win
      :show="showAddWin"
      title="项目添加"
      @on-hide="showAddWin=false"
      @on-confirm-ok="onAddProject"></project-edit-win>
    <project-edit-win
      v-model="editProject"
      :show="showEditWin"
      title="项目编辑"
      @on-hide="showEditWin=false"
      @on-confirm-ok="onEidtProject"></project-edit-win>
    <project-import-win
      :show="showImportWin"
      @on-hide="showImportWin=false"
      @on-confirm-ok="onImportProject"></project-import-win>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)">{{loadingText}}</loading>
  </div>
</template>

<script>
import YButton from '../common/button/button'
import YTable from '../common/table/table'
import Pagination from '../common/pagination/pagination'
import Loading from '../common/loading/loading'
import ProjectEditWin from './win/project-edit-win'
import ProjectImportWin from './win/project-import-win'

import ProjectEdit from './mixins/projectEdit'
import ProjectAction from './mixins/projectAction'
import ProjectColumn from './mixins/projectColumn'
import { authenticate, catchExpired } from '../../utils/auth'

export default {
  components: {YButton, YTable, Pagination, Loading, ProjectEditWin, ProjectImportWin},
  mixins: [ProjectEdit, ProjectAction, ProjectColumn],
  data () {
    return {
      projectList: [],

      editProject: {},
      showAddWin: false,
      showEditWin: false,
      showLoading: false,
      loadingText: '',

      currentDataIdx: '',
      showImportWin: false
    }
  },
  computed: {
    tableHeight () {
      return Math.max(this.$store.getters.getWinHeight - 60 - 70, 32)
    }
  },
  mounted () {
    this.loadProjectList()
  },
  methods: {
    loadProjectList () {
      this.updateLoadStatus('load-start', '')
      this.$http.get(`${this.httpRoot}/admin/project/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.projectList = res.projectList || []
          this.updateLoadStatus('load-success')
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '加载项目列表失败')
      })
    },
    updateProjectId () {
      let project = this.$refs.table.getDataById(this.currentDataIdx)
      if (project && project.code) {
        this.$store.dispatch('updateProjectId', project.code)
      }
    },
    updateLoadStatus (status, loadingText) {
      if (status === 'load-start') {
        this.loadingText = loadingText
        this.showLoading = true
      } else if (status === 'load-success') {
        this.showLoading = false
        if (loadingText) {
          this.$Message.success(loadingText)
        }
      } else if (status === 'load-faild') {
        this.showLoading = false
        this.$Message.error(loadingText)
      }
    }
  }
}
</script>

<style lang="less">
.project_edit-btn {
  height: 50px;
  line-height: 50px;
}
</style>
