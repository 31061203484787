export default {
  data () {
    return {
      columns: [
        {
          type: 'index',
          title: '序号',
          width: 65,
          align: 'center'
          // fixed: 'left'
        },
        // {
        //   type: 'selection',
        //   width: 40,
        //   align: 'center'
        //   // fixed: 'left'
        // },
        {
          title: '项目名称',
          key: 'name',
          width: 200
        },
        {
          title: '英文简称',
          key: 'nameEn',
          width: 100
        },
        {
          title: '项目类型',
          key: 'type',
          width: 200
        },
        {
          title: '项目编码',
          key: 'code',
          width: 150
        },
        {
          title: '创建时间',
          key: 'createTime',
          formatter: (val) => { return val ? new Date(val).format('yyyy-MM-dd hh:mm:ss') : '' },
          width: 160
        },
        {
          title: '新项目',
          key: 'isNew',
          formatter: (val) => { return val ? '是' : '' },
          width: 80
        },
        {
          title: 'root用户',
          key: 'root',
          width: 150
        },
        {
          title: '菜单授权',
          key: 'menuAuth',
          align: 'center',
          formatter: (val) => { return val ? '是' : '否' },
          width: 100
        },
        {
          title: '预期收集样本数',
          key: 'expectSampleTotal',
          width: 150
        },
        {
          title: 'logoUrl',
          key: 'logoUrl',
          width: 250
        },
        {
          title: '描述',
          key: 'desc',
          width: 300
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 600,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small',
                  emphasis: 'emphasis'
                },
                nativeOn: {
                  click: () => { this.editCrfTable(params.row._idx) }
                }
              }, 'CRF 表'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                nativeOn: {
                  click: () => { this.editCrf(params.row._idx) }
                }
              }, 'CRF'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small',
                  emphasis: 'emphasis'
                },
                nativeOn: {
                  click: () => { this.editSubject(params.row._idx) }
                }
              }, '课题'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                nativeOn: {
                  click: () => { this.editMenu(params.row._idx) }
                }
              }, '菜单'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small',
                  emphasis: 'emphasis'
                },
                nativeOn: {
                  click: () => { this.editUser(params.row._idx) }
                }
              }, '用户管理'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                nativeOn: {
                  click: () => { this.editAPI(params.row._idx) }
                }
              }, 'API黑名单'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small',
                  emphasis: 'emphasis'
                },
                nativeOn: {
                  click: () => { this.editDict(params.row._idx) }
                }
              }, '字典数据'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                nativeOn: {
                  click: () => { this.edit(params.row._idx) }
                }
              }, '编辑'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small',
                  emphasis: 'emphasis'
                },
                nativeOn: {
                  'click': () => { this.export(params.row._idx) }
                }
              }, '导出'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                nativeOn: {
                  'click': () => { this.deleteLog(params.row._idx) }
                }
              }, '清除日志')
              // h('y-button', {
              //   props: {
              //     type: 'text',
              //     size: 'small'
              //   },
              //   nativeOn: {
              //     'click': () => { this.delete(params.row._idx) }
              //   }
              // }, '删除')
            ])
          }
        }
      ]
    }
  }
}
