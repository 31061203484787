<template>
  <div class="datatable-list">
    <div class="datatable-row-hd">
      <div class="datatable-item index">#</div>
      <div class="datatable-item col" :style="colStyle(col)" v-for="(col, index) in columns" :key="index" :data-key="col.key" :title="col.key">{{col.title}}</div>
    </div>
    <div v-for="(item, index) in items" :key="item.id" class="datatable-row">
      <div class="datatable-item index"><span class="ionic-icon ionic-icon-clipboard"></span>{{index + 1}}</div>
      <div class="datatable-item col" :style="colStyle(col)" v-for="(col, idx) in columns" :key="idx">{{item[col.key]}}</div>
    </div>
    <div v-if="!items || !items.length" class="nodata">
      暂无数据
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataList',
  props: {
    columns: {
      type: Array,
      default () { return [] }
    },
    items: {
      type: Array,
      default () { return [] }
    },
    width: Number
  },
  methods: {
    colStyle (col) {
      return {width: col.width + 'px'}
    }
  }
}
</script>

<style lang="less">
.datatable-list {
  padding: 0;
  margin: 0;
  display: inline-block;
}
.nodata {
  text-align: center;
}
.datatable-row-hd {
  height: 28px;
  line-height: 28px;
  background: #b7bcca;
  display: flex;
  flex-wrap: nowrap;
}
.datatable-row {
  padding: 5px 0;
  display: flex;
  flex-wrap: nowrap;
  &:after {
    content: ".";
    height: 0;
    display: block;
    visibility: hidden;
    clear: both;
  }
  &:hover {
    background: #f3f3f3;
  }
  &:nth-child(odd) {
    background: #f9f9f9;
    &:hover {
      background: #f3f3f3;
    }
  }
  &:nth-child(even) {
    background: #e8ebf1;
    &:hover {
      background: #dee1e8;
    }
  }
}
.datatable-item {
  float: left;
  text-align: center;
  flex: 0 0 auto;
  &.index {
    width: 60px;
    .ionic-icon {
      padding-right: 3px;
    }
  }
  &.col {
    padding: 0 4px;
    text-align: left;
    width: 10%;
    .disabled {
      color: #aaa;
      cursor: not-allowed;
    }
  }
}
</style>
