<template>
  <span class="node-content" :class="{focused: node.focused}">
    <label class="checkbox-wrapper" :for="node.key" v-if="node.type === 'item' || node.type === 'gitem' && node.isLeaf">
      <span class="checkbox" :class="{'checkbox-checked': node.checked}">
        <span class="checkbox-inner"></span>
        <input type="checkbox" class="checkbox-input" :id="node.key" v-model="node.checked">
      </span>
    </label>
    <span class="node-content-label"
      :class="[node.type, node.data.type, node.data.modelId?'modelid':'']"
      @click.stop="onLabelClick">{{ node.label || node.type }}
      <span class="field-type" v-if="node.type==='field'">{{node.data.type}}</span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    node: {
      default () {
        return {}
      }
    }
  },
  watch: {
    'node.checked' (val) {
      this.emitCheckChange(val)
    }
  },
  methods: {
    emitCheckChange (val) {
      this.$emit('on-node-check-change', val)
    }
  }
}
</script>

<style lang="css">
</style>
