const state = {
  lastPath: '',
  currentPath: ''
}
const mutations = {
  UPDATE_CURRENTPATH (state, path) {
    state.currentPath = path
  },
  UPDATE_LASTPATH (state, path) {
    state.lastPath = path
  }
}

export default {
  state,
  mutations
}
