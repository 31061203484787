<template>
  <div class="nitrogen-panel" :style="{height:chartWidth+'px'}">
    <div class="nitrogen-chart-wrapper" :style="{width:chartWidth+'px',height:chartWidth+'px'}">
      <div class="nitrogen-chart">
        <svg :width="width" :height="height" :viewBox="viewBox" ref="svg"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <path :fill="baseColor" :d="basePathD"></path>
          <g class="section-segments" :style="{opacity: 1}">
            <path
              v-for="(item, index) in data"
              :key="index"
              ref="segmentPath"
              :d="segmentPathD[index]"
              :stroke-width="segmentStrokeWidth"
              :stroke="segmentStrokeColor"
              :fill="color[index % color.length]"
              :opacity="segmentOpacity[index]"
              @click.stop="onPathClick(index)">
            </path>
          </g>
          <g class="section-labels" fill="none" font-size="12" font-family="sans-serif" text-anchor="middle">
            <text
              v-for="(item, index) in sectionLabels"
              :key="index"
              fill="#000"
              opacity="1"
              :x="item.x"
              :y="item.y"
              text-anchor="middle"
              @click.stop="onPathClick(index)">
              {{item.text}}
            </text>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default () { return [1, 1, 1, 1, 1, 1] }
    },
    section: {
      type: Number,
      default: 0
    },
    chartWidth: {
      type: [Number, String],
      default: 200
    },
    segmentShowStroke: {
      type: Boolean,
      default: true
    },
    segmentStrokeColor: {
      type: String,
      default: '#fff' // '#0C1013'
    },
    segmentStrokeWidth: {
      type: Number,
      default: 1
    },
    baseColor: {
      type: String,
      default: 'rgba(0,0,0,0.5)'
    },
    baseOffset: {
      type: Number,
      default: 0
    },
    edgeOffset: { // offset from edge of this.$el
      type: Number,
      default: 10
    },
    percentageInnerCutout: {
      type: Number,
      default: 25
    }
  },
  data () {
    return {
      width: 100,
      height: 100,
      viewBox: '0 0 100 100',
      centerX: 50,
      centerY: 50,
      doughnutRadius: 0,
      cutoutRadius: 0,
      baseDoughnutRadius: 0,
      baseCutoutRadius: 0,

      segmentTotal: 0,

      basePathD: '',
      segmentPathD: {},
      segmentOpacity: [0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3],
      sectionLabels: [],
      currentSection: this.section, // 下标1开始

      color: ['#c23531', '#F4A532', '#45D4E6', '#5085E8', '#26DE6C', '#E037DA', '#749f83', '#ca8622', '#bda29a']
    }
  },
  watch: {
    section (val) {
      this.currentSection = val
      if (!val) {
        Object.keys(this.segmentOpacity).forEach(prop => {
          this.$set(this.segmentOpacity, prop, 0.3)
        })
      }
    },
    currentSection (val) {
      if (val) {
        this.$emit('on-section-select', val)
      } else {
        this.$emit('on-section-unselect')
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.width = this.chartWidth
      this.height = this.chartWidth
      this.viewBox = `0 0 ${this.width} ${this.height}`
      this.centerX = this.width / 2
      this.centerY = this.height / 2

      this.doughnutRadius = this.min([this.width / 2, this.height / 2]) - this.edgeOffset
      this.cutoutRadius = this.doughnutRadius * (this.percentageInnerCutout / 100)

      this.baseDoughnutRadius = this.doughnutRadius + this.baseOffset
      this.baseCutoutRadius = this.cutoutRadius - this.baseOffset

      this.drawChart()
    },
    drawChart () {
      this.data.forEach(item => {
        this.segmentTotal += item
      })

      this.drawBaseDoughnut()
      // Animation start
      this.drawPieSegments()
    },
    drawBaseDoughnut () {
      // Calculate values for the path.
      // We needn't calculate startRadius, segmentAngle and endRadius, because base doughnut doesn't animate.
      let startRadius = -1.570 // -Math.PI/2
      // let segmentAngle = 6.2831 // 1 * ((99.9999/100) * (PI*2)),
      let endRadius = 4.7131 // startRadius + segmentAngle
      let startX = this.centerX + Math.cos(startRadius) * this.baseDoughnutRadius
      let startY = this.centerY + Math.sin(startRadius) * this.baseDoughnutRadius
      let endX2 = this.centerX + Math.cos(startRadius) * this.baseCutoutRadius
      let endY2 = this.centerY + Math.sin(startRadius) * this.baseCutoutRadius
      let endX = this.centerX + Math.cos(endRadius) * this.baseDoughnutRadius
      let endY = this.centerY + Math.sin(endRadius) * this.baseDoughnutRadius
      let startX2 = this.centerX + Math.cos(endRadius) * this.baseCutoutRadius
      let startY2 = this.centerY + Math.sin(endRadius) * this.baseCutoutRadius
      let cmd = [
        'M', startX, startY,
        'A', this.baseDoughnutRadius, this.baseDoughnutRadius, 0, 1, 1, endX, endY,
        'L', startX2, startY2,
        'A', this.baseCutoutRadius, this.baseCutoutRadius, 0, 1, 0, endX2, endY2, // reverse
        'Z'
      ]
      this.basePathD = cmd.join(' ')
    },
    drawPieSegments () {
      let startRadius = -Math.PI / 2 // -90 degree
      // draw each path
      for (var i = 0, len = this.data.length; i < len; i++) {
        let segmentAngle = ((this.data[i] / this.segmentTotal) * (Math.PI * 2))
        let endRadius = startRadius + segmentAngle
        let largeArc = ((endRadius - startRadius) % (Math.PI * 2)) > Math.PI ? 1 : 0
        let startX = this.centerX + Math.cos(startRadius) * this.doughnutRadius
        let startY = this.centerY + Math.sin(startRadius) * this.doughnutRadius
        let endX2 = this.centerX + Math.cos(startRadius) * this.cutoutRadius
        let endY2 = this.centerY + Math.sin(startRadius) * this.cutoutRadius
        let endX = this.centerX + Math.cos(endRadius) * this.doughnutRadius
        let endY = this.centerY + Math.sin(endRadius) * this.doughnutRadius
        let startX2 = this.centerX + Math.cos(endRadius) * this.cutoutRadius
        let startY2 = this.centerY + Math.sin(endRadius) * this.cutoutRadius
        let cmd = [
          'M', startX, startY, // Move pointer
          'A', this.doughnutRadius, this.doughnutRadius, 0, largeArc, 1, endX, endY, // Draw outer arc path
          'L', startX2, startY2, // Draw line path(this line connects outer and innner arc paths)
          'A', this.cutoutRadius, this.cutoutRadius, 0, largeArc, 0, endX2, endY2, // Draw inner arc path
          'Z' // Cloth path
        ]
        this.$set(this.segmentPathD, i, cmd.join(' '))
        // draw each label
        let labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
        let labelAngle = startRadius + segmentAngle / 2
        let labelX = this.centerX + Math.cos(labelAngle) * ((this.doughnutRadius + this.cutoutRadius) / 2)
        let labelY = this.centerY + Math.sin(labelAngle) * ((this.doughnutRadius + this.cutoutRadius) / 2)
        this.sectionLabels.push({x: labelX, y: labelY, text: labels[i]})

        startRadius += segmentAngle
      }
    },
    min (arr) {
      return Math.min.apply(null, arr)
    },
    onPathClick (index) {
      if (this.segmentOpacity[index] === 1) {
        this.$set(this.segmentOpacity, index, 0.3)
        this.currentSection = 0
      } else {
        this.$set(this.segmentOpacity, index, 1)
        this.currentSection = index + 1
        Object.keys(this.segmentOpacity).forEach(prop => {
          if (index !== parseInt(prop)) {
            this.$set(this.segmentOpacity, prop, 0.3)
          }
        })
      }
    }
  }
}
</script>

<style lang="css">
.nitrogen-panel {
  background: #fff;
  position: relative;
  width: 100%;
  text-align: center;
}
.nitrogen-chart-wrapper {
  margin: 0 auto;
}
.nitrogen-chart {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
}
.nitrogen-chart path:hover { opacity: 0.85; }
.section-segments path {cursor: pointer;}
.section-labels text {cursor: pointer;}
</style>
