<template>
  <modal v-model="showModal" title="患者信息" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="itemForm" :model="item" :rules="itemRule" label-width="100px" item-width="300px">
      <form-item label="uniqueCode" prop="uniqueCode">
        <y-input v-model="item.uniqueCode"></y-input>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
export default {
  components: { Modal, YForm, FormItem, YSelect, YInput },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    tablenameList: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      showModal: this.show,
      item: {
        uniqueCode: ''
      },
      itemRule: {
        uniqueCode: [{required: true, message: '不能为空', trigger: 'blur'}]
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.itemForm.validate()
      if (!this.$refs.itemForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.mergeValues(this.item))
    },
    mergeValues (item) {
      let data = Object.assign({}, item)
      return data
    }
  }
}
</script>

<style lang="css">
</style>
