export default {
  methods: {
    setCellWidth (column, index, top) {
      let width = ''
      if (column.width) {
        width = column.width
      } else if (this.columnsWidth[column._index]) {
        width = this.columnsWidth[column._index].width
      }
      // when table has scrollBar,reset a width to resolve scroll position bug
      if (this.columns.length === index + 1 && !top && this.$parent.bodyHeight !== 0) {
        if (this.$parent.bodyHeight < this.$parent.bodyRealHeight) {
          width -= this.$parent.scrollBarWidth
        }
      }
      return width
    },
    alignCls (column, row = {}) {
      let cellClassName = ''
      if (row.cellClassName && column.key && row.cellClassName[column.key]) {
        cellClassName = row.cellClassName[column.key]
      }
      return {
        [`${cellClassName}`]: cellClassName,    // cell className
        [`${column.className}`]: column.className,    // column className
        [`table-column-${column.align}`]: column.align,
        'no-border': this.noborder,
        'table-column-selection': column.type === 'selection',
        [`table-hidden`]: (this.fixed === 'left' && column.fixed !== 'left') || (this.fixed === 'right' && column.fixed !== 'right') || (!this.fixed && column.fixed && (column.fixed === 'left' || column.fixed === 'right'))
      }
    },
    isPopperShow (column) {
      return column.filters && ((!this.fixed && !column.fixed) || (this.fixed === 'left' && column.fixed === 'left') || (this.fixed === 'right' && column.fixed === 'right'))
    }
  }
}
