<template>
  <div class="input-number" :class="{[`input-number-${size}`]: !!size, 'input-number-disabled': disabled, 'input-number-focused': focused}">
    <div class="input-number-handler-wrap">
      <a class="input-number-handler input-number-handler-up" :class="{'input-number-handler-up-disabled': upDisabled}"
        @click="up"
        @mouse.down="preventDefault">
        <span class="input-number-handler-up-inner ionic-icon ionic-icon-ios-arrow-up" @click="preventDefault"></span>
      </a>
      <a class="input-number-handler input-number-handler-down" :class="{'input-number-handler-down-disabled': downDisabled}"
        @click="down"
        @mouse.down="preventDefault">
        <span class="input-number-handler-down-inner ionic-icon ionic-icon-ios-arrow-down" @click="preventDefault"></span>
      </a>
    </div>
    <div class="input-number-input-wrap">
      <input
        ref="inputNumber"
        class="input-number-input"
        :disabled="disabled"
        autocomplete="off"
        @keyup.enter="handleEnter"
        @focus="focus"
        @blur="blur"
        @keydown.stop="keyDown"
        v-model="currentValue"/>
    </div>
  </div>
</template>

<script>
import { oneOf } from '../../../utils/assist'
import Emitter from '../mixins/emitter'

function isValueNumber (value) {
  return (/(^-?[0-9]+\.{1}\d+$)|(^-?[1-9][0-9]*$)|(^-?0{1}$)/).test(value + '')
}
function addNum (num1, num2) {
  let sq1, sq2, m
  try {
    sq1 = num1.toString().split('.')[1].length
  } catch (e) {
    sq1 = 0
  }
  try {
    sq2 = num2.toString().split('.')[1].length
  } catch (e) {
    sq2 = 0
  }
  // if (sq1 === 0 || sq2 === 0) {
  //   return num1 + num2
  // } else {
  //   m = Math.pow(10, Math.max(sq1, sq2))
  //   return (num1 * m + num2 * m) / m
  // }
  m = Math.pow(10, Math.max(sq1, sq2))
  return (num1 * m + num2 * m) / m
}

export default {
  name: 'InputNumber',
  mixins: [ Emitter ],
  props: {
    max: {
      type: Number,
      default: Infinity
    },
    min: {
      type: Number,
      default: -Infinity
    },
    step: {
      type: Number,
      default: 1
    },
    value: {
      type: [Number, String],
      default: ''
    },
    size: {
      validator (value) {
        return oneOf(value, ['small', 'large'])
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      focused: false,
      upDisabled: false,
      downDisabled: false,
      oldValue: this.value,
      currentValue: this.value,
      changeTime: 0
    }
  },
  watch: {
    value (val) {
      this.currentValue = parseFloat(val || 0) || '' // parseFloat('') = NaN
    },
    currentValue (val) {
      this.changeTime = new Date().getTime()
      this.resolveUpDown(val)
    },
    changeTime (val) {
      this.watchValueChange()
    }
  },
  mounted () {
    this.resolveUpDown(this.currentValue)
  },
  methods: {
    preventDefault (e) {
      e.preventDefault()
    },
    up () {
      // const targetVal = Number(e.target.value)
      const targetVal = Number(this.$refs.inputNumber.value)
      if (this.upDisabled && isNaN(targetVal)) {
        return false
      }
      this.changeStep('up')
    },
    down () {
      // const targetVal = Number(e.target.value)
      const targetVal = Number(this.$refs.inputNumber.value)
      if (this.downDisabled && isNaN(targetVal)) {
        return false
      }
      this.changeStep('down')
    },
    focus () {
      this.focused = true
    },
    changeStep (type) {
      if (this.disabled) {
        return false
      }

      // const targetVal = Number(e.target.value)
      const targetVal = Number(this.$refs.inputNumber.value)
      let val = Number(this.currentValue)
      const step = Number(this.step)
      if (isNaN(val)) {
        return false
      }

      // input a number, and key up or down
      if (!isNaN(targetVal)) {
        if (type === 'up') {
          if (addNum(targetVal, step) <= this.max) {
            val = targetVal
          } else {
            return false
          }
        } else if (type === 'down') {
          if (addNum(targetVal, -step) >= this.min) {
            val = targetVal
          } else {
            return false
          }
        }
      }

      if (type === 'up') {
        val = addNum(val, step)
      } else if (type === 'down') {
        val = addNum(val, -step)
      }
      this.currentValue = val
      this.setValue(val)
    },
    setValue (val) {
      this.oldValue = val
      this.$nextTick(() => {
        this.$emit('input', val)
        this.$emit('on-change', val)
        this.dispatch('FormItem', 'on-form-change', val)
      })
    },
    blur () {
      this.focused = false
      this.resolveNumber(this.currentValue)
      this.dispatch('FormItem', 'on-form-blur', this.currentValue)
    },
    handleEnter () {
      this.resolveNumber(this.currentValue)
      this.$emit('on-enter', this.currentValue)
    },
    keyDown (e) {
      if (e.keyCode === 38) {
        e.preventDefault()
        this.up(e)
      } else if (e.keyCode === 40) {
        e.preventDefault()
        this.down(e)
      }
    },
    resolveUpDown (val) {
      if (isValueNumber(val) || val === 0 || val === '') {
        val = Number(val)
        const step = this.step

        this.upDisabled = val + step > this.max
        this.downDisabled = val - step < this.min
      } else {
        this.upDisabled = true
        this.downDisabled = true
      }
    },
    resolveNumber (val) {
      if (isValueNumber(val)) {
        val = Number(val)

        if (val > this.max) {
          this.setValue(this.max)
        } else if (val < this.min) {
          this.setValue(this.min)
        } else {
          this.setValue(val)
        }
      } else {
        this.currentValue = this.oldValue
      }
    },
    watchValueChange () {
      setTimeout(() => {
        var time = new Date().getTime()
        // console.log(time, this.changeTime, this.currentValue, this.oldValue)
        if (time > this.changeTime && '' + this.currentValue !== ('' + this.oldValue)) {
          this.resolveNumber(this.currentValue)
        }
      }, 5000)
    }
  }
}
</script>
