<template>
  <div class="sorts-panel">
    <div class="sort-panel">
      <div class="sort-item tip"><span>已选择:</span></div>
      <div class="sort-item remove" v-for="item in selectedKeys" :key="item"
        @click.stop="removeColClick(item)">
        <span>{{item}}</span>
        <span class="ionic-icon ionic-icon-android-close"></span>
      </div>
    </div>
    <div class="all-panel">
      <div class="sort-item tip"><span>请选择:</span></div>
      <template v-for="col in columns">
        <div class="sort-item" v-if="(col.key !== '#' && col.key !== 'action')" :key="col.key"
          :class="{active: col.selected}"
          @click.stop="addColClick(col)">
          <span>{{col.key}}</span>
          <span class="ionic-icon" :class="[col.selected?'ionic-icon-checkmark-round':'ionic-icon-android-add']"></span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    columns: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      selectedKeys: this.value || []
    }
  },
  watch: {
    value (val) {
      if (Array.isArray(val) && JSON.stringify(val) !== JSON.stringify(this.selectedKeys)) {
        this.selectedKeys = val
        this.initSelected(val)
      }
    },
    columns () {
      this.initSelected(this.selectedKeys)
    }
  },
  methods: {
    initSelected (val) {
      const columns = this.columns || []
      if (Array.isArray(val)) {
        columns.forEach(col => {
          if (val.includes(col.key)) {
            col.selected = false
          }
        })
      }
    },
    removeColClick (key) {
      let index = this.selectedKeys.indexOf(key)
      if (index >= 0) {
        this.selectedKeys.splice(index, 1)
      }
      this.columns.forEach(col => {
        if (col.key === key) {
          col.selected = false
        }
      })
      this.$emit('input', this.selectedKeys)
    },
    addColClick (item) {
      if (item.selected) return
      this.selectedKeys.push(item.key)
      item.selected = true
      this.$emit('input', this.selectedKeys)
    }
  }
}
</script>

<style lang="less">
.sorts-panel {
  padding: 10px;
  border-radius: 4px;
}
.sort-panel, .all-panel {
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  min-width: 1000px;
  &:after {
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}
.sort-item {
  padding: 2px 7px;
  float: left;
  cursor: pointer;
  &.remove {
    &:hover {
      background: #eee;
      color: #da4141;
    }
  }
  &:hover {
    background: #ddd;
    color: #987654;
    &.active {
      background: #fff;
      cursor: not-allowed;
    }
  }
  &.active {
    color: #2373ec;
  }
  &.tip {
    &:hover {
      background: #fff;
      color: #2c3e50;
    }
  }
}
</style>
