// used in myContainers.vue and home.vue
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  methods: {
    loadFreezerList () {
      this.$http.get(`${this.httpRoot}/container/freezer/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          // this.freezerList = res.freezerList || []
          this.resetData(res.freezerList)
          this.$store.dispatch('updateFreezerList', res.freezerList || [])
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    resetData (freezerList) {
      if (freezerList && freezerList.length) {
        freezerList.forEach(item => {
          this.$set(item, 'value', 'mySamples')
        })
      }
    }
  }
}
