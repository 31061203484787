<template>
  <div class="shelf-panel" :style="shelfStyle">
    <div class="layer-box-container">
      <div class="layer-box" v-for="(item, index) in layerList" :class="{enable: item.enable}" @click.stop="onClick(item)" :key="index">
        <delete-mask :show="showDeleteMask" :container="item" @on-delete="onShowDeleteWin" @on-auth="onShowAuthWin" @on-edit="onShowEditWin"></delete-mask>
        <div class="layer-item-hd" :style="{backgroundColor: getLevalBg(index)}">
          <span>{{item.label}}</span>
          <span v-if="currentPath.indexOf('mySamples')>=0">({{item.siteCode}})</span>
        </div>
        <div class="layer-item-bd">
          <span>样本数量</span><span class="num">{{item.sampleNum}}</span>
        </div>
        <div class="layer-item-ft">
          <span class="label">已经使用空间</span>
          <span class="prog-outer"><span class="prog-inner" :style="getMaskStyle(item)"></span></span>
          <span class="num">{{item.proportion}}%</span>
        </div>
      </div>
    </div>
    <div class="layer-box-append" v-if="currentPath.indexOf('mySamples') >= 0">
      <div class="layer-add" @click.stop="onShowModal">
        <div class="layer-add-plus">+</div>
      </div>
    </div>
    <edit-menu v-if="currentPath.indexOf('mySamples') >= 0"
      :active="layerEditActive" @on-edit-click="onContainerEdit('layer')"></edit-menu>
    <layer-add v-if="currentPath.indexOf('mySamples') >= 0"
      v-model="newLayer"
      :show="showModal"
      @on-hide="showModal=false"
      @on-add-ok="onLayerAdd"></layer-add>
    <delete-win
      v-model="deletePermanent"
      :show="showDeleteWin"
      @on-hide="showDeleteWin=false"
      @on-delete-ok="onLayerDelete"></delete-win>
    <auth-win
      v-model="currentGroupCodeList"
      :show="showAuthWin"
      @on-hide="showAuthWin=false"
      @on-confirm-ok="onContainerAuth"></auth-win>
    <edit-win
      containerType="layer"
      v-model="editContainer"
      :show="showEditWin"
      @on-hide="showEditWin=false"
      @on-confirm-ok="onContainerUpdate"></edit-win>
  </div>
</template>

<script>
import LayerAdd from '../add/layerAdd'
import DeleteMask from '../delete/deleteMask'
import DeleteWin from '../delete/deleteWin'
import AuthWin from '../auth/auth-win'
import EditWin from '../edit/edit-win'
import EditMenu from '../delete/edit-menu'
import editMixin from '../mixins/editMixin'
import { authenticate, catchExpired } from '../../../../utils/auth'
export default {
  name: 'ShelfPanel',
  components: { LayerAdd, DeleteMask, DeleteWin, AuthWin, EditWin, EditMenu },
  mixins: [editMixin],
  props: {
    parentId: String,
    rootId: String,
    parentCode: String
  },
  data () {
    return {
      containerType: 'layer',
      layerList: [],
      newLayer: {
        layerNum: 1,
        label: '',
        desc: '',
        sampleboxCount: 0,
        sampleboxRow: 1,
        sampleboxCol: 1,
        exboxType: '',
        strawboxCount: 0
      }
    }
  },
  computed: {
    currentPath () {
      return this.$store.getters.getCurrentPath
    },
    shelfStyle () {
      if (this.currentPath.indexOf('sampleTransSelect') >= 0) {
        return {
          maxWidth: '400px'
        }
      } else {
        return {}
      }
    }
  },
  watch: {
    parentId (val) {
      this.loadLayerList()
    }
  },
  mounted () {
    this.loadLayerList()
  },
  methods: {
    getMaskBg (item) {
      return (item.proportion < 25 && item.proportion >= 0
        ? '#6AE065'
        : item.proportion < 50 && item.proportion >= 25
          ? '#FCB946'
          : item.proportion < 75 && item.proportion >= 50
            ? '#70A3C7'
            : item.proportion < 100 && item.proportion >= 75
              ? '#F87E7F'
              : '#f00'
      )
    },
    getMaskStyle (item) {
      return {
        width: item.proportion + '%',
        backgroundColor: this.getMaskBg(item)
      }
    },
    getLevalBg (level) {
      const colors = ['#4D809A', '#5382B7', '#4F8DD9', '#658EBC']
      return colors[level % 4]
    },
    loadLayerList () {
      this.$parent.showLoading = true
      this.$http.get(`${this.httpRoot}/container/layer/list`,
        authenticate({params: {parentId: this.parentId, projectId: this.$store.state.project.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.layerList = res.layerList || []
          this.$parent.showLoading = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onClick (item) {
      // 选择左边对应的 container, 更新右上 containerPath 切换右边 panel
      if (item.enable) {
        this.$parent.selectContainerById(item.id)
        this.$parent.updateContainerPathById(item.id)
        this.$parent.updatePanel('layerPanel', {
          parentId: item.id,
          rootId: item.rootId,
          parentCode: item.siteCode
        })
      }
    },
    onLayerAdd () {
      this.$http.post(`${this.httpRoot}/container/layer/add`,
        Object.assign({}, {
          parentId: this.parentId,
          rootId: this.rootId,
          parentCode: this.parentCode
        }, this.newLayer), authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('创建抽屉成功')
          this.loadLayerList()
        } else {
          this.$Modal.error({
            title: '创建抽屉失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('创建抽屉失败')
      })
    },
    onLayerDelete () {
      let path = this.deletePermanent ? '/permanent' : ''
      this.$http.post(`${this.httpRoot}/container/layer/delete${path}`, {
        id: this.deleteContainer.id,
        rootId: this.deleteContainer.rootId,
        siteCode: this.deleteContainer.siteCode
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('删除抽屉成功')
          this.loadLayerList()
        } else {
          this.$Modal.error({
            title: '删除抽屉失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('删除抽屉失败')
      })
    }
  }
}
</script>

<style lang="less">
@import "../../../../assets/ivu/mixins/clear";
.shelf-panel {
  padding: 0 10px;
  min-width: 400px;
  .layer-box {
    border-bottom: 0;
    position: relative;
    font-size: 12px;
    &.enable {
      &:hover {
        color: #fff;
        cursor: pointer;
      }
    }
    &:after {
      .clearFloat();
    }
  }
  .layer-item-hd,
  .layer-item-bd,
  .layer-item-ft {
    float: left;
    height: 50px;
    line-height: 50px;
    margin-bottom: 3px;
  }
  .layer-item-hd {
    width: 155px;
    margin-right: 3px;
    text-align: center;
    font-size: 15px;
    color: #fff;
  }
  .layer-item-bd {
    width: 150px;
    font-size: 15px;
    padding-left: 15px;
    background: #F1F1F1;
    .num {
      font-size: 17px;
      font-weight: bold;
      padding-left: 3px;
    }
  }
  .layer-item-ft {
    font-size: 15px;
    background: #F1F1F1;
    .label {
      display: inline-block;
      width: 95px;
      padding-left: 5px;
    }
    .prog-outer {
      position: relative;
      display: inline-block;
      width: 150px;
      height: 12px;
      background: #d8d8d8;
    }
    .prog-inner {
      position: absolute;
      display: inline-block;
      height: 12px;
    }
    .num {
      font-size: 17px;
      font-weight: bold;
      padding-left: 3px;
      display: inline-block;
      width: 65px;
    }
  }
}
.layer-box-append {
  width: 625px;
}
.layer-add {
  height: 50px;
  transition: all .3s;
  &:hover {
    cursor: pointer;
    .layer-add-plus {
      display: block;
    }
  }
}
.layer-add-plus {
  border: 2px dotted #ccc;
  font-size: 32px;
  height: 50px;
  line-height: 50px;
  color: #bdbdbd;
  text-align: center;
  display: none;
  transition: all .3s;
}
</style>
