<template>
  <div class="sys-up">
    <div class="sys-actions">
      <y-button @click.native.stop="onProjectExport">deb导出</y-button>
      <y-button @click.native.stop="onProjectImport">deb导入</y-button>
      <y-button @click.native.stop="onFrontImport">前端导入</y-button>
      <y-button @click.native.stop="onEndImport">后端导入</y-button>
      <y-button @click.native.stop="onEndStart">后端启动</y-button>
    </div>
    <project-import-win
      :show="showImportWin"
      @on-hide="showImportWin=false"
      @on-confirm-ok="onImportProject"></project-import-win>
    <front-import-win
      :show="showFrontWin"
      @on-hide="showFrontWin=false"
      @on-confirm-ok="onImportProject"></front-import-win>
    <end-import-win
      :show="showEndWin"
      @on-hide="showEndWin=false"
      @on-confirm-ok="onImportProject"></end-import-win>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)">{{loadingText}}</loading>
  </div>
</template>
<script>
import YButton from '../common/button/button'
import YSelect from '../common/select/select'
import Loading from '../common/loading/loading'
import ProjectImportWin from './win/project-import-win'
import FrontImportWin from './win/front-import-win'
import EndImportWin from './win/end-import-win'

import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {YButton, YSelect, Loading, ProjectImportWin, FrontImportWin, EndImportWin},
  data () {
    return {
      loadingText: '',
      showLoading: false,
      showImportWin: false,
      showFrontWin: false,
      showEndWin: false
    }
  },
  computed: {
    projectCode () {
      return this.$store.state.project.projectId
    }
  },
  methods: {
    onProjectExport () {
      this.updateLoadStatus('load-start', '')
      this.$http.post(`${this.httpRoot}/db/backup/export`, {
        projectCode: this.projectCode
      }, authenticate({responseType: 'blob', timeout: 360000}))
      .then(res => res.blob())
      .then(blob => {
        this.updateLoadStatus('load-success')
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = this.projectCode + '-' + new Date().format('yyyyMMddhhmmss') + '.tar.gz'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onProjectImport () {
      this.showImportWin = true
    },
    onFrontImport () {
      this.showFrontWin = true
    },
    onEndImport () {
      this.showEndWin = true
    },
    onImportProject () {
      this.updateLoadStatus('load-success', '上传项目文件成功')
    },
    onEndStart () {
      this.updateLoadStatus('load-start', '')
      this.$http.post(`${this.httpRoot}/app/backend/start`, {
        projectCode: this.projectCode
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        this.updateLoadStatus('load-success')
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', err.info)
      })
    },
    updateLoadStatus (status, loadingText) {
      if (status === 'load-start') {
        this.loadingText = loadingText
        this.showLoading = true
      } else if (status === 'load-success') {
        this.showLoading = false
        if (loadingText) {
          this.$Message.success(loadingText)
        }
      } else if (status === 'load-faild') {
        this.showLoading = false
        this.$Message.error(loadingText)
      }
    }
  }
}
</script>
<style lang="less">
.sys-up {
  padding-top: 20px;
  padding-left: 20px;
}
.sys-actions {
  margin-left: 130px;
  button {
    margin-right: 20px;
  }
}
</style>
