<template>
  <div class="edit-mask-box" v-show="show">
    <div class="edit-mask">
      <i class="icon ionic-icon"
        title="删除容器"
        @mouseover="iconHover=true"
        @mouseout="iconHover=false"
        @click.stop="onContainerDelete"
        :class="[iconHover?'ionic-icon-ios-close':'ionic-icon-ios-close-outline']">
      </i>
      <i class="icon ionic-icon"
        title="用户组授权"
        @mouseover="lockHover=true"
        @mouseout="lockHover=false"
        @click.stop="onContainerAuth"
        :class="[lockHover?'ionic-icon-ios-unlocked':'ionic-icon-ios-unlocked-outline']">
      </i>
      <i class="icon ionic-icon"
        title="编辑容器标签名称"
        @mouseover="editHover=true"
        @mouseout="editHover=false"
        @click.stop="onContainerEdit"
        :class="[editHover?'ionic-icon-ios-compose':'ionic-icon-ios-compose-outline']">
      </i>
      <i class="icon ionic-icon"
        title="更新每层容器样本总数（total）"
        @mouseover="calcvolHover=true"
        @mouseout="calcvolHover=false"
        @click.stop="onContainerCalcvol"
        :class="[calcvolHover?'ionic-icon-ios-analytics':'ionic-icon-ios-analytics-outline']">
      </i>
    </div>
    <div class="edit-mask">
      <i class="icon ionic-icon"
        title="更新每层容器样本数（sampleNum）"
        @mouseover="calcSamplenumHover=true"
        @mouseout="calcSamplenumHover=false"
        @click.stop="onContainerCalcSamplenum"
        :class="[calcSamplenumHover?'ionic-icon-ios-pie':'ionic-icon-ios-pie-outline']">
      </i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    container: {}
  },
  data () {
    return {
      iconHover: false,
      lockHover: false,
      editHover: false,
      calcvolHover: false,
      calcSamplenumHover: false
    }
  },
  methods: {
    onContainerDelete () {
      this.$emit('on-delete', this.container)
    },
    onContainerAuth () {
      this.$emit('on-auth', this.container)
    },
    onContainerEdit () {
      this.$emit('on-edit', this.container)
    },
    onContainerCalcvol () {
      this.$emit('on-calcvol', this.container)
    },
    onContainerCalcSamplenum () {
      this.$emit('on-calc-samplenum', this.container)
    }
  }
}
</script>

<style lang="less">
.edit-mask-box {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.edit-mask {
  background: rgba(255, 255, 255, 0.65);
  .icon {
    width: 28px;
    text-align: center;
    font-size: 18px;
    color: #666;
    cursor: pointer;
  }
}
</style>
