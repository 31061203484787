export default {
  methods: {
    getLabelField (data = {}) {
      if (this.labelField && data[this.labelField]) {
        return data[this.labelField]
      } else if (typeof data === 'string') {
        return data
      } else {
        return data.name || data.label || data.title || ''
      }
    },
    getValueField (data = {}) {
      if (this.valueField && data[this.valueField]) {
        return data[this.valueField]
      } else if (typeof data === 'string') {
        return data
      } else if (typeof data.value === 'boolean') {
        return data.value
      } else if (this.$options.name === 'Option') {
        return data.value || data.id || data.key || 'vid_' + this.valueId + this.id
      } else {
        return data.value || data.id || data.key || data.code
      }
    }
  }
}
