<template>
  <div class="dict-list-container">
    <div class="dict-list">
      <div class="dict-list-item-header">
        <span class="name">名称</span>
        <span class="code">编码</span>
      </div>
      <div class="dict-list-item" v-for="item in data" :key="item.id"
         :class="{selected: item.id===currentDict.id}" @click.stop="onDictClick(item)">
        <span class="name">{{item.name}}</span>
        <span class="code">{{item.code}}</span>
      </div>
    </div>
    <div class="dict-list-toolbar">
      <span class="tool">
        <tooltip placement="top-start" content="添加新的 字典项">
          <span class="ionic-icon" :class="[plusHover?'ionic-icon-ios-plus':'ionic-icon-ios-plus-outline']"
            @mouseover="onMouseoverPlus"
            @mouseout="onMouseoutPlus"
            @click.stop="onPlusClick"></span>
        </tooltip>
      </span>
      <span class="tool">
        <tooltip placement="top" content="删除选择的 字典项">
          <span class="ionic-icon" :class="[closeHover?'ionic-icon-ios-close':'ionic-icon-ios-close-outline']"
            @mouseover="onMouseoverClose"
            @mouseout="onMouseoutClose"
            @click.stop="onDeleteClick"></span>
        </tooltip>
      </span>
      <span class="tool">
        <tooltip placement="top-end" content="编辑选择的 字典项">
          <span class="ionic-icon" :class="[checkHover?'ionic-icon-ios-compose':'ionic-icon-ios-compose-outline']"
            @mouseover="onMouseoverCheck"
            @mouseout="onMouseoutCheck"
            @click.stop="onUpdateClick"></span>
        </tooltip>
      </span>
    </div>
  </div>
</template>

<script>
import Tooltip from '../../common/tooltip/tooltip'
export default {
  components: {Tooltip},
  props: {
    data: {
      type: Array,
      default () { return [] }
    },
    currentDict: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      plusHover: false,
      closeHover: false,
      checkHover: false
    }
  },
  methods: {
    onDictClick (item) {
      this.$emit('on-dict-select', item)
    },
    onMouseoverPlus () {
      this.plusHover = true
    },
    onMouseoutPlus () {
      this.plusHover = false
    },
    onMouseoverClose () {
      this.closeHover = true
    },
    onMouseoutClose () {
      this.closeHover = false
    },
    onMouseoverCheck () {
      this.checkHover = true
    },
    onMouseoutCheck () {
      this.checkHover = false
    },
    onPlusClick () {
      this.$emit('on-dict-add')
    },
    onDeleteClick () {
      this.$emit('on-dict-delete')
    },
    onUpdateClick () {
      this.$emit('on-dict-edit')
    }
  }
}
</script>

<style lang="less">
.dict-list-container {
  width: 300px;
  height: 100%;
  float: left;
  position: relative;
  overflow-y: auto;
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    background: #ddd;
  }
}
.dict-list {
  margin-bottom: 32px;
  width: 100%;
}
.dict-list-item,
.dict-list-item-header {
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  cursor: pointer;
  .name {
    width: 50%;
    padding-left: 5px;
    display: inline-block;
  }
  .code {
    width: 40%;
    padding-left: 5px;
    display: inline-block;
  }
  &.selected {
    background: #afc6ec;
  }
}
.dict-list-item {
  &:nth-child(odd) {
    background: #f3f3f3;
    &.selected {
      background: #afc6ec;
    }
  }
}
.dict-list-toolbar {
  position: fixed;
  bottom: 0;
  width: 300px;
  height: 32px;
  background: #f7f7f7;
  .tool {
    width: 33.3%;
    height: 32px;
    line-height: 32px;
    display: inline-block;
    float: left;
    text-align: center;
    font-size: 22px;
    position: relative;
    &:after{
      position: absolute;
      content: "";
      right: 0;
      top: 5px;
      bottom: 5px;
      width: 1px;
      background: #ddd;
    }
    &:last-child {
      &:after{
        display: none;
      }
    }
  }
  .ionic-icon {
    cursor: pointer;
  }
}
</style>
