<template>
  <modal v-model="showModal" title="新建冻存架" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm" :styles="{top: '60px'}">
    <y-form ref="shelfForm" :model="shelf" :rules="shelfRule" label-width="100px" item-width="250px">
      <form-item label="液氮罐区" prop="section" v-if="freezerType === 'nitrogen_sm' || freezerType === 'nitrogen_bg'">
        <y-select v-model="shelf.section" :data="sectionList" clearable></y-select>
      </form-item>
      <form-item :label="levelLabel" prop="level" v-if="freezerType === 'fridge_v'">
        <y-select v-model="shelf.level" :data="levelList" clearable></y-select>
      </form-item>
      <form-item :label="levelLabel" prop="level" v-if="freezerType === 'fridge_h' || freezerType === 'nitrogen_sm' || freezerType === 'nitrogen_bg'">
        <y-select v-model="shelf.level" :data="levelList2" clearable></y-select>
      </form-item>
      <form-item label="架子编号" prop="shelfNum">
        <input-number v-model="shelf.shelfNum"></input-number>
      </form-item>
      <form-item label="架子标签" prop="label">
        <y-input v-model="shelf.label"></y-input>
      </form-item>
      <form-item label="备注" prop="desc">
        <y-input v-model="shelf.desc" type="textarea"></y-input>
      </form-item>
      <div class="split-line"></div>
      <form-item label="架子抽屉数" prop="layerCount">
        <input-number v-model="shelf.layerCount"></input-number>
      </form-item>
      <form-item label="每抽屉盒子数" prop="sampleboxCount">
        <input-number v-model="shelf.sampleboxCount"></input-number>
      </form-item>
      <form-item label="样本盒类型" prop="exboxType">
        <y-select v-model="shelf.exboxType" :data="exboxTypeList"></y-select>
      </form-item>
      <template v-if="shelf.exboxType==='strawbox'">
        <form-item label="麦管盒数" prop="strawboxCount">
          <input-number v-model="shelf.strawboxCount"></input-number>
        </form-item>
      </template>
      <form-item label="盒子行数" prop="sampleboxRow">
        <input-number v-model="shelf.sampleboxRow"></input-number>
      </form-item>
      <form-item label="盒子列数" prop="sampleboxCol">
        <input-number v-model="shelf.sampleboxCol"></input-number>
      </form-item>
      <form-item label="盒子类型" prop="sampleboxType">
        <y-select v-model="shelf.sampleboxType" :data="typeList"></y-select>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../modal/modal.vue'
import YForm from '../../form/form'
import FormItem from '../../form/form-item'
import YInput from '../../input/input'
import InputNumber from '../../input/input-number'
import YSelect from '../../select/select'
import Poptip from '../../poptip/poptip'
export default {
  components: { Modal, YForm, FormItem, YInput, InputNumber, YSelect, Poptip },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    freezerType: {
      type: String,
      default: 'nitrogen_bg'
    },
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      levelList: [
        {id: '001', name: '1层', value: 1},
        {id: '002', name: '2层', value: 2},
        {id: '003', name: '3层', value: 3},
        {id: '004', name: '4层', value: 4},
        {id: '005', name: '5层', value: 5},
        {id: '006', name: '6层', value: 6}
      ],
      levelList2: [
        {id: '001', name: '1排', value: 1},
        {id: '002', name: '2排', value: 2},
        {id: '003', name: '3排', value: 3},
        {id: '004', name: '4排', value: 4},
        {id: '005', name: '5排', value: 5},
        {id: '006', name: '6排', value: 6}
      ],
      sectionList: [
        {id: '001', name: '1区', value: 1},
        {id: '002', name: '2区', value: 2},
        {id: '003', name: '3区', value: 3},
        {id: '004', name: '4区', value: 4},
        {id: '005', name: '5区', value: 5},
        {id: '006', name: '6区', value: 6}
      ],
      shelf: this.value || {
        level: 1,
        section: 1,
        shelfNum: 1,
        label: '',
        desc: '',
        layerCount: 0,
        sampleboxCount: 0,
        sampleboxRow: 1,
        sampleboxCol: 1,
        strawboxCount: 0
      },
      shelfRule: {
        level: [{type: 'number', min: 1, max: 10, required: true, message: '容器层次或排次不能为空, ≥1 且 ≤10', trigger: 'change'}],
        section: [{type: 'number', min: 1, max: 6, required: true, message: '液氮罐区位不能为空, ≥1 且 ≤6', trigger: 'change'}],
        shelfNum: [{type: 'number', min: 1, max: 80, required: true, message: '架子编号不能为空, ≥1 且 ≤80', trigger: 'blur'}],
        label: [{required: true, max: 15, message: '架子标签不能为空, 长度小于15', trigger: 'blur'}],
        exboxType: [{required: true, message: '样本盒类型不能为空', trigger: 'blur'}],
        layerCount: [{type: 'number', min: 0, max: 10, message: '架子抽屉数 ≥0 且 ≤10', trigger: 'change'}],
        sampleboxCount: [{type: 'number', min: 0, max: 10, message: '每抽屉盒子数 ≥0 且 ≤10', trigger: 'change'}],
        sampleboxRow: [{type: 'number', min: 1, max: 16, message: '盒子行数 ≥1 且 ≤16', trigger: 'change'}],
        sampleboxCol: [{type: 'number', min: 1, max: 12, message: '盒子列数 ≥1 且 ≤12', trigger: 'change'}]
      },
      exboxTypeList: [
        {name: '冻存管', value: 'samplebox'},
        {name: '麦管', value: 'strawbox'},
        {name: '切片', value: 'cardbox'}
      ],
      typeList: [
        {name: '普通类型', value: 'normal'},
        {name: '间隔类型', value: 'skip'}
      ]
    }
  },
  computed: {
    levelLabel () {
      if (this.freezerType === 'fridge_v') {
        return '冰箱层次'
      } else if (this.freezerType === 'fridge_h') {
        return '冰箱排次'
      } else if (this.freezerType === 'nitrogen_sm' || this.freezerType === 'nitrogen_bg') {
        return '液氮罐区排次'
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    'shelf.level' (val) {
      this.$emit('input', this.shelf)
    },
    'shelf.section' (val) {
      this.$emit('input', this.shelf)
    },
    'shelf.shelfNum' (val) {
      this.$emit('input', this.shelf)
    },
    'shelf.label' (val) {
      this.$emit('input', this.shelf)
    },
    'shelf.desc' (val) {
      this.$emit('input', this.shelf)
    },
    'shelf.layerCount' (val) {
      this.$emit('input', this.shelf)
    },
    'shelf.sampleboxCount' (val) {
      this.$emit('input', this.shelf)
    },
    'shelf.exboxType' (val) {
      this.$emit('input', this.shelf)
    },
    'shelf.strawboxCount' (val) {
      this.$emit('input', this.shelf)
    },
    'shelf.sampleboxRow' (val) {
      this.$emit('input', this.shelf)
    },
    'shelf.sampleboxCol' (val) {
      this.$emit('input', this.shelf)
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.shelfForm.validate()
      if (!this.$refs.shelfForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-add-ok')
    }
  }
}
</script>

<style lang="css">
.split-line {
  height: 1px;
  background-color: #eee;
  margin-bottom: 10px;
}
</style>
