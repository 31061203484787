const state = {
  columnMap: {},
  filterMap: {}
}
const mutations = {
  UPDATE_COLUMNMAP (state, {key, columns}) {
    state.columnMap[key] = columns
  },
  UPDATE_FILTERMAP (state, {key, filters}) {
    state.filterMap[key] = filters
  }
}

export default {
  state,
  mutations
}
