import { getPropertyFromData } from './util'

let nodeIdSeed = new Date().getTime()

export default class Node {
  constructor (options) {
    this.id = nodeIdSeed++

    this.data = null
    this.parent = null
    this.store = null
    this.level = 0
    this.type = ''
    this.expanded = true
    this.focused = false
    this.isLeaf = true
    this.childNodes = []

    for (let name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name]
      }
    }

    if (this.parent) {
      this.level = this.parent.level + 1
    }
    this._key = getPropertyFromData(this, 'key')

    const store = this.store
    store.registerNode(this)
    store.currentNode = this

    if (this.data && this.hasChild()) {
      this.setChild(this.data)
    }
  }

  get label () {
    return getPropertyFromData(this, 'label')
  }

  get icon () {
    return getPropertyFromData(this, 'icon')
  }

  get key () {
    return this._key || this.id
  }

  hasChild () {
    return this.data && this.data.children && this.data.children.length
  }

  setChild () {
    let children

    if (this.data) {
      children = getPropertyFromData(this, 'children') || []
    }

    for (let i = 0, len = children.length; i < len; i++) {
      this.insertChild(children[i], this.store.nodeType || 'dataset')
    }
  }

  insertChild (data, type, index) {
    if (!data) throw new Error('insertChild error: child is required.')

    const child = new Node({
      type,
      data,
      parent: this,
      store: this.store
    })

    if (index > -1) {
      this.childNodes.splice(index, 0, child)
    } else {
      this.childNodes.push(child)
    }

    this.updateLeafState()
  }

  removeChild (child) {
    this.removeChildData(child)

    const index = this.childNodes.indexOf(child)

    if (index > -1) {
      this.store && this.store.deregisterNode(child)
      child.parent = null
      this.childNodes.splice(index, 1)
    }

    this.updateLeafState()
  }

  removeChildData (child) {
    let attr = this.mapTypeSet(child.type)

    if (this.data[attr]) {
      const dataIndex = this.data[attr].indexOf(child.data)
      if (dataIndex > -1) {
        this.data[attr].splice(dataIndex, 1)
      }
      if (!this.data[attr].length) {
        delete this.data[attr]
      }
    }
  }

  insertChildData (data, type, index) {
    let _data = JSON.parse(JSON.stringify(data))
    let attr = this.mapTypeSet(type)

    this.data[attr] = this.data[attr] || []

    if (index > -1) {
      this.data[attr].splice(index, 0, _data)
    } else {
      this.data[attr].push(_data)
    }

    this.insertChild(_data, type, index)
  }

  mapTypeSet (type) {
    const types = {
      'patientDataset': 'children',
      'dataset': 'children'
    }
    if (!types[type]) throw new Error('insertChildData error: type is not exist.')
    return types[type]
  }

  delete () {
    if (this.parent) {
      this.parent.removeChild(this)
    }
  }

  updateLeafState () {
    const childNodes = this.childNodes
    this.isLeaf = !childNodes || childNodes.length === 0
  }

  toggleExpand () {
    this.expanded = !this.expanded
  }

  expand () {
    if (!this.expanded) this.expanded = true
  }

  focus (status, deep) {
    if (!deep) {
      this.store.focus(false, true)
    }
    if (this.focused && !status && deep) {
      this.focused = false
    }
    this.childNodes.forEach(child => {
      child.focus(false, true)
    })
    if (!this.focused && status && !deep) {
      this.focused = true
    }
  }
}
