<template>
  <span class="node-content" :class="{focused: node.focused}">
    <span class="node-content-label" :class="[node.type==='root'?'field': 'group']"
      @click.stop="onLabelClick">{{ node.label }}
    </span>
    <span>[{{node.key}}]</span>
    <span
      class="node-content-btn ionic-icon ionic-icon-plus" @click.stop="onAddClick"></span>
    <span v-if="node.type !== 'root'"
      class="node-content-btn ionic-icon ionic-icon-close" @click.stop="onDelete"></span>
    <span v-if="node.type !== 'root'"
      class="node-content-btn ionic-icon ionic-icon-edit" @click.stop="onEdit"></span>
  </span>
</template>

<script>
export default {
  props: {
    node: {
      default () {
        return {}
      }
    }
  },
  methods: {
    onLabelClick () {
      this.$emit('on-label-click')
    },
    onAddClick () {
      this.$emit('on-add-child')
    },
    onDelete () {
      this.$Modal.confirm({
        content: '确认删除该分组？',
        onOk: () => {
          this.node.delete()
          this.$emit('on-delete-node')
        }
      })
    },
    onEdit () {
      this.$emit('on-edit-node')
    }
  }
}
</script>

<style lang="css">
</style>
