<template>
  <div class="field-tree" :style="{overflow: 'auto', height: pageHeight + 'px'}">
    <tree-node
      :node="rootNode"
      :props="props"
      :key="getKeyField(rootNode)">
    </tree-node>
    <group-win
      :code="newChildKey"
      :show="showGroupWin"
      title="添加用户分组"
      @on-hide="showGroupWin=false"
      @on-confirm-ok="onGroupAdd"></group-win>
    <group-win
      v-model="currentNodeData"
      :show="showGroupEditWin"
      title="编辑用户分组"
      @on-hide="showGroupEditWin=false"
      @on-confirm-ok="onGroupEdit"></group-win>
  </div>
</template>

<script>
import TreeStore from './model/tree-store'
import TreeNode from './tree-node'
import GroupWin from '../win/group-win'
import mixin from './mixin'

export default {
  components: {TreeNode, GroupWin},
  mixins: [mixin],
  props: {
    name: {
      type: String,
      default: '所有分组'
    },
    data: Array,
    props: {
      default () {
        return {
          children: 'children',
          label: 'name',
          value: 'code',
          type: 'type',
          key: 'code',
          code: 'code',
          icon: 'icon'
        }
      }
    },
    indent: {
      type: Number,
      default: 16
    }
  },
  data () {
    return {
      $tree: null,
      $currentTreeNode: null, // for add child
      treeStore: null,
      rootNode: null,
      currentNodeData: {},

      newChildKey: '',
      showGroupWin: false,
      showGroupEditWin: false
    }
  },
  computed: {
    pageHeight () {
      return this.$store.getters.getWinHeight - 100
    }
  },
  watch: {
    data (val) {
      this.treeStore = new TreeStore({
        name: this.name,
        data: val,
        props: this.props
      })
      this.rootNode = this.treeStore.rootNode
    }
  },
  created () {
    this.$tree = this
    this.treeStore = new TreeStore({
      name: this.name,
      data: this.data,
      props: this.props
    })
    this.rootNode = this.treeStore.rootNode

    this.$on('on-child-add', () => {
      this.newChildKey = this.$currentTreeNode.getNewChildKey()
      this.onChildAdd()
    })
    this.$on('on-node-click', (data, type) => {
      this.$emit('on-tree-node-click', data, type)
    })
    this.$on('on-node-delete', (key) => {
      this.$emit('on-tree-update', key)
    })
    this.$on('on-node-edit', (data) => {
      this.currentNodeData = data
      this.onNodeEdit()
    })
  },
  methods: {
    onChildAdd () {
      this.showGroupWin = true
    },
    onGroupAdd (group) {
      if (this.$currentTreeNode) {
        this.$currentTreeNode.insertChild(group, 'child')
        this.$emit('on-tree-update')
      }
    },
    onNodeEdit () {
      this.showGroupEditWin = true
    },
    onGroupEdit () {
      this.$emit('on-tree-update')
    },
    getNodeByKey (key) {
      return this.treeStore.getNodeByKey(key)
    },
    getFieldsData () {
      return this.treeStore.getFieldsData()
    }
  }
}
</script>

<style lang="css">
</style>
