<template>
  <div class="crf-tables">
    <div class="crf-tables-title">
      {{currentCrf.title}}
    </div>
    <div class="crf-tables-list">
      <div class="crf-tables-item" @click.stop="onCrfTableItemClick(item)"
        v-for="item in currentCrf.items" :key="item.modelId">
        <span class="fa" :class="{['fa-' + item.icon]: item.icon}"></span>
        <span class="label">{{item.title}}</span>
        <span class="slid-up ionic-icon ionic-icon-arrow-up-c" @click.stop="onSlidUp(item)"></span>
        <span class="slid-down ionic-icon ionic-icon-arrow-down-c" @click.stop="onSlidDown(item)"></span>
        <span class="remove ionic-icon ionic-icon-close" @click.stop="onCrfTableDelete(item)"></span>
      </div>
      <div class="crf-tables-item-add" @click.stop="onCrfTableAddShow">
        <span class="add ionic-icon ionic-icon-plus"></span>
      </div>
    </div>
    <crf-table-win
      :show="showCrfTableWin"
      :projectId="projectId"
      :crfTableList="crfTableList"
      @on-hide="showCrfTableWin=false"
      @on-confirm-ok="onCrfTableAdd"></crf-table-win>
  </div>
</template>

<script>
import CrfTableWin from '../win/crf-table-win'
export default {
  components: {CrfTableWin},
  props: {
    projectId: String,
    crfTableList: Array,
    currentCrf: {
      type: Object,
      default () {
        return {
          title: '',
          items: []
        }
      }
    }
  },
  data () {
    return {
      showCrfTableWin: false
    }
  },
  methods: {
    onCrfTableItemClick (crfTable) {
      this.$emit('on-crftable-click', crfTable)
    },
    onCrfTableDelete (crfTable) {
      this.$Modal.confirm({
        title: `删除 CRF表 复制`,
        content: `<p>确认删除 ${this.currentCrf.title} ？</p>`,
        onOk: () => {
          const index = this.currentCrf.items.indexOf(crfTable)
          if (index > -1) {
            this.currentCrf.items.splice(index, 1)
          }
        }
      })
    },
    onCrfTableAdd (newCrfTable) {
      this.currentCrf.items = this.currentCrf.items || []
      this.currentCrf.items.push(newCrfTable)
    },
    onCrfTableAddShow () {
      this.showCrfTableWin = true
    },
    onSlidUp (item) {
      if (!this.currentCrf || !this.currentCrf.items) return
      let index = this.currentCrf.items.indexOf(item)
      if (index > 0) {
        const items = this.currentCrf.items
        const item = items[index - 1]
        items.splice(index - 1, 1)
        items.splice(index, 0, item)
      }
    },
    onSlidDown (item) {
      if (!this.currentCrf || !this.currentCrf.items) return
      let index = this.currentCrf.items.indexOf(item)
      if (index > -1 && index < this.currentCrf.items.length - 1) {
        const items = this.currentCrf.items
        const item = items[index]
        items.splice(index, 1)
        items.splice(index + 1, 0, item)
      }
    }
  }
}
</script>

<style lang="less">
.crf-tables {
  font-size: 14px;
}
.crf-tables-title {
  padding-left: 5px;
  height: 32px;
  line-height: 32px;
}
.crf-tables-item {
  padding-left: 22px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  &:hover {
    background: #ebf5ff;
    .remove, .slid-up, .slid-down {
      display: inline-block;
    }
  }
  .remove {
    padding: 0 10px;
    cursor: pointer;
    float: right;
    display: none;
    &:hover {
      color: #3399ff;
    }
  }
  .slid-up, .slid-down {
    padding: 0 3px;
    cursor: pointer;
    display: none;
    &:hover {
      color: #3399ff;
    }
  }
}
.crf-tables-item-add {
  padding-left: 22px;
  height: 26px;
  line-height: 26px;
  margin: 3px 0;
  cursor: pointer;
  .add {
    width: 60%;
    display: block;
    border-radius: 4px;
    border: 1px dotted #ccc;
    text-align: center;
    &:hover {
      color: #3399ff;
    }
  }
}
</style>
