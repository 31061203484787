<template>
  <modal v-model="showModal" title="添加 pickerData" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <div class="range">
      <span class="label">小时最大值:</span><input class="input" v-model="data.h"/>
    </div>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
export default {
  components: {Modal},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default () {
        return {h: 9}
      }
    }
  },
  data () {
    return {
      showModal: this.show
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      if (!this.validate()) {
        this.$Message.warning('请完小时填写')
        return false
      }
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.data)
    },
    validate () {
      if (!(parseInt(this.data.h) >= 0)) return false
      return true
    }
  }
}
</script>

<style lang="less">
.range{
  .label {
    width: 30%;
    padding-right: 5px;
  }
  .input {
    width: 60%;
  }
}
</style>
