<template>
  <span class="node-content select" :class="{focused: node.focused}">
    <label class="checkbox-wrapper" :for="node.key" v-if="node.showCheckbox">
      <span class="checkbox" :class="{'checkbox-checked': node.checked}">
        <span class="checkbox-inner"></span>
        <input type="checkbox" class="checkbox-input" :id="node.key" v-model="node.checked">
      </span>
    </label>
    <span class="node-content-label" @click.stop="onLabelClick">{{ node.label }}</span>
    <span v-if="node.store.showField" style="float:right;color:#ccc;padding:0 5px;">{{node.getDataField(node.store.showField)}}</span>
  </span>
</template>

<script>
export default {
  props: {
    node: {
      default () {
        return {}
      }
    }
  },
  watch: {
    'node.checked' (val) {
      this.$emit('on-check-change', val)
    }
  },
  methods: {
    onLabelClick () {
      this.$emit('on-label-click')
    }
  }
}
</script>
