<template>
  <div class="default-panel">
    <div>
      <p>请选择左边的冻存设备, 找到自己的样本盒</p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less">
.default-panel {
  padding-left: 10px;
}
.autoinput {
  margin-top: 10px;
  .label {
    font-size: 13px;
    padding-right: 5px;
  }
}
</style>
