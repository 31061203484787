<template>
  <div class="param-list">
    <div class="param-item" v-for="(item, index) in currentValue" :key="index">
      <span class="param-label">key:</span>
      <input class="param-val" :value="item.key" @change="onKeyChange(index, $event)">
      <span class="param-label">title:</span>
      <input class="param-val" :value="item.title" @change="onValueChange(index, $event)">
      <span class="ionic-icon ionic-icon-close-round" @click="remove(index)"></span>
    </div>
    <div class="param-add">
      <button type="button" @click.stop="addItem">添加</button>
    </div>
  </div>
</template>

<script>
import Emitter from '../../mixins/emitter'
export default {
  mixins: [Emitter],
  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    valtype: {
      type: String,
      default: 'array'  // 'object'
    }
  },
  data () {
    return {
      currentValue: this.mapObj2Array(this.value)
    }
  },
  watch: {
    value (val, oldVal) {
      if (val && JSON.stringify(oldVal) !== JSON.stringify(val)) {
        this.currentValue = this.mapObj2Array(val)
      }
    },
    currentValue (val) {
      let result = val
      if (this.valtype === 'object') {
        result = this.mapArray2Obj(val)
      }
      this.$emit('input', result)
      this.dispatch('FormItem', 'on-form-change', result)
      this.$emit('on-change', result)
    }
  },
  methods: {
    mapObj2Array (obj) {
      if (Array.isArray(obj)) return obj
      const ary = []
      Object.keys(obj || {}).forEach(prop => {
        ary.push({key: prop, title: obj[prop]})
      })
      return ary
    },
    mapArray2Obj (ary) {
      if (!Array.isArray(ary) && typeof ary === 'object') return ary
      const obj = {}
      ary.forEach(item => {
        if (item.key) {
          obj[item.key] = item.title
        }
      })
      return obj
    },
    onKeyChange (index, e) {
      let val = {key: e.target.value, title: this.currentValue[index] ? this.currentValue[index].title : ''}
      this.$set(this.currentValue, index, val)
    },
    onValueChange (index, e) {
      let val = {key: this.currentValue[index] ? this.currentValue[index].key : '', title: e.target.value}
      this.$set(this.currentValue, index, val)
    },
    remove (index) {
      this.currentValue.splice(index, 1)
    },
    addItem () {
      this.currentValue.push({key: '', title: ''})
    }
  }
}
</script>

<style lang="less">
.param-item {
  height: 28px;
  line-height: 28px;
  .param-label {
    width: 10%
  }
  .param-val {
    width: 30%;
    height: 100%;
    padding-left: 7px;
    outline: 0;
    border: 0;
    border-bottom: 1px solid #888;
  }
  .ionic-icon {
    margin: 0 5px;
    cursor: pointer;
    &:hover {
      color: #3399ff;
    }
  }
}
.param-add {
  height: 28px;
  line-height: 28px;
  button {
    cursor: pointer;
    font-size: 12px;
    outline: 0;
    padding: 1px 7px;
    background: #fff;
    border: 1px solid #888;
    border-radius: 2px;
  }
}
</style>
