<template>
  <div class="field-tree" :style="{overflow: 'auto', height: pageHeight + 'px'}">
    <field-tree-node
      :node="rootNode"
      :props="props"
      :key="getKeyField(rootNode)">
    </field-tree-node>
  </div>
</template>

<script>
import TreeStore from './model/fieldTree-store'
import FieldTreeNode from './field-tree-node'
import mixin from './mixin'

export default {
  components: {FieldTreeNode},
  mixins: [mixin],
  props: {
    title: String,
    value: Array,
    data: Array,
    modelId: String,
    props: {
      default () {
        return {
          fields: 'fields',
          items: 'items',
          group: 'group',
          children: 'children',
          subChildren: 'subChildren',
          label: 'title',
          value: 'value',
          type: 'type',
          key: 'key',
          icon: 'icon'
        }
      }
    },
    indent: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      $tree: null,
      treeStore: null,
      rootNode: null
    }
  },
  computed: {
    pageHeight () {
      return Math.min(this.$store.getters.getWinHeight - 200, this.height)
    }
  },
  watch: {
    data (val) {
      this.treeStore = new TreeStore({
        key: this.modelId,
        title: this.title,
        data: val,
        props: this.props
      })
      this.rootNode = this.treeStore.rootNode
      this.updateCheckStatus(this.value)
    },
    value (val) {
      this.updateCheckStatus(val)
    }
  },
  created () {
    this.$tree = this
    this.treeStore = new TreeStore({
      key: this.modelId,
      title: this.title,
      data: this.data,
      props: this.props
    })
    this.rootNode = this.treeStore.rootNode
    this.updateCheckStatus(this.value)
    this.$on('on-node-check-change', (node, status) => {
      if (node.type === 'root' && !status) {
        this.treeStore.unCheckAllNodes()
      } else if (node.type === 'root' && status) {
        this.treeStore.checkAllNodes()
      }
    })
  },
  methods: {
    getFieldNavs () {
      return this.treeStore.getFieldNavs()
    },
    updateCheckStatus (val) {
      if (this.treeStore) {
        this.treeStore.unCheckAllNodes()
      }
      if (Array.isArray(val) && this.treeStore) {
        val.forEach(item => {
          let node = this.treeStore.getNodeByKey(item.key)
          if (node) {
            node.check(true)
          }
        })
      }
    }
  }
}
</script>

<style lang="css">
</style>
