<template>
  <modal v-model="showModal" title="添加 datasource" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="datasourceForm" :model="datasource" :rules="datasourceRule" label-width="100px" item-width="250px">
      <form-item label="名称" prop="name">
        <y-input v-model="datasource.name"></y-input>
      </form-item>
      <form-item label="数据源类型" prop="type">
        <y-select v-model="datasource.type" :data="sqlTypeList"></y-select>
      </form-item>
      <form-item label="followupModel" prop="followupModel">
        <y-input v-model="datasource.followupModel"></y-input>
      </form-item>
      <form-item label="crfId" prop="crfId">
        <y-input v-model="datasource.crfId"></y-input>
      </form-item>
      <form-item label="subjectId" prop="subjectId">
        <y-input v-model="datasource.subjectId"></y-input>
      </form-item>
      <form-item label="doctorId" prop="doctorId">
        <y-input v-model="datasource.doctorId"></y-input>
      </form-item>
      <form-item label="groupCode">
        <y-input v-model="datasource.groupCode"></y-input>
      </form-item>
      <form-item label="dateRange">
        <y-input v-model="datasource.dateRange"></y-input>
      </form-item>
      <form-item label="数据源URL" prop="url">
        <y-input type="textarea" v-model="datasource.url"></y-input>
      </form-item>
      <form-item label="数据源描述">
        <y-input v-model="datasource.desc"></y-input>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
export default {
  components: { Modal, YForm, FormItem, YSelect, YInput },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: Object
  },
  data () {
    return {
      showModal: this.show,
      datasource: this.value || {
        name: '',
        type: '',
        followupModel: '',
        crfId: '',
        subjectId: '',
        doctorId: '',
        groupCode: '',
        dateRange: 0,
        url: '',
        desc: ''
      },
      sqlTypeList: ['webservice', 'mysql', 'sqlserver', 'postgres', 'mongodb', 'oracle', 'view'],
      datasourceRule: {
        name: [{required: true, message: '不能为空', trigger: 'blur'}],
        type: [{required: true, message: '不能为空', trigger: 'change'}],
        url: [{required: true, message: '不能为空', trigger: 'change'}]
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    value (val) {
      if (val) {
        this.datasource = val
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.datasourceForm.validate()
      if (!this.$refs.datasourceForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.mergeValues(this.datasource))
    },
    mergeValues (datasource) {
      let data = Object.assign({}, datasource)
      if (data && data.dateRange) {
        data.dateRange = Number(data.dateRange)
      }
      return data
    }
  }
}
</script>

<style lang="css">
</style>
