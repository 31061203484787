import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  methods: {
    onProjectAdd () {
      this.showAddWin = true
    },
    onAddProject (data) {
      this.addProject(data, (project) => {
        if (project) {
          this.projectList.unshift(project)
        }
      })
    },

    edit (_idx) {
      this.currentDataIdx = _idx
      this.updateProjectId()
      let project = this.$refs.table.getDataById(_idx)
      this.editProject = project
      this.showEditWin = true
    },
    onEidtProject (data) {
      this.updateProject(data, () => {
        this.$refs.table.setDataById(this.currentDataIdx, data)
      })
    },
    export (_idx) {
      this.currentDataIdx = _idx
      this.updateProjectId()
      let project = this.$refs.table.getDataById(_idx)
      this.$http.post(`${this.httpRoot}/db/backup/export`, {
        projectCode: project.code
      }, authenticate({responseType: 'blob', timeout: 180000}))
      .then(res => {
        return res.blob()
      })
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = project.code + '-' + new Date().format('yyyyMMddhhmmss') + '.tar.gz'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onProjectImport () {
      this.showImportWin = true
    },
    onImportProject () {
      this.updateLoadStatus('load-success', '上传项目文件成功')
    },
    onProjectDelete () {
      const projects = this.$refs.table.getSelectedData() || []
      if (!projects || !projects.length) {
        this.$Message.warning('请选择项目')
        return
      }
      const ids = projects.map(item => { return item.id })
      const idxs = projects.map(item => { return item._idx })

      this.$Modal.confirm({
        title: '删除项目',
        content: '<p>您确定删除选定的项目？</p>',
        onOk: () => {
          this.deleteProject(ids, () => {
            idxs.forEach(_idx => {
              this.$refs.table.removeDataById(_idx)
            })
          })
        }
      })
    },
    delete (_idx) {
      this.currentDataIdx = _idx
      let project = this.$refs.table.getDataById(_idx)
      let ids = [project.id]
      this.$Modal.confirm({
        title: '删除项目',
        content: '<p>您确定删除选定的项目？</p>',
        onOk: () => {
          this.deleteProject(ids, () => {
            this.$refs.table.removeDataById(_idx)
          })
        }
      })
    },
    addProject (data, callback) {
      this.updateLoadStatus('load-start', '提交中...')
      this.$http.post(`${this.httpRoot}/admin/project/add`, {
        project: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', '添加患者成功')
          if (typeof callback === 'function') {
            callback(res.project)
          }
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '添加患者失败')
      })
    },
    updateProject (data, callback) {
      let cData = JSON.parse(JSON.stringify(data))
      delete cData._idx
      delete cData._index
      this.updateLoadStatus('load-start', '提交中...')
      this.$http.post(`${this.httpRoot}/admin/project/update`, {
        project: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', '更新项目成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '更新项目失败')
      })
    },
    deleteProject (data, callback) {
      this.$http.post(`${this.httpRoot}/admin/project/delete`, {
        ids: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', '删除项目成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '删除项目失败')
      })
    },
    deleteLog (_idx) {
      this.currentDataIdx = _idx
      this.updateProjectId()
      let project = this.$refs.table.getDataById(_idx)
      this.$Modal.confirm({
        title: '清除项目日志',
        content: '<div><p>您确定清除<b style="color:red">' + project.name + '</b>的日志？</p><p><input id="clearLog" style="font-size:12px;" placeholder="输入项目编码"/></p></div>',
        onOk: () => {
          let cinput = document.querySelector('#clearLog')
          if (!cinput || cinput.value !== project.code) {
            this.$Message.error('请输入正确的项目编码', 2)
            return
          }
          this.$http.post(`${this.httpRoot}/auth/operationLog/delete`, {
            projectId: project.code
          }, authenticate())
          .then(res => res.json())
          .then(res => {
            if (res.status === 'success') {
              this.updateLoadStatus('load-success', '清除项目日志成功')
            } else {
              this.updateLoadStatus('load-faild', res.statusText)
            }
          })
          .catch(err => {
            catchExpired(err, this)
            this.updateLoadStatus('load-faild', '清除项目日志失败')
          })
        }
      })
    }
  }
}
