import { getPropertyFromData } from './util'

let nodeIdSeed = new Date().getTime()

export default class Node {
  constructor (options) {
    this.id = nodeIdSeed++

    this.data = null
    this.parent = null
    this.store = null
    this.level = 0
    this.type = ''
    this.expanded = true
    this.focused = false
    this.isLeaf = true
    this.childNodes = []

    for (let name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name]
      }
    }

    if (this.parent) {
      this.level = this.parent.level + 1
    }
    this._key = getPropertyFromData(this, 'key')

    const store = this.store
    store.registerNode(this)
    store.currentNode = this

    if (this.data && this.hasChild()) {
      this.setChild(this.data)
    }
  }

  get label () {
    return getPropertyFromData(this, 'label')
  }

  get icon () {
    return getPropertyFromData(this, 'icon')
  }

  get key () {
    return this._key || this.id
  }

  hasChild () {
    return this.data &&
        ((this.data.fields && this.data.fields.length) ||
          (this.data.items && this.data.items.length) ||
          (this.data.group && this.data.group.length) ||
          (this.data.children && this.data.children.length) ||
          (this.data.subChildren && this.data.subChildren.length))
  }

  setChild () {
    let children
    let subChildren
    let fields
    let items
    let group

    if (this.data) {
      fields = getPropertyFromData(this, 'fields') || []
      items = getPropertyFromData(this, 'items') || []
      group = getPropertyFromData(this, 'group') || []
      children = getPropertyFromData(this, 'children') || []
      subChildren = getPropertyFromData(this, 'subChildren') || []
    }

    for (let i = 0, len = fields.length; i < len; i++) {
      this.insertChild(fields[i], 'field')
    }

    for (let i = 0, len = items.length; i < len; i++) {
      this.insertChild(items[i], items[i].type === 'group' ? 'group' : 'item')
    }

    for (let i = 0, len = group.length; i < len; i++) {
      this.insertChild(group[i], 'gitem')
    }

    for (let i = 0, len = children.length; i < len; i++) {
      this.insertChild(children[i], 'child')
    }

    for (let i = 0, len = subChildren.length; i < len; i++) {
      this.insertChild(subChildren[i], 'subChild')
    }
  }

  insertChild (data, type, index) {
    if (!data) throw new Error('insertChild error: child is required.')

    const child = new Node({
      type,
      data,
      parent: this,
      store: this.store
    })

    if (index > -1) {
      this.childNodes.splice(index, 0, child)
    } else {
      this.childNodes.push(child)
    }

    this.updateLeafState()
  }

  removeChild (child) {
    this.removeChildData(child)

    const index = this.childNodes.indexOf(child)

    if (index > -1) {
      this.store && this.store.deregisterNode(child)
      child.parent = null
      this.childNodes.splice(index, 1)
    }

    this.updateLeafState()
  }

  removeChildData (child) {
    let attr = this.mapTypeSet(child.type)

    if (this.data[attr]) {
      const dataIndex = this.data[attr].indexOf(child.data)
      if (dataIndex > -1) {
        this.data[attr].splice(dataIndex, 1)
      }
      if (!this.data[attr].length) {
        delete this.data[attr]
      }
    }
  }

  insertChildData (data, type, index) {
    let _data = JSON.parse(JSON.stringify(data))
    let attr = this.mapTypeSet(type)

    this.data[attr] = this.data[attr] || []

    if (index > -1) {
      this.data[attr].splice(index, 0, _data)
    } else {
      this.data[attr].push(_data)
    }

    this.insertChild(_data, type, index)
  }

  mapTypeSet (type) {
    const types = {
      'field': 'fields',
      'item': 'items',
      'group': 'items',
      'gitem': 'group',
      'child': 'children',
      'subChild': 'subChildren'
    }
    if (!types[type]) throw new Error('insertChildData error: type is not exist.')
    return types[type]
  }

  delete () {
    if (this.parent) {
      this.parent.removeChild(this)
    }
  }

  slidUp () {
    const parent = this.parent
    if (parent && this.type === 'field' && parent.type === 'root') {
      const index = parent.childNodes.indexOf(this)
      if (index > 0) {
        const fields = parent.data.fields
        const field = fields[index - 1]
        fields.splice(index - 1, 1)
        fields.splice(index, 0, field)
        //
        const childs = parent.childNodes
        const child = childs[index - 1]
        childs.splice(index - 1, 1)
        childs.splice(index, 0, child)
      }
    } else if (parent && (this.type === 'item' || this.type === 'group')) {
      const index = parent.childNodes.indexOf(this)
      if (index > 0) {
        const items = parent.data.items
        const item = items[index - 1]
        items.splice(index - 1, 1)
        items.splice(index, 0, item)
        //
        const childs = parent.childNodes
        const child = childs[index - 1]
        childs.splice(index - 1, 1)
        childs.splice(index, 0, child)
      }
    } else if (parent && this.type === 'gitem') {
      const index = parent.childNodes.indexOf(this)
      if (index > 0) {
        const gitems = parent.data.group
        const gitem = gitems[index - 1]
        gitems.splice(index - 1, 1)
        gitems.splice(index, 0, gitem)
        //
        const childs = parent.childNodes
        const child = childs[index - 1]
        childs.splice(index - 1, 1)
        childs.splice(index, 0, child)
      }
    }
  }

  slidDown () {
    const parent = this.parent
    if (parent && this.type === 'field' && parent.type === 'root') {
      const index = parent.childNodes.indexOf(this)
      if (index > -1 && index < parent.childNodes.length - 1) {
        const fields = parent.data.fields
        const field = fields[index]
        fields.splice(index, 1)
        fields.splice(index + 1, 0, field)
        //
        const childs = parent.childNodes
        const child = childs[index]
        childs.splice(index, 1)
        childs.splice(index + 1, 0, child)
      }
    } else if (parent && (this.type === 'item' || this.type === 'group')) {
      const index = parent.childNodes.indexOf(this)
      if (index > -1 && index < parent.childNodes.length - 1) {
        const items = parent.data.items
        const item = items[index]
        items.splice(index, 1)
        items.splice(index + 1, 0, item)
        //
        const childs = parent.childNodes
        const child = childs[index]
        childs.splice(index, 1)
        childs.splice(index + 1, 0, child)
      }
    } else if (parent && this.type === 'gitem') {
      const index = parent.childNodes.indexOf(this)
      if (index > -1 && index < parent.childNodes.length - 1) {
        const gitems = parent.data.group
        const gitem = gitems[index]
        gitems.splice(index, 1)
        gitems.splice(index + 1, 0, gitem)
        //
        const childs = parent.childNodes
        const child = childs[index]
        childs.splice(index, 1)
        childs.splice(index + 1, 0, child)
      }
    }
  }

  addTriggeredKey (key) {
    const ary = this.data.triggeredItems = this.data.triggeredItems || []
    if (!(ary.indexOf(key) > -1)) {
      ary.push(key)
    }
  }

  updateLeafState () {
    const childNodes = this.childNodes
    this.isLeaf = !childNodes || childNodes.length === 0
  }

  toggleExpand () {
    this.expanded = !this.expanded
  }

  expand () {
    if (!this.expanded) this.expanded = true
  }

  focus (status, deep) {
    if (!deep) {
      this.store.focus(false, true)
    }
    if (this.focused && !status && deep) {
      this.focused = false
    }
    this.childNodes.forEach(child => {
      child.focus(false, true)
    })
    if (!this.focused && status && !deep) {
      this.focused = true
    }
  }

  getNewChildKey (gitemKeys) {
    let key = ''

    if (!this.hasChild()) {
      if (this.type === 'root' || this.type === 'field') {
        let keyAry = this.key.split('_')
        if (keyAry.length > 1) {
          key = keyAry[1]
        } else if (keyAry.length === 1) {
          key = keyAry[0]
        }
        key = this.type === 'root'
              ? 'm_' + key + 'x' + '001'
              : 'k_' + key + 'x' + '001'
      } else if (this.type === 'group') {
        key = this.parent.getNewChildKey()
      } else {
        key = this.key + 'x' + '001'
      }
    } else {
      if (this.type === 'group') {
        key = this.parent.getNewChildKey()
      } else {
        const childNodes = this.childNodes
        const keys = []
        childNodes.forEach(child => {
          if (child.type === 'group') {
            let group = child.childNodes
            group.forEach(gitem => {
              keys.push(gitem.key)
            })
          } else {
            keys.push(child.key)
          }
        })
        // for group.parent === 'field'
        if (this.type === 'field' && gitemKeys) {
          gitemKeys.forEach((key) => { keys.push(key) })
        }
        let keyPrefix = ''
        const indexs = []
        keys.forEach(key => {
          let xIndex = key.lastIndexOf('x')
          keyPrefix = key.slice(0, xIndex)
          indexs.push(parseInt(key.slice(xIndex + 1, key.length)))
        })
        if (!keys.length) {
          keyPrefix = this.key
          indexs.push(0)
        }
        let nextIndex = Math.max.apply(null, indexs) + 1
        let newIndex = nextIndex < 10
        ? '00' + nextIndex
        : nextIndex < 100
        ? '0' + nextIndex
        : '' + nextIndex
        key = keyPrefix + 'x' + newIndex
      }
    }
    return key
  }

  copyData (parentKey, gitemKey) {
    const data = JSON.parse(JSON.stringify(this.data))
    if (this.type === 'field') {
      // update field fid
      data.fid = 'fid_' + new Date().getTime().toString()
      //
      this.updateDataKey(data, parentKey)
      // update items
      if (data.items) delete data.items
      this.childNodes.forEach(child => {
        data.items = data.items || []
        data.items.push(child.copyData(data.key))
      })
    } else if (this.type === 'item') {
      this.updateDataKey(data, parentKey)
      // update children or subChildren
      if (data.children) delete data.children
      if (data.subChildren) delete data.subChildren
      this.childNodes.forEach(child => {
        if (child.type === 'child') {
          data.children = data.children || []
          data.children.push(child.copyData(data.key))
        } else if (child.type === 'subChild') {
          data.subChildren = data.subChildren || []
          data.subChildren.push(child.copyData(data.key))
        }
      })
    } else if (this.type === 'gitem') {
      // direct copy group, set copyed gitem's key, because goup may have more than one gitem
      if (gitemKey) {
        data.key = gitemKey
      } else {
        this.updateDataKey(data, parentKey)
      }
      // update children or subChildren
      if (data.children) delete data.children
      if (data.subChildren) delete data.subChildren
      this.childNodes.forEach(child => {
        if (child.type === 'child') {
          data.children = data.children || []
          data.children.push(child.copyData(data.key))
        } else if (child.type === 'subChild') {
          data.subChildren = data.subChildren || []
          data.subChildren.push(child.copyData(data.key))
        }
      })
    } else if (this.type === 'group') {
      // update key // no data key for group
      // update group
      let keys = []
      if (data.group) delete data.group
      this.childNodes.forEach(child => {
        data.group = data.group || []
        // for group copy, get new gitem's key, because goup does not have key and may have more than one gitem
        let newGitemKey
        if (!parentKey) {
          newGitemKey = this.parent.getNewChildKey(keys)
          keys.push(newGitemKey)
        }
        data.group.push(child.copyData(parentKey, newGitemKey))
      })
    } else if (this.type === 'child' || this.type === 'subChild') {
      this.updateDataKey(data, parentKey)
    }

    return data
  }

  updateDataKey (data, parentKey) {
    // update key
    if (parentKey) {
      // copyData from this parent node
      const newKeyPrefix = parentKey.slice(2, parentKey.length)
      const sourceKeyPrefix = data.key.slice(2, parentKey.length)
      data.key = data.key.replace(sourceKeyPrefix, newKeyPrefix)
    } else {
      // copyData from this node
      data.key = this.parent.getNewChildKey()
    }
  }

  copyNodeAndInsertAfter () {
    const data = this.copyData()
    const index = this.parent.childNodes.indexOf(this)
    if (index > -1 && this.parent) {
      this.parent.insertChildData(data, this.type, index + 1)
    }
  }
}
