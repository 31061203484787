<template>
  <div class="panel-collapse">
    <div class="panel__title">
      <span>{{title}}</span>
      <div class="expand-menu" v-show="show"
        :class="{expanded: expanded}" @click.stop="onClick">
        {{expanded ? '收起' : '展开'}}
        <icon type="ios-arrow-down"></icon>
      </div>
    </div>
    <div class="panel__content" :style="{height: innerHeight}" ref="panel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Icon from '../icon/icon'
export default {
  components: { Icon },
  props: {
    title: String,
    height: String,
    value: Boolean
  },
  data () {
    return {
      innerHeight: '',
      expanded: this.value,
      show: false
    }
  },
  watch: {
    height (val) {
      if (val) {
        this.innerHeight = val
      }
    },
    value (val) {
      this.expanded = val
    },
    expanded (val) {
      if (val) {
        console.log('this.$refs.panel.scrollHeight:' + this.$refs.panel.scrollHeight)
        this.innerHeight = this.$refs.panel.scrollHeight + 'px'
      } else {
        this.innerHeight = this.height + 'px'
      }
      this.updateShow()
      this.$emit('input', val)
    }
  },
  created () {
    if (this.height && !this.value) {
      this.innerHeight = parseInt(this.height) + 'px'
    }
  },
  mounted () {
    this.updateShow()
  },
  updated () {
    this.updateShow()
  },
  methods: {
    onClick () {
      this.expanded = !this.expanded
    },
    updateShow () {
      if (this.$refs.panel && this.$refs.panel.scrollHeight > parseInt(this.innerHeight) * 1.5) {
        this.show = true
      }
    }
  }
}
</script>

<style lang="less">
@import "../../../assets/ivu/custom";
.panel-collapse {
  .panel__title {
    background-color: #f7f7f7;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    padding-left: 5px;
    position: relative;
    color: @text-color;
  }
  .expand-menu {
    transition: all 0.3s;
    position: absolute;
    padding-left: 5px;
    padding-right: 5px;
    top: 0;
    right: 3px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: @primary-color;
    }
    &.expanded i {
      transition: all 0.3s;
      transform: rotate(180deg);
    }
  }
  .panel__content {
    overflow: hidden;
    transition: height 0.3s;
  }
}
</style>
