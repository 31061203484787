<template>
  <div class="callFunc-list">
    <div class="callFunc-item" v-for="(item, index) in currentValue" :key="index">
      <div class="title callFunc-label" :style="{width: labelWidth}">callFunc.</div>
      <div class="callFunc-row">
        <div class="callFunc methodName">
          <div class="callFunc-label" :style="{width: labelWidth}">methodName:</div>
          <y-input v-model="item.methodName" :style="{width: itemWidth}" @on-input-change="onChange"></y-input>
        </div>
        <div class="callFunc retType">
          <div class="callFunc-label" :style="{width: labelWidth}">retType:</div>
          <y-select v-model="item.retType" :data="retTypeList" :style="{width: itemWidth}" @on-select-change="onChange"></y-select>
        </div>
      </div>
      <div class="callFunc params">
        <div class="callFunc-label" :style="{width: labelWidth}">params:</div>
        <namevaltesttype-list v-model="item.params" @on-change="onChange"></namevaltesttype-list>
      </div>
      <div class="callFunc filters">
        <div class="callFunc-label" :style="{width: labelWidth}">filters:</div>
        <filter-list v-model="item.filters" valtype="array" @on-change="onChange"></filter-list>
      </div>
      <div class="callFunc-remove">
        <div :style="{width: labelWidth, height: '30px'}"></div>
        <span class="ionic-icon ionic-icon-close-round" @click="remove(index)">删除callFunc</span>
        <span class="btn" @click="onJsonPreview(index)">JSON预览</span>
      </div>
    </div>
    <div class="callFunc-add">
      <div :style="{width: labelWidth, height: '30px'}"></div>
      <button type="button" @click.stop="addItem"><span class="ionic-icon ionic-icon-plus"></span>添加callFunc</button>
    </div>
  </div>
</template>

<script>
import YInput from '../../common/input/input'
import YSelect from '../../common/select/select'
import NamevaltesttypeList from './namevaltesttype-list'
import FilterList from './filter-list.vue'
import Emitter from '../../mixins/emitter'
export default {
  components: {YInput, NamevaltesttypeList, FilterList, YSelect},
  mixins: [Emitter],
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    labelWidth: String,
    itemWidth: String
  },
  data () {
    return {
      currentValue: this.value || [],
      retTypeList: ['string', 'map', 'slice']
    }
  },
  watch: {
    value (val, oldVal) {
      if (val && JSON.stringify(this.currentValue) !== JSON.stringify(val)) {
        this.currentValue = val
      }
    },
    currentValue (val) {
      // current item add remove
      console.log('on-change', 'val')
      this.$emit('input', val)
      this.dispatch('FormItem', 'on-form-change', val)
      this.$emit('on-change', val)
    }
  },
  methods: {
    onChange (val) {
      // current 内部属性修改
      // console.log('callFunc change ', val)
      const value = this.currentValue
      this.$emit('input', value)
      this.dispatch('FormItem', 'on-form-change', value)
      this.$emit('on-change', value)
    },
    remove (index) {
      this.currentValue.splice(index, 1)
    },
    addItem () {
      this.$emit('on-callfunc-add')
      // this.currentValue.push({methodName: '', params: [], filters: []})
    },
    onJsonPreview (index) {
      // 根据 index 取 callFunc list, index下标 0 开始
      const callFunc = this.currentValue.slice(0, index + 1)
      this.$emit('on-json-preview', callFunc)
    }
  }
}
</script>

<style lang="less">
.callFunc-list {
  border-radius: 5px;
  margin: 0 15px 24px 15px;
}
.callFunc-item {
  border: 1px dashed rgb(157, 167, 255);
  border-radius: 5px;
  margin: 10px 15px 0 15px;
  .title {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
  }
  .callFunc-row {
    display: flex;
    align-items: center;
  }
  .methodName, .retType {
    display: flex;
    width: 44%;
    font-size: 13px;
    margin-bottom: 14px;
  }
  .params, .filters {
    display: flex;
    font-size: 13px;
    margin-bottom: 4px;
  }
  .callFunc-label {
    width: 10%;
    height: 28px;
    line-height: 28px;
    padding-right: 12px;
    text-align: right;
  }
}
.callFunc-remove {
  height: 28px;
  line-height: 28px;
  display: flex;
  font-size: 12px;
  margin-bottom: 14px;
  &:hover {
    cursor: pointer;
    color: #3399ff;
  }
  .btn {
    padding: 4px 12px;
    margin-left: 10px;
  }
}
.callFunc-add {
  margin: 5px 0 15px 0;
  display: flex;
  font-size: 12px;
  .ionic-icon {
    margin: 0 5px;
  }
  button {
    cursor: pointer;
    font-size: 12px;
    outline: 0;
    padding: 1px 7px;
    background: #fff;
    border: 1px solid #888;
    border-radius: 2px;
  }
}
</style>
