<template>
  <modal v-model="showModal" title="预览结果" :width="width+50" footerHide="true">
    <div class="ds-table-preview">
      <data-list :columns="fields" :items="data" :width="width"></data-list>
    </div>
  </modal>
</template>
<script>
import Modal from '../../common/modal/modal.vue'
import DataList from './data-list'
export default {
  components: {Modal, DataList},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default () { return [] }
    },
    data: [Object, String]
  },
  data () {
    return {
      showModal: this.show,
      width: 1000
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    }
  }
}
</script>
<style lang="less">
.ds-table-preview {
  width: 1000px;
  height: 500px;
  overflow: auto;
}
</style>
