<template>
  <form class="login-form" method="post" @submit.prevent="onSubmit">
    <label for="user" class="form-container">
      <span class="icon"><span class="user-icon">&nbsp;</span></span>
      <input type="text" placeholder="用户名" v-model="user" @change="onUserChange">
      <span v-if="showUserError" class="error-tip">{{userError}}</span>
    </label>
    <label for="pass" class="form-container">
      <span class="icon"><span class="pass-icon">&nbsp;</span></span>
      <input type="password" placeholder="密码(6-14位字母+数字)" v-model="password" @change="onPassChange" @keyup.enter="onEnter">
      <span v-if="showPassError" class="error-tip">* 密码错误</span>
    </label>
    <label for="verifycode" class="form-container">
      <span class="icon"><span class="pass-icon">&nbsp;</span></span>
      <input class="verify" type="text" placeholder="验证码" v-model="verifycode" @change="onVerifyChange" @keyup.enter="onEnter">
      <span class="refresh ionic-icon ionic-icon-ios-refresh-empty" @click.stop="refreshVerifyCode"></span>
      <span class="verify-img" ref="verifyImg"><img :src="imgSrcInit" alt=""></span>
      <span v-if="showVerifyError" class="error-tip">验证码错误</span>
    </label>
    <label for="btn">
      <span class="label"></span>
      <input class="login-btn" type="button" value="登 录" :disabled="disabled" @click.stop="onEnter">
    </label>
  </form>
</template>

<script>
import { httpOptions, checkHttpStatus } from '../../utils/auth'
import { sha256Hash, hMACSHA256Base64UrlEscape } from '../../utils/sha256'
import base64EncodeUrlEscape from '../../utils/base64'
import { updateStorageItem } from '../../utils/cache'
export default {
  data () {
    return {
      user: '',
      password: '',
      verifycode: '',
      userError: '用户名不能为空',
      showUserError: false,
      showPassError: false,
      showVerifyError: false,
      disabled: true,
      failureText: '登录失败，请刷新页面重新登录',
      validate: {},
      imgSrcInit: this.verifyRoot + '/auth/verifycode?' + this.refreshRandom(),
      imgSrc: `${this.verifyRoot}/auth/verifycode`
    }
  },
  coputed: {
    loggedIn () {
      return this.$store.getters.getLoginState
    }
  },
  watch: {
    user (val) {
      if (val && val.length) {
        this.$set(this.validate, 'user', true)
        this.showUserError = false
      } else {
        this.$set(this.validate, 'user', false)
      }
      this.isValidate()
    },
    password (val) {
      if (/^[\w]{6,14}$/.test(val) && /^(?![^a-zA-Z]+$)(?!\D+$)/.test(val)) {
        this.$set(this.validate, 'pass', true)
        this.showPassError = false
      } else {
        this.$set(this.validate, 'pass', false)
      }
      this.isValidate()
    },
    verifycode (val) {
      if (/^[\da-zA-Z]{4}$/.test(val)) {
        this.$set(this.validate, 'verifycode', true)
        this.showVerifyError = false
      } else {
        this.$set(this.validate, 'verifycode', false)
      }
      this.isValidate()
    }
  },
  methods: {
    onSubmit () {
      return false
    },
    onEnter () {
      if (this.isValidate() && !this.loggedIn) {
        this.$emit('on-loading', true)
        this.login()
      }
    },
    onUserChange (e) {
      if (e.target.value === '') {
        this.userError = '用户名不能为空'
        this.showUserError = true
      }
    },
    onPassChange (e) {
      if (!(/^[\w]{6,14}$/.test(e.target.value) && /^(?![^a-zA-Z]+$)(?!\D+$)/.test(e.target.value))) {
        this.showPassError = true
      }
    },
    onVerifyChange (e) {
      if (!/^[\da-zA-Z]{4}$/.test(e.target.value)) {
        this.showVerifyError = true
      }
    },
    isValidate () {
      if (this.validate.user && this.validate.pass && this.validate.verifycode) {
        this.disabled = false
        return true
      }
      this.disabled = true
      return false
    },
    refreshVerifyCode () {
      this.$refs.verifyImg.innerHTML = '<img src="' + this.imgSrc + '?' + this.refreshRandom() + '" alt="">'
    },
    refreshRandom () {
      return new Date().getTime().toString().slice(9, 13)
    },
    login (e) {
      //
      const header = base64EncodeUrlEscape({ typ: 'JWT', alg: 'HS256' })
      const payload = base64EncodeUrlEscape({ user: this.user, pass: sha256Hash(this.password) })
      //
      this.$http.post(`${this.httpRoot}/auth/nonce`)
      .then(checkHttpStatus)
      .then(res => res.json())
      .then(res => {
        const signature = hMACSHA256Base64UrlEscape(res.nonce, [header, payload].join('.'))
        const jwtToken = [header, payload, signature].join('.')
        //
        Object.assign(httpOptions.headers, {'Authorization': `Digest ${jwtToken}`})
        this.$http.post(`${this.httpRoot}/auth/login`, {nonce: res.nonce, verifycode: this.verifycode}, httpOptions)
        .then(res => (res.json()))
        .then(res => {
          if (res.status === 'success' && res.token) {
            // login(res.token)
            this.$store.dispatch('login', res.token)
            if (res.auth) {
              updateStorageItem('auth', res.auth)
              if (res.auth.id && res.auth.role === 'assistant') {
                updateStorageItem('doctorId', '')
              } else if (res.auth.id) {
                updateStorageItem('doctorId', res.auth.id)
              }
              if (res.auth.name) {
                this.$store.dispatch('updateUserName', res.auth.name)
              }
            }
            this.$router.push({name: 'home'})
            // this.loadFileserverUrl() // 2023.4.3 不再调用fileRoot
          } else {
            if (res.statusText === '验证码错误') {
              this.verifycode = ''
              this.refreshVerifyCode()
            }
            this.$emit('on-loading', false)
            this.$emit('on-login-error', res.statusText)
          }
        })
        .catch(() => {
          this.$emit('on-loading', false)
          this.$emit('on-login-error', this.failureText)
        })
      })
      .catch(() => {
        this.$emit('on-loading', false)
        this.$emit('on-login-error', this.failureText)
      })
    }
  }
}
</script>

<style lang="less">
.login-form {
  font-size: 14px;
  label {
    display: block;
    height: 32px;
    line-height: 32px;
    margin-bottom: 28px;
  }
  .icon {
    height: 32px;
    line-height: 32px;
    width: 32px;
    display: inline-block;
    float: left;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      right: 0;
      top: 8px;
      bottom: 8px;
      border-right: 1px solid #ccc;
    }
  }
  .user-icon {
    display: block;
    margin-left: 8px;
    margin-top: 8px;
    width: 16px;
    height: 16px;
    background-image: url('../../assets/imgs/login/user.png');
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
  }
  .pass-icon {
    display: block;
    margin-left: 8px;
    margin-top: 8px;
    width: 16px;
    height: 16px;
    background-image: url('../../assets/imgs/login/password.png');
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
  }
  input {
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    width: 220px;
    outline: none;
    padding: 2px 8px;
    border: 0;
    &.verify {
      width: 140px;
    }
  }
  .login-btn {
    width: 280px;
    height: 32px;
    line-height: 30px;
    border-radius: 4px;
    color: #eee;
    font-size: 15px;
    background-color: #3a90e4;
    &:active {
      background-color: #298ae8;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.form-container {
  position: relative;
  background: #fff;
  border-radius: 4px;
  margin-right: 20px;
}
.refresh {
  position: absolute;
  top: 0;
  right: 120px;
  display: block;
  width: 28px;
  height: 32px;
  font-size: 22px;
  color: #666;
  text-align: center;
}
.verify-img {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 120px;
  height: 32px;
  img {
    display: block;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 100%;
    height: 100%;
  }
}
.error-tip {
  position: absolute;
  left: 100px;
  top: 32px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  color: #b92c33;
}
.login-btn[disabled] {
  color: rgba(250,250,250,0.6);
  background-color: tint(#3a90e4, 10%);
}
</style>
