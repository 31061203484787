<template>
  <div class="samplebox-panel">
    <div class="samplebox-container">
      <div class="samplebox-container-left">
        <div class="samplebox">
          <sample-box
            :width="samplebox.width"
            :height="samplebox.height"
            :boxItemWidth="50"
            :marginWidth="7"
            :boxTopLeftWidth="18"
            :data="samplebox.items"
            :actionType="actionType"
            :boxType="boxType"
            ref="samplebox">
          </sample-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SampleBox from '../../samplebox/sample-box'
import { authenticate, catchExpired } from '../../../../utils/auth'

export default {
  components: { SampleBox },
  props: {
    parentId: String,
    rootId: String,
    parentCode: String,
    actionType: {
      type: String,
      default: 'all'
    },
    leafboxType: {
      type: String,
      default: 'samplebox'
    },
    boxType: String,
    boxLabel: String
  },
  data () {
    return {
      samplebox: {
        width: 10,
        height: 10,
        items: []
      }
    }
  },
  watch: {
    parentId (val) {
      if (val) {
        this.loadSamplebox()
      }
    }
  },
  mounted () {
    this.loadSamplebox()
  },
  methods: {
    loadSamplebox () {
      this.$http.get(`${this.httpRoot}/container/tube/list`,
        authenticate({params: {parentId: this.parentId, boxType: this.leafboxType, projectId: this.$store.state.project.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.samplebox = res.samplebox || {}
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="less">
// other css are same as samplebox-panel-samplein
.mysamples-btn-box,
.mysamples-op-list {
  margin-top: 15px;
  position: relative;
  .title {
    background: #eee;
    height: 32px;
    line-height: 32px;
    margin-bottom: 5px;
    padding-left: 7px;
  }
}
.sample-box-action-filter {
  position: relative;
  cursor: pointer;
  color: #666;
  font-size: 12px;
  height: 19px;
  line-height: 19px;
  padding: 0 2px;
  border: 1px solid #888;
  border-left: 0;
  border-bottom: 1px dotted #888;
  &:hover {
    color: #47a3ff;
    ul {
      display: block;
      padding: 2px 0;
    }
  }
  ul {
    position: absolute;
    left: 1px;
    top: 18px;
    display: none;
    list-style: none;
    box-shadow: 1px 2px 2px rgba(50,50,50,.3);
    border-radius: 2px;
  }
  li {
    color: #888;
    display: inline-block;
    width: 100%;
    text-align: center;
    &.active {
      color: #47a3ff;
    }
    &:hover {
      background: #f3f3f3;
    }
  }
}
.samplebox {
  &:after {
    content: ".";
    height: 1px;
    display: block;
    visibility: hidden;
    clear: both;
  }
  .sample-box, .sample-box-action-filter {
    float: left;
  }
}
</style>
