import Node from './fieldTree-node'

export default class TreeStore {
  constructor (options) {
    this.key = ''
    this.title = ''
    this.data = null
    this.props = {}

    this.currentNode = null

    for (let option in options) {
      if (options.hasOwnProperty(option)) {
        this[option] = options[option]
      }
    }

    this.nodesMap = {}

    this.rootNode = new Node({
      type: 'root',
      data: {
        key: this.key || 'root',
        title: this.title,
        value: this.title,
        fields: JSON.parse(JSON.stringify(this.data))
      },
      store: this
    })
  }

  registerNode (node) {
    if (!node || !node.data) return
    if (node.key) this.nodesMap[node.key] = node
  }

  deregisterNode (node) {
    if (!node || !node.data) return
    delete this.nodesMap[node.key]
  }

  getNodeByKey (key) {
    return this.nodesMap[key]
  }

  hasNodeChecked () {
    let hasCheck = false
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if ((node.type === 'item' || node.type === 'gitem') && node.checked) {
        hasCheck = true
      }
    })
    return hasCheck
  }

  getFieldKeys () {
    const result = []
    const fieldNodes = this.rootNode.childNodes
    fieldNodes.forEach(node => {
      if (node.type === 'field') result.push(node.key)
    })
    return result
  }

  getItemKeys () {
    const result = []
    const fieldNodes = this.rootNode.childNodes
    fieldNodes.forEach(child => {
      let itemNodes = child.childNodes
      itemNodes.forEach(item => {
        if (item.type === 'item' && item.checked) {
          if (item.data.desktop) {
            result.push({type: 'item', key: item.key, desktop: item.data.desktop})
          } else {
            result.push({type: 'item', key: item.key})
          }
        } else if (item.type === 'group') {
          let gitemNodes = item.childNodes
          gitemNodes.forEach(gitem => {
            if (gitem.checked) {
              if (gitem.data.desktop) {
                result.push({type: 'gitem', key: gitem.key, desktop: gitem.data.desktop})
              } else {
                result.push({type: 'gitem', key: gitem.key})
              }
            }
          })
        }
      })
    })
    return result
  }

  getCheckedData () {
    const result = []
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if ((node.type === 'item' || node.type === 'gitem') && node.checked) {
        result.push({type: node.type, key: node.key})
      }
    })
    return result
  }

  checkAllNodes () {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.type !== 'root' && (node.type === 'item' || node.type === 'gitem')) {
        node.checked = true
      }
    })
  }

  unCheckAllNodes () {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.type !== 'root') {
        node.checked = false
      }
    })
  }
}
