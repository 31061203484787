export const copyContainers = function (containers = []) {
  const newContainers = []
  containers.forEach(item => {
    let newContainer = Object.assign({}, item, {
      parent: null,
      children: null,
      store: null,
      $container: null,
      loadFn: null
    })
    if (item._type === 'freezer') {
      if (item.selected) {
        if (item.shelfContainer && item.shelfContainer.length) {
          newContainer.children = copyContainers(item.shelfContainer)
        }
      } else {
        newContainer.loaded = false
      }
      delete newContainer.shelfContainer
    } else if (item._type === 'shelf') {
      if (item.selected) {
        if (item.layerContainer && item.layerContainer.length) {
          newContainer.children = copyContainers(item.layerContainer)
        }
      } else {
        newContainer.loaded = false
      }
      delete newContainer.layerContainer
    } else if (item._type === 'layer') {
      if (item.selected) {
        if (item.sampleboxContainer && item.sampleboxContainer.length) {
          newContainer.children = copyContainers(item.sampleboxContainer)
        }
      } else {
        newContainer.loaded = false
      }
      delete newContainer.sampleboxContainer
    } else if (item._type === 'samplebox' && item.exboxType === 'strawbox') {
      if (item.selected) {
        if (item.strawboxContainer && item.strawboxContainer.length) {
          newContainer.children = copyContainers(item.strawboxContainer)
        }
      } else {
        newContainer.loaded = false
      }
      delete newContainer.strawboxContainer
    }
    newContainers.push(newContainer)
  })
  return newContainers
}

export const copyContainer = function (container = {}) {
  let newContainer = {
    id: container.id || '',
    type: container.type || '',
    label: container.label || '',
    _type: container._type || '',
    exboxType: container.exboxType || '', // 多层麦管样本盒下的样本盒
    rootId: container.rootId || '',
    siteCode: container.siteCode || ''
  }
  return newContainer
}
