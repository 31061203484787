<template>
  <modal class="user-edit-win" v-model="showModal" :title="title" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="userForm" :model="user" :rules="userRule" label-width="100px" item-width="250px">
      <form-item label="用户名称" prop="name">
        <y-input v-model="user.name"></y-input>
      </form-item>
      <form-item label="用户角色" prop="role">
        <y-select
          v-model="user.role"
          :data="roleList"></y-select>
      </form-item>
      <form-item label="登录密码" prop="pass">
        <y-input type="password" v-model="user.pass" placeholder="密码(6-14位字母+数字)"></y-input>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import { sha256Hash } from '../../../utils/sha256'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: { Modal, YForm, FormItem, YInput, YSelect },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    projectId: String,
    title: String,
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      user: this.value || {},
      userRule: {
        name: [{required: true, message: '不能为空', trigger: 'blur'}],
        role: [{required: true, message: '不能为空', trigger: 'blur'}],
        pass: [{required: true, message: '不能为空', trigger: 'blur'}]
      },
      roleList: []
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      } else {
        this.loadRoleList()
      }
    },
    value (val) {
      if (val) {
        this.user = val
      }
    }
  },
  mounted () {
    this.loadRoleList()
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.userForm.validate()
      if (!this.$refs.userForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.encyptUserPass(this.user))
    },
    encyptUserPass (user) {
      if (user && user.pass) {
        user.pass = sha256Hash(user.pass)
      }
      return user
    },
    loadRoleList () {
      this.$http.get(`${this.httpRoot}/admin/role/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.roleList = res.roleList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="less">
</style>
