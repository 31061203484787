<template>
  <modal v-model="showModal" title="添加 modelId 选项" width="450">
    <div class="check-item-list">
      <a class="check-title" v-if="title">{{title}}</a>
      <template v-for="(item, idx) in tables">
        <label class="check-item" :class="{'inline': inline}" :style="lineStyle" :for="'_id_'+idx+idRand" :key="idx">
          <span class="check-radio-outer" :style="absoluteTop" :class="{checked: item.key === currentKey}">
            <span class="check-radio-inner"></span>
            <input class="check-radio" type="radio" :id="'_id_'+idx+idRand" :value="item.key" v-model="currentKey">
          </span>
          <span class="check-label" :style="lineStyle">{{item.label || item.title}}</span>
        </label>
      </template>
    </div>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
export default {
  components: {Modal},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: String,
    tables: Array,
    inline: {
      type: Boolean,
      default: false
    },
    lineHeight: {
      type: Number,
      default: 32
    }
  },
  data () {
    return {
      showModal: this.show,
      currentKey: '',
      idRand: Math.random().toString().slice(2, 6)
    }
  },
  computed: {
    lineStyle () {
      return {
        height: this.lineHeight + 'px',
        lineHeight: this.lineHeight + 'px'
      }
    },
    absoluteTop () {
      return {
        top: ((this.lineHeight - 20) / 2 + 1) + 'px'
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
        this.currentKey = ''
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    currentKey (val) {
      if (val) {
        this.emitChange(val)
      }
    }
  },
  methods: {
    emitChange (key) {
      let title = ''
      this.tables.forEach(crfTable => {
        if (crfTable.key === key) {
          title = crfTable.title
        }
      })
      this.$emit('on-set-modelId-click', key, title)
    }
  }
}
</script>

<style lang="css">
</style>
