<template>
  <div>
    <div class="login-page" :style="{height: pageHeight + 'px'}">
      <div :class="{'login-page-inner': mounted}">
        <div class="login-error" v-show="showLoginError">{{errorText}}</div>
        <div :style="{marginTop: formTop + 'px'}">
          <div class="login-title">样本库信息管理系统</div>
          <div class="login-panel">
            <login-form @on-loading="onLoginLoading" @on-login-error="onLoginError"></login-form>
          </div>
        </div>
      </div>
    </div>
    <div class="login-loading" v-show="showLoading">
      <loading v-model="showLoading" icon="load-c" fix transparent="rgba(222,222,222,0.6)">登录中...</loading>
    </div>
  </div>
</template>

<script>
import LoginForm from './login-form'
import Loading from '../common/loading/loading'
export default {
  components: { LoginForm, Loading },
  data () {
    return {
      mounted: false,
      showLoading: false,
      showLoginError: false,
      errorText: '服务异常，登录失败！'
    }
  },
  computed: {
    pageHeight () {
      return this.$store.getters.getWinHeight
    },
    formTop () {
      return Math.max((this.$store.getters.getWinHeight - 320) / 2, 0)
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.$store.dispatch('logout')
    })
  },
  mounted () {
    this.mounted = true
  },
  methods: {
    onLoginLoading (status) {
      this.showLoading = status
    },
    onLoginError (errorText) {
      this.errorText = errorText
      this.showLoginError = true
    }
  }
}
</script>

<style lang="less">
.login-page {
  height: 100%;
  overflow: hidden;
  background-image: url('../../assets/imgs/login/login-bg-02.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.login-page-inner {
  height: 100%;
  overflow: hidden;
  background-image: url('../../assets/imgs/login/login-bg-01.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.login-title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}
.login-panel {
  width: 320px;
  margin: 0 auto;
  background: rgba(250,250,250,0.5);
  border-radius: 8px;
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 10px;
}
.login-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.login-error {
  position: absolute;
  top: 10%;
  left: 45%;
  color: #ff020f;
}
</style>
