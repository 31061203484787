<template>
  <modal v-model="showModal" title="新建冻存盒" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm" :styles="{top: '60px'}">
    <y-form ref="sampleboxForm" :model="samplebox" :rules="sampleboxRule" label-width="100px" item-width="250px">
      <form-item label="盒子编号" prop="sampleboxNum">
        <input-number v-model="samplebox.sampleboxNum"></input-number>
      </form-item>
      <form-item label="盒子标签" prop="label">
        <y-input v-model="samplebox.label"></y-input>
      </form-item>
      <form-item label="样本盒类型" prop="exboxType">
        <y-select v-model="samplebox.exboxType" :data="exboxTypeList"></y-select>
      </form-item>
      <form-item label="盒子类型" prop="type">
        <y-select v-model="samplebox.type" :data="typeList"></y-select>
      </form-item>
      <form-item label="备注" prop="desc">
        <y-input v-model="samplebox.desc" type="textarea"></y-input>
      </form-item>
      <div class="split-line"></div>
      <template v-if="samplebox.exboxType==='strawbox'">
        <form-item label="麦管盒数" prop="strawboxCount">
          <input-number v-model="samplebox.strawboxCount"></input-number>
        </form-item>
      </template>
      <form-item label="盒子行数" prop="sampleboxRow">
        <input-number v-model="samplebox.sampleboxRow"></input-number>
      </form-item>
      <form-item label="盒子列数" prop="sampleboxCol">
        <input-number v-model="samplebox.sampleboxCol"></input-number>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../modal/modal.vue'
import YForm from '../../form/form'
import FormItem from '../../form/form-item'
import YInput from '../../input/input'
import InputNumber from '../../input/input-number'
import YSelect from '../../select/select'
import Poptip from '../../poptip/poptip'
export default {
  components: { Modal, YForm, FormItem, YInput, InputNumber, YSelect, Poptip },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      samplebox: this.value || {
        sampleboxNum: 1,
        label: '',
        desc: '',
        sampleboxRow: 10,
        sampleboxCol: 10,
        strawboxCount: 0
      },
      sampleboxRule: {
        sampleboxNum: [{type: 'number', min: 1, max: 12, required: true, message: '盒子编号不能为空, ≥1 且 ≤12', trigger: 'blur'}],
        label: [{required: true, max: 15, message: '盒子标签不能为空, 长度小于15', trigger: 'blur'}],
        exboxType: [{required: true, message: '样本盒类型不能为空', trigger: 'blur'}],
        type: [{required: true, message: '盒子类型不能为空', trigger: 'blur'}],
        sampleboxRow: [{type: 'number', min: 1, max: 16, required: true, message: '盒子行数不能为空, ≥1 且 ≤16', trigger: 'change'}],
        sampleboxCol: [{type: 'number', min: 1, max: 12, required: true, message: '盒子列数不能为空, ≥1 且 ≤12', trigger: 'change'}]
      },
      exboxTypeList: [
        {name: '冻存管', value: 'samplebox'},
        {name: '麦管', value: 'strawbox'},
        {name: '切片', value: 'cardbox'}
      ],
      typeList: [
        {name: '普通类型', value: 'normal'},
        {name: '间隔类型', value: 'skip'}
      ]
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    'samplebox.sampleboxNum' (val) {
      this.$emit('input', this.samplebox)
    },
    'samplebox.label' (val) {
      this.$emit('input', this.samplebox)
    },
    'samplebox.exboxType' (val) {
      this.$emit('input', this.samplebox)
    },
    'samplebox.strawboxCount' (val) {
      this.$emit('input', this.samplebox)
    },
    'samplebox.desc' (val) {
      this.$emit('input', this.samplebox)
    },
    'samplebox.sampleboxRow' (val) {
      this.$emit('input', this.samplebox)
    },
    'samplebox.sampleboxCol' (val) {
      this.$emit('input', this.samplebox)
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.sampleboxForm.validate()
      if (!this.$refs.sampleboxForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-add-ok')
    }
  }
}
</script>

<style lang="css">
</style>
