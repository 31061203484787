import { authenticate, catchExpired } from '../../../../utils/auth'
export default {
  data () {
    return {
      showModal: false,
      showDeleteMask: false,
      showDeleteWin: false,
      showEditWin: false,
      deletePermanent: false,
      deleteContainer: {},
      editContainer: {},

      currentGroupCodeList: [],
      authContainer: {},
      showAuthWin: false
    }
  },
  computed: {
    freezerEditActive () {
      return this.$store.state.edit.freezerEditActive
    },
    shelfEditActive () {
      return this.$store.state.edit.shelfEditActive
    },
    layerEditActive () {
      return this.$store.state.edit.layerEditActive
    },
    sampleboxEditActive () {
      return this.$store.state.edit.sampleboxEditActive
    },
    strawboxEditActive () {
      return this.$store.state.edit.strawboxEditActive
    }
  },
  mounted () {
    this.$store.dispatch('updateEditStatus', {containerType: 'freezer', status: false})
    this.$store.dispatch('updateEditStatus', {containerType: 'shelf', status: false})
    this.$store.dispatch('updateEditStatus', {containerType: 'layer', status: false})
    this.$store.dispatch('updateEditStatus', {containerType: 'samplebox', status: false})
    this.$store.dispatch('updateEditStatus', {containerType: 'strawbox', status: false})
  },
  methods: {
    onShowModal (index) {
      this.showModal = true
      if (index) {
        if (this.newFreezer) {
          this.newFreezer.zone = index
        }
      }
    },
    onShowDeleteWin (container) {
      this.deleteContainer = container
      this.showDeleteWin = true
    },
    onShowAuthWin (container) {
      this.authContainer = container
      this.currentGroupCodeList = container.groupCodeList
      this.showAuthWin = true
    },
    onShowEditWin (container) {
      this.editContainer = container
      this.showEditWin = true
    },
    onContainerEdit (type) {
      this.showDeleteMask = !this.showDeleteMask
      if (type === 'freezer') {
        this.$store.dispatch('updateEditStatus', {containerType: type, status: !this.freezerEditActive})
      } else if (type === 'shelf') {
        this.$store.dispatch('updateEditStatus', {containerType: type, status: !this.shelfEditActive})
      } else if (type === 'layer') {
        this.$store.dispatch('updateEditStatus', {containerType: type, status: !this.layerEditActive})
      } else if (type === 'samplebox') {
        this.$store.dispatch('updateEditStatus', {containerType: type, status: !this.sampleboxEditActive})
      } else if (type === 'strawbox') {
        this.$store.dispatch('updateEditStatus', {containerType: type, status: !this.strawboxEditActive})
      }
    },
    onContainerAuth (groupList) {
      let codeList = groupList.map(item => { return item.code })
      this.$http.post(`${this.httpRoot}/container/${this.containerType}/authorize`, {
        groupCodeList: codeList,
        rootId: this.authContainer.rootId,
        siteCode: this.authContainer.siteCode
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('授权成功')
          this.authContainer.groupCodeList = codeList
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('授权失败')
      })
    },
    onContainerUpdate (container) {
      this.$http.post(`${this.httpRoot}/container/${this.containerType}/update`, {
        container
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('更新成功')
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('更新失败')
      })
    },
    onCalcVol (container) {
      // 异步计算容器样本数量(空间占比,实时计算)
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/container/total/calculate`, {
        id: container.id
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.$Message.success('样本总量更新中')
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
        this.$Message.error('更新样本总量失败')
      })
    },
    onCalcSamplenum (container) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/container/samplenum/calculate`, {
        id: container.id
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          this.$Message.success('容器样本数量更新中')
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
        this.$Message.error('更新容器样本数量失败')
      })
    }
  }
}
