<template>
  <div class="home">
    <project></project>
  </div>
</template>

<script>
import Project from './project'
export default {
  components: {Project}
}
</script>
<style lang="less">
.home {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
