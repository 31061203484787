<template>
  <span class="node-content" :class="{focused: node.focused}">
    <span class="node-content-label"
      :class="[node.type, node.data.type, node.data.modelId?'modelid':'']"
      @click.stop="onLabelClick">{{ node.label || node.type }}
      <span class="field-type" v-if="node.type==='field'">{{node.data.type}}</span>
      <span class="value" v-if="node.data.valueType === 'number' && (node.data.value || node.data.value === 0)">[{{node.data.value}}]</span>
    </span>
    <span class="node-content-btn ionic-icon ionic-icon-plus"
      @click.stop="onAddClick"
      v-if="isShowPlus()">
      <ul class="node-type-list" v-show="showTypeList">
        <li class="node-type" v-if="node.type === 'root'" @click="onAddTypeNode('field')">field</li>
        <li class="node-type" v-if="node.type === 'field'" @click="onAddTypeNode('item')">item</li>
        <li class="node-type" v-if="isShowGroup()" @click="onAddTypeNode('group')">group</li>
        <li class="node-type" v-if="node.type === 'group'" @click="onAddTypeNode('gitem')">gitem</li>
        <li class="node-type" v-if="isShowChild()" @click="onAddTypeNode('child')">child</li>
        <li class="node-type" v-if="isShowSubChild()" @click="onAddTypeNode('subChild')">subChild</li>
      </ul>
    </span>
    <span class="node-content-btn ionic-icon ionic-icon-ios-cloud-download"
      @click.stop="onSaveClick" v-if="node.type === 'root'">
    </span>
    <span class="node-content-btn ionic-icon ionic-icon-ios-copy"
      @click.stop="onReplicateClick" v-if="node.type === 'root'">
    </span>
    <poptip
      confirm
      title="确认删除？"
      @on-ok="onDelete"
      v-if="node.type !== 'root'">
      <span class="node-content-btn ionic-icon ionic-icon-close"></span>
    </poptip>
    <span class="node-content-arrow ionic-icon ionic-icon-ios-copy"
      v-if="canCopy" @click.stop="onCopyNode"></span>
    <span class="node-content-arrow ionic-icon ionic-icon-arrow-up-c"
      v-if="canCopy" @click.stop="onSlidUp"></span>
    <span class="node-content-arrow ionic-icon ionic-icon-arrow-down-c"
      v-if="canCopy" @click.stop="onSlidDown"></span>
  </span>
</template>

<script>
import Poptip from '../../common/poptip/poptip'
import { getPropertyFromData } from './model/util'
export default {
  components: {Poptip},
  props: {
    node: {
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      showTypeList: false
    }
  },
  computed: {
    canCopy () {
      return this.node.type === 'field' ||
             this.node.type === 'item' ||
             this.node.type === 'group' ||
             this.node.type === 'gitem'
    }
  },
  methods: {
    onSaveClick () {
      this.$parent.$tree.$emit('on-tree-save', this.node.label)
    },
    onReplicateClick () {
      this.$parent.$tree.$emit('on-tree-replicate', this.node.label)
    },
    onLabelClick () {
      this.$emit('on-label-click')
    },
    onAddClick () {
      this.$emit('on-typeList-show-before')
      this.showTypeList = !this.showTypeList
      if (this.showTypeList) {
        this.$emit('on-typeList-show')
      }
    },
    onAddTypeNode (type) {
      this.$nextTick(() => {
        this.$parent.$tree.$emit('on-typeList-click', type)
      })
    },
    onDelete () {
      this.node.delete()
    },
    onSlidUp () {
      this.node.slidUp()
    },
    onSlidDown () {
      this.node.slidDown()
    },
    onCopyNode () {
      this.node.copyNodeAndInsertAfter()
    },
    hideTypeList () {
      this.showTypeList = false
    },
    isShowPlus () {
      return this.node.type === 'root' ||
             this.node.type === 'field' ||
             this.node.type === 'group' ||
             this.isShowChild()
    },
    isShowGroup () {
      return this.node.type === 'field' && getPropertyFromData(this.node, 'type') === 'input'
    },
    isShowChild () {
      return (this.node.type === 'item' || this.node.type === 'gitem') &&
         (getPropertyFromData(this.node, 'type') === 'input_multi_inline' ||
          getPropertyFromData(this.node, 'type') === 'input_multi_line' ||
          getPropertyFromData(this.node, 'type') === 'input_sselect' ||
          getPropertyFromData(this.node, 'type') === 'input_sselect_multi_line' ||
          getPropertyFromData(this.node, 'type') === 'input_sselect_input' ||
          getPropertyFromData(this.node, 'type') === 'input_mselect' ||
          getPropertyFromData(this.node, 'type') === 'input_mselect_input' ||
          getPropertyFromData(this.node, 'type') === 'sselect_input' ||
          getPropertyFromData(this.node, 'type') === 'mselect_input_multi' ||
          getPropertyFromData(this.node, 'type') === 'mselect_input_multi_self_input' ||
          getPropertyFromData(this.node, 'type') === 'show_score')
    },
    isShowSubChild () {
      return (this.node.type === 'item' || this.node.type === 'gitem') &&
          getPropertyFromData(this.node, 'type') === 'sselect_input'
    }
  }
}
</script>

<style lang="css">
</style>
