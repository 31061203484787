<template>
  <modal v-model="showModal" title="设置 db 连接" width="650" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <div class="r-f-item">
      <span class="r-f-label">db</span>
      <y-select v-model="driverName" :data="dbTypeList"></y-select>
    </div>
    <div class="r-f-item">
      <span class="r-f-label">datasource</span>
      <y-select v-model="datasourceName" attrInValue valueField="url" showValue :data="datasourceList" @on-select-change="onDatasourceChange"></y-select>
    </div>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YSelect from '../../common/select/select.vue'
import YInput from '../../common/input/input.vue'
import { authenticate, catchExpired } from '../../../utils/auth'

export default {
  components: {Modal, YSelect, YInput},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: this.show,
      dbTypeList: ['mysql', 'mongodb'],
      datasourceList: [],
      datasource: null,
      driverName: this.$store.state.db.dbName || 'mysql',
      datasourceName: this.$store.state.db.dbSource || ''
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    projectId (val) {
      if (val) {
        this.loadDatasourceList()
      }
    }
  },
  mounted () {
    this.loadDatasourceList()
  },
  methods: {
    onBeforeConfirm () {
      if (!this.driverName || !this.datasourceName) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', {
        driverName: this.driverName,
        datasourceName: this.datasourceName,
        datasource: this.datasource
      })
    },
    onDatasourceChange (val) {
      if (val && val.url) {
        this.datasource = val
      }
    },
    loadDatasourceList () {
      this.$http.get(`${this.httpRoot}/dataset/datasource/list`, authenticate({params: {projectId: this.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.datasourceList = res.datasourceList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="less">
.r-f-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .r-f-label {
    width: 90px;
    text-align: right;
    margin-right: 10px;
    font-size: 14px;
  }
}
</style>
