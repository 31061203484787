<template>
  <div class="table-wrapper" :style="styles"
    :class="{'table-hide': !ready, 'table-with-header': this.$slots.header, 'table-with-footer': this.$slots.footer}">
    <div class="table" :class="{[`table-${size}`]: !!size, 'table-border': border,
                                'table-stripe': stripe, 'table-with-fixed-top': !!height}" ref="table">
      <div class="table-title" v-if="this.$slots.header" ref="title"><slot name="header"></slot></div>
      <div class="table-header-edit" v-if="showHeaderEditor">
        <table-header-editor
          ref="editheader"
          :columns="tableStore.centerColumns"
          :styleObject="tableWidthStyle"
          :columnsWidth="columnsWidth"
          :showSelectAll="showHeaderSelectAll"
          @on-column-edit="onColumnEdit">
        </table-header-editor>
        <div v-if="title" class="title">{{title}}</div>
      </div>
      <div class="table-header" v-if="showHeader" ref="header" @mousewheel="handleMouseWheel"
        @contextmenu.prevent="handleHeaderContextMenu"
        @click="handleHeaderClick"
        v-clickoutside="handleContextMenuClose">
        <table-header
          ref="checkheader"
          :columns="tableStore.centerColumns"
          :styleObject="tableWidthStyle"
          :columnsWidth="columnsWidth">
        </table-header>
        <column-toggle
          ref="columnToggle"
          :columns="tableStore.toggleColumns"
          :style="{top: cf.top+'px', left: cf.left+'px', display: cf.display, maxHeight: cf.maxHeight+'px', overflow: 'auto'}"
          @on-toggle-column-change="handleToggleColumnChange"></column-toggle>
      </div>
      <div class="table-body" :style="bodyStyle" ref="body" @scroll="handleBodyScroll">
        <table-body
          :columns="tableStore.centerColumns"
          :hideColumns="tableStore.hideColumns"
          :data="tableStore.filteredData"
          :styleObject="tableWidthStyle"
          :columnsWidth="columnsWidth"
          :hideRowSelect="hideRowSelect"
          :noborder="noborder"
          :indexStart="indexStart"
          ref="tbody">
        </table-body>
      </div>
      <div class="table-tip" v-show="showTip">
        <table cellspacing="0" cellpadding="0" border="0">
          <tbody>
            <tr>
              <td :style="{height: bodyStyle.height}">
                <span v-html="noDataText" v-if="!data || data.length === 0"></span>
                <span v-html="noFilteredDataText" v-else></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-fixed" :style="fixedTableStyle" v-if="tableStore.isLeftFixed">
        <div class="table-fixed-header" v-if="showHeader">
          <table-header
            fixed="left"
            :columns="tableStore.leftFixedColumns"
            :styleObject="fixedTableStyle"
            :columnsWidth="columnsWidth"></table-header>
        </div>
        <div class="table-fixed-body" :style="fixedBodyStyle" ref="fixedBody">
          <table-body
            fixed="left"
            :columns="tableStore.leftFixedColumns"
            :data="tableStore.filteredData"
            :styleObject="fixedTableStyle"
            :columnsWidth="columnsWidth"
            :hideRowSelect="hideRowSelect"
            :noborder="noborder"
            :indexStart="indexStart"></table-body>
        </div>
      </div>
      <div class="table-fixed-right" :style="fixedRightTableStyle" v-if="tableStore.isRightFixed">
        <div class="table-fixed-header" v-if="showHeader">
          <table-header
            fixed="right"
            :columns="tableStore.rightFixedColumns"
            :styleObject="fixedRightTableStyle"
            :columnsWidth="columnsWidth"></table-header>
        </div>
        <div class="table-fixed-body" :style="fixedBodyStyle" ref="fixedRightBody" @mousewheel="handleFixedRightBodyMouseWheel">
          <table-body
            fixed="right"
            :columns="tableStore.rightFixedColumns"
            :data="tableStore.filteredData"
            :styleObject="fixedRightTableWidthStyle"
            :columnsWidth="columnsWidth"
            :hideRowSelect="hideRowSelect"
            :noborder="noborder"
            :indexStart="indexStart"></table-body>
        </div>
      </div>
      <div class="table-footer" v-if="this.$slots.footer" ref="footer"><slot name="footer"></slot></div>
    </div>
  </div>
</template>

<script>
import TableStore from './table-store'
import TableHeaderEditor from './table-header-editor'
import TableHeader from './table-header'
import TableBody from './table-body'
import ColumnToggle from './column-toggle'
import clickoutside from '../directives/clickoutside'
import { getStyle, getScrollBarSize } from '../../../utils/assist'
import { authenticate, catchExpired } from '../../../utils/auth'

export default {
  name: 'Table',
  components: { TableHeaderEditor, TableHeader, TableBody, ColumnToggle },
  directives: { clickoutside },
  props: {
    id: String,
    columns: Array,
    data: Array,
    queryPath: String,
    queryParams: {},
    width: [String, Number],
    height: [String, Number],
    size: String,
    title: String,
    stripe: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    rowClassName: Function,
    showHeader: {
      type: Boolean,
      default: true
    },
    showHeaderEditor: {
      type: Boolean,
      default: false
    },
    showHeaderSelectAll: {
      type: Boolean,
      default: false
    },
    highlightRow: {
      type: Boolean,
      default: false
    },
    noDataText: {
      type: [String, Boolean],
      default: '暂无数据'
    },
    noFilteredDataText: {
      type: [String, Boolean],
      default: '暂无筛选结果'
    },
    hideRowSelect: {
      type: Boolean,
      default: false
    },
    noborder: {
      type: Boolean,
      default: false
    },
    hoverDisable: {
      type: Boolean,
      default: false
    },
    indexStart: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      tableStore: {
        centerColumns: [],
        leftFixedColumns: [],
        rightFixedColumns: [],
        filteredData: []
      },
      ready: true,
      tableWidth: 0,
      bodyHeight: 0,
      bodyRealHeight: 0,
      columnsWidth: {},
      scrollBarWidth: getScrollBarSize(),
      cf: {
        top: 0,
        left: 0,
        display: 'none',
        maxHeight: 'auto'
      }
    }
  },
  computed: {
    // set table-wrapper width and height
    styles () {
      let style = {}
      if (this.height) {
        const height = (this.tableStore.isLeftFixed || this.tableStore.isRightFixed)
          ? parseInt(this.height) + this.scrollBarWidth
          : parseInt(this.height)
        style.height = `${height}px`
      }
      if (this.width) style.width = `${this.width}px`
      return style
    },
    // set table-body height
    bodyStyle () {
      let style = {}
      if (this.bodyHeight !== 0) {
        // add a height to resolve scroll bug when browser has a scrollBar in fixed type and height prop
        const height = (this.tableStore.isLeftFixed || this.tableStore.isRightFixed)
          ? this.bodyHeight + this.scrollBarWidth
          : this.bodyHeight
        style.height = `${height}px`
      }
      return style
    },
    // set table-header table-body width
    tableWidthStyle () {
      let style = {}
      // console.log('tableWidth ', this.tableWidth)
      if (this.tableWidth !== 0) {
        let width = ''
        if (this.bodyHeight === 0) {
          width = this.tableWidth
        } else {
          // console.log('bodyHeight < bodyRealHeight ? ', this.bodyHeight, this.bodyRealHeight)
          if (this.bodyHeight > this.bodyRealHeight) {
            width = this.tableWidth
          } else {
            width = this.tableWidth - this.scrollBarWidth
          }
        }
        style.width = `${width}px`
      }
      return style
    },
    fixedTableStyle () {
      let style = {}
      let width = 0
      this.tableStore.leftFixedColumns.forEach(col => {
        if (col.fixed && col.fixed === 'left') width += col._width
      })
      style.width = `${width}px`
      return style
    },
    fixedRightTableStyle () {
      let style = {}
      let width = 0
      this.tableStore.rightFixedColumns.forEach(col => {
        if (col.fixed && col.fixed === 'right') width += col._width
      })
      style.width = `${width}px`
      return style
    },
    fixedRightTableWidthStyle () {
      let style = {}
      let width = 0
      this.tableStore.rightFixedColumns.forEach(col => {
        if (col.fixed && col.fixed === 'right') width += col._width
      })
      if (this.bodyHeight < this.bodyRealHeight) {
        width -= this.scrollBarWidth
      }
      style.width = `${width}px`
      return style
    },
    fixedBodyStyle () {
      let style = {}
      if (this.bodyHeight !== 0) {
        let height = this.bodyHeight
        // if (this.width && this.width < this.tableWidth) {
        //   height = this.bodyHeight - this.scrollBarWidth
        // }
        style.height = this.scrollBarWidth > 0 ? `${height}px` : `${height - 1}px`
      }
      return style
    },
    showTip () {
      if (this.noDataText && (!this.data || (this.data && !this.data.length))) return true
      if (this.noFilteredDataText && (!this.tableStore.filteredData || !this.tableStore.filteredData.length)) return true
      return false
    }
  },
  watch: {
    columns (val) {
      if (Array.isArray(val)) {
        this.tableStore.setColumns(val)
        this.ready = true
        this.handleResize()
      }
    },
    data (val) {
      if (Array.isArray(val)) {
        const $body = this.$refs.body
        if ($body) {
          $body.scrollTop = 0
        }
        this.tableStore.setData(val)
        this.ready = true
        this.handleResize()
      }
    },
    height (val) {
      this.resolveBodyHeight()
    }
  },
  created () {
    this.tableStore = new TableStore({
      $container: this,
      loadFn: this.loadData,
      columns: this.columns,
      data: this.data
    })
    this.tableStore.loadData()

    this.$on('on-load-success', () => {
      this.ready = true
    })
    this.$on('on-row-select', (_index) => {
      this.toggleSelect(_index)
    })
    this.$on('on-select-change', () => {
      if (this.isSelectAll() && this.$refs.checkheader) {
        this.$refs.checkheader.updateSelectAll(true)
      } else if (!this.isSelectAll() && this.$refs.checkheader) {
        this.$refs.checkheader.updateSelectAll(false)
      }
      if (this.isSelectAll() && this.$refs.editheader) {
        this.$refs.editheader.updateSelectAll(true)
      } else if (!this.isSelectAll() && this.$refs.editheader) {
        this.$refs.editheader.updateSelectAll(false)
      }
      // console.log('selected data ', this.tableStore.getSelectedData())
      this.$emit('on-table-select-change', this.tableStore.getSelectedData())
    })
    this.$on('on-row-click', (_index) => {
      // console.log(_index, 'clicked')
    })
    this.$on('on-row-dblclick', (_index) => {
      // console.log(_index, 'dblclicked')
    })
  },
  mounted () {
    this.handleResize()
    this.resolveBodyHeight()
    window.addEventListener('resize', this.handleResize, false)
  },
  methods: {
    handleMouseWheel (event) {
      const deltaX = event.deltaX
      const $body = this.$refs.body
      if (deltaX > 0) {
        $body.scrollLeft = $body.scrollLeft + 10
      } else {
        $body.scrollLeft = $body.scrollLeft - 10
      }
    },
    handleBodyScroll (event) {
      if (this.showHeader) this.$refs.header.scrollLeft = event.target.scrollLeft
      if (this.tableStore.isLeftFixed) this.$refs.fixedBody.scrollTop = event.target.scrollTop
      if (this.tableStore.isRightFixed) this.$refs.fixedRightBody.scrollTop = event.target.scrollTop
      this.hideColumnFilter()
    },
    handleFixedRightBodyMouseWheel (event) {
      const deltaY = event.deltaY
      const $body = this.$refs.body
      const $fixedBody = this.$refs.fixedBody
      if (deltaY > 0) {
        $body.scrollTop = $body.scrollTop + deltaY
        if ($fixedBody) $fixedBody.scrollTop = $fixedBody.scrollTop + deltaY
      } else {
        $body.scrollTop = $body.scrollTop + deltaY
        if ($fixedBody) $fixedBody.scrollTop = $fixedBody.scrollTop + deltaY
      }
    },
    handleResize () {
      this.$nextTick(() => {
        this.resolveTableWidth()
        // get table real height,for fixed when set height prop,but height < table's height,show scrollBarWidth
        if (this.$refs.tbody) {
          // update by zh_qd 2021.8.3
          this.bodyRealHeight = parseInt(getStyle(this.$refs.tbody.$el, 'height'))
        }

        this.$nextTick(() => {
          // 计算过早不准确, 有滚动条的时候
          setTimeout(() => {
            this.resolveColumnsWidth()
          }, 100)
        })
      })
    },
    resolveTableWidth () {
      const columns = this.tableStore.centerColumns
      const allWidth = !columns.some(col => !col.width)    // each column has a width
      const tableWrapperWidth = parseInt(getStyle(this.$el, 'width'))
      if (allWidth && allWidth >= tableWrapperWidth) {
        this.tableWidth = columns.map(col => col.width).reduce((a, b) => a + b)
      } else {
        this.tableWidth = tableWrapperWidth - 1
      }
      // then trigger tableWidthStyle computed and change table width style
    },
    resolveColumnsWidth () {
      const columns = this.tableStore.allColumns
      const allWidth = !columns.some(col => !col.width)    // each column has a width

      let columnsWidth = {}
      let autoWidthIndex = -1
      if (!allWidth) autoWidthIndex = columns.findIndex(col => !col.width)

      if (this.data && this.data.length && this.$refs.tbody) {
        const $td = this.$refs.tbody.$el.querySelectorAll('tbody tr')[0].querySelectorAll('td')
        for (let i = 0; i < $td.length; i++) {    // can not use forEach in Firefox
          const column = columns[i]

          let width = parseInt(getStyle($td[i], 'width'))
          if (i === autoWidthIndex) {
            width = parseInt(getStyle($td[i], 'width')) - 1
          }
          columnsWidth[column._index] = { width: width }
        }
        this.columnsWidth = columnsWidth
      }
      // then trigger table-header and table-body's columnsWidth changed and then reRender
      // it's col width throught calling setCellWidth method
    },
    resolveBodyHeight () {
      if (this.height) {
        this.$nextTick(() => {
          // 计算过早不准确
          setTimeout(() => {
            const titleHeight = parseInt(getStyle(this.$refs.title, 'height')) || 0
            const headerHeight = parseInt(getStyle(this.$refs.header, 'height')) || 0
            const footerHeight = parseInt(getStyle(this.$refs.footer, 'height')) || 0
            this.bodyHeight = this.height - titleHeight - headerHeight - footerHeight
          }, 50)
        })
      } else {
        this.bodyHeight = 0
      }
    },
    handleHeaderContextMenu (e) {
      let mouse = {
        top: e.clientY,
        left: e.clientX
      }
      let headerRect = {}
      if (this.$refs.header) {
        headerRect = this.$refs.header.getBoundingClientRect()
        this.cf.top = headerRect.top
        this.cf.left = headerRect.left
      }
      this.cf.top = mouse.top - this.cf.top
      this.cf.left = mouse.left - this.cf.left
      this.cf.display = 'block'
      this.cf.maxHeight = window.innerHeight - 60
      // update top position after display
      let columnToggleRect = {}
      setTimeout(() => {
        if (this.$refs.columnToggle) {
          columnToggleRect = this.$refs.columnToggle.$el.getBoundingClientRect()
        }
        let mouseBottom = window.innerHeight - mouse.top
        if (mouseBottom < columnToggleRect.height) {
          this.cf.top = window.innerHeight - columnToggleRect.height - headerRect.top
        }
      }, 50)
      return false
    },
    handleHeaderClick (e) {
      if (!this.$refs.columnToggle.$el.contains(e.target)) {
        this.cf.display = 'none'
      }
    },
    handleContextMenuClose () {
      this.cf.display = 'none'
    },
    handleToggleColumnChange (columnKeys, isAdd) {
      // add or remove toggle columns
      this.tableStore.updateToggleColumn(columnKeys, isAdd)
      // need to reset tableWidth ?
      this.resolveTableWidth()
      // notify parent component
      this.$emit('on-toggle-column-change', this.tableStore.getSortedToggleColumns())
    },
    hideColumnFilter () {},
    toggleSelect (_index) {
      this.tableStore.toggleSelect(_index)
    },
    selectAll (isSelectAll) {
      this.tableStore.selectAll(isSelectAll)
      if (isSelectAll && this.$refs.checkheader) {
        this.$refs.checkheader.updateSelectAll(true)
      } else if (!isSelectAll && this.$refs.checkheader) {
        this.$refs.checkheader.updateSelectAll(false)
      }
      if (isSelectAll && this.$refs.editheader) {
        this.$refs.editheader.updateSelectAll(true)
      } else if (!isSelectAll && this.$refs.editheader) {
        this.$refs.editheader.updateSelectAll(false)
      }
      this.$emit('on-selectall-change', isSelectAll)
    },
    isSelectAll () {
      return this.tableStore.isSelectAll()
    },
    hasSelect () {
      return this.tableStore.hasSelect()
    },
    handleSort (column, type) {
      if (column.sortable === 'server') {
        this.tableStore.setColumnSortType(column, type)
        this.$emit('on-column-sort', column.key, type)
      } else {
        this.tableStore.sortData(column, type)
      }
    },
    loadData (store, successCallback, faildCallback, {path, params, callback} = {}) {
      if (!this.queryPath && !path) return

      let _path = path || this.queryPath
      let _params = params || this.queryParams

      this.$http.get(`${this.httpRoot}/${_path}`, authenticate({params: _params}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && typeof successCallback === 'function') {
          successCallback.call(store, res.data)
          if (typeof callback === 'function') {
            callback()
          }
        } else if (typeof faildCallback === 'function') {
          faildCallback.call(store)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        faildCallback.call(store)
      })
    },
    loadDataWithParams (params) {
      if (params && params.path && params.params) {
        this.tableStore.loadDataWithParams(params)
      }
    },
    removeData (_index) {
      this.tableStore.removeData(_index)
    },
    removeDataById (_idx) {
      return this.tableStore.removeDataById(_idx)
    },
    removeSelectedData () {
      this.tableStore.removeSelectedData()
    },
    getAllData () {
      return this.tableStore.getAllData()
    },
    getDataById (_idx) {
      return this.tableStore.getDataById(_idx)
    },
    setDataById (_idx, data) {
      this.tableStore.setDataById(_idx, data)
    },
    setDataColumnById (_idx, data, column) {
      this.tableStore.setDataColumnById(_idx, data, column)
    },
    recoverData () {
      this.tableStore.recoverData()
    },
    getSelectedData () {
      return this.tableStore.getSelectedData()
    },
    editSelectedRow (column, value) {
      this.tableStore.editSelectedRow(column, value)
    },
    onColumnEdit (column) {
      this.$emit('on-column-edit', column, this)
    },
    validate (column, validator) {
      return this.tableStore.validate(column, validator)
    },
    toggleShowHideColumns (_idx) {
      this.tableStore.toggleShowHideColumns(_idx)
    },
    isShowHideColumns (_idx) {
      return this.tableStore.isShowHideColumns(_idx)
    },
    scrollIntoView (_idx) {
      this.$refs.tbody.scrollIntoView(_idx)
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize, false)
  }
}
</script>

<style lang="css">
</style>
