<template>
  <i :class="['ionic-icon', `ionic-icon-${type}`]" :style="{color: color, fontSize: size ? size + 'px' : false}"></i>
</template>

<script>
export default {
  props: {
    type: String,
    size: [Number, String],
    color: String
  }
}
</script>
