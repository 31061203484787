<template>
  <modal class="type-edit-win" v-model="showModal" title="样本类型添加" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm" :styles="{top: '60px'}">
    <y-form ref="typeForm" :model="type" :rules="typeRule" label-width="100px" item-width="250px">
      <form-item label="样本类型" prop="id">
        <y-select
          v-model="type.id"
          valueField="id"
          attrInValue
          filterable
          clearable
          showField="code"
          showIconUrl
          :data="typeList"
          @on-select-change="onTypeChange"
          remote
          :load-method="loadTypeList"></y-select>
      </form-item>
      <form-item label="样本规格" prop="specList">
        <poptip trigger="hover" content="配置样本需要用到的冻存管规格" placement="right-start">
          <y-select
            v-model="type.specList"
            :data="specList"
            valueField="name"
            multiple></y-select>
        </poptip>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import Poptip from '../../common/poptip/poptip'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: { Modal, YForm, FormItem, YSelect, Poptip },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {},
    projectId: String
  },
  data () {
    return {
      showModal: this.show,
      type: this.value || {},
      tempSampleType: {},
      typeRule: {
        id: [{required: true, message: '不能为空', trigger: 'blur'}],
        specList: [{type: 'array', required: true, message: '不能为空', trigger: 'blur'}]
      },
      typeList: [],
      specList: []
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    value (val) {
      this.type = val
    }
  },
  mounted () {
    this.loadTypeList()
    this.loadSpecList()
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.typeForm.validate()
      if (!this.$refs.typeForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.mergeValues())
    },
    onTypeChange (val) {
      this.tempSampleType = val
    },
    loadTypeList (query, callback) {
      this.$http.get(`${this.httpRoot}/sample/type/list`, authenticate({params: {projectId: this.projectId, query}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback(res.typeList)
          } else {
            this.typeList = res.typeList || []
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadSpecList () {
      this.$http.get(`${this.httpRoot}/dict/item/list`, authenticate({params: {dictCode: 'spec', projectId: this.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.specList = res.items || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    mergeValues () {
      let type = Object.assign({}, this.tempSampleType, {specList: this.type.specList})
      delete type.id
      return type
    }
  }
}
</script>

<style lang="less">
.form-item {
  .poptip {
    width: 100%;
    .poptip-rel {
      width: 100%;
    }
  }
}
</style>
