<template>
  <div class="progress" :class="[`progress-${currentStatus}`,
                                {'progress-show-info': hideInfo},
                                {'progress-vertical': vertical}]">
    <div class="progress-outer">
      <div class="progress-inner">
        <div v-if="vertical" class="progress-bg" :style="{backgroundColor: strokeColor, width: `${strokeWidth}px`, height: `${percent}%`}"></div>
        <div v-else class="progress-bg" :style="{backgroundColor: strokeColor, width: `${percent}%`, height: `${strokeWidth}px`}"></div>
      </div>
    </div>
    <span v-if="!hideInfo" class="progress-text">
      <slot>
        <span v-if="isStatus" class="progress-text-inner">
          <icon :type="statusIcon"></icon>
        </span>
        <span v-else class="progress-text-inner">
          {{ showValue || percent }}%
        </span>
      </slot>
    </span>
  </div>
</template>

<script>
import Icon from '../icon/icon'
import { oneOf } from '../../../utils/assist'

export default {
  components: { Icon },
  props: {
    percent: {
      type: Number,
      default: 0
    },
    showValue: [String, Number],
    status: {
      validator (value) {
        return oneOf(value, ['normal', 'active', 'wrong', 'success'])
      },
      default: 'normal'
    },
    hideInfo: {
      type: Boolean,
      default: false
    },
    strokeColor: {
      type: String,
      default: '#2db7f5'
    },
    strokeWidth: {
      type: Number,
      default: 10
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentStatus: this.status
    }
  },
  computed: {
    isStatus () {
      return this.currentStatus === 'wrong' || this.currentStatus === 'success'
    },
    statusIcon () {
      let type = ''
      switch (this.currentStatus) {
        case 'wrong':
          type = 'ios-close'
          break
        case 'success':
          type = 'ios-checkmark'
          break
      }
      return type
    }
  },
  watch: {
    percent (val, oldVal) {
      if (val < oldVal) {
        this.handleStatus(true)
      } else {
        this.handleStatus()
      }
    },
    status (val) {
      this.currentStatus = val
    }
  },
  created () {
    this.handleStatus()
  },
  methods: {
    handleStatus (isDown) {
      if (isDown) {
        this.currentStatus = 'normal'
        this.$emit('on-status-change', 'normal')
      } else {
        if (parseInt(this.percent, 10) === 100) {
          this.currentStatus = 'success'
          this.$emit('on-status-change', 'success')
        }
      }
    }
  }
}
</script>
