<template>
  <div class="field-tree-node" @click.stop="onNodeClick">
    <div class="field-tree-node__content"
      :style="{ 'padding-left': node.level * $tree.indent + (node.level > 0 && node.isLeaf ? 20 : 0) + 'px' }">
      <span class="field-tree-node__expand-icon ionic-icon ionic-icon-arrow-right-b"
        :class="{ 'is-leaf': node.isLeaf, 'expanded': !node.isLeaf && node.expanded }"
        @click.stop="onToggleNodeExpand">
      </span>
      <node-content ref="nodeContent"
        :node="node"
        @on-label-click="onLabelClick"
        @on-typeList-show="onTypeListShow"
        @on-typeList-show-before="onTypeListShowBefore"></node-content>
    </div>
    <collapse-transition>
      <div class="field-tree-node__children" v-show="node.expanded">
        <field-tree-node v-for="child in node.childNodes"
          :node="child"
          :props="props"
          :key="getKeyField(child)">
        </field-tree-node>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import CollapseTransition from '../../common/transitions/collapse-transition'
import NodeContent from './node-content'
import Emitter from '../../common/mixins/emitter'
import mixin from './mixin'

export default {
  name: 'field-tree-node',
  mixins: [Emitter, mixin],
  components: {CollapseTransition, NodeContent},
  props: {
    node: {
      default () {
        return {}
      }
    },
    props: {}
  },
  data () {
    return {
      $tree: null
    }
  },
  created () {
    this.$tree = this.$parent.$tree
  },
  methods: {
    onNodeClick () {
      // this.node
      this.$tree.hideTypeList()
      this.updateCurrentTreeNode()
      this.$tree.$emit('on-node-click', this.node.data, this.node.type)
    },
    onToggleNodeExpand () {
      if (this.node) {
        this.node.toggleExpand()
      }
    },
    onTypeListShowBefore () {
      if (this.$tree.$currentTreeNode !== this) {
        this.$tree.hideTypeList()
      }
    },
    onLabelClick () {
      this.$tree.hideTypeList()
      this.updateCurrentTreeNode()
      this.$tree.$emit('on-node-click', this.node.data, this.node.type)
    },
    onTypeListShow () {
      this.updateCurrentTreeNode()
    },
    updateCurrentTreeNode () {
      this.$tree.$currentTreeNode = this
      this.$nextTick(() => {
        this.node.focus(true)
      })
    },
    hideTypeList () {
      if (this.$refs.nodeContent) {
        this.$refs.nodeContent.hideTypeList()
      }
    },
    hasChild () {
      return this.node.hasChild()
    },
    getNewChildKey () {
      return this.node.getNewChildKey()
    },
    insertChild (data, type) {
      this.node.insertChildData(data, type)
    }
  }
}
</script>

<style lang="css">
</style>
