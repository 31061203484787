<template>
  <div :class="[fixedTop ? 'loading-bar-fixed-top': 'loading-bar']" :style="{height: height + 'px'}" v-show="show" transition="fade">
    <div :class="['loading-bar-inner', {'loading-bar-inner-color-primary': color === 'primary' && status === 'success', 'loading-bar-inner-failed-color-error': failedColor === 'error' && status === 'error'}]"
      :style="{height: height + 'px', width: percent + '%', backgroundColor: (this.color !== 'primary' && this.status === 'success' ? color : (failedColor !== 'error' && status === 'error' ? failedColor : false))}"></div>
  </div>
</template>
<script>
import Tween from '../../../utils/tween'
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    failedColor: {
      type: String,
      default: 'error'
    },
    height: {
      type: Number,
      default: 2
    },
    time: {
      type: Number,
      default: 100 // 预设加载时间
    },
    trigger: {
      type: String,
      default: 'mounted' // program
    },
    fixedTop: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      percent: 0,
      status: 'success', // error
      show: false
    }
  },
  mounted () {
    if (this.trigger === 'mounted') {
      this.start()
    }
  },
  methods: {
    update (percent, status, isShow) {
      this.percent = percent
      this.status = status
      this.show = isShow
    },
    start () {
      this.update(0, 'success', true)
      this._trigger()
    },
    finish () {
      this.update(100, 'success', true)
      this.hide()
    },
    error () {
      this.update(100, 'error', true)
      this.hide()
    },
    hide () {
      setTimeout(() => {
        this.show = false
        setTimeout(() => {
          this.percent = 0
        }, 100)
      }, 800)
    },
    _trigger () {
      let t = 0
      let b = 0
      let c = 100
      let d = this.time
      let progress = 0
      let loopId = null
      let self = this
      function loop () {
        if (t < d - 15) {
          t++
          progress = Tween.Cubic.easeInOut(t, b, c, d)
          self.percent = Math.floor(progress)
          self.$nextTick(() => {
            clearTimeout(loopId)
            loopId = setTimeout(loop, 20)
          })
        } else {
          clearTimeout(loopId)
        }
      }
      setTimeout(loop, 0)
    }
  }
}
</script>
<style lang="less">
@import "../../../assets/ivu/custom";
.loading-bar {
  width: 100%;
  position: relative;;
}
.loading-bar-fixed-top {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
.loading-bar, .loading-bar-fixed-top{
  &-inner {
    transition: width .2s linear;
    &-color-primary {
      background-color: @primary-color;
    }
    &-failed-color-error {
      background-color: @error-color;
    }
  }
}
</style>
