import objectAssign from '../../../../utils/merge'
import Samplebox from './samplebox'
let layerid = 300

export default class Layer {
  constructor (options) {
    this.id = ++layerid
    this._type = 'layer'
    this.type = 'fridge'
    this.exboxType = ''
    this.label = ''
    this.layer = 1 // 层位于架子层数，下标1开始
    this.enable = true
    this.capacity = 100
    this.sampleboxContainer = []
    this.loaded = false
    this.selected = false
    this.store = null
    this.parent = null
    this.$container = null

    for (let name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name]
      }
    }
    if (this.store) {
      this.$container = this.store.$container
      this.loadFn = this.store.loadFn
    }
    if (this.children) {
      this.setChildren(this.children)
      this.loaded = true
    }
  }

  loadSampleboxContainer (parentId, callback) {
    if (!this.loaded && !this.store.loading) {
      this.store.loading = true
      const resolveSuccess = (items) => {
        this.store.loading = false
        this.setChildren(items)
        this.loaded = true

        if (typeof callback === 'function') {
          callback.call(this)
        }
        this.$container.$emit('on-samplebox-load-success')
      }
      const resolveFaild = () => {
        this.store.loading = false
      }
      if (typeof this.loadFn === 'function') {
        this.loadFn.call(this.$container, this, resolveSuccess, resolveFaild, 'sampleboxContainer', parentId)
      }
    }
  }

  setChildren (items) {
    this.sampleboxContainer = items.map((item) => {
      return new Samplebox(objectAssign(item, {store: this.store, parent: this}))
    })
    this.store.registContainer(this.sampleboxContainer)
  }

  select (isSelect, reSelect) {
    if (!this.enable) return
    if (!this.selected && isSelect) {
      this.selected = true
      this.parent.updateSelectedLayer(this.id)
      if (!this.loaded) {
        this.loadSampleboxContainer(this.id, () => {
          this.updateCurrentSampleboxContainer()
          this.$container.$emit('on-layer-code-selected', this)
        })
      } else {
        this.updateCurrentSampleboxContainer()
        this.$container.$emit('on-layer-code-selected', this)
      }
      this.store.updateContainerPath(this)
      this.$container.$emit('on-path-change')
    } else if (this.selected && !isSelect) {
      this.selected = false
    } else if (this.selected && isSelect && reSelect) {
      this.updateCurrentSampleboxContainer()
      this.store.updateContainerPath(this)
      this.$container.$emit('on-layer-code-selected', this)
      this.$container.$emit('on-path-change')
    }
  }

  updateCurrentSampleboxContainer () {
    this.store.strawboxContainer.items.forEach((item) => {
      item.select(false)
    })
    // 将原来的 sampleboxContainer 选择状态置空
    this.store.sampleboxContainer.items.forEach((item) => {
      item.select(false)
    })
    // 赋上新的 sampleboxContainer
    this.store.sampleboxContainer.items = this.sampleboxContainer
    const items = this.sampleboxContainer
    if (items && items.length && items[0].type) {
      this.store.sampleboxContainer.type = items[0].type
    }
    this.store.sampleboxContainer.loaded = true
    this.store.strawboxContainer.items = []
  }

  updateSelectedSamplebox (selectedSampleboxId) {
    // 清除其它 shelf 选中状态
    this.sampleboxContainer.forEach((item) => {
      if (item.id !== selectedSampleboxId) {
        item.select(false)
      }
    })
  }
}
