export default {
  data () {
    return {
      columns: [
        {
          type: 'index',
          title: '序号',
          width: 65,
          align: 'center'
        },
        {
          type: 'selection',
          width: 40,
          align: 'center'
        },
        {
          title: '样本编码',
          key: 'sampleCode',
          width: 150
        },
        {
          title: '样本类型',
          // type: 'imgUrl',
          key: 'sampleType',
          editable: true,
          width: 110,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('tooltip', {
                props: {
                  placement: 'top',
                  content: params.row.sampleType
                }
              }, [
                h('cell-img', {
                  props: {
                    sampleType: params.row.sampleType,
                    iconUrl: params.row.iconUrl
                  }
                })
              ])
            ])
          }
        },
        {
          title: '样本状态',
          key: 'statusText',
          width: 110
          // sortable: true
        },
        {
          title: '入库时间',
          key: 'storeTime',
          width: 160
          // sortable: true,
          // toggle: true,
          // toggleShow: true
        },
        {
          title: '患者姓名',
          key: 'patientName',
          editable: true,
          width: 100
          // toggle: true,
          // toggleShow: true
        },
        {
          title: '患者唯一号',
          key: 'patientUniqueCode',
          width: 120
          // toggle: true,
          // toggleShow: false
        },
        // {
        //   title: '随访周次',
        //   key: 'followupTimes',
        //   width: 100,
        //   toggle: true,
        //   toggleShow: true
        // },
        // {
        //   title: '样本规格',
        //   key: 'sampleSpec',
        //   width: 100,
        //   toggle: true,
        //   toggleShow: true
        // },
        // {
        //   title: '冻融次数',
        //   key: 'freezeThrawTimes',
        //   width: 110,
        //   sortable: true,
        //   toggle: true,
        //   toggleShow: true
        // },
        {
          title: '疾病诊断',
          key: 'diagnose',
          width: 150
          // toggle: true,
          // toggleShow: false
        },
        {
          title: '位置编码',
          key: 'siteCode',
          width: 150
        },
        {
          title: '操作',
          key: 'action',
          width: 280,
          fixed: 'right',
          align: 'center',
          render: (h, params) => {
            let children = [
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small',
                  emphasis: 'emphasis'
                },
                style: {
                  marginLeft: '5px'
                },
                nativeOn: {
                  click: () => { this.edit(params.row._idx) }
                }
              }, '编辑'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginLeft: '5px'
                },
                nativeOn: {
                  click: () => { this.delete(params.row._idx) }
                }
              }, '删除'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginLeft: '5px'
                },
                nativeOn: {
                  click: () => { this.slidUp(params.row._idx) }
                }
              }, '前移'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginLeft: '5px'
                },
                nativeOn: {
                  click: () => { this.slidDown(params.row._idx) }
                }
              }, '后移'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small',
                  emphasis: 'emphasis'
                },
                style: {
                  marginLeft: '5px'
                },
                attrs: {
                  title: '该位置后面插入'
                },
                nativeOn: {
                  click: () => { this.insertAfter(params.row._idx) }
                }
              }, '插入')
            ]
            return h('div', {
              style: {
                textAlign: 'left'
              }
            }, children)
          }
        }
      ]
    }
  }
}
