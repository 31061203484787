import { loggedIn } from '../../utils/auth'

const state = {
  loggedIn: loggedIn(),
  username: localStorage.getItem('nickname') || ''
}
const mutations = {
  LOGIN (state) {
    state.loggedIn = true
  },
  LOGOUT (state) {
    state.loggedIn = false
  },
  UPDATE_USERNAME (state, name) {
    localStorage.setItem('nickname', name)
    state.username = name
  }
}

export default {
  state,
  mutations
}
