import objectAssign from '../../../../utils/merge'
import Layer from './layer'
let shelfid = 200

export default class Shelf {
  constructor (options) {
    this.id = ++shelfid
    this._type = 'shelf'
    this.type = 'fridge'
    this.exboxType = ''
    this.label = ''
    this.level = 1 // 立式冰箱层、卧式冰箱排、液氮罐区中的排，下标1开始
    this.section = 1 // 液氮罐区中的区，下标1开始
    this.enable = true
    this.capacity = 100
    this.layerContainer = []
    this.loaded = false
    this.selected = false
    this.store = null
    this.parent = null
    this.$container = null
    this.loadFn = null

    for (let name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name]
      }
    }
    if (this.store) {
      this.$container = this.store.$container
      this.loadFn = this.store.loadFn
    }
    if (this.children) {
      this.setChildren(this.children)
      this.loaded = true
    }
  }

  loadlayerContainer (parentId, callback) {
    if (!this.loaded && !this.store.loading) {
      this.store.loading = true
      const resolveSuccess = (items) => {
        this.store.loading = false
        this.setChildren(items)
        this.loaded = true

        if (typeof callback === 'function') {
          callback.call(this)
        }
        this.$container.$emit('on-layer-load-success')
      }
      const resolveFaild = () => {
        this.store.loading = false
      }
      if (typeof this.loadFn === 'function') {
        this.loadFn.call(this.$container, this, resolveSuccess, resolveFaild, 'layerContainer', parentId)
      }
    }
  }

  setChildren (items) {
    this.layerContainer = items.map((item) => {
      return new Layer(objectAssign(item, {store: this.store, parent: this}))
    })
    this.store.registContainer(this.layerContainer)
  }

  select (isSelect, reSelect) {
    if (!this.enable) return
    if (!this.selected && isSelect) {
      this.selected = true
      this.parent.updateSelectedShelf(this.id)
      if (!this.loaded) {
        this.loadlayerContainer(this.id, () => {
          this.updateCurrentLayerContainer()
          this.$container.$emit('on-shelf-code-selected', this)
        })
      } else {
        this.updateCurrentLayerContainer()
        this.$container.$emit('on-shelf-code-selected', this)
      }
      this.store.updateContainerPath(this)
      this.$container.$emit('on-path-change')
    } else if (this.selected && !isSelect) {
      this.selected = false
    } else if (this.selected && isSelect && reSelect) {
      this.updateCurrentLayerContainer()
      this.store.updateContainerPath(this)
      this.$container.$emit('on-shelf-code-selected', this)
      this.$container.$emit('on-path-change')
    }
  }

  updateCurrentLayerContainer () {
    this.store.strawboxContainer.items.forEach((item) => {
      item.select(false)
    })
    this.store.sampleboxContainer.items.forEach((item) => {
      item.select(false)
    })
    this.store.layerContainer.items.forEach((item) => {
      item.select(false)
    })

    this.store.layerContainer.items = this.layerContainer
    const items = this.layerContainer
    if (items && items.length && items[0].type) {
      this.store.layerContainer.type = items[0].type
    }
    this.store.layerContainer.loaded = true
    this.store.sampleboxContainer.items = []
    this.store.strawboxContainer.items = []
  }

  updateSelectedLayer (selectedLayerId) {
    // 清除其它 shelf 选中状态
    this.layerContainer.forEach((item) => {
      if (item.id !== selectedLayerId) {
        item.select(false)
      }
    })
  }
}
