<template>
  <modal class="type-edit-win" v-model="showModal" title="关联样本自定义字段" width="400"  @on-ok="onConfirm">
    <div class="field-list">
      <div class="field-item" v-for="item in customFields" :key="item.id">
        <label class="checkbox-wrapper" :for="item.id">
          <span class="checkbox" :class="{'checkbox-checked': customFieldList.indexOf(item.id)>=0}">
            <span class="checkbox-inner"></span>
            <input type="checkbox" class="checkbox-input" :value="item.id" :id="item.id" v-model="customFieldList">
          </span>
        </label>
        <span class="node-content-label field">{{ item.title }}</span>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import { authenticate, catchExpired } from '../../../utils/auth'

export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    projectId: String,
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      customFields: [], // all sample custom field list
      customFieldList: this.getCustomFieldList(this.value) // [id, id, ...]
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    value (val) {
      console.log(val)
      this.customFieldList = this.getCustomFieldList(val)
    },
    projectId (val) {
      if (val) {
        this.loadCustomFieldList()
      }
    }
  },
  mounted () {
    this.loadCustomFieldList()
  },
  methods: {
    getCustomFieldList (data) {
      let _data = data || {}
      return _data.customFieldList || []
    },
    onConfirm () {
      this.value.customFieldList = this.customFieldList
      this.$emit('on-confirm-ok', JSON.parse(JSON.stringify(this.value)))
    },
    loadCustomFieldList () {
      this.$http.get(`${this.httpRoot}/custom/field/list`, authenticate({
        params: {group: 'sample', projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.customFields = res.fieldList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="less">
.field-list {
  max-height: 400px;
  overflow-y: auto;
}
</style>
