<template>
  <div class="box-header-row" :class="{selected: selected}" @click.stop="onSelect" :style="headerRowStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    boxItemWidth: {
      type: Number,
      default: 50
    },
    boxTopLeftWidth: {
      type: Number,
      default: 20
    }
  },
  data () {
    return {
      selected: false
    }
  },
  computed: {
    headerRowStyle () {
      return {
        lineHeight: this.boxItemWidth + 'px',
        width: this.boxTopLeftWidth + 'px',
        height: this.boxItemWidth + 'px'
      }
    }
  },
  methods: {
    onSelect () {
      if (this.$parent.selectAction === 'single' && !event.shiftKey) {
        // 续选-反选
        // 记录最新选中的row
        if (this.$parent.canSelectRow(this.index) && !this.selected) {
          this.$parent.startRowIndex = this.index
        }
        if (this.$parent.canSelectRow(this.index)) {
          const selected = this.selected
          this.select(!selected)
          this.$parent.selectRow(this.index, !selected, true)
          this.$emit('on-col-selected', this.selected, this.index)
        }
      } else if (this.$parent.selectAction === 'single' && !event.metaKey && !event.ctrlKey && event.shiftKey) {
        // 连选
        this.$parent.selectRowBlock(this.index)
      }
      // if (this.$parent.canSelectRow(this.index)) {
      //   const selected = this.selected
      //   this.select(!selected)
      //   this.$parent.selectRow(this.index, !selected, true)
      //   this.$emit('on-row-selected', this.selected, this.index)
      // }
    },
    select (isSelect) {
      this.selected = isSelect
    }
  }
}
</script>
