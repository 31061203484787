<template>
  <modal v-model="showModal" :title="title" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="groupForm" :model="group" :rules="groupRule" label-width="100px" item-width="250px">
      <form-item label="分组编码" prop="code">
        <y-input v-model="group.code" disabled></y-input>
      </form-item>
      <form-item label="分组名称" prop="name">
        <y-input v-model="group.name"></y-input>
      </form-item>
      <form-item label="首页名称">
        <y-input v-model="group.homepage"></y-input>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YInput from '../../common/input/input'

export default {
  components: { Modal, YForm, FormItem, YInput },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    code: String,
    title: String,
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      group: {
        name: '',
        code: this.code || ''
      },
      groupRule: {
        code: [{required: true, message: '不能为空', trigger: 'blur'}],
        name: [{required: true, message: '不能为空', trigger: 'blur'}]
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    code (val) {
      if (val) {
        this.group.code = val
      }
    },
    value (val) {
      if (val) {
        this.group = val
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.groupForm.validate()
      if (!this.$refs.groupForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.group)
    }
  }
}
</script>

<style lang="css">
</style>
