<template>
  <div class="field-tree-node">
    <div class="field-tree-node__content"
      :style="{ 'padding-left': node.level * $tree.indent + (node.level > 0 && node.isLeaf ? 20 : 0) + 'px' }">
      <span class="field-tree-node__expand-icon ionic-icon ionic-icon-arrow-right-b"
        :class="{ 'is-leaf': node.isLeaf, 'expanded': !node.isLeaf && node.expanded }"
        @click.stop="onToggleNodeExpand">
      </span>
      <node-content ref="nodeContent"
        :node="node"
        @on-node-check-change="onNodeCheckChange"></node-content>
    </div>
    <collapse-transition>
      <div class="field-tree-node__children" v-show="node.expanded">
        <tree-node v-for="child in node.childNodes"
          :node="child"
          :props="props"
          :key="getKeyField(child)">
        </tree-node>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import CollapseTransition from '../../../transitions/collapse-transition'
import NodeContent from './node-content'
import mixin from './mixin'

export default {
  name: 'tree-node',
  mixins: [mixin],
  components: {CollapseTransition, NodeContent},
  props: {
    node: {
      default () {
        return {}
      }
    },
    props: {}
  },
  data () {
    return {
      $tree: null
    }
  },
  created () {
    this.$tree = this.$parent.$tree
  },
  methods: {
    onToggleNodeExpand () {
      if (this.node) {
        this.node.toggleExpand()
      }
    },
    onNodeCheckChange (status) {
      this.node.check(status)
      this.$tree.$emit('on-node-check-change', this.node, status)
    }
  }
}
</script>

<style lang="css">
</style>
