<template>
  <div class="column-toggle">
    <ul>
      <li v-for="item in columns" :key="item.key">
        <input type="checkbox" :id="item.key" :value="item.key" v-model="toggleSelected"/>
        <label :for="item.key">{{ item.title }}</label>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      toggleSelected: []
    }
  },
  watch: {
    columns (val) {
      if (val) {
        this.initToggleSelected()
      }
    },
    toggleSelected (val, oldVal) {
      let isAdd = false
      if (val.length > oldVal.length) {
        isAdd = true
      }
      if (oldVal && oldVal.length > 0 && JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.$emit('on-toggle-column-change', val, isAdd)
      }
    }
  },
  created () {
    this.initToggleSelected()
  },
  methods: {
    initToggleSelected () {
      let toggleColumns = this.columns || []
      this.toggleSelected = []
      toggleColumns.forEach(col => {
        if (col._toggleShow) {
          this.toggleSelected.push(col.key)
        }
      })
    }
  }
}
</script>

<style lang="less">
.column-toggle {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  ul {
    list-style: none;
  }
  li {
    height: 22px;
    line-height: 22px;
    padding: 0 10px 0 8px;
    input {
      outline: 0;
    }
    &:hover {
      background-color: rgba(92, 173, 255, 0.2);
    }
    input {
      vertical-align: middle;
    }
    label {
      vertical-align: middle;
    }
  }
  background: #fff;
  box-shadow: 0 0 5px rgba(50,50,50,0.35);
  padding: 5px 0;
}
</style>
