<template>
  <div class="panel-field">
    <!-- <div class="map-edit-title">patientDataset 编辑</div> -->
    <y-form ref="itemForm" :model="item" :rules="itemRule" label-width="150px" item-width="80%">
      <div class="dataset-form-row-2">
        <form-item label="名称" prop="name">
          <y-input v-model="item.name"></y-input>
        </form-item>
        <form-item label="tableName" prop="tableName">
          <y-select v-model="item.tableName" :data="tablenameList" @on-select-change="onTableChange"></y-select>
        </form-item>
      </div>
      <div class="dataset-form-row-2">
        <form-item label="projectId" prop="projectId">
          <y-input v-model="item.projectId"></y-input>
        </form-item>
        <form-item label="collection" prop="collection">
          <y-input v-model="item.collection"></y-input>
        </form-item>
      </div>
      <div class="dataset-form-row-2">
        <form-item label="pkField" prop="pkField">
          <y-input v-model="item.pkField" :data="colList"></y-input>
        </form-item>
        <form-item label="reportDateField">
          <y-input v-model="item.reportDateField" :data="colList"></y-input>
        </form-item>
      </div>
      <div class="dataset-form-row-2">
        <form-item label="uniqueCodeField" prop="uniqueCodeField">
          <y-input v-model="item.uniqueCodeField" :data="colList"></y-input>
        </form-item>
        <form-item label="clinicNoField" prop="clinicNoField">
          <y-input v-model="item.clinicNoField" :data="colList"></y-input>
        </form-item>
      </div>
      <div class="dataset-form-row-2">
        <form-item label="codeType">
          <y-input v-model="item.codeType"></y-input>
        </form-item>
        <form-item label="nameField">
          <y-input v-model="item.nameField" :data="colList"></y-input>
        </form-item>
      </div>
      <form-item label="fieldMap">
        <keyval-list v-model="item.fieldMap" valtype="object"></keyval-list>
      </form-item>
      <form-item label="customFields">
        <keytitle-list v-model="item.customFields" valtype="array"></keytitle-list>
      </form-item>
    </y-form>
    <div class="map-edit-save">
      <y-button type="primary" @click.native="onSaveClick">更新</y-button>
    </div>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import YButton from '../../common/button/button'
import Emitter from '../../common/mixins/emitter'
import KeyvalList from './keyval-list.vue'
import KeytitleList from './keytitle-list.vue'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: { YForm, FormItem, YSelect, YInput, YButton, KeyvalList, KeytitleList },
  mixins: [Emitter],
  props: {
    data: {
      type: Object,
      default () { return {} }
    },
    tablenameList: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      item: {
        name: '',
        type: '',
        fieldMap: {}
      },
      itemRule: {
        name: [{required: true, message: '不能为空', trigger: 'blur'}]
      },
      colList: []
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.item = val
      }
    }
  },
  methods: {
    onTableChange (val) {
      if (val) {
        this.currentTablename = val
        this.loadColumnList()
      }
    },
    loadColumnList () {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/rds/table/column/list`, {tablename: this.currentTablename}, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          this.colList = res.columnList
          if (!this.colList.length) {
            this.$Message.warning('colList: []')
          } else {
            this.$Message.success(`colList: [${this.colList.length}]`)
          }
        } else {
          this.$parent.connStatus = false
          this.$Modal.error({
            title: 'colList 加载报错',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
      })
    },
    onSaveClick () {
      this.dispatch('DataExtract', 'on-pdataset-save', JSON.parse(JSON.stringify(this.item)))
    }
  }
}
</script>

<style lang="css">
.panel-field {
  padding-top: 20px;
}
.map-edit-title {
  font-size: 14px;
  height: 38px;
  line-height: 38px;
  text-align: center;
}
.map-edit-save {
  padding-left: 100px;
  margin-bottom: 10px;
}
</style>
