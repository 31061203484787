<template>
  <div class="input-wrapper" :class="{[`input-wrapper-${size}`]: !!this.size,
                'input-type': type, 'input-group': prepend || append,
                [`input-group-${size}`]: (prepend || append) && !!size}">
    <template v-if="type !== 'textarea'">
      <div class="input-group-prepend" v-if="prepend" v-show="slotReady" ref="prepend">
        <slot name="prepend"></slot>
      </div>
      <i class="ionic-icon input-icon" :class="['ionic-icon-' + icon]" v-if="icon" @click="handleIconClick"></i>
      <transition name="fade">
        <i class="ionic-icon ionic-icon-load-c load-loop input-icon input-icon-validate" v-if="!icon"></i>
      </transition>
      <input
        :type="type"
        ref="input"
        class="input"
        :class="{[`input-${size}`]: !!size, 'input-disabled': disabled}"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        :readonly="readonly"
        :name="name"
        :value="currentValue"
        :number="number"
        @keyup.enter="handleEnter"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
        @change="handleChange"/>
      <div class="input-group-append" v-if="append" v-show="slotReady" ref="append">
        <slot name="append"></slot>
      </div>
    </template>
    <textarea
      v-else
      ref="textarea"
      :class="['input', {'input-disabled': disabled}]"
      :style="textareaStyles"
      :placeholder="placeholder"
      :disabled="disabled"
      :rows="rows"
      :maxlength="maxlength"
      :readonly="readonly"
      :name="name"
      :value="value"
      @keyup.enter="handleEnter"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput">
    </textarea>
  </div>
</template>

<script>
import { oneOf } from '../../../utils/assist'
import calcTextareaHeight from '../../../utils/calcTextareaHeight'
import Emitter from '../mixins/emitter'

export default {
  name: 'Input',
  mixins: [ Emitter ],
  props: {
    type: {
      validator (value) {
        return oneOf(value, ['text', 'textarea', 'password'])
      },
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    size: {
      validator (value) {
        return oneOf(value, ['small', 'large'])
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxlength: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    icon: String,
    autosize: {
      type: [Boolean, Object],
      default: false
    },
    rows: {
      type: Number,
      default: 2
    },
    readonly: {
      type: Boolean,
      default: false
    },
    name: String,
    number: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentValue: this.value,
      prepend: true,
      append: true,
      slotReady: false,
      textareaStyles: {}
    }
  },
  watch: {
    value (val) {
      this.setCurrentValue(val)
    }
  },
  mounted () {
    if (this.type !== 'textarea') {
      this.prepend = this.$slots.prepend !== undefined
      this.append = this.$slots.append !== undefined
    } else {
      this.prepend = false
      this.append = false
    }
    this.slotReady = true
    this.resizeTextarea()
  },
  methods: {
    handleEnter () {
      this.$emit('on-enter', this.currentValue)
    },
    handleIconClick () {
      this.$emit('on-click')
    },
    handleFocus () {
      this.$emit('on-focus', this)
    },
    handleBlur () {
      this.$emit('on-blur')
      this.dispatch('FormItem', 'on-form-blur', this.currentValue)
    },
    handleInput (event) {
      const value = event.target.value
      this.$emit('input', value)
      this.setCurrentValue(value)
      this.$emit('on-change', event)
    },
    handleChange (event) {
      this.$emit('on-input-change', this.currentValue)
    },
    setCurrentValue (value) {
      if (value === this.currentValue) return
      this.$nextTick(() => {
        this.resizeTextarea()
      })
      this.currentValue = value
      this.dispatch('FormItem', 'on-form-change', value)
    },
    forceSetValue (val) {
      this.currentValue = val
    },
    resizeTextarea () {
      const autosize = this.autosize
      if (!autosize || this.type !== 'textarea') {
        return false
      }

      const minRows = autosize.minRows
      const maxRows = autosize.maxRows

      this.textareaStyles = calcTextareaHeight(this.$refs.textarea, minRows, maxRows)
    },
    focus () {
      if (this.$refs.input) {
        this.$refs.input.focus()
      } else if (this.$refs.textarea) {
        this.$refs.textarea.focus()
      }
    },
    blur () {
      if (this.$refs.input) {
        this.$refs.input.blur()
      } else if (this.$refs.textarea) {
        this.$refs.textarea.blur()
      }
    }
  }
}
</script>
