<template>
  <li class="select-group-wrap" v-show="!hidden">
    <div class="select-group-title">{{ label }}</div>
    <ul>
      <li class="select-group" ref="options">
        <slot></slot>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'OptionGroup',
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      hidden: false    // for search
    }
  },
  mounted () {
    this.$on('on-query-change', () => {
      this.queryChange()
      return true
    })
  },
  methods: {
    queryChange () {
      this.$nextTick(() => {
        const options = this.$refs.options.querySelectorAll('.select-item')
        let hasVisibleOption = false
        for (let i = 0; i < options.length; i++) {
          if (options[i].style.display !== 'none') {
            hasVisibleOption = true
            break
          }
        }
        this.hidden = !hasVisibleOption
      })
    }
  }
}
</script>
