<template>
  <modal v-model="showModal" :title="containerName+'编辑'" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="containerForm" :model="container" :rules="containerRule" label-width="100px" item-width="250px">
      <form-item label="位置编码">
        <y-input v-model="container.siteCode" disabled></y-input>
      </form-item>
      <form-item :label="containerName+'标签'" prop="label">
        <y-input v-model="container.label"></y-input>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../modal/modal.vue'
import YForm from '../../form/form'
import FormItem from '../../form/form-item'
import YInput from '../../input/input'

export default {
  components: { Modal, YForm, FormItem, YInput },
  props: {
    containerType: {
      type: String,
      default: 'freezer'
    },
    show: {
      type: Boolean,
      default: false
    },
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      container: Object.assign({}, {label: ''}, this.value),
      containerRule: {
        label: [{required: true, max: 15, message: '标签不能为空, 长度小于15', trigger: 'blur'}]
      }
    }
  },
  computed: {
    containerName () {
      if (this.containerType === 'freezer') {
        return '容器'
      } else if (this.containerType === 'shelf') {
        return '冻存架'
      } else if (this.containerType === 'layer') {
        return '抽屉'
      } else if (this.containerType === 'samplebox') {
        return '样本盒'
      } else if (this.containerType === 'strawbox') {
        return '麦管盒'
      } else {
        return '容器'
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      } else {
        this.container = Object.assign({}, {label: ''}, this.value)
      }
    },
    value (val) {
      if (val) {
        this.container = Object.assign({}, val)
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.containerForm.validate()
      if (!this.$refs.containerForm.isValid) return false
      return true
    },
    onConfirm () {
      if (this.value) {
        this.value.label = this.container.label
      }
      this.$emit('on-confirm-ok', this.container)
    }
  }
}
</script>

<style lang="css">
</style>
