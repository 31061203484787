<template>
  <div class="sys-sampletype">
    <div class="type-list">
      <template v-for="(item, index) in userTypeList">
        <sample-type-item
          :item="item"
          :key="index"
          @on-edit="onUserTypeEdit"
          @on-delete="onDeleteUserType"
          @on-custom-field-edit="onCustomFieldEdit"></sample-type-item>
      </template>
    </div>
    <div class="type-add">
      <!-- <y-button @click.native="onCustomTypeAdd">添加自定义样本类型</y-button> -->
      <y-button @click.native="onUserTypeAdd">添加样本类型</y-button>
      <y-button @click.native="onSampleTypeUpdate">替换样本类型图标</y-button>
    </div>
    <div class="sys-type-setting" @click.stop="onCustomTypeSetting">
      <span class="ionic-icon ionic-icon-gear-b"></span>
    </div>
    <sample-type-edit-win
      v-model="userType"
      :projectId="projectId"
      :show="showEditWin"
      @on-hide="showEditWin=false"
      @on-confirm-ok="onEditUserType"></sample-type-edit-win>
    <sample-type-add-win
      ref="sampleTypeAddWin"
      :projectId="projectId"
      :show="showAddWin"
      @on-hide="showAddWin=false"
      @on-confirm-ok="onAddUserType"></sample-type-add-win>
    <sample-type-update-win
      ref="sampleTypeUpdateWin"
      :show="showSampleTypeUpdateWin"
      @on-hide="showSampleTypeUpdateWin=false"
      @on-confirm-ok="onUpdateSampleType"></sample-type-update-win>
    <!-- <custom-sample-type-win
      :show="showCustomWin"
      @on-hide="showCustomWin=false"
      @on-confirm-ok="onAddCustomType"></custom-sample-type-win> -->
    <custom-field-win
      v-model="userType"
      :projectId="projectId"
      :show="showCustomFieldEditWin"
      @on-hide="showCustomFieldEditWin=false"
      @on-confirm-ok="onCustomFieldUpdate"></custom-field-win>
    <sample-type-custom ref="sysSampleType" v-show="showCustomTypeSetting" @on-back="showCustomTypeSetting=false" @on-sys-type-update="onCumstomTypeUpdate"></sample-type-custom>
  </div>
</template>

<script>
import YButton from '../common/button/button'
import SampleTypeItem from './sampleTypeItem'
import SampleTypeCustom from './sampleTypeCustom'
import SampleTypeEditWin from './win/sampleType-edit-win'
import SampleTypeAddWin from './win/sampleType-add-win'
import SampleTypeUpdateWin from './win/sampleType-update-win'
// import CustomSampleTypeWin from './win/custom-sampleType-win'
import CustomFieldWin from './win/custom-field-win'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {YButton, SampleTypeItem, SampleTypeCustom, SampleTypeEditWin, SampleTypeAddWin, SampleTypeUpdateWin, CustomFieldWin},
  data () {
    return {
      userType: {},
      userTypeList: [],
      showAddWin: false,
      showEditWin: false,
      // showCustomWin: false,
      showCustomFieldEditWin: false,
      showSampleTypeUpdateWin: false,
      showCustomTypeSetting: false
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    }
  },
  watch: {
    projectId (val) {
      if (val) {
        this.loadUserTypeList()
        this.$refs.sysSampleType.loadTypeList()
      }
    }
  },
  mounted () {
    this.loadUserTypeList()
  },
  methods: {
    onCustomTypeSetting () {
      this.showCustomTypeSetting = true
    },
    // onCustomTypeAdd () {
    //   this.showCustomWin = true
    // },
    onUserTypeAdd () {
      this.showAddWin = true
    },
    onSampleTypeUpdate () {
      this.showSampleTypeUpdateWin = true
    },
    onCustomFieldEdit (type) {
      this.userType = type
      this.showCustomFieldEditWin = true
    },
    onUserTypeEdit (type) {
      this.userType = type
      this.showEditWin = true
    },
    hasAdd (item) {
      let find = false
      this.userTypeList.forEach(type => {
        if (type.code === item.code) {
          find = true
        }
      })
      return find
    },
    onDeleteUserType (userType) {
      this.deleteUserType(userType, () => {
        this.userTypeList.forEach((type, index) => {
          if (type.code === userType.code) {
            this.userTypeList.splice(index, 1)
          }
        })
      })
    },
    onEditUserType (userType) {
      this.updateUserType(userType, () => {
        this.userTypeList.forEach((type, index) => {
          if (type.code === this.userType.code) {
            this.$set(this.userTypeList, index, userType)
          }
        })
      })
    },
    onCustomFieldUpdate (userType) {
      this.updateUserType(userType, () => {
        this.userTypeList.forEach((type, index) => {
          if (type.code === this.userType.code) {
            this.$set(this.userTypeList, index, userType)
          }
        })
      })
    },
    // onAddCustomType (customType) {
    //   // this.$set(customType, 'code', this.getNewTypeCode())
    //   // this.addUserType(customType, () => {
    //   //   this.userTypeList.push(customType)
    //   // })
    //   this.addSampleType(customType, () => {
    //     this.$refs.sampleTypeAddWin.loadTypeList()
    //   })
    // },
    onUpdateSampleType () {
      // 图标更换后，重新加载
      this.loadUserTypeList()
      this.$refs.sampleTypeAddWin.loadTypeList()
      this.$refs.sampleTypeUpdateWin.loadTypeList()
    },
    onAddUserType (userType) {
      if (this.hasAdd(userType)) {
        this.$Message.warning('样本类型已经存在')
        return
      }
      this.addUserType(userType, (id) => {
        userType.id = id
        this.userTypeList.push(userType)
      })
    },
    loadUserTypeList () {
      this.$http.get(`${this.httpRoot}/sample/user/type/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.userTypeList = res.sampleTypeList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    // addSampleType (data, callback) {
    //   this.$http.post(`${this.httpRoot}/sample/type/add`, {
    //     projectId: this.projectId,
    //     sampleType: data
    //   }, authenticate())
    //   .then(res => res.json())
    //   .then(res => {
    //     if (res.status === 'success') {
    //       this.$Message.success('添加样本类型成功')
    //       if (typeof callback === 'function') {
    //         callback()
    //       }
    //     } else {
    //       this.$Message.error(res.statusText)
    //     }
    //   })
    //   .catch(err => {
    //     catchExpired(err, this)
    //     this.$Message.error('添加样本类型失败')
    //   })
    // },
    addUserType (data, callback) {
      this.$http.post(`${this.httpRoot}/sample/user/type/add`, {
        projectId: this.projectId,
        sampleType: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('添加样本类型成功')
          if (typeof callback === 'function') {
            callback(res.id || '')
          }
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('添加样本类型失败')
      })
    },
    deleteUserType (data, callback) {
      this.$http.post(`${this.httpRoot}/sample/user/type/delete`, {
        projectId: this.projectId,
        id: data.id
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('删除样本类型成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    updateUserType (data, callback) {
      this.$http.post(`${this.httpRoot}/sample/user/type/update`, {
        projectId: this.projectId,
        sampleType: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('更新样本类型成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onCumstomTypeUpdate () {
      // 系统样本类型更新了
      this.$refs.sampleTypeAddWin.loadTypeList()
    }
  }
}
</script>

<style lang="less">
.sys-sampletype {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
.type-list {
  &:after {
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}
.type-item {
  cursor: pointer;
  position: relative;
  font-size: 16px;
  min-width: 200px;
  float: left;
  &:after {
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
  &:hover {
    .ionic-icon {
      display: inline-block;
    }
  }
  .icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .title {
    height: 50px;
    line-height: 50px;
    display: inline-block;
    vertical-align: top;
    background: #fff;
    padding: 0;
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .code {
    height: 50px;
    line-height: 50px;
    display: inline-block;
    vertical-align: top;
    color: #888;
  }
  .ionic-icon {
    position: relative;
    color: #888;
    height: 50px;
    line-height: 50px;
    margin: 0 5px;
    display: none;
    &:hover {
      color: #3a90e4;
    }
  }
  .edit {
    position: absolute;
    right: 5px;
    top: 0;
  }
}
.type-add {
  margin-top: 15px;
}
.sys-type-setting {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  .ionic-icon {
    font-size: 24px;
    color: #888;
    &:hover {
      color: #336699;
    }
  }
}
</style>
