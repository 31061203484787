<template>
  <div class="crftable-brief-page">
    <div class="settings-list head">
      <div class="settings-item">
        <span class="index">序号</span>
        <span class="title">
          <span>CRF名称</span>
          <span class="ionic-icon" :class="[sortStatus.title==='desc'?'ionic-icon-arrow-down-b': 'ionic-icon-arrow-up-b']"
            @click.stop="sort('title')">
          </span>
        </span>
        <span class="collection">
          <span>集合名称</span>
          <span class="ionic-icon" :class="[sortStatus.collection==='desc'?'ionic-icon-arrow-down-b': 'ionic-icon-arrow-up-b']"
            @click.stop="sort('collection')">
          </span>
        </span>
        <span class="modelid">
          <span>modelId</span>
          <span class="ionic-icon" :class="[sortStatus.modelId==='desc'?'ionic-icon-arrow-down-b': 'ionic-icon-arrow-up-b']"
            @click.stop="sort('modelId')">
          </span>
        </span>
        <span class="projectid">
          <span>项目编码</span>
          <span class="ionic-icon" :class="[sortStatus.projectId==='desc'?'ionic-icon-arrow-down-b': 'ionic-icon-arrow-up-b']"
            @click.stop="sort('projectId')">
          </span>
        </span>
        <span class="projectname">
          <span>项目名称</span>
          <span class="ionic-icon" :class="[sortStatus.projectName==='desc'?'ionic-icon-arrow-down-b': 'ionic-icon-arrow-up-b']"
            @click.stop="sort('projectName')">
          </span>
        </span>
        <span class="shared">
          <span>是否共享</span>
          <span class="ionic-icon" :class="[sortStatus.shared==='desc'?'ionic-icon-arrow-down-b': 'ionic-icon-arrow-up-b']"
            @click.stop="sort('shared')">
          </span>
        </span>
        <span class="shared">
          <span>是否锁定</span>
          <span class="ionic-icon" :class="[sortStatus.locked==='desc'?'ionic-icon-arrow-down-b': 'ionic-icon-arrow-up-b']"
            @click.stop="sort('locked')">
          </span>
        </span>
      </div>
    </div>
    <div class="settings-list body" :style="{overflow: 'auto', height: tableHeight + 'px'}">
      <div class="settings-item" v-for="(item, index) in collectionList" :key="index">
        <span class="index">{{ index + 1 }}</span>
        <span class="title">{{ item.title }}</span>
        <span class="collection">{{ item.collection }}</span>
        <span class="modelid">{{ item.modelId }}</span>
        <span class="projectid">{{ item.projectId }}</span>
        <span class="projectname">{{ item.projectName }}</span>
        <span class="shared">
          <input type="checkbox" v-model="item.shared" disabled>
        </span>
        <span class="locked">
          <input type="checkbox" v-model="item.locked" disabled>
        </span>
      </div>
    </div>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>

<script>
import Loading from '../common/loading/loading'
import { authenticate, catchExpired } from '../../utils/auth'

export default {
  components: { Loading },
  data () {
    return {
      showLoading: false,
      collectionList: [],
      sortStatus: {
        title: 'desc', // 'asc'
        collection: 'desc',
        modelId: 'desc',
        projectId: 'desc',
        projectName: 'desc',
        shared: 'desc',
        locked: 'desc'
      }
    }
  },
  computed: {
    tableHeight () {
      return this.$store.getters.getWinHeight - 130
    }
  },
  mounted () {
    this.loadCrfTableCollectionList()
  },
  methods: {
    loadCrfTableCollectionList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/crf/crfTable/collection/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.collectionList = res.collectionList || []
          this.showLoading = false
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    sort (column) {
      if (this.sortStatus[column] === 'desc') {
        this.sortStatus[column] = 'asc'
        this.collectionList.sort((ia, ib) => {
          let a = ia[column]
          let b = ib[column]
          if (column === 'shared') {
            if (a && !b) return -1
            if (!a && b) return 1
            return 0
          } else {
            if (a > b) return -1
            if (a < b) return 1
            return 0
          }
        })
      } else {
        this.sortStatus[column] = 'desc'
        this.collectionList.sort((ia, ib) => {
          let a = ia[column]
          let b = ib[column]
          if (column === 'shared') {
            if (a && !b) return 1
            if (!a && b) return -1
            return 0
          } else {
            if (a > b) return 1
            if (a < b) return -1
            return 0
          }
        })
      }
    }
  }
}
</script>

<style lang="less">
.crftable-brief-page {
  padding: 15px;
  width: 100%;
}
.settings-tool {
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
}
.settings-list {
  &.head {
    background: #ebeef1;
    border: 1px solid #b6c3d0;;
    border-bottom: 0;
    font-size: 14px;
  }
  &.body {
    font-size: 13px;
    border: 1px solid #b6c3d0;;
    border-top: 0;
  }
}
.settings-item {
  height: 30px;
  line-height: 30px;
  position: relative;
  &:nth-child(odd) {
    &:hover {
      background-color: #dce5f9;
    }
  }
  &:nth-child(even) {
    &:hover {
      background-color: #f3f3f3;
    }
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e2e7f1;
  }
  &>.index {
    display: inline-block;
    overflow: hidden;
    width: 4%;
    text-align: center;
    white-space: nowrap;
    float: left;
  }
  &>.title {
    display: inline-block;
    overflow: hidden;
    width: 18%;
    white-space: nowrap;
    float: left;
  }
  &>.collection {
    display: inline-block;
    overflow: hidden;
    width: 18%;
    white-space: nowrap;
    float: left;
  }
  &>.modelid {
    display: inline-block;
    overflow: hidden;
    width: 14%;
    white-space: nowrap;
    float: left;
  }
  &>.projectid {
    display: inline-block;
    overflow: hidden;
    width: 14%;
    white-space: nowrap;
    float: left;
  }
  &>.projectname {
    display: inline-block;
    overflow: hidden;
    width: 16%;
    white-space: nowrap;
    float: left;
  }
  &>.shared {
    display: inline-block;
    overflow: hidden;
    text-align: center;
    width: 8%;
    white-space: nowrap;
    float: left;
  }
  &>.locked {
    display: inline-block;
    overflow: hidden;
    text-align: center;
    width: 8%;
    white-space: nowrap;
    float: left;
  }
}
</style>
