<template>
  <div class="rds-pg">
    <div class="r-left">
      <div class="r-db-panel">
        <div v-if="connStatus" class="conn-db">{{`${connDB.driverName}: ${connDB.datasourceName}`}}</div>
        <div class="status-tip">
          <span>{{connStatus ? '已连接' : '未连接'}}</span>
          <span v-if="connStatus" class="reconn fa fa-unlink" @click.stop="reConnDB" title="重新连接"></span>
        </div>
        <div v-if="!connStatus" class="btn" @click.stop="onShowDBConnWin">数据库连接</div>
      </div>
      <div class="r-table-panel">
        <div class="r-table-head" :class="{active: naviView==='table'}" @click.stop="naviView='table'">
          <div class="navi-label">数据库表查询</div>
          <div class="search-icon fa fa-search" @click.stop="onTableSearch" title="查询数据库表"></div>
        </div>
        <div class="r-table-list" :style="{height: tableListHeight+'px'}" @click.stop="onTableClick">
          <div class="r-table-item" :class="{active: currentTablename===item}" v-for="item in tablenameList" :key="item" :data-name="item">
            <span class="fa fa-table"></span>
            <span>{{item}}</span>
          </div>
        </div>
      </div>
      <div class="r-sql-navi" :class="{active: naviView==='sql'}">
        <div class="navi-label" @click.stop="naviView='sql'">sql单独查询</div>
      </div>
    </div>
    <div class="r-right" v-if="naviView==='table'">
      <div class="r-query-panel">
        <textarea class="sql" v-model="sql" placeholder="请输入sql查询语句"></textarea>
      </div>
      <div class="r-data-panel">
        <div class="actions">
          <div class="at-item">
            <span class="at-label">skip</span>
            <input class="input sl-num" :value="skip" @change="onSkipChange"/>
          </div>
          <div class="at-item">
            <span class="at-label">limit</span>
            <input class="input sl-num" :value="limit" @change="onLimitChange"/>
          </div>
          <div class="at-item at-btn-left">
            <span class="at-label"></span>
            <y-button type="primary" :disabled="!currentTablename || !sql" @click.native.stop="onSearch">查 询</y-button>
            <y-button type="primary" :disabled="!currentTablename || !sql || skip <= 0" @click.native.stop="onPrePage">上一页</y-button>
            <y-button type="primary" :disabled="!currentTablename || !sql" @click.native.stop="onNextPage">下一页</y-button>
            <y-button :disabled="!currentTablename" @click.native.stop="onCount">Count</y-button>
            <y-button type="primary" :disabled="!currentTablename" @click.native.stop="onShowFilter">过滤</y-button>
            <y-button :disabled="!currentTablename" @click.native.stop="onShowDatasetFilter">dataset过滤</y-button>
          </div>
          <div class="at-deset-item">
            <span class="at-deset-name" v-for="item in selectedDatasets" :key="item.id">{{item.name}}</span>
          </div>
        </div>
        <sql-table ref="table" :name="currentTablename" :indexStart="skip" :width="tableWidth" :height="tableHeight" @on-action="onSqlTableAction" @on-sort="onSort"></sql-table>
      </div>
    </div>
    <div class="r-right" v-if="naviView==='sql'">
      <div class="r-query-panel">
        <textarea class="sql" v-model="sql" placeholder="请输入sql语句"></textarea>
      </div>
      <div class="r-data-panel">
        <div class="actions">
          <div class="at-item at-btn-left">
            <span class="at-btn"></span>
            <y-button type="primary" :disabled="!sql" @click.native.stop="onSqlSearch">执行 sql</y-button>
          </div>
        </div>
        <textarea class="input input-textarea" placeholder="sql执行结果json" rows="30" cols="400" ref="inputText"></textarea>
      </div>
    </div>
    <conn-win :show="showConnWin" @on-hide="showConnWin=false" @on-confirm-ok="onDBConn"></conn-win>
    <filter-win :show="showFilterWin" :name="currentTablename" :cols="columnList" @on-hide="showFilterWin=false" @on-confirm-ok="onFiler"></filter-win>
    <dataset-win :show="showDatasetFilterWin" @on-hide="showDatasetFilterWin=false" @on-confirm-ok="onDatasetFielter"></dataset-win>
    <edit-win :show="showEditWin" @on-hide="showEditWin=false" :columns="columnList" :data="editData" @on-ok="onDataEdit"></edit-win>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>
<script>
import Loading from '../common/loading/loading'
import YInput from '../common/input/input.vue'
import YButton from '../common/button/button.vue'
import SqlTable from '../common/table/sql-table.vue'
import ConnWin from './win/conn-win.vue'
import FilterWin from './win/filter-win.vue'
import DatasetWin from './win/dataset-win.vue'
import EditWin from '../mongo/edit-win.vue'
import { authenticate, catchExpired } from '../../utils/auth'

export default {
  components: {Loading, YInput, YButton, SqlTable, ConnWin, FilterWin, DatasetWin, EditWin},
  data () {
    return {
      showLoading: false,
      connDB: {driverName: this.$store.state.db.dbName, datasourceName: this.$store.state.db.dbSource},
      connStatus: this.$store.state.db.conn,
      showConnWin: false,
      tablenameList: [],
      columnList: [{ title: '#', key: '#', type: 'index', width: 80 }],
      dataList: [],
      sortKey: '',
      sortType: '',
      sql: '',
      skip: 0,
      limit: 200,
      total: 0,
      currentTablename: '',
      hasLimit: true,
      tableWidth: this.$store.state.win.winWidth - 300,
      tableHeight: this.$store.state.win.winHeight - 60 - 200 - 68 - 40 - 2,
      tableListHeight: this.$store.state.win.winHeight - 60 - 54 - 44 - 32,

      showFilterWin: false,
      showDatasetFilterWin: false,

      filterData: [],
      selectedDatasets: [],
      datasetIds: [],

      naviView: 'table',  // 'sql'
      editData: {},
      showEditWin: false
    }
  },
  watch: {
    skip (val) {
      this.skip = Number(val)
    }
  },
  mounted () {
    if (this.connStatus) {
      this.loadTableList()
    }
  },
  methods: {
    onShowDBConnWin () {
      this.showConnWin = true
    },
    onTableClick (e) {
      this.naviView = 'table'
      const path = []
      let el = e.target
      path.push(el)
      while (el !== e.currentTarget) {
        el = el.parentElement
        path.push(el)
      }
      let name = ''
      for (const el of path) {
        if (el.dataset.name) {
          name = el.dataset.name
        }
      }
      if (name) {
        this.$nextTick(() => {
          this.currentTablename = name
          this.skip = 0
          this.limit = 200
          this.sortKey = ''
          this.sortType = ''
          Promise.resolve().then(() => {
            return this.loadColumnList()
          }).then((columnList) => {
            this.sql = this.getSql()
            this.searchTable()
          }).catch(err => {
            console.log('promise', err)
          })
        })
      }
    },
    onSearch () {
      // sql 手动输入
      this.hasLimit = true
      this.searchTable()
    },
    onSqlSearch () {
      this.searchSql()
    },
    onSkipChange (e) {
      this.hasLimit = true
      this.skip = Number(e.target.value)
      this.sql = this.getSql('filter&rowKey', this.filterData, this.selectedDatasets)
    },
    onLimitChange (e) {
      this.hasLimit = true
      this.limit = Number(e.target.value)
      this.sql = this.getSql('filter&rowKey', this.filterData, this.selectedDatasets)
    },
    onPrePage () {
      this.hasLimit = true
      if (this.skip > 0) {
        this.skip -= this.limit
      }
      if (this.skip < 0) {
        this.skip = 0
      }
      this.sql = this.getSql('filter&rowKey', this.filterData, this.selectedDatasets)
      this.searchTable()
    },
    onNextPage () {
      this.hasLimit = true
      this.skip += this.limit
      this.sql = this.getSql('filter&rowKey', this.filterData, this.selectedDatasets)
      this.searchTable()
    },
    onSort (key, type) {
      this.sortKey = key
      this.sortType = type
      console.log(key, type)
      this.sql = this.getSql('sort', this.filterData, this.selectedDatasets)
      this.searchTable()
    },
    onDBConn (data) {
      this.connDB = data
      if (this.connDB && this.connDB.driverName && this.connDB.datasourceName) {
        this.$store.dispatch('updateDB', {dbName: this.connDB.driverName, dbSource: this.connDB.datasourceName})
        this.dbConn(this.connDB)
      }
    },
    reConnDB () {
      if (this.connDB && this.connDB.driverName && this.connDB.datasourceName) {
        this.$store.dispatch('updateDB', {dbName: this.connDB.driverName, dbSource: this.connDB.datasourceName})
        this.dbConn(this.connDB)
      }
    },
    onTableSearch () {
      this.loadTableList()
    },
    onCount () {
      this.columnList = [
        { title: '#', key: '#', type: 'index', width: 80 },
        { title: 'count(*)', key: 'count(*)', width: 200 }
      ]
      this.sql = this.getSql('count')
      this.searchTable()
    },
    onShowFilter () {
      this.showFilterWin = true
    },
    onFiler (data = []) {
      // this.hasLimit = false
      this.limit = 1000
      this.filterData = data
      this.sql = this.getSql('filter&rowKey', data, this.selectedDatasets)
      // this.datasetIds = this.getDatasetIds()
      this.searchTable()
    },
    onShowDatasetFilter () {
      this.showDatasetFilterWin = true
    },
    onDatasetFielter (datasets = []) {
      // this.hasLimit = false
      this.limit = 1000
      this.selectedDatasets = datasets
      this.sql = this.getSql('filter&rowKey', this.filterData, datasets)
      // this.datasetIds = this.getDatasetIds()
      this.searchTable()
    },
    getDatasetIds () {
      const ids = []
      this.selectedDatasets.forEach(item => {
        ids.push(item.id)
      })
      return ids
    },
    dbConn (data) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/rds/database/connect`, data, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          this.currentTablename = ''
          this.connStatus = true
          this.$store.dispatch('updateDBConn', true)
          this.loadTableList()
        } else {
          this.connStatus = false
          this.$Modal.error({
            title: 'rds 数据库连接失败',
            content: `<p>${res.status} ${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadTableList () {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/rds/table/list`, {}, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          this.tablenameList = res.tablenameList || []
        } else {
          this.connStatus = false
          this.$Modal.error({
            title: 'rds 数据库连接失败',
            content: `<p>${res.status} ${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadColumnList () {
      this.showLoading = true
      return this.$http.post(`${this.httpRoot}/rds/table/column/list`, {tablename: this.currentTablename}, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          this.columnList = this.setTableColumns(res.columnList)
          this.$refs.table.setShowColumns(this.columnList)
        } else {
          this.connStatus = false
        }
        return this.columnList
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    setTableColumns (colList = []) {
      const re = [{
        title: '#',
        key: '#',
        type: 'index',
        checked: true,
        width: 80
      }, {
        title: '操作',
        key: 'action',
        align: 'center',
        checked: true,
        width: 80,
        render: (data, col) => {
          return `<span data-action="look">详情</span>`
        }
      }]
      colList.forEach(item => {
        re.push({
          title: item.name,
          key: item.name,
          dataType: item.type,
          sortType: '',
          checked: true,
          width: 150,
          render: (h, params) => { return h('div', {class: 'r-nowrap', attrs: {title: params.row[item.name]}}, params.row[item.name]) },
          sortable: 'server'
        })
      })
      return re
    },
    onSqlTableAction (action, data) {
      if (action === 'look') {
        this.editData = data
        this.showEditWin = true
      }
    },
    onDataEdit (data) {
      //
    },
    searchSql () {
      if (!this.sql) {
        return
      }
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/rds/sql/excute`, {sql: this.sql}, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          const data = res.result || []
          try {
            this.$refs.inputText.value = JSON.stringify(data, null, 4)
          } catch (err) {
            this.$Modal.error({
              title: '结果解析失败',
              content: `<p>${err.message}</p>`
            })
          }
        } else {
          this.$Modal.error({
            title: '查询失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
      })
    },
    searchTable () {
      if (!this.currentTablename) {
        this.$Message.warning('请选择数据库表')
        return
      }
      if (!this.sql) {
        this.$Message.warning('请填写sql')
        return
      }
      const query = {
        sql: this.sql,
        sort: this.sortKey,
        skip: this.skip,
        limit: this.limit,
        datasetIds: this.datasetIds
      }
      this.showLoading = true
      return this.$http.post(`${this.httpRoot}/rds/sql/query`, query, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          // this.dataList = res.dataList || []
          // window.sqlData = res.dataList
          this.$refs.table.setData(res.dataList)
        } else {
          this.$Modal.error({
            title: '查询失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
      })
    },
    getSqlFilters (filters = []) {
      let cond = ''
      let cnt = 0
      filters.forEach(item => {
        if (item.check) {
          cond += (cnt > 0 ? item.bool : '') + ` ${item.col}${item.rel}'${item.val.trim()}' `
          cnt += 1
        }
      })
      return cond
    },
    getSqlDatasets (datasets = []) {
      let cond = ''
      let cnt = 0
      const rowKeyMapList = []
      datasets.forEach(deset => {
        let ins = []
        const fieldMapping = deset.fieldMapping || []
        fieldMapping.forEach(map => {
          if (map.rowKey) {
            const rowKeys = map.rowKey.split('|')
            rowKeys.forEach(item => {
              const key = item.trim()
              if (key) {
                ins.push(key)
              }
            })
          }
        })
        ins = ins.map(item => `'${item}'`)
        if (deset.rowToCol && deset.rowToCol.keyField) {
          rowKeyMapList.push({keyField: deset.rowToCol.keyField, rowKeys: ins})
        }
      })
      // 相同keyField 合并并去重
      const keyFieldMap = {}
      rowKeyMapList.forEach(map => {
        if (!keyFieldMap[map.keyField]) {
          keyFieldMap[map.keyField] = Array.from(new Set(map.rowKeys))
        } else {
          map.rowKeys.forEach(key => {
            if (!keyFieldMap[map.keyField].includes(key)) {
              keyFieldMap[map.keyField].push(key)
            }
          })
        }
      })
      for (const keyField in keyFieldMap) {
        if (keyFieldMap[keyField].length) {
          const inQ = `${keyField} in (${keyFieldMap[keyField].join(', ')})`
          cond += `${cnt > 0 ? ' or ' : ''}` + inQ
          cnt += 1
        }
      }
      return cond
    },
    getSqlNoSort (filters, datasets) {
      let sql = `select * from ${this.currentTablename}`
      let cond = this.getSqlFilters(filters)
      let condIn = this.getSqlDatasets(datasets)
      if (cond) {
        sql += ` where ${cond}`
      }
      if (cond && condIn) {
        sql += ` and (${condIn})`
      }
      if (!cond && condIn) {
        sql += ` where ${condIn}`
      }
      return sql
    },
    getSql (type, filters, datasets) {
      if (type === 'count') {
        return `select count(*) from ${this.currentTablename}`
      } else if (type === 'filter' && filters) {
        let sql = `select * from ${this.currentTablename}`
        let cond = this.getSqlFilters(filters)
        if (cond) {
          sql += ` where ${cond}`
        }
        return `${sql}${this.hasLimit ? ` limit ${this.skip}, ${this.limit}` : ''}`
      } else if (type === 'filter&rowKey') {
        let sql = this.getSqlNoSort(filters, datasets)
        return `${sql}${this.hasLimit ? ` limit ${this.skip}, ${this.limit}` : ''}`
      } else if (type === 'sort' && this.sortKey && this.sortType) {
        let sql = this.getSqlNoSort(filters, datasets)
        if (this.sortType === 'normal') {
          return `${sql}${this.hasLimit ? ` limit ${this.skip}, ${this.limit}` : ''}`
        } else {
          return `${sql} order by ${this.sortKey} ${this.sortType}${this.hasLimit ? ` limit ${this.skip}, ${this.limit}` : ''}`
        }
      } else {
        return `select * from ${this.currentTablename}${this.hasLimit ? ` limit ${this.skip}, ${this.limit}` : ''}`
      }
    }
  }
}
</script>
<style lang="less">
.rds-pg {
  display: flex;
  height: 100%;
}
.r-left {
  width: 300px;
  height: 100%;
  flex: 0 0 auto;
  border-right: 1px solid #7d818a;
}
.r-right {
  flex: 1 1 auto;
}
.r-db-panel {
  padding: 15px;
  border-bottom: 1px solid #7d818a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  .status-tip {
    margin: 0 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  .reconn {
    padding-left: 10px;
    cursor: pointer;
  }
}
.conn-db {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
}
.r-table-head {
  height: 32px;
  line-height: 32px;
  background: #dddfe3;
  display: flex;
  justify-content: space-between;
  .search-icon {
    cursor: pointer;
    font-size: 16px;
    padding: 8px 10px;
    margin: 0 6px;
  }
  &.active {
    background-color: #91aae1;
  }
}
.r-table-list {
  overflow-y: auto;
}
.r-table-item {
  padding-left: 15px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  &:hover {
    background-color: #b6c1d8;
    color: #fff;
  }
  &.active {
    background-color: #9faac2;
    color: #fff;
  }
}
.r-query-panel {
  width: 100%;
  height: 200px;
  border-bottom: 1px solid #7d818a;
  .sql {
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid #e4edff;
    padding-top: 4px;
    padding-left: 4px;
  }
}
.r-data-panel {
  .actions {
    display: flex;
    padding-top: 10px;
    padding-bottom: 16px;
  }
}
.at-item {
  display: flex;
  align-items: center;
}
.at-btn {
  width: 20px;
}
.at-label {
  width: 60px;
  text-align: right;
  padding-right: 6px;
}
.at-deset-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.at-deset-name {
  font-size: 13px;
  font-weight: 600;
  padding: 0 6px;
}
.at-btn-left > button{
  margin-right: 10px;
}
.sl-num {
  width: 100px;
}
.r-nowrap {
  white-space: nowrap;
  overflow: hidden;
}
.r-sql-navi {
  background-color: #ccc;
  height: 32px;
  line-height: 32px;
  &.active {
    background-color: #91aae1;
  }
}
.navi-label {
  padding-left: 16px;
  text-align: left;
  cursor: pointer;
}
</style>
