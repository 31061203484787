<template>
  <div class="param-list">
    <div class="param-item" v-for="(item, index) in currentValue" :key="index">
      <div class="line">
        <span class="param-label">单位:</span>
        <input class="param-val-3" :value="item.unit" @change="onUnitChange(index, $event)">
        <span class="param-label">低值:</span>
        <input class="param-val-3" :value="item.rmin" @change="onRminChange(index, $event)">
        <span class="param-label">高值:</span>
        <input class="param-val-3" :value="item.rmax" @change="onRmaxChange(index, $event)">
        <span class="ionic-icon ionic-icon-close-round" @click="remove(index)"></span>
      </div>
      <div class="line">
        <span class="param-label null"></span>
        <span class="param-val-3 null"></span>
        <span class="param-label">描述:</span>
        <input class="param-val-3" :value="item.rmindesc" @change="onRminDescChange(index, $event)">
        <span class="param-label">描述:</span>
        <input class="param-val-3" :value="item.rmaxdesc" @change="onRmaxDescChange(index, $event)">
      </div>
    </div>
    <div class="param-add">
      <button type="button" @click.stop="addItem">添加</button>
    </div>
  </div>
</template>

<script>
import Emitter from '../../mixins/emitter'
export default {
  mixins: [Emitter],
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      currentValue: this.value
    }
  },
  watch: {
    value (val, oldVal) {
      if (val && JSON.stringify(oldVal) !== JSON.stringify(val)) {
        this.currentValue = val
      }
    },
    currentValue (val) {
      let result = val
      this.$emit('input', result)
      this.dispatch('FormItem', 'on-form-change', result)
      this.$emit('on-change', result)
    }
  },
  methods: {
    onUnitChange (index, e) {
      let val = {
        unit: e.target.value,
        rmin: this.currentValue[index] ? this.currentValue[index].rmin : '',
        rmax: this.currentValue[index] ? this.currentValue[index].rmax : '',
        rmindesc: this.currentValue[index] ? this.currentValue[index].rmindesc : '',
        rmaxdesc: this.currentValue[index] ? this.currentValue[index].rmaxdesc : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onRminChange (index, e) {
      let val = {
        unit: this.currentValue[index] ? this.currentValue[index].unit : '',
        rmin: e.target.value,
        rmax: this.currentValue[index] ? this.currentValue[index].rmax : '',
        rmindesc: this.currentValue[index] ? this.currentValue[index].rmindesc : '',
        rmaxdesc: this.currentValue[index] ? this.currentValue[index].rmaxdesc : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onRmaxChange (index, e) {
      let val = {
        unit: this.currentValue[index] ? this.currentValue[index].unit : '',
        rmin: this.currentValue[index] ? this.currentValue[index].rmin : '',
        rmax: e.target.value,
        rmindesc: this.currentValue[index] ? this.currentValue[index].rmindesc : '',
        rmaxdesc: this.currentValue[index] ? this.currentValue[index].rmaxdesc : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onRminDescChange (index, e) {
      let val = {
        unit: this.currentValue[index] ? this.currentValue[index].unit : '',
        rmin: this.currentValue[index] ? this.currentValue[index].rmin : '',
        rmax: this.currentValue[index] ? this.currentValue[index].rmax : '',
        rmindesc: e.target.value,
        rmaxdesc: this.currentValue[index] ? this.currentValue[index].rmaxdesc : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onRmaxDescChange (index, e) {
      let val = {
        unit: this.currentValue[index] ? this.currentValue[index].unit : '',
        rmin: this.currentValue[index] ? this.currentValue[index].rmin : '',
        rmax: this.currentValue[index] ? this.currentValue[index].rmax : '',
        rmindesc: this.currentValue[index] ? this.currentValue[index].rmindesc : '',
        rmaxdesc: e.target.value
      }
      this.$set(this.currentValue, index, val)
    },
    remove (index) {
      this.currentValue.splice(index, 1)
    },
    addItem () {
      this.currentValue.push({unit: '', rmin: '', rmax: '', rmindesc: '', rmaxdesc: ''})
    }
  }
}
</script>

<style lang="less">
.param-item {
  margin-bottom: 10px;
  .line {
    height: 28px;
    line-height: 28px;
    display: flex;
  }
  .param-label {
    width: 10%;
    &.null {
      display: inline-block;
      width: 39px;
    }
  }
  .param-val-3 {
    width: 20%;
    height: 100%;
    padding-left: 7px;
    outline: 0;
    border: 0;
    border-bottom: 1px solid #888;
    &.null {
      display: inline-block;
      border-bottom: 0;
    }
  }
  .ionic-icon {
    margin: 0 5px;
    cursor: pointer;
    &:hover {
      color: #3399ff;
    }
  }
}
.param-add {
  height: 28px;
  line-height: 28px;
  button {
    cursor: pointer;
    font-size: 12px;
    outline: 0;
    padding: 1px 7px;
    background: #fff;
    border: 1px solid #888;
    border-radius: 2px;
  }
}
</style>
