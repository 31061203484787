const state = {
  freezerList: [],
  containers: null,
  cachedContainers: [],
  selectedContainer: {
    id: '',
    type: '',
    _type: '',
    rootId: '',
    siteCode: ''
  }
}
const mutations = {
  UPDATE_FREEZERLIST (state, freezerList) {
    state.freezerList = freezerList
  },
  UPDATE_CONTAINERS (state, containers) {
    state.containers = containers
  },
  UPDATE_CACHED_CONTAINERS (state, {containers, item}) {
    state.cachedContainers = containers
    state.selectedContainer = item
  },
  // for manage add 2020.6.24
  CLEAR_CONTAINER_CACHE (state) {
    state.freezerList = []
    state.containers = null
    state.cachedContainers = []
    state.selectedContainer = {
      id: '',
      type: '',
      _type: '',
      rootId: '',
      siteCode: ''
    }
  }
}

export default {
  state,
  mutations
}
