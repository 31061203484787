import Node from './fieldTree-node'

export default class TreeStore {
  constructor (options) {
    this.key = ''
    this.title = ''
    this.data = null
    this.props = {}

    this.currentNode = null

    for (let option in options) {
      if (options.hasOwnProperty(option)) {
        this[option] = options[option]
      }
    }

    this.nodesMap = {}

    this.rootNode = new Node({
      type: 'root',
      data: {
        key: this.key || 'root',
        title: this.title,
        value: this.title,
        fields: JSON.parse(JSON.stringify(this.data))
      },
      store: this
    })
  }

  registerNode (node) {
    if (!node || !node.data) return
    if (node.key) this.nodesMap[node.key] = node
  }

  deregisterNode (node) {
    if (!node || !node.data) return
    delete this.nodesMap[node.key]
  }

  getNodeByKey (key) {
    return this.nodesMap[key]
  }

  focus (status, deep) {
    this.rootNode.focus(status, deep)
  }

  getFieldsData () {
    return this.rootNode.data.fields || []
  }

  replicateFields () {
    const newModelId = 'q_' + new Date().getTime().toString().slice(1, 10)
    const newFields = []
    const fields = this.rootNode.data.fields || []
    fields.forEach(field => {
      newFields.push(this.copyData(field, 'field', newModelId))
    })
    return {
      modelId: newModelId,
      fieldList: newFields
    }
  }

  copyData (item, type, parentKey) {
    const data = JSON.parse(JSON.stringify(item))
    if (type === 'field') {
      this.updateDataKey(data, parentKey)
      // update items
      if (data.items) delete data.items
      let items = item.items || []
      items.forEach(child => {
        data.items = data.items || []
        if (child.type === 'group') {
          data.items.push(this.copyData(child, 'group', data.key))
        } else {
          data.items.push(this.copyData(child, 'item', data.key))
        }
      })
    } else if (type === 'item' || type === 'gitem') {
      this.updateDataKey(data, parentKey)
      // update children or subChildren
      if (data.children) delete data.children
      if (data.subChildren) delete data.subChildren
      let children = item.children || []
      let subChildren = item.subChildren || []
      children.forEach(child => {
        data.children = data.children || []
        data.children.push(this.copyData(child, 'child', data.key))
      })
      subChildren.forEach(child => {
        data.subChildren = data.subChildren || []
        data.subChildren.push(this.copyData(child, 'subChild', data.key))
      })
    } else if (type === 'group') {
      // update key // no data key for group
      // update group
      if (data.group) delete data.group
      let group = item.group || []
      group.forEach(child => {
        data.group = data.group || []
        data.group.push(this.copyData(child, 'gitem', parentKey))
      })
    } else if (type === 'child' || type === 'subChild') {
      this.updateDataKey(data, parentKey)
    }

    return data
  }

  updateDataKey (data, parentKey) {
    // update key
    if (parentKey) {
      // copyData from this parent node
      const newKeyPrefix = parentKey.slice(2, parentKey.length)
      const sourceKeyPrefix = data.key.slice(2, parentKey.length)
      data.key = data.key.replace(sourceKeyPrefix, newKeyPrefix)
    }
  }
}
