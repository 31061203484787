<template>
  <div class="field-tree" :style="{overflow: 'auto', height: pageHeight + 'px'}">
    <field-tree-node
      :node="rootNode"
      :props="props"
      :key="getKeyField(rootNode)">
    </field-tree-node>
  </div>
</template>

<script>
import TreeStore from './model/fieldTree-store'
import FieldTreeNode from './field-tree-node'
import mixin from './mixin'

export default {
  components: {FieldTreeNode},
  mixins: [mixin],
  props: {
    title: String,
    value: Object,
    data: Array,
    modelId: String,
    props: {
      default () {
        return {
          fields: 'fields',
          items: 'items',
          group: 'group',
          children: 'children',
          label: 'name',
          value: 'value',
          type: 'type',
          key: 'value',
          icon: 'icon'
        }
      }
    },
    indent: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      $tree: null,
      $currentTreeNode: null, // for update node.data.groupCodeList
      treeStore: null,
      rootNode: null
    }
  },
  computed: {
    pageHeight () {
      return Math.min(this.$store.getters.getWinHeight - 100, this.height)
    }
  },
  watch: {
    data (val) {
      this.treeStore = new TreeStore({
        key: this.modelId,
        title: this.title,
        data: val,
        props: this.props
      })
      this.rootNode = this.treeStore.rootNode
      this.updateCheckStatus(this.value)
    },
    value (val) {
      this.updateCheckStatus(val)
    }
  },
  created () {
    this.$tree = this
    this.treeStore = new TreeStore({
      key: this.modelId,
      title: this.title,
      data: this.data,
      props: this.props
    })
    this.rootNode = this.treeStore.rootNode
    this.updateCheckStatus(this.value)
    this.$on('on-node-check-change', (node, status) => {
      if (node.type === 'root' && !status) {
        this.treeStore.unCheckAllNodes()
      } else if (node.type === 'root' && status) {
        this.treeStore.checkAllNodes()
      } else if ((node.type === 'child' || node.type === 'item') && status) {
        node.checkParent(true, true)
      } else if ((node.type === 'child' || node.type === 'item') && !status) {
        node.check(false)
        // 子节点清空，清除父节点
        // if (node.parent.isLeafChecked()) {
        //   node.parent.check(false)
        // }
      } else if (node.type === 'field' && status) {
        if (node.isLeafChecked()) {
          node.checkChild(true, true)
        }
      } else if (node.type === 'field' && !status) {
        node.checkChild(false, true)
      }
    })
    this.$on('on-node-click', (node) => {
      this.$emit('on-tree-node-click', node.label, node.getGroupCodeList())
    })
  },
  methods: {
    getCheckedData () {
      return this.treeStore.getCheckedData()
    },
    getGroupCodes () {
      return this.treeStore.getGroupCodes()
    },
    setCurrentTreeNodeGroupCodeList (groupCodeList) {
      if (this.$currentTreeNode) {
        this.$currentTreeNode.setGroupCodeList(groupCodeList)
      } else {
        this.$Message.error('未选中菜单树节点', 2)
      }
    },
    updateCheckStatus (val) {
      if (this.treeStore) {
        this.treeStore.unCheckAllNodes()
      }
      if (typeof val === 'object' && !Array.isArray(val) && this.treeStore) {
        Object.keys(val || {}).forEach(prop => {
          let headMenu = this.treeStore.getNodeByKey(prop)
          if (headMenu) {
            headMenu.check(true)
          }
          let secondMenus = val[prop] || []
          secondMenus.forEach(menu => {
            let secondMenu = this.treeStore.getNodeByKey(prop + '-' + menu)
            if (secondMenu) {
              secondMenu.check(true)
            }
          })
        })
      }
    }
  }
}
</script>

<style lang="css">
</style>
