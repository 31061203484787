<template>
  <div class="container_manager" :class="[containerAlign]">
    <div class="left" :style="{width: containerWidth+'px', height: containerHeight+'px'}" v-if="containerAlign === 'left'">
      <scroll-y :width="containerWidth" :height="containerHeight">
        <container ref="container" :async="async" :data="freezerList" :storeContainers="storeContainers"></container>
      </scroll-y>
      <loading v-model="showContainerLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)">{{loadingText}}</loading>
    </div>
    <div class="right" :style="{width: panelWidth+'px', height: containerHeight+'px'}">
      <div class="container_path">
        <div class="container_path-item" v-for="(item, index) in containerPath" :key="index">
          <span class="label" @click.stop="onPathSelect(item, index, containerPath.length)">
            <span>{{item.label}}</span>
          </span>
          <span v-if="index < containerPath.length - 1" class="split">
            <span class="ionic-icon ionic-icon-ios-arrow-forward"></span>
          </span>
        </div>
      </div>
      <template v-if="currentView==='defaultPanel'">
        <default-panel ref="defaultPanel" :containerAlign="containerAlign"></default-panel>
      </template>
      <template v-if="currentView==='freezerPanel'">
        <freezer-panel :parentId="parentId" :rootId="rootId" :parentCode="parentCode" :freezerType="freezerType" :section="nitrogenSection"></freezer-panel>
      </template>
      <template v-if="currentView==='shelfPanel'">
        <shelf-panel :parentId="parentId" :rootId="rootId" :parentCode="parentCode"></shelf-panel>
      </template>
      <template v-if="currentView==='layerPanel'">
        <layer-panel :parentId="parentId" :rootId="rootId" :parentCode="parentCode" :containerAlign="containerAlign"></layer-panel>
      </template>
      <template v-if="currentView==='strawboxPanel'">
        <strawbox-panel :parentId="parentId" :rootId="rootId" :parentCode="parentCode" :containerAlign="containerAlign"></strawbox-panel>
      </template>
      <template v-if="currentView==='cardboxPanel'">
        <cardbox-panel :parentId="parentId" :rootId="rootId" :parentCode="parentCode"></cardbox-panel>
      </template>
      <template v-if="currentView==='sampleboxPanelMysamples'">
        <samplebox-panel-mysamples ref="sampleboxPanelMysamples"
          :parentId="parentId" :rootId="rootId" :parentCode="parentCode" :leafboxType="leafboxType" :boxType="boxType" :boxLabel="boxLabel" :actionType="actionType">
        </samplebox-panel-mysamples>
      </template>
      <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)">{{loadingText}}</loading>
    </div>
  </div>
</template>

<script>
import { authenticate, catchExpired } from '../../../utils/auth'
import Loading from '../loading/loading'
import ScrollY from '../scroll/scroll-y'
import Container from './container'
import DefaultPanel from './panels/default-panel'
import FreezerPanel from './panels/freezer-panel'
import ShelfPanel from './panels/shelf-panel'
import LayerPanel from './panels/layer-panel'
import StrawboxPanel from './panels/strawbox-panel'
import SampleboxPanelMysamples from './panels/samplebox-panel-mysamples'
import CardboxPanel from './panels/cardbox-panel'

export default {
  props: {
    containerAlign: {
      type: String,
      default: 'left'
    },
    async: {
      type: Boolean,
      default: false
    },
    storeContainers: {
      type: Boolean,
      default: false
    },
    loadAll: {
      type: Boolean,
      default: false
    },
    freezerId: String,
    projectId: String
  },
  data () {
    return {
      containerWidth: 200,
      containerPath: [],
      freezerList: [],
      currentView: 'defaultPanel',
      parentId: '',
      rootId: '',
      parentCode: '',
      row: undefined,
      col: undefined,
      actionType: undefined,
      freezerType: '',
      nitrogenSection: 0,
      leafboxType: 'samplebox',
      boxType: '',
      boxLabel: '',
      showContainerLoading: false,
      showLoading: false,
      loadingText: '查询中...'
    }
  },
  computed: {
    currentPath () {
      return this.$store.getters.getCurrentPath
    },
    containerHeight () {
      return this.$store.getters.getWinHeight - 60 - 10
    },
    panelWidth () {
      return this.$store.getters.getWinWidth - 20 - this.containerWidth
    }
  },
  watch: {
    freezerId (val) {
      let cachedSelectedFreezerId = ''
      this.$store.state.container.cachedContainers.forEach(freezer => {
        if (freezer.selected) {
          cachedSelectedFreezerId = freezer.id
        }
      })
      if (val && this.$refs.container.containerStore.freezerContainer.loaded &&
          val !== cachedSelectedFreezerId) {
        // 容器管理页面中, 重新选择容器进入
        this.selectFreezerOnLoad(val)
      }
    }
  },
  created () {
    this.$on('on-freezer-selected', (params) => {
      this.updatePanel('freezerPanel', params)
    })
    this.$on('on-shelf-selected', (params) => {
      this.updatePanel('shelfPanel', params)
    })
    this.$on('on-shelf-section-selected', (section) => {
      this.nitrogenSection = section
    })
    this.$on('on-shelf-section-unselected', () => {
      this.nitrogenSection = 0
    })
    this.$on('on-layer-selected', (params) => {
      this.updatePanel('layerPanel', params)
    })
    this.$on('on-samplebox-selected', (params) => {
      if (params.exboxType === 'strawbox') {
        this.updatePanel('strawboxPanel', params)
      } else if (params.exboxType === 'cardbox') {
        this.updatePanel('cardboxPanel', params)
      } else {
        this.updateSampleboxPanel(params)
      }
    })
    this.$on('on-strawbox-selected', (params) => {
      this.updateSampleboxPanel(params)
    })
    this.$on('on-container-load-success', () => {
      if (this.freezerId) {
        this.selectFreezerOnLoad(this.freezerId)
      } else {
        this.updatePanelFromStore()
      }
    })
  },
  mounted () {
    if (this.async) {
      this.loadFreezerList()
    }
  },
  methods: {
    selectContainerById (id) {
      this.$refs.container.selectById(id)
    },
    reSelectContainerById (id) {
      this.$refs.container.reSelectById(id)
    },
    selectChainContainersById (id) {
      this.$refs.container.selectChainContainersById(id)
    },
    reSelectChainContainersById (id) {
      this.$refs.container.reSelectChainContainersById(id)
    },
    updateContainerPathById (id) {
      this.$refs.container.updateContainerPathById(id)
    },
    loadFreezerList () {
      if (this.loadContainersFromStore()) return
      this.showContainerLoading = true
      this.$http.get(`${this.httpRoot}/container/list`,
        authenticate({params: {type: this.loadAll ? 'all' : 'freezerContainer', projectId: this.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.freezerList = res.containerList || []
          this.showContainerLoading = false
          if (this.loadAll) {
            this.$store.dispatch('updateContainers', JSON.parse(JSON.stringify(this.freezerList)))
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadContainersFromStore () {
      const freezers = this.$store.state.container.cachedContainers
      const containers = this.$store.state.container.containers
      if (!this.loadAll && Array.isArray(freezers) && freezers.length && this.storeContainers) {
        this.$refs.container.setContainersFromStore()
        return true
      } else if (this.loadAll && Array.isArray(containers) && containers.length) {
        this.freezerList = containers
        return true
      }
      return false
    },
    updatePanel (view, {parentId, rootId, parentCode, type, leafboxType, label, row, col, actionType}) {
      this.currentView = view
      this.parentId = parentId
      this.rootId = rootId
      this.parentCode = parentCode
      this.row = row
      this.col = col
      this.actionType = actionType
      this.freezerType = type
      this.leafboxType = leafboxType
      this.boxType = type
      this.boxLabel = label
      this.$parent[this.containerAlign] = view
    },
    updatePanelFromStore () {
      const containerType = this.$store.state.container.selectedContainer._type
      const exboxType = this.$store.state.container.selectedContainer.exboxType
      if (containerType === 'freezer') {
        this.updatePanelFromStoreType('freezerPanel')
      } else if (containerType === 'shelf') {
        this.updatePanelFromStoreType('shelfPanel')
      } else if (containerType === 'layer') {
        this.updatePanelFromStoreType('layerPanel')
      } else if (containerType === 'samplebox' && exboxType === 'strawbox') {
        this.updatePanelFromStoreType('strawboxPanel')
      } else if (containerType === 'samplebox' && exboxType === 'cardbox') {
        this.updatePanelFromStoreType('cardboxPanel')
      } else if ((containerType === 'samplebox' || containerType === 'strawbox') && this.currentPath.indexOf('mySamples') >= 0) {
        this.updatePanelFromStoreType('sampleboxPanelMysamples', 'all')
      }
    },
    updatePanelFromStoreType (viewType, actionType = '') {
      this.updatePanel(viewType, {
        rootId: this.$store.state.container.selectedContainer.rootId,
        parentCode: this.$store.state.container.selectedContainer.siteCode,
        parentId: this.$store.state.container.selectedContainer.id,
        leafboxType: this.$store.state.container.selectedContainer._type,
        type: this.$store.state.container.selectedContainer.type,
        label: this.$store.state.container.selectedContainer.label,
        actionType: actionType
      })

      this.reSelectChainContainersById(this.$store.state.container.selectedContainer.id)
    },
    selectFreezerOnLoad () {
      this.$refs.container.selectById(this.freezerId)
      const freezer = this.$refs.container.getFreezerById(this.freezerId) || {}
      this.updatePanel('freezerPanel', {
        parentId: this.freezerId,
        rootId: freezer.rootId,
        parentCode: freezer.siteCode,
        type: freezer.type
      })
    },
    updateSampleboxPanel (params) {
      if (this.currentPath.indexOf('mySamples') >= 0) {
        this.updatePanel('sampleboxPanelMysamples', Object.assign({}, params, {actionType: 'all'}))
      }
    },
    onPathSelect (container, index, pathLen) {
      if (index >= pathLen - 1) return
      if (container._type === 'freezer') {
        this.reSelectContainerById(container.id)
        this.updatePanel('freezerPanel', {
          parentId: container.id,
          rootId: container.rootId,
          parentCode: container.siteCode,
          type: container.type
        })
      } else if (container._type === 'shelf') {
        this.reSelectContainerById(container.id)
        this.updatePanel('shelfPanel', {
          parentId: container.id,
          rootId: container.rootId,
          parentCode: container.siteCode
        })
      } else if (container._type === 'layer') {
        this.reSelectContainerById(container.id)
        this.updatePanel('layerPanel', {
          parentId: container.id,
          rootId: container.rootId,
          parentCode: container.siteCode
        })
      } else if (container._type === 'samplebox' && container.exboxType === 'strawbox') {
        this.reSelectContainerById(container.id)
        this.updatePanel('strawboxPanel', {
          parentId: container.id,
          rootId: container.rootId,
          parentCode: container.siteCode
        })
      } else if (container._type === 'samplebox' && container.exboxType === 'cardbox') {
        this.reSelectContainerById(container.id)
        this.updatePanel('cardboxPanel', {
          parentId: container.id,
          rootId: container.rootId,
          parentCode: container.siteCode
        })
      }
    },
    getPathLabel () {
      let pathLabel = ''
      this.containerPath.forEach(item => {
        if (item.label) {
          pathLabel += `/${item.label}`
        }
      })
      return pathLabel
    }
  },
  components: {
    Loading,
    ScrollY,
    Container,
    DefaultPanel,
    FreezerPanel,
    ShelfPanel,
    LayerPanel,
    StrawboxPanel,
    SampleboxPanelMysamples,
    CardboxPanel
  }
}
</script>

<style lang="less">
.container_manager {
  position: relative;
  padding-left: 10px;
  padding-top: 10px;
  width: 100%;
  background: #f1f1f1;
  // display: flex;
  &.right {
    justify-content: flex-end;
  }
  .left {
    position: relative;
    background: #fff;
  }
  .right {
    position: absolute;
    background: #fff;
    top: 10px;
    right: 0;
    font-size: 14px;
    overflow-y: auto;
  }
  .container_path {
    position: relative;
    line-height: 22px;
    height: 22px;
    font-size: 14px;
    .container_path-item {
      display: inline-block;
      cursor: pointer;
      padding-left: 5px;
      &:hover {
        color: #3399ff;
      }
      &:last-child {
        &:hover {
          cursor: auto;
          color: #2c3e50;
        }
      }
      & > .label {
        max-width: 150px;
        overflow: hidden;
        display: table-cell;
        span {
          white-space: nowrap;
        }
      }
      & > .split {
        padding-left: 5px;
        display: table-cell;
      }
    }
  }
}
</style>
