<template>
  <div>
    <div style="position:fixed;top:0;z-index:6;width:100%;height:60px;">
      <y-header></y-header>
    </div>
    <div class="yxy_container" :style="{height: bodyHeight + 'px'}">
      <router-view></router-view>
    </div>
    <!-- <y-footer></y-footer> -->
  </div>
</template>

<script>
import YHeader from './header/header'
import Emitter from './common/mixins/emitter'
export default {
  name: 'Index',
  mixins: [Emitter],
  computed: {
    bodyHeight () {
      return this.$store.getters.getWinHeight - 60
    }
  },
  components: {
    YHeader
  }
}
</script>
<style lang="less">
.yxy_container {
  margin-top: 60px;
  position: relative;
}
</style>
