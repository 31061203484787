<template>
  <modal class="type-edit-win" v-model="showModal" title="自定义样本类型" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm" :styles="{top: '60px'}">
    <y-form ref="typeForm" :model="type" :rules="typeRule" label-width="100px" item-width="250px">
      <form-item label="样本类型名称" prop="name">
        <y-input v-model="type.name"></y-input>
      </form-item>
      <form-item label="样本编码" prop="code">
        <y-input v-model="type.code"></y-input>
      </form-item>
      <form-item label="上传图片" prop="iconUrl">
        <upload
          :action="`${httpRoot}/file/upload`"
          :headers="headers"
          :maxSize="50"
          :beforeUpload="uploadBefore"
          :onExceededSize="uploadExceeded"
          :onSuccess="uploadSuccess">
          <y-button type="ghost" icon="ios-cloud-upload-outline">上传文件</y-button>
          <span class="img-exceed" v-if="showExceeded">文件大小不能超过50KB</span>
          <span class="img-preview" v-if="showImgPreview" title="预览"><img :src="type.iconUrl"/></span>
        </upload>
        <a class="btn" target="_blank" href="/static/tool/cropper.html">定制样本图标</a>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Upload from '../../common/upload/upload'
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YButton from '../../common/button/button'
import YInput from '../../common/input/input'
import { authenticate } from '../../../utils/auth'
export default {
  components: { Upload, Modal, YForm, FormItem, YButton, YInput },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {}
  },
  data () {
    return {
      headers: authenticate().headers,
      showModal: this.show,
      type: this.value || {},
      tempSampleType: {},
      typeRule: {
        name: [{required: true, message: '不能为空', trigger: 'blur'}],
        code: [{required: true, message: '不能为空', trigger: 'blur'}],
        iconUrl: [{required: true, message: '样本图片不能为空', trigger: 'change'}]
      },

      showExceeded: false,
      showImgPreview: false
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      } else {
        this.$set(this.type, 'code', this.getNewTypeCode())
      }
    },
    value (val) {
      console.log(val)
      this.type = val
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.typeForm.validate()
      if (!this.$refs.typeForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', JSON.parse(JSON.stringify(this.type)))
    },
    uploadSuccess (res) {
      this.type.iconUrl = res.url
      this.showImgPreview = true
    },
    uploadExceeded (file) {
      this.showExceeded = true
    },
    uploadBefore () {
      this.showImgPreview = false
      this.showExceeded = false
    },
    getNewTypeCode () {
      let found = true
      let code = ''
      while (found) {
        code = 'X' + new Date().getTime().toString().slice(8, 10)
        found = false
        this.$parent.typeList.forEach(type => {
          if (type.code === code) {
            found = true
          }
        })
      }
      return code
    }
  }
}
</script>

<style lang="less">
.img-exceed {
  color: #F76260;
}
.img-preview {
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 32px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
</style>
