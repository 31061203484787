<template>
  <modal v-model="showModal" title="添加 item 选项" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="itemForm" :model="item" :rules="itemRule" label-width="100px" item-width="250px">
      <form-item label="唯一编码" prop="key">
        <y-input v-model="item.key" disabled></y-input>
      </form-item>
      <form-item label="组件类型" prop="type">
        <y-select v-model="item.type" :data="typeList"></y-select>
      </form-item>
      <form-item label="显示名称" prop="title">
        <y-input v-model="item.title"></y-input>
      </form-item>
      <form-item label="保存值" prop="value" ref="value" v-if="showValue">
        <y-input v-model="item.value"></y-input>
      </form-item>
      <form-item label="保存值类型" prop="valueType" v-if="showValueType">
        <y-select v-model="item.valueType" :data="valueTypeList"></y-select>
      </form-item>
      <form-item label="单位" v-if="showUnit">
        <y-input v-model="item.unit"></y-input>
      </form-item>
      <form-item label="指标编码" v-if="showIcode">
        <y-input v-model="item.icode"></y-input>
      </form-item>
      <form-item label="参考值范围">
        <y-input v-model="item.ref"></y-input>
      </form-item>
      <form-item label="是否必填" v-if="showUnit">
        <checklist inline v-model="item.required" :items="boolList"></checklist>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import Checklist from '../../common/checklist/checklist'
export default {
  components: { Modal, YForm, FormItem, YSelect, YInput, Checklist },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const validateValue = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'))
      } else if (this.item.valueType === 'number' && isNaN(Number(value))) {
        callback(new Error('保存值必须为数字'))
      } else {
        callback()
      }
    }
    const validateValueType = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'))
      } else if (this.$refs.value) {
        this.$refs.itemForm.validateField('value')
        callback()
      }
    }
    return {
      showModal: this.show,
      item: {
        key: '',
        title: '',
        value: '',
        valueType: '',
        type: '',
        unit: '',
        icode: '',
        ref: '',
        required: '',
        queryable: false,
        queryTitle: '',
        reportable: false,
        reportTitle: ''
        // unitRef: []
        // placeholder: '',
        // relationSymbol: '', // children > 0
        // subRelationSymbol: '', // subChildren.length > 0
        // fn: '', // type: show_score
        // trigger: '', // type: show_score
        // modelId: '', // field.type: sselect_show_quest && type: sselect
        // picker: '', // type: input_inline_picker
        // daterange: {y,m,d}
        // timerange: {h,m,s}
        // desktop: null
      },
      parentType: '',
      typeList: [
        {
          name: '多选',
          children: [
            {name: '多选项(mselect)', value: 'mselect'},
            {name: '多选项_行内输入(mselect_input_inline)', value: 'mselect_input_inline'},
            {name: '多选项_文本输入(mselect_input)', value: 'mselect_input'},
            {name: '多选项_换行多个输入(mselect_input_multi)', value: 'mselect_input_multi'},
            {name: '多选项_单行输入+换行多个输入(mselect_input_multi_self_input)', value: 'mselect_input_multi_self_input'}
          ]
        },
        {
          name: '单选',
          children: [
            {name: '单选项_多行单选(sselect)', value: 'sselect'},
            {name: '单选项_行内单选(sselect_inline)', value: 'sselect_inline'},
            {name: '单选项_输入(sselect_input)', value: 'sselect_input'}
          ]
        },
        {
          name: '输入',
          children: [
            {name: '输入_文本(input)', value: 'input'},
            {name: '输入_单行(input_inline)', value: 'input_inline'},
            {name: '输入_仅数字(input_number)', value: 'input_number'},
            {name: '输入_下拉列表(input_inline_picker)', value: 'input_inline_picker'},
            {name: '输入_日期(input_inline_date)', value: 'input_inline_date'},
            {name: '输入_日期时间(input_inline_datetime)', value: 'input_inline_datetime'},
            {name: '输入_日期长度(input_inline_daterange)', value: 'input_inline_daterange'},
            {name: '输入_时间长度(input_inline_timerange)', value: 'input_inline_timerange'},
            {name: '输入_多行输入(input_multi_line)', value: 'input_multi_line'},
            {name: '输入_行内多输入(input_multi_inline)', value: 'input_multi_inline'},
            {name: '输入_行内单选(input_sselect)', value: 'input_sselect'},
            {name: '输入_多行单选(input_sselect_multi_line)', value: 'input_sselect_multi_line'},
            {name: '输入_单选输入(input_sselect_input)', value: 'input_sselect_input'},
            {name: '输入_多选(input_mselect)', value: 'input_mselect'},
            {name: '输入_多选输入(input_mselect_input)', value: 'input_mselect_input'},
            {name: '输入_上传图片', value: 'input_image'},
            {name: '输入_上传文件', value: 'input_file'},
            {name: '输入_显示分值', value: 'input_show_score'},
            {name: '输入_显示文本描述', value: 'input_show_tip'},
            {name: '输入_单位_参考值', value: 'input_unit_ref'}
          ]
        },
        {
          name: '显示',
          children: [
            {name: '显示分值(show_score)', value: 'show_score'},
            {name: '显示提示文本(show_tip)', value: 'show_tip'}
          ]
        },
        {
          name: '文件',
          children: [
            {name: '影像(image)', value: 'image'},
            {name: '文件(file)', value: 'file'},
            {name: '日期(input_inline_date)', value: 'input_inline_date'},
            {name: '输入_单行(input_inline)', value: 'input_inline'},
            {name: '输入_文本(input)', value: 'input'}
          ]
        }
      ],
      valueTypeList: [
        {name: '字符串', value: 'string'},
        {name: '日期', value: 'date'}, // 后台存储 Date, 返回前台string
        {name: '数值', value: 'number'},
        {name: '整数', value: 'integer'}
      ],
      boolList: [
        {key: '1', title: '是', value: true},
        {key: '0', title: '否', value: false}
      ],
      itemRule: {
        key: [{required: true, message: '不能为空', trigger: 'change'}],
        title: [{required: true, message: '不能为空', trigger: 'blur'}],
        type: [{required: true, message: '不能为空', trigger: 'change'}],
        valueType: [{required: true, trigger: 'change', validator: validateValueType}],
        value: [{required: true, trigger: 'change', validator: validateValue}]
      }
    }
  },
  computed: {
    showUnit () {
      if (this.item && (this.item.type === 'input_inline' ||
          this.item.type === 'input_number' ||
          this.item.type === 'input_inline_picker' ||
          this.item.type === 'input_inline_date' ||
          this.item.type === 'input_inline_datetime' ||
          this.item.type === 'input_inline_daterange' ||
          this.item.type === 'input_inline_timerange' ||
          this.item.type === 'mselect_input_inline' ||
          this.item.type === 'mselect_input_multi_self_input')) {
        return true
      }
      return false
    },
    showIcode () {
      return this.parentType.indexOf('input_table') >= 0
    },
    showValue () {
      return (this.item.type.indexOf('input') === -1 ||
              this.item.type.indexOf('input') > 0 ||
              this.parentType.indexOf('input_table') >= 0) &&
             (this.item.type !== 'input_multi_inline' &&
              this.item.type !== 'input_multi_line' &&
              this.item.type !== 'input_sselect' &&
              this.item.type !== 'input_sselect_multi_line' &&
              this.item.type !== 'input_sselect_input' &&
              this.item.type !== 'input_mselect' &&
              this.item.type !== 'input_mselect_input' &&
              this.item.type !== 'input_image' &&
              this.item.type !== 'input_file' &&
              this.item.type !== 'input_show_tip')
    },
    showValueType () {
      return this.item.type !== 'input_multi_inline' &&
             this.item.type !== 'input_multi_line' &&
             this.item.type !== 'input_sselect' &&
             this.item.type !== 'input_sselect_multi_line' &&
             this.item.type !== 'input_sselect_input' &&
             this.item.type !== 'input_mselect' &&
             this.item.type !== 'input_mselect_input' &&
             this.item.type !== 'image' &&
             this.item.type !== 'file' &&
             this.item.type !== 'input_image' &&
             this.item.type !== 'input_file' &&
             this.item.type !== 'input_show_tip'
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
        this.setKey()
        this.resetParentType()
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    'item.title' (val) {
      if (val && this.showValue) {
        this.item.value = val
      }
      if (!this.showValue) {
        this.item.value = ''
      }
      if (!this.showValueType) {
        this.item.valueType = ''
      }
    },
    'parentType' (val) {
      if (val) {
        this.resetTypeList(val)
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.itemForm.validate()
      if (!this.$refs.itemForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.mergeValues(this.item))
    },
    setKey () {
      const currentNode = this.$parent.$currentTreeNode
      if (!currentNode) return
      this.item.key = currentNode.getNewChildKey()
    },
    resetParentType () {
      if (this.$parent.$currentTreeNode) {
        this.parentType = this.$parent.$currentTreeNode.node.data.type
      }
    },
    resetTypeList (type) {
      if (type && type === 'mselect') {
        this.typeList = [
          {name: '多选项(mselect)', value: 'mselect'}
        ]
      } else if (type && type === 'mselect_input') {
        this.typeList = [
          {name: '多选项(mselect)', value: 'mselect'},
          {name: '多选项_行内输入(mselect_input_inline)', value: 'mselect_input_inline'},
          {name: '多选项_文本输入(mselect_input)', value: 'mselect_input'},
          {name: '多选项_换行多个输入(mselect_input_multi)', value: 'mselect_input_multi'},
          {name: '多选项_单行输入+换行多个输入(mselect_input_multi_self_input)', value: 'mselect_input_multi_self_input'}
        ]
      } else if (type && type === 'sselect') {
        this.typeList = [
          {name: '单选项_多行单选(sselect)', value: 'sselect'},
          {name: '单选项_行内单选(sselect_inline)', value: 'sselect_inline'},
          {name: '单选项_输入(sselect_input)', value: 'sselect_input'}
        ]
      } else if (type && type === 'sselect_inline') {
        this.typeList = [
          {name: '单选项_行内单选(sselect_inline)', value: 'sselect_inline'}
        ]
      } else if (type && type === 'sselect_show_quest') {
        this.typeList = [
          {name: '单选项_行内单选(sselect_inline)', value: 'sselect_inline'}
        ]
      } else if (type && type.indexOf('input') >= 0) {
        this.typeList = [
          {name: '输入_文本(input)', value: 'input'},
          {name: '输入_单行(input_inline)', value: 'input_inline'},
          {name: '输入_仅数字(input_number)', value: 'input_number'},
          {name: '输入_下拉列表(input_inline_picker)', value: 'input_inline_picker'},
          {name: '输入_日期(input_inline_date)', value: 'input_inline_date'},
          {name: '输入_日期时间(input_inline_datetime)', value: 'input_inline_datetime'},
          {name: '输入_日期长度(input_inline_daterange)', value: 'input_inline_daterange'},
          {name: '输入_时间长度(input_inline_timerange)', value: 'input_inline_timerange'},
          {name: '输入_多行输入(input_multi_line)', value: 'input_multi_line'},
          {name: '输入_行内多输入(input_multi_inline)', value: 'input_multi_inline'},
          {name: '输入_行内单选(input_sselect)', value: 'input_sselect'},
          {name: '输入_多行单选(input_sselect_multi_line)', value: 'input_sselect_multi_line'},
          {name: '输入_单选输入(input_sselect_input)', value: 'input_sselect_input'},
          {name: '输入_多选(input_mselect)', value: 'input_mselect'},
          {name: '输入_多选输入(input_mselect_input)', value: 'input_mselect_input'},
          {name: '输入_上传图片', value: 'input_image'},
          {name: '输入_上传文件', value: 'input_file'},
          {name: '输入_显示分值', value: 'input_show_score'},
          {name: '输入_显示文本描述', value: 'input_show_tip'},
          {name: '输入_单位_参考值', value: 'input_unit_ref'}
        ]
      } else if (type && type.indexOf('show') >= 0) {
        this.typeList = [
          {name: '显示分值(show_score)', value: 'show_score'},
          {name: '显示提示文本(show_tip)', value: 'show_tip'}
        ]
      } else if (type && type.indexOf('file') >= 0) {
        this.typeList = [
          {name: '影像(image)', value: 'image'},
          {name: '文件(file)', value: 'file'},
          {name: '日期(input_inline_date)', value: 'input_inline_date'},
          {name: '输入_单行(input_inline)', value: 'input_inline'},
          {name: '输入_文本(input)', value: 'input'}
        ]
      }
    },
    mergeValues (item) {
      let data = Object.assign({}, item)
      if (data.valueType && data.valueType === 'number' && !isNaN(Number(data.value))) {
        data.value = Number(data.value)
      }
      return data
    }
  }
}
</script>

<style lang="css">
</style>
