<template>
  <modal v-model="showModal" title="添加 dataset" width="600" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="itemForm" :model="item" :rules="itemRule" label-width="150px" item-width="400px">
      <form-item label="名称" prop="name">
        <y-input v-model="item.name"></y-input>
      </form-item>
      <form-item label="类型" prop="type">
        <y-input v-model="item.type"></y-input>
      </form-item>
      <form-item label="projectId" prop="projectId">
        <y-input v-model="item.projectId"></y-input>
      </form-item>
      <form-item label="modelId">
        <y-input v-model="item.modelId"></y-input>
      </form-item>
      <form-item label="collection">
        <y-input v-model="item.collection"></y-input>
      </form-item>
      <form-item label="tableName">
        <y-input v-model="item.tableName"></y-input>
      </form-item>
      <form-item label="reportDateField">
        <y-input v-model="item.reportDateField"></y-input>
      </form-item>
      <form-item label="dateFormat">
        <y-input v-model="item.dateFormat"></y-input>
      </form-item>
      <form-item label="patientField">
        <y-input v-model="item.patientField"></y-input>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import KeyvalSelect from '../panel/keyval-select'
export default {
  components: { Modal, YForm, FormItem, YSelect, YInput, KeyvalSelect },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    tablenameList: {
      type: Array,
      default () { return [] }
    },
    crftableList: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      showModal: this.show,
      item: {
        name: '',
        type: 'examItem',
        datasourceId: '',
        datasourceName: '',
        rowToCol: {},
        projectId: this.$store.state.project.projectId,
        modelId: '',
        collection: '',
        tableName: '',
        reportDateField: 'AuthDate',
        dateFormat: '2006-01-02T15:04:05',
        patientField: '',
        updateField: '',
        sorts: [],
        fieldMapping: []
      },
      sqlTypeList: ['mysql', 'sqlserver', 'mongodb', 'oracle'],
      itemRule: {
        name: [{required: true, message: '不能为空', trigger: 'blur'}],
        datasourceId: [{required: true, message: '不能为空', trigger: 'blur'}]
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.itemForm.validate()
      if (!this.$refs.itemForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.mergeValues(this.item))
    },
    mergeValues (item) {
      let data = Object.assign({}, item)
      if (data.dataSource && data.dataSource.port) {
        data.dataSource.port = Number(data.dataSource.port)
      }
      return data
    },
    onFieldmappingDelete (index) {
      if (index > -1) {
        this.item.fieldMapping.splice(index, 1)
      }
    },
    onfieldmapppingadd (val) {
      if (val) {
        this.item.fieldMapping.push(val)
      }
    },
    onDatasourceChange (val) {
      if (val && val.name) {
        this.item.datasourceName = val.name
      }
    }
  }
}
</script>

<style lang="css">
</style>
