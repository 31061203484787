<template>
  <div class="sys-type-custom-global" :style="{height: pageHeight+'px'}">
    <div class="sys-type-title">设置系统所有样本类型</div>
    <div class="type-list">
      <sample-type-item v-for="item in typeList" :key="item.id" :item="item" @on-delete="onDeleteCustomType" belong="sys"></sample-type-item>
    </div>
    <div class="sys-type-action">
      <y-button @click.native="onCustomTypeAdd">添加自定义样本类型</y-button>
    </div>
    <custom-sample-type-win
      :show="showCustomWin"
      @on-hide="showCustomWin=false"
      @on-confirm-ok="onAddCustomType"></custom-sample-type-win>
  </div>
</template>

<script>
import YButton from '../common/button/button'
import SampleTypeItem from './sampleTypeItem'
import CustomSampleTypeWin from './win/custom-sampleType-win'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {YButton, SampleTypeItem, CustomSampleTypeWin},
  data () {
    return {
      typeList: [],
      showCustomWin: false
    }
  },
  computed: {
    pageHeight () {
      return Math.max(this.$store.getters.getWinHeight - 60, 60)
    }
  },
  mounted () {
    this.loadTypeList()
  },
  methods: {
    onCustomTypeAdd () {
      this.showCustomWin = true
    },
    onAddCustomType (customType) {
      this.addSampleType(customType, () => {
        this.loadTypeList()
        this.$emit('on-sys-type-update')
      })
    },
    onDeleteCustomType (data) {
      // this.deleteSampleType(data.id, () => {
      //   this.loadTypeList()
      //   this.$emit('on-sys-type-update')
      // })
    },
    loadTypeList () {
      this.$http.get(`${this.httpRoot}/admin/sample/type/list`, authenticate({params: {
        // projectId: this.projectId
        isSys: true
      }}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.typeList = res.typeList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    addSampleType (data, callback) {
      this.$http.post(`${this.httpRoot}/admin/sample/type/add`, {
        // projectId: this.projectId,
        isSys: true,
        sampleType: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('添加样本类型成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('添加样本类型失败')
      })
    },
    // 服务端没有实现
    deleteSampleType (id, callback) {
      this.$http.post(`${this.httpRoot}/admin/sample/type/delete`, {
        // projectId: this.projectId,
        isSys: true,
        id
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('删除样本类型成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('删除样本类型失败')
      })
    }
  }
}
</script>
<style lang="less">
.sys-type-custom-global {
  background: #fff;
  padding-left: 20px;
  box-shadow: 0 0 5px rgba(0,0,0,0.35);
  overflow-y: auto;
  .type-list {
    &:after {
      content: ".";
      display: block;
      height: 0;
      visibility: hidden;
      clear: both;
    }
  }
  .type-item {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    min-width: 200px;
    float: left;
    &:after {
      content: ".";
      display: block;
      height: 0;
      visibility: hidden;
      clear: both;
    }
  }
}
.sys-type-title {
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.sys-type-action {
  margin: 15px 0;
}
</style>
