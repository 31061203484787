const state = {
  projectId: ''
}
const mutations = {
  UPDATE_PROJECTID (state, projectId) {
    state.projectId = projectId
  }
}

export default {
  state,
  mutations
}
