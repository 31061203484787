<template>
  <div class="subject-manage">
    <div class="subject-add">
      <y-button type="primary" @click.native.stop="onSubjectAdd">添加</y-button>
    </div>
    <div class="subject-list head">
      <div class="subject-item">
        <span class="name">课题名称</span>
        <span class="default">是否默认</span>
        <span class="desc">描述</span>
        <span class="group">用户组</span>
        <span class="edit">操作</span>
      </div>
    </div>
    <div class="subject-list body" :style="{overflow: 'auto', height: tableHeight + 'px'}">
      <div class="subject-item" v-for="item in subjectList" :key="item.id">
        <span class="name">{{item.name}}</span>
        <span class="default"><input disabled type="checkbox" v-model="item.default"></span>
        <span class="desc">{{item.desc}}</span>
        <span class="group">{{mapGroupListName(item.groupCodeList)}}</span>
        <span class="edit">
          <span class="action" @click.stop="onSubjectEdit(item)"><span class="ionic-icon ionic-icon-edit"></span><span class="label">编辑</span></span>
          <span class="action" @click.stop="onSubjectDelete(item)"><span class="ionic-icon ionic-icon-close"></span><span class="label">删除</span></span>
          <span class="action" @click.stop="onSubjectAuth(item)"><span class="ionic-icon ionic-icon-unlocked"></span><span class="label">授权</span></span>
          <span class="action" @click.stop="onWxcodeCreate(item)"><span class="label">小程序码</span></span>
        </span>
      </div>
    </div>
    <subject-win
      :show="showSubjectAddWin"
      title="添加课题"
      @on-hide="showSubjectAddWin=false"
      @on-confirm-ok="addSubject"></subject-win>
    <subject-win
      v-model="currentSubject"
      :show="showSubjectEditWin"
      title="编辑课题"
      @on-hide="showSubjectEditWin=false"
      @on-confirm-ok="updateSubject"></subject-win>
    <auth-win
      v-model="currentGroupCodeList"
      :projectId="projectId"
      :show="showAuthWin"
      @on-hide="showAuthWin=false"
      @on-confirm-ok="authSubject"></auth-win>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>

<script>
import YButton from '../common/button/button'
import Loading from '../common/loading/loading'
import SubjectWin from './win/subject-win'
import AuthWin from './win/auth-win'
import { authenticate, catchExpired } from '../../utils/auth'

export default {
  components: {YButton, Loading, SubjectWin, AuthWin},
  data () {
    return {
      subjectList: [],
      currentSubject: {},
      currentGroupCodeList: '',
      groupList: [],
      showLoading: false,
      showAuthWin: false,
      showSubjectAddWin: false,
      showSubjectEditWin: false
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    },
    tableHeight () {
      return this.$store.getters.getWinHeight - 160
    }
  },
  watch: {
    projectId (val) {
      if (val) {
        this.loadSubjectList()
        this.loadGroupList()
      }
    }
  },
  mounted () {
    this.loadSubjectList()
    this.loadGroupList()
  },
  methods: {
    loadSubjectList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/subject/list`,
        authenticate({params: {projectId: this.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.subjectList = res.subjectList || []
          this.showLoading = false
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    onSubjectAdd () {
      this.showSubjectAddWin = true
    },
    onSubjectEdit (item) {
      this.currentSubject = item
      this.showSubjectEditWin = true
    },
    onSubjectAuth (item) {
      this.currentSubject = item
      this.currentGroupCodeList = this.currentSubject.groupCodeList
      this.showAuthWin = true
    },
    onSubjectDelete (item) {
      this.$Modal.confirm({
        title: '删除课题',
        content: '<p>您确定删除该课题？</p>',
        onOk: () => {
          this.deleteSubject(item.id, () => {
            this.subjectList.forEach((subject, index) => {
              if (subject.id === item.id) {
                this.subjectList.splice(index, 1)
              }
            })
          })
        }
      })
    },
    addSubject (data) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/subject/add`, {
        projectId: this.projectId, subject: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.$Message.success('添加成功')
          if (res.subject) {
            this.subjectList.unshift(res.subject)
          } else {
            this.loadSubjectList()
          }
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
        this.$Message.error('添加失败')
      })
    },
    updateSubject (data) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/subject/update`, {
        projectId: this.projectId, subject: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.$Message.success('更新成功')
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
        this.$Message.error('更新失败')
      })
    },
    deleteSubject (id, callback) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/subject/delete`, {id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.$Message.success('删除成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
        this.$Message.error('删除失败')
      })
    },
    authSubject (groupList) {
      let codeList = groupList.map(item => { return item.code })
      this.$http.post(`${this.httpRoot}/subject/authorize`, {
        projectId: this.projectId,
        subjectId: this.currentSubject.id,
        groupCodeList: codeList
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('授权成功')
          this.currentSubject.groupCodeList = codeList
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('授权失败')
      })
    },
    onWxcodeCreate (item) {
      this.$http.post(`${this.httpRoot}/subject/wxcode/create`, {
        projectId: this.projectId,
        subjectId: item.id
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = '' + item.name + new Date().format('yyyyMMddhhmmss') + '.jpg'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadGroupList () {
      this.$http.get(`${this.httpRoot}/admin/user/grouptree/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.groupList = res.treeList || []
          this.regGroupList(res.treeList)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    regGroupList (groupTreeList) {
      let treeList = groupTreeList || []
      treeList.forEach(node => {
        this.groupList.push(node)
        if (node.children && node.children.length) {
          this.regGroupList(node.children)
        }
      })
    },
    mapGroupName (code) {
      let groupName = ''
      this.groupList.forEach(g => {
        if (code === g.code) {
          groupName = g.name
        }
      })
      return groupName
    },
    mapGroupListName (groupCodeList) {
      let codeList = groupCodeList || []
      return codeList.map(code => this.mapGroupName(code))
    }
  }
}
</script>

<style lang="less">
.subject-manage {
  padding: 15px;
  padding-top: 5px;
}
.subject-add {
  height: 40px;
  line-height: 40px;
}
.subject-list {
  &.head {
    background: #ebeef1;
    border: 1px solid #b6c3d0;;
    border-bottom: 0;
  }
  &.body {
    font-size: 13px;
    border: 1px solid #b6c3d0;;
    border-top: 0;
  }
}
.subject-item {
  height: 30px;
  line-height: 30px;
  position: relative;
  &:nth-child(odd) {
    &:hover {
      background-color: #dce5f9;
    }
  }
  &:nth-child(even) {
    &:hover {
      background-color: #f3f3f3;
    }
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e2e7f1;
  }
  &>.name {
    display: inline-block;
    width: 20%;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
  }
  &>.default {
    display: inline-block;
    text-align: center;
    width: 7%;
    white-space: nowrap;
    overflow: hidden;
  }
  &>.desc {
    display: inline-block;
    width: 10%;
    white-space: nowrap;
    overflow: hidden;
  }
  &>.group {
    display: inline-block;
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
  }
  &>.edit {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    width: 20%;
    .action {
      padding: 0 7px;
      &:hover {
        color: #1e78ff;
      }
    }
  }
}
</style>
