<template>
  <div class="layer-panel" :style="layerStyle">
    <div class="samplebox-container" v-for="item in strawboxList" :key="item.id">
      <div class="samplebox">
        <div class="samplebox-box" :class="{enable: item.enable}" @click.stop="onClick(item)">
          <delete-mask :show="showDeleteMask" :container="item" @on-delete="onShowDeleteWin" @on-auth="onShowAuthWin" @on-edit="onShowEditWin"></delete-mask>
          <template v-if="item.type === 'skip'">
            <div v-for="i in item.row" class="box-row" :style="getBoxRowStyle(item)" :key="i">
              <div v-for="j in parseInt(item.col/2)" class="box-col" :style="getBoxColStyle(item, i, j)" :key="j"></div>
            </div>
          </template>
          <template v-else>
            <div v-for="i in item.row" class="box-row" :style="getBoxRowStyle(item)" :key="i">
              <div v-for="j in item.col" class="box-col" :style="getBoxColStyle(item, i, j)" :key="j"></div>
            </div>
          </template>
          <div class="sample-num">
            {{item.sampleNum}}
          </div>
          <div class="box-transable" v-if="item.transable">
            <span class="ionic-icon" :class="[containerAlign==='left'? 'ionic-icon-ios-upload-outline': 'ionic-icon-ios-download-outline']"></span>
          </div>
        </div>
        <div class="samplebox-desc" :style="{width: desSize + 'px'}">
          <p v-if="item.type === 'skip'">{{`${item.label}  ${item.row}&times;${item.col/2}`}}</p>
          <p v-else>{{`${item.label}  ${item.row}&times;${item.col}`}}</p>
          <p v-if="currentPath.indexOf('mySamples')>=0"><span style="white-space:nowrap;">位置编码：{{item.siteCode}}</span></p>
        </div>
      </div>
    </div>
    <div class="trans-tip" v-if="containerAlign==='left' && currentPath.indexOf('sampleTransSelect')>=0">
      选择需要转移的非空盒子, 再次点击取消
    </div>
    <div class="trans-tip" v-if="containerAlign==='right' && currentPath.indexOf('sampleTransSelect')>=0">
      选择需要被替换的空盒子, 再次点击取消, 空盒子将被替换
    </div>
    <div class="samplebox-container" v-if="currentPath.indexOf('mySamples') >= 0">
      <div class="samplebox" @click.stop="onShowModal">
        <div class="samplebox-add">
          <div class="samplebox-add-plus">+</div>
        </div>
      </div>
    </div>
    <edit-menu v-if="currentPath.indexOf('mySamples') >= 0"
      :active="sampleboxEditActive" @on-edit-click="onContainerEdit('samplebox')"></edit-menu>
    <strawbox-add v-if="currentPath.indexOf('mySamples') >= 0"
      v-model="newSamplebox"
      :show="showModal"
      @on-hide="showModal=false"
      @on-add-ok="onSampleboxAdd"></strawbox-add>
    <delete-win
      v-model="deletePermanent"
      :show="showDeleteWin"
      @on-hide="showDeleteWin=false"
      @on-delete-ok="onSampleboxDelete"></delete-win>
    <auth-win
      v-model="currentGroupCodeList"
      :show="showAuthWin"
      @on-hide="showAuthWin=false"
      @on-confirm-ok="onContainerAuth"></auth-win>
    <edit-win
      containerType="strawbox"
      v-model="editContainer"
      :show="showEditWin"
      @on-hide="showEditWin=false"
      @on-confirm-ok="onContainerUpdate"></edit-win>
  </div>
</template>

<script>
import StrawboxAdd from '../add/strawboxAdd'
import DeleteMask from '../delete/deleteMask'
import DeleteWin from '../delete/deleteWin'
import AuthWin from '../auth/auth-win'
import EditWin from '../edit/edit-win'
import EditMenu from '../delete/edit-menu'
import editMixin from '../mixins/editMixin'
import Emitter from '../../mixins/emitter'
import { authenticate, catchExpired } from '../../../../utils/auth'
export default {
  components: { StrawboxAdd, DeleteMask, DeleteWin, AuthWin, EditWin, EditMenu },
  mixins: [Emitter, editMixin],
  props: {
    parentId: String,
    rootId: String,
    parentCode: String,
    containerAlign: String
  },
  data () {
    return {
      containerType: 'strawbox',
      boxSize: 120,
      desSize: 110,
      strawboxList: [],
      newSamplebox: {
        sampleboxNum: 1,
        label: '',
        type: 'normal',
        desc: '',
        sampleboxRow: 10,
        sampleboxCol: 10
      }
    }
  },
  computed: {
    currentPath () {
      return this.$store.getters.getCurrentPath
    },
    layerStyle () {
      if (this.currentPath.indexOf('sampleTransSelect') >= 0) {
        return {
          maxWidth: '290px'
        }
      } else {
        return {
          maxWidth: this.$store.getters.getWinWidth - 15 - 200 + 'px'
        }
      }
    }
  },
  watch: {
    parentId (val) {
      this.loadSampleboxList()
    }
  },
  mounted () {
    this.loadSampleboxList()
  },
  methods: {
    getBoxRowStyle (item) {
      let height = this.boxSize / item.row
      return {
        height: height + 'px'
      }
    },
    getBoxColStyle (item, rowIndex, colIndex) {
      let height = this.boxSize / item.row
      let width = this.boxSize / item.col
      let hasSample = ((rowIndex - 1) * item.col + colIndex) + item.sampleNum > item.row * item.col
      if (item.type === 'skip') {
        width = 2 * width - 2
        height = height - 2
        hasSample = ((rowIndex - 1) * item.col / 2 + colIndex) + item.sampleNum > item.row * item.col / 2
      } else {
        width = width - 2
        height = height - 2
      }
      return {
        width: width + 'px',
        height: height + 'px',
        background: hasSample ? '#7AB7FC' : '#D8DFE7'
      }
    },
    loadSampleboxList () {
      this.$parent.showLoading = true
      this.$http.get(`${this.httpRoot}/container/strawbox/list`,
        authenticate({params: {parentId: this.parentId, projectId: this.$store.state.project.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.strawboxList = res.strawboxList || []
          this.$parent.showLoading = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onClick (item) {
      // 选择左边对应的 container, 更新右上 containerPath 切换右边 panel
      if (item.enable) {
        // 选择盒子转入转出
        if (this.currentPath.indexOf('sampleTransSelect') >= 0) {
          if (this.containerAlign === 'left' && item.sampleNum === 0) {
            this.$Message.warning('请选择非空的盒子')
          } else if (this.containerAlign === 'right' && item.sampleNum > 0) {
            this.$Message.warning('请选择空盒子')
          } else {
            this.dispatch('SampleTransSelect', 'on-samplebox-changed', this.containerAlign, {
              id: item.id,
              rootId: item.rootId,
              siteCode: item.siteCode,
              pathLabel: this.$parent.getPathLabel() + `/${item.label}`
            }, !item.transable)
            this.$set(item, 'transable', !item.transable)
          }
          return
        }
        // 选择对应的 container, 更新右上 containerPath 切换右边 panel
        this.$parent.selectContainerById(item.id)
        this.$parent.updateContainerPathById(item.id)
        if (this.currentPath.indexOf('codeLocate') >= 0) {
          this.$parent.updatePanel('sampleboxPanelLocation', {
            parentId: item.id,
            rootId: item.rootId,
            parentCode: item.siteCode,
            leafboxType: 'strawbox',
            type: item.type,
            label: item.label,
            actionType: 'once'
          })
        } else if (this.currentPath.indexOf('mySamples') >= 0) {
          this.$parent.updatePanel('sampleboxPanelMysamples', {
            parentId: item.id,
            rootId: item.rootId,
            parentCode: item.siteCode,
            leafboxType: 'strawbox',
            type: item.type,
            label: item.label,
            actionType: 'all'
          })
        } else if (this.currentPath.indexOf('sampleLocationVerify') >= 0) {
          this.$parent.updatePanel('sampleboxPanelLocverify', {
            parentId: item.id,
            rootId: item.rootId,
            parentCode: item.siteCode,
            leafboxType: 'strawbox',
            type: item.type,
            label: item.label,
            actionType: 'lookup'
          })
        } else {
          this.$parent.updatePanel('sampleboxPanelSamplein', {
            parentId: item.id,
            rootId: item.rootId,
            parentCode: item.siteCode,
            leafboxType: 'strawbox',
            type: item.type,
            label: item.label,
            actionType: 'all'
          })
        }
      }
    },
    onSampleboxAdd () {
      this.$http.post(`${this.httpRoot}/container/strawbox/add`,
        Object.assign({}, {
          parentId: this.parentId,
          rootId: this.rootId,
          parentCode: this.parentCode
        }, this.newSamplebox), authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('创建样本盒成功')
          this.loadSampleboxList()
        } else {
          this.$Modal.error({
            title: '创建样本盒失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('创建样本盒失败')
      })
    },
    onSampleboxDelete () {
      let path = this.deletePermanent ? '/permanent' : ''
      this.$http.post(`${this.httpRoot}/container/strawbox/delete${path}`, {
        id: this.deleteContainer.id,
        rootId: this.deleteContainer.rootId,
        siteCode: this.deleteContainer.siteCode
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('删除样本盒成功')
          this.loadSampleboxList()
        } else {
          this.$Modal.error({
            title: '删除样本盒失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('删除样本盒失败')
      })
    }
  }
}
</script>

<style lang="less">
@import "../../../../assets/ivu/mixins/clear";
.layer-panel {
  padding: 0 10px;
  // position: relative;
  &:after {
    content: ".";
    height: 0;
    display: block;
    visibility: hidden;
    clear: both;
  }
  .samplebox-container{
    float: left;
    &:after {
      .clearFloat();
    }
  }
  .samplebox{
    margin-right: 40px;
  }
  .samplebox-box {
    margin: 10px;
    position: relative;
    &.enable {
      &:hover {
        cursor: pointer;
      }
    }
    .box-row {
      margin: 0;
      padding: 0;
    }
    .box-col {
      float: left;
      display: inline-block;
      margin-bottom: 2px;
      margin-right: 2px;
    }
  }
  .samplebox-desc {
    margin: 0 10px;
    text-align: center;
    font-size: 12px;
  }
  .sample-num {
    position: absolute;
    top: 30%;
    left: 30%;
    font-size: 44px;
    color: #666;
  }
  .box-transable {
    position: absolute;
    top: 20%;
    left: 40%;
    font-size: 44px;
    color: #8649a7;
  }
}
.samplebox-add {
  width: 110px;
  height: 110px;
  margin: 10px;
  transition: all .3s;
  &:hover {
    cursor: pointer;
    .samplebox-add-plus {
      display: block;
    }
  }
}
.samplebox-add-plus {
  border: 2px dotted #ccc;
  font-size: 32px;
  height: 106px;
  line-height: 106px;
  color: #bdbdbd;
  text-align: center;
  display: none;
  transition: all .3s;
}
.trans-tip {
  font-size: 14px;
  padding-left: 10px;
  padding-top: 20px;
}
</style>
