<template>
  <div class="field-tree" :style="{overflow: 'auto', height: pageHeight + 'px'}">
    <tree-node
      :node="rootNode"
      :props="props"
      :key="getKeyField(rootNode)">
    </tree-node>
  </div>
</template>

<script>
import TreeStore from './model/tree-store'
import TreeNode from './tree-node'
import mixin from './mixin'

export default {
  components: {TreeNode},
  mixins: [mixin],
  props: {
    name: {
      type: String,
      default: '所有分组'
    },
    value: Array,
    data: Array,
    props: {
      default () {
        return {
          children: 'children',
          label: 'name',
          value: 'code',
          type: 'type',
          key: 'code',
          code: 'code',
          icon: 'icon'
        }
      }
    },
    indent: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      $tree: null,
      treeStore: null,
      rootNode: null
    }
  },
  computed: {
    pageHeight () {
      return Math.min(this.$store.getters.getWinHeight - 200, this.height)
    }
  },
  watch: {
    data (val) {
      this.treeStore = new TreeStore({
        name: this.name,
        data: val,
        props: this.props
      })
      this.rootNode = this.treeStore.rootNode
      this.updateCheckStatus(this.value)
    },
    value (val) {
      this.updateCheckStatus(val)
    }
  },
  created () {
    this.$tree = this
    this.treeStore = new TreeStore({
      name: this.name,
      data: this.data,
      props: this.props
    })
    this.rootNode = this.treeStore.rootNode
    this.updateCheckStatus(this.value)
    this.$on('on-node-check-change', (node, status) => {
      this.$emit('on-check-change', {name: node.label, code: node.key}, status)
    })
  },
  methods: {
    getLeafCheckedData () {
      return this.treeStore.getLeafCheckedData()
    },
    updateCheckStatus (val) {
      if (this.treeStore) {
        this.treeStore.unCheckAllNodes()
      }
      if (Array.isArray(val) && this.treeStore) {
        val.forEach(item => {
          let node = this.treeStore.getNodeByKey(item)
          if (node) {
            node.check(true)
          }
        })
      }
    }
  }
}
</script>

<style lang="css">
</style>
