<template>
  <div class="check-item-list">
    <a class="check-title" v-if="title">{{title}}</a>
    <template v-for="(item, idx) in items">
      <label class="check-item" :class="{'inline': inline}" :style="lineStyle" :for="'_id_'+idx+idRand" :key="item.key">
        <span class="check-radio-outer" :style="absoluteTop" :class="{checked: item.key === currentKey}">
          <span class="check-radio-inner"></span>
          <input class="check-radio" type="radio" :id="'_id_'+idx+idRand" :value="item.key" v-model="currentKey" :disabled="disabled">
        </span>
        <span class="check-label" :style="lineStyle">{{item.label || item.title}}</span>
      </label>
    </template>
  </div>
</template>

<script>
import Emitter from '../mixins/emitter'
export default {
  mixins: [Emitter],
  props: {
    title: String,
    items: {
      type: Array,
      default () {
        return [
          { key: '1', value: true, title: '男' },
          { key: '0', value: false, title: '女' }
        ]
      }
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    lineHeight: {
      type: Number,
      default: 32
    }
  },
  data () {
    return {
      currentKey: this.getKey(this.value),
      idRand: Math.random().toString().slice(2, 6)
    }
  },
  computed: {
    lineStyle () {
      return {
        height: this.lineHeight + 'px',
        lineHeight: this.lineHeight + 'px'
      }
    },
    absoluteTop () {
      return {
        top: ((this.lineHeight - 20) / 2 + 1) + 'px'
      }
    }
  },
  watch: {
    value (val) {
      this.currentKey = this.getKey(val)
    },
    currentKey (val) {
      this.emitChange(val)
    }
  },
  methods: {
    getKey (val) {
      let key = ''
      this.items.forEach(item => {
        if (val === item.value) {
          key = item.key
        }
      })
      return key
    },
    getValue (key) {
      let val = ''
      this.items.forEach(item => {
        if (key === item.key) {
          val = item.value
        }
      })
      return val
    },
    emitChange (currentKey) {
      const val = this.getValue(currentKey)
      this.$emit('input', val)
      this.dispatch('FormItem', 'on-form-change', val)
      this.$emit('on-change', val)
    }
  }
}
</script>

<style lang="less">
@import "../../../assets/ivu/custom.less";
.check-title {
  font-size: 12px;
  color: #666;
  padding-right: 5px;
}
.check-item-list {
  font-size: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  & * {
    box-sizing: content-box;
  }
  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}
.check-item {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  padding: 10px 0 0 0;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  &.inline {
    display: inline-block;
    margin-right: 15px;
    float: left;
  }
  .check-label {
    margin-left: 28px;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    float: left;
  }
}
.check-radio-outer {
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  position: absolute;
  left: 5px;
  // top: 7px; // fix container line-height 32px;
  line-height: 1;
  vertical-align: middle;
  float: left;
  &.checked {
    .check-radio-inner {
      border-color: @primary-color;
      &:after {
        border-color: @primary-color;
        opacity: 1;
        transform: scale(1);
        transition: all .2s ease-in-out;
      }
    }
  }
}
.check-radio-inner {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  top: 0;
  left: 0;
  background-color: #fff;
  border: 1px solid @border-color-base;
  border-radius: 50%;
  transition: all .2s ease-in-out;
  &:after {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 3px;
    top: 3px;
    display: block;
    content: "";
    background-color: @primary-color;
    opacity: 0;
    transition: all .2s ease-in-out;
    transform: scale(0);
  }
}
.check-radio {
  position: absolute;
  left: -999px;
  &:checked+.check-label {
    color: tint(@primary-color, 10%);
  }
}
</style>
