<template>
  <div class="panel-field">
    <div class="panel-edit-title">(fields) CRF表项</div>
    <y-form ref="rootForm" label-width="100px" item-width="70%">
      <form-item label="CRF表唯一编码">
        <y-input v-model="root.key" disabled></y-input>
      </form-item>
      <form-item label="CRF表名称">
        <y-input v-model="root.title" disabled></y-input>
      </form-item>
      <template v-for="item in root.fields">
        <form-item label="CRF表项" :key="item.key">
          <y-input v-model="item.title" disabled></y-input>
        </form-item>
      </template>
    </y-form>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YInput from '../../common/input/input'

export default {
  components: { YForm, FormItem, YInput },
  props: {
    data: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      root: this.data
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.root = val
      }
    }
  },
  methods: {
    //
  }
}
</script>

<style lang="css">
</style>
