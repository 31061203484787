<template>
  <div class="pagination">
    <span class="pagination__total">{{`共 ${total} 条`}}</span>
    <page-size v-if="pageSizeChangeable"
      :pageSize="pageSize"
      @on-pagesize-change="onPageSizeChange"></page-size>
    <button class="btn-prev" :disabled="currentPageNum === 1" @click.stop="onPrev">
      <i class="ionic-icon ionic-icon-ios-arrow-left"></i>
    </button>
    <ul class="pager">
      <li v-if="totalPageNum >= 1"
        :class="{active: 1 === currentPageNum}"
        @click.stop="onFirstPageClick">{{1}}</li>
      <li v-if="minPageNum > 2"
        class="more ionic-icon"
        :class="[hoverLess ? 'ionic-icon-ios-arrow-back': 'ionic-icon-ios-more']"
        @mouseover="onMouseoverLess"
        @mouseout="onMouseoutLess"
        @click.stop="onLessClick"></li>

      <template v-for="(page, index) in pageList">
        <li :class="{active: page === currentPageNum}" :key="index"
          @click.stop="onPageClick(page)">{{page}}</li>
      </template>

      <li v-if="maxPageNum < totalPageNum"
        class="more ionic-icon"
        :class="[hoverMore ? 'ionic-icon-ios-arrow-forward': 'ionic-icon-ios-more']"
        @click.stop="onMoreClick"
        @mouseover="onMouseoverMore"
        @mouseout="onMouseoutMore"></li>
      <li v-if="maxPageNum < totalPageNum"
        class="total"
        :class="{active: totalPageNum === currentPageNum}"
        @click.stop="onTotalPageNumClick">{{totalPageNum}}</li>
    </ul>
    <button class="btn-next" :disabled="currentPageNum >= totalPageNum" @click.stop="onNext">
      <i class="ionic-icon ionic-icon-ios-arrow-right"></i>
    </button>
    <span class="pagination__jump" v-if="totalPageNum > 1">
      前往
      <input :value="nextPageNum" class="pagination__editor" :style="{width: width + 'px'}"
        @blur="onBlur" @keyup.enter="onEnter">
      页
    </span>
  </div>
</template>

<script>
import PageSize from './pageSize'
export default {
  components: {PageSize},
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageIndex: {
      type: Number,
      default: 0
    },
    pageSizeChangeable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      minPageNum: 0,
      maxPageNum: 0,
      nextPageNum: 1,
      oldPageNum: 1,
      currentPageNum: 1,
      hoverLess: false,
      hoverMore: false
    }
  },
  computed: {
    totalPageNum () {
      var total = this.total > 0 ? this.total : 0
      var pSize = this.pageSize > 0 ? this.pageSize : 1
      return Math.ceil(total / pSize)
    },
    pageList () {
      var list = []
      if (this.currentPageNum < 6) {
        this.minPageNum = 2
        this.maxPageNum = this.totalPageNum <= 6 ? this.totalPageNum : 6
      } else {
        // set maxPageNum and minPageNum
        if (this.currentPageNum + 2 <= this.totalPageNum) {
          this.maxPageNum = this.currentPageNum + 2
          this.minPageNum = this.currentPageNum - 2
        } else if (this.currentPageNum + 1 <= this.totalPageNum) {
          this.maxPageNum = this.currentPageNum + 1
          this.minPageNum = this.currentPageNum - 3
        } else if (this.currentPageNum <= this.totalPageNum) {
          this.maxPageNum = this.currentPageNum
          this.minPageNum = this.currentPageNum - 4
        }
      }

      for (let i = this.minPageNum; i <= this.maxPageNum; i++) {
        list.push(i)
      }
      return list
    },
    width () {
      var totalPageNum = this.totalPageNum
      if (totalPageNum < 100) return 30
      if (totalPageNum < 1000) return 35
      if (totalPageNum < 10000) return 45
    }
  },
  watch: {
    currentPageNum (newVal, oldVal) {
      this.oldPageNum = oldVal
      if (newVal >= 1 && newVal <= this.totalPageNum) {
        this.$emit('on-currentpage-change', newVal)
      }
    },
    pageIndex (val) {
      if (val || parseInt(val) >= 0) {
        this.gotoPage(val + 1)
      }
    }
  },
  methods: {
    onEnter (e) {
      this.nextPageNumChange(e)
    },
    onBlur (e) {
      this.nextPageNumChange(e)
    },
    nextPageNumChange (e) {
      var pageNum = parseInt(e.target.value)
      if (pageNum >= 1 && pageNum <= this.totalPageNum) {
        this.gotoPage(pageNum)
      } else {
        e.target.value = this.nextPageNum
      }
    },
    gotoPage (num) {
      this.nextPageNum = num
      this.currentPageNum = num
    },
    onPrev () {
      if (this.currentPageNum > 1) {
        this.gotoPage(this.currentPageNum - 1)
      }
    },
    onNext () {
      if (this.currentPageNum < this.totalPageNum) {
        this.gotoPage(this.currentPageNum + 1)
      }
    },
    onPageClick (index) {
      this.gotoPage(index)
    },
    onFirstPageClick () {
      this.gotoPage(1)
    },
    onTotalPageNumClick () {
      this.gotoPage(this.totalPageNum)
    },
    onMoreClick () {
      this.hoverMore = false
      this.hoverLess = false
      this.gotoPage(this.maxPageNum + 1)
    },
    onLessClick () {
      this.hoverMore = false
      this.hoverLess = false
      this.gotoPage(this.minPageNum - 1)
    },
    onMouseoverMore () {
      this.hoverMore = true
    },
    onMouseoutMore () {
      this.hoverMore = false
    },
    onMouseoverLess () {
      this.hoverLess = true
    },
    onMouseoutLess () {
      this.hoverLess = false
    },
    onPageSizeChange (size) {
      this.$emit('on-pagesize-change', size)
    }
  }
}
</script>

<style lang="less">
@import "../../../assets/ivu/custom.less";
.pagination {
  white-space: nowrap;
  padding: 2px 5px;
  color: #48576a;
  &:before {
    display: table;
    content: "";
  }
  &:after {
    display: table;
    content: "";
    clear:both;
  }
  span {
    display: inline-block;
    font-size: 13px;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    vertical-align: top;
    box-sizing: border-box;
  }
  button {
    border: none;
    padding: 0 8px;
    margin: 0;
    outline: none;
    &[disabled="disabled"] {
      color: #ccc;
      cursor: default;
    }
  }
  .btn-next,
  .btn-prev {
    background: center center no-repeat #fff;
    background-size: 16px;
    border: 1px solid @border-color-base;
    cursor: pointer;
    margin: 0;
    color: #666;
    font-size: 20px;
    height: 28px;
    line-height: 28px;
    float: left;
  }
  .btn-prev {
    border-radius: 2px 0 0 2px;
    border-right: 0;
  }
  .btn-next {
    border-radius: 0 2px 2px 0;
    border-left: 0;
  }
  .pager {
    user-select: none;
    list-style: none;
    font-size: 0;
    padding: 0;
    margin: 0;
    float: left;
    li {
      padding: 0 4px;
      border: 1px solid @border-color-base;
      border-right: 0;
      background: #fff;
      font-size: 13px;
      min-width: 28px;
      height: 28px;
      line-height: 28px;
      box-sizing: border-box;
      text-align: center;
      &.active {
        border-color: @primary-color;
        background-color: @primary-color;
        color: #fff;
        cursor: default;
        & + li {
          border-left: 0;
          padding-left: 5px;
        }
      }
      &:last-child {
        border-right: 1px solid @border-color-base;
        &.active {
          border-right: 0;
        }
      }
    }
    .more {
      font-size: 20px;
      &:hover {
        color: #3399ff;
      }
    }
    .total {
      border-right: 1px solid @border-color-base;
    }
  }
  .pager,
  .pager li {
    vertical-align: top;
    display: inline-block;
    list-style: none;
    cursor: pointer;
    margin: 0;
  }
}
.pagination__total {
  margin: 0 10px;
  float: left;
}
.pagination__jump {
  margin-left: 10px;
  float: left;
}
.pagination__editor {
  outline: none;
  padding: 0 4px;
  margin: 0 6px;
  border: 1px solid @border-color-base;
  border-radius: 2px;
  background: #fff;
  font-size: 13px;
  width: 30px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  box-sizing: border-box;
  text-align: center;
  transition: border .3s;
  &:focus {
    border-color: #5cadff;
    outline: 0;
    box-shadow: 0 0 2px tint(@input-hover-border-color, 20%);
    transition: all 0.2s;
  }
}
</style>
