import objectAssign from '../../../../utils/merge'
import Strawbox from './strawbox'
let sampleboxid = 400

export default class Samplebox {
  constructor (options) {
    this.id = ++sampleboxid
    this._type = 'samplebox'
    this.type = 'normal'
    this.exboxType = 'samplebox' // 'strawbox' 'cardbox'
    this.label = ''
    this.index = 0 // 序号
    this.enable = true
    this.capacity = 100
    this.strawboxContainer = []
    this.loaded = false
    this.selected = false
    this.store = null
    this.parent = null
    this.$container = null

    for (let name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name]
      }
    }
    if (this.store) {
      this.$container = this.store.$container
      this.loadFn = this.store.loadFn
    }
    if (this.children) {
      this.setChildren(this.children)
      this.loaded = true
    }
  }

  loadStrawboxContainer (parentId, callback) {
    if (!this.loaded && !this.store.loading) {
      this.store.loading = true
      const resolveSuccess = (items) => {
        this.store.loading = false
        this.setChildren(items)
        this.loaded = true

        if (typeof callback === 'function') {
          callback.call(this)
        }
        this.$container.$emit('on-strawbox-load-success')
      }
      const resolveFaild = () => {
        this.store.loading = false
      }
      if (typeof this.loadFn === 'function') {
        this.loadFn.call(this.$container, this, resolveSuccess, resolveFaild, 'strawboxContainer', parentId)
      }
    }
  }

  setChildren (items) {
    this.strawboxContainer = items.map((item) => {
      return new Strawbox(objectAssign(item, {store: this.store, parent: this}))
    })
    this.store.registContainer(this.strawboxContainer)
  }

  select (isSelect, reSelect) {
    if (!this.enable) return
    if (this.exboxType === 'strawbox') {
      this.selectStarwbox(isSelect, reSelect)
    } else {
      this.selectSamplebox(isSelect, reSelect)
    }
  }

  selectStarwbox (isSelect, reSelect) {
    if (!this.selected && isSelect) {
      this.selected = true
      this.parent.updateSelectedSamplebox(this.id)
      if (!this.loaded) {
        this.loadStrawboxContainer(this.id, () => {
          this.updateCurrentStrawboxContainer()
          this.$container.$emit('on-samplebox-code-selected', this)
        })
      } else {
        this.updateCurrentStrawboxContainer()
        this.$container.$emit('on-samplebox-code-selected', this)
      }
      this.store.updateContainerPath(this)
      this.$container.$emit('on-path-change')
    } else if (this.selected && !isSelect) {
      this.selected = false
    } else if (this.selected && isSelect && reSelect) {
      this.updateCurrentStrawboxContainer()
      this.store.updateContainerPath(this)
      this.$container.$emit('on-samplebox-code-selected', this)
      this.$container.$emit('on-path-change')
    }
  }

  selectSamplebox (isSelect, reSelect) {
    if (!this.selected && isSelect) {
      this.selected = true
      // 将原来的 strawboxContainer 选择状态置空
      this.store.strawboxContainer.items.forEach((item) => {
        item.select(false)
      })
      // 赋上新的 strawboxContainer = []
      this.store.strawboxContainer.items = []
      this.parent.updateSelectedSamplebox(this.id)
      this.store.updateContainerPath(this)
      this.$container.$emit('on-samplebox-code-selected', this)
      this.$container.$emit('on-path-change')
    } else if (this.selected && !isSelect) {
      this.selected = false
    } else if (this.selected && isSelect && reSelect) {
      // 将原来的 strawboxContainer 选择状态置空
      this.store.strawboxContainer.items.forEach((item) => {
        item.select(false)
      })
      // 赋上新的 strawboxContainer = []
      this.store.strawboxContainer.items = []
      this.parent.updateSelectedSamplebox(this.id)
      this.store.updateContainerPath(this)
      this.$container.$emit('on-samplebox-code-selected', this)
      this.$container.$emit('on-path-change')
    }
  }

  updateCurrentStrawboxContainer () {
    // 将原来的 strawboxContainer 选择状态置空
    this.store.strawboxContainer.items.forEach((item) => {
      item.select(false)
    })
    // 赋上新的 strawboxContainer
    this.store.strawboxContainer.items = this.strawboxContainer
    const items = this.strawboxContainer
    if (items && items.length && items[0].type) {
      this.store.strawboxContainer.type = items[0].type
    }
    this.store.strawboxContainer.loaded = true
  }

  updateSelectedStrawbox (selectedStrawboxId) {
    // 清除其它 shelf 选中状态
    this.strawboxContainer.forEach((item) => {
      if (item.id !== selectedStrawboxId) {
        item.select(false)
      }
    })
  }
}
