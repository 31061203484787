<template>
  <div class="edit-menus">
    <div class="edit-menu" :class="{active: active}" @click.stop="onClick">
      <i class="icon ionic-icon" :class="[active?'ionic-icon-checkmark-circled': 'ionic-icon-compose']"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      this.$emit('on-edit-click')
    }
  }
}
</script>

<style lang="less">
.edit-menus {
  position: absolute;
  z-index: 5;
  top: 0px;
  right: 10px;
  .icon {
    font-size: 32px;
  }
  .edit-menu {
    width: 24px;
    height: 51px;
    color: #888;
    cursor: pointer;
    &:hover {
      color: #3a90e4;
    }
    &.active {
      color: #3a90e4;
    }
  }
}
</style>
