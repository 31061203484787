<template>
  <div class="header-search-box" v-clickoutside="handleClose">
    <form class="header-search-form" method="get" @submit.prevent="onSubmit">
      <input type="text" :placeholder="placeholder" v-model="query" @focus="onFocus">
      <span class="header-search-btn">
        <i v-if="searching" class="ionic-icon ionic-icon-load-c spin-icon-load"></i>
        <i v-else class="ionic-icon ionic-icon-ios-search-strong" @click.stop="onSearch"></i>
      </span>
    </form>
    <ul class="search-result-box" v-show="showList">
      <li class="result-item" v-for="(item, index) in projectList" @click.stop="onItemClick(item)" :key="index">
        <span>{{item.name}}</span>
        <span class="code">{{item.code}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { authenticate, catchExpired } from '../../utils/auth'
import clickoutside from '../common/directives/clickoutside'
import Emitter from '../common/mixins/emitter'
export default {
  mixins: [Emitter],
  directives: { clickoutside },
  props: {
    value: {},
    placeholder: String
  },
  data () {
    return {
      searching: false,
      query: this.value || '',
      projectList: [],
      showList: false
    }
  },
  computed: {
    loggedIn () {
      return this.$store.getters.getLoginState
    },
    projectId () {
      return this.$store.state.project.projectId
    }
  },
  watch: {
    value (val) {
      this.query = val
    },
    projectList (val) {
      if (val && val.length) {
        this.onItemClick(val[0])
      }
    },
    projectId (val) {
      if (val) {
        this.projectList.forEach(item => {
          if (item.code === val) {
            this.query = item.name
          }
        })
      }
    }
  },
  mounted () {
    this.loadProjectList()
  },
  methods: {
    handleClose () {
      this.showList = false
    },
    onSubmit () {
      return false
    },
    onSearch () {
      if (this.loggedIn) {
        this.loadProjectList()
      }
    },
    onFocus () {
      this.showList = true
    },
    loadProjectList () {
      this.searching = true
      this.$http.get(`${this.httpRoot}/admin/project/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.projectList = res.projectList
          this.searching = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onItemClick (item) {
      this.query = item.name
      this.$store.dispatch('updateProjectId', item.code)
      this.showList = false
    }
  }
}
</script>

<style lang="less">
.header-search-box {
  position: relative;
  * {
    box-sizing: content-box;
  }
}
.header-search-form {
  width: 360px;
  position: relative;
  input {
    padding: 7px 50px 7px 10px;
    width: 100%;
    color: #49525c;
    font-size: 14px;
    line-height: 17px;
    background-color: #e1eaf2;
    border: 1px solid tint(#313e58, 10%);
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2), 0 1px 0 rgba(255, 255, 255, .1);
    outline: 0;
    box-sizing: border-box;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    &:focus {
      background: #fff;
    }
  }
  i[class^='ionic-icon'] {
    color: #49525c;
    font-size: 17px;
  }
}
.header-search-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  text-align: center;
  &:hover {
    cursor: pointer;
    i {
      color: #222;
    }
  }
}
.search-result-box {
  position: absolute;
  z-index: 999;
  font-size: 14px;
  list-style: none;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-height: 330px;
  overflow-x: hidden;
  overflow-y: auto;
  .result-item {
    height: 20px;
    line-height: 20px;
    padding: 6px 10px;
    color: #49525c;
    background-color: #fff;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #f3f3f3;
      transition: background-color 0.2s ease-in-out;
    }
    &:first-child {
      margin-top: 5px;
    }
    &:last-child {
      margin-bottom: 5px;
    }
    .code {
      float: right;
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
