import Node from './fieldTree-node'

export default class TreeStore {
  constructor (options) {
    this.key = ''
    this.title = ''
    this.data = null
    this.props = {}

    this.currentNode = null

    for (let option in options) {
      if (options.hasOwnProperty(option)) {
        this[option] = options[option]
      }
    }

    this.nodesMap = {}

    this.rootNode = new Node({
      type: 'root',
      data: {
        key: this.key || 'root',
        title: this.title,
        value: this.title,
        fields: JSON.parse(JSON.stringify(this.data))
      },
      store: this
    })
  }

  registerNode (node) {
    if (!node || !node.data) return
    if (node.key) this.nodesMap[node.key] = node
  }

  deregisterNode (node) {
    if (!node || !node.data) return
    delete this.nodesMap[node.key]
  }

  getNodeByKey (key) {
    return this.nodesMap[key]
  }

  getFieldNavs () {
    const result = []
    const fieldNodes = this.rootNode.childNodes
    fieldNodes.forEach(node => {
      if (node.type === 'field' && node.checked) {
        result.push({
          key: node.key,
          title: node.data.title,
          fid: node.data.fid
        })
      }
    })
    return result
  }

  checkAllNodes () {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.type !== 'root' && node.type === 'field') {
        node.checked = true
      }
    })
  }

  unCheckAllNodes () {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.type !== 'root' && node.type === 'field') {
        node.checked = false
      }
    })
  }
}
