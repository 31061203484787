import Vue from 'vue'
import { deepCopy } from '../../../utils/assist'
export default class TableStore {
  constructor (options, $container, loadFn) {
    this.id = 'tid_' + new Date().getTime()
    this.$container = $container
    this.loadFn = loadFn
    this.loaded = false
    this.loading = false
    this.columns = []
    this.allColumns = []
    this.hideColumns = []
    this.centerColumns = []
    this.leftFixedColumns = []
    this.rightFixedColumns = []
    this.toggleColumns = []
    this.isLeftFixed = false
    this.isRightFixed = false
    this.data = []
    this.selectedRowData = []
    this.highlightRowData = []
    this.filteredData = []
    this.beforeSortData = []

    for (let name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name]
      }
    }

    this.initialColumns()

    if (Array.isArray(this.data) && this.data.length) {
      // initial data
      this.setData(this.data, true)
      this.loaded = true
    }
  }

  loadData (callback) {
    if (!this.loaded && !this.loading) {
      this.loading = true
      if (typeof this.loadFn === 'function') {
        this.loadFn.call(this.$container, this, this.resolveSuccess, this.resolveFaild)
      }
    } else {
      this.$container.$emit('on-load-success')
    }
  }

  loadDataWithParams (params) {
    // reload
    this.loaded = false
    this.loading = true
    if (typeof this.loadFn === 'function') {
      this.loadFn.call(this.$container, this, this.resolveSuccess, this.resolveFaild, params)
    }
  }

  resolveSuccess (data) {
    this.loading = false
    this.setData(data)
    this.loaded = true
    this.$container.$emit('on-load-success')
  }

  resolveFaild () {
    this.loading = false
  }

  setData (data, init) {
    this.data = data
    this.initialFilteredData()
    this.recoverData(init)
  }

  setColumns (newColumns) {
    this.columns = newColumns
    this.allColumns = []
    this.hideColumns = []
    this.centerColumns = []
    this.leftFixedColumns = []
    this.rightFixedColumns = []
    this.toggleColumns = []
    this.initialColumns()
  }

  initialColumns () {
    if (!this.columns || !this.columns.length) return
    const columns = deepCopy(this.columns)

    columns.forEach((column, index) => {
      column._index = index
      column._width = column.width ? column.width : ''    // update in handleResize()
      column._sortType = 'normal'
      column._filterVisible = false
      column._isFiltered = false
      column._filterChecked = []
      column._toggleShow = !!column.toggleShow
      column.formatter = column.formatter || function (val) { return val }

      if ('filterMultiple' in column) {
        column._filterMultiple = column.filterMultiple
      } else {
        column._filterMultiple = true // 默认多选过滤
      }
      if ('filteredValue' in column) {
        column._filterChecked = column.filteredValue
        column._isFiltered = true
      }

      if (column.fixed && column.fixed === 'left') {
        this.leftFixedColumns.push(column)
      } else if (column.fixed && column.fixed === 'right') {
        this.rightFixedColumns.push(column)
      }
      if (column.hide) {
        this.hideColumns.push(column)
      } else if (column.toggle) {
        this.toggleColumns.push(column)
        if (column.toggleShow) {
          this.centerColumns.push(column)
        }
      } else {
        this.centerColumns.push(column) // 包含所有非隐藏列，固定列是利用样式技巧被单独的固定列遮盖
      }
    })
    this.allColumns = columns

    if (this.leftFixedColumns.length) this.isLeftFixed = true
    if (this.rightFixedColumns.length) this.isRightFixed = true
  }

  initialFilteredData () {
    if (!Array.isArray(this.data)) return
    const data = deepCopy(this.data)
    data.forEach((row, index) => {
      this.data[index]._idx = this.id + '_' + index // 便于外部使用 _id 和 mongo冲突
      this.data[index]._index = index // 便于外部使用
      row._idx = this.id + '_' + index
      row._index = index
      row._isHover = false
      row._isDisabled = false
      row._isChecked = false
      row._isHighlight = false
      row._showHideColumns = false
    })
    this.filteredData = data
  }

  removeData (_index) {
    let idx = -1
    this.filteredData.forEach((fRow, index) => {
      if (fRow._index === _index) {
        idx = index
      }
    })
    if (idx >= 0) {
      this.filteredData.splice(idx, 1)
      this.resolveSelectedData()
      return true
    }
    return false
  }

  removeDataById (_idx) {
    let idx = -1
    this.filteredData.forEach((fRow, index) => {
      if (fRow._idx === _idx) {
        idx = index
      }
    })
    if (idx >= 0) {
      this.filteredData.splice(idx, 1)
      this.resolveSelectedData()
      return true
    }
    return false
  }

  removeSelectedData () {
    let indexs = []
    this.filteredData.forEach(fRow => {
      if (fRow._isChecked) {
        indexs.push(fRow._index)
      }
    })
    indexs.forEach(_index => {
      this.removeData(_index)
    })
  }

  getAllData () {
    let datas = []
    this.filteredData.forEach((fRow) => {
      datas.push(this.data[fRow._index])
    })
    return datas
  }

  getDataById (_idx) {
    let index = -1
    this.filteredData.forEach((fRow) => {
      if (fRow._idx === _idx) {
        index = fRow._index
      }
    })
    return index >= 0 ? this.data[index] : null
  }

  setDataById (_idx, data) {
    let index = -1
    let fRowIndex = -1
    this.filteredData.forEach((fRow, idx) => {
      if (fRow._idx === _idx) {
        index = fRow._index
        fRowIndex = idx
      }
    })
    if (index >= 0) {
      Vue.set(this.data, index, Object.assign({}, this.data[index], data))
      Vue.set(this.filteredData, fRowIndex, Object.assign({}, this.filteredData[fRowIndex], data))
    }
  }

  setDataColumnById (_idx, data, column) {
    let index = -1
    let fRowIndex = -1
    this.filteredData.forEach((fRow, idx) => {
      if (fRow._idx === _idx) {
        index = fRow._index
        fRowIndex = idx
      }
    })
    if (index >= 0 && column) {
      // this.data[index][column] = data
      // this.filteredData[fRowIndex][column] = data
      Vue.set(this.data[index], column, data)
      Vue.set(this.filteredData[fRowIndex], column, data)
    }
  }

  recoverData (init) {
    if (!init) {
      this.beforeSortData = deepCopy(this.filteredData)
      this.clearSortStatus()
      this.resolveSelectedData()
    }
  }

  updateToggleColumn (columnKeys, isAdd) {
    let _columnKeys = columnKeys || []
    let hasActionColumn = false
    let removeColIndex = -1
    this.centerColumns.forEach((col, index) => {
      if (col.key === 'action') hasActionColumn = true
      // 找出删除的toggle列
      if (!isAdd && col.toggle && _columnKeys.indexOf(col.key) < 0) {
        removeColIndex = index
      }
    })
    //
    let addColIndex = hasActionColumn ? this.centerColumns.length - 1 : this.centerColumns.length
    let addColKey = ''
    if (isAdd) {
      _columnKeys.forEach(_colKey => {
        let hasFound = false
        this.centerColumns.forEach(col => {
          if (col.key === _colKey) hasFound = true
        })
        if (!hasFound) addColKey = _colKey
      })
      let addCol = null
      if (addColKey) {
        this.toggleColumns.forEach(col => {
          if (col.key === addColKey) addCol = col
        })
      }
      if (addCol && addColIndex >= 0) {
        addCol._toggleShow = true
        this.centerColumns.splice(addColIndex, 0, addCol)
      }
    } else if (removeColIndex >= 0) {
      let removeCols = this.centerColumns.splice(removeColIndex, 1)
      if (removeCols.length) removeCols[0]._toggleShow = false
    }
  }

  getSortedToggleColumns () {
    let columns = []
    let columnKeys = []
    let deleteColumnInnerAttr = function (column) {
      delete column._index
      delete column._width
      delete column._sortType
      delete column._filterVisible
      delete column._isFiltered
      delete column._filterChecked
      delete column._toggleShow
      delete column._filterMultiple
    }
    // show columns
    this.centerColumns.forEach(col => {
      if (col.toggle) {
        columnKeys.push(col.key)
        let cloneColumn = Object.assign({}, col, {
          toggle: true,
          toggleShow: true,
          sortable: !!col.sortable
        })
        deleteColumnInnerAttr(cloneColumn)
        columns.push(cloneColumn)
      }
    })
    // hidden columns
    this.toggleColumns.forEach(col => {
      if (columnKeys.indexOf(col.key) < 0) {
        let cloneColumn = Object.assign({}, col, {
          toggle: true,
          toggleShow: false,
          sortable: !!col.sortable
        })
        deleteColumnInnerAttr(cloneColumn)
        columns.push(cloneColumn)
      }
    })
    return columns
  }

  toggleShowHideColumns (_idx) {
    let _fRow
    this.filteredData.forEach(fRow => {
      if (fRow._idx === _idx) {
        _fRow = fRow
      }
    })
    if (_fRow) {
      _fRow._showHideColumns = !_fRow._showHideColumns
    }
  }

  isShowHideColumns (_idx) {
    let _fRow
    this.filteredData.forEach(fRow => {
      if (fRow._idx === _idx) {
        _fRow = fRow
      }
    })
    if (_fRow) {
      return _fRow._showHideColumns
    }
    return false
  }

  editSelectedRow (column, value) {
    // column 来自table外部的定义
    // value 来自table外部的传值
    if (!column || !value) return
    // this.data[i].column = value
    this.selectedRowData.forEach(row => {
      row[column.key] = value
    })
    // this.filteredData[i].column = value
    this.filteredData.forEach(fRow => {
      if (fRow.__isChecked) {
        fRow[column.key] = value
      }
    })
  }

  clearSortStatus () {
    this.allColumns.forEach(column => {
      if (column.sortable !== 'server' && column._sortType !== 'normal') {
        column._sortType = 'normal'
      }
    })
  }

  toggleSelect (_index) {
    const fRow = this.getRowOfFilterData(_index) || {}
    if (_index !== fRow._index) {
      throw new Error('selected table row._index is not equal with data[_index]._index')
    }
    if (!fRow._isDisabled) {
      fRow._isChecked = !fRow._isChecked
      this.resolveSelectedData()
      this.$container.$emit('on-select-change')
    }
  }

  highlightRow (_index) {
    const fRow = this.getRowOfFilterData(_index) || {}
    if (_index !== fRow._index) {
      throw new Error('selected table row._index is not equal with data[_index]._index')
    }
    if (!fRow._isDisabled) {
      fRow._isHighlight = !fRow._isHighlight
    }
    this.resolveHightlightRowData()
    this.$container.$emit('on-select-change')
  }

  getRowOfFilterData (_index) {
    let idx = -1
    this.filteredData.forEach((fRow, index) => {
      if (fRow._index === _index) {
        idx = index
      }
    })
    return idx >= 0 ? this.filteredData[idx] : null
  }

  selectAll (isSelect) {
    this.filteredData.forEach(fRow => {
      if (!fRow._isDisabled) {
        if (isSelect) {
          fRow._isChecked = true
        } else {
          fRow._isChecked = false
        }
      }
    })
    this.resolveSelectedData()
    this.$container.$emit('on-select-change')
  }

  isSelectAll () {
    return this.filteredData.length === this.selectedRowData.length
  }
  hasSelect () {
    return this.selectedRowData.length > 0
  }

  resolveSelectedData () {
    let data = []
    this.filteredData.forEach(fRow => {
      let _index = fRow._index
      if (fRow._isChecked) {
        data.push(this.data[_index])
      }
    })
    this.selectedRowData = data
  }

  resolveHightlightRowData () {
    let data = []
    this.filteredData.forEach(fRow => {
      let _index = fRow._index
      if (fRow._isHighlight) {
        data.push(this.data[_index])
      }
    })
    this.highlightRowData = data
  }

  getSelectedData () {
    return this.selectedRowData
  }

  getHighlightRowData () {
    return this.highlightRowData
  }

  memoryBeforeSortData () {
    this.beforeSortData = deepCopy(this.filteredData)
  }

  sortData (column, type) {
    if (column.sortable !== 'custom') { // custom is for remote sort
      if (type === 'normal') {
        // 恢复排序前的数据
        this.filteredData = deepCopy(this.beforeSortData)
      } else {
        // 排序数据 ↑asc ↓desc
        this.sortDataByType(column, type)
      }
    }
    column._sortType = type
  }

  sortDataByType (column, type) {
    const key = column.key
    this.filteredData.sort((a, b) => {
      if (column.sortMethod) {
        return column.sortMethod(a[key], b[key], type)
      } else {
        if (type === 'asc') {
          return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0
        } else if (type === 'desc') {
          return a[key] < b[key] ? 1 : a[key] > b[key] ? -1 : 0
        } else {
          return 0
        }
      }
    })
  }

  setColumnSortType (column, type) {
    column._sortType = type
  }

  validate (column, validator) {
    if (!column) return false
    let valid = true
    let _validator = validator || function () { return true }
    this.filteredData.forEach(fRow => {
      let colVal = fRow[column]
      if (fRow._isChecked && !_validator(colVal)) valid = false
    })
    return valid
  }

  filterData (column) {
    //
  }
}
