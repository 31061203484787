<template>
  <modal v-model="showModal" title="选择用户组授权" width="550" @on-ok="onConfirm" :styles="{top: '60px'}">
    <tree ref="tree" v-model="groupVal" :data="groupList" :height="550"></tree>
  </modal>
</template>

<script>
import Modal from '../../modal/modal.vue'
import Tree from './group/tree'
import { authenticate, catchExpired } from '../../../../utils/auth'
export default {
  components: { Modal, Tree },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      groupVal: this.value,
      groupList: []
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    value (val) {
      if (Array.isArray(val)) {
        this.groupVal = val
      } else {
        this.groupVal = []
      }
    }
  },
  mounted () {
    this.loadGroupList()
  },
  methods: {
    onConfirm () {
      this.$emit('on-confirm-ok', this.$refs.tree.getLeafCheckedData())
    },
    loadGroupList () {
      this.$http.get(`${this.httpRoot}/admin/user/grouptree/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.groupList = res.treeList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="css">
</style>
