import { updateStorageCache, updateStorageItem } from '../utils/cache'
import { login as loginCache, logout as logoutCache } from '../utils/auth'
// vuex actions
export function updateLastPath ({commit}, path) {
  commit('UPDATE_LASTPATH', path)
}

export function updateCurrentPath ({commit}, path) {
  commit('UPDATE_CURRENTPATH', path)
}

export function login ({commit}, token) {
  loginCache(token)
  commit('LOGIN')
}

export function logout ({commit}) {
  logoutCache()
  commit('LOGOUT')
}

export function updateUserName ({commit}, name) {
  commit('UPDATE_USERNAME', name)
}

export function updateWinHeight ({commit}, height) {
  commit('UPDATE_WIN_HEIGHT', height)
}

export function updateWinWidth ({commit}, width) {
  commit('UPDATE_WIN_WIDTH', width)
}

export function updateProjectId ({commit}, projectId) {
  commit('UPDATE_PROJECTID', projectId)
}

export function updateFreezerList ({commit}, freezerList) {
  commit('UPDATE_FREEZERLIST', freezerList)
}

export function updateContainers ({commit}, containers) {
  commit('UPDATE_CONTAINERS', containers)
}

export function updateCachedContainers ({commit}, {containers, item}) {
  commit('UPDATE_CACHED_CONTAINERS', {containers, item})
}

// for manage add 2020.6.24
export function clearContainerCache ({commit}) {
  commit('CLEAR_CONTAINER_CACHE')
}

export function updateEditStatus ({commit}, {containerType, status}) {
  if (containerType === 'freezer') {
    commit('UPDATE_FREEZER_EDIT_ACTIVE', status)
  } else if (containerType === 'shelf') {
    commit('UPDATE_SHELF_EDIT_ACTIVE', status)
  } else if (containerType === 'layer') {
    commit('UPDATE_LAYER_EDIT_ACTIVE', status)
  } else if (containerType === 'samplebox') {
    commit('UPDATE_SAMPLEBOX_EDIT_ACTIVE', status)
  } else if (containerType === 'strawbox') {
    commit('UPDATE_STRAWBOX_EDIT_ACTIVE', status)
  }
}

export function updateFileRoot ({commit}, ipPort) {
  commit('UPDATE_FILE_ROOT', ipPort)
}

export function updateDesktop ({commit}, desktop) {
  commit('UPDATE_DESKTOP', desktop)
}

export function updateDB ({commit}, db) {
  commit('UPDATE_DB', db)
}

export function updateDBConn ({commit}, status) {
  commit('UPDATE_DB_CONN', status)
}

export function updateResColumnMap ({commit}, {key, columns}) {
  commit('UPDATE_COLUMNMAP', {key, columns})
}

export function updateFilterMap ({commit}, {key, filters}) {
  commit('UPDATE_FILTERMAP', {key, filters})
}
