const state = {
  desktop: {}
}
const mutations = {
  UPDATE_DESKTOP (state, desktop) {
    state.desktop = desktop
  }
}

export default {
  state,
  mutations
}
