<template>
  <div class="picker-panel-body-wrapper">
    <div class="picker-panel-body">
      <div class="time-picker-header" v-if="showDate">{{ visibleDate }}</div>
      <div class="picker-panel-content">
        <time-spinner
          ref="timeSpinner"
          :show-seconds="showSeconds"
          :hours="hours"
          :minutes="minutes"
          :seconds="seconds"
          :disabled-hours="disabledHours"
          :disabled-minutes="disabledMinutes"
          :disabled-seconds="disabledSeconds"
          :hide-disabled-options="hideDisabledOptions"
          @on-change="handleChange"
          @on-pick-click="handlePickClick">
        </time-spinner>
      </div>
      <Confirm
        v-if="confirm"
        @on-pick-clear="handlePickClear"
        @on-pick-success="handlePickSuccess">
      </Confirm>
    </div>
  </div>
</template>

<script>
import TimeSpinner from '../base/time-spinner'
import Confirm from '../base/confirm'

import Mixin from './mixin'

import { initTimeDate } from '../util'

const months = ['1 月', '2 月', '3 月', '4 月', '5 月', '6 月', '7 月', '8 月', '9 月', '10 月', '11 月', '12 月']

export default {
  mixins: [ Mixin ],
  components: { TimeSpinner, Confirm },
  data () {
    return {
      date: initTimeDate(),
      value: '',
      showDate: false,
      format: 'HH:mm:ss',
      hours: '',
      minutes: '',
      seconds: '',
      disabledHours: [],
      disabledMinutes: [],
      disabledSeconds: [],
      hideDisabledOptions: false,
      confirm: false
    }
  },
  computed: {
    showSeconds () {
      return (this.format || '').indexOf('ss') !== -1
    },
    visibleDate () {
      const date = this.date
      const month = date.getMonth()
      const tYear = '年'
      const tMonth = months[month]
      return `${date.getFullYear()}${tYear} ${tMonth}`
    }
  },
  watch: {
    value (newVal) {
      if (!newVal) return
      newVal = new Date(newVal)
      if (!isNaN(newVal)) {
        this.date = newVal
        this.handleChange({
          hours: newVal.getHours(),
          minutes: newVal.getMinutes(),
          seconds: newVal.getSeconds()
        }, false)
      }
    }
  },
  methods: {
    handleClear () {
      this.date = initTimeDate()
      this.hours = ''
      this.minutes = ''
      this.seconds = ''
    },
    handleChange (date, emit = true) {
      if (date.hours !== undefined) {
        this.date.setHours(date.hours)
        this.hours = this.date.getHours()
      }
      if (date.minutes !== undefined) {
        this.date.setMinutes(date.minutes)
        this.minutes = this.date.getMinutes()
      }
      if (date.seconds !== undefined) {
        this.date.setSeconds(date.seconds)
        this.seconds = this.date.getSeconds()
      }
      if (emit) this.$emit('on-pick', this.date, true)
    },
    updateScroll () {
      this.$refs.timeSpinner.updateScroll()
    }
  },
  mounted () {
    if (this.$parent && this.$parent.$options.name === 'DatePicker') this.showDate = true
  }
}
</script>
