<template>
  <div class="panel-crf-table">
    <div class="panel-edit-title">(crfTable) CRF表编辑</div>
    <y-form ref="currentDataForm" :model="currentData" :rules="currentDataRule" label-width="100px" item-width="70%">
      <form-item label="唯一编码" prop="modelId">
        <y-input v-model="currentData.modelId" disabled></y-input>
      </form-item>
      <form-item label="显示名称" prop="title">
        <y-input v-model="currentData.title"></y-input>
      </form-item>
      <form-item label="集合名称" prop="collection">
        <y-input v-model="currentData.collection"></y-input>
      </form-item>
      <form-item label="图标">
        <y-select v-model="currentData.icon" :data="iconList" clearable showIcon></y-select>
      </form-item>
      <form-item label="是否显示分数" prop="hasScore">
        <checklist inline v-model="currentData.hasScore" :items="scoreList"></checklist>
      </form-item>
      <div class="split"></div>
      <form-item label="角色">
        <y-select v-model="currentData.role" :data="roleList" multiple></y-select>
      </form-item>
      <form-item label="是否可见" prop="visible">
        <checklist inline v-model="currentData.visible" :items="visibleList"></checklist>
      </form-item>
      <form-item label="所属课题" prop="projectId">
        <y-select v-model="currentData.subjectIdList" :data="subjectList" disabled multiple></y-select>
      </form-item>
      <form-item label="访问控制">
        <y-button @click.native="onAuthCrfTable">所属课题</y-button>
        (根据课题过滤CRFTable)
      </form-item>
    </y-form>
    <div class="split"></div>
    <div style="padding-left:100px">
      <y-button type="primary" @click.native="save">保存&更新</y-button>
    </div>
    <auth-win
      v-model="currentSubjectIdList"
      :projectId="projectId"
      :show="showAuthWin"
      @on-hide="showAuthWin=false"
      @on-confirm-ok="authCrfTable"></auth-win>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import YButton from '../../common/button/button'
import Checklist from '../../common/checklist/checklist'
import AuthWin from '../win/auth-win'
import Emitter from '../../common/mixins/emitter'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: {YForm, FormItem, YSelect, YInput, YButton, Checklist, AuthWin},
  mixins: [Emitter],
  props: {
    data: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      currentData: this.getData(this.data),
      iconList: [
        {name: 'user-md', value: 'user-md', icon: 'user-md'},
        {name: 'venus-double', value: 'venus-double', icon: 'venus-double'},
        {name: 'heart-o', value: 'heart-o', icon: 'heart-o'},
        {name: 'cut', value: 'cut', icon: 'cut'},
        {name: 'medkit', value: 'medkit', icon: 'medkit'},
        {name: 'h-square', value: 'h-square', icon: 'h-square'},
        {name: 'hospital-o', value: 'hospital-o', icon: 'hospital-o'},
        {name: 'heartbeat', value: 'heartbeat', icon: 'heartbeat'},
        {name: 'stethoscope', value: 'stethoscope', icon: 'stethoscope'}
      ],
      scoreList: [
        {key: '1', title: '显示', value: true},
        {key: '0', title: '不显示', value: false}
      ],
      roleList: [
        {name: '医生', value: 'doctor'},
        {name: '患者', value: 'patient'},
        {name: '助理', value: 'assistant'}
      ],
      visibleList: [
        {key: '1', title: '可见', value: true},
        {key: '0', title: '不可见', value: false}
      ],
      currentDataRule: {
        modelId: [{required: true, message: '不能为空', trigger: 'change'}],
        title: [{required: true, message: '不能为空', trigger: 'blur'}],
        collection: [{required: true, message: '不能为空', trigger: 'blur'}],
        hasScore: [{type: 'boolean', required: true, message: '不能为空', trigger: 'change'}]
      },
      showAuthWin: false,
      subjectList: [],
      currentSubjectIdList: []
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.currentData = this.getData(val)
        this.currentSubjectIdList = this.currentData.subjectIdList || []
        this.loadSubjectList()
      }
    }
  },
  mounted () {
    this.loadSubjectList()
  },
  methods: {
    getData (data) {
      var cData = Object.assign({}, {
        modelId: '',
        title: '',
        collection: '',
        icon: '',
        hasScore: false,
        visible: true
      }, data)
      var ary = cData.collection.split('crf_')
      if (ary.length === 2) {
        cData.collection = ary[1]
      } else if (ary.length === 1) {
        cData.collection = ary[0]
      } else {
        cData.collection = ''
      }
      return cData
    },
    recoverData (data) {
      var cData = Object.assign({}, data)
      cData.collection = 'crf_' + cData.collection
      return cData
    },
    save () {
      const data = this.recoverData(this.currentData)
      this.$refs.currentDataForm.validate()
      if (!this.$refs.currentDataForm.isValid) return false
      // ... update
      this.dispatch('CRF', 'on-crftable-update', data)
    },
    onAuthCrfTable () {
      this.showAuthWin = true
    },
    authCrfTable (subjectList) {
      // console.log(subjectList)
      let idList = subjectList.map(item => { return item.id })
      this.currentSubjectIdList = idList
      this.currentData.subjectIdList = idList
      // this.$http.post(`${this.httpRoot}/crfTable/authorize`, {
      //   projectId: this.projectId,
      //   subjectId: codeList[0]
      // }, authenticate())
      // .then(res => res.json())
      // .then(res => {
      //   if (res.status === 'success') {
      //     this.$Message.success('授权成功')
      //     this.currentSubjectId = codeList[0]
      //   } else {
      //     this.$Message.error(res.statusText)
      //   }
      // })
      // .catch(err => {
      //   catchExpired(err, this)
      //   this.$Message.error('授权失败')
      // })
    },
    loadSubjectList () {
      this.$http.get(`${this.httpRoot}/subject/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.subjectList = res.subjectList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="css">
.split {
  border-top: 1px dashed #282828;
  margin-bottom: 10px;
}
</style>
