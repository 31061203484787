<template>
  <div class="field-tree" :style="{overflow: 'auto', height: pageHeight + 'px'}">
    <field-tree-node
      :node="rootNode"
      :props="props"
      :key="getKeyField(rootNode)">
    </field-tree-node>
    <field-win :show="showFieldWin"
      @on-hide="showFieldWin=false"
      @on-confirm-ok="onFieldAdd"></field-win>
    <item-win :show="showItemWin"
      @on-hide="showItemWin=false"
      @on-confirm-ok="onItemAdd"></item-win>
    <group-win :show="showGroupWin"
      @on-hide="showGroupWin=false"
      @on-confirm-ok="onGroupAdd"></group-win>
    <child-win :show="showChildWin"
      @on-hide="showChildWin=false"
      @on-confirm-ok="onChildAdd"></child-win>
    <sub-child-win :show="showSubChildWin"
      @on-hide="showSubChildWin=false"
      @on-confirm-ok="onSubChildAdd"></sub-child-win>
  </div>
</template>

<script>
import TreeStore from './model/fieldTree-store'
import FieldTreeNode from './field-tree-node'
import FieldWin from '../win/field-win'
import ItemWin from '../win/item-win'
import GroupWin from '../win/group-win'
import ChildWin from '../win/child-win'
import SubChildWin from '../win/subChild-win'
import Emitter from '../../common/mixins/emitter'
import mixin from './mixin'

export default {
  components: {FieldTreeNode, FieldWin, ItemWin, GroupWin, ChildWin, SubChildWin},
  mixins: [Emitter, mixin],
  props: {
    title: String,
    data: Array,
    modelId: String,
    props: {
      default () {
        return {
          fields: 'fields',
          items: 'items',
          group: 'group',
          children: 'children',
          subChildren: 'subChildren',
          label: 'title',
          value: 'value',
          type: 'type',
          key: 'key',
          icon: 'icon'
        }
      }
    },
    indent: {
      type: Number,
      default: 16
    }
  },
  data () {
    return {
      $tree: null,
      $currentTreeNode: null, // for hide type list
      treeStore: null,
      rootNode: null,
      currentNode: null,

      showFieldWin: false,
      showItemWin: false,
      showGroupWin: false,
      showChildWin: false,
      showSubChildWin: false,
      addedNodeType: ''
    }
  },
  computed: {
    pageHeight () {
      return this.$store.getters.getWinHeight - 55
    }
  },
  watch: {
    data (val) {
      this.treeStore = new TreeStore({
        key: this.modelId,
        title: this.title,
        data: val,
        props: this.props
      })
      this.rootNode = this.treeStore.rootNode
    }
  },
  created () {
    this.$tree = this
    this.treeStore = new TreeStore({
      key: this.modelId,
      title: this.title,
      data: this.data,
      props: this.props
    })
    this.rootNode = this.treeStore.rootNode

    this.$on('on-typeList-click', type => {
      this.onTypeListClick(type)
    })
    this.$on('on-node-click', (data, type) => {
      this.$emit('on-tree-node-click', data, type)
    })
  },
  methods: {
    hideTypeList () {
      if (this.$currentTreeNode) {
        this.$currentTreeNode.hideTypeList()
      }
    },
    onTypeListClick (type) {
      this.addedNodeType = type
      if (type === 'field') {
        this.showFieldWin = true
      } else if (type === 'item' || type === 'gitem') {
        this.showItemWin = true
      } else if (type === 'group') {
        this.showGroupWin = true
      } else if (type === 'child') {
        this.showChildWin = true
      } else if (type === 'subChild') {
        this.showSubChildWin = true
      }
    },
    onFieldAdd (field) {
      if (this.$currentTreeNode) {
        this.$currentTreeNode.insertChild(field, this.addedNodeType)
      }
    },
    onItemAdd (item) {
      // item and gitem
      if (this.$currentTreeNode) {
        this.$currentTreeNode.insertChild(item, this.addedNodeType)
      }
    },
    onGroupAdd (group) {
      if (this.$currentTreeNode) {
        this.$currentTreeNode.insertChild(group, this.addedNodeType)
      }
    },
    onChildAdd (child) {
      if (this.$currentTreeNode) {
        this.$currentTreeNode.insertChild(child, this.addedNodeType)
      }
    },
    onSubChildAdd (subChild) {
      if (this.$currentTreeNode) {
        this.$currentTreeNode.insertChild(subChild, this.addedNodeType)
      }
    },
    getNodeByKey (key) {
      return this.treeStore.getNodeByKey(key)
    },
    getFieldsData () {
      return this.treeStore.getFieldsData()
    },
    replicateFields () {
      return this.treeStore.replicateFields()
    }
  }
}
</script>

<style lang="css">
</style>
