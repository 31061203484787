<template>
  <div class="picker-panel-body-wrapper time-picker-with-range" :class="{'time-picker-with-seconds': showSeconds}">
    <div class="picker-panel-body">
      <div class="picker-panel-content picker-panel-content-left">
        <div class="time-picker-header">
          <template v-if="showDate">{{ visibleDate }}</template>
          <template v-else>{{ '开始时间' }}</template>
        </div>
        <time-spinner
          ref="timeSpinner"
          :show-seconds="showSeconds"
          :hours="hours"
          :minutes="minutes"
          :seconds="seconds"
          :disabled-hours="disabledHours"
          :disabled-minutes="disabledMinutes"
          :disabled-seconds="disabledSeconds"
          :hide-disabled-options="hideDisabledOptions"
          @on-change="handleStartChange"
          @on-pick-click="handlePickClick">
        </time-spinner>
      </div>
      <div class="picker-panel-content picker-panel-content-right">
        <div class="time-picker-header">
          <template v-if="showDate">{{ visibleDateEnd }}</template>
          <template v-else>{{ '结束时间' }}</template>
        </div>
        <time-spinner
          ref="timeSpinnerEnd"
          :show-seconds="showSeconds"
          :hours="hoursEnd"
          :minutes="minutesEnd"
          :seconds="secondsEnd"
          :disabled-hours="disabledHours"
          :disabled-minutes="disabledMinutes"
          :disabled-seconds="disabledSeconds"
          :hide-disabled-options="hideDisabledOptions"
          @on-change="handleEndChange"
          @on-pick-click="handlePickClick">
        </time-spinner>
      </div>
      <Confirm
        v-if="confirm"
        @on-pick-clear="handlePickClear"
        @on-pick-success="handlePickSuccess">
      </Confirm>
    </div>
  </div>
</template>

<script>
import TimeSpinner from '../base/time-spinner.vue'
import Confirm from '../base/confirm.vue'

import Mixin from './mixin'

import { initTimeDate, toDate, formatDate } from '../util'

const months = ['1 月', '2 月', '3 月', '4 月', '5 月', '6 月', '7 月', '8 月', '9 月', '10 月', '11 月', '12 月']

export default {
  mixins: [ Mixin ],
  components: { TimeSpinner, Confirm },
  data () {
    return {
      format: 'HH:mm:ss',
      showDate: false,
      date: initTimeDate(),
      dateEnd: initTimeDate(),
      value: '',
      hours: '',
      minutes: '',
      seconds: '',
      hoursEnd: '',
      minutesEnd: '',
      secondsEnd: '',
      disabledHours: [],
      disabledMinutes: [],
      disabledSeconds: [],
      hideDisabledOptions: false,
      confirm: false
    }
  },
  computed: {
    showSeconds () {
      return (this.format || '').indexOf('ss') !== -1
    },
    visibleDate () {
      const date = this.date || initTimeDate()
      const tYear = '年'
      const month = date.getMonth()
      const tMonth = months[month]
      return `${date.getFullYear()}${tYear} ${tMonth}`
    },
    visibleDateEnd () {
      const date = this.dateEnd || initTimeDate()
      const tYear = '年'
      const month = date.getMonth()
      const tMonth = months[month]
      return `${date.getFullYear()}${tYear} ${tMonth}`
    }
  },
  watch: {
    value (newVal) {
      if (!newVal) return
      if (Array.isArray(newVal)) {
        const valStart = newVal[0] ? toDate(newVal[0]) : false
        const valEnd = newVal[1] ? toDate(newVal[1]) : false

        if (valStart && valEnd) {
          this.handleChange({
            hours: valStart.getHours(),
            minutes: valStart.getMinutes(),
            seconds: valStart.getSeconds()
          }, {
            hours: valEnd.getHours(),
            minutes: valEnd.getMinutes(),
            seconds: valEnd.getSeconds()
          }, false)
        }
      }
    }
  },
  mounted () {
    if (this.$parent && this.$parent.$options.name === 'DatePicker') this.showDate = true
  },
  methods: {
    handleClear () {
      this.date = initTimeDate()
      this.dateEnd = initTimeDate()
      this.hours = ''
      this.minutes = ''
      this.seconds = ''
      this.hoursEnd = ''
      this.minutesEnd = ''
      this.secondsEnd = ''
    },
    handleChange (date, dateEnd, emit = true) {
      const oldDateEnd = new Date(this.dateEnd)

      if (date.hours !== undefined) {
        this.date.setHours(date.hours)
        this.hours = this.date.getHours()
      }
      if (date.minutes !== undefined) {
        this.date.setMinutes(date.minutes)
        this.minutes = this.date.getMinutes()
      }
      if (date.seconds !== undefined) {
        this.date.setSeconds(date.seconds)
        this.seconds = this.date.getSeconds()
      }
      if (dateEnd.hours !== undefined) {
        this.dateEnd.setHours(dateEnd.hours)
        this.hoursEnd = this.dateEnd.getHours()
      }
      if (dateEnd.minutes !== undefined) {
        this.dateEnd.setMinutes(dateEnd.minutes)
        this.minutesEnd = this.dateEnd.getMinutes()
      }
      if (dateEnd.seconds !== undefined) {
        this.dateEnd.setSeconds(dateEnd.seconds)
        this.secondsEnd = this.dateEnd.getSeconds()
      }
      // judge endTime > startTime?
      if (this.dateEnd < this.date) {
        this.$nextTick(() => {
          this.dateEnd = new Date(this.date)
          this.hoursEnd = this.dateEnd.getHours()
          this.minutesEnd = this.dateEnd.getMinutes()
          this.secondsEnd = this.dateEnd.getSeconds()

          const format = 'yyyy-MM-dd HH:mm:ss'
          if (formatDate(oldDateEnd, format) !== formatDate(this.dateEnd, format)) {
            if (emit) this.$emit('on-pick', [this.date, this.dateEnd], true)
          }
        })
      } else {
        if (emit) this.$emit('on-pick', [this.date, this.dateEnd], true)
      }
    },
    handleStartChange (date) {
      this.handleChange(date, {})
    },
    handleEndChange (date) {
      this.handleChange({}, date)
    },
    updateScroll () {
      this.$refs.timeSpinner.updateScroll()
      this.$refs.timeSpinnerEnd.updateScroll()
    }
  }
}
</script>
