<template>
  <div>
    <div class="crf-list-panel-15" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <dataset-tree
        ref="pdatasetTree"
        title="patientDataset"
        datasetType="patientDataset"
        :data="pDatasetList"
        :datasourceList="datasourceList"
        :crftableList="crftableList"
        @on-tree-node-click="onPDatasetClick"
        @on-tree-node-delete="onDatasetDelete"
        @on-tree-node-add="onPDatasetAdd">
      </dataset-tree>
      <dataset-tree
        ref="datasetTree"
        title="数据集dataset"
        :data="datasetList"
        :datasourceList="datasourceList"
        :crftableList="crftableList"
        @on-tree-node-click="onDatasetClick"
        @on-tree-node-delete="onDatasetDelete"
        @on-tree-node-add="onDatasetAdd">
      </dataset-tree>
    </div>
    <div class="crf-list-panel-65" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <panel-dataset-patient v-if="panelView==='patientDataset'" :data="currentPDataset" :datasourceList="datasourceList"
        @on-json-preview="onJsonPreview"
        @on-table-preview="onTablePreview"></panel-dataset-patient>
      <panel-dataset v-if="panelView==='dataset'" :data="currentDataset" :datasourceList="datasourceList" :crftableList="crftableList"
        @on-json-preview="onJsonPreview"
        @on-table-preview="onTablePreview"></panel-dataset>
    </div>
    <div class="crf-list-panel-20" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <preview-win :show="showJsonPreview" @on-hide="showJsonPreview=false" :data="previewData"></preview-win>
    </div>
    <preview-table-win :show="showTablePreview" @on-hide="showTablePreview=false" :data="previewData" :fields="previewFields"></preview-table-win>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>
<script>
import DatasetTree from './field/field-tree'
import YSelect from '../common/select/select'
import Loading from '../common/loading/loading'
import PanelDatasetPatient from './panel/panel-dataset-patient'
import PanelDataset from './panel/panel-dataset'
import PreviewTableWin from './win/preview-table-win'
import PreviewWin from './win/preview-win'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  name: 'DataExtract',
  components: {DatasetTree, YSelect, Loading, PanelDatasetPatient, PanelDataset, PreviewTableWin, PreviewWin},
  data () {
    return {
      datasourceList: [],
      showLoading: false,
      crftableList: [],

      panelView: '',
      datasetList: [],
      currentDataset: {},

      currentPDataset: {},

      showTablePreview: false,
      showJsonPreview: false,
      previewData: '',
      previewFields: [],

      pDatasetList: []
    }
  },
  mounted () {
    this.loadDatasetList()
    this.loadDatasourceList()
    this.loadCrftableList()
    this.loadPDatasetList()
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId // in manage questId is projectId, quest-item-list 中无效
    },
    pageHeight () {
      return this.$store.getters.getWinHeight - 60
    }
  },
  watch: {
    projectId (val) {
      if (val) {
        this.loadDatasetList()
        this.loadPDatasetList()
        this.loadDatasourceList()
        this.loadCrftableList()
      }
    }
  },
  created () {
    this.$on('on-dataset-save', (dataset) => {
      this.updateDataset(dataset)
    })
    this.$on('on-pdataset-save', (dataset) => {
      this.onPDatasetAdd(dataset)
    })
  },
  methods: {
    loadCrftableList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/crf/crfTable/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success' && Array.isArray(res.crfTableList)) {
          this.crftableList = res.crfTableList
        } else if (res.status === 'success') {
          this.crftableList = []
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadDatasourceList () {
      this.$http.get(`${this.httpRoot}/dataset/datasource/list`, authenticate({params: {projectId: this.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.datasourceList = res.datasourceList || []
          this.updateLoadStatus('load-success')
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '加载数据表失败')
      })
    },
    loadPDatasetList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/dataset/patient`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.pDatasetList = res.datasetList || []
        } else {
          this.showLoading = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadDatasetList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/dataset/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.datasetList = res.datasetList || []
        } else {
          this.showLoading = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    updateDataset (data) {
      delete data.patientList // 保存模型删除patientList
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/dataset/update`, {
        dataset: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    onDatasetDelete (data) {
      console.log('delete', data)
      this.updateLoadStatus('load-start', '')
      this.$http.post(`${this.httpRoot}/dataset/delete`, {id: data.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success')
          this.loadDatasetList()
        } else {
          this.updateLoadStatus('load-faild')
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '服务异常，查询失败')
      })
    },
    onPDatasetAdd (data) {
      // patientDataset 没有add接口,供用update
      console.log('add pdataset', data)
      this.updateLoadStatus('load-start', '')
      this.$http.post(`${this.httpRoot}/dataset/patient/update`, {dataset: data}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success')
          this.loadPDatasetList()
        } else {
          this.updateLoadStatus('load-faild')
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '服务异常，查询失败')
      })
    },
    onDatasetAdd (data) {
      console.log('add dataset', data)
      this.updateLoadStatus('load-start', '')
      this.$http.post(`${this.httpRoot}/dataset/add`, {dataset: data, projectId: this.projectId}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success')
          this.loadDatasetList()
        } else {
          this.updateLoadStatus('load-faild')
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '服务异常，查询失败')
      })
    },
    onPDatasetClick (data, type) {
      // console.log(data, type)
      this.panelView = type
      this.$refs.datasetTree.unfocusAll()
      this.previewData = ''
      setTimeout(() => {
        if (this.panelView === 'patientDataset') {
          this.currentPDataset = data
          this.currentDataset = {}
        } else {
          this.currentDataset = {}
          this.currentPDataset = {}
        }
      }, 0)
    },
    onDatasetClick (data, type) {
      // console.log(data, type)
      this.panelView = type
      this.$refs.pdatasetTree.unfocusAll()
      this.previewData = ''
      setTimeout(() => {
        if (this.panelView === 'dataset') {
          this.currentDataset = data
          this.currentPDataset = {}
        } else {
          this.currentDataset = {}
          this.currentPDataset = {}
        }
      }, 0)
    },
    loadPreview (data, callback) {
      this.updateLoadStatus('load-start', '')
      this.$http.post(`${this.httpRoot}/wsdl/preview/query`, data, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', `预览成功: ${res.dataList ? res.dataList.length : 'null'}`)
          if (typeof callback === 'function') {
            callback(res.dataList || [])
          }
        } else {
          this.showLoading = false
          this.$Modal.error({
            title: '预览失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '服务异常，查询失败')
      })
    },
    onJsonPreview (datasourceId, callFunc, patientList, rowToCol) {
      let param = {datasourceId, callFunc, patientList}
      if (rowToCol) {
        Object.assign(param, {rowToCol})
      }
      this.previewData = ''
      this.loadPreview(param, data => {
        this.previewData = data
        this.showJsonPreview = true
      })
    },
    onTablePreview (datasourceId, callFunc, patientList, rowToCol, fieldMapping, columns) {
      let param = {datasourceId, callFunc, patientList}
      if (rowToCol) {
        Object.assign(param, {rowToCol})
      }
      if (fieldMapping) {
        Object.assign(param, {fieldMapping})
      }
      this.previewData = ''
      // update table columns
      this.previewFields = columns
      this.loadPreview(param, data => {
        this.previewData = data
        this.showTablePreview = true
      })
    },
    updateLoadStatus (status, loadingText) {
      if (status === 'load-start') {
        this.loadingText = loadingText
        this.showLoading = true
      } else if (status === 'load-success') {
        this.showLoading = false
        if (loadingText) {
          this.$Message.success(loadingText)
        }
      } else if (status === 'load-faild') {
        this.showLoading = false
        this.$Message.error(loadingText)
      }
    }
  }
}
</script>

<style lang="less">
.crf-list-panel-15 {
  width: 15%;
  border-right: 1px solid #dfdfdf;
  float: left;
}
.crf-list-panel-20 {
  width: 20%;
  border-right: 1px solid #dfdfdf;
  float: left;
}
.crf-list-panel-65 {
  width: 65%;
  border-right: 1px solid #dfdfdf;
  float: left;
}
.crftable-panels * {
  box-sizing: content-box;
}
</style>
