<template>
  <div class="panel-crf-table">
    <div class="panel-edit-title">(crf) CRF编辑</div>
    <y-form ref="currentCrfForm" :model="currentCrf" :rules="currentCrfRule" label-width="100px" item-width="250px">
      <form-item label="唯一编码" prop="id">
        <y-input v-model="currentCrf.id" disabled></y-input>
      </form-item>
      <form-item label="显示名称" prop="title">
        <y-input v-model="currentCrf.title"></y-input>
      </form-item>
      <form-item label="是否默认" prop="default">
        <checkbox v-model="currentCrf.default">(新建患者生成一个默认CRF随访实例)</checkbox>
      </form-item>
      <form-item label="所属课题" prop="projectId">
        <y-select v-model="currentCrf.subjectId" :data="subjectList" disabled></y-select>
      </form-item>
      <form-item label="访问控制">
        <y-button @click.native="onAuthCrf">所属课题</y-button>
        (根据课题过滤CRF)
      </form-item>
    </y-form>
    <auth-win
      v-model="currentSubjectId"
      :projectId="projectId"
      :show="showAuthWin"
      @on-hide="showAuthWin=false"
      @on-confirm-ok="authCrf"></auth-win>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YInput from '../../common/input/input'
import YSelect from '../../common/select/select'
import Checkbox from '../../common/checkbox/checkbox'
import YButton from '../../common/button/button'
import AuthWin from '../win/auth-win'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: {YForm, FormItem, YInput, YSelect, Checkbox, YButton, AuthWin},
  props: {
    currentCrf: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      currentSubjectId: this.currentCrf.subjectId || '',
      showAuthWin: false,
      currentCrfRule: {
        id: [{required: true, message: '不能为空', trigger: 'change'}],
        title: [{required: true, message: '不能为空', trigger: 'blur'}]
      },
      subjectList: []
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    }
  },
  watch: {
    currentCrf (val) {
      if (val && val.subjectId) {
        this.currentSubjectId = val.subjectId
      } else {
        this.currentSubjectId = ''
      }
    },
    projectId (val) {
      this.loadSubjectList()
    }
  },
  mounted () {
    this.loadSubjectList()
  },
  methods: {
    onAuthCrf () {
      this.showAuthWin = true
    },
    authCrf (groupList) {
      let codeList = groupList.map(item => { return item.code })
      this.$http.post(`${this.httpRoot}/crf/authorize`, {
        projectId: this.projectId,
        crfId: this.currentCrf.id,
        subjectId: codeList[0]
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('授权成功')
          this.currentSubjectId = codeList[0]
          this.currentCrf.subjectId = codeList[0]
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('授权失败')
      })
    },
    loadSubjectList () {
      this.$http.get(`${this.httpRoot}/subject/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.subjectList = res.subjectList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="css">
.panel-edit-title {
  font-size: 14px;
  height: 38px;
  line-height: 38px;
  padding-left: 100px;
}
</style>
