<template>
  <div class="panel-field">
    <div class="panel-edit-title">(subChild) 换行子输入编辑</div>
    <y-form ref="subChildForm" :model="subChild" :rules="subChildRule" label-width="100px" item-width="70%">
      <form-item label="唯一编码" prop="key">
        <y-input v-model="subChild.key" disabled></y-input>
      </form-item>
      <form-item label="显示名称">
        <y-input v-model="subChild.title"></y-input>
      </form-item>
      <form-item label="保存值类型" prop="valueType">
        <y-select v-model="subChild.valueType" :data="valueTypeList"></y-select>
      </form-item>
      <form-item label="单位">
        <y-input v-model="subChild.unit"></y-input>
      </form-item>
      <form-item label="是否必填">
        <checklist inline v-model="subChild.required" :items="boolList"></checklist>
      </form-item>
      <form-item label="报告子标题">
        <y-input v-model="subChild.reportTitle"></y-input>
      </form-item>
    </y-form>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import Checklist from '../../common/checklist/checklist'
export default {
  components: { YForm, FormItem, YSelect, YInput, Checklist },
  props: {
    data: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      subChild: this.data,
      valueTypeList: [
        {name: '字符串', value: 'string'},
        {name: '日期', value: 'date'}, // 后台存储 Date, 返回前台string
        {name: '数值', value: 'number'},
        {name: '整数', value: 'integer'}
      ],
      subChildRule: {
        key: [{required: true, message: '不能为空', trigger: 'change'}],
        valueType: [{required: true, message: '不能为空', trigger: 'change'}]
      },
      boolList: [
        {key: '1', title: '是', value: true},
        {key: '0', title: '否', value: false}
      ]
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.subChild = val
      }
    }
  },
  methods: {
    //
  }
}
</script>

<style lang="css">
</style>
