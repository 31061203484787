<template>
  <div class="param-item-r2c">
    <input type="checkbox" class="param-check" v-model="checked">
    <span class="param-label">keyField:</span>
    <select class="param-val select" :value="currentValue.keyField" @change="onKeyChange">
      <option v-for="item of data" :key="item">{{item}}</option>
    </select>
    <span class="param-label">valueField:</span>
    <select class="param-val select" :value="currentValue.valueField" @change="onValueChange">
      <option v-for="item of data" :key="item">{{item}}</option>
    </select>
    <span class="btn action-btn" @click="onLoadColList">加载colList</span>
    <span class="btn action-btn" v-show="checked" @click="onJsonPreview">JSON预览</span>
  </div>
</template>

<script>
import YSelect from '../../common/select/select'
import Emitter from '../../mixins/emitter'
export default {
  components: {YSelect},
  mixins: [Emitter],
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      checked: this.isCheck(this.value),
      currentValue: this.value || {}
    }
  },
  watch: {
    value (val, oldVal) {
      // console.log('watch value', JSON.stringify(val), JSON.stringify(this.currentValue))
      if (val && JSON.stringify(this.currentValue) !== JSON.stringify(val)) {
        this.currentValue = val
        this.checked = this.isCheck(val)
      }
    },
    currentValue (val) {
      this.emitChange(val)
    },
    checked (val) {
      if (val) {
        this.emitChange(this.currentValue)
      } else {
        // console.log('emit null {}')
        this.currentValue = {}
        this.$emit('input', {})
        this.dispatch('FormItem', 'on-form-change', {})
        this.$emit('on-change', {})
      }
    }
  },
  methods: {
    emitChange (val) {
      if (val && val.keyField && val.valueField && this.checked) {
        // console.log('emit changed', JSON.stringify(val))
        this.$emit('input', val)
        this.dispatch('FormItem', 'on-form-change', val)
        this.$emit('on-change', val)
      }
    },
    isCheck (data) {
      if (data && data.keyField && data.valueField) {
        return true
      }
      return false
    },
    onKeyChange (e) {
      this.currentValue.keyField = e.target.value
      this.emitChange(this.currentValue)
    },
    onValueChange (e) {
      this.currentValue.valueField = e.target.value
      this.emitChange(this.currentValue)
    },
    onLoadColList () {
      this.$emit('on-loadcollist')
    },
    onJsonPreview () {
      this.$emit('on-rol2col-json-preview')
    }
  }
}
</script>

<style lang="less">
.param-item-r2c {
  display: flex;
  align-items: center;
  .param-check {
    width: 40px;
  }
  .param-label {
    margin-left: 10px;
  }
  .param-val {
    width: 30%;
    &.select {
      outline: 0;
      height: 32px;
    }
  }
  .action-btn {
    margin-left: 6px;
  }
}
</style>
