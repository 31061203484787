<template>
  <div>
    <div class="cd-hd">
      <div class="action-lt">
        <y-button type="primary">入库</y-button>
        <y-button>出库</y-button>
      </div>
      <div class="action-rt">
        <y-button>前移</y-button>
        <y-button>后移</y-button>
        <y-button>销毁</y-button>
      </div>
    </div>
    <div class="cd-bd">
      <y-table :columns="columns" :data="samplebox.items" :height="tableHeight" size="small" ref="table"></y-table>
    </div>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)">{{loadingText}}</loading>
  </div>
</template>
<script>
import Loading from '../../loading/loading'
import YTable from '../../table/table'
import YButton from '../../button/button'
// import SampleEditWin from './win/sample-edit-win'
import cardColumn from './mixins/cardbox-column'
import { authenticate, catchExpired } from '../../../../utils/auth'
export default {
  components: {Loading, YTable, YButton},
  mixins: [cardColumn],
  props: {
    parentId: String,
    rootId: String,
    parentCode: String
  },
  data () {
    return {
      samplebox: {
        width: 1,
        height: 30,
        items: []
      },
      selectedItems: [],
      currentEditItem: {},

      showLoading: false,
      loadingText: ''
    }
  },
  computed: {
    tableHeight () {
      return Math.max(this.$store.getters.getWinHeight - 200 - 40, 32)
    }
  },
  watch: {
    parentId (val) {
      if (val) {
        this.loadSamplebox()
      }
    }
  },
  mounted () {
    this.loadSamplebox()
  },
  methods: {
    loadSamplebox () {
      this.updateLoadStatus('load-start', '')
      this.$http.get(`${this.httpRoot}/container/tube/list`,
        authenticate({params: {parentId: this.parentId, boxType: 'cardbox', projectId: this.$store.state.project.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.samplebox = res.samplebox || {}
          this.updateLoadStatus('load-success', '')
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '')
      })
    },
    updateLoadStatus (status, loadingText) {
      if (status === 'load-start') {
        this.loadingText = loadingText
        this.showLoading = true
        this.otherBtnDisabled = true
      } else if (status === 'load-success') {
        this.showLoading = false
        this.otherBtnDisabled = false
        if (loadingText) {
          this.$Message.success(loadingText)
        }
      } else if (status === 'load-faild') {
        this.showLoading = false
        this.otherBtnDisabled = false
        this.$Message.error(loadingText)
      }
    }
  }
}
</script>
<style lang="less">
.cd-hd {
  padding: 0 15px;
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  .action-lt {
    button {
      margin-right: 10px;
    }
  }
  .action-rt {
    button {
      margin-left: 10px;
    }
  }
}
.cd-bd {
  padding: 0 15px;
  margin-bottom: 15px;
}
</style>
