<template>
  <button :type="htmlType" :disabled="disabled"
    :class="['btn', {'emphasis': emphasis, [`btn-${type}`]: !!type, 'btn-long': long, 'btn-loading': loading, 'btn-icon-only': !showSlot && (!!icon || loading), [`btn-${shape}`]: !!shape, [`btn-${size}`]: !!size}]">
    <Icon class="load-loop" type="load-c" v-if="loading"></Icon>
    <Icon :type="icon" v-if="icon && !loading"></Icon>
    <span v-if="showSlot" ref="slot"><slot></slot></span>
  </button>
</template>

<script>
import Icon from '../icon/icon'
import { oneOf } from '../../../utils/assist'

export default {
  components: { Icon },
  props: {
    type: {
      validator (value) {
        return oneOf(value, ['primary', 'ghost', 'dashed', 'text', 'info', 'success', 'warning', 'error'])
      }
    },
    shape: {
      validator (value) {
        return oneOf(value, ['circle', 'circle-outline'])
      }
    },
    size: {
      validator (value) {
        return oneOf(value, ['small', 'large'])
      }
    },
    loading: Boolean,
    disabled: Boolean,
    htmlType: {
      validator (value) {
        return oneOf(value, ['button', 'submit', 'reset'])
      },
      default: 'button'
    },
    emphasis: {
      type: Boolean,
      default: false
    },
    icon: String,
    long: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showSlot: true
    }
  },
  mounted () {
    this.showSlot = this.$refs.slot.innerHTML.replace(/\n/g, '').replace(/<!--[\w\W\r\n]*?-->/gmi, '') !== ''
  }
}
</script>
