<template>
  <div class="patient-select">
    <div class="patient-list">
      <div class="patient-item" v-for="item in selectPatient" :key="item.id">
        <span class="name">{{item.name}}</span>
        <span class="ionic-icon ionic-icon-ios-close" @click.stop="removePatient(item)"></span>
      </div>
      <div class="patient-search">
        <y-select
          v-model="currentUniqueCode"
          :data="patientList"
          :toggleShow="false"
          show-value
          clearable
          valueField="uniqueCode"
          attrInValue
          @on-select-change="onPatientChange"></y-select>
      </div>
      <div class="patient-query">
        <input type="text" v-model="query">
      </div>
      <div>
        <button type="button" class="btn query-button" @click.stop="onQuery">查询</button>
      </div>
    </div>
  </div>
</template>
<script>
import YSelect from '../../common/select/select'
import Emitter from '../../mixins/emitter'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: {YSelect},
  mixins: [Emitter],
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    currentName: String
  },
  data () {
    return {
      currentUniqueCode: '',
      selectPatient: [],
      patientList: [],
      query: ''
    }
  },
  watch: {
    value (val, oldVal) {
      if (val && JSON.stringify(oldVal) !== JSON.stringify(val)) {
        this.selectPatient = val
        if (!val || !val.length) {
          this.currentUniqueCode = ''
        }
      }
    },
    selectPatient (val) {
      let result = val
      this.$emit('input', result)
      this.dispatch('FormItem', 'on-form-change', result)
      this.$emit('on-change', result)
    },
    currentName (val) {
      if (val) {
        // this.query = ''
        // this.patientList = []
      }
    }
  },
  methods: {
    onPatientChange (val) {
      if (val && (val.id || val.uniqueCode)) {
        let found = false
        this.selectPatient.forEach(item => {
          if (item.id === val.id || item.uniqueCode === val.uniqueCode) {
            found = true
          }
        })
        if (!found) {
          this.selectPatient.push(val)
        }
      }
    },
    removePatient (val) {
      let idx = -1
      this.selectPatient.forEach((item, index) => {
        if (item.id === val.id) {
          idx = index
        }
      })
      if (idx >= 0) {
        this.selectPatient.splice(idx, 1)
      }
    },
    onQuery () {
      if (!this.query) {
        return
      }
      this.loadPatientList(this.query)
    },
    loadPatientList (query) {
      this.$http.post(`${this.httpRoot}/rds/preview/query/patient`, {
        query,
        projectId: this.$store.state.project.projectId
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.patientList = res.patientList || []
          this.$Message.success('患者加载成功 ' + this.patientList.length)
        }
      })
      .catch(err => {
        this.$Message.error('请求服务异常')
        catchExpired(err, this)
      })
    }
  }
}
</script>
<style lang="less">
.patient-list {
  display: flex;
  flex-wrap: wrap;
}
.patient-item {
  padding: 0 10px;
  background-color: #f1f1f1;
  height: 32px;
  line-height: 32px;
  margin-right: 10px;
  margin-bottom: 4px;
  position: relative;
  .ionic-icon {
    position: absolute;
    top: -12px;
    right: -2px;
    font-size: 14px;
    color: #888;
    cursor: pointer;
  }
}
.patient-search {
  width: 250px;
}
.patient-query {
  margin: 0 20px;
  input {
    height: 32px;
    padding-left: 6px;
    outline: 0;
    height: 100%;
  }
}
.query-button {
  outline: 0;
}
</style>
