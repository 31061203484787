import { getPropertyFromData } from './util'

let nodeIdSeed = new Date().getTime()

export default class Node {
  constructor (options) {
    this.id = nodeIdSeed++

    this.data = null
    this.parent = null
    this.store = null
    this.level = 0
    this.type = ''
    this.expanded = true
    this.focused = false
    this.isLeaf = true
    this.childNodes = []

    for (let name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name]
      }
    }

    if (this.parent) {
      this.level = this.parent.level + 1
    }
    this._key = getPropertyFromData(this, 'key')

    const store = this.store
    store.registerNode(this)
    store.currentNode = this

    if (this.data && this.hasChild()) {
      this.setChild(this.data)
    }
  }

  get label () {
    return getPropertyFromData(this, 'label')
  }

  get icon () {
    return getPropertyFromData(this, 'icon')
  }

  get key () {
    return this._key || this.id
  }

  hasChild () {
    return this.data &&
        ((this.data.fields && this.data.fields.length) ||
          (this.data.items && this.data.items.length) ||
          (this.data.group && this.data.group.length) ||
          (this.data.children && this.data.children.length) ||
          (this.data.subChildren && this.data.subChildren.length))
  }

  setChild () {
    let children
    let subChildren
    let fields
    let items
    let group

    if (this.data) {
      fields = getPropertyFromData(this, 'fields') || []
      items = getPropertyFromData(this, 'items') || []
      group = getPropertyFromData(this, 'group') || []
      children = getPropertyFromData(this, 'children') || []
      subChildren = getPropertyFromData(this, 'subChildren') || []
    }

    for (let i = 0, len = fields.length; i < len; i++) {
      this.insertChild(fields[i], 'field')
    }

    for (let i = 0, len = items.length; i < len; i++) {
      this.insertChild(items[i], items[i].type === 'group' ? 'group' : 'item')
    }

    for (let i = 0, len = group.length; i < len; i++) {
      this.insertChild(group[i], 'gitem')
    }

    for (let i = 0, len = children.length; i < len; i++) {
      this.insertChild(children[i], 'child')
    }

    for (let i = 0, len = subChildren.length; i < len; i++) {
      this.insertChild(subChildren[i], 'subChild')
    }
  }

  insertChild (data, type, index) {
    if (!data) throw new Error('insertChild error: child is required.')
    data.fieldMap = null

    const child = new Node({
      type,
      data,
      parent: this,
      store: this.store
    })

    if (index > -1) {
      this.childNodes.splice(index, 0, child)
    } else {
      this.childNodes.push(child)
    }

    this.updateLeafState()
  }

  updateLeafState () {
    const childNodes = this.childNodes
    this.isLeaf = !childNodes || childNodes.length === 0
  }

  toggleExpand () {
    this.expanded = !this.expanded
  }

  expand () {
    if (!this.expanded) this.expanded = true
  }

  check (status) {
    this.checked = status
  }
}
