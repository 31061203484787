<template>
  <span class="node-content" :class="{focused: node.focused}">
    <label class="checkbox-wrapper" :for="node.key" v-if="node.type !== 'root'">
      <span class="checkbox" :class="{'checkbox-checked': node.checked}">
        <span class="checkbox-inner"></span>
        <input type="checkbox" class="checkbox-input" :id="node.key" v-model="node.checked">
      </span>
    </label>
    <span class="node-content-label" :class="[node.type==='root'?'field': 'group']">{{ node.label }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    node: {
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isCheck: false,
      checkVal: []
    }
  },
  watch: {
    'node.checked' (val) {
      this.emitCheckChange(val)
    }
  },
  methods: {
    emitCheckChange (val) {
      this.$emit('on-node-check-change', val)
    }
  }
}
</script>

<style lang="css">
</style>
