<template>
  <div class="sample-box-item"
    :class="{selected: selected, highlight: hh, emphasize: em}"
    :style="boxItemOuterStyle"
    @click.stop="onSelect"
    @mouseover="onMouseover">
    <div class="box-item"
      :style="boxItemInnerStyle"
      :class="{error: error, skip: boxType === 'skip' && (colIndex+rowIndex)%2>0}">
      <div v-if="item.status === 'sample_exist'" class="item-sample_exist">
        <img :src="item.iconUrl" alt="">
      </div>
      <div v-if="item.status === 'sample_in_ing' && item.iconUrl" class="item-sample-in">
        <img :src="item.iconUrl" alt="">
        <span class="ionic-icon ionic-icon-log-in"></span>
      </div>
      <div v-if="item.status === 'sample_in_ing' && !item.iconUrl">入</div>
      <div v-if="item.status === 'sample_out_ing'" class="item-sample-out">
        <img :src="item.iconUrl" alt="">
        <span class="ionic-icon ionic-icon-log-out"></span>
      </div>
      <div v-if="item.status === 'sample_out_complete'" class="item-sample-out-complete">
        <img :src="item.iconUrl" alt="">
        <span class="ionic-icon ionic-icon-android-checkmark-circle"></span>
      </div>
      <div v-if="item.status === 'sample_trans_ing'" class="item-sample-trans">
        <img :src="item.iconUrl" alt="">
        <span class="ionic-icon ionic-icon-shuffle"></span>
      </div>
      <div v-if="error" class="item-error" @click.stop="clearError">
        <tooltip placement="top" content="编码冲突,请删除">
          <span class="ionic-icon ionic-icon-android-close"></span>
        </tooltip>
      </div>
      <div v-if="conflict" class="item-error">
        <tooltip placement="top" content="编码冲突,请放置正确编码的样本">
          <span class="ionic-icon ionic-icon-android-close"></span>
        </tooltip>
      </div>
      <div v-if="noSample" class="item-error">
        <tooltip placement="top" content="该位置没有样本，请移到正确的位置">
          <span class="ionic-icon ionic-icon-android-close"></span>
        </tooltip>
      </div>
      <div v-if="hasSample" class="item-error">
        <tooltip placement="top" content="该位置有样本，请放置样本到该位置">
          <span class="ionic-icon ionic-icon-android-close"></span>
        </tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from '../tooltip/tooltip'
export default {
  components: {Tooltip},
  props: {
    boxItemWidth: {
      type: Number,
      default: 40
    },
    marginWidth: {
      type: Number,
      default: 7
    },
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    itemIndex: Number,
    boxType: String
  },
  data () {
    return {
      // uuid: Math.random().toString(36).substring(3, 8),
      selected: false,
      hh: false,
      em: false,
      error: false,
      conflict: false,
      noSample: false,
      hasSample: false
      // disabled: false
    }
  },
  computed: {
    rowIndex () {
      return parseInt(this.itemIndex / this.$parent.width)
    },
    colIndex () {
      return this.itemIndex % this.$parent.width
    },
    boxItemOuterStyle () {
      return {
        width: this.boxItemWidth + 'px',
        height: this.boxItemWidth + 'px',
        lineHeight: this.boxItemWidth + 'px'
      }
    },
    boxItemInnerStyle () {
      const exist = {
        border: 'none',
        borderRadius: '0px',
        background: 'transparent',
        height: '100%'
      }
      const other = {
        margin: this.marginWidth + 'px',
        width: this.boxItemWidth - 2 * (this.marginWidth) + 'px',
        height: this.boxItemWidth - 2 * (this.marginWidth) + 'px',
        lineHeight: this.boxItemWidth - 2 * (this.marginWidth) + 'px'
      }
      if (this.item.status !== 'sample_empty' && this.item.iconUrl) {
        return exist
      } else {
        return other
      }
    }
  },
  methods: {
    onSelect (event) {
      // 判断只能选中一根管子
      if (this.$parent.selectAction === 'single' && (event.metaKey || event.ctrlKey) && !event.shiftKey) {
        // 单选-反选-连选
        // 记录最新选中的item
        if (this.canSelect() && !this.selected) {
          this.$parent.startItem = this.item
        }
        this.select(!this.selected, true)
      } else if (this.$parent.selectAction === 'single' && !event.metaKey && !event.ctrlKey && event.shiftKey) {
        // 连选
        this.$parent.unSelectAll()
        this.$parent.selectBlock(this.item)
      } else if (this.$parent.selectAction === 'single' && !event.metaKey && !event.ctrlKey && !event.shiftKey) {
        // 单选
        this.$parent.unSelectAll()
        // 记录最新选中的item
        if (this.canSelect() && !this.selected) {
          this.$parent.startItem = this.item
        } else if (!this.canSelect()) {
          this.$parent.startItem = {}
          // 单选无效，由于 $parent.unSelectAll() 没有冒泡触发事件 on-item-selected
          this.$parent.$emit('on-item-selected')
        }
        this.select(!this.selected, true)
      }
    },
    select (isSelect, dispatch, addType) {
      if (this.canSelect()) {
        this.selected = isSelect
        // 反选，需要判断并清除行头和列头以及左上角头的选择状态
        if (!isSelect) {
          this.$parent.clearRowHeader(this.rowIndex)
          this.$parent.clearColHeader(this.colIndex)
          this.$parent.clearTopLeft()
        } else {
          // 选中，需要判断并设置行头和列头以及左上角头的选择状态
          this.$parent.checkRowHeader(this.rowIndex)
          this.$parent.checkColHeader(this.colIndex)
          this.$parent.checkAll()
        }
        this.$emit('on-item-selected', this.selected, this.rowIndex, this.colIndex, this.itemIndex, dispatch, addType)
      }
    },
    canSelect () {
      /* item.status 操作状态
      sample_empty     标本空
      sample_exist     标本在库
      sample_out_ing   标本出库中
      sample_in_ing    标本入库中
      sample_trans_ing 标本转库中
      sample_out_complete 标本已出库
      sample_destroyed 标本已销毁 不加载显示(同位置有多个)
      */
      if (this.boxType === 'skip' && (this.colIndex + this.rowIndex) % 2 > 0) {
        return false
      }
      if (this.$parent.actionType === 'all') {
        return true
      } else if (this.$parent.actionType === 'once') {
        if (this.$parent.selectedTimes === 0) {
          return true
        }
      } else if (this.$parent.actionType === 'samplein' &&
          (this.item.status === 'sample_empty' || (this.item.status === 'sample_in_ing' && !this.item.iconUrl))) {
        return true
      } else if ((this.$parent.actionType === 'sampleout' || this.$parent.actionType === 'sampletrans') &&
          this.item.status === 'sample_exist') {
        return true
      } else if (this.$parent.actionType === 'sampleinouttrans' &&
          (this.item.status === 'sample_empty' || this.item.status === 'sample_exist')) {
        return true
      } else if (this.$parent.actionType === 'lookup' &&
          (this.item.status === 'sample_exist' || this.item.status === 'sample_out_ing' ||
          this.item.status === 'sample_trans_ing' || (this.item.status === 'sample_in_ing' && this.item.iconUrl))) {
        return true
      }
      return false
    },
    sampleIning () {
      this.select(false, true)
      this.item.status = 'sample_in_ing'
    },
    sampleDelete () {
      this.item.sampleCode = ''
      this.item.iconUrl = ''
      this.error = false
      this.$emit('on-item-delete', this.rowIndex, this.colIndex, this.itemIndex)
      this.select(false, true)
      this.item.status = 'sample_empty'
    },
    sampleTransing () {
      this.select(false, true)
      this.item.status = 'sample_trans_ing'
    },
    onMouseover () {
      if (this.canSelect()) {
        this.$el.style.cursor = 'pointer'
      } else {
        this.$el.style.cursor = 'not-allowed'
      }
    },
    setType (iconUrl) {
      if (iconUrl) {
        this.$set(this.item, 'iconUrl', iconUrl)
      }
    },
    highlight () {
      this.hh = true
    },
    emphasize () {
      this.em = true
    },
    markConflict () {
      this.conflict = true
    },
    markNoSample () {
      this.noSample = true
    },
    markHasSample () {
      this.hasSample = true
    },
    markError () {
      this.error = true
    },
    clearError () {
      if (this.$parent.actionType === 'all' || this.$parent.actionType === 'samplein') {
        this.item.sampleCode = ''
        this.error = false
        this.$emit('on-item-delete', this.rowIndex, this.colIndex, this.itemIndex)
        this.select(false, true)
        this.item.status = 'sample_empty'
      }
    },
    clearVerify () {
      this.conflict = false
      this.noSample = false
      this.hasSample = false
    },
    clearEmphasize () {
      this.em = false
    }
  }
}
</script>
