/*
 * 在 Vue.prototype 上构建一个单实例 $Modal, 供 Vue 所有组件实例使用
 * 在 Vue 组件中调用 this.$Modal.info()/success()/warning()/error()/confrim()/remove()
 * 初始化方式: 在 Vue 单页面根组件初始化之前 import 该 js
 */
import Vue from 'vue'
import Modal from './modal.vue'
import YButton from '../button/button.vue'
// import { camelcaseToHyphen } from '../../../utils/assist'

function newInstance (properties) {
  const _props = properties || {}

  // let props = ''
  // Object.keys(_props).forEach(prop => {
  //   props += ' :' + camelcaseToHyphen(prop) + '=' + prop
  // })

  // const div = document.createElement('div')
  // div.innerHTML = `
  //   <Modal${props} v-model="visible" :width="width" :scrollable="scrollable">
  //     <div class="modal-confirm">
  //       <div class="modal-confirm-head">
  //         <div class="modal-confirm-head-title" v-html="title"></div>
  //       </div>
  //       <div class="modal-confirm-body">
  //         <div class="modal-confirm-body-icon" :class="['modal-confirm-body-icon-'+ iconType]">
  //           <i class="ionic-icon" :class="['ionic-icon-' + iconName]"></i>
  //         </div>
  //         <div v-html="body"></div>
  //       </div>
  //       <div class="modal-confirm-footer">
  //         <y-button type="text" size="large" v-if="showCancel" @click.native="cancel">{{ cancelText }}</y-button>
  //         <y-button type="primary" size="large" :loading="buttonLoading" @click.native="ok">{{ okText }}</y-button>
  //       </div>
  //     </div>
  //   </Modal>
  // `
  // document.body.appendChild(div)

  const modalParent = new Vue({
    render (h) {
      const getFooter = () => {
        if (this.showCancel) {
          return [
            h('y-button', { props: { type: 'text', size: 'large' }, nativeOn: { 'click': this.cancel } }, this.cancelText),
            h('y-button', { props: { type: 'primary', size: 'large', loading: this.buttonLoading }, nativeOn: { 'click': this.ok } }, this.okText)
          ]
        } else {
          return [
            h('y-button', { props: { type: 'primary', size: 'large', loading: this.buttonLoading }, nativeOn: { 'click': this.ok } }, this.okText)
          ]
        }
      }
      return h('div', [
        h('modal', {
          props: {
            ..._props,
            value: this.visible,
            width: this.width,
            scrollable: this.scrollable,
            isGlobal: true
          },
          on: {
            'input': (val) => {
              this.visible = val
            }
          }
        }, [
          h('div', { attrs: { class: 'modal-confirm' } }, [
            h('div', { attrs: { class: 'modal-confirm-head' } }, [
              h('div', { attrs: { class: 'modal-confirm-head-title' }, domProps: { innerHTML: this.title } })
            ]),
            h('div', { attrs: { class: 'modal-confirm-body' } }, [
              h('div', { class: ['modal-confirm-body-icon', 'modal-confirm-body-icon-' + this.iconType] }, [
                h('i', { class: ['ionic-icon', 'ionic-icon-' + this.iconName] })
              ]),
              h('div', { domProps: { innerHTML: this.body } })
            ]),
            h('div', { attrs: { class: 'modal-confirm-footer' } }, getFooter())
          ])
        ])
      ])
    },
    components: { Modal, YButton },
    data: Object.assign(_props, {
      visible: false,
      width: 416,
      title: '',
      body: '',
      iconType: '',
      iconName: '',
      okText: '确定',
      cancelText: '取消',
      showCancel: false,
      loading: false,
      buttonLoading: false,
      scrollable: false
    }),
    methods: {
      cancel () {
        this.$children[0].visible = false
        this.buttonLoading = false
        this.onCancel()
        this.remove()
      },
      ok () {
        if (this.loading) {
          this.buttonLoading = true
        } else {
          this.$children[0].visible = false
          this.remove()
        }
        this.onOk()
      },
      remove () {
        setTimeout(() => {
          this.destroy()
        }, 300)
      },
      destroy () {
        this.$destroy()
        document.body.removeChild(this.$el)
        this.onRemove()
      },
      onOk () {},
      onCancel () {},
      onRemove () {}
    }
  }).$mount()

  const modal = modalParent.$children[0]

  document.body.appendChild(modalParent.$el)

  return {
    show (props) {
      modal.$parent.showCancel = props.showCancel
      modal.$parent.iconType = props.icon

      switch (props.icon) {
        case 'info':
          modal.$parent.iconName = 'information-circled'
          break
        case 'success':
          modal.$parent.iconName = 'checkmark-circled'
          break
        case 'warning':
          modal.$parent.iconName = 'android-alert'
          break
        case 'error':
          modal.$parent.iconName = 'close-circled'
          break
        case 'confirm':
          modal.$parent.iconName = 'help-circled'
          break
      }

      if ('width' in props) {
        modal.$parent.width = props.width
      }
      if ('title' in props) {
        modal.$parent.title = props.title
      }
      if ('content' in props) {
        modal.$parent.body = props.content
      }
      if ('okText' in props) {
        modal.$parent.okText = props.okText
      }
      if ('cancelText' in props) {
        modal.$parent.cancelText = props.cancelText
      }
      if ('onCancel' in props) {
        modal.$parent.onCancel = props.onCancel
      }
      if ('onOk' in props) {
        modal.$parent.onOk = props.onOk
      }
      // async for ok
      if ('loading' in props) {
        modal.$parent.loading = props.loading
      }
      if ('scrollable' in props) {
        modal.$parent.scrollable = props.scrollable
      }
      // notice when component destroy
      modal.$parent.onRemove = props.onRemove
      modal.visible = true
    },
    remove () {
      modal.visible = false
      modal.$parent.buttonLoading = false
      modal.$parent.remove()
    },
    component: modal
  }
}

let modalInstance

function getModalInstance () {
  modalInstance = modalInstance || newInstance({
    closable: false,
    maskClosable: false,
    footerHide: true
  })
  return modalInstance
}

function showModal (options) {
  let instance = getModalInstance()

  options.onRemove = function () {
    modalInstance = null
  }
  // may be Object.assign(options, {onRemove: () => {modalInstance = null}})
  instance.show(options)
}

export default {
  info (props = {}) {
    props.icon = 'info'
    props.showCancel = false
    return showModal(props)
  },
  success (props = {}) {
    props.icon = 'success'
    props.showCancel = false
    return showModal(props)
  },
  warning (props = {}) {
    props.icon = 'warning'
    props.showCancel = false
    return showModal(props)
  },
  error (props = {}) {
    props.icon = 'error'
    props.showCancel = false
    return showModal(props)
  },
  confirm (props = {}) {
    props.icon = 'confirm'
    props.showCancel = true
    return showModal(props)
  },
  remove () {
    if (!modalInstance) {   // at loading status, remove after Cancel
      return false
    }
    const instance = getModalInstance()
    instance.remove()
  }
}
