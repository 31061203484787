<template>
  <div class="dict-setting">
    <dict-list
      :data="dictList"
      :currentDict="currentDict"
      @on-dict-select="onDictSelect"
      @on-dict-edit="onDictEdit"
      @on-dict-add="onDictAdd"
      @on-dict-delete="onDictDelete"></dict-list>
    <dict-item-list :data="dictItemList" :dict="currentDict" :projectId="projectId"></dict-item-list>
    <dict-add-win
      v-model="currentDict"
      :show="showDictEditWin"
      title="字典编辑"
      @on-hide="showDictEditWin=false"
      @on-confirm-ok="onEditDict"></dict-add-win>
    <dict-add-win
      :show="showDictAddWin"
      title="字典添加"
      @on-hide="showDictAddWin=false"
      @on-confirm-ok="onAddDict"></dict-add-win>
  </div>
</template>

<script>
import DictList from './dicts/dict-list'
import DictItemList from './dicts/dict-item-list'
import DictAddWin from './win/dict-add-win'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {DictList, DictItemList, DictAddWin},
  data () {
    return {
      dictList: [],
      currentDict: {},
      dictItemList: [],

      showDictEditWin: false,
      showDictAddWin: false
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    }
  },
  watch: {
    projectId (val) {
      if (val) {
        this.loadDictList()
      }
    },
    dictList (val) {
      if (val && val.length) {
        this.currentDict = val[0]
      }
    },
    currentDict (val) {
      this.loadDictItemList(val, (items) => {
        this.dictItemList = items
      })
    }
  },
  mounted () {
    this.loadDictList()
  },
  methods: {
    onDictSelect (dict) {
      this.currentDict = dict
    },
    onDictEdit () {
      this.showDictEditWin = true
    },
    onEditDict (val) {
      this.updateDict(val)
    },
    onDictAdd () {
      this.showDictAddWin = true
    },
    onAddDict (val) {
      this.updateDict(val, () => {
        this.loadDictList()
      })
    },
    onDictDelete () {
      this.$Modal.confirm({
        title: '删除字典',
        content: '<p>您确定删除该字典？</p>',
        onOk: () => {
          this.deleteDict(this.currentDict, () => {
            this.loadDictList()
          })
        }
      })
    },
    loadDictList () {
      this.$http.get(`${this.httpRoot}/dict/list`, authenticate({params: {projectId: this.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.dictList = res.dicts || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadDictItemList (val, callback) {
      this.$http.get(`${this.httpRoot}/dict/item/list`, authenticate({params: {projectId: this.projectId, dictCode: val.code}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback(res.items || [])
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    updateDict (newDict, callback) {
      this.$http.post(`${this.httpRoot}/dict/update`, {
        projectId: this.projectId,
        dict: newDict
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('更新字典数据成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.$Message.error(res.statusText, 2)
          this.loadDictList()
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('更新字典数据失败')
      })
    },
    deleteDict (dict, callback) {
      this.$http.post(`${this.httpRoot}/dict/delete`, {id: dict.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('删除字典数据成功')
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('删除字典数据失败')
      })
    }
  }
}
</script>

<style lang="less">
.dict-setting {
  height: 100%;
  * {
    box-sizing: content-box;
  }
}
</style>
