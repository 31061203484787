import { getPropertyFromData } from './util'

let nodeIdSeed = new Date().getTime()

export default class Node {
  constructor (options) {
    this.id = nodeIdSeed++

    this.data = null
    this.parent = null
    this.store = null
    this.level = 0
    this.type = ''
    this.expanded = true
    this.checked = false
    this.focused = false
    this.isLeaf = true
    this.childNodes = []

    for (let name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name]
      }
    }

    if (this.parent) {
      this.level = this.parent.level + 1
    }
    this._key = getPropertyFromData(this, 'key')

    const store = this.store
    store.registerNode(this)
    store.currentNode = this

    if (this.data && this.hasChild()) {
      this.setChild(this.data)
    }
  }

  get label () {
    return getPropertyFromData(this, 'label')
  }

  get icon () {
    return getPropertyFromData(this, 'icon')
  }

  get key () {
    return this._key || this.id
  }

  hasChild () {
    return this.data && this.data.children && this.data.children.length
  }

  setChild () {
    let children

    if (this.data) {
      children = getPropertyFromData(this, 'children') || []
    }

    for (let i = 0, len = children.length; i < len; i++) {
      this.insertChild(children[i], 'child')
    }
  }

  insertChild (data, type, index) {
    if (!data) throw new Error('insertChild error: child is required.')

    const child = new Node({
      type,
      data,
      parent: this,
      store: this.store
    })

    if (index > -1) {
      this.childNodes.splice(index, 0, child)
    } else {
      this.childNodes.push(child)
    }

    this.updateLeafState()
  }

  updateLeafState () {
    const childNodes = this.childNodes
    this.isLeaf = !childNodes || childNodes.length === 0
  }

  toggleExpand () {
    this.expanded = !this.expanded
  }

  expand () {
    if (!this.expanded) this.expanded = true
  }

  checkParent (status, deep) {
    this.checked = status
    if (deep && this.parent && this.parent.type !== 'root') {
      this.parent.checkParent(status, deep)
    }
  }

  checkChild (status, deep) {
    this.checked = status
    if (deep && this.childNodes && this.childNodes.length) {
      for (let i = 0, len = this.childNodes.length; i < len; i++) {
        let child = this.childNodes[i]
        child.checkChild(status, deep)
      }
    }
  }

  check (status) {
    if (status) {
      this.checkParent(status, true)
    } else {
      this.checkChild(status, true)
    }
  }

  isLeafChecked () {
    let isChildHasChecked = function (nodes) {
      for (let i = 0, len = nodes.length; i < len; i++) {
        let node = nodes[i]
        if (node.checked) {
          return true
        } else if (isChildHasChecked(node.childNodes)) {
          return true
        }
      }
      return false
    }
    if (this.checked && !isChildHasChecked(this.childNodes)) {
      return true
    }
    return false
  }
}
