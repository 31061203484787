import { render, staticRenderFns } from "./dict-item-list.vue?vue&type=template&id=fc2ac67e&"
import script from "./dict-item-list.vue?vue&type=script&lang=js&"
export * from "./dict-item-list.vue?vue&type=script&lang=js&"
import style0 from "./dict-item-list.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports