<template>
  <div class="pagesize" @click="handleShowPopper" v-clickoutside="handleClosePopper">
    <div class="pagesize-rel" ref="reference">
      <span>{{`${currentSize} 条/页`}}</span>
      <i class="ionic-icon ionic-icon-arrow-down-b"></i>
    </div>
    <transition name="fade">
      <div class="pagesize-popper" ref="popper" v-show="!disabled && (visible || always)">
        <div class="pagesize-list">
          <div
            v-for="(size, index) in pageSizeList"
            :key="index"
            class="pagesize-list__item"
            :class="{active: currentSize === size}"
            @click.stop="pageSizeChange(size)">{{`${size} 条/页`}}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Popper from '../base/popper'
import clickoutside from '../directives/clickoutside'
import { oneOf } from '../../../utils/assist'

export default {
  name: 'PageSize',
  mixins: [Popper],
  directives: {clickoutside},
  props: {
    placement: {
      validator (value) {
        return oneOf(value, ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end'])
      },
      default: 'top-start'
    },
    content: {
      type: [String, Number],
      default: ''
    },
    delay: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    controlled: {    // under this prop,Tooltip will not close when mouseleave
      type: Boolean,
      default: false
    },
    always: {
      type: Boolean,
      default: false
    },
    pageSize: Number
  },
  data () {
    return {
      pageSizeList: [10, 20, 50, 100, 200, 500],
      currentSize: this.pageSize || 50
    }
  },
  watch: {
    pageSize (val) {
      this.currentSize = val
    }
  },
  methods: {
    handleShowPopper () {
      this.timeout = setTimeout(() => {
        this.visible = !this.visible
      }, this.delay)
    },
    handleClosePopper () {
      clearTimeout(this.timeout)
      if (!this.controlled) {
        this.visible = false
      }
    },
    pageSizeChange (size) {
      if (this.currentSize !== size) {
        this.currentSize = size
        this.visible = false
        this.$emit('on-pagesize-change', size)
      }
    }
  }
}
</script>
<style lang="less">
@import "../../../assets/ivu/custom.less";
.pagesize {
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  margin: 0 10px;
  width: 93px;
  border-radius: 2px;
  border: 1px solid @border-color-base;
  float: left;
}
.pagesize-rel {
  display: inline-block;
  position: relative;
  span {
    width: 68px;
    text-align: right;
  }
}
.pagesize-popper[x-placement^="top"] {
  padding: 5px 0 8px 0;
}
.pagesize-popper {
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  position: absolute;
  z-index: 1060;
}
.pagesize-list {
  background: #fff;
  box-shadow: 0 0px 5px rgba(50, 50, 50, 0.3);
  width: 91px;
  padding: 5px 0;
  border-radius: 2px;
}
.pagesize-list__item {
  text-align: center;
  height: 28px;
  line-height: 28px;
  &:hover {
    background: #eee;
  }
  &.active {
    background: @primary-color;
    color: #fff;
  }
}
</style>
