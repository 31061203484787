<template>
  <modal class="type-edit-win" v-model="showModal" title="更换样本类型图标" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm" :styles="{top: '60px'}">
    <y-form ref="typeForm" :model="type" :rules="typeRule" label-width="100px" item-width="250px">
      <form-item label="样本类型" prop="id">
        <y-select
          v-model="type.id"
          valueField="id"
          attrInValue
          showField="code"
          showIconUrl
          :data="typeList"
          @on-select-change="onTypeChange"></y-select>
      </form-item>
      <form-item label="上传图片" prop="iconUrl">
        <upload
          :action="`${httRoot}/file/upload`"
          :headers="headers"
          :maxSize="50"
          :format="['png','jpg']"
          :beforeUpload="uploadBefore"
          :onExceededSize="uploadExceeded"
          :onFormatError="uploadFormatError"
          :onSuccess="uploadSuccess">
          <y-button type="ghost" icon="ios-cloud-upload-outline">上传图片</y-button>
          <span class="img-exceed" v-if="showExceeded">文件大小不能超过50KB</span>
          <span class="img-exceed" v-if="showFormatError">图片格式限定为 png 或 jpg</span>
          <span class="img-preview" v-if="showImgPreview" title="预览"><img :src="type.iconUrl"/></span>
        </upload>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Upload from '../../common/upload/upload'
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import Icon from '../../common/icon/icon'
import YButton from '../../common/button/button'
import YInput from '../../common/input/input'
import YSelect from '../../common/select/select'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: { Upload, Modal, YForm, FormItem, Icon, YButton, YInput, YSelect },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      headers: authenticate().headers,
      showModal: this.show,
      type: {},
      currentSampleType: {},
      typeList: [],
      typeRule: {
        id: [{required: true, message: '不能为空', trigger: 'blur'}],
        iconUrl: [{required: true, message: '样本图片不能为空', trigger: 'change'}]
      },

      showExceeded: false,
      showFormatError: false,
      showImgPreview: false
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    }
  },
  mounted () {
    this.loadTypeList()
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.typeForm.validate()
      if (!this.$refs.typeForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', JSON.parse(JSON.stringify(this.type)))
    },
    onTypeChange (val) {
      this.currentSampleType = val
    },
    loadTypeList () {
      this.$http.get(`${this.httpRoot}/sample/type/list`, authenticate({params: {projectId: this.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.typeList = res.typeList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    updateSampleTypeIcon () {
      if (!this.currentSampleType || !this.currentSampleType.id || !this.type.iconUrl) {
        this.$Message.error('没有选择样本类型', 2)
        return
      }
      this.$http.post(`${this.httpRoot}/sample/type/icon/update`, {
        projectId: this.projectId, // 没用到
        sampleType: this.currentSampleType,
        newIconUrl: this.type.iconUrl
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('更新样本类型图标成功')
        } else {
          this.$Message.error(res.statusText, 2)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('更新样本类型图标失败', 2)
      })
    },
    uploadSuccess (res) {
      this.type.iconUrl = res.url
      this.showImgPreview = true
      this.updateSampleTypeIcon()
    },
    uploadExceeded (file) {
      this.showExceeded = true
    },
    uploadFormatError () {
      this.showFormatError = true
    },
    uploadBefore () {
      if (!this.currentSampleType || !this.currentSampleType.id) {
        this.$Message.warning('请选择样本类型', 2)
        return false
      }
      this.showImgPreview = false
      this.showExceeded = false
      this.showFormatError = false
    }
  }
}
</script>

<style lang="less">
.img-exceed {
  color: #F76260;
}
.img-preview {
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 32px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
</style>
