<template>
  <div class="container_box">
    <template v-for="(zone, index) in zones">
      <panel-zone
        v-if="index !== 0"
        :key="index"
        :name="mapZone(index)" :width="getWidth(zones, index)" :class="{'gap-line': index===2}">
        <div class="container" v-for="item in zone" :key="item.id">
          <panel-container-item :container="item">
            <delete-mask slot="deletemask"
              :show="showDeleteMask"
              :container="item"
              @on-delete="onDelete"
              @on-auth="onAuth"
              @on-edit="onEdit"
              @on-calcvol="onCalcvol"
              @on-calc-samplenum="onCalcSamplenum"></delete-mask>
          </panel-container-item>
        </div>
        <div class="container" v-if="showAdd"
          @click.stop="onAdd(index)">
          <div class="container-add">
            <div class="container-add-plus">
              +
            </div>
          </div>
        </div>
      </panel-zone>
    </template>
  </div>
</template>

<script>
import PanelZone from './panel-zone'
import PanelContainerItem from './panel-container-item'
import DeleteMask from '../common/container/delete/deleteMask'
export default {
  components: { PanelZone, PanelContainerItem, DeleteMask },
  props: {
    containers: {
      type: Array,
      default () { return [] }
    },
    showDeleteMask: {
      type: Boolean,
      default: false
    },
    showAdd: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    zones () {
      let ary = []
      this.containers.forEach(item => {
        if (item.zone) {
          ary[item.zone] = ary[item.zone] || []
          ary[item.zone].push(item)
        } else {
          ary[1] = ary[1] || []
          ary[1].push(item)
        }
      })
      if (!ary.length) {
        ary = [[], []]
      }
      for (let i = 1; i < ary.length; i++) {
        if (!ary[i]) ary[i] = []
      }
      return ary
    }
  },
  methods: {
    onDelete (container) {
      this.$emit('on-show-delete', container)
    },
    onAuth (container) {
      this.$emit('on-show-auth', container)
    },
    onEdit (container) {
      this.$emit('on-show-edit', container)
    },
    onCalcvol (container) {
      this.$emit('on-calc-vol', container)
    },
    onCalcSamplenum (container) {
      this.$emit('on-calc-samplenum', container)
    },
    onAdd (index) {
      this.$emit('on-show-add', index)
    },
    mapZone (index) {
      // zone 下标默认从 1 开始
      const zones = ['0区', 'A区', 'B区', 'C区', 'D区', 'E区', 'F区', 'G区', 'H区', 'I区', 'J区']
      return zones[index]
    },
    getWidth (zones, index) {
      let zone = zones[index]
      let zone1
      let zone2
      if (zone && zones.length === 2) {
        return '100%'
      } else if (zones.length >= 3 && index % 2 !== 0 && index + 1 < zones.length) {
        zone1 = zone.length || 1 // 空的情况
        zone2 = zones[index + 1].length || 1 // 空的情况
        if (100 * zone1 / (zone1 + zone2) >= 80) {
          return '80%'
        } else {
          return `${Math.floor(100 * zone1 / (zone1 + zone2))}%`
        }
      } else if (zones.length >= 3 && index % 2 === 0) {
        zone1 = zones[index - 1].length || 1 // 空的情况
        zone2 = zone.length || 1 // 空的情况
        if (100 * zone2 / (zone1 + zone2) <= 20) {
          return '20%'
        } else {
          return `${Math.floor(100 * zone2 / (zone1 + zone2))}%`
        }
      } else if (zones.length >= 3 && index % 2 !== 0 && index + 1 >= zones.length) {
        return '100%'
      } else {
        return '100%'
      }
    }
  }
}
</script>

<style lang="less">
@import "../../assets/ivu/custom";
@import "../../assets/ivu/mixins/clear";
.container_box {
  &:after {
    .clearFloat();
  }
}
.container {
  margin: 5px 10px 25px 10px;
  float: left;
  display: flex;
}
.container-img {
  flex: 0 0 auto;
  display: inline-block;
  position: relative;
  cursor: pointer;
  &.fridge-v {
    width: 55px;
    height: 100px;
    &.prop-1 {
      background: url(../../assets/imgs/container/refrigerator1.png) no-repeat;
      background-size: 100% 100%;
    }
    &.prop-2 {
      background: url(../../assets/imgs/container/refrigerator1-green.png) no-repeat;
      background-size: 100% 100%;
    }
    &.prop-3 {
      background: url(../../assets/imgs/container/refrigerator1-orange.png) no-repeat;
      background-size: 100% 100%;
    }
    &.prop-4 {
      background: url(../../assets/imgs/container/refrigerator1-red.png) no-repeat;
      background-size: 100% 100%;
    }
    &.enable {
      &:hover {
        background: url(../../assets/imgs/container/refrigerator1.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        transition: all 0.3s;
      }
    }
  }
  &.fridge-h {
    margin-top: 40px;
    width: 90px;
    height: 60px;
    &.prop-1 {
      background: url(../../assets/imgs/container/refrigerator2.png) no-repeat;
      background-size: 100% 100%;
    }
    &.prop-2 {
      background: url(../../assets/imgs/container/refrigerator2-green.png) no-repeat;
      background-size: 100% 100%;
    }
    &.prop-3 {
      background: url(../../assets/imgs/container/refrigerator2-orange.png) no-repeat;
      background-size: 100% 100%;
    }
    &.prop-4 {
      background: url(../../assets/imgs/container/refrigerator2-red.png) no-repeat;
      background-size: 100% 100%;
    }
    &.enable {
      &:hover {
        background: url(../../assets/imgs/container/refrigerator2.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        transition: all 0.3s;
      }
    }
  }
  &.nitrogen {
    width: 55px;
    height: 100px;
    &.prop-1 {
      background: url(../../assets/imgs/container/jar.png) no-repeat;
      background-size: 100% 100%;
    }
    &.prop-2 {
      background: url(../../assets/imgs/container/jar-green.png) no-repeat;
      background-size: 100% 100%;
    }
    &.prop-3 {
      background: url(../../assets/imgs/container/jar-orange.png) no-repeat;
      background-size: 100% 100%;
    }
    &.prop-4 {
      background: url(../../assets/imgs/container/jar-red.png) no-repeat;
      background-size: 100% 100%;
    }
    &.enable {
      &:hover {
        background: url(../../assets/imgs/container/jar.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        transition: all 0.3s;
      }
    }
  }

}
.container-desc {
  position: relative;
  flex: 0 0 auto;
  display: inline-block;
  min-width: 100px;
  padding-left: 10px;
  .label {
    height: 22px;
    line-height: 22px;
    font-size: 14px;
  }
  .info {
    height: 20px;
    line-height: 20px;
    font-size: 12px;
  }
  .ionic-icon {
    margin-right: 5px;
    color: #666;
    cursor: pointer;
    &:hover {
      color: @primary-color;
    }
  }
}
.container-percent {
  cursor: pointer;
  width: 70px;
}
.container-hover {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 999;
  min-width: 48px;
  border-radius: 4px;
  padding: 5px;
  background: rgba(220, 220, 220, .85);
  & > .line {
    font-size: 12px;
    height: 14px;
    line-height: 14px;
  }
}
</style>
