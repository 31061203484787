<template>
  <div class="date-picker-cells date-picker-cells-month" @click="handleClick">
    <span v-for="(cell, index) in cells"
      :key="index"
      class="date-picker-cells-cell"
      :class="{'date-picker-cells-cell-selected': cell.selected,
              'date-picker-cells-cell-disabled': cell.disabled}">
      <em :index="index">{{ months[index] }}</em>
    </span>
  </div>
</template>

<script>
import { deepCopy } from '../../../../utils/assist'

export default {
  props: {
    date: {},
    month: {
      type: Number
    },
    disabledDate: {},
    selectionMode: {
      default: 'month'
    }
  },
  data () {
    return {
      months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    }
  },
  computed: {
    cells () {
      let cells = []
      const cellTmpl = {
        text: '',
        selected: false,
        disabled: false
      }

      for (let i = 0; i < 12; i++) {
        const cell = deepCopy(cellTmpl)
        cell.text = i + 1

        const date = new Date(this.date)
        date.setMonth(i)
        cell.disabled = typeof this.disabledDate === 'function' && this.disabledDate(date) && this.selectionMode === 'month'

        cell.selected = Number(this.month) === i
        cells.push(cell)
      }

      return cells
    }
  },
  methods: {
    handleClick (event) {
      const target = event.target
      if (target.tagName === 'EM') {
        const index = parseInt(event.target.getAttribute('index'))
        const cell = this.cells[index]
        if (cell.disabled) return

        this.$emit('on-pick', index)
      }
      this.$emit('on-pick-click')
    }
  }
}
</script>
