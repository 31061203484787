<template>
  <div class="crf-item">
    <div class="crf-item__title" :class="{selected: selected}"
      @click.stop="onCrfItemClick">
      {{crf.title}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    crf: {
      type: Object,
      default () { return {} }
    },
    selected: Boolean
  },
  methods: {
    onCrfItemClick () {
      this.$emit('on-crf-click', this.crf)
    }
  }
}
</script>

<style lang="less">
.crf-item__title {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
  &.selected {
    background: #eddfdf;
    color: #3399ff;
    font-weight: 600;
  }
}
</style>
