import Node from './tree-node'

export default class TreeStore {
  constructor (options) {
    this.key = ''
    this.name = ''
    this.data = null
    this.props = {}

    this.currentNode = null

    for (let option in options) {
      if (options.hasOwnProperty(option)) {
        this[option] = options[option]
      }
    }

    this.nodesMap = {}

    this.rootNode = new Node({
      type: 'root',
      data: {
        name: this.name,
        code: this.code || 'root',
        children: JSON.parse(JSON.stringify(this.data || []))
      },
      store: this
    })
  }

  registerNode (node) {
    if (!node || !node.data) return
    if (node.key) this.nodesMap[node.key] = node
  }

  deregisterNode (node) {
    if (!node || !node.data) return
    delete this.nodesMap[node.key]
  }

  getNodeByKey (key) {
    return this.nodesMap[key]
  }

  getLeafCheckedData () {
    const result = []
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.type !== 'root' && node.isLeafChecked()) {
        result.push({name: node.label, code: node.key})
      }
    })
    return result
  }

  unCheckAllNodes () {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.type !== 'root') {
        node.checked = false
      }
    })
  }
}
