<template>
  <div class="root-container" :style="{width: width}">
    <collapse-panel :title="title" v-model="containers.expanded"
      v-show="containers.items && containers.items.length">
      <div class="layer-container__box">
        <div class="layer-container__item"
          :class="{enable: item.enable, selected: item.selected}"
          v-for="(item, index) in containers.items"
          :key="item.id"
          @click.stop="onClick(item)">
          <div class="layer-index">
            {{index + 1}}
          </div>
          <div class="container-img-fridge" :title="item.label">
          </div>
        </div>
      </div>
    </collapse-panel>
  </div>
</template>

<script>
import CollapsePanel from '../transitions/collapse-panel'
export default {
  name: 'Layer',
  components: { CollapsePanel },
  props: {
    width: String,
    containers: {
      type: Object,
      default: function () {
        return {
          items: [],
          type: 'fridge',
          expanded: false,
          loaded: false
        }
      }
    }
  },
  data () {
    return {
      title: '抽屉'
    }
  },
  methods: {
    onClick (item) {
      if (item.enable) {
        if (item.selected) {
          // reselect
          item.select(true, true)
        } else {
          item.select(true)
        }
        this.$emit('on-layer-selected', item)
      }
    }
  }
}
</script>

<style lang="less">
.layer-container__box {
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  .layer-container__item {
    margin: 2px auto;
    line-height: 15px;
    flex: auto;
    display: flex;
    justify-content: space-around;
    .layer-index {
      flex: auto;
      position: absolute;
      left: 50%;
      font-size: 12px;
      height: 15px;
      line-height: 15px;
      cursor: default;
    }
    &.enable {
      &.selected {
        .container-img-fridge {
          background: url(../../../assets/imgs/container/subpage-bigshelf-mousehover.png) no-repeat;
          background-size: 100% 100%;
        }
      }
      .container-img-fridge {
        background: url(../../../assets/imgs/container/subpage-bigshelf-blue.png) no-repeat;
        background-size: 100% 100%;
        &:hover {
          background: url(../../../assets/imgs/container/subpage-bigshelf-mousehover.png) no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          transition: all 0.3s;
        }
      }
    }
  }
  .container-img-fridge {
    height: 20px;
    width: 60px;
    background: url(../../../assets/imgs/container/subpage-bigshelf-grey.png) no-repeat;
    background-size: 100% 100%;
  }
}

</style>
