<template>
  <modal v-model="showModal" title="添加 field 问卷随访项" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="fieldForm" :model="field" :rules="fieldRule" label-width="100px" item-width="250px">
      <form-item label="唯一编码" prop="key">
        <y-input v-model="field.key" disabled></y-input>
      </form-item>
      <form-item label="显示名称" prop="title">
        <y-input v-model="field.title"></y-input>
      </form-item>
      <form-item label="组件分类" prop="type">
        <y-select v-model="field.type" :data="typeList"></y-select>
      </form-item>
      <form-item label="保存值类型" prop="fieldType">
        <y-select v-model="field.fieldType" :data="fieldTypeList"></y-select>
      </form-item>
      <form-item label="是否可复制" v-if="field.type==='input'">
        <checklist inline v-model="field.copyable" :items="boolList"></checklist>
      </form-item>
      <form-item label="是否可删除" v-if="field.type==='input'">
        <checklist inline v-model="field.removeable" :items="boolList"></checklist>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import Checklist from '../../common/checklist/checklist'

export default {
  components: { Modal, YForm, FormItem, YSelect, YInput, Checklist },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: this.show,
      field: {
        key: '',
        title: '',
        type: '',
        fieldType: '',
        queryable: false,
        queryTitle: '',
        reportable: false,
        reportTitle: '',
        fid: ''
        // triggeredItems: []
      },
      typeList: [
        {
          name: '多选',
          children: [
            {name: '多选(mselect)', value: 'mselect'},
            {name: '多选输入(mselect_input)', value: 'mselect_input'}
          ]
        },
        {
          name: '单选',
          children: [
            {name: '单选_多行单选(sselect)', value: 'sselect'},
            {name: '单选_行内单选(sselect_inline)', value: 'sselect_inline'},
            {name: '单选_联动CRF表(sselect_show_quest)', value: 'sselect_show_quest'}
          ]
        },
        {
          name: '输入',
          children: [
            {name: '输入(input)', value: 'input'},
            {name: '表格输入(input_table)', value: 'input_table'}
          ]
        },
        {
          name: '显示',
          children: [
            {name: '显示(show)', value: 'show'}
          ]
        },
        {
          name: '上传',
          children: [
            {name: '文件(file)', value: 'file'}
          ]
        }
      ],
      fieldTypeList: [
        {name: '多选', value: 'array'},
        {name: '单选', value: 'object'},
        {name: '输入', value: 'input'},
        {name: '显示', value: 'show'},
        {name: '评分', value: 'score'},
        {name: '文件', value: 'file'}
      ],
      boolList: [
        {key: '1', title: '是', value: true},
        {key: '0', title: '否', value: false}
      ],
      fieldRule: {
        key: [{required: true, message: '不能为空', trigger: 'change'}],
        title: [{required: true, message: '不能为空', trigger: 'blur'}],
        type: [{required: true, message: '不能为空', trigger: 'change'}],
        fieldType: [{required: true, message: '不能为空', trigger: 'change'}]
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
        this.setKey()
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    'field.type' (val) {
      this.field.fieldType = ''
      this.resetFieldTypeList(val)
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.fieldForm.validate()
      if (!this.$refs.fieldForm.isValid) return false
      return true
    },
    onConfirm () {
      // 保证唯一性
      this.field.fid = 'fid_' + new Date().getTime().toString()
      this.$emit('on-confirm-ok', this.field)
    },
    setKey () {
      const currentNode = this.$parent.$currentTreeNode
      if (!currentNode) return
      this.field.key = currentNode.getNewChildKey()
    },
    resetFieldTypeList (type) {
      if (type && type.indexOf('mselect') >= 0) {
        this.fieldTypeList = [{name: '多选', value: 'array'}]
      } else if (type && type.indexOf('sselect') >= 0) {
        this.fieldTypeList = [{name: '单选', value: 'object'}, {name: '评分', value: 'score'}]
      } else if (type && type.indexOf('input') >= 0) {
        this.fieldTypeList = [{name: '输入', value: 'input'}]
      } else if (type && type.indexOf('show') >= 0) {
        this.fieldTypeList = [{name: '评分', value: 'score'}]
      } else if (type && type.indexOf('file') >= 0) {
        this.fieldTypeList = [{name: '文件', value: 'file'}]
      }
    }
  }
}
</script>

<style lang="css">
</style>
