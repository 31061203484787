<template>
  <label class="checkbox-wrapper"
    :class="{'checkbox-group-item': group,
            'checkbox-wrapper-checked': currentValue,
            'checkbox-wrapper-disabled': disabled}">
    <span class="checkbox"
      :class="{'checkbox-checked': currentValue,
              'checkbox-disabled': disabled,
              'checkbox-indeterminate': indeterminate}">
      <span class="checkbox-inner"></span>
      <input
        v-if="group"
        type="checkbox"
        class="checkbox-input"
        :disabled="disabled"
        :value="label"
        v-model="model"
        @change="change">
      <input
        v-if="!group"
        type="checkbox"
        class="checkbox-input"
        :disabled="disabled"
        :checked="currentValue"
        @change="change">
    </span>
    <slot v-if="this.$slots.default || label"><span>{{ label }}</span></slot>
  </label>
</template>

<script>
import Emitter from '../mixins/emitter'

export default {
  name: 'Checkbox',
  mixins: [ Emitter ],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: [String, Number, Boolean]
    },
    indeterminate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: [],
      currentValue: this.value,
      group: false
    }
  },
  watch: {
    value () {
      this.updateModel()
    }
  },
  mounted () {
    // todo 使用 while向上查找
    if (this.$parent && this.$parent.$options.name === 'CheckboxGroup') this.group = true
    if (!this.group) {
      this.updateModel()
    }
  },
  methods: {
    change (event) {
      if (this.disabled) {
        return false
      }

      const checked = event.target.checked
      this.currentValue = checked
      this.$emit('input', checked)

      if (this.group) {
        this.$parent.change(this.model)
      } else {
        this.$emit('on-change', checked)
        this.dispatch('FormItem', 'on-form-change', checked)
      }
    },
    updateModel () {
      this.currentValue = this.value
    }
  }
}
</script>
