<template>
  <modal v-model="showModal" title="新建抽屉" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm" :styles="{top: '60px'}">
    <y-form ref="layerForm" :model="layer" :rules="layerRule" label-width="100px" item-width="250px">
      <form-item label="抽屉编号" prop="layerNum">
        <input-number v-model="layer.layerNum"></input-number>
      </form-item>
      <form-item label="抽屉标签" prop="label">
        <y-input v-model="layer.label"></y-input>
      </form-item>
      <form-item label="备注" prop="desc">
        <y-input v-model="layer.desc" type="textarea"></y-input>
      </form-item>
      <div class="split-line"></div>
      <form-item label="每抽屉盒子数" prop="sampleboxCount">
        <input-number v-model="layer.sampleboxCount"></input-number>
      </form-item>
      <form-item label="样本盒类型" prop="exboxType">
        <y-select v-model="layer.exboxType" :data="exboxTypeList"></y-select>
      </form-item>
      <template v-if="layer.exboxType==='strawbox'">
        <form-item label="麦管盒数" prop="strawboxCount">
          <input-number v-model="layer.strawboxCount"></input-number>
        </form-item>
      </template>
      <form-item label="盒子行数" prop="sampleboxRow">
        <input-number v-model="layer.sampleboxRow"></input-number>
      </form-item>
      <form-item label="盒子列数" prop="sampleboxCol">
        <input-number v-model="layer.sampleboxCol"></input-number>
      </form-item>
      <form-item label="盒子类型" prop="sampleboxType">
        <y-select v-model="layer.sampleboxType" :data="typeList"></y-select>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../modal/modal.vue'
import YForm from '../../form/form'
import FormItem from '../../form/form-item'
import YInput from '../../input/input'
import InputNumber from '../../input/input-number'
import YSelect from '../../select/select'
import Poptip from '../../poptip/poptip'
export default {
  components: { Modal, YForm, FormItem, YInput, InputNumber, YSelect, Poptip },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      layer: this.value || {
        layerNum: 1,
        label: '',
        desc: '',
        sampleboxCount: 0,
        sampleboxRow: 1,
        sampleboxCol: 1,
        strawboxCount: 0
      },
      layerRule: {
        layerNum: [{type: 'number', min: 1, max: 80, required: true, message: '架子抽屉编号不能为空, ≥1 且 ≤80', trigger: 'blur'}],
        label: [{required: true, max: 15, message: '架子抽屉标签不能为空, 长度小于15', trigger: 'blur'}],
        exboxType: [{required: true, message: '样本盒类型不能为空', trigger: 'blur'}],
        sampleboxCount: [{type: 'number', min: 0, max: 10, message: '每抽屉盒子数 ≥0 且 ≤10', trigger: 'change'}],
        sampleboxRow: [{type: 'number', min: 1, max: 16, message: '盒子行数 ≥1 且 ≤16', trigger: 'change'}],
        sampleboxCol: [{type: 'number', min: 1, max: 12, message: '盒子列数 ≥1 且 ≤12', trigger: 'change'}]
      },
      exboxTypeList: [
        {name: '冻存管', value: 'samplebox'},
        {name: '麦管', value: 'strawbox'},
        {name: '切片', value: 'cardbox'}
      ],
      typeList: [
        {name: '普通类型', value: 'normal'},
        {name: '间隔类型', value: 'skip'}
      ]
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    'layer.layerNum' (val) {
      this.$emit('input', this.layer)
    },
    'layer.label' (val) {
      this.$emit('input', this.layer)
    },
    'layer.exboxType' (val) {
      this.$emit('input', this.layer)
    },
    'layer.strawboxCount' (val) {
      this.$emit('input', this.layer)
    },
    'layer.desc' (val) {
      this.$emit('input', this.layer)
    },
    'layer.sampleboxCount' (val) {
      this.$emit('input', this.layer)
    },
    'layer.sampleboxRow' (val) {
      this.$emit('input', this.layer)
    },
    'layer.sampleboxCol' (val) {
      this.$emit('input', this.layer)
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.layerForm.validate()
      if (!this.$refs.layerForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-add-ok')
    }
  }
}
</script>

<style lang="css">
</style>
