<template>
  <transition name="fade">
    <div v-show="currentShow"
      class="spin"
      :class="{'spin-fix': fix, 'spin-show-text': showText, [`spin-${size}`]: !!size, 'fix-top': global}"
      :style="{background: transparent ? transparent: false, height: global ? '100%' : height + 'px'}">
      <div class="spin-main">
        <span v-if="!icon" class="spin-dot"></span>
        <span v-if="icon === 'load-c'">
          <icon type="load-c" :size="18" class="spin-icon-load"></icon>
        </span>
        <div v-if="icon === 'circle'" class="loader">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"></circle>
          </svg>
        </div>
        <div class="spin-text">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from '../icon/icon'
import { oneOf } from '../../../utils/assist'

export default {
  name: 'Spin',
  components: {Icon},
  props: {
    value: {},
    size: {
      validator (value) {
        return oneOf(value, ['small', 'large'])
      }
    },
    fix: {
      type: Boolean,
      default: false
    },
    transparent: String,
    icon: String, // load-c
    global: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showText: false,
      currentShow: this.value,
      height: 0
    }
  },
  watch: {
    value (val) {
      this.currentShow = val
    },
    currentShow (val) {
      if (val) {
        this.resetHeight()
      }
    }
  },
  mounted () {
    this.showText = this.$slots.default !== undefined
  },
  methods: {
    resetHeight () {
      this.height = this.$parent && this.$parent.$el.scrollHeight
    }
  }
}
</script>

<style lang="less">
.spin-icon-load{
  animation: load-circle-spin 1s linear infinite;
}
.spin.fix-top {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}
.spin-main {
  font-size: 14px;
  .loader {
    width: 30px;
    height: 30px;
    position: relative;
    margin: 0 auto;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  .circular {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
}
// @keyframes rotate {
//   100% {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes color {
  100%, 0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%, 90% {
    stroke: #ffa700;
  }
}
</style>
