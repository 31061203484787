import Node from './fieldTree-node'

export default class TreeStore {
  constructor (options) {
    this.key = ''
    this.title = ''
    this.data = null
    this.props = {}

    this.currentNode = null

    for (let option in options) {
      if (options.hasOwnProperty(option)) {
        this[option] = options[option]
      }
    }

    this.nodesMap = {}

    this.rootNode = new Node({
      type: 'root',
      data: {
        key: this.key || 'root',
        title: this.title,
        value: this.title,
        fields: JSON.parse(JSON.stringify(this.data))
      },
      store: this
    })
  }

  registerNode (node) {
    if (!node || !node.data) return
    if (node.key) this.nodesMap[node.key] = node
  }

  deregisterNode (node) {
    if (!node || !node.data) return
    delete this.nodesMap[node.key]
  }

  getNodeByKey (key) {
    return this.nodesMap[key]
  }

  focus (status, deep) {
    this.rootNode.focus(status, deep)
  }

  getCheckedData () {
    const result = {}
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.type === 'field' && node.checked) {
        // find field and it's item or child
        result[node.key] = []
        node.childNodes.forEach(item => {
          if (item.checked) {
            result[node.key].push(item.key)
          }
        })
      }
    })
    return result
  }

  getGroupCodes () {
    const result = {}
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.type === 'field' && node.checked) {
        // 获取节点的groupCodeList
        let groupCodeList = node.getGroupCodeList()
        if (Array.isArray(groupCodeList) && groupCodeList.length) result[node.key] = groupCodeList
        node.childNodes.forEach(cnode => {
          if (cnode.checked) {
            let groupCodeList = cnode.getGroupCodeList()
            if (Array.isArray(groupCodeList) && groupCodeList.length) result[cnode.key] = groupCodeList
          }
        })
      }
    })
    return result
  }

  checkAllNodes () {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.type !== 'root') {
        node.checked = true
      }
    })
  }

  unCheckAllNodes () {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.type !== 'root') {
        node.checked = false
      }
    })
  }
}
