<template>
  <div class="field-tree">
    <field-tree-node
      :node="rootNode"
      :props="props"
      :key="getKeyField(rootNode)">
    </field-tree-node>
    <dataset-win :show="showDatasetWin"
      :datasourceList="datasourceList"
      :crftableList="crftableList"
      @on-hide="showDatasetWin=false"
      @on-confirm-ok="onDatasetAdd"></dataset-win>
    <patient-dataset-win :show="showPatientDatasetWin"
      :datasourceList="datasourceList"
      @on-hide="showPatientDatasetWin=false"
      @on-confirm-ok="onPatientDatasetAdd"></patient-dataset-win>
  </div>
</template>

<script>
import TreeStore from './model/fieldTree-store'
import FieldTreeNode from './field-tree-node'
import DatasetWin from '../win/dataset-win'
import PatientDatasetWin from '../win/patient-dataset-win'
import Emitter from '../../common/mixins/emitter'
import mixin from './mixin'

export default {
  components: {FieldTreeNode, DatasetWin, PatientDatasetWin},
  mixins: [Emitter, mixin],
  props: {
    title: String,
    data: Array,
    datasetType: {
      type: String,
      default: 'dataset'
    },
    props: {
      default () {
        return {
          children: 'children',
          label: 'name',
          value: 'value',
          type: 'type',
          key: 'id',
          icon: 'icon'
        }
      }
    },
    indent: {
      type: Number,
      default: 16
    },
    datasourceList: {
      type: Array,
      default () { return [] }
    },
    crftableList: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      $tree: null,
      $currentTreeNode: null, // for hide type list
      treeStore: null,
      rootNode: null,
      currentNode: null,

      showDatasetWin: false,
      showPatientDatasetWin: false,
      addedNodeType: ''
    }
  },
  watch: {
    data (val) {
      this.treeStore = new TreeStore({
        key: 'root',
        title: this.title,
        data: val,
        props: this.props,
        nodeType: this.datasetType
      })
      this.rootNode = this.treeStore.rootNode
    }
  },
  created () {
    this.$tree = this
    this.treeStore = new TreeStore({
      key: 'root',
      title: this.title,
      data: this.data,
      props: this.props,
      nodeType: this.datasetType
    })
    this.rootNode = this.treeStore.rootNode

    this.$on('on-add-click', () => {
      this.onTypeDatasetClick(this.datasetType)
    })
    this.$on('on-node-click', (data, type) => {
      this.$emit('on-tree-node-click', data, type)
    })
    this.$on('on-node-delete', (data) => {
      this.$emit('on-tree-node-delete', data)
    })
  },
  methods: {
    onTypeDatasetClick (type) {
      this.addedNodeType = type
      if (type === 'dataset') {
        this.showDatasetWin = true
      } else if (type === 'patientDataset') {
        this.showPatientDatasetWin = true
      }
    },
    onDatasetAdd (field) {
      if (this.$currentTreeNode) {
        this.$currentTreeNode.insertChild(field, this.addedNodeType)
      }
      this.$emit('on-tree-node-add', field)
    },
    onPatientDatasetAdd (field) {
      if (this.$currentTreeNode) {
        this.$currentTreeNode.insertChild(field, this.addedNodeType)
      }
      this.$emit('on-tree-node-add', field)
    },
    getNodeByKey (key) {
      return this.treeStore.getNodeByKey(key)
    },
    getData () {
      return this.treeStore.getData()
    },
    unfocusAll () {
      this.treeStore.unfocusAll()
    }
  }
}
</script>

<style lang="css">
</style>
