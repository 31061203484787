// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import polyfill from './utils/polyfill'

Vue.use(VueRouter)
Vue.use(VueResource)
polyfill()
// 'http://localhost:8000'
// https://www.iyxy.cn:50059
const httpRoot = ip + ':' + port
// const verifyRoot = ip + ':' + '50061'
// const verifyRoot = process.env.NODE_ENV === 'production_local'
//                   ? ip + ':50061'
//                   : 'https://www.iyxy.cn:50061'
if (DOCKER === 'docker') {
  Vue.prototype.httpRoot = $httpRoot
  Vue.prototype.verifyRoot = $httpRoot // 2022.11.10 给manage 增加接口 /verifycode, 不再与biobank共用接口
} else {
  Vue.prototype.httpRoot = httpRoot
  Vue.prototype.verifyRoot = httpRoot
}
Vue.http.headers.common['Authorization'] = 'Basic YXBpOnBhc3N3b3Jk'
Vue.http.headers.common['Accept'] = 'application/json'
Vue.http.headers.common['Content-Type'] = 'application/json; charset=utf-8'

import routes from './router/routes'
import deviceMixin from './utils/device'

let router = {}
if (process.env.NODE_ENV === 'production') {
  router = new VueRouter({
    mode: 'history',
    routes: routes
  })
} else {
  router = new VueRouter({
    mode: 'hash', // 'history'
    base: '/',
    routes: routes
  })
}

deviceMixin(Vue)

import store from './store'
import updateRoute from './store/updateRoute'
// import loadFileRoot from './utils/loadFileRoot' // 移到/src/components/mixins/
// loadFileRoot(store) // 改到登录后调用

router.beforeEach((to, from, next) => {
  if (to.path === '/home' && to.query.n1e3k2ot) {
    if (to.query.user) {
      store.dispatch('updateUserName', to.query.user)
    }
    store.dispatch('login', to.query.n1e3k2ot)
  }
  if (to.path !== '/login' && !store.getters.getLoginState) {
    updateRoute(store, from, to)
    router.push({name: 'login', query: {next: to.path}})
  } else {
    updateRoute(store, from, to)
    next()
  }
})

import App from './App'
import './registerComponents'

window.addEventListener('resize', function () {
  var winHeight = document.documentElement.clientHeight
  var winWidth = document.documentElement.clientWidth
  store.dispatch('updateWinHeight', winHeight)
  store.dispatch('updateWinWidth', winWidth)
}, false)

/* eslint-disable no-new */
const app = new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
