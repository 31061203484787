<template>
  <modal v-model="showModal" title="编辑 fieldMapping" width="500" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="itemForm" :model="item" :rules="itemRule" label-width="120px" item-width="320px">
      <form-item label="title">
        <y-input v-model="item.title" disabled></y-input>
      </form-item>
      <form-item label="rowKey">
        <y-input v-model="item.rowKey"></y-input>
      </form-item>
      <form-item label="fieldName" prop="fieldName">
        <y-input v-model="item.fieldName"></y-input>
      </form-item>
      <form-item label="type">
        <y-input v-model="item.type" disabled></y-input>
      </form-item>
      <form-item label="valueType" prop="valueType">
        <y-select v-model="item.valueType" :data="typeList"></y-select>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
export default {
  components: { Modal, YForm, FormItem, YSelect, YInput },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      showModal: this.show,
      item: this.value || {},
      typeList: ['int', 'float', 'string']
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    value (val) {
      this.item = val
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.itemForm.validate()
      if (!this.$refs.itemForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.item)
    }
  }
}
</script>

<style lang="css">
</style>
