<template>
  <div class="user-setting">
    <div class="user-group">
      <group-tree ref="tree"
        :data="groupList"
        @on-tree-update="updateGroupList"
        @on-tree-node-click="onTreeNodeClick"></group-tree>
    </div>
    <div class="user-list" v-show="showUserList">
      <div class="user-item" v-for="item in userList" :key="item.id">
        <span class="name">{{item.name}}</span>
        <span class="role">{{roleMap[item.role] || '未知角色'}}</span>
        <span class="action ionic-icon ionic-icon-edit" @click.stop="onUserEdit(item)"></span>
        <span class="action ionic-icon ionic-icon-close" @click.stop="onUserDelete(item)"></span>
      </div>
      <div class="user-action-btn">
        <y-button @click.native="onUserAdd">新增用户</y-button>
      </div>
    </div>
    <user-add-win
      :projectId="projectId"
      :show="showUserAddWin"
      title="新增用户"
      @on-hide="showUserAddWin=false"
      @on-confirm-ok="onAddUser"></user-add-win>
    <user-add-win
      v-model="currentUser"
      :projectId="projectId"
      :show="showUserEditWin"
      title="编辑用户"
      @on-hide="showUserEditWin=false"
      @on-confirm-ok="onUpdateUser"></user-add-win>
  </div>
</template>

<script>
import YButton from '../common/button/button'
import UserAddWin from './win/user-add-win'
import GroupTree from './group/tree'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {YButton, UserAddWin, GroupTree},
  data () {
    return {
      showUserAddWin: false,
      showUserEditWin: false,
      currentUser: {},
      groupList: [],
      userList: [],
      roleList: [],

      currentGroup: {},
      showUserList: false
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    },
    roleMap () {
      let map = {}
      this.roleList.forEach(role => {
        map[role.value] = role.name
      })
      return map
    }
  },
  watch: {
    projectId (val) {
      if (val) {
        this.loadGroupList()
        this.loadRoleList()
      }
    }
  },
  mounted () {
    this.loadGroupList()
    this.loadRoleList()
  },
  methods: {
    loadGroupList () {
      this.$http.get(`${this.httpRoot}/admin/user/grouptree/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.groupList = res.treeList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    updateGroupList (code) {
      this.$http.post(`${this.httpRoot}/admin/user/grouptree/update`, {
        projectId: this.projectId,
        treeList: this.$refs.tree.getFieldsData(),
        groupCode: code || '' // 仅仅删除操作
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.treeList) {
          this.groupList = res.treeList || []
        }
        if (res.status === 'success') {
          this.$Message.success('更新用户组成功')
        } else {
          this.$Message.success(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadUserList () {
      this.$http.get(`${this.httpRoot}/admin/user/list`, authenticate({
        params: {projectId: this.projectId, groupCode: this.currentGroup.code}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.userList = res.userList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadRoleList () {
      this.$http.get(`${this.httpRoot}/admin/role/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.roleList = res.roleList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    addUsers (user, callback) {
      this.$http.post(`${this.httpRoot}/admin/user/add`, {
        projectId: this.projectId,
        groupCode: this.currentGroup.code,
        user
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('添加用户成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.$Message.error(res.statusText || '添加失败')
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    updateUser (user, callback) {
      this.$http.post(`${this.httpRoot}/admin/user/update`, {
        projectId: this.projectId, user
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('更新用户成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.$Message.error(res.statusText || '更新失败')
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    deleteUser (id, callback) {
      this.$http.post(`${this.httpRoot}/admin/user/delete`, {id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('删除用户成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.$Message.error(res.statusText || '删除失败')
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onTreeNodeClick (data, type) {
      if (type === 'child') {
        this.currentGroup = data
        this.loadUserList(data.code)
        this.showUserList = true
      } else {
        this.showUserList = false
      }
    },
    onUserAdd () {
      this.showUserAddWin = true
    },
    onAddUser (user) {
      this.addUsers(user, () => {
        this.loadUserList()
      })
    },
    onUserEdit (item) {
      this.currentUser = item
      this.showUserEditWin = true
    },
    onUpdateUser (user) {
      this.updateUser(user, () => {
        this.loadUserList()
      })
    },
    onUserDelete (item) {
      this.$Modal.confirm({
        title: '删除用户',
        content: '确认删除该用户？',
        onOk: () => {
          this.deleteUser(item.id, () => {
            this.loadUserList()
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
@import "../../assets/ivu/custom";
.user-setting {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.user-group {
  width: 500px;
  float: left;
}
.user-list {
  float: left;
  margin-left: 15px;
}
.user-item {
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  span {
    display: inline-block;
  }
  .name {
    width: 120px;
    padding-left: 15px;
  }
  .role {
    width: 80px;
    font-size: 13px;
    color: #666;
  }
  .action {
    width: 40px;
    color: #666;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: @primary-color;
    }
  }
  &:nth-child(odd) {
    background: #f3f3f3;
  }
  &:nth-child(even) {
    background: #e6ebf1;
  }
}
.user-action-btn {
  margin-top: 15px;
}
</style>
