import Node from './fieldTree-node'

export default class TreeStore {
  constructor (options) {
    this.key = ''
    this.title = ''
    this.data = null
    this.props = {}

    this.nodeType = '' // 用来创建节点统一类型

    this.currentNode = null

    for (let option in options) {
      if (options.hasOwnProperty(option)) {
        this[option] = options[option]
      }
    }

    this.nodesMap = {}

    this.rootNode = new Node({
      type: 'root',
      data: {
        id: this.key || 'root_key_id',
        name: this.title,
        value: this.title,
        children: JSON.parse(JSON.stringify(this.data))
      },
      store: this
    })
  }

  registerNode (node) {
    if (!node || !node.data) return
    if (node.key) this.nodesMap[node.key] = node
  }

  deregisterNode (node) {
    if (!node || !node.data) return
    delete this.nodesMap[node.key]
  }

  getNodeByKey (key) {
    return this.nodesMap[key]
  }

  focus (status, deep) {
    this.rootNode.focus(status, deep)
  }

  getData () {
    return this.rootNode.data.children || []
  }

  unfocusAll () {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      node.focused = false
    })
  }
}
