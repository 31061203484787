<template>
  <div class="flt-list" @change="hanldeChange">
    <div class="fil-item" v-for="(item, index) of filters" :key="index">
      <div class="check">
        <input type="checkbox" v-model="item.check"/>
      </div>
      <div class="bool">
        <select class="flt-select-bool" v-model="item.bool" v-if="index>0">
          <option value="and">and</option>
          <option value="or">or</option>
          <option value="not">not</option>
        </select>
      </div>
      <div class="col">
        <select class="flt-select-col" v-model="item.col">
          <option v-for="item of cols" :value="item.key" :key="item.key">{{item.key}}</option>
        </select>
      </div>
      <div class="rel">
        <select class="flt-select-rel" v-model="item.rel">
          <option value="=">=</option>
          <option value=">">&gt;</option>
          <option value=">=">&gt;=</option>
          <option value="<">&lt;</option>
          <option value="<=">&lt;=</option>
          <option value="<>">&lt;&gt;</option>
          <!-- <option value="in">in</option> -->
          <option value=" like ">like</option>
        </select>
      </div>
      <div class="val">
        <input class="input" v-model="item.val"/>
      </div>
      <div class="action">
        <span class="btn" @click.stop="remove(item)">X</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cols: [],
    filters: []
  },
  methods: {
    remove (item) {
      const index = this.filters.indexOf(item)
      if (index >= 0) {
        this.filters.splice(index, 1)
        this.$emit('on-change')
      }
    },
    hanldeChange (e) {
      e.stopPropagation()
      this.$emit('on-change')
    }
  }
}
</script>
<style lang="less">
.fil-item {
  display: flex;
  margin-bottom: 10px;
  .check {
    width: 40px;
    margin-right: 10px;
    text-align: center;
    height: 32px;
    line-height: 32px;
  }
  .bool {
    width: 60px;
    margin-right: 10px;
  }
  .col {
    width: 200px;
    margin-right: 10px;
  }
  .val {
    width: 115px;
  }
  .action {
    margin-left: 10px;
  }
}
.flt-select-bool {
  padding-left: 6px;
  width: 60px;
  height: 32px;
  line-height: 32px;
  outline: 0;
}
.flt-select-col {
  padding-left: 6px;
  width: 200px;
  height: 32px;
  line-height: 32px;
  outline: 0;
}
.flt-select-rel {
  padding-left: 6px;
  width: 100px;
  height: 32px;
  line-height: 32px;
  outline: 0;
  margin: 0 10px;
}
</style>
