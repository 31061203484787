<template>
  <modal v-model="showModal" title="添加 CRF" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="currentDataForm" :model="currentData" :rules="currentDataRule" label-width="100px" item-width="250px">
      <form-item label="CRF 名称" prop="title">
        <y-input v-model="currentData.title"></y-input>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YInput from '../../common/input/input'

export default {
  components: { Modal, YForm, FormItem, YInput },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: this.show,
      currentData: {
        // id: '',
        title: '',
        items: []
      },
      currentDataRule: {
        title: [{required: true, message: '不能为空', trigger: 'blur'}]
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.currentDataForm.validate()
      if (!this.$refs.currentDataForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.currentData)
    }
  }
}
</script>

<style lang="css">
</style>
