export function getPropertyFromData (node, prop) {
  const props = node.store.props
  const data = node.data || {}
  const relProp = props[prop]

  if (typeof relProp === 'function') {
    return relProp(data, node)
  } else if (typeof relProp === 'string') {
    return data[relProp]
  } else if (typeof relProp === 'undefined') {
    return null
  }
}
