<template>
  <div class="spage">
    <div class="api-hd">
      <span class="label">选择功能模块</span>
      <y-select
        style="width: 200px;"
        v-model="currentModule"
        :data="apiModuleList"
        valueField="module"
        attrInValue
        @on-select-change="onModuleSelect"></y-select>
      <span style="padding: 0 10px;font-size: 13px;">系统API</span>
      <select-tree
        style="width: 600px"
        :data="sysApiList"
        labelField="operation"
        valueField="api"
        showField="api"
        multiple
        clearable
        @on-select-change="onApiSelect"></select-tree>
      <button class="btn" @click.stop="onAddApi">添加</button>
    </div>
    <div class="api-list">
      <div class="api-item" v-for="item in apiList" :key="item.api">
        <span class="operation">{{item.operation}}</span>
        <y-select
          :id="item.api"
          v-model="item.roles"
          :data="roleList"
          style="width: 600px;"
          multiple
          @on-select-change="onApiRolesChange"></y-select>
        <span class="icon ionic-icon ionic-icon-close" @click.stop="deleteApi(item)"></span>
      </div>
    </div>
    <div style="font-size:12px">
      <span>黑名单一般设计原则：</span>
      <p>1.系统管理员拥有有所有菜单和功能，故不做限制</p>
      <p>2.管理员在自己组下拥有所有功能，故不做限制</p>
      <p>3.1普通用户, 能够录入、编辑和查询样本、患者、随访和随访数据</p>
      <p>3.2普通用户, 能够导出样本数据（用于交给管理员物理出库）</p>
      <p>3.3普通用户, 不能删除和导出患者、随访、随访数据，不能删除、销毁样本</p>
      <p>3.4普通用户, 不能给患者分组</p>
      <p>4.1普通用户1, 能够录入患者和随访数据，能够查询患者（需要根据患者查询随访然后录入随访数据）</p>
      <p>4.1普通用户1, 不能查询、删除、修改和导出样本、患者和随访；随访创建应该由管理员或普通用户进行，普通用户可能是学生只能执行指定数据录入任务</p>
    </div>
  </div>
</template>

<script>
import YSelect from '../common/select/select'
import SelectTree from '../common/select/select-tree'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {YSelect, SelectTree},
  data () {
    return {
      apiModuleList: [],
      apiList: [],
      apiListClone: [],
      sysApiList: [],
      roleList: [],
      currentModule: '',
      currentApiModule: {},
      currentApi: {}
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    }
  },
  watch: {
    projectId (val) {
      if (val) {
        this.loadApiModuleList()
        this.loadRoleList()
      }
    },
    apiModuleList (val) {
      if (val && val.length) {
        this.$nextTick(() => {
          this.currentApiModule = val[0]
          this.currentModule = val[0].module
        })
      }
    },
    currentApiModule (val) {
      if (val) {
        this.loadApiList(val)
        this.loadSysApiList(val)
      }
    }
  },
  mounted () {
    this.loadApiModuleList()
    this.loadRoleList()
    this.loadSysApiList({})
  },
  methods: {
    onModuleSelect (val) {
      this.currentApiModule = val
    },
    onApiRolesChange (val, selectId) {
      let oldRoles = []
      let newRoles = []
      this.apiList.forEach(item => {
        if (item.api === selectId) {
          newRoles = item.roles
          this.currentApi = item
        }
      })
      this.apiListClone.forEach(item => {
        if (item.api === selectId) {
          oldRoles = item.roles
        }
      })
      if (val && JSON.stringify(oldRoles) !== JSON.stringify(newRoles)) {
        this.updateApi(() => {
          this.apiListClone = JSON.parse(JSON.stringify(this.apiList))
        })
      }
    },
    loadApiModuleList () {
      this.$http.get(`${this.httpRoot}/api/module/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.apiModuleList = res.moduleList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadApiList (apiModule) {
      this.$http.get(`${this.httpRoot}/api/list`, authenticate({
        params: {projectId: this.projectId, module: apiModule.module}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.apiList = res.apiList || []
          this.apiListClone = JSON.parse(JSON.stringify(this.apiList))
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadRoleList () {
      this.$http.get(`${this.httpRoot}/admin/role/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.roleList = res.roleList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    updateApi (callback) {
      this.$http.post(`${this.httpRoot}/api/update`, {
        projectId: this.projectId, api: this.currentApi
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('更新权限成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    addApi (apiList) {
      this.$http.post(`${this.httpRoot}/api/add`, {
        projectId: this.projectId, apiList
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('更新权限成功')
          this.loadApiList(this.currentApiModule)
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    deleteApi (api) {
      if (!api || !api.id) return
      this.$http.post(`${this.httpRoot}/api/delete`, {
        projectId: this.projectId, id: api.id
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('更新权限成功')
          this.loadApiList(this.currentApiModule)
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadSysApiList (apiModule) {
      this.$http.get(`${this.httpRoot}/api/list`, authenticate({
        params: {projectId: 'sys_api', module: apiModule.module}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$nextTick(() => {
            this.sysApiList = res.apiList || []
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onApiSelect (apiList) {
      this.selectedApiList = apiList
    },
    onAddApi () {
      this.addApi(this.selectedApiList)
    }
  }
}
</script>

<style lang="less">
.spage {
  padding-top: 10px;
  padding-left: 15px;
}
.api-hd {
  margin-bottom: 15px;
  .label {
    font-size: 14px;
    padding-right: 5px;
    display: inline-block;
    min-width: 180px;
    height: 32px;
    line-height: 32px;
    padding-left: 5px;
    background: #f1f3f7;
  }
}
.api-item {
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
  .operation {
    font-size: 14px;
    padding-right: 5px;
    display: inline-block;
    min-width: 180px;
  }
  .icon {
    margin: 0 10px;
    cursor: pointer;
  }
}
</style>
