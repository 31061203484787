<template>
  <div class="root-container" :style="{width: width}">
    <collapse-panel :title="title" v-model="containers.expanded"
      v-show="containers.items && containers.items.length">
      <nitrogen v-if="maxSection > 1"
        :chartWidth="150"
        :data="sections"
        :section="currentSection"
        @on-section-select="onSectionSelect"
        @on-section-unselect="onSectionUnselect">
      </nitrogen>
      <div class="shelf-container__box">
        <template v-for="level in maxLevel">
          <div class="shelf-container__level" :key="level">
            <div class="shelf-container__item"
              :class="{enable: item.enable, selected: item.selected}"
              v-for="item in getLevelItems(level, currentSection)"
              :key="item.id"
              @click.stop="onClick(item)">
              <div class="container-img-fridge" :title="item.label">
              </div>
              <div class="container-name">
                <span>{{item.label}}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </collapse-panel>
  </div>
</template>

<script>
import CollapsePanel from '../transitions/collapse-panel'
import Nitrogen from './nitrogen'
export default {
  name: 'Shelf',
  components: { CollapsePanel, Nitrogen },
  props: {
    width: String,
    containers: {
      type: Object,
      default: function () {
        return {
          items: [],
          type: 'fridge',
          expanded: false,
          loaded: false
        }
      }
    }
  },
  data () {
    return {
      title: '冻存架',
      sections: [],
      currentSection: 0
    }
  },
  computed: {
    maxLevel () {
      let level = 0
      this.containers.items.forEach((item) => {
        if (item.level > level) level = item.level
      })
      return level
    },
    maxSection () {
      let section = 0
      this.containers.items.forEach((item) => {
        if (item.section > section) section = item.section
      })
      let data = [1, 1, 1, 1, 1, 1, 1, 1]
      this.sections = data.slice(0, section)
      return section
    }
  },
  watch: {
    'containers.items' (val) {
      if (val) {
        this.currentSection = 0
      }
    }
  },
  methods: {
    getLevelItems (level, section) {
      let items = []
      this.containers.items.forEach((item) => {
        if (section) {
          if (item.section === section && item.level === level) items.push(item)
        } else {
          if (item.level === level) items.push(item)
        }
      })
      return items
    },
    onSectionSelect (section) {
      this.currentSection = section
      this.$emit('on-shelf-section-selected', section)
    },
    onSectionUnselect () {
      this.currentSection = 0
      this.$emit('on-shelf-section-unselected')
    },
    onClick (item) {
      if (item.enable) {
        if (item.selected) {
          // reselect
          item.select(true, true)
        } else {
          item.select(true)
        }
        this.$emit('on-shelf-selected', item)
      }
    }
  }
}
</script>

<style lang="less">
@import "../../../assets/ivu/mixins/clear";
.shelf-container__box {
  display: flex;
  position: relative;
  justify-content: space-around;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
  .shelf-container__level {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
    text-align: center;
    &:after {
      .clearFloat();
    }
  }
  .shelf-container__item {
    float: left;
    padding: 5px;
    flex: 0 0 auto;
    &.selected {
      background: rgba(67, 186, 255, 0.3);
    }
    &.enable {
      .container-img-fridge {
        background: url(../../../assets/imgs/container/subpage-shelf-blue.png) no-repeat;
        background-size: 100% 100%;
      }
      &:hover {
        background: rgba(67, 186, 255, 0.3);
        transition: all 0.3s;
      }
    }
  }
  .container-img-fridge {
    width: 35px;
    height: 55px;
    margin: 0 auto;
    text-align: center;
    background: url(../../../assets/imgs/container/subpage-shelf-grey.png) no-repeat;
    background-size: 100% 100%;
  }
  .container-name {
    text-align: center;
    font-size: 12px;
    max-width: 35px;
    white-space: nowrap;
    overflow: hidden;
    transform: scale(0.9);
    position: relative;
    height: 14px;
    & > span {
      position: absolute;
      left: -1px;
    }
  }
}

</style>
