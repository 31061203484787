<template>
  <modal v-model="showModal" title="添加 child 子输入" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="childForm" :model="child" :rules="childRule" label-width="100px" item-width="250px">
      <form-item label="唯一编码" prop="key">
        <y-input v-model="child.key" disabled></y-input>
      </form-item>
      <form-item label="组件类型" v-if="showType">
        <y-select v-model="child.type" :data="typeList"></y-select>
      </form-item>
      <form-item label="显示名称">
        <y-input v-model="child.title"></y-input>
      </form-item>
      <form-item label="保存值" v-if="showValue">
        <y-input v-model="child.value"></y-input>
      </form-item>
      <form-item label="保存值类型" prop="valueType">
        <y-select v-model="child.valueType" :data="valueTypeList"></y-select>
      </form-item>
      <form-item label="单位">
        <y-input v-model="child.unit"></y-input>
      </form-item>
      <form-item label="值 ≥" v-if="showMinMax">
        <y-input v-model="child.min"></y-input>
      </form-item>
      <form-item label="值 ≤" v-if="showMinMax">
        <y-input v-model="child.max"></y-input>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'

export default {
  components: { Modal, YForm, FormItem, YSelect, YInput },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: this.show,
      child: {
        key: '',
        title: '',
        value: '',
        valueType: '',
        unit: '',
        queryable: false,
        queryTitle: '',
        reportTitle: ''
      },
      showType: false,
      showValue: false,
      showMinMax: false,
      valueTypeList: [
        {name: '字符串', value: 'string'},
        {name: '日期', value: 'date'}, // 后台存储 Date, 返回前台string
        {name: '数值', value: 'number'},
        {name: '整数', value: 'integer'}
      ],
      typeList: [
        {name: '单选项(sselect)', value: 'sselect'},
        {name: '单选项_行内输入(sselect_input_inline)', value: 'sselect_input_inline'},
        {name: '多选项(mselect)', value: 'mselect'},
        {name: '多选项_行内输入(mselect_input_inline)', value: 'mselect_input_inline'},
        {name: '多选项_文本输入(mselect_input)', value: 'mselect_input'}
      ],
      childRule: {
        key: [{required: true, message: '不能为空', trigger: 'change'}],
        valueType: [{required: true, message: '不能为空', trigger: 'change'}]
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
        this.setKey()
        this.updateShow()
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    'child.title' (val) {
      this.child.value = val
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.childForm.validate()
      if (!this.$refs.childForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.child)
    },
    setKey () {
      const currentNode = this.$parent.$currentTreeNode
      if (!currentNode) return
      this.child.key = currentNode.getNewChildKey()
    },
    updateShow () {
      const $currentNode = this.$parent.$currentTreeNode
      if (!$currentNode) return
      const type = $currentNode.node.data.type
      if (type === 'input_sselect' || type === 'input_sselect_input' || type === 'input_sselect_multi_line' || type === 'input_mselect' || type === 'input_mselect_input') {
        this.showValue = true
      } else {
        this.showValue = false
      }
      if (type === 'input_mselect_input') {
        this.typeList = [
          {name: '多选项(mselect)', value: 'mselect'},
          {name: '多选项_行内输入(mselect_input_inline)', value: 'mselect_input_inline'},
          {name: '多选项_文本输入(mselect_input)', value: 'mselect_input'}
        ]
        this.showType = true
      } else if (type === 'input_sselect_input') {
        this.typeList = [
          {name: '单选项(sselect)', value: 'sselect'},
          {name: '单选项_行内输入(sselect_input_inline)', value: 'sselect_input_inline'}
        ]
        this.showType = true
      } else {
        this.showType = false
      }
      if (type === 'show_score') {
        this.showMinMax = true
      } else {
        this.showMinMax = false
      }
    }
  }
}
</script>

<style lang="css">
</style>
