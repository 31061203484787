<template>
  <modal v-model="showModal" title="选择 callFunc" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-select
      v-model="currentMethodName"
      valueField="methodName"
      labelField="methodName"
      :data="callFnList"
      clearable
      attrInValue
      @on-select-change="onCallFuncSelect"></y-select>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YSelect from '../../common/select/select'
export default {
  components: {Modal, YSelect},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    modelId: String,
    callFnList: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      showModal: this.show,
      currentMethodName: this.modelId || '',
      currentCallFunc: {}
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    modelId (val) {
      this.currentMethodName = val
    }
  },
  methods: {
    onBeforeConfirm () {
      if (!this.currentMethodName) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.currentCallFunc)
    },
    onCallFuncSelect (val) {
      if (val && val.methodName) {
        this.currentCallFunc = val
      } else {
        this.currentCallFunc = {}
      }
    }
  }
}
</script>

<style lang="less">
</style>
