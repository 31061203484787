import Node from './tree-node'

export default class TreeStore {
  constructor (options) {
    this.key = ''
    this.name = ''
    this.data = null
    this.props = {}

    this.currentNode = null

    for (let option in options) {
      if (options.hasOwnProperty(option)) {
        this[option] = options[option]
      }
    }

    this.nodesMap = {}

    this.rootNode = new Node({
      type: 'root',
      data: {
        name: this.name,
        code: this.code || 'system',
        children: JSON.parse(JSON.stringify(this.data || []))
      },
      store: this
    })
  }

  registerNode (node) {
    if (!node || !node.data) return
    if (node.key) this.nodesMap[node.key] = node
  }

  deregisterNode (node) {
    if (!node || !node.data) return
    delete this.nodesMap[node.key]
  }

  getNodeByKey (key) {
    return this.nodesMap[key]
  }

  focus (status, deep) {
    this.rootNode.focus(status, deep)
  }

  getFieldsData () {
    return this.rootNode.data.children || []
  }
}
