<template>
  <div class="panel-field">
    <div class="panel-edit-title">(group) 分组编辑</div>
    <y-form ref="groupForm" :model="group" :rules="groupRule" label-width="100px" item-width="70%">
      <form-item label="显示名称" prop="title">
        <y-input v-model="group.title"></y-input>
      </form-item>
    </y-form>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YInput from '../../common/input/input'
export default {
  components: { YForm, FormItem, YInput },
  props: {
    data: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      group: this.data,
      groupRule: {
        title: [{required: true, message: '不能为空', trigger: 'blur'}]
      }
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.group = val
      }
    }
  },
  methods: {
    //
  }
}
</script>

<style lang="css">
</style>
