<template>
  <div class="param-list">
    <div class="param-item params" v-for="(item, index) in currentValue" :key="index">
      <span class="param-label-name">Name:</span>
      <input class="param-val-name" :value="item.name" @change="onKeyChange(index, $event)">
      <span class="param-label-value">Value:</span>
      <input class="param-val-value" :value="item.value" @change="onValueChange(index, $event)">
      <span class="param-label-value">TestValue:</span>
      <input class="param-val-testvalue" :value="item.testValue" @change="onTestValueChange(index, $event)">
      <span class="param-label-type">Type:</span>
      <select class="param-val-type" :value="item.type" @change="onTypeChange(index, $event)">
        <option>number</option>
        <option>string</option>
        <option>date</option>
      </select>
      <!-- <input class="param-val-3" :value="item.type" @change="onTypeChange(index, $event)"> -->
      <span class="ionic-icon ionic-icon-close-round" @click="remove(index)"></span>
    </div>
    <div class="param-add">
      <button type="button" @click.stop="addItem">添加</button>
    </div>
  </div>
</template>

<script>
import Emitter from '../../mixins/emitter'
export default {
  mixins: [Emitter],
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      currentValue: this.value || []
    }
  },
  watch: {
    value (val, oldVal) {
      if (val && JSON.stringify(oldVal) !== JSON.stringify(val)) {
        this.currentValue = val
      }
    },
    currentValue (val) {
      this.$emit('input', val)
      this.dispatch('FormItem', 'on-form-change', val)
      this.$emit('on-change', val)
    }
  },
  methods: {
    onKeyChange (index, e) {
      let val = {
        name: e.target.value,
        value: this.currentValue[index] ? this.currentValue[index].value : '',
        testValue: this.currentValue[index] ? this.currentValue[index].testValue : '',
        type: this.currentValue[index] ? this.currentValue[index].type : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onValueChange (index, e) {
      let val = {
        name: this.currentValue[index] ? this.currentValue[index].name : '',
        value: e.target.value,
        testValue: this.currentValue[index] ? this.currentValue[index].testValue : '',
        type: this.currentValue[index] ? this.currentValue[index].type : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onTestValueChange (index, e) {
      let val = {
        name: this.currentValue[index] ? this.currentValue[index].name : '',
        value: this.currentValue[index] ? this.currentValue[index].value : '',
        testValue: e.target.value,
        type: this.currentValue[index] ? this.currentValue[index].type : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onTypeChange (index, e) {
      let val = {
        name: this.currentValue[index] ? this.currentValue[index].name : '',
        value: this.currentValue[index] ? this.currentValue[index].value : '',
        testValue: this.currentValue[index] ? this.currentValue[index].testValue : '',
        type: e.target.value
      }
      this.$set(this.currentValue, index, val)
    },
    remove (index) {
      this.currentValue.splice(index, 1)
    },
    addItem () {
      this.currentValue.push({name: '', value: '', testValue: '', type: ''})
    }
  }
}
</script>

<style lang="less">
.param-list {
  flex: 1 1 auto;
}
.param-item {
  height: 28px;
  line-height: 28px;
  &.params {
    display: flex;
    justify-content: space-between;
  }
  .param-val-name, .param-val-value, .param-val-testvalue {
    flex: 1 1 auto;
    height: 100%;
    outline: 0;
    border: 0;
    border-bottom: 1px solid #888;
  }
  .param-val-type {
    width: 12%;
    height: 100%;
    outline: 0;
    border: 0;
    border-bottom: 1px solid #888;
  }
  .ionic-icon {
    width: 20px;
    margin: 0 5px;
    cursor: pointer;
    &:hover {
      color: #3399ff;
    }
  }
}
.param-add {
  height: 28px;
  line-height: 28px;
  button {
    cursor: pointer;
    font-size: 12px;
    outline: 0;
    padding: 1px 7px;
    background: #fff;
    border: 1px solid #888;
    border-radius: 2px;
  }
}
</style>
