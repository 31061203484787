<template>
  <div class="field-tree">
    <tree-node
      v-if="showRootNode"
      :node="rootNode"
      :props="props">
    </tree-node>
    <div v-else class="field-tree-node-list">
      <tree-node v-for="child in rootNode.childNodes"
        :node="child"
        :props="props"
        :key="child.key">
      </tree-node>
    </div>
  </div>
</template>

<script>
import TreeStore from './model/tree-store'
import TreeNode from './tree-node'

export default {
  components: {TreeNode},
  props: {
    label: {
      type: String,
      default: 'tree'
    },
    data: Array,
    props: {
      default () {
        return {
          children: 'children',
          label: 'name',
          value: 'value',
          key: 'id'
        }
      }
    },
    indent: {
      type: Number,
      default: 16
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    showField: String,
    showRootNode: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      $tree: null,
      treeStore: null,
      rootNode: {}
    }
  },
  watch: {
    data (val) {
      this.treeStore = new TreeStore({
        label: this.label,
        data: val,
        props: this.props,
        showCheckbox: this.showCheckbox,
        showField: this.showField,
        multiple: this.multiple
      })
      this.rootNode = this.treeStore.rootNode
      this.$nextTick(() => {
        this.$emit('on-tree-updated')
      })
    }
  },
  created () {
    this.$tree = this
    this.treeStore = new TreeStore({
      label: this.label,
      data: this.data,
      props: this.props,
      showCheckbox: this.showCheckbox,
      showField: this.showField,
      multiple: this.multiple
    })
    this.rootNode = this.treeStore.rootNode

    this.$on('on-node-click', (data, type) => {
      this.$emit('on-tree-node-click', data, type)
    })
    this.$on('on-node-check', (data, type) => {
      this.$emit('on-tree-node-check', data, type)
    })
  },
  methods: {
    clearStatus () {
      this.treeStore.clearFocus()
    },
    focusNodeByDataValue (value) {
      this.treeStore.focusNodeByDataValue(value)
    },
    getNodeDataByDataValue (value) {
      return this.treeStore.getNodeDataByDataValue(value)
    }
  }
}
</script>
