const state = {
  freezerEditActive: false,
  shelfEditActive: false,
  layerEditActive: false,
  sampleboxEditActive: false,
  strawboxEditActive: false
}
const mutations = {
  UPDATE_FREEZER_EDIT_ACTIVE (state, status) {
    state.freezerEditActive = status
  },
  UPDATE_SHELF_EDIT_ACTIVE (state, status) {
    state.shelfEditActive = status
  },
  UPDATE_LAYER_EDIT_ACTIVE (state, status) {
    state.layerEditActive = status
  },
  UPDATE_SAMPLEBOX_EDIT_ACTIVE (state, status) {
    state.sampleboxEditActive = status
  },
  UPDATE_STRAWBOX_EDIT_ACTIVE (state, status) {
    state.strawboxEditActive = status
  }
}

export default {
  state,
  mutations
}
