<template>
  <div class="crf-table-item">
    <div class="crf-table-item__title" :class="{selected: selected}"
      @click.stop="onTableItemClick">
      <span class="fa" :class="{['fa-' + crftable.icon]: crftable.icon}"></span>
      {{crftable.title}}
      <!-- <span
        @click.stop="onShareCrfTable"
        class="shared ionic-icon"
        :class="[[crftable.shared?'ionic-icon-ios-star':'ionic-icon-ios-star-outline']]"></span> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    crftable: {
      type: Object,
      default () { return {} }
    },
    selected: Boolean
  },
  methods: {
    onTableItemClick () {
      this.$emit('on-crftable-click', this.crftable)
    }
    // onShareCrfTable () {
    //   this.$emit('on-crftable-share', this.crftable)
    // }
  }
}
</script>

<style lang="less">
.crf-table-item__title {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
  &.selected {
    background: #eddfdf;
    color: #3399ff;
    font-weight: 600;
  }
  .shared {
    padding: 0 5px;
  }
}
</style>
