<template>
  <div :class="[`${prefixCls}`, {[`${className}`]: !!className}]" :style="styles">
    <Notification
      v-for="notice in notices"
      :key="notice.name"
      :prefix-cls="prefixCls"
      :styles="notice.styles"
      :content="notice.content"
      :duration="notice.duration"
      :closable="notice.closable"
      :name="notice.name"
      :transition-name="notice.transitionName"
      :on-close="notice.onClose">
    </Notification>
  </div>
</template>

<script>
import Notification from './notification.vue'

let seed = 0
const now = Date.now()

function getUUID () {
  return 'Notification_' + now + '_' + (seed++)
}

export default {
  components: { Notification },
  props: {
    prefixCls: {
      type: String,
      default: 'notice'
    },
    styles: {
      type: Object,
      default: function () {
        return {
          top: '65px',
          left: '50%'
        }
      }
    },
    content: String,
    className: String
  },
  data () {
    return {
      notices: []
    }
  },
  methods: {
    add (notice) {
      const name = notice.name || getUUID()

      let _notice = Object.assign({
        styles: { right: '50%' },
        content: '',
        duration: 1.5,
        closable: false,
        name: name
      }, notice)

      this.notices.push(_notice)
    },
    close (name) {
      const notices = this.notices

      for (let i = 0; i < notices.length; i++) {
        if (notices[i].name === name) {
          this.notices.splice(i, 1)
          break
        }
      }
    }
  }
}
</script>
