<template>
  <div class="date-picker-cells date-picker-cells-year" @click="handleClick">
    <span v-for="(cell, index) in cells"
      :key="index"
      class="date-picker-cells-cell"
      :class="{'date-picker-cells-cell-selected': cell.selected,
              'date-picker-cells-cell-disabled': cell.disabled}">
      <em :index="index">{{ cell.text }}</em>
    </span>
  </div>
</template>

<script>
import { deepCopy } from '../../../../utils/assist'

export default {
  props: {
    date: {},
    year: {},
    disabledDate: {},
    selectionMode: {
      default: 'year'
    }
  },
  computed: {
    startYear () {
      return Math.floor(this.year / 10) * 10
    },
    cells () {
      let cells = []
      const cellTmpl = {
        text: '',
        selected: false,
        disabled: false
      }

      for (let i = 0; i < 10; i++) {
        const cell = deepCopy(cellTmpl)
        cell.text = this.startYear + i

        const date = new Date(this.date)
        date.setFullYear(cell.text)
        cell.disabled = typeof this.disabledDate === 'function' && this.disabledDate(date) && this.selectionMode === 'year'

        cell.selected = Number(this.year) === cell.text
        cells.push(cell)
      }

      return cells
    }
  },
  methods: {
    nextTenYear () {
      this.$emit('on-pick', Number(this.year) + 10, false)
    },
    prevTenYear () {
      this.$emit('on-pick', Number(this.year) - 10, false)
    },
    handleClick (event) {
      const target = event.target
      if (target.tagName === 'EM') {
        const cell = this.cells[parseInt(event.target.getAttribute('index'))]
        if (cell.disabled) return

        this.$emit('on-pick', cell.text)
      }
      this.$emit('on-pick-click')
    }
  }
}
</script>
