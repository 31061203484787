import Vue from 'vue'
import $Modal from './components/common/modal/modal.js'
import $Notice from './components/common/notice/notice.js'
import $Message from './components/common/message/message.js'
import $LoadingBar from './components/common/loadingbar/loading-bar.js'
// import CollapseTransition from 'components/common/transitions/collapse-transition'
// import Collapse from 'components/common/collapse/collapse'
// import CollapseItem from 'components/common/collapse/collapse-item'
Vue.prototype.$Modal = $Modal
Vue.prototype.$Notice = $Notice
Vue.prototype.$Message = $Message
Vue.prototype.$LoadingBar = $LoadingBar
