<template>
  <div class="table-cell"
    :class="{'table-cell-ellipsis': column.ellipsis, 'table-cell-hide': ($parent.hideRowSelect && renderType === 'selection'), 'with-expand': withExpand}"
    ref="cell">
    <template v-if="renderType === 'index'">{{indexStart + naturalIndex + 1}}</template>
    <template v-if="renderType === 'selection'">
      <checkbox :value="checked" @click.native.prevent.stop="toggleSelect" :disabled="disabled"></checkbox>
    </template>
    <template v-if="renderType === 'normal'">
      <span v-html="column.formatter(row[column.key])"></span>
    </template>
    <template v-if="renderType === 'imgUrl'">
      <img :src="row[column.key]"/>
    </template>
    <template v-if="renderType === 'render'">
      <cell-render
        :row="row"
        :column="column"
        :index="index"
        :render="column.render"></cell-render>
    </template>
  </div>
</template>

<script>
import CellRender from './extends/cell-render'
import Checkbox from './checkbox'
import Emitter from '../mixins/emitter'
// cell-render 中render函数渲染组件用到
import YButton from '../button/button'
import Poptip from '../poptip/poptip'
import Tooltip from '../tooltip/tooltip'
import CellImg from './extends/cell-img'

export default {
  name: 'TableCell',
  components: { CellRender, Checkbox, YButton, Poptip, Tooltip, CellImg },
  mixins: [Emitter],
  props: {
    row: Object,
    column: Object,
    naturalIndex: Number,    // index of filteredData
    index: Number,           // _index of warppedData
    checked: Boolean,        // 由 table 判断是否更新 warppedData[index]._isChecked
    disabled: Boolean,
    withExpand: Boolean,
    fixed: {
      type: [Boolean, String],
      default: false
    },
    indexStart: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      renderType: ''
    }
  },
  watch: {
    'column.render' (val, oldVal) {
      this.setRenderType()
    }
  },
  created () {
    this.setRenderType()
  },
  methods: {
    setRenderType () {
      if (this.column.type === 'index') {
        this.renderType = 'index'
      } else if (this.column.type === 'selection') {
        this.renderType = 'selection'
      } else if (this.column.type === 'imgUrl') {
        this.renderType = 'imgUrl'
      } else if (this.column.render) {
        this.renderType = 'render'
      } else {
        this.renderType = 'normal'
      }
    },
    toggleSelect () {
      this.dispatch('Table', 'on-row-select', this.index)
    }
  }
}
</script>
