<template>
  <div class="crf-list">
    <crf-item
      v-for="(item, index) in crfList"
      :crf="item"
      :selected="item.id===currentCrf.id"
      :key="index"
      @on-crf-click="onCrfClick">
    </crf-item>
    <div class="crf-toolbar">
      <span class="tool">
        <tooltip placement="top-start" content="添加新的 CRF">
          <span class="ionic-icon" :class="[plusHover?'ionic-icon-ios-plus':'ionic-icon-ios-plus-outline']"
            @mouseover="onMouseoverPlus"
            @mouseout="onMouseoutPlus"
            @click.stop="onPlusClick"></span>
        </tooltip>
      </span>
      <span class="tool">
        <tooltip placement="top" content="删除选择的 CRF">
          <span class="ionic-icon" :class="[closeHover?'ionic-icon-ios-close':'ionic-icon-ios-close-outline']"
            @mouseover="onMouseoverClose"
            @mouseout="onMouseoutClose"
            @click.stop="onDeleteClick"></span>
        </tooltip>
      </span>
      <span class="tool">
        <tooltip placement="top-end" content="更新选择的 CRF">
          <span class="ionic-icon" :class="[checkHover?'ionic-icon-ios-checkmark':'ionic-icon-ios-checkmark-outline']"
            @mouseover="onMouseoverCheck"
            @mouseout="onMouseoutCheck"
            @click.stop="onUpdateClick"></span>
        </tooltip>
      </span>
    </div>
    <crf-win
      :show="showCrfWin"
      @on-hide="showCrfWin=false"
      @on-confirm-ok="onCrfAdd"></crf-win>
  </div>
</template>

<script>
import CrfItem from './crf-item'
import CrfWin from './win/crf-win'
import Tooltip from '../common/tooltip/tooltip'
export default {
  components: {CrfItem, CrfWin, Tooltip},
  props: {
    crfList: Array,
    currentCrf: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      plusHover: false,
      closeHover: false,
      checkHover: false,
      showCrfWin: false
    }
  },
  methods: {
    onCrfClick (crf) {
      this.$emit('on-crf-click', crf)
    },
    onMouseoverPlus () {
      this.plusHover = true
    },
    onMouseoutPlus () {
      this.plusHover = false
    },
    onMouseoverClose () {
      this.closeHover = true
    },
    onMouseoutClose () {
      this.closeHover = false
    },
    onMouseoverCheck () {
      this.checkHover = true
    },
    onMouseoutCheck () {
      this.checkHover = false
    },
    onPlusClick () {
      this.showCrfWin = true
    },
    onDeleteClick () {
      this.$emit('on-crf-delete')
    },
    onUpdateClick () {
      this.$emit('on-crf-update')
    },
    onCrfAdd (newCrf) {
      this.$emit('on-crf-add', newCrf)
    }
  }
}
</script>

<style lang="less">
.crf-list {
  margin-bottom: 32px;
}
.crf-toolbar {
  position: fixed;
  bottom: 0;
  width: 20%;
  height: 32px;
  background: #f7f7f7;
  .tool {
    width: 33.33%;
    height: 32px;
    line-height: 32px;
    display: inline-block;
    float: left;
    text-align: center;
    font-size: 22px;
    position: relative;
    &:after{
      position: absolute;
      content: "";
      right: 0;
      top: 5px;
      bottom: 5px;
      width: 1px;
      background: #ddd;
    }
    &:last-child {
      &:after{
        display: none;
      }
    }
  }
  .ionic-icon {
    cursor: pointer;
  }
}
</style>
