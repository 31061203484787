import { FINISHED, VERIFIED } from '../constant'
import { clearCache } from './cache'

export var httpOptions = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': ''
  }
}

export function authenticate (options) {
  // in main.js 全局默认配置'Authorization': 'Basic YXBpOnBhc3N3b3Jk'
  const token = localStorage.getItem('token')
  if (!token) {
    return Object.assign({}, options)
  } else {
    let option = {
      headers: {
        'X-Requested-With': null,
        'Authorization': `Digest ${token}`
      }
    }
    return Object.assign({}, option, options)
  }
}

export function login (token) {
  localStorage.setItem('token', token)
}

export function logout (cb) {
  clearCache()
  if (cb) {
    cb()
  }
}

export function loggedIn () {
  return !!localStorage.getItem('token')
}

/* registed logged finished verified */
export function updateAuth (status) {
  const auth = JSON.parse(localStorage.getItem('auth')) || {}
  auth.status = status
  localStorage.setItem('auth', JSON.stringify(auth))
}

export function finishedInfo () {
  const auth = JSON.parse(localStorage.getItem('auth')) || {}
  return (auth.status === FINISHED || auth.status === VERIFIED)
}

export function verified () {
  const auth = JSON.parse(localStorage.getItem('auth')) || {}
  return (auth.status === VERIFIED)
}

export function checkHttpStatus (res) {
  var error
  if (res.status >= 200 && res.status < 300) {
    return res
  } else if (res.status === 401) {
    var data = res.json()
    if (data.nonce) {
      return res
    } else {
      error = new Error(res.status + ', ' + res.statusText)
      error.res = res
      throw error
    }
  } else {
    error = new Error(res.status + ',' + res.statusText)
    error.res = res
    throw error
  }
}

export function catchExpired (err, vm) {
  if (err.status === 401) {
    logout()
    vm && vm.$router.push({name: 'login', query: {status: 'expired'}})
  }
}
