<template>
  <div class="panel-field">
    <div class="panel-edit-title">(field) 问卷随访项编辑</div>
    <y-form ref="fieldForm" :model="field" :rules="fieldRule" label-width="100px" item-width="70%">
      <form-item label="唯一编码" prop="key">
        <y-input v-model="field.key" disabled></y-input>
      </form-item>
      <form-item label="显示名称" prop="title">
        <y-input v-model="field.title"></y-input>
      </form-item>
      <form-item label="组件分类" prop="type">
        <y-select v-model="field.type" :data="typeList"></y-select>
      </form-item>
      <form-item label="保存值类型" prop="fieldType">
        <y-select v-model="field.fieldType" :data="fieldTypeList"></y-select>
      </form-item>
      <form-item label="计分index" v-if="field.fieldType==='score'">
        <y-input v-model="field.scoreIndex"></y-input>
      </form-item>
      <form-item label="被触发item.key" v-if="field.triggeredItems && field.triggeredItems.length">
        <y-input :value="field.triggeredItems.toString()" disabled></y-input>
        <tooltip placement="top" content="点击删除后重新切换到该节点才可观察到删除">
          <span class="clear-triggered" @click.stop="clearTriggeredItems">删除</span>
        </tooltip>
      </form-item>
      <form-item label="是否必选" v-if="showRequired">
        <checklist inline v-model="field.required" :items="boolList"></checklist>
      </form-item>
      <form-item label="查询显示名称">
        <y-input v-model="field.queryTitle" placeholder="修改显示名称供查询用"></y-input>
      </form-item>
      <form-item label="是否计算分值" v-if="field.type==='input'">
        <checklist inline v-model="field.calcScore" :items="boolList"></checklist>
      </form-item>
      <form-item label="是否可复制" v-if="field.type==='input'">
        <checklist inline v-model="field.copyable" :items="boolList"></checklist>
      </form-item>
      <form-item label="是否可删除" v-if="field.type==='input'">
        <checklist inline v-model="field.removeable" :items="boolList"></checklist>
      </form-item>
      <form-item label="是否隐藏标题" v-if="field.type==='input'">
        <checklist inline v-model="field.hideTitle" :items="boolList"></checklist>
      </form-item>
      <form-item label="是否出报告">
        <checklist inline v-model="field.reportable" :items="boolList"></checklist>
      </form-item>
      <form-item label="报告子标题">
        <y-input v-model="field.reportTitle"></y-input>
      </form-item>
    </y-form>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import Checklist from '../../common/checklist/checklist'
import YInput from '../../common/input/input'
import Tooltip from '../../common/tooltip/tooltip'

export default {
  components: { YForm, FormItem, YSelect, YInput, Tooltip, Checklist },
  props: {
    data: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      field: this.data,
      typeList: [
        {
          name: '多选',
          children: [
            {name: '多选(mselect)', value: 'mselect'},
            {name: '多选输入(mselect_input)', value: 'mselect_input'}
          ]
        },
        {
          name: '单选',
          children: [
            {name: '单选_多行单选(sselect)', value: 'sselect'},
            {name: '单选_行内单选(sselect_inline)', value: 'sselect_inline'},
            {name: '单选_联动CRF表(sselect_show_quest)', value: 'sselect_show_quest'}
          ]
        },
        {
          name: '输入',
          children: [
            {name: '输入(input)', value: 'input'},
            {name: '表格输入(input_table)', value: 'input_table'}
          ]
        },
        {
          name: '显示',
          children: [
            {name: '显示(show)', value: 'show'}
          ]
        },
        {
          name: '上传',
          children: [
            {name: '文件(file)', value: 'file'}
          ]
        }
      ],
      fieldTypeList: [
        {name: '多选', value: 'array'},
        {name: '单选', value: 'object'},
        {name: '输入', value: 'input'},
        {name: '显示', value: 'show'},
        {name: '评分', value: 'score'},
        {name: '文件', value: 'file'}
      ],
      fieldRule: {
        key: [{required: true, message: '不能为空', trigger: 'change'}],
        title: [{required: true, message: '不能为空', trigger: 'blur'}],
        type: [{required: true, message: '不能为空', trigger: 'change'}],
        fieldType: [{required: true, message: '不能为空', trigger: 'change'}]
      },
      boolList: [
        {key: '1', title: '是', value: true},
        {key: '0', title: '否', value: false}
      ]
    }
  },
  computed: {
    showRequired () {
      return this.field.type && (this.field.type === 'sselect' ||
              this.field.type === 'sselect_inline' ||
              this.field.type === 'mselect' ||
              this.field.type === 'mselect_input')
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.field = val
      }
    },
    'field.type' (val) {
      this.resetFieldTypeList(val)
    }
  },
  methods: {
    clearTriggeredItems () {
      delete this.field.triggeredItems
    },
    resetFieldTypeList (type) {
      if (type && type.indexOf('mselect') >= 0) {
        this.fieldTypeList = [{name: '多选', value: 'array'}]
      } else if (type && type.indexOf('sselect') >= 0) {
        this.fieldTypeList = [{name: '单选', value: 'object'}, {name: '评分', value: 'score'}]
      } else if (type && type.indexOf('input') >= 0) {
        this.fieldTypeList = [{name: '输入', value: 'input'}]
      } else if (type && type.indexOf('show') >= 0) {
        this.fieldTypeList = [{name: '显示', value: 'show'}, {name: '评分', value: 'score'}]
      } else if (type && type.indexOf('file') >= 0) {
        this.fieldTypeList = [{name: '文件', value: 'file'}]
      }
    }
  }
}
</script>

<style lang="less">
.clear-triggered {
  height: 32px;
  line-height: 32p;
  padding: 0 5px;
  cursor: pointer;
  &:hover {
    color: #3399ff;
  }
}
</style>
