<template>
  <div class="crf">
    <div class="crf-lists" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <crf-list
        :crfList="crfList"
        :currentCrf="currentCrf"
        @on-crf-click="onCrfClick"
        @on-crf-add="onCrfAdd"
        @on-crf-delete="onCrfDelete"
        @on-crf-update="onCrfUpdate"></crf-list>
    </div>
    <div class="crf-table-lists" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <crf-table-list
        :projectId="projectId"
        :crfTableList="crfTableList"
        :currentCrf="currentCrf"
        @on-crftable-click="onCrfTableClick"></crf-table-list>
    </div>
    <div class="crf-panels" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <panel-crf
        v-if="currentPanelView==='crf'"
        :currentCrf="currentCrf"></panel-crf>
      <panel-crf-table
        v-if="currentPanelView==='crfTable'"
        :data="currentCrfTable"
        @on-crftable-update="onCrfTableUpdate"></panel-crf-table>
    </div>
    <div class="crftable-panels" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <quest-item-list
        :title="currentCrfTable.title"
        :role="currentCrfTable.role"
        :hasScore="currentCrfTable.hasScore"
        :modelId="currentCrfTable.modelId"
        :questId="projectId"
        :questItemList="fieldList"></quest-item-list>
    </div>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>

<script>
import CrfList from './crf-list'
import CrfTableList from './crftables/crf-table-list'
import PanelCrf from './panel/panel-crf'
import PanelCrfTable from './panel/panel-crf-table'
import QuestItemList from '../QuestItemList'
import Loading from '../common/loading/loading'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  name: 'CRF',
  components: {CrfList, CrfTableList, PanelCrf, PanelCrfTable, QuestItemList, Loading},
  data () {
    return {
      crfList: [],
      currentCrf: {},
      crfTableList: [],
      currentCrfTable: {},
      currentPanelView: '',
      showLoading: false,

      fieldList: []
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    },
    pageHeight () {
      return this.$store.getters.getWinHeight - 60
    }
  },
  watch: {
    projectId (val) {
      if (val) {
        this.loadCrfList()
        this.loadCrfTableList()
      }
    },
    crfList (val) {
      if (Array.isArray(val) && val.length) {
        this.currentCrf = val[0]
        this.currentPanelView = 'crf'
      } else {
        this.crfTableList = []
        this.currentCrf = {}
        this.currentCrfTable = {}
      }
    },
    currentCrf (val) {
      if (val && val.items && val.items.length) {
        this.currentCrfTable = val.items[0]
      }
    }
  },
  mounted () {
    this.loadCrfList()
    this.loadCrfTableList()
  },
  methods: {
    loadCrfTableList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/crf/crfTable/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && Array.isArray(res.crfTableList)) {
          this.showLoading = false
          this.crfTableList = res.crfTableList
          this.$nextTick(() => {
            // this.updateFieldsData()
          })
        } else if (res.status === 'success') {
          this.showLoading = false
          this.crfTableList = []
          this.$nextTick(() => {
            // this.updateFieldsData()
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadCrfList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/crf/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && Array.isArray(res.crfList)) {
          this.showLoading = false
          this.crfList = res.crfList
          this.$nextTick(() => {
            // this.updateFieldsData()
          })
        } else if (res.status === 'success') {
          this.showLoading = false
          this.crfList = []
          this.$nextTick(() => {
            // this.updateFieldsData()
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    crfAdd (data, callback) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/crf/add`, {
        projectId: this.projectId,
        crf: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && typeof callback === 'function') {
          this.showLoading = false
          this.$Message.success('添加CRF成功')
          callback()
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    crfUpdate (data) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/crf/update`, {
        projectId: this.projectId,
        crf: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.$Message.success('更新CRF成功')
          this.loadFieldList()
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
        setTimeout(() => {
          this.$Modal.error({
            title: '更新CRF失败',
            content: `<p>${err.status} ${err.statusText} ${err.url}</p>`
          })
        }, 500)
      })
    },
    crfDelete (crfId, callback) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/crf/delete`, {id: crfId}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && typeof callback === 'function') {
          this.showLoading = false
          this.$Message.success('删除CRF成功')
          callback()
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
        setTimeout(() => {
          this.$Modal.error({
            title: '删除CRF失败',
            content: `<p>${err.status} ${err.statusText} ${err.url}</p>`
          })
        }, 500)
      })
    },
    onCrfAdd (newCrf) {
      this.crfAdd(newCrf, () => {
        this.loadCrfList()
      })
    },
    onCrfDelete () {
      if (!this.currentCrf) {
        this.$Message.warning('请选择 CRF')
      } else {
        this.$Modal.confirm({
          title: `删除 CRF`,
          content: `<p>确认删除 ${this.currentCrf.title} ？</p>`,
          onOk: () => {
            this.crfDelete(this.currentCrf.id, () => {
              const index = this.crfList.indexOf(this.currentCrf)
              if (index > -1) {
                this.crfList.splice(index, 1)
              }
            })
          }
        })
      }
    },
    onCrfUpdate () {
      if (!this.currentCrf) {
        this.$Message.warning('请选择 CRF')
      } else {
        this.crfUpdate(this.currentCrf)
      }
    },
    onCrfClick (crf) {
      this.currentCrf = crf
      this.currentPanelView = 'crf'
    },
    onCrfTableClick (crfTable) {
      this.currentCrfTable = crfTable
      this.currentPanelView = 'crfTable'
      this.loadFieldList()
    },
    onCrfTableUpdate (newCrfTable) {
      let idx = -1
      this.currentCrf.items.forEach((item, index) => {
        if (item.modelId === newCrfTable.modelId) {
          idx = index
        }
      })
      if (idx > -1) {
        this.$set(this.currentCrf.items, idx, newCrfTable)
        // this.$Message.success('本地更新 CRF 成功')
        this.onCrfUpdate()
      } else {
        this.$Message.warning('CRF表 与 CRF 不匹配, 重新选择 CRF 编辑后更新')
      }
    },
    loadFieldList () {
      // 加载fieldList时，需要将crf.items中item(crftable)的itemkeys,fieldkeys映射到每个节点上
      if (!this.currentCrfTable || !this.currentCrfTable.modelId) return
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/crf/crfTable/field/list`, authenticate({
        params: {id: this.currentCrfTable.modelId, crfId: this.currentCrf.id}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && Array.isArray(res.fieldList)) {
          this.showLoading = false
          this.fieldList = res.fieldList
        } else if (res.status === 'success') {
          this.showLoading = false
          this.fieldList = []
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    }
  }
}
</script>

<style lang="less">
.crf-lists {
  width: 20%;
  border-right: 1px solid #dfdfdf;
  float: left;
}
.crf-table-lists {
  width: 20%;
  border-right: 1px solid #dfdfdf;
  float: left;
}
.crf-panels {
  width: 30%;
  border-right: 1px solid #dfdfdf;
  float: left;
}
.crftable-panels * {
  box-sizing: content-box;
}
</style>
