import Index from '../components/Index'
import Login from '../components/login/login'

const About = () => import('../components/About')
const NotFound = () => import('../components/NotFound')
const Home = () => import('../components/home/home')
const Crftable = () => import('../components/crftable/crf')
const CrftableShared = () => import('../components/crftable/crfShared')
const Crf = () => import('../components/crf/crf')
const User = () => import('../components/user/user')
const Subject = () => import('../components/subject/subject')
const Api = () => import('../components/api/api')
const Dict = () => import('../components/dict/dict')
const Menu = () => import('../components/menu/menu')
const MySamples = () => import('../components/container/mySamples')
const MyContainers = () => import('../components/container/myContainers')
const License = () => import('../components/license/license')
const SystemUpdate = () => import('../components/database/system-update')
const CrfTableBrief = () => import('../components/crftablebrief/crftablebrief')
const SampleType = () => import('../components/sampletype/sampleType')
const SampleTypeGlobal = () => import('../components/sampletype/sampleTypeCustomGlobal')
const Datasource = () => import('../components/datasource/datasource')
const Dataextract = () => import('../components/dataextract/dataextract')
const Mongo = () => import('../components/mongo/mongo.vue')
const RDS = () => import('../components/rds/rds.vue')
const DataextractRDS = () => import('../components/dataextract-rds/dataextract-rds')

export default [
  {
    path: '/',
    name: 'index',
    component: Index,
    children: [{
      path: '',
      redirect: '/login'
    }, {
      path: '/home',
      name: 'home',
      component: Home
    }, {
      path: '/crftable',
      name: 'crftable',
      component: Crftable
    }, {
      path: '/crf',
      name: 'crf',
      component: Crf
    }, {
      path: '/crftableshared',
      name: 'crftableshared',
      component: CrftableShared
    }, {
      path: '/user',
      name: 'user',
      component: User
    }, {
      path: '/subject',
      name: 'subject',
      component: Subject
    }, {
      path: '/api',
      name: 'api',
      component: Api
    }, {
      path: '/dict',
      name: 'dict',
      component: Dict
    }, {
      path: '/menu',
      name: 'menu',
      component: Menu
    }, {
      path: '/myContainers',
      name: 'myContainers',
      component: MyContainers
    }, {
      path: '/mySamples',
      name: 'mySamples',
      component: MySamples
    }, {
      path: '/license',
      name: 'license',
      component: License
    }, {
      path: '/sysupdate',
      name: 'sysupdate',
      component: SystemUpdate
    }, {
      path: '/crftablebrief',
      name: 'crftablebrief',
      component: CrfTableBrief
    }, {
      path: '/sampletype',
      name: 'sampletype',
      component: SampleType
    }, {
      path: '/sampleTypeGlobal',
      name: 'sampleTypeGlobal',
      component: SampleTypeGlobal
    }, {
      path: '/datasource',
      name: 'datasource',
      component: Datasource
    }, {
      path: '/dataextract',
      name: 'dataextract',
      component: Dataextract
    }, {
      path: '/mongo',
      name: 'mongo',
      component: Mongo
    }, {
      path: '/rds',
      name: 'rds',
      component: RDS
    }, {
      path: '/dataextractrds',
      name: 'dataextractrds',
      component: DataextractRDS
    }]
  }, {
    path: '/login',
    name: 'login',
    component: Login
  }, {
    path: '/about',
    name: 'about',
    component: About
  }, {
    path: '*',
    component: NotFound
  }
]
