<template>
  <div class="freezer-panel" :class="{transable: currentPath.indexOf('sampleTransSelect')>=0}">
    <div class="freezer-panel__bd">
      <div class="freezer-level" v-for="level in maxLevel" :key="level">
        <div class="shelf" v-for="item in getLevelItems(level, section)" @click.stop="onClick(item)" :key="item.id">
          <div class="shelf-box" :class="{enable: item.enable}">
            <delete-mask :show="showDeleteMask" :container="item" @on-delete="onShowDeleteWin" @on-auth="onShowAuthWin" @on-edit="onShowEditWin"></delete-mask>
            <div class="shelf-img-box">
              <div class="shelf-img-layer" v-for="i in item.layerCount" :key="i"></div>
            </div>
          </div>
          <div class="shelf-desc" :style="{minWidth: getMinDescWidth()+'px'}">
            <p class="shelf-label">{{item.label}}</p>
            <p class="shelf-label-2">位置编码：</p>
            <p>{{item.siteCode}}</p>
            <p class="shelf-label-2">样本存量：</p>
            <p style="width: 50px;">
              <y-progress :percent="getPercent(item.proportion)" :show-value="item.proportion" :strokeWidth="5" :strokeColor="getMaskBg(item)"></y-progress>
            </p>
          </div>
        </div>
      </div>
      <div class="shelf-add" @click.stop="onShowModal" v-if="currentPath.indexOf('mySamples') >= 0">
        <div class="shelf-add-plus">
          +
        </div>
      </div>
    </div>
    <edit-menu v-if="currentPath.indexOf('mySamples') >= 0"
      :active="shelfEditActive" @on-edit-click="onContainerEdit('shelf')"></edit-menu>
    <shelf-add v-if="currentPath.indexOf('mySamples') >= 0"
      v-model="newShelf"
      :show="showModal"
      :freezerType="freezerType"
      @on-hide="showModal=false"
      @on-add-ok="onShelfAdd"></shelf-add>
    <delete-win
      v-model="deletePermanent"
      :show="showDeleteWin"
      @on-hide="showDeleteWin=false"
      @on-delete-ok="onShelfDelete"></delete-win>
    <auth-win
      v-model="currentGroupCodeList"
      :show="showAuthWin"
      @on-hide="showAuthWin=false"
      @on-confirm-ok="onContainerAuth"></auth-win>
    <edit-win
      containerType="shelf"
      v-model="editContainer"
      :show="showEditWin"
      @on-hide="showEditWin=false"
      @on-confirm-ok="onContainerUpdate"></edit-win>
  </div>
</template>

<script>
import YProgress from '../../progress/progress'
import ShelfAdd from '../add/shelfAdd'
import DeleteMask from '../delete/deleteMask'
import DeleteWin from '../delete/deleteWin'
import AuthWin from '../auth/auth-win'
import EditWin from '../edit/edit-win'
import EditMenu from '../delete/edit-menu'
import editMixin from '../mixins/editMixin'
import { authenticate, catchExpired } from '../../../../utils/auth'
export default {
  name: 'FreezerPanel',
  components: { YProgress, ShelfAdd, DeleteMask, DeleteWin, AuthWin, EditWin, EditMenu },
  mixins: [editMixin],
  props: {
    parentId: String,
    rootId: String,
    parentCode: String,
    freezerType: String,
    section: Number
  },
  data () {
    return {
      containerType: 'shelf',
      shelfList: [],
      newShelf: {
        level: 1,
        section: 1,
        shelfNum: 1,
        label: '',
        desc: '',
        layerCount: 0,
        sampleboxCount: 0,
        sampleboxRow: 1,
        sampleboxCol: 1,
        exboxType: '',
        strawboxCount: 0
      }
    }
  },
  computed: {
    currentPath () {
      return this.$store.getters.getCurrentPath
    },
    maxLevel () {
      let maxLevel = 0
      const list = this.shelfList || []
      list.forEach((item) => {
        if (item.level > maxLevel) maxLevel = item.level
      })
      return maxLevel
    }
  },
  watch: {
    parentId (val) {
      this.loadShelfList()
    }
  },
  mounted () {
    this.loadShelfList()
  },
  methods: {
    getLevelItems (level, section) {
      let items = []
      const list = this.shelfList || []
      list.forEach((item) => {
        if (section) {
          if (item.section === section && item.level === level) items.push(item)
        } else {
          if (item.level === level) items.push(item)
        }
      })
      return items
    },
    getMinDescWidth () {
      let maxNum = 1
      let levelNums = {}
      const list = this.shelfList || []
      list.forEach((item) => {
        levelNums['' + item.level] = levelNums['' + item.level] || 0
        levelNums['' + item.level]++
      })
      Object.keys(levelNums || {}).forEach(level => {
        if (levelNums[level] > maxNum) maxNum = levelNums[level]
      })
      if (maxNum >= 5) {
        return '65'
      } else {
        return '100'
      }
    },
    loadShelfList () {
      this.$parent.showLoading = true
      this.$http.get(`${this.httpRoot}/container/shelf/list`,
        authenticate({params: {parentId: this.parentId, projectId: this.$store.state.project.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.shelfList = res.shelfList || []
          this.$parent.showLoading = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onClick (item) {
      // 选择左边对应的 container, 更新右上 containerPath 切换右边 panel
      if (item.enable) {
        this.$parent.selectContainerById(item.id)
        this.$parent.updateContainerPathById(item.id)
        this.$parent.updatePanel('shelfPanel', {
          parentId: item.id,
          rootId: item.rootId,
          parentCode: item.siteCode
        })
      }
    },
    onShelfAdd () {
      this.$http.post(`${this.httpRoot}/container/shelf/add`,
        Object.assign({}, {
          parentId: this.parentId,
          rootId: this.rootId,
          parentCode: this.parentCode,
          freezerType: this.freezerType
        }, this.newShelf), authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('创建架子成功')
          this.loadShelfList()
        } else {
          this.$Modal.error({
            title: '创建架子失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('建架子失败')
      })
    },
    onShelfDelete () {
      let path = this.deletePermanent ? '/permanent' : ''
      this.$http.post(`${this.httpRoot}/container/shelf/delete${path}`, {
        id: this.deleteContainer.id,
        rootId: this.deleteContainer.rootId,
        siteCode: this.deleteContainer.siteCode
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('删除架子成功')
          this.loadShelfList()
        } else {
          this.$Modal.error({
            title: '删除架子失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('删除架子失败')
      })
    },
    getPercent (proportion) {
      if (proportion < 50) {
        proportion = proportion * (60 - proportion) / 10
      }
      return parseInt(proportion || 0)
    },
    getMaskBg (item) {
      return (item.proportion < 25 && item.proportion >= 0
        ? '#6AE065'
        : item.proportion < 50 && item.proportion >= 25
          ? '#FCB946'
          : item.proportion < 75 && item.proportion >= 50
            ? '#70A3C7'
            : item.proportion < 100 && item.proportion >= 75
              ? '#F87E7F'
              : '#f00'
      )
    }
  }
}
</script>

<style lang="less">
.freezer-panel {
  padding: 0 10px;
  // position: relative;
  &.transable {
    max-width: 400px;
    overflow-x: auto;
  }
}
.freezer-panel__bd {
  display: flex;
  flex-direction: column;
  .freezer-level {
    margin: 8px 0;
    display: flex;
    flex-direction: row;
  }
  .shelf {
    margin-right: 10px;
    font-size: 12px;
  }
  .shelf-box {
    float: left;
    margin: 0 10px 0 0;
    transform-style: preserve-3d;
    // transform: perspective(200px) rotateY(-20deg);
    position: relative;
    .shelf-img-layer {
      width: 80px;
      height: 30px;
      margin-bottom: 2px;
      background: url(../../../../assets/imgs/container/subpage-bigshelf-grey.png) no-repeat;
      background-size: 100% 100%;
    }
    &.enable {
      cursor: pointer;
      .shelf-img-layer {
        background: url(../../../../assets/imgs/container/subpage-bigshelf-blue.png) no-repeat;
        background-size: 100% 100%;
      }
      &:hover {
        .shelf-img-layer {
          background: url(../../../../assets/imgs/container/subpage-bigshelf-mousehover.png) no-repeat;
          background-size: 100% 100%;
          transition: all 0.3s;
        }
      }
    }
  }
  .shelf-desc {
    float: left;
    cursor: default;
  }
  .shelf-label {
    max-width: 100px;
  }
  .shelf-label-2 {
    margin-top: 5px;
  }
}
.shelf-add {
  width: 80px;
  height: 80px;
  transition: all .3s;
  &:hover {
    cursor: pointer;
    .shelf-add-plus {
      display: block;
    }
  }
}
.shelf-add-plus {
  border: 2px dotted #ccc;
  font-size: 32px;
  height: 80px;
  line-height: 76px;
  color: #bdbdbd;
  text-align: center;
  display: none;
  transition: all .3s;
}
</style>
