export default {
  methods: {
    editCrfTable (_idx) {
      this.currentDataIdx = _idx
      this.updateProjectId()
      this.$router.push({name: 'crftable'})
    },
    editCrf (_idx) {
      this.currentDataIdx = _idx
      this.updateProjectId()
      this.$router.push({name: 'crf'})
    },
    editUser (_idx) {
      this.currentDataIdx = _idx
      this.updateProjectId()
      this.$router.push({name: 'user'})
    },
    editSubject (_idx) {
      this.currentDataIdx = _idx
      this.updateProjectId()
      this.$router.push({name: 'subject'})
    },
    editAPI (_idx) {
      this.currentDataIdx = _idx
      this.updateProjectId()
      this.$router.push({name: 'api'})
    },
    editDict (_idx) {
      this.currentDataIdx = _idx
      this.updateProjectId()
      this.$router.push({name: 'dict'})
    },
    editMenu (_idx) {
      this.currentDataIdx = _idx
      this.updateProjectId()
      this.$router.push({name: 'menu'})
    }
  }
}
