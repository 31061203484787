export default {
  filters: {
    formatDate: function (time) {
      let t = time || new Date()
      return new Date(t).format('yyyy-MM-dd')
    },
    formatTime: function (time) {
      let t = time || new Date()
      return new Date(t).format('yyyy-MM-dd hh:mm:ss')
    }
  }
}
