<template>
  <transition :name="transitionName">
    <div :style="styles" :class="[`${prefixCls}-notice`,{[`${className}`]: !!this.className,
      [`${prefixCls}-notice-closable`]: this.closable,
      [`${prefixCls}-notice-with-desc`]: this.withDesc}]">
      <div :class="[`${prefixCls}-notice-content`]" ref="content" v-html="content"></div>
      <a :class="[`${prefixCls}-notice-close`]" @click="close" v-if="closable">
        <i class="ionic-icon ionic-icon-ios-close-empty"></i>
      </a>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    prefixCls: {
      type: String,
      default: 'notice'
    },
    duration: {
      type: Number,
      default: 1.5
    },
    content: {
      type: String,
      default: ''
    },
    styles: {
      type: Object,
      default: function () {
        return {
          right: '50%'
        }
      }
    },
    closable: {
      type: Boolean,
      default: false
    },
    className: String,
    name: {
      type: String,
      required: true
    },
    onClose: Function,
    transitionName: String
  },
  data () {
    return {
      withDesc: false
    }
  },
  mounted () {
    this.clearCloseTimer()

    if (this.duration !== 0) {
      this.closeTimer = setTimeout(() => {
        this.close()
      }, this.duration * 1000)
    }

    // check if with desc in Notice component
    if (this.prefixCls === 'notice') {
      this.withDesc = this.$refs.content.querySelectorAll(`.${this.prefixCls}-desc`)[0].innerHTML !== ''
    }
  },
  methods: {
    clearCloseTimer () {
      if (this.closeTimer) {
        clearTimeout(this.closeTimer)
        this.closeTimer = null
      }
    },
    close () {
      this.clearCloseTimer()
      this.onClose()
      this.$parent.close(this.name)
    }
  },
  beforeDestroy () {
    this.clearCloseTimer()
  }
}
</script>
