<template>
  <div class="crf">
    <div class="crf-table-list" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <crf-table
        :projectId="projectId"
        :crfTableList="crfTableList"
        :currentCrfTabel="currentCrfTabel"
        @on-crftable-click="onCrfTableClick"
        @on-crftable-share="onCrfTableShare"
        @on-crftable-delete="onCrfTableDelete"
        @on-crftable-fields-update="onCrfTableFieldsUpdate"
        @on-crftable-add="onCrfTableAdd"
        @on-query-change="onQueryChange"
        @on-upload-success="onUploadSuccess">
      </crf-table>
    </div>
    <div class="crf-field-tree" :style="{overflow: 'hidden', height: pageHeight + 'px'}">
      <field-tree
        ref="fieldTree"
        :title="currentTitle"
        :data="fieldList"
        :modelId="currentModelId"
        @on-tree-save="saveFieldList"
        @on-tree-replicate="replicateFieldList"
        @on-tree-node-click="onTreeNodeClick"></field-tree>
    </div>
    <div class="crf-field-panel" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <panel-crf-table
        v-if="currentPanelView==='crfTable'"
        :data="currentPanelData"></panel-crf-table>
      <panel-root
        v-if="currentPanelView==='root'"
        :data="currentPanelData"></panel-root>
      <panel-field
        v-if="currentPanelView==='field'"
        :data="currentPanelData"></panel-field>
      <panel-item
        v-if="currentPanelView==='item' || currentPanelView==='gitem'"
        :data="currentPanelData"></panel-item>
      <panel-group
        v-if="currentPanelView==='group'"
        :data="currentPanelData"></panel-group>
      <panel-child
        v-if="currentPanelView==='child'"
        :data="currentPanelData"></panel-child>
      <panel-sub-child
        v-if="currentPanelView==='subChild'"
        :data="currentPanelData"></panel-sub-child>
    </div>
    <div class="crf-quest-list" :style="{overflow: 'hidden', height: pageHeight + 'px'}">
      <quest-item-list
        :title="currentTitle"
        :role="currentCrfTabel.role"
        :hasScore="currentCrfTabel.hasScore"
        :questId="projectId"
        :modelId="currentModelId"
        :questItemList="fieldsData"></quest-item-list>
    </div>
    <fields-win
      :show="showAddFieldTrigger"
      @on-hide="showAddFieldTrigger=false"
      :triggerKey="triggerKey"
      :fields="currentFieldsData"></fields-win>
    <crf-tables-win
      :show="showAddModelId"
      @on-hide="showAddModelId=false"
      @on-set-modelId-click="onSetModelIdClick"
      :tables="crfTables"></crf-tables-win>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>

<script>
import FieldTree from './field/field-tree'
import CrfTable from './crfTable'
import QuestItemList from '../QuestItemList'
import PanelCrfTable from './panel/panel-crfTable'
import PanelRoot from './panel/panel-root'
import PanelField from './panel/panel-field'
import PanelItem from './panel/panel-item'
import PanelGroup from './panel/panel-group'
import PanelChild from './panel/panel-child'
import PanelSubChild from './panel/panel-subChild'
import FieldsWin from './win/fields-win'
import CrfTablesWin from './win/crfTables-win'
import Loading from '../common/loading/loading'
import Emitter from '../common/mixins/emitter'
import { updateStorageItem } from '../../utils/cache'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  name: 'CRF',
  components: {CrfTable, FieldTree, QuestItemList, PanelCrfTable, PanelRoot, PanelField, PanelItem, PanelGroup, PanelChild, PanelSubChild, FieldsWin, CrfTablesWin, Loading},
  mixins: [Emitter],
  data () {
    return {
      crfTableList: [],
      fieldList: [],
      fieldsData: [],
      currentCrfTabel: {},
      currentTitle: '',
      currentModelId: '',

      currentNode: null,
      currentPanelView: '',
      currentPanelData: {},

      currentFieldsData: [],
      showAddFieldTrigger: false,
      showAddModelId: false,

      triggerKey: '',
      showLoading: false
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId // in manage questId is projectId, quest-item-list 中无效
    },
    pageHeight () {
      return this.$store.getters.getWinHeight - 60
    },
    crfTables () {
      let tables = []
      this.crfTableList.forEach(table => {
        tables.push({key: table.modelId, title: table.title})
      })
      return tables
    }
  },
  watch: {
    projectId (val) {
      if (val) {
        this.loadCrfTableList()
      }
    },
    crfTableList (val) {
      if (Array.isArray(val) && val.length) {
        this.currentCrfTabel = val[0]
      } else {
        this.currentCrfTabel = {}
        this.fieldList = []
        this.currentPanelData = {}
        this.fieldsData = []
      }
    },
    currentCrfTabel (val) {
      if (val && val.modelId) {
        this.currentTitle = val.title
        this.currentModelId = val.modelId
        this.updateEditPanel('crfTable', val)
      }
    },
    currentModelId (val) {
      if (val) {
        this.loadFieldList(val)
      }
    }
  },
  created () {
    this.$on('on-crftable-update', crfTable => {
      this.updateCrfTable(crfTable, () => {
        this.crfTableList.forEach((item, index) => {
          if (item.modelId === crfTable.modelId) {
            this.$set(this.crfTableList, index, crfTable)
          }
        })
      })
    })
    this.$on('on-trigger', key => {
      this.currentFieldsData = JSON.parse(JSON.stringify(this.$refs.fieldTree.getFieldsData()))
      this.triggerKey = key
      this.showAddFieldTrigger = true
    })
    this.$on('on-set-modelId-show', () => {
      this.showAddModelId = true
    })
  },
  mounted () {
    this.loadCrfTableList()
    // this.loadFieldList(this.currentModelId)
  },
  methods: {
    updateEditPanel (view, data) {
      this.currentPanelView = view
      this.currentPanelData = data
    },
    loadFieldList (currentModelId) {
      if (!currentModelId) return
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/crf/crfTable/field/list/all`, authenticate({
        params: {id: currentModelId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && Array.isArray(res.fieldList)) {
          this.showLoading = false
          this.fieldList = res.fieldList
          this.$nextTick(() => {
            this.updateFieldsData()
          })
        } else if (res.status === 'success') {
          this.showLoading = false
          this.fieldList = []
          this.$nextTick(() => {
            this.updateFieldsData()
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadCrfTableList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/crf/crfTable/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && Array.isArray(res.crfTableList)) {
          this.showLoading = false
          this.crfTableList = res.crfTableList || []
          this.$nextTick(() => {
            this.updateCrfTableListCache()
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    updateCrfTableListCache () {
      updateStorageItem('followupItems_' + this.projectId, this.crfTableList)
    },
    onQueryChange (query) {
      this.$http.get(`${this.httpRoot}/crf/crfTable/query`, authenticate({
        params: {query, projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.crfTableList = res.crfTableList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    updateCrfTableFields () {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/crf/crfTable/fields/update`, {
        modelId: this.currentModelId,
        collection: this.currentCrfTabel.collection,
        fieldList: this.$refs.fieldTree.getFieldsData()
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.$Message.success('更新CRF表模型树成功')
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
        setTimeout(() => {
          this.$Modal.error({
            title: '更新CRF表模型树失败',
            content: `<p>${err.status} ${err.statusText} ${err.url}</p>`
          })
        }, 500)
      })
    },
    updateCrfTable (data, callback) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/crf/crfTable/update`, {crfTable: data, projectId: this.projectId}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && typeof callback === 'function') {
          this.showLoading = false
          this.$Message.success('更新CRF表成功')
          callback()
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
        setTimeout(() => {
          this.$Modal.error({
            title: '更新CRF表失败',
            content: `<p>${err.status} ${err.statusText} ${err.url}</p>`
          })
        }, 500)
      })
    },
    deleteCrfTable (id, callback) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/crf/crfTable/delete`, {id: id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && typeof callback === 'function') {
          this.showLoading = false
          this.$Message.success('删除CRF表成功')
          callback()
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
        setTimeout(() => {
          this.$Modal.error({
            title: '删除CRF表失败',
            content: `<p>${err.status} ${err.statusText} ${err.url}</p>`
          })
        }, 500)
      })
    },
    addCrfTable (data, callback) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/crf/crfTable/add`, {
        projectId: this.projectId,
        crfTable: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && typeof callback === 'function') {
          this.showLoading = false
          this.$Message.success('添加CRF表成功')
          callback()
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
        setTimeout(() => {
          this.$Modal.error({
            title: '添加CRF表失败',
            content: `<p>${err.status} ${err.statusText} ${err.url}</p>`
          })
        }, 500)
      })
    },
    updateCrfTableShare (id, shared, callback) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/crf/crfTable/share`, {
        projectId: this.projectId,
        id: id,
        shared: shared
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && typeof callback === 'function') {
          this.showLoading = false
          this.$Message.success('共享CRF表成功')
          callback()
        } else {
          this.showLoading = false
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
        this.$Message.error('共享CRF表失败')
      })
    },
    onCrfTableFieldsUpdate () {
      if (!this.currentCrfTabel.title) {
        this.$Message.warning('请选择 CRF 表')
      } else {
        this.updateCrfTableFields()
      }
    },
    onCrfTableDelete () {
      if (!this.currentCrfTabel.title) {
        this.$Message.warning('请选择 CRF 表')
      } else {
        this.$Modal.confirm({
          title: `删除 CRF 表`,
          content: `<p>确认删除 ${this.currentCrfTabel.title} ？</p>`,
          onOk: () => {
            this.deleteCrfTable(this.currentCrfTabel.id, () => {
              const index = this.crfTableList.indexOf(this.currentCrfTabel)
              if (index > -1) {
                this.crfTableList.splice(index, 1)
              }
            })
          }
        })
      }
    },
    onCrfTableAdd (crftable) {
      this.addCrfTable(crftable, () => {
        this.crfTableList.push(crftable)
      })
    },
    onCrfTableClick (crftable) {
      this.currentCrfTabel = crftable
    },
    onCrfTableShare (crftable) {
      this.updateCrfTableShare(crftable.id, !crftable.shared, () => {
        this.$set(crftable, 'shared', !crftable.shared)
      })
    },
    onTreeNodeClick (data, type) {
      this.updateEditPanel(type, data)
    },
    onSetModelIdClick (modelId, modelIdTitle) {
      this.broadcast('PanelItem', 'on-set-modelId', modelId, modelIdTitle)
    },
    onUploadSuccess () {
      this.loadCrfTableList()
    },
    updateFieldsData () {
      this.fieldsData = this.$refs.fieldTree.getFieldsData()
    },
    saveFieldList (label) {
      this.$http.post(`${this.httpRoot}/crf/crfTable/export`, {
        modelId: this.currentCrfTabel.modelId
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'CRF表.' + label + new Date().format('yyyyMMddhhmmss') + '.json'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    replicateFieldList (label) {
      let data = this.$refs.fieldTree.replicateFields()
      this.$http.post(`${this.httpRoot}/crf/crfTable/replicate`, {
        modelId: this.currentCrfTabel.modelId,
        newModelId: data.modelId,
        fieldList: data.fieldList
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'new_CRF表.' + label + new Date().format('yyyyMMddhhmmss') + '.json'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="less">
.crf {
  height: 100%;
  overflow: hidden;
}
.crf-table-list {
  width: 15%;
  float: left;
  padding: 1px 0;
}
.crf-field-tree {
  width: 25%;
  float: left;
}
.crf-field-panel {
  width: 30%;
  float: left;
}
.panel-edit-title {
  font-size: 14px;
  height: 38px;
  line-height: 38px;
  padding-left: 100px;
}
.crf-quest-list * {
  box-sizing: content-box;
}
</style>
