export default {
  data () {
    return {
      columns2: [
        {
          type: 'index',
          title: '序号',
          width: 65,
          align: 'center'
        },
        {
          title: '数据表名称',
          key: 'name',
          width: 150
        },
        {
          title: '数据源名称',
          key: 'datasourceName',
          width: 120
        },
        {
          title: '参数字段',
          key: 'params',
          formatter: (val) => { return val ? this.mapParams(val) : '' },
          width: 200
        },
        {
          title: '列表字段',
          key: 'fields',
          formatter: (val) => { return val ? this.mapFields(val) : '' },
          width: 570
        },
        {
          title: '操作',
          key: 'action',
          width: 240,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                nativeOn: {
                  click: () => { this.preview(params.row._idx) }
                }
              }, 'JSON预览'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                nativeOn: {
                  click: () => { this.lookup(params.row._idx) }
                }
              }, 'Table预览'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                nativeOn: {
                  click: () => { this.edit2(params.row._idx) }
                }
              }, '编辑'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                nativeOn: {
                  'click': () => { this.delete2(params.row._idx) }
                }
              }, '删除')
            ])
          }
        },
        {
          key: 'items',
          hide: true,
          render: (h, params) => {
            return h('div', [
              h('DataList', {
                props: {
                  columns: params.row.fields,
                  items: params.row.items
                }
              })
            ])
          }
        }
      ]
    }
  }
}
