<template>
  <modal v-model="showModal" :title="title" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="projectForm" :model="project" :rules="projectRule" label-width="100px" item-width="250px">
      <form-item label="名称" prop="name">
        <y-input v-model="project.name"></y-input>
      </form-item>
      <form-item label="类型" prop="type">
        <y-select v-model="project.type" :data="projectTypeList"></y-select>
      </form-item>
      <form-item label="英文简称" prop="nameEn">
        <y-input v-model="project.nameEn"></y-input>
      </form-item>
      <form-item label="编码" prop="code">
        <y-input v-model="project.code"></y-input>
      </form-item>
      <form-item label="是否新项目">
        <checklist inline v-model="project.isNew" :items="boolList"></checklist>
      </form-item>
      <form-item label="root用户" prop="root">
        <y-input v-model="project.root"></y-input>
      </form-item>
      <form-item label="菜单授权">
        <checklist inline v-model="project.menuAuth" :items="boolList"></checklist>
      </form-item>
      <form-item label="预期收集样本数">
        <input-number v-model="project.expectSampleTotal"></input-number>
      </form-item>
      <form-item label="logoUrl" prop="logoUrl">
        <y-input v-model="project.logoUrl"></y-input>
      </form-item>
      <form-item label="描述">
        <y-input type="textarea" v-model="project.desc"></y-input>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YInput from '../../common/input/input'
import YSelect from '../../common/select/select'
import InputNumber from '../../common/input/input-number'
import Checklist from '../../common/checklist/checklist'

export default {
  components: { Modal, YForm, FormItem, YInput, YSelect, InputNumber, Checklist },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: String,
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      project: {
        name: '',
        nameEn: '',
        type: '',
        code: '',
        isNew: true,
        root: '',
        menuAuth: false,
        logoUrl: ''
      },
      projectTypeList: ['样本随访管理系统', '样本管理系统', '随访管理系统'],
      projectRule: {
        name: [{required: true, message: '不能为空', trigger: 'blur'}],
        nameEn: [{required: true, message: '不能为空', trigger: 'blur'}],
        type: [{required: true, message: '不能为空', trigger: 'change'}],
        code: [{required: true, message: '不能为空', trigger: 'blur'}],
        root: [{required: true, message: '不能为空', trigger: 'blur'}],
        logoUrl: [{required: true, message: '不能为空', trigger: 'blur'}]
      },
      boolList: [
        { key: '1', value: true, title: '是' },
        { key: '0', value: false, title: '否' }
      ]
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    value (val) {
      if (val) {
        this.project = val
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.projectForm.validate()
      if (!this.$refs.projectForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', Object.assign({}, this.project))
    }
  }
}
</script>

<style lang="css">
</style>
