export default {
  name: 'CellImg',
  functional: true,
  // 为了弥补缺少的实例
  // 提供第二个参数作为上下文
  render: function (h, ctx) {
    if (ctx.props.sampleType) {
      return h('img', {
        attrs: {
          src: ctx.props.iconUrl
        }
      })
    } else {
      return h('span')
    }
  },
  // Props 可选
  props: {
    sampleType: String,
    iconUrl: String
  }
}
