<template>
  <div class="root-container" :style="{width: width}">
    <collapse-panel :title="title" height="100" v-model="containers.expanded"
      v-show="containers.items && containers.items.length">
      <div class="root-container__box">
        <div class="root-container__item"
          :class="{enable: item.enable, selected: item.selected}"
          v-for="item in containers.items"
          :key="item.id"
          @click.stop="onClick(item)">
          <div class="container-img-fridge" :title="item.label" :class="{fridge_v: item.type === 'fridge_v', fridge_h: item.type === 'fridge_h',
            nitrogen: item.type === 'nitrogen_sm' || item.type === 'nitrogen_bg'}">
          </div>
          <div class="container-name" :class="[item.type]">
            <span>{{item.label}}</span>
          </div>
        </div>
      </div>
    </collapse-panel>
  </div>
</template>

<script>
import CollapsePanel from '../transitions/collapse-panel'
export default {
  name: 'Freezer',
  components: { CollapsePanel },
  props: {
    width: String,
    containers: {
      type: Object,
      default: function () {
        return {
          items: [],
          type: 'fridge',
          expanded: false,
          loaded: false
        }
      }
    }
  },
  data () {
    return {
      title: '冻存设备'
    }
  },
  methods: {
    onClick (item) {
      if (item.enable) {
        if (item.selected) {
          // reselect
          item.select(true, true)
        } else {
          item.select(true)
        }
        this.$emit('on-freezer-selected', item)
      }
    }
  }
}
</script>

<style lang="less">
@import "../../../assets/ivu/mixins/clear";
.root-container {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #eee;
  }
  &:before {
    content: "";
    position: absolute;
    height: 1px;
    right: 0;
    left: 0;
    bottom: 0;
    height: 1px;
    background-color: #eee;
  }
}
.root-container__box {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  &:after {
    .clearFloat();
  }
  .root-container__item {
    float: left;
    padding: 5px 10px;
    flex: 0 0 auto;
    &.selected {
      background: rgba(67, 186, 255, 0.3);
    }
    &.enable {
      .container-img-fridge {
        &.fridge_v {
          background: url(../../../assets/imgs/container/subpage-refrigerator-blue.png) no-repeat;
          background-size: 100% 100%;
        }
        &.fridge_h {
          background: url(../../../assets/imgs/container/fridge-h-blue.png) no-repeat;
          background-size: 100% 100%;
        }
        &.nitrogen {
          background: url(../../../assets/imgs/container/subpage-jar-blue.png) no-repeat;
          background-size: 100% 100%;
        }
      }
      &:hover {
        background: rgba(67, 186, 255, 0.3);
        transition: all 0.3s;
      }
    }
  }
  .container-img-fridge {
    width: 46px;
    height: 71px;
    margin: 0 auto;
    text-align: center;
    &.fridge_v {
      background: url(../../../assets/imgs/container/subpage-refrigerator-grey.png) no-repeat;
      background-size: 100% 100%;
    }
    &.fridge_h {
      background: url(../../../assets/imgs/container/fridge-h-grey.png) no-repeat;
      background-size: 100% 100%;
    }
    &.nitrogen {
      background: url(../../../assets/imgs/container/subpage-jar-grey.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  .container-name {
    text-align: center;
    font-size: 12px;
    max-width: 40px;
    white-space: nowrap;
    overflow: hidden;
    transform: scale(0.9);
    position: relative;
    height: 18px;
  }
}

</style>
