<template>
  <div>
    <tree ref="tree" :name="menuName" v-model="groupVal" :data="groupList" :height="400" @on-check-change="onGroupChange"></tree>
    <div>
      <y-button @click.native.stop="onUpdate">更新菜单分组</y-button>
    </div>
  </div>
</template>

<script>
import Tree from './group/tree'
import YButton from '../common/button/button'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: { Tree, YButton },
  props: {
    projectId: String,
    menuName: String,
    value: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      groupVal: this.value || [],
      groupList: []
    }
  },
  watch: {
    value (val) {
      if (val) {
        this.groupVal = val
      } else {
        this.groupVal = []
      }
    },
    projectId (val) {
      this.loadGroupList()
    }
  },
  mounted () {
    this.loadGroupList()
  },
  methods: {
    onGroupChange () {
      this.$emit('on-group-change', this.$refs.tree.getCheckedData())
    },
    onUpdate () {
      this.$emit('on-group-update', this.$refs.tree.getCheckedData())
    },
    loadGroupList () {
      this.$http.get(`${this.httpRoot}/admin/user/grouptree/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.groupList = res.treeList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="css">
</style>
