<template>
  <div class="poptip" :class="{'poptip-confirm': confirm}"
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
    v-clickoutside="handleClose">
    <div class="poptip-rel"
      ref="reference"
      @click="handleClick"
      @mousedown="handleFocus(false)"
      @mouseup="handleBlur(false)">
      <slot></slot>
    </div>
    <transition name="fade">
      <div class="poptip-popper" :style="{width: width ? width + 'px' : false}" ref="popper" v-show="visible">
        <div class="poptip-content">
          <div class="poptip-arrow"></div>
          <div class="poptip-inner" v-if="confirm">
            <div class="poptip-body">
              <i class="ionic-icon ionic-icon-help-circled"></i>
              <div class="poptip-body-message"><slot name="title">{{ title }}</slot></div>
            </div>
            <div class="poptip-footer">
              <y-button type="text" size="small" @click.native.stop="cancel">{{ cancelText }}</y-button>
              <y-button type="primary" size="small" @click.native.stop="ok">{{ okText }}</y-button>
            </div>
          </div>
          <div class="poptip-inner" v-if="!confirm">
            <div class="poptip-title" v-if="this.$slots.title || title">
              <slot name="title"><div class="poptip-title-inner">{{ title }}</div></slot>
            </div>
            <div class="poptip-body">
              <div class="poptip-body-content">
                <slot name="content"><div class="poptip-body-content-inner">{{ content }}</div></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Popper from '../base/popper'
import YButton from '../button/button.vue'
import clickoutside from '../directives/clickoutside'
import { oneOf } from '../../../utils/assist'

export default {
  name: 'Poptip',
  mixins: [Popper],
  directives: { clickoutside },
  components: { YButton },
  props: {
    trigger: {
      validator (value) {
        return oneOf(value, ['click', 'focus', 'hover'])
      },
      default: 'click'
    },
    placement: {
      validator (value) {
        return oneOf(value, ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end'])
      },
      default: 'top'
    },
    title: {
      type: [String, Number]
    },
    content: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: [String, Number]
    },
    confirm: {
      type: Boolean,
      default: false
    },
    okText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    fixOverflow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showTitle: true,
      isInput: false
    }
  },
  computed: {
    styles () {
      let style = {}

      if (this.width) {
        style.width = `${this.width}px`
      }
      return style
    }
  },
  watch: {
    visible (val) {
      let $overflowScrollPostionAbsoluteFix = document.querySelector('.bio_content-right')
      if (this.fixOverflow && val && $overflowScrollPostionAbsoluteFix) {
        $overflowScrollPostionAbsoluteFix.style.overflow = 'visible'
      } else if (this.fixOverflow && !val && $overflowScrollPostionAbsoluteFix) {
        $overflowScrollPostionAbsoluteFix.style.overflow = 'scroll'
      }
    }
  },
  mounted () {
    // if trigger and children is input or textarea,listen focus & blur event
    if (this.trigger === 'focus') {
      const $children = this.getInputChildren()
      if ($children) {
        $children.addEventListener('focus', this.handleFocus, false)
        $children.addEventListener('blur', this.handleBlur, false)
        this.isInput = true
      }
    }
  },
  methods: {
    handleClick () {
      if (this.confirm) {
        this.visible = !this.visible
        return true
      }
      if (this.trigger !== 'click') {
        return false
      }
      this.visible = !this.visible
    },
    handleClose () {
      if (this.confirm) {
        this.visible = false
        return true
      }
      if (this.trigger !== 'click') {
        return false
      }
      this.visible = false
    },
    handleFocus (fromInput = true) {
      if (this.trigger !== 'focus' || this.confirm || (this.isInput && !fromInput)) {
        return false
      }
      this.visible = true
    },
    handleBlur (fromInput = true) {
      if (this.trigger !== 'focus' || this.confirm || (this.isInput && !fromInput)) {
        return false
      }
      this.visible = false
    },
    handleMouseenter () {
      if (this.trigger !== 'hover' || this.confirm) {
        return false
      }
      this.visible = true
    },
    handleMouseleave () {
      if (this.trigger !== 'hover' || this.confirm) {
        return false
      }
      this.visible = false
    },
    cancel () {
      this.visible = false
      this.$emit('on-cancel')
    },
    ok () {
      this.visible = false
      this.$emit('on-ok')
    },
    getInputChildren () {
      const $input = this.$refs.reference.querySelectorAll('input')
      const $textarea = this.$refs.reference.querySelectorAll('textarea')
      let $children = null

      if ($input.length) {
        $children = $input[0]
      } else if ($textarea.length) {
        $children = $textarea[0]
      }
      return $children
    }
  },
  beforeDestroy () {
    const $children = this.getInputChildren()
    if ($children) {
      $children.removeEventListener('focus', this.handleFocus, false)
      $children.removeEventListener('blur', this.handleBlur, false)
    }
  }
}
</script>
