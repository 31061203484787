<template>
  <li class="select-item" :class="{'select-item-disabled': this.disabled,
                                  'select-item-selected': this.selected,
                                  'select-item-focus': this.isFocus}"
    @click.stop="select" @mouseout.stop="blur" v-show="!hidden">
    <span>{{label}}</span>
    <span v-if="showIconUrl" style="float:right;height:22px;"><img :src="data.iconUrl" alt="" style="position:relative;width:30px;height:30px;top:-7px;"></span>
    <span v-if="showIcon" style="float:right;color:#666" class="fa" :class="{['fa-' + data.icon]: data.icon}"></span>
    <span v-if="!showValue && showField && typeof data ==='object'" style="float:right;color:#ccc">{{data[showField]}}</span>
    <span v-if="showValue" style="float:right;color:#ccc">{{value}}</span>
    <span v-if="showColor" :style="{float:'right',background:data.color,width:'16px',height:'16px',borderRadius:'50%'}">&nbsp;</span>
  </li>
</template>

<script>
import Emitter from '../mixins/emitter'
import dataMixin from './mixin'

export default {
  name: 'Option',
  mixins: [ Emitter, dataMixin ],
  props: {
    data: {
      type: [Object, String, Number],
      required: true
    },
    id: [String, Number],
    disabled: {
      type: Boolean,
      default: false
    },
    remote: {
      type: Boolean,
      default: false
    },
    showValue: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    showColor: {
      type: Boolean,
      default: false
    },
    showIconUrl: {
      type: Boolean,
      default: false
    },
    labelField: String,
    valueField: String,
    showField: String
  },
  data () {
    return {
      valueId: new Date().getTime(),
      label: this.getLabelField(this.data),
      value: this.getValueField(this.data),
      selected: false,
      index: this.id,    // for up and down to focus
      isFocus: false,
      hidden: false,    // for search
      searchLabel: ''    // the value is slot,only for search
    }
  },
  watch: {
    data (val) {
      this.label = this.getLabelField(val)
      this.value = this.getValueField(val)
      this.selected = false
    }
  },
  mounted () {
    this.searchLabel = this.$el.innerHTML
    this.$on('on-select-close', () => {
      this.isFocus = false
    })
    this.$on('on-query-change', (val) => {
      this.queryChange(val)
    })
  },
  methods: {
    select () {
      if (this.disabled) {
        return false
      }
      this.dispatch('Select', 'on-option-selected', this.value)
    },
    blur () {
      this.isFocus = false
    },
    queryChange (val) {
      const parsedQuery = val.replace(/(\^|\(|\)|\[|\]|\$|\*|\+|\.|\?|\\|\{|\}|\|)/g, '\\$1')
      if (!this.remote) {
        this.hidden = !new RegExp(parsedQuery, 'i').test(this.searchLabel) &&
        !new RegExp('[' + parsedQuery + ']', 'i').test(this.value)
        console.log('query: ' + parsedQuery + ' hidden status: ' + this.hidden)
      }
    }
  }
}
</script>
