import objectAssign from '../../../../utils/merge'
import Shelf from './shelf'
let freezerid = 100

export default class Freezer {
  constructor (options) {
    this.id = ++freezerid
    this._type = 'freezer'
    this.type = 'fridge'
    this.exboxType = ''
    this.label = ''
    this.index = 0
    this.zone = 1 // 房间号或区号, 下标1开始
    this.enable = true
    this.capacity = 100
    this.shelfContainer = []
    this.loaded = false
    this.selected = false
    this.parent = null
    this.store = null
    this.$container = null
    this.loadFn = null

    for (let name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name]
      }
    }
    if (this.store) {
      this.$container = this.store.$container
      this.loadFn = this.store.loadFn
    }
    if (this.children) {
      this.setChildren(this.children)
      this.loaded = true
    }
  }

  loadShelfContainer (parentId, callback) {
    if (!this.loaded && !this.store.loading) {
      this.store.loading = true
      const resolveSuccess = (items) => {
        this.store.loading = false
        this.setChildren(items)
        this.loaded = true

        if (typeof callback === 'function') {
          callback.call(this)
        }
        this.$container.$emit('on-shelf-load-success')
      }
      const resolveFaild = () => {
        this.store.loading = false
      }
      if (typeof this.loadFn === 'function') {
        this.loadFn.call(this.$container, this, resolveSuccess, resolveFaild, 'shelfContainer', parentId)
      }
    }
  }

  setChildren (items) {
    this.shelfContainer = items.map((item) => {
      return new Shelf(objectAssign(item, {store: this.store, parent: this}))
    })
    this.store.registContainer(this.shelfContainer)
  }

  select (isSelect, reSelect) {
    if (!this.enable) return
    if (!this.selected && isSelect) {
      this.selected = true
      this.parent.updateSelectedFreezer(this.id)
      if (!this.loaded) {
        this.loadShelfContainer(this.id, () => {
          this.updateCurrentShelfContainer()
          this.$container.$emit('on-freezer-code-selected', this)
        })
      } else {
        this.updateCurrentShelfContainer()
        this.$container.$emit('on-freezer-code-selected', this)
      }
      this.store.updateContainerPath(this)
      this.$container.$emit('on-path-change')
    } else if (this.selected && !isSelect) {
      this.selected = false
    } else if (this.selected && isSelect && reSelect) {
      this.updateCurrentShelfContainer()
      this.store.updateContainerPath(this)
      this.$container.$emit('on-freezer-code-selected', this)
      this.$container.$emit('on-path-change')
    }
  }

  updateCurrentShelfContainer () {
    this.store.strawboxContainer.items.forEach((item) => {
      item.select(false)
    })
    this.store.sampleboxContainer.items.forEach((item) => {
      item.select(false)
    })
    this.store.layerContainer.items.forEach((item) => {
      item.select(false)
    })
    this.store.shelfContainer.items.forEach((item) => {
      item.select(false)
    })

    this.store.shelfContainer.items = this.shelfContainer
    const items = this.shelfContainer
    if (items && items.length && items[0].type) {
      this.store.shelfContainer.type = items[0].type
    }
    this.store.shelfContainer.loaded = true

    this.store.layerContainer.items = []
    this.store.sampleboxContainer.items = []
    this.store.strawboxContainer.items = []
  }

  updateSelectedShelf (selectedShelfId) {
    // 清除其它 shelf 选中状态
    this.shelfContainer.forEach((item) => {
      if (item.id !== selectedShelfId) {
        item.select(false)
      }
    })
  }
}
