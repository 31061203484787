const state = {
  fileRoot: 'http://127.0.0.1:8082'
}
const mutations = {
  UPDATE_FILE_ROOT (state, ipPort) {
    state.fileRoot = ipPort
  }
}

export default {
  state,
  mutations
}
