<template>
  <div class="panel-field">
    <div class="panel-edit-title">(child) 子输入编辑</div>
    <y-form ref="childForm" :model="child" :rules="childRule" label-width="100px" item-width="70%">
      <form-item label="唯一编码" prop="key">
        <y-input v-model="child.key" disabled></y-input>
      </form-item>
      <form-item label="组件类型" v-if="showType">
        <y-select v-model="child.type" :data="typeList"></y-select>
      </form-item>
      <form-item label="显示名称">
        <y-input v-model="child.title"></y-input>
      </form-item>
      <form-item label="保存值" v-if="showValue">
        <y-input v-model="child.value"></y-input>
      </form-item>
      <form-item label="保存值类型" prop="valueType">
        <y-select v-model="child.valueType" :data="valueTypeList"></y-select>
      </form-item>
      <form-item label="单位">
        <y-input v-model="child.unit"></y-input>
      </form-item>
      <form-item label="值 ≥" v-if="showMinMax">
        <y-input v-model="child.min"></y-input>
      </form-item>
      <form-item label="值 ≤" v-if="showMinMax">
        <y-input v-model="child.max"></y-input>
      </form-item>
      <form-item label="是否必填">
        <checklist inline v-model="child.required" :items="boolList"></checklist>
      </form-item>
      <form-item label="查询显示名称">
        <y-input v-model="child.queryTitle" placeholder="修改显示名称供查询用"></y-input>
      </form-item>
      <form-item label="报告子标题">
        <y-input v-model="child.reportTitle"></y-input>
      </form-item>
    </y-form>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import Checklist from '../../common/checklist/checklist'
export default {
  components: { YForm, FormItem, YSelect, YInput, Checklist },
  props: {
    data: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      child: this.data,
      showType: false,
      showValue: false,
      showMinMax: false,
      valueTypeList: [
        {name: '字符串', value: 'string'},
        {name: '日期', value: 'date'}, // 后台存储 Date, 返回前台string
        {name: '数值', value: 'number'},
        {name: '整数', value: 'integer'}
      ],
      typeList: [
        {name: '单选项(sselect)', value: 'sselect'},
        {name: '单选项_行内输入(sselect_input_inline)', value: 'sselect_input_inline'},
        {name: '多选项(mselect)', value: 'mselect'},
        {name: '多选项_行内输入(mselect_input_inline)', value: 'mselect_input_inline'},
        {name: '多选项_文本输入(mselect_input)', value: 'mselect_input'}
      ],
      childRule: {
        key: [{required: true, message: '不能为空', trigger: 'change'}],
        valueType: [{required: true, message: '不能为空', trigger: 'change'}]
      },
      boolList: [
        {key: '1', title: '是', value: true},
        {key: '0', title: '否', value: false}
      ]
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.child = val
        this.updateShow()
      }
    }
  },
  mounted () {
    this.updateShow()
  },
  methods: {
    updateShow () {
      if (this.$parent.$refs.fieldTree &&
          this.$parent.$refs.fieldTree.$currentTreeNode) {
        const type = this.$parent.$refs.fieldTree.$currentTreeNode.$parent.node.data.type
        if (type === 'input_sselect' || type === 'input_sselect_input' || type === 'input_sselect_multi_line' || type === 'input_mselect' || type === 'input_mselect_input') {
          this.showValue = true
        } else {
          this.showValue = false
        }
        if (type === 'input_mselect_input') {
          this.typeList = [
            {name: '多选项(mselect)', value: 'mselect'},
            {name: '多选项_行内输入(mselect_input_inline)', value: 'mselect_input_inline'},
            {name: '多选项_文本输入(mselect_input)', value: 'mselect_input'}
          ]
          this.showType = true
        } else if (type === 'input_sselect_input') {
          this.typeList = [
            {name: '单选项(sselect)', value: 'sselect'},
            {name: '单选项_行内输入(sselect_input_inline)', value: 'sselect_input_inline'}
          ]
          this.showType = true
        } else {
          this.showType = false
        }
        if (type === 'show_score') {
          this.showMinMax = true
        } else {
          this.showMinMax = false
        }
      }
    }
  }
}
</script>

<style lang="css">
</style>
