<template>
  <div class="panel-field">
    <div class="panel-edit-title">(item) 选项编辑</div>
    <y-form ref="itemForm" :model="item" :rules="itemRule" label-width="100px" item-width="70%">
      <form-item label="唯一编码" prop="key">
        <y-input v-model="item.key" disabled></y-input>
      </form-item>
      <form-item label="组件类型" prop="type">
        <y-select v-model="item.type" :data="typeList"></y-select>
      </form-item>
      <form-item label="显示名称" prop="title">
        <y-input v-model="item.title"></y-input>
      </form-item>
      <form-item label="保存值" prop="value" ref="value" v-if="item.valueType==='number' && !isNaN(Number(item.value))">
        <input-number v-model="item.value"></input-number>
      </form-item>
      <!-- <form-item label="保存值" prop="value" ref="value">
        <y-input v-model="item.value"></y-input>
      </form-item> -->
      <form-item label="保存值" prop="value" ref="value" v-if="showValue && (!item.valueType || item.valueType==='string' || (item.valueType==='number' && isNaN(Number(item.value))))">
        <y-input v-model="item.value"></y-input>
      </form-item>
      <!-- <form-item label="保存值类型" prop="valueType">
        <y-select v-model="item.valueType" :data="valueTypeList" clearable></y-select>
      </form-item> -->
      <form-item label="保存值类型" prop="valueType" v-if="showValueType">
        <y-select v-model="item.valueType" :data="valueTypeList"></y-select>
      </form-item>
      <form-item label="输入提示" v-if="showPlaceholder">
        <y-input v-model="item.placeholder"></y-input>
      </form-item>
      <form-item label="单位">
        <y-input v-model="item.unit"></y-input>
      </form-item>
      <form-item label="指标编码">
        <y-input v-model="item.icode"></y-input>
      </form-item>
      <form-item label="参考值范围">
        <y-input v-model="item.ref"></y-input>
      </form-item>
      <form-item label="单位,参考值" v-if="item.type==='input_unit_ref'">
        <unit-ref-list v-model="item.unitRef"></unit-ref-list>
      </form-item>
      <form-item label="是否必填" v-if="showUnit">
        <checklist inline v-model="item.required" :items="boolList"></checklist>
      </form-item>
      <form-item label="子输入间关系" v-if="item.children && item.children.length">
        <y-input v-model="item.relationSymbol"></y-input>
      </form-item>
      <form-item label="次子输入间关系" v-if="item.subChildren && item.subChildren.length">
        <y-input v-model="item.subRelationSymbol"></y-input>
      </form-item>
      <form-item label="是否触发求值" v-if="item.type==='input_inline'">
        <checklist inline v-model="item.triggerArg" :items="boolList"></checklist>
      </form-item>
      <form-item label="计算key列表" v-if="item.type==='input_inline'">
        <!-- 多选key列表 -->
        <y-select placeholder='顺序选择计算项' multiple v-model="item.triggerKeyList" :data="keyValList"></y-select>
        <tooltip placement="top" content="点击删除后重新切换到该节点才可观察到删除">
          <span class="clear-triggered" @click.stop="clearTriggerKeyList">删除</span>
        </tooltip>
      </form-item>
      <form-item label="计算函数" v-if="item.type==='input_inline'">
        <y-input type="textarea" v-model="item.triggerComputed"></y-input>
      </form-item>
      <!-- 计分索引，记分函数 为输入项中的一组 -->
      <form-item label="计分索引" v-if="item.type==='input_inline' || item.type==='input_number' || item.type==='input_sselect' || item.type==='input_sselect_multi_line'">
        <y-input v-model="item.scoreIndex"></y-input>
      </form-item>
      <form-item label="记分函数" v-if="item.type==='show_score' || item.type==='input_show_score'">
        <y-input type="textarea" v-model="item.fn"></y-input>
      </form-item>
      <form-item label="触发函数" v-if="item.type==='show_score'">
        <y-input type="textarea" v-model="item.trigger"></y-input>
      </form-item>
      <form-item label="触发随访项显示" v-if="item.type==='show_score' || item.type==='sselect'">
        <y-button @click.native="onTriggerClick">选择(Field)随访项</y-button>
      </form-item>
      <form-item label="联表CRF表ID" v-if="item.modelId">
        <y-input :value="`${item.modelId}(${item.modelIdTitle||''})`" disabled></y-input>
        <tooltip placement="top" content="点击删除后重新切换到该节点才可观察到删除">
          <span class="clear-triggered" @click.stop="clearModelId">删除</span>
        </tooltip>
      </form-item>
      <form-item label="desktop样式" v-if="item.desktop">
        <y-input type="textarea" :value="JSON.stringify(item.desktop)"></y-input>
        <tooltip placement="top" content="点击删除后重新切换到该节点才可观察到删除">
          <span class="clear-triggered" @click.stop="clearDesktop">删除</span>
        </tooltip>
      </form-item>
      <form-item label="desktop样式" v-if="!item.desktop && hasInput">
        <y-button @click.native="onShowDesktop">设置desktop样式</y-button>
      </form-item>
      <form-item label="单选联表选择" v-if="!item.modelId &&
                                          (item.type==='sselect_inline' || item.type==='sselect')">
        <y-button @click.native="onSetModelIdClick">选择CRF表</y-button>
      </form-item>
      <form-item label="编辑picker" v-if="item.type === 'input_inline_picker'">
        <y-button @click.native="onShowPickerWin">编辑下拉列表数据</y-button>
      </form-item>
      <form-item label="编辑timerange" v-if="item.type === 'input_inline_timerange'">
        <y-button @click.native="onShowTimerangeWin">编辑时间长度数据</y-button>
      </form-item>
      <form-item label="编辑daterange" v-if="item.type === 'input_inline_daterange'">
        <y-button @click.native="onShowDaterangeWin">编辑日期长度数据</y-button>
      </form-item>
      <form-item label="查询显示名称">
        <y-input v-model="item.queryTitle" placeholder="修改显示名称供查询用"></y-input>
      </form-item>
      <form-item label="是否出报告" v-if="showReportable">
        <checklist inline v-model="item.reportable" :items="boolList"></checklist>
      </form-item>
      <form-item label="报告子标题">
        <y-input v-model="item.reportTitle"></y-input>
      </form-item>
    </y-form>
    <picker-win
      :show="showPickerWin"
      @on-hide="showPickerWin=false"
      :data="pickerData"
      @on-confirm-ok="onPickerDataChange"></picker-win>
    <timerange-win
      :show="showTimerange"
      @on-hide="showTimerange=false"
      :data="timerange"
      @on-confirm-ok="onTimerangeChange"></timerange-win>
    <daterange-win
      :show="showDaterange"
      @on-hide="showDaterange=false"
      :data="daterange"
      @on-confirm-ok="onDaterangeChange"></daterange-win>
    <desktop-win
      :show="showDesktop"
      @on-hide="showDesktop=false"
      @on-confirm-ok="onDesktopChange"></desktop-win>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import InputNumber from '../../common/input/input-number'
import YButton from '../../common/button/button'
import Checklist from '../../common/checklist/checklist'
import UnitRefList from './unit-ref-list'
import PickerWin from '../win/picker-win'
import TimerangeWin from '../win/timerange-win'
import DaterangeWin from '../win/daterange-win'
import DesktopWin from '../win/desktop-win'
import Tooltip from '../../common/tooltip/tooltip'
import Emitter from '../../common/mixins/emitter'
export default {
  name: 'PanelItem',
  components: { YForm, FormItem, YSelect, YInput, InputNumber, YButton, Checklist, UnitRefList, PickerWin, TimerangeWin, DaterangeWin, Tooltip, DesktopWin },
  mixins: [Emitter],
  props: {
    data: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    const validateValue = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'))
      } else if (this.item.valueType === 'number' && isNaN(Number(value)) && this.showValue) {
        callback(new Error('保存值必须为数字'))
      } else {
        callback()
      }
    }
    const validateValueType = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'))
      } else if (this.$refs.value) {
        this.$refs.itemForm.validateField('value')
        callback()
      }
    }
    return {
      initFlag: true,
      item: this.data,
      typeList: [
        {
          name: '多选',
          children: [
            {name: '多选项(mselect)', value: 'mselect'},
            {name: '多选项_行内输入(mselect_input_inline)', value: 'mselect_input_inline'},
            {name: '多选项_文本输入(mselect_input)', value: 'mselect_input'},
            {name: '多选项_换行多个输入(mselect_input_multi)', value: 'mselect_input_multi'},
            {name: '多选项_单行输入+换行多个输入(mselect_input_multi_self_input)', value: 'mselect_input_multi_self_input'}
          ]
        },
        {
          name: '单选',
          children: [
            {name: '单选项_多行单选(sselect)', value: 'sselect'},
            {name: '单选项_行内单选(sselect_inline)', value: 'sselect_inline'},
            {name: '单选项_输入(sselect_input)', value: 'sselect_input'}
          ]
        },
        {
          name: '输入',
          children: [
            {name: '输入_文本(input)', value: 'input'},
            {name: '输入_单行(input_inline)', value: 'input_inline'},
            {name: '输入_仅数字(input_number)', value: 'input_number'},
            {name: '输入_下拉列表(input_inline_picker)', value: 'input_inline_picker'},
            {name: '输入_日期(input_inline_date)', value: 'input_inline_date'},
            {name: '输入_日期时间(input_inline_datetime)', value: 'input_inline_datetime'},
            {name: '输入_日期长度(input_inline_daterange)', value: 'input_inline_daterange'},
            {name: '输入_时间长度(input_inline_timerange)', value: 'input_inline_timerange'},
            {name: '输入_多行输入(input_multi_line)', value: 'input_multi_line'},
            {name: '输入_行内多输入(input_multi_inline)', value: 'input_multi_inline'},
            {name: '输入_行内单选(input_sselect)', value: 'input_sselect'},
            {name: '输入_多行单选(input_sselect_multi_line)', value: 'input_sselect_multi_line'},
            {name: '输入_单选输入(input_sselect_input)', value: 'input_sselect_input'},
            {name: '输入_多选(input_mselect)', value: 'input_mselect'},
            {name: '输入_多选输入(input_mselect_input)', value: 'input_mselect_input'},
            {name: '输入_上传图片', value: 'input_image'},
            {name: '输入_上传文件', value: 'input_file'},
            {name: '输入_显示分值', value: 'input_show_score'},
            {name: '输入_显示文本描述', value: 'input_show_tip'},
            {name: '输入_单位_参考值', value: 'input_unit_ref'}
          ]
        },
        {
          name: '显示',
          children: [
            {name: '显示分值(show_score)', value: 'show_score'},
            {name: '显示提示文本(show_tip)', value: 'show_tip'}
          ]
        },
        {
          name: '文件',
          children: [
            {name: '影像(image)', value: 'image'},
            {name: '文件(file)', value: 'file'},
            {name: '日期(input_inline_date)', value: 'input_inline_date'},
            {name: '输入_单行(input_inline)', value: 'input_inline'},
            {name: '输入_文本(input)', value: 'input'}
          ]
        }
      ],
      valueTypeList: [
        {name: '字符串', value: 'string'},
        {name: '日期', value: 'date'}, // 后台存储 Date, 返回前台string
        {name: '数值', value: 'number'},
        {name: '整数', value: 'integer'}
      ],
      boolList: [
        {key: '1', title: '是', value: true},
        {key: '0', title: '否', value: false}
      ],
      itemRule: {
        key: [{required: true, message: '不能为空', trigger: 'change'}],
        title: [{required: true, message: '不能为空', trigger: 'blur'}],
        type: [{required: true, message: '不能为空', trigger: 'change'}],
        valueType: [{required: true, trigger: 'change', validator: validateValueType}],
        value: [{required: true, trigger: 'change', validator: validateValue}]
      },
      keyValList: [],
      showPickerWin: false,
      showTimerange: false,
      showDaterange: false,
      showDesktop: false,
      pickerData: this.getPickerData(this.item),
      timerange: this.getTimerange(this.item),
      daterange: this.getDaterange(this.item)
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.initFlag = true
        this.item = val
        this.pickerData = this.getPickerData(val)
        this.timerange = this.getTimerange(val)
        this.daterange = this.getDaterange(val)
        this.keyValList = this.updateInputKeyValList(val)
        this.resetTypeList()
      }
    },
    'item.title' (val) {
      if (!this.initFlag && val && (!this.item.valueType || this.item.valueType === 'string') && this.showValue) {
        this.item.value = val
      } else {
        this.initFlag = false
      }
    }
  },
  computed: {
    showPlaceholder () {
      if (this.item && (this.item.type === 'input_inline' ||
          this.item.type === 'input_number' ||
          this.item.type === 'input_inline_picker' ||
          this.item.type === 'input_inline_date' ||
          this.item.type === 'input_inline_datetime' ||
          this.item.type === 'input_inline_daterange' ||
          this.item.type === 'input_inline_timerange' ||
          this.item.type === 'mselect_input_inline' ||
          this.item.type === 'mselect_input_multi_self_input')) {
        return true
      }
      return false
    },
    showUnit () {
      if (this.item && (this.item.type === 'input_inline' ||
          this.item.type === 'input_number' ||
          this.item.type === 'input_inline_picker' ||
          this.item.type === 'input_inline_date' ||
          this.item.type === 'input_inline_datetime' ||
          this.item.type === 'input_inline_daterange' ||
          this.item.type === 'input_inline_timerange' ||
          this.item.type === 'mselect_input_inline' ||
          this.item.type === 'mselect_input_multi_self_input' ||
          this.item.type === 'show_score')) {
        return true
      }
      return false
    },
    hasInput () {
      if (this.item && (this.item.type === 'input' ||
          this.item.type === 'input_inline' ||
          this.item.type === 'input_number' ||
          this.item.type === 'input_multi_line' ||
          this.item.type === 'input_multi_inline' ||
          this.item.type === 'input_inline_picker' ||
          this.item.type === 'input_inline_date' ||
          this.item.type === 'input_inline_datetime' ||
          this.item.type === 'input_inline_daterange' ||
          this.item.type === 'input_inline_timerange' ||
          this.item.type === 'sselect_input' ||
          this.item.type === 'mselect_input_inline' ||
          this.item.type === 'mselect_input_multi' ||
          this.item.type === 'mselect_input_multi_self_input')) {
        return true
      }
      return false
    },
    showValue () {
      let parentType = ''
      if (this.$parent.$refs.fieldTree &&
          this.$parent.$refs.fieldTree.$currentTreeNode) {
        // field type
        parentType = this.$parent.$refs.fieldTree.$currentTreeNode.$parent.node.data.type
      }
      if (this.item.type) {
        return this.item.type &&
             (this.item.type.indexOf('input') === -1 ||
              this.item.type.indexOf('input') > 0 ||
              parentType.indexOf('input_table') >= 0) &&  // input_inline
             (this.item.type !== 'input_multi_inline' &&
              this.item.type !== 'input_multi_line' &&
              this.item.type !== 'input_sselect' &&
              this.item.type !== 'input_sselect_multi_line' &&
              this.item.type !== 'input_sselect_input' &&
              this.item.type !== 'input_mselect' &&
              this.item.type !== 'input_mselect_input' &&
              this.item.type !== 'input_image' &&
              this.item.type !== 'input_file' &&
              this.item.type !== 'input_show_tip')
      }
      return true
    },
    showValueType () {
      return this.item.type !== 'input_multi_inline' &&
             this.item.type !== 'input_multi_line' &&
             this.item.type !== 'input_sselect' &&
             this.item.type !== 'input_sselect_multi_line' &&
             this.item.type !== 'input_sselect_input' &&
             this.item.type !== 'input_mselect' &&
             this.item.type !== 'input_mselect_input' &&
             this.item.type !== 'image' &&
             this.item.type !== 'file' &&
             this.item.type !== 'input_image' &&
             this.item.type !== 'input_file' &&
             this.item.type !== 'input_show_tip'
    },
    showReportable () {
      return this.item.type === 'input' ||
            this.item.type === 'input_inline' ||
            this.item.type === 'input_number' ||
            this.item.type === 'input_inline_picker' ||
            this.item.type === 'input_inline_date' ||
            this.item.type === 'input_inline_datetime' ||
            this.item.type === 'input_inline_daterange' ||
            this.item.type === 'input_inline_timerange' ||
            this.item.type === 'input_multi_line' ||
            this.item.type === 'input_multi_inline' ||
            this.item.type === 'input_sselect' ||
            this.item.type === 'input_sselect_multi_line' ||
            this.item.type === 'input_sselect_input' ||
            this.item.type === 'input_mselect' ||
            this.item.type === 'input_mselect_input' ||
            this.item.type === 'input_show_score' ||
            this.item.type === 'show_score'
    }
  },
  created () {
    this.$on('on-set-modelId', (modelId, modelIdTitle) => {
      this.$set(this.item, 'modelId', modelId)
      this.$set(this.item, 'modelIdTitle', modelIdTitle)
    })
  },
  mounted () {
    this.resetTypeList()
  },
  methods: {
    onTriggerClick () {
      this.dispatch('CRF', 'on-trigger', this.item.key)
    },
    onSetModelIdClick () {
      // open window and then select crfTable, set crfTable.modelId to this.item
      this.dispatch('CRF', 'on-set-modelId-show')
    },
    clearTriggerKeyList () {
      delete this.item.triggerKeyList
    },
    clearModelId () {
      delete this.item.modelId
      delete this.item.modelIdTitle
    },
    clearDesktop () {
      // delete this.item.desktop
      this.item.desktop = null
    },
    getPickerData (item) {
      if (item && item.picker && Array.isArray(item.picker.data)) {
        return JSON.parse(JSON.stringify(item.picker.data))
      } else {
        return [[]]
      }
    },
    getTimerange (item) {
      if (item && item.timerange) {
        return JSON.parse(JSON.stringify(item.timerange))
      } else {
        return {h: 0}
      }
    },
    getDaterange (item) {
      if (item && item.daterange) {
        return JSON.parse(JSON.stringify(item.daterange))
      } else {
        return {h: 0}
      }
    },
    onShowPickerWin () {
      this.showPickerWin = true
    },
    onShowTimerangeWin () {
      this.showTimerange = true
    },
    onShowDaterangeWin () {
      this.showDaterange = true
    },
    onShowDesktop () {
      this.showDesktop = true
    },
    onPickerDataChange (data) {
      // update or delete when data is []
      if (Array.isArray(data) && Array.isArray(data[0]) && data[0].length) {
        if (!this.item.picker) {
          this.$set(this.item, 'picker', {})
        }
        this.item.picker.data = data
      } else if (this.item) {
        delete this.item.picker
      }
    },
    onTimerangeChange (data) {
      if (this.item) {
        if (!this.item.timerange) {
          this.$set(this.item, 'timerange', data)
        } else {
          this.item.timerange = data
        }
      }
    },
    onDaterangeChange (data) {
      if (this.item) {
        if (!this.item.daterange) {
          this.$set(this.item, 'daterange', data)
        } else {
          this.item.daterange = data
        }
      }
    },
    onDesktopChange (data) {
      if (this.item && Object.keys(data || {}).length) {
        if (!this.item.desktop) {
          this.$set(this.item, 'desktop', data)
        } else {
          this.item.desktop = data
        }
      }
    },
    resetTypeList () {
      if (this.$parent.$refs.fieldTree &&
          this.$parent.$refs.fieldTree.$currentTreeNode) {
        // field type
        const parentType = this.$parent.$refs.fieldTree.$currentTreeNode.$parent.node.data.type
        this.resetTypeListDone(parentType)
      }
    },
    updateInputKeyValList (val) {
      const keyValList = []
      if (this.$parent.$refs.fieldTree &&
          this.$parent.$refs.fieldTree.$currentTreeNode) {
        // field node
        const field = this.$parent.$refs.fieldTree.$currentTreeNode.$parent.node.data
        const items = field.items || []
        items.forEach(item => {
          if (item.type === 'input_inline' && item.key !== val.key) {
            keyValList.push({value: item.key, title: item.title})
          }
        })
      }
      return keyValList
    },
    resetTypeListDone (type) {
      if (type && type === 'mselect') {
        this.typeList = [
          {name: '多选项(mselect)', value: 'mselect'}
        ]
      } else if (type && type === 'mselect_input') {
        this.typeList = [
          {name: '多选项(mselect)', value: 'mselect'},
          {name: '多选项_行内输入(mselect_input_inline)', value: 'mselect_input_inline'},
          {name: '多选项_文本输入(mselect_input)', value: 'mselect_input'},
          {name: '多选项_换行多个输入(mselect_input_multi)', value: 'mselect_input_multi'},
          {name: '多选项_单行输入+换行多个输入(mselect_input_multi_self_input)', value: 'mselect_input_multi_self_input'}
        ]
      } else if (type && type === 'sselect') {
        this.typeList = [
          {name: '单选项_多行单选(sselect)', value: 'sselect'},
          {name: '单选项_行内单选(sselect_inline)', value: 'sselect_inline'},
          {name: '单选项_输入(sselect_input)', value: 'sselect_input'}
        ]
      } else if (type && type === 'sselect_inline') {
        this.typeList = [
          {name: '单选项_行内单选(sselect_inline)', value: 'sselect_inline'}
        ]
      } else if (type && type === 'sselect_show_quest') {
        this.typeList = [
          {name: '单选项_行内单选(sselect_inline)', value: 'sselect_inline'}
        ]
      } else if (type && type.indexOf('input') >= 0) {
        this.typeList = [
          {name: '输入_文本(input)', value: 'input'},
          {name: '输入_单行(input_inline)', value: 'input_inline'},
          {name: '输入_仅数字(input_number)', value: 'input_number'},
          {name: '输入_下拉列表(input_inline_picker)', value: 'input_inline_picker'},
          {name: '输入_日期(input_inline_date)', value: 'input_inline_date'},
          {name: '输入_日期时间(input_inline_datetime)', value: 'input_inline_datetime'},
          {name: '输入_日期长度(input_inline_daterange)', value: 'input_inline_daterange'},
          {name: '输入_时间长度(input_inline_timerange)', value: 'input_inline_timerange'},
          {name: '输入_多行输入(input_multi_line)', value: 'input_multi_line'},
          {name: '输入_行内多输入(input_multi_inline)', value: 'input_multi_inline'},
          {name: '输入_行内单选(input_sselect)', value: 'input_sselect'},
          {name: '输入_多行单选(input_sselect_multi_line)', value: 'input_sselect_multi_line'},
          {name: '输入_单选输入(input_sselect_input)', value: 'input_sselect_input'},
          {name: '输入_多选(input_mselect)', value: 'input_mselect'},
          {name: '输入_多选输入(input_mselect_input)', value: 'input_mselect_input'},
          {name: '输入_上传图片', value: 'input_image'},
          {name: '输入_上传文件', value: 'input_file'},
          {name: '输入_显示分值', value: 'input_show_score'},
          {name: '输入_显示文本描述', value: 'input_show_tip'},
          {name: '输入_单位_参考值', value: 'input_unit_ref'}
        ]
      } else if (type && type.indexOf('show') >= 0) {
        this.typeList = [
          {name: '显示分值(show_score)', value: 'show_score'},
          {name: '显示提示文本(show_tip)', value: 'show_tip'}
        ]
      } else if (type && type.indexOf('file') >= 0) {
        this.typeList = [
          {name: '影像(image)', value: 'image'},
          {name: '文件(file)', value: 'file'},
          {name: '日期(input_inline_date)', value: 'input_inline_date'},
          {name: '输入_单行(input_inline)', value: 'input_inline'},
          {name: '输入_文本(input)', value: 'input'}
        ]
      }
    }
  }
}
</script>

<style lang="css">
</style>
