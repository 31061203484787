<template>
  <div class="picker-confirm">
    <span :class="{'picker-confirm-time-disabled': timeDisabled}" v-if="showTime" @click="handleToggleTime">
      <template v-if="isTime">{{ '选择日期' }}</template>
      <template v-else>{{ '选择时间' }}</template>
    </span>
    <y-button size="small" type="text" @click.native="handleClear">{{ '清空' }}</y-button>
    <y-button size="small" type="primary" @click.native="handleSuccess">{{ '确定' }}</y-button>
  </div>
</template>

<script>
import YButton from '../../button/button'

export default {
  components: { YButton },
  props: {
    showTime: {
      type: Boolean,
      default: false
    },
    isTime: {
      type: Boolean,
      default: false
    },
    timeDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClear () {
      this.$emit('on-pick-clear')
    },
    handleSuccess () {
      this.$emit('on-pick-success')
    },
    handleToggleTime () {
      if (this.timeDisabled) return
      this.$emit('on-pick-toggle-time')
    }
  }
}
</script>
