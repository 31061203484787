import Notice from './notice.vue'
import Vue from 'vue'

export function newInstance (properties) {
  const _props = properties || {}

  const notice = new Vue({
    render: (h) => {
      return h('div', [
        h('notice', {
          props: _props
        })
      ])
    },
    components: { Notice }
  }).$mount().$children[0]

  document.body.appendChild(notice.$el)

  return {
    notice (noticeProps) {
      notice.add(noticeProps)
    },
    remove (name) {
      notice.close(name)
    },
    component: notice,
    destroy () {
      notice.$destroy()
      document.body.removeChild(notice.$el)
    }
  }
}
