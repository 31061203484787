<template>
  <modal v-model="showModal" title="添加 desktop 样式" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <div class="desktop">
      <div class="item">
        <label for="float-left" class="label">向左浮动</label>
        <input id="float-left" class="input-checkbox" type="checkbox" value="float:left" v-model="float">
      </div>
      <div class="item">
        <label class="label">宽度百分比 %</label>
        <input class="input" v-model="width">
      </div>
      <div class="item">
        <label class="label">左边距百分比 %</label>
        <input class="input" v-model="marginLeft">
      </div>
      <div class="item">
        <label class="label">右边距百分比 %</label>
        <input class="input" v-model="marginRight">
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
export default {
  components: {Modal},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      showModal: this.show,
      float: [],
      width: 100,
      marginLeft: 0,
      marginRight: 0
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    value (val) {
      if (val && val.float) this.float = val.float
      if (val && val.width) this.width = val.width
      if (val && val.marginLeft) this.marginLeft = val.marginLeft
      if (val && val.marginRight) this.marginRight = val.marginRight
    }
  },
  methods: {
    onBeforeConfirm () {
      if (!this.validate()) {
        this.$Message.warning('请完成数据填写')
        return false
      }
      return true
    },
    onConfirm () {
      let data = {}
      if (this.float.length) {
        data.float = 'left'
      }
      if (parseInt(this.width) > 0 && parseInt(this.width) <= 100) {
        data.width = parseInt(this.width) + '%'
      }
      if (parseInt(this.marginLeft) > 0 && parseInt(this.marginLeft) <= 100) {
        data.marginLeft = parseInt(this.marginLeft) + '%'
      }
      if (parseInt(this.marginRight) > 0 && parseInt(this.marginRight) <= 100) {
        data.marginRight = parseInt(this.marginRight) + '%'
      }
      this.$emit('on-confirm-ok', data)
    },
    validate () {
      try {
        if (this.width.length && (parseInt(this.width) <= 0 ||
            parseInt(this.width) > 100 || isNaN(parseInt(this.width)))) {
          return false
        }
        if (this.width.marginLeft && (parseInt(this.marginLeft) < 0 ||
            parseInt(this.marginLeft) > 100 || isNaN(parseInt(this.marginLeft)))) {
          return false
        }
        if (this.width.marginRight && (parseInt(this.marginRight) < 0 ||
            parseInt(this.marginRight) > 100 || isNaN(parseInt(this.marginRight)))) {
          return false
        }
      } catch (e) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="less">
.desktop {
  .item {
    height: 38px;
    line-height: 38px;
  }
  .label {
    display: inline-block;
    width: 100px;
  }
  .input-checkbox {
    display: inline-block;
    height: 32px;
    padding: 4px 7px;
    border: 1px solid #d7dde4;
    border-radius: 4px;
    color: #657180;
    outline: none;
  }
  .input {
    display: inline-block;
    width: 250px;
    height: 32px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 12px;
    border: 1px solid #d7dde4;
    border-radius: 4px;
    color: #657180;
    background-color: #fff;
    background-image: none;
    position: relative;
    cursor: text;
    outline: none;
    transition: border 0.2s ease-in-out, background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
}
</style>
