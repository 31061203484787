<template>
  <div class="top_header">
    <div class="top_header-logo">
      <img src="/static/logo/yxy-logo-green.png" alt=""/>
    </div>
    <!-- <div class="top_rj-logo">
      <img src="/static/logo/rj-bb-logo.png" alt=""/>
    </div> -->
    <div class="header-search" style="float:left;">
      <header-search placeholder="请选择项目"></header-search>
    </div>
    <div class="header-menus" style="padding-left: 15px; float:left;">
      <header-menu type="text" routeName="home" :class="{active: currentPath==='/home'}">首页</header-menu>
      <header-menu type="text" routeName="crftable" :class="{active: currentPath==='/crftable'}" auth>CRF表</header-menu>
      <header-menu type="text" routeName="crf" :class="{active: currentPath==='/crf'}" auth>CRF</header-menu>
      <header-menu type="text" routeName="subject" :class="{active: currentPath==='/subject'}" auth>课题</header-menu>
      <header-menu type="text" routeName="menu" :class="{active: currentPath==='/menu'}" auth>菜单</header-menu>
      <header-menu type="text" routeName="user" :class="{active: currentPath==='/user'}" auth>用户管理</header-menu>
      <header-menu type="text" routeName="api" :class="{active: currentPath==='/api'}" auth>API黑名单</header-menu>
      <header-menu type="text" routeName="dict" :class="{active: currentPath==='/dict'}" auth>字典数据</header-menu>
      <header-menu type="text" routeName="myContainers" :class="{active: currentPath==='/myContainers'}" auth>容器创建</header-menu>
      <header-menu type="text" routeName="sampletype" :class="{active: currentPath==='/sampletype'}" auth>样本类型</header-menu>
    </div>
    <div class="header-menus top" style="float:left;">
      <a class="header-menu text">全局设置</a>
      <ul class="sub-menus">
        <li class="sub-menu"><header-menu type="text" routeName="license" :class="{active: currentPath==='/license'}" auth>license</header-menu></li>
        <li class="sub-menu"><header-menu type="text" routeName="sysupdate" :class="{active: currentPath==='/sysupdate'}" auth>系统debug</header-menu></li>
        <li class="sub-menu"><header-menu type="text" routeName="sampleTypeGlobal" :class="{active: currentPath==='/sampleTypeGlobal'}" auth>样本类型</header-menu></li>
        <li class="sub-menu"><header-menu type="text" routeName="crftableshared" :class="{active: currentPath==='/crftableshared'}" auth>共享CRF表</header-menu></li>
        <li class="sub-menu"><header-menu type="text" routeName="crftablebrief" :class="{active: currentPath==='/crftablebrief'}" auth>CRF表汇总</header-menu></li>
        <li class="sub-menu"><header-menu type="text" routeName="datasource" :class="{active: currentPath==='/datasource'}" auth>数据源管理</header-menu></li>
        <li class="sub-menu"><header-menu type="text" routeName="dataextract" :class="{active: currentPath==='/dataextract'}" auth>数据集管理</header-menu></li>
        <li class="sub-menu"><header-menu type="text" routeName="mongo" :class="{active: currentPath==='/mongo'}" auth>Mongo数据库</header-menu></li>
        <li class="sub-menu"><header-menu type="text" routeName="rds" :class="{active: currentPath==='/rds'}" auth>RDS数据库</header-menu></li>
        <li class="sub-menu"><header-menu type="text" routeName="dataextractrds" :class="{active: currentPath==='/dataextractrds'}" auth>RDS数据集管理</header-menu></li>
      </ul>
    </div>
    <div class="header-menus clearfix" style="float:right">
      <header-menu v-if="loggedIn" type="text" routeName="login">{{username}}登出</header-menu>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import HeaderMenu from './header-menu'
import HeaderSearch from './header-search'

export default {
  components: {HeaderMenu, HeaderSearch},
  computed: {
    loggedIn () {
      return this.$store.getters.getLoginState
    },
    username () {
      return this.$store.getters.getUserName
    },
    currentPath () {
      return this.$store.getters.getCurrentPath
    }
  }
}
</script>

<style lang="less">
.top_header {
  background: #3a90e4;
  background: #313e58;
  position: relative;
  height: 60px;
  line-height: 60px;
  .top_rj-logo {
    height: 60px;
    float: left;
    img {
      display: block;
      height: 100%;
    }
  }
  .top_header-logo {
    height: 50px;
    float: left;
    padding-left: 15px;
    margin-right: 10px;
    img {
      display: block;
      height: 100%;
    }
  }
  .clear {
    clear:both;
  }
}
.clearfix {
  &:before, &:after {
    display: table;
    content: ""
  }
  &:after {
    clear: both;
  }
}
.header-search, .header-menus {
  display: inline-block;
}
.sub-menus {
  position: absolute;
  z-index: 5;
  display: none;
  list-style: none;
  min-width: 100px;
  background: #313e58; // #3a90e4;
  box-shadow: 0px 0px 5px rgba(8, 110, 100, 0.5);
  color: #eee;
}
.sub-menu {
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-weight: normal;
  transition: all .2s ease-in-out;
  &:hover {
    background: #2277da;
    color: #fff;
  }
}
</style>
