<template>
  <span class="node-content" :class="{focused: node.focused}">
    <label class="checkbox-wrapper" :for="node.key" v-if="node.type === 'root' || node.type === 'item' || node.type === 'gitem'">
      <span class="checkbox" :class="{'checkbox-checked': node.checked}">
        <span class="checkbox-inner"></span>
        <input type="checkbox" class="checkbox-input" :id="node.key" v-model="node.checked">
      </span>
    </label>
    <span class="node-content-label" :class="[node.type]">{{ node.label || node.type }}
      <span class="value" v-if="node.data.valueType === 'number' && (node.data.value || node.data.value === 0)">[{{node.data.value}}]</span>
    </span>
    <span class="node-content-arrow ionic-icon ionic-icon-arrow-up-c"
      v-if="canSlide" @click.stop="onSlidUp"></span>
    <span class="node-content-arrow ionic-icon ionic-icon-arrow-down-c"
      v-if="canSlide" @click.stop="onSlidDown"></span>
    <span class="node-content-arrow ionic-icon ionic-icon-information-circled"
      v-if="canSort" @click.stop="onDesktopStyle"></span>
    <span class="node-content-desktop ionic-icon ionic-icon-close"
      v-if="desktop" @click.stop="deleteDesktopStyle">{{JSON.stringify(this.desktop)}}</span>
    <desktop-win
      :show="showDesktop"
      v-model="currentDesktop"
      @on-hide="showDesktop=false"
      @on-confirm-ok="onDesktopChange"></desktop-win>
  </span>
</template>

<script>
import DesktopWin from '../win/desktop-win'
export default {
  components: {DesktopWin},
  props: {
    node: {
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      showDesktop: false,
      desktop: this.node.data ? this.node.data.desktop ? this.node.data.desktop : null : null,
      currentDesktop: {}
    }
  },
  computed: {
    canSlide () {
      return this.node.type === 'field' ||
             this.node.type === 'item' ||
             this.node.type === 'group' ||
             this.node.type === 'gitem'
    },
    canSort () {
      return this.node.type === 'item' ||
             this.node.type === 'gitem'
    }
  },
  watch: {
    'node.checked' (val) {
      if (val && (this.node.type === 'item' || this.node.type === 'gitem') && this.node.data.desktop) {
        this.desktop = this.node.data.desktop
      }
      this.emitCheckChange(val)
    }
  },
  methods: {
    emitCheckChange (val) {
      this.$emit('on-node-check-change', val)
    },
    onSlidUp () {
      this.node.slidUp()
    },
    onSlidDown () {
      this.node.slidDown()
    },
    onDesktopStyle () {
      if (this.desktop) {
        this.currentDesktop = Object.assign({}, this.desktop)
      } else {
        let temp = this.$store.state.desktop.desktop
        this.currentDesktop = Object.assign({}, temp)
      }
      this.showDesktop = true
    },
    onDesktopChange (val) {
      this.node.setData('desktop', val)
      this.desktop = val
      this.$store.dispatch('updateDesktop', val)
    },
    deleteDesktopStyle () {
      this.node.removeData('desktop')
      this.desktop = null
    }
  }
}
</script>

<style lang="css">
</style>
