<template>
  <modal class="type-edit-win" v-model="showModal" title="样本类型编辑" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm" :styles="{top: '60px'}">
    <y-form ref="typeForm" :model="type" :rules="typeRule" label-width="100px" item-width="250px">
      <form-item label="类型编码" prop="code">
        <y-input v-model="type.code" disabled></y-input>
      </form-item>
      <form-item label="类型名称" prop="name">
        <y-input v-model="type.name" disabled></y-input>
      </form-item>
      <form-item label="样本规格" prop="specList">
        <poptip trigger="hover" content="配置样本需要用到的冻存管规格" placement="right-start">
          <y-select
            v-model="type.specList"
            :data="specList"
            valueField="name"
            multiple></y-select>
        </poptip>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YInput from '../../common/input/input'
import YSelect from '../../common/select/select'
import Poptip from '../../common/poptip/poptip'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: { Modal, YForm, FormItem, YInput, YSelect, Poptip },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {},
    projectId: String
  },
  data () {
    return {
      showModal: this.show,
      type: this.value || {},
      typeRule: {
        code: [{required: true, message: '不能为空', trigger: 'blur'}],
        name: [{required: true, message: '不能为空', trigger: 'blur'}],
        specList: [{type: 'array', required: true, message: '不能为空', trigger: 'blur'}]
      },
      specList: []
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    value (val) {
      this.type = val
    }
  },
  mounted () {
    this.loadSpecList()
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.typeForm.validate()
      if (!this.$refs.typeForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.mergeValues())
    },
    loadSpecList () {
      this.$http.get(`${this.httpRoot}/dict/item/list`, authenticate({params: {dictCode: 'spec', projectId: this.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.specList = res.items || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    mergeValues () {
      let type = Object.assign({}, this.type)
      return type
    }
  }
}
</script>

<style lang="less">
.form-item {
  .poptip {
    width: 100%;
    .poptip-rel {
      width: 100%;
    }
  }
}
</style>
