<template>
  <modal v-model="showModal" title="添加 patientDataset" width="600" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="itemForm" :model="item" :rules="itemRule" label-width="150px" item-width="400px">
      <form-item label="名称" prop="name">
        <y-input v-model="item.name"></y-input>
      </form-item>
      <form-item label="projectId" prop="projectId">
        <y-input v-model="item.projectId"></y-input>
      </form-item>
      <form-item label="collection">
        <y-input v-model="item.collection"></y-input>
      </form-item>
      <form-item label="uniqueCodeField">
        <y-input v-model="item.uniqueCodeField"></y-input>
      </form-item>
      <form-item label="codeType">
        <y-input v-model="item.codeType"></y-input>
      </form-item>
      <form-item label="nameField">
        <y-input v-model="item.nameField"></y-input>
      </form-item>
      <form-item label="fieldMap">
        <keyval-list v-model="item.fieldMap" valtype="object"></keyval-list>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import KeyvalList from '../panel/keyval-list'
export default {
  components: { Modal, YForm, FormItem, YSelect, YInput, KeyvalList },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    tablenameList: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      showModal: this.show,
      item: {
        name: '',
        projectId: this.$store.state.project.projectId,
        datasourceId: '',
        datasourceName: '',
        collection: '',
        uniqueCodeField: '',
        codeType: '',
        nameField: '',
        callFunc: [],
        fieldMap: []
      },
      itemRule: {
        name: [{required: true, message: '不能为空', trigger: 'blur'}],
        projectId: [{required: true, message: '不能为空', trigger: 'blur'}],
        datasourceId: [{required: true, message: '不能为空', trigger: 'blur'}]
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.itemForm.validate()
      if (!this.$refs.itemForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.mergeValues(this.item))
    },
    mergeValues (item) {
      let data = Object.assign({}, item)
      return data
    },
    onDatasourceChange (val) {
      if (val && val.name) {
        this.item.datasourceName = val.name
      }
    }
  }
}
</script>

<style lang="css">
</style>
