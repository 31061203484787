<template>
  <div class="panel-pp">
    <div class="pp-left">
      <div class="pp-container btn-group-left">
        <button type="button" class="btn" @click.stop="onShowPatientUniqueCodeWin=true">患者数据抓取</button>
        <button type="button" class="btn" @click.stop="onShowPatientUniqueCodeWin2=true">患者随访实例列表</button>
      </div>
      <div class="pp-container block" @click.stop="onLoadFollowup($event)">
        <div class="pp-follow-item hd">
          <span class="action">操作</span>
          <span class="title">title</span>
          <span class="time">followupDate</span>
          <span class="time">createTime</span>
        </div>
        <div class="pp-follow-item" v-for="item in followupList" :key="item.id" :data-id="item.id">
          <span class="action fa fa-close" data-action="delete">&nbsp;</span>
          <span class="title" style="text-decoration: underline;">{{item.title}}</span>
          <span class="time">{{item.followupDate | formatDate}}</span>
          <span class="time">{{item.createTime | formatDate}}</span>
        </div>
      </div>
    </div>
    <div class="pp-right">
      <div class="pp-container block-2">
        <div class="pp-followup-list" @click.stop="onFollowupItemClick($event)">
          <div class="pp-followup-item" v-for="item in followup.items" :key="item.modelId" :data-id="item.modelId">{{item.title}}</div>
        </div>
        <quest-item-list :questItemList="questItemList" :data="questItemData" class="quest-item-list-box"></quest-item-list>
      </div>
    </div>
    <patient-uniquecode-win :show="onShowPatientUniqueCodeWin" @on-hide="onShowPatientUniqueCodeWin=false" @on-confirm-ok="onPatientUniqueCode"></patient-uniquecode-win>
    <patient-uniquecode-win :show="onShowPatientUniqueCodeWin2" @on-hide="onShowPatientUniqueCodeWin2=false" @on-confirm-ok="onPatientUniqueCode2"></patient-uniquecode-win>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>
<script>
import Loading from '../../common/loading/loading.vue'
import patientUniquecodeWin from '../win/patient-uniquecode-win.vue'
import QuestItemList from '../../QuestItemList.vue'
import DateFilter from '../../mixins/dateFilter'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: {Loading, patientUniquecodeWin, QuestItemList},
  mixins: [DateFilter],
  data () {
    return {
      onShowPatientUniqueCodeWin: false,
      onShowPatientUniqueCodeWin2: false,
      showLoading: false,
      uniqueCode: '',
      followupList: [],
      followup: {},
      crfId: '',
      questItemList: [],
      questItemData: {}
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId // in manage questId is projectId, quest-item-list 中无效
    },
    datasource () {
      return this.$store.state.db.datasource
    }
  },
  methods: {
    onPatientUniqueCode (data) {
      this.uniqueCode = data.uniqueCode
      this.extractPatientData(data.uniqueCode)
    },
    onPatientUniqueCode2 (data) {
      console.log('loadFollowupAbstractList uniqueCode: ', data)
      this.uniqueCode = data.uniqueCode
      this.loadFollowupAbstractList()
    },
    onLoadFollowup (e) {
      e.stopPropagation()
      const path = []
      let el = e.target
      path.push(el)
      while (el !== e.currentTarget) {
        el = el.parentElement
        path.push(el)
      }
      let id = ''
      let action = ''
      for (const el of path) {
        if (el.dataset.action) {
          action = el.dataset.action
        }
        if (el.dataset.id) {
          id = el.dataset.id
        }
      }
      if (id) {
        if (action === 'delete') {
          this.$Modal.confirm({
            title: '删除抓取随访',
            content: '确认删除？',
            onOk: () => {
              this.deleteFollowup(id, () => {
                this.loadFollowupAbstractList()
              })
            }
          })
        } else {
          this.loadFollowup(id)
        }
      }
    },
    onFollowupItemClick (e) {
      e.stopPropagation()
      const path = []
      let el = e.target
      path.push(el)
      while (el !== e.currentTarget) {
        el = el.parentElement
        path.push(el)
      }
      let id = ''
      for (const el of path) {
        if (el.dataset.id) {
          id = el.dataset.id
        }
      }
      if (id) {
        let fitem = null
        this.followup.items.forEach(item => {
          if (item.modelId === id) {
            fitem = item
          }
        })
        if (fitem) {
          this.loadItemList(fitem)
          this.loadItemData(fitem)
        }
      }
    },
    extractPatientData (uniqueCode) {
      this.updateLoadStatus('load-start', '')
      this.$http.post(`${this.httpRoot}/rds/fetch/single`, {projectId: this.projectId, uniqueCode}, authenticate())
      .then(res => res.json())
      .then(res => {
        this.updateLoadStatus('load-success')
        if (res.status === 'success') {
          this.$Message.success('患者数据抓取成功')
          // 抓取成功后，直接调用 随访列表
          this.loadFollowupAbstractList()
        } else {
          this.$Modal.error({
            title: '抓取失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        this.updateLoadStatus('load-faild', '服务异常，抓取失败')
        catchExpired(err, this)
      })
    },
    loadFollowupAbstractList () {
      if (!this.datasource) {
        this.$Message.error('没有选择数据源')
        return
      }
      if (this.datasource && !this.datasource.subjectId) {
        this.$Message.error('数据源没有subjectId')
        return
      }
      if (!this.uniqueCode) {
        this.$Message.error('没有填写uniqueCode')
        return
      }
      this.updateLoadStatus('load-start', '')
      this.$http.get(`${this.httpRoot}/followup/abstract/list`, authenticate({
        params: {
          patientId: '', // patientId, uniqueCode 二取其一
          subjectId: this.datasource.subjectId,
          uniqueCode: this.uniqueCode
        }
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success')
          this.followupList = res.followupList
          // clear
          this.followup = {}
          this.crfId = ''
          this.questItemList = []
          this.questItemData = {}
        } else {
          this.$Modal.error({
            title: '获取患者随访列表失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        this.updateLoadStatus('load-faild', '服务异常，查询失败')
        catchExpired(err, this)
      })
    },
    loadFollowup (id) {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/followup/detail`, authenticate({params: {id}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && res.followup) {
          this.followup = res.followup || {}
          this.crfId = this.followup.crfId
          // clear
          this.questItemList = []
          this.questItemData = {}
          this.showLoading = false
        } else {
          this.showLoading = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    deleteFollowup (id, callback) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/followup/delete`, {id}, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
      })
    },
    loadItemList (item) {
      this.loadingText = ''
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/crf/crfTable/field/list`,
        authenticate({params: {id: item.modelId, crfId: this.crfId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.questItemList = res.fieldList || []
          this.showLoading = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadItemData (item) {
      this.loadingText = '正在加载...'
      // this.showLoading = true
      this.$http.get(`${this.httpRoot}/followup/data/detail`,
        authenticate({params: {collection: item.collection, valueId: item.valueId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$nextTick(() => {
            this.questItemData = res.itemData
            // this.showLoading = false
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    updateLoadStatus (status, loadingText) {
      if (status === 'load-start') {
        this.loadingText = loadingText
        this.showLoading = true
      } else if (status === 'load-success') {
        this.showLoading = false
        if (loadingText) {
          this.$Message.success(loadingText)
        }
      } else if (status === 'load-faild') {
        this.showLoading = false
        this.$Message.error(loadingText)
      }
    }
  }
}
</script>
<style lang="less">
.panel-pp {
  height: 100%;
  display: flex;
}
.pp-left {
  padding-top: 16px;
}
.pp-right {
  flex: 1 1 auto;
}
.pp-container {
  padding-left: 16px;
  &.block {
    margin-top: 10px;
  }
  &.block-2 {
    display: flex;
  }
}
.pp-follow-item {
  display: flex;
  align-content: center;
  line-height: 28px;
  cursor: pointer;
  &.hd {
    background-color: #ccc;
  }
  .action {
    width: 40px;
    text-align: center;
    line-height: 28px;
    white-space: nowrap;
  }
  .times {
    width: 15%;
    text-align: center;
    white-space: nowrap;
  }
  .time {
    width: 100px;
    white-space: nowrap;
  }
  .title {
    width: 220px;
    white-space: normal;
  }
  &:nth-child(even) {
    background-color: #ffffff;
  }
  &:nth-child(odd) {
    background-color: #f7f7f7;
  }
  &:first-child{
    background-color: #ccc;
  }
}
.pp-followup-list {
  background-color: #f1f1f1;
}
.pp-followup-item {
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  background-clip: #e1e5e9;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #224fe3;
  }
}
.quest-item-list-box {
  flex: 1 1 auto;
}
</style>
