<template>
  <div class="dict-item-list-container" :style="{width: width+'px'}">
    <div class="dict-item-list-hd">
      <button @click="onAddItem">添加</button>
    </div>
    <div class="dict-item-list">
      <div class="dict-item-list-item-hd">
        <div class="name">名称</div>
        <div class="code">编码</div>
        <div class="action">操作</div>
      </div>
      <div class="dict-item-list-item" v-for="(item, index) in itemList" :key="index">
        <div class="name" :title="item.name">{{item.name}}</div>
        <div class="code">{{item.code}}</div>
        <div class="action">
          <span class="ionic-icon ionic-icon-close-round" @click.stop="remove(item)"></span>
          <span class="ionic-icon ionic-icon-edit" @click.stop="edit(item, index)"></span>
          <span class="ionic-icon ionic-icon-arrow-up-c" @click.stop="up(index)" v-if="index > 0"></span>
          <span class="ionic-icon ionic-icon-arrow-down-c" @click.stop="down(index)" v-if="index < itemList.length - 1"></span>
        </div>
      </div>
    </div>
    <dict-add-win
      v-model="currentItem"
      :show="showItemEditWin"
      title="编辑数据"
      @on-hide="showItemEditWin=false"
      @on-confirm-ok="onItemUpdate"></dict-add-win>
    <dict-add-win
      :show="showItemAddWin"
      title="添加数据"
      @on-hide="showItemAddWin=false"
      @on-confirm-ok="onItemAdd"></dict-add-win>
  </div>
</template>

<script>
import DictAddWin from '../win/dict-add-win'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: {DictAddWin},
  props: {
    data: {
      type: Array,
      default () { return [] }
    },
    dict: {},
    projectId: String // 提交用域冗余数据
  },
  data () {
    return {
      itemList: this.data || [],
      currentItem: {},
      currentItemIndx: 0,

      showItemEditWin: false,
      showItemAddWin: false
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.itemList = val
      }
    }
  },
  computed: {
    width () {
      return this.$store.state.win.winWidth - this.$store.state.win.subNavWidth - 300
    }
  },
  methods: {
    onItemUpdate (val) {
      this.itemList.splice(this.currentItemIndx, 1, val)
      this.updateDictItems()
    },
    onAddItem () {
      this.showItemAddWin = true
    },
    onItemAdd (val) {
      this.itemList.push(val)
      this.updateDictItems()
    },
    remove (item) {
      this.$Modal.confirm({
        content: '确认删除该数据项？',
        onOk: () => {
          const index = this.itemList.indexOf(item)
          if (index > -1) {
            this.itemList.splice(index, 1)
          }
          this.updateDictItems()
        }
      })
    },
    edit (item, index) {
      this.currentItem = JSON.parse(JSON.stringify(item))
      this.currentItemIndx = index
      this.showItemEditWin = true
    },
    up (index) {
      if (index > 0) {
        let cur = this.itemList[index]
        this.itemList.splice(index, 1)
        this.itemList.splice(index - 1, 0, cur)
        this.updateDictItems()
      }
    },
    down (index) {
      if (index < this.itemList.length - 1) {
        let cur = this.itemList[index]
        this.itemList.splice(index, 1)
        this.itemList.splice(index + 1, 0, cur)
        this.updateDictItems()
      }
    },
    updateDictItems () {
      this.$http.post(`${this.httpRoot}/dict/item/update`, {
        projectId: this.projectId,
        dictId: this.dict.id,
        dictCode: this.dict.code,
        items: this.itemList
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$Message.success('更新字典数据成功')
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.$Message.error('更新字典数据失败')
      })
    }
  }
}
</script>

<style lang="less">
.dict-item-list-container {
  height: 100%;
  float: left;
  overflow-y: scroll;
}
.dict-item-list-hd {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  button {
    outline: 0;
    background: #fff;
    border: 1px solid #888;
    border-radius: 4px;
    padding: 2px 7px;
    cursor: pointer;
    &:hover {
      border-color: #3399ff;
    }
  }
}
.dict-item-list {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    left: 15px;
    right: 0;
    height: 1px;
    background-color: #ccc;
  }
}
.dict-item-list-item-hd {
  height: 28px;
  line-height: 28px;
  background: #f7f7ff;
  .name {
    width: 350px;
    padding-left: 10px;
    float: left;
  }
  .code {
    width: 350px;
    padding-left: 10px;
    float: left;
  }
  .action {
    padding-left: 40px;
    float: left;
  }
}
.dict-item-list-item {
  height: 28px;
  line-height: 28px;
  .name {
    width: 350px;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 10px;
    float: left;
  }
  .code {
    width: 350px;
    padding-left: 10px;
    float: left;
  }
  .action {
    padding-left: 40px;
    height: 28px;
    line-height: 28px;
    float: left;
  }
  .ionic-icon {
    margin: 0 10px;
    cursor: pointer;
    &:hover {
      color: #3399ff;
    }
  }
  &:nth-child(even) {
    background: #E4DDDC;
  }
  &:nth-child(odd) {
    background: #f7f7f7;
  }
}
</style>
