/*
 * 在 Vue.prototype 上构建一个单实例 $LoadingBar, 供 Vue 所有组件实例使用
 * 在 Vue 组件中调用 this.$LoadingBar.config(options)/start()/finish()/error()
 * 初始化方式: 在 Vue 单页面根组件初始化之前 import 该 js
 */
import Vue from 'vue'
import LoadingBar from './loading-bar.vue'

let loadingBarInstance

let options = {
  color: 'primary',
  failedColor: 'error',
  height: 2
}

function newInstance (options) {
  const _props = Object.assign({}, options, {fixedTop: true})

  const loadingBar = new Vue({
    render: (h) => {
      return h('div', {
        attrs: {
          id: 'single_loading_bar'
        }
      }, [
        h('loading-bar', {
          props: _props
        })
      ])
    },
    components: { LoadingBar }
  }).$mount().$children[0]

  document.body.appendChild(loadingBar.$el)

  return loadingBar
}

function getLoadingBarInstance () {
  loadingBarInstance = loadingBarInstance || newInstance()
  return loadingBarInstance
}

export default {
  start () {
    getLoadingBarInstance().start()
  },
  update (percent) {
    getLoadingBarInstance().update(percent, 'success', true)
  },
  finish () {
    getLoadingBarInstance().finish()
  },
  error () {
    getLoadingBarInstance().error()
  },
  config (config) {
    if (config.color) options.color = config.color
    if (config.failedColor) options.failedColor = config.failedColor
    if (config.height) options.height = config.height
  },
  destroy () {
    let instance = getLoadingBarInstance()
    loadingBarInstance = null
    // document.body.removeChild(document.querySelector('#single_loading_bar'))
    instance.$destroy()
    document.body.removeChild(instance.$el)
  }
}
