<template>
  <div class="license">
    <div class="item">
      <span class="label">注册码</span>
      <y-input style="width: 500px;" v-model="signature"></y-input>
    </div>
    <div class="item">
      <span class="label">过期时间</span>
      <date-picker style="width: 250px; display:inline-block;" type="date" v-model="expireTo" placeholder="选择时间"></date-picker>
    </div>
    <div class="item">
      <span class="label"></span>
      <y-button type="primary" @click.native.stop="generateLicense">生成License</y-button>
    </div>
  </div>
</template>

<script>
import YInput from '../common/input/input'
import YButton from '../common/button/button'
import DatePicker from '../common/datepicker/picker/date-picker'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {YInput, YButton, DatePicker},
  data () {
    return {
      signature: '',
      expireTo: ''
    }
  },
  methods: {
    generateLicense () {
      this.$http.post(`${this.httpRoot}/auth/license/generate`, {
        signature: this.signature, expireTo: this.expireTo
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'license' + new Date().format('yyyyMMddhhmmss')
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="less">
.license {
  padding: 15px;
  .item {
    margin: 10px 0;
  }
  .label {
    display: inline-block;
    width: 80px;
    text-align: right;
    padding-right: 5px;
  }
}
</style>
