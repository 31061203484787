<template>
  <modal v-model="showModal" width="250" @on-ok="onConfirm" :closable="false">
    <y-checkbox v-model="isCheck">
      <poptip trigger="hover" content="删除容器后，样本和容器都不可恢复">是否永久删除容器</poptip>
    </y-checkbox>
  </modal>
</template>

<script>
import Modal from '../../modal/modal.vue'
import YCheckbox from '../../checkbox/checkbox'
import Poptip from '../../poptip/poptip'
export default {
  components: { Modal, YCheckbox, Poptip },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      isCheck: this.value || false
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    isCheck (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    onConfirm () {
      this.$emit('on-delete-ok')
    }
  }
}
</script>

<style lang="css">
</style>
