import Node from './tree-node'

export default class TreeStore {
  constructor (options) {
    this.label = ''
    this.data = null
    this.props = {}

    this.showCheckbox = false
    this.showField = ''
    this.multiple = false  // 是否支持多 focus 或者 check
    this.currentNode = null

    for (let option in options) {
      if (options.hasOwnProperty(option)) {
        this[option] = options[option]
      }
    }

    this.nodesMap = {}

    this.rootNode = new Node({
      type: 'root',
      data: {
        [this.props.label || 'label']: this.label || 'root-label',
        [this.props.value || 'value']: 'root-value',
        children: JSON.parse(JSON.stringify(this.data || []))
      },
      store: this
    })
  }

  registerNode (node) {
    if (!node || !node.data) return
    if (node.key) this.nodesMap[node.key] = node
  }

  deregisterNode (node) {
    if (!node || !node.data) return
    delete this.nodesMap[node.key]
  }

  getNodeByKey (key) {
    return this.nodesMap[key]
  }

  clearFocus () {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      node.focus(false)
    })
  }

  clearCheck () {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      node.check(false)
    })
  }

  focusNodeByDataValue (value) {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.value === value) {
        node.focus(true)
      }
    })
  }

  getNodeDataByDataValue (value) {
    let data = null
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.value === value) {
        data = JSON.parse(JSON.stringify(node.data))
        delete data['children']
      }
    })
    return data
  }
}
