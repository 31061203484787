<template>
  <div class="fieldset-container" :style="{width: width}">
    <fieldset :name="name" class="fieldset">
      <legend>{{name}}</legend>
      <slot></slot>
    </fieldset>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    width: String
  }
}
</script>

<style lang="less">
.fieldset-container {
  float: left;
}
.fieldset {
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 10px;
  border: 0;
  background: #fff;
  legend {
    font-size: 12px;
    padding-left: 15px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    width: 100%;
    border-radius: 4px;
  }
}
.main_container {
  .gap-line {
    position: relative;
    .line {
      position: absolute;
      top: 10px;
      bottom: 20px;
      left: 0;
      border: 1px dashed #ddd;
    }
  }
}
</style>
