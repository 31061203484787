import { newInstance } from '../base/getNotice'

const prefixCls = 'notice'
const iconPrefixCls = 'ionic-icon'
const prefixKey = 'notice_key_'

let top = 24
let defaultDuration = 4.5
let noticeInstance
let name = 1

const iconTypes = {
  'info': 'information-circled',
  'success': 'checkmark-circled',
  'warning': 'android-alert',
  'error': 'close-circled'
}

function getNoticeInstance () {
  noticeInstance = noticeInstance || newInstance({
    prefixCls: prefixCls,
    styles: {
      top: `${top}px`,
      right: 0
    }
  })

  return noticeInstance
}

function notice (type, options) {
  const title = options.title || ''
  const desc = options.desc || ''
  const noticeKey = options.name || `${prefixKey}${name}`
  const onClose = options.onClose || function () {}
  // todo const btn = options.btn || null
  const duration = (options.duration === 0) ? 0 : options.duration || defaultDuration

  name++

  let instance = getNoticeInstance()

  let content

  const withDesc = desc === '' ? '' : ` ${prefixCls}-with-desc`

  if (type === 'normal') {
    content = `
      <div class="${prefixCls}-custom-content ${prefixCls}-with-normal${withDesc}">
        <div class="${prefixCls}-title">${title}</div>
        <div class="${prefixCls}-desc">${desc}</div>
      </div>
    `
  } else {
    const iconType = iconTypes[type]
    content = `
      <div class="${prefixCls}-custom-content ${prefixCls}-with-icon ${prefixCls}-with-${type}${withDesc}">
        <span class="${prefixCls}-icon ${prefixCls}-icon-${type}">
          <i class="${iconPrefixCls} ${iconPrefixCls}-${iconType}"></i>
        </span>
        <div class="${prefixCls}-title">${title}</div>
        <div class="${prefixCls}-desc">${desc}</div>
      </div>
    `
  }

  instance.notice({
    name: noticeKey.toString(),
    duration: duration,
    styles: {},
    transitionName: 'move-notice',
    content: content,
    onClose: onClose,
    closable: true
  })
}

export default {
  open (options) {
    return notice('normal', options)
  },
  info (options) {
    return notice('info', options)
  },
  success (options) {
    return notice('success', options)
  },
  warning (options) {
    return notice('warning', options)
  },
  error (options) {
    return notice('error', options)
  },
  config (options) {
    if (options.top) {
      top = options.top
    }
    if (options.duration || options.duration === 0) {
      defaultDuration = options.duration
    }
  },
  close (name) {
    if (name) {
      name = name.toString()
      if (noticeInstance) {
        noticeInstance.remove(name)
      }
    } else {
      return false
    }
  },
  destroy () {
    let instance = getNoticeInstance()
    noticeInstance = null
    instance.destroy()
  }
}
