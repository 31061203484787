<template>
  <div class="filter-list">
    <div class="filter-item" v-for="(item, index) in currentValue" :key="index">
      <span class="filter-label">key:</span>
      <input class="filter-val" :value="item.key" @change="onKeyChange(index, $event)">
      <span class="filter-label">value:</span>
      <input class="filter-val" :value="item.value" @change="onValueChange(index, $event)">
      <span class="filter-label">op:</span>
      <select class="filter-val" :value="item.op" @change="onOpChange(index, $event)">
        <option>eq</option>
        <option>neq</option>
        <option>contain</option>
        <option>gte</option>
        <option>gt</option>
        <option>lte</option>
        <option>lt</option>
      </select>
      <span class="filter-label">enable:</span>
      <input type="checkbox" class="filter-check" v-model="item.enable" @change="onEnableChange(index, $event)"/>
      <span class="ionic-icon ionic-icon-close-round" @click="remove(index)"></span>
    </div>
    <div class="filter-add">
      <button type="button" @click.stop="addItem">添加</button>
    </div>
  </div>
</template>

<script>
import Emitter from '../../mixins/emitter'
export default {
  mixins: [Emitter],
  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    valtype: {
      type: String,
      default: 'object'  // 'array'
    }
  },
  data () {
    return {
      currentValue: this.value || []
    }
  },
  watch: {
    value (val, oldVal) {
      if (val && JSON.stringify(oldVal) !== JSON.stringify(val)) {
        this.currentValue = val
      }
    },
    currentValue (val) {
      this.$emit('input', val)
      this.dispatch('FormItem', 'on-form-change', val)
      this.$emit('on-change', val)
    }
  },
  methods: {
    onKeyChange (index, e) {
      let val = {
        key: e.target.value,
        value: this.currentValue[index] ? this.currentValue[index].value : '',
        op: this.currentValue[index] ? this.currentValue[index].op : '',
        enable: this.currentValue[index] ? this.currentValue[index].enable : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onValueChange (index, e) {
      let val = {
        key: this.currentValue[index] ? this.currentValue[index].key : '',
        value: e.target.value,
        op: this.currentValue[index] ? this.currentValue[index].op : '',
        enable: this.currentValue[index] ? this.currentValue[index].enable : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onOpChange (index, e) {
      let val = {
        key: this.currentValue[index] ? this.currentValue[index].key : '',
        value: this.currentValue[index] ? this.currentValue[index].value : '',
        op: e.target.value,
        enable: this.currentValue[index] ? this.currentValue[index].enable : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onEnableChange (index, e) {
      let val = {
        key: this.currentValue[index] ? this.currentValue[index].key : '',
        value: this.currentValue[index] ? this.currentValue[index].value : '',
        op: this.currentValue[index] ? this.currentValue[index].op : '',
        enable: e.target.checked
      }
      this.$set(this.currentValue, index, val)
    },
    remove (index) {
      this.currentValue.splice(index, 1)
    },
    addItem () {
      this.currentValue.push({key: '', value: ''})
    }
  }
}
</script>

<style lang="less">
.filter-list {
  flex: 1 1 auto;
}
.filter-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  .filter-val {
    flex: 1 1 auto;
    padding-left: 7px;
    outline: 0;
    border: 0;
    border-bottom: 1px solid #888;
  }
  .filter-check {
    margin-left: 5px;
    margin-right: 20px;
  }
  .ionic-icon {
    margin-left: 5px;
    margin-right: 15px;
    cursor: pointer;
    &:hover {
      color: #3399ff;
    }
  }
}
.filter-add {
  height: 28px;
  line-height: 28px;
  button {
    cursor: pointer;
    font-size: 12px;
    outline: 0;
    padding: 1px 7px;
    background: #fff;
    border: 1px solid #888;
    border-radius: 2px;
  }
}
</style>
