<template>
  <modal v-model="showModal" title="数据值" width="800"  @on-ok="onConfirm">
    <div class="m-form">
      <template v-for="col in columns">
        <div class="m-form-item" :key="col.key" v-if="col.key !== 'action' && col.key !== '#'">
          <div class="m-form-label">{{col.key}}</div>
          <div class="m-form-value">
            <textarea v-if="typeof data[col.key] === 'object'" :value="JSON.stringify(data[col.key], null, 4)" @change="onChange(col.key, $event)"></textarea>
            <input v-else :value="data[col.key]" @change="onChange(col.key, $event)"/>
          </div>
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
import Modal from '../common/modal/modal.vue'

export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default () { return [] }
    },
    data: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      showModal: this.show,
      keyType: {},
      hasError: false
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
        this.hasError = false
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    data (val) {
      this.keyType = this.getKeyType(val)
    }
  },
  methods: {
    getKeyType (val = {}) {
      const map = {}
      for (const key in val) {
        map[key] = typeof val[key]
      }
      return map
    },
    onChange (key, event) {
      let value = event.target.value.trim()
      if (this.keyType[key] === 'number') {
        value = Number(value) || null
      } else if (this.keyType[key] === 'boolean') {
        value = Boolean(value)
      } else if (this.keyType[key] === 'object') {
        try {
          value = JSON.parse(value)
        } catch (err) {
          this.hasError = true
          this.$Modal.error({
            title: 'json 异常',
            content: err.message
          })
        }
      }
      // else {
      //   // 默认string, 不处理
      // }
      this.data[key] = value
    },
    onConfirm () {
      // data 引用一致关联到table row 对象
      if (!this.hasError) {
        this.$emit('on-ok', this.data)
      }
    }
  }
}
</script>

<style lang="less">
.m-form-item {
  margin-bottom: 16px;
  display: flex;
  align-items: top;
}
.m-form-label {
  min-width: 120px;
  text-align: right;
  padding-right: 10px;
}
.m-form-value {
  min-width: 650px;
  word-break: break-all;
  textarea {
    width: 644px;
    height: 420px;
    outline: 0;
  }
  input {
    width: 644px;
    height: 28px;
    outline: 0;
    padding-left: 6px;
  }
}
</style>
