<template>
  <div class="select" :class="{'select-visible': visible, 'select-disabled': disabled,
      'select-multiple': multiple, 'select-single': !multiple, 'select-show-clear': showCloseIcon,
      [`select-${size}`]: !!size}" v-clickoutside="handleClose">
    <div ref="reference" class="select-selection" @click="toggleOptionsShow">
      <div class="tag" v-for="(item, index) in selectedMultiple" :key="index">
        <span class="tag-text">{{ item.label }}</span>
        <Icon type="ios-close-empty" @click.native.stop="removeTag(index)"></Icon>
      </div>
      <span class="select-placeholder" v-show="showPlaceholder && !filterable">{{ placeholder }}</span>
      <span class="select-selected-value" v-show="!showPlaceholder && !multiple && !filterable">{{ selectedSingle }}</span>
      <input
        type="text"
        v-if="filterable"
        v-model="query"
        class="select-input"
        :placeholder="showPlaceholder ? placeholder : ''"
        :style="inputStyle"
        @blur="handleBlur"
        @keydown="resetInputState"
        @keydown.delete="handleInputDelete"
        ref="input">
      <Icon type="ios-close" class="select-arrow" v-show="showCloseIcon" @click.native.stop="clearSingleSelect"></Icon>
      <Icon type="arrow-down-b" class="select-arrow" v-show="!remote || (remote && !loading)"></Icon>
      <Icon type="load-c" class="select-loading" v-show="remote && loading"></Icon>
    </div>
    <transition name="slide-up">
      <Drop v-show="(visible && currentData && currentData.length) || (visible && !currentData) || (visible && currentData && !currentData.length && notFound)" ref="dropdown">
        <ul v-show="notFound" class="select-not-found">
          <li>{{ notFoundText }}</li>
        </ul>
        <ul v-show="!notFound" class="select-dropdown-list" ref="options">
          <template v-if="currentData && currentData.length && currentData[0].children">
            <option-group  v-for="(item, index) in currentData" :label="item.name || item.label" :key="index">
              <y-option v-for="(child, idx) in item.children" :data="child" :id="item.id || idx"
                :key="idx"
                :remote="remote"
                :labelField="labelField" :valueField="valueField" :showField="showField"
                :showValue="showValue" :showIcon="showIcon" :showColor="showColor" :showIconUrl="showIconUrl">
              </y-option>
            </option-group>
          </template>
          <template v-else>
            <y-option v-for="(item, _idx) in currentData" :data="item" :id="item.id || _idx"
              :key="_idx"
              :remote="remote"
              :labelField="labelField" :valueField="valueField" :showField="showField"
              :showValue="showValue" :showIcon="showIcon" :showColor="showColor" :showIconUrl="showIconUrl">
            </y-option>
          </template>
        </ul>
      </Drop>
    </transition>
  </div>
</template>

<script>
import Icon from '../icon/icon'
import Drop from './dropdown.vue'
import OptionGroup from './option-group'
import YOption from './option'
import clickoutside from '../directives/clickoutside'
import { oneOf } from '../../../utils/assist'
import Emitter from '../mixins/emitter'
import dataMixin from './mixin'

export default {
  name: 'Select',
  mixins: [ Emitter, dataMixin ],
  components: { Icon, Drop, OptionGroup, YOption },
  directives: { clickoutside },
  props: {
    value: {
      type: [String, Number, Array, Boolean],
      default: ''
    },
    id: String,
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    filterable: {
      type: Boolean,
      default: false
    },
    filterMethod: {
      type: Function
    },
    size: {
      validator (value) {
        return oneOf(value, ['small', 'large', 'default'])
      }
    },
    attrInValue: {
      type: Boolean,
      default: false
    },
    labelInValue: {
      type: Boolean,
      default: false
    },
    labelField: String,
    valueField: String,
    showField: String,
    notFoundText: {
      type: String,
      default: '无匹配的数据'
    },
    data: Array,
    remote: {
      type: Boolean,
      default: false
    },
    loadMethod: Function,
    showValue: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    showColor: {
      type: Boolean,
      default: false
    },
    showIconUrl: {
      type: Boolean,
      default: false
    },
    toggleShow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visible: false,
      options: [], // option children
      selectedSingle: '',    // label
      selectedMultiple: [],
      focusIndex: 0,
      query: '',
      inputLength: 20,
      notFound: false,
      model: this.regModelValue(this.value), // value 字符串 或者 value 字符串数组
      currentData: this.data || [],
      loading: false,
      queryChangeByModel: false
    }
  },
  computed: {
    showPlaceholder () {
      if (typeof this.model === 'string' && this.model === '') {
        return true
      } else if (Array.isArray(this.model) && !this.model.length) {
        return true
      }
      return false
    },
    showCloseIcon () {
      return !this.multiple && this.clearable && !this.showPlaceholder
    },
    inputStyle () {
      let style = {}
      if (this.multiple) {
        if (this.showPlaceholder) {
          style.width = '100%'
        } else {
          style.width = `${this.inputLength}px`
        }
      }
      return style
    }
  },
  watch: {
    value (val) {
      this.model = this.regModelValue(val)
    },
    model (val) {
      this.$emit('input', val)
      this.updateQueryByModel()
      if (this.multiple) {
        this.updateMultipleSelectedByModel()
      } else {
        this.updateSingleSelectedByModel()
      }
    },
    visible (val) {
      if (val) {
        if (this.multiple && this.filterable) {
          this.$refs.input.focus()
        }
        this.broadcast('Dropdown', 'on-update-popper')
      } else {
        if (this.filterable) {
          this.$refs.input.blur()
        }
        this.broadcast('Dropdown', 'on-destroy-popper')
      }
    },
    query (val) {
      if (this.remote && this.loadMethod && !this.queryChangeByModel) {
        this.loading = true
        this.loadMethod(val, (data) => {
          this.loading = false
          this.currentData = this.mergeCurrentData(data)
          this.$nextTick(() => {
            this.emitQueryChange(val)
          })
        })
      } else {
        this.emitQueryChange(val)
      }
      if (this.queryChangeByModel) this.queryChangeByModel = false
    },
    data (val) {
      if (val) {
        this.currentData = val
      } else {
        this.currentData = []
      }
      this.$nextTick(() => {
        this.updateQueryByModel()
        if (this.multiple) {
          // fix data change, options has not updated
          setTimeout(() => {
            this.updateMultipleSelectedByModel()
          }, 50)
        } else {
          this.updateSingleSelectedByModel()
        }
      })
    },
    currentData (val) {
      this.$nextTick(() => {
        this.updateOptions()
        if (val && val.length) {
          this.notFound = false
        } else {
          this.notFound = true
        }
      })
    }
  },
  created () {
    this.$on('on-option-selected', (value) => {
      // for update model then update query and singleSelected or multiSelected
      if (this.model === value) {
        this.hideOptions()
      } else if (this.multiple && !this.model) {
        // 修复 this.model = '' 初始无法选中并加入
        this.model = []
        this.model.push(value)
        this.broadcast('Dropdown', 'on-update-popper') // 多选导致框的尺寸变化，更新 options 的绝对定位
      } else if (this.multiple && this.model) {
        const index = this.model.indexOf(value)
        if (index >= 0) {
          this.removeTag(index) // 删除 model 中的 value 更新 multiSelected
        } else {
          if (!Array.isArray(this.model)) this.model = []
          this.model.push(value)
          this.broadcast('Dropdown', 'on-update-popper') // 多选导致框的尺寸变化，更新 options 的绝对定位
        }
        if (this.filterable) {
          this.$refs.input.focus()
        }
      } else {
        this.model = value
      }
    })
  },
  mounted () {
    this.updateOptions()
    this.updateQueryByModel(true)
    this.updateSingleSelectedByModel()
    this.updateMultipleSelectedByModel()
    document.addEventListener('keydown', this.handleKeydown)
  },
  methods: {
    toggleOptionsShow () {
      if (this.disabled) {
        return false
      }
      // this.visible = !this.visible
      if (!this.toggleShow) {
        if (!this.visible) this.visible = true
      } else {
        this.visible = !this.visible
      }
    },
    hideOptions () {
      this.visible = false
      this.focusIndex = 0
      this.broadcast('Option', 'on-select-close')
    },
    // find option component
    getOptions (filter) {
      const $Options = []
      const dfs = function (children) {
        children.forEach((child) => {
          if (child.$options.name === 'Option') {
            if (typeof filter === 'function' && filter(child)) {
              $Options.push(child)
            } else if (typeof filter === 'undefined') {
              $Options.push(child)
            }
          }
          if (child.$children && child.$children.length) {
            dfs(child.$children)
          }
        })
      }
      dfs(this.$children)
      return $Options
    },
    updateOptions () {
      this.options = this.getOptions()
    },
    /* 针对单选 (1)初始化加载, model由父组件初始化值 (2)用户选择导致model发生改变 针对多选置空 */
    updateQueryByModel (inital) {
      if (!inital) this.queryChangeByModel = true

      const model = this.model
      const options = this.getOptions((option) => {
        if (typeof model === 'string' || typeof model === 'number') {
          if (option.value === model) {
            return true
          }
        }
      })
      if (options && options.length && !this.multiple && this.filterable) {
        const option = options[0]
        if (option.label) {
          this.query = option.label
        } else if (option.searchLabel) {
          this.query = option.searchLabel
        } else {
          this.query = option.value
        }
      } else {
        this.query = ''
      }
    },
    updateSingleSelectedByModel () {
      const model = this.model
      console.log('model: ', this.model)
      if (typeof model === 'undefined') {
        this.selectedSingle = ''
      } else if (typeof model === 'string' || typeof model === 'number' ||
          (typeof model === 'boolean' && !this.multiple)) {
        let found = false
        this.getOptions(option => {
          if (model === option.value) {
            found = true
            this.selectedSingle = option.label
          }
        })
        if (!found) this.selectedSingle = ''
      }
      this.toggleSingleSelected(this.model)
    },
    clearSingleSelect () {
      if (this.showCloseIcon) {
        this.getOptions(option => {
          option.selected = false
        })
        this.model = '' // query 会由于 model 监听而清空
      }
    },
    updateMultipleSelectedByModel () {
      // model is undefined -> ''
      // console.log('multi model, options', this.model, this.options)
      if (this.multiple && !this.model) {
        this.selectedMultiple = []
        return
      } else if (!this.multiple || !Array.isArray(this.model)) {
        return
      }
      let selected = []
      this.model.forEach((item) => {
        this.options.forEach((option) => {
          if (item === option.value) {
            selected.push({
              value: option.value,
              label: option.label
            })
          }
        })
      })
      this.selectedMultiple = selected
      this.toggleMultipleSelected(this.model)
    },
    removeTag (index) {
      if (this.disabled) return
      this.model.splice(index, 1)

      if (this.filterable && this.visible) {
        this.$refs.input.focus()
      }
      this.broadcast('Dropdown', 'on-update-popper')
    },
    // to select option for single
    toggleSingleSelected (value) {
      if (this.multiple) return
      let label = ''
      let selectedOption = {}
      this.getOptions(option => {
        if (option.value === value) {
          option.selected = true
          label = option.label || option.$el.innerHTML
        } else {
          option.selected = false
        }
      })
      this.currentData.forEach(item => {
        if (this.getValueField(item) === value) {
          selectedOption = item
        }
      })
      this.hideOptions()
      if (this.labelInValue) {
        this.$emit('on-select-change', {value, label}, this.id)
        this.dispatch('FormItem', 'on-form-change', {value, label})
      } else if (this.attrInValue) {
        this.$emit('on-select-change', selectedOption, this.id)
        this.dispatch('FormItem', 'on-form-change', selectedOption)
      } else {
        this.$emit('on-select-change', value, this.id)
        this.dispatch('FormItem', 'on-form-change', value)
      }
    },
    // to select option for multiple
    toggleMultipleSelected (values) {
      if (!this.multiple) return
      values = values || [] // undefined model
      let _values = []
      let selectedOptions = []
      this.getOptions(option => {
        if (values.indexOf(option.value) >= 0) {
          option.selected = true
          _values.push({
            value: option.value,
            label: option.label || option.$el.innerHTML
          })
        } else {
          option.selected = false
        }
      })
      this.currentData.forEach(item => {
        values.forEach(value => {
          if (this.getValueField(item) === value) {
            selectedOptions.push(item)
          }
        })
      })
      if (this.labelInValue) {
        this.$emit('on-select-change', _values, this.id)
        this.dispatch('FormItem', 'on-form-change', _values)
      } else if (this.attrInValue) {
        this.$emit('on-select-change', selectedOptions, this.id)
        this.dispatch('FormItem', 'on-form-change', selectedOptions)
      } else {
        this.$emit('on-select-change', values, this.id)
        this.dispatch('FormItem', 'on-form-change', values)
      }
    },
    handleClose () {
      this.hideOptions()
    },
    handleKeydown (e) {
      if (!this.visible) return

      const keyCode = e.keyCode
      // Esc slide-up
      if (keyCode === 27) {
        e.preventDefault()
        this.hideOptions()
      }
      // next
      if (keyCode === 40) {
        e.preventDefault()
        this.navigateOptions('next')
      }
      // prev
      if (keyCode === 38) {
        e.preventDefault()
        this.navigateOptions('prev')
      }
      // enter
      if (keyCode === 13) {
        e.preventDefault()
        this.options.forEach(option => {
          if (option.isFocus) {
            option.select()
            return true
          }
        })
      }
    },
    navigateOptions (direction) {
      if (direction === 'next') {
        const next = this.focusIndex + 1
        this.focusIndex = (this.focusIndex === this.options.length) ? 1 : next
      } else if (direction === 'prev') {
        const prev = this.focusIndex - 1
        this.focusIndex = (this.focusIndex <= 1) ? this.options.length : prev
      }

      let childStatus = {
        disabled: false,
        hidden: false
      }

      let findDeep = false    // can next find allowed
      this.getOptions(option => {
        if (option.index === this.focusIndex) {
          childStatus.disabled = option.disabled
          childStatus.hidden = option.hidden
          if (!option.disabled && !option.hidden) {
            option.isFocus = true
          }
        } else {
          option.isFocus = false
        }
        if (!option.disabled && !option.hidden) {
          findDeep = true
        }
      })

      this.resetScrollTop()

      if ((childStatus.disabled || childStatus.hidden) && findDeep) {
        this.navigateOptions(direction)
      }
    },
    resetScrollTop () {
      const index = this.focusIndex - 1
      let bottomOverflowDistance = this.options[index].$el.getBoundingClientRect().bottom - this.$refs.dropdown.$el.getBoundingClientRect().bottom
      let topOverflowDistance = this.options[index].$el.getBoundingClientRect().top - this.$refs.dropdown.$el.getBoundingClientRect().top

      if (bottomOverflowDistance > 0) {
        this.$refs.dropdown.$el.scrollTop += bottomOverflowDistance
      }
      if (topOverflowDistance < 0) {
        this.$refs.dropdown.$el.scrollTop += topOverflowDistance
      }
    },
    handleBlur () {
      // 删除 multi 中的 query, 更正 single 中的 query 为 model 对应的 label
      setTimeout(() => {
        const model = this.model

        if (this.multiple) {
          this.query = ''
        } else {
          if (model !== '') {
            this.options.forEach(option => {
              if (option.value === model) {
                this.query = option.label || option.searchLabel
              }
            })
          } else {
            this.query = ''
          }
        }
      }, 300)
      this.dispatch('FormItem', 'on-form-blur', this.value)
    },
    resetInputState () {
      this.inputLength = this.$refs.input.value.length * 12 + 20
    },
    handleInputDelete () {
      if (this.multiple && this.model && this.model.length && this.query === '') {
        this.removeTag(this.model.length - 1)
      }
    },
    mergeCurrentData (data) {
      if (this.isArrayHasChild(data)) return this.currentData
      let newData = []
      this.getOptions((option) => {
        if (option.selected) {
          // newData.push({label: option.label, value: option.value})
          newData.push(JSON.parse(JSON.stringify(option.data)))
          return true
        }
      })
      data.forEach(item => {
        let hasFound = false
        newData.forEach((ndata) => {
          if (this.getValueField(ndata) === this.getValueField(item)) hasFound = true
        })
        if (!hasFound) {
          newData.push(JSON.parse(JSON.stringify(item)))
        }
      })
      return newData
    },
    isArrayHasChild (data) {
      if (Array.isArray(data) && data.length) {
        if (data[0].children) {
          return true
        }
        return false
      }
      return false
    },
    setQuery (query) {
      if (!this.filterable) return
      this.query = query
    },
    emitQueryChange (query) {
      this.broadcast('OptionGroup', 'on-query-change', query)
      this.broadcast('Option', 'on-query-change', query)

      this.$nextTick(() => {
        const options = this.getOptions((option) => {
          if (!option.hidden) return true
        })
        if (options && options.length) {
          this.notFound = false
        } else {
          this.notFound = true
        }
      })
      // 多选导致框的尺寸变化，更新 options 的绝对定位
      this.broadcast('Dropdown', 'on-update-popper')
    },
    regModelValue (val) {
      let value = val || ''
      if (typeof value === 'string' && value !== '' && this.multiple) {
        return [value]
      } else if (typeof value === 'object' && !this.multiple) {
        return JSON.stringify(value)
      } else {
        return value
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.handleKeydown)
  }
}
</script>
