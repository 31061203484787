<template>
  <div class="type-item">
    <span class="icon"><img :src="item.iconUrl"></span>
    <span class="title">{{item.name}}</span>
    <span class="code" v-if="belong==='sys'">{{item.code}}</span>
    <span class="edit">
      <span class="ionic-icon ionic-icon-gear-b" v-if="belong==='user'" @click.stop="onCustomFieldClick"></span>
      <span class="ionic-icon ionic-icon-edit" v-if="belong==='user'" @click.stop="onEditClick"></span>
      <span class="ionic-icon ionic-icon-close" v-if="!item.isSys" @click.stop="onDeleteClick"></span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
    belong: {
      type: String,
      default: 'user' // sys
    }
  },
  methods: {
    onCustomFieldClick () {
      this.$emit('on-custom-field-edit', this.item)
    },
    onEditClick () {
      this.$emit('on-edit', this.item)
    },
    onDeleteClick () {
      this.$Modal.confirm({
        title: '样本类型删除',
        content: '确定删除该样本类型？',
        onOk: () => {
          this.$emit('on-delete', this.item)
        }
      })
    }
  }
}
</script>

<style lang="css">
</style>
