<template>
  <div class="my_samples">
    <container-manager :freezerId="freezerId" :projectId="projectId" async storeContainers ref="containerManager"></container-manager>
  </div>
</template>

<script>
import ContainerManager from '../common/container/container-manager'
export default {
  components: { ContainerManager },
  data () {
    return {
      freezerId: undefined
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.freezerId = to.query.id
    })
  },
  watch: {
    projectId (val) {
      if (val) {
        this.$store.dispatch('clearContainerCache')
        this.freezerId = ''
        // this.$refs.containerManager.freezerList = [], container.data 会侦听到变化提前加载一次
        this.$refs.containerManager.containerPath = []
        this.$refs.containerManager.currentView = 'defaultPanel'
        this.$nextTick(() => {
          // 让 projectId 更新到子组件后再加载
          this.$refs.containerManager.loadFreezerList()
        })
      }
    }
  }
}
</script>
