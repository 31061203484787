<template>
  <div class="panel-crf-table">
    <div class="panel-edit-title">(crfTable) CRF表编辑</div>
    <y-form ref="currentDataForm" :model="currentData" :rules="currentDataRule" label-width="100px" item-width="250px">
      <form-item label="唯一编码" prop="modelId">
        <y-input v-model="currentData.modelId" disabled></y-input>
      </form-item>
      <form-item label="显示名称" prop="title">
        <y-input v-model="currentData.title"></y-input>
      </form-item>
      <form-item label="集合名称">
        <y-input v-model="currentData.collection" disabled></y-input>
      </form-item>
      <form-item label="角色">
        <y-select v-model="currentData.role" :data="roleList" multiple></y-select>
      </form-item>
      <form-item label="设置itemkeys">
        <y-button @click.native.stop="onShowFieldsTreeWin">设置 CRF表->Field->Item 过滤项 & desktop样式</y-button>
      </form-item>
      <form-item label="设置fieldNavs">
        <y-button @click.native.stop="onShowFieldsNavWin">设置 CRF表->Field 导航标签</y-button>
      </form-item>
      <form-item label="是否可见" prop="visible">
        <checklist inline v-model="currentData.visible" :items="visibleList"></checklist>
      </form-item>
      <form-item label="对患者隐藏">
        <checklist inline v-model="currentData.pHidden" :items="hiddenList"></checklist>
      </form-item>
    </y-form>
    <div style="padding-left:100px">
      <y-button type="primary" @click.native="save">保存&更新</y-button>
    </div>
    <fields-tree-win
      ref="fieldsTreeWin"
      :title="currentData.title"
      :itemkeys="currentData.itemkeys"
      :fieldList="fieldList"
      :show="showFieldsTreeWin"
      @on-hide="showFieldsTreeWin=false"
      @on-confirm-ok="onFieldsItemChange"></fields-tree-win>
    <fields-nav-win
      :title="currentData.title"
      :fieldNavs="currentData.fieldNavs"
      :fieldList="fieldList"
      :show="showFieldsNavWin"
      @on-hide="showFieldsNavWin=false"
      @on-confirm-ok="onFieldsNavChange"></fields-nav-win>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import YButton from '../../common/button/button'
import Checklist from '../../common/checklist/checklist'
import Loading from '../../common/loading/loading'
import FieldsTreeWin from '../win/fields-tree-win'
import FieldsNavWin from './win/fields-nav-win'
import { authenticate, catchExpired } from '../../../utils/auth'

export default {
  components: {YForm, FormItem, YSelect, YInput, YButton, Checklist, Loading, FieldsTreeWin, FieldsNavWin},
  props: {
    data: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      showFieldsNavWin: false,
      showFieldsTreeWin: false,
      showLoading: false,
      fieldList: [],
      currentData: this.getData(this.data),
      roleList: [
        {name: '医生', value: 'doctor'},
        {name: '患者', value: 'patient'},
        {name: '助理', value: 'assistant'}
      ],
      visibleList: [
        {key: '1', title: '可见', value: true},
        {key: '0', title: '不可见', value: false}
      ],
      hiddenList: [
        {key: '1', title: '隐藏', value: true},
        {key: '0', title: '不隐藏', value: false}
      ],
      currentDataRule: {
        modelId: [{required: true, message: '不能为空', trigger: 'change'}],
        title: [{required: true, message: '不能为空', trigger: 'blur'}],
        visible: [{type: 'boolean', required: true, message: '不能为空', trigger: 'change'}],
        hasScore: [{type: 'boolean', required: true, message: '不能为空', trigger: 'change'}]
      }
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.currentData = this.getData(val)
        // currentData 为crf中item(crftable)，每个item有modelId, cllection, title, itemkeys, fieldkeys
        // 加载crfList时候，每个crf中有items, 每个item即为currentCrfTable， crfTable不用另加载
      }
    },
    currentData (val) {
      if (val && val.modelId) {
        this.loadFieldList()
      }
    }
  },
  mounted () {
    this.loadFieldList()
  },
  methods: {
    getData (data) {
      var cData = Object.assign({}, {
        modelId: '',
        title: '',
        itemkeys: [],
        visible: true,
        role: ''
      }, data)
      cData.role = cData.role.split(',')
      return cData
    },
    recoverData (data) {
      var cData = Object.assign({}, data)
      cData.role = cData.role.toString()
      return cData
    },
    save () {
      const data = this.recoverData(this.currentData)
      this.$refs.currentDataForm.validate()
      if (!this.$refs.currentDataForm.isValid) return false
      // ... update
      this.$emit('on-crftable-update', data)
    },
    onShowFieldsTreeWin () {
      this.showFieldsTreeWin = true
    },
    onShowFieldsNavWin () {
      this.showFieldsNavWin = true
    },
    onFieldsItemChange (fieldkeys, itemkeys) {
      this.currentData.fieldkeys = fieldkeys
      this.currentData.itemkeys = itemkeys
      this.save()
    },
    onFieldsNavChange (fieldNavs) {
      this.currentData.fieldNavs = fieldNavs
      this.save()
    },
    loadFieldList () {
      // 该接口加载fieldList(crftable)时，不需要将itemkeys,fieldkeys映射到每个节点上
      if (!this.currentData || !this.currentData.modelId) return
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/crf/crfTable/field/list/all`, authenticate({
        params: {id: this.currentData.modelId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && Array.isArray(res.fieldList)) {
          this.fieldList = res.fieldList
          this.showLoading = false
          this.$nextTick(() => {
            setTimeout(() => {
              if (!this.$refs.fieldsTreeWin.hasDataChecked()) {
                this.$refs.fieldsTreeWin.checkAll()
                this.currentData.fieldkeys = this.$refs.fieldsTreeWin.getFieldKeys()
                this.currentData.itemkeys = this.$refs.fieldsTreeWin.getItemKeys()
              } else {
                this.currentData.fieldkeys = this.$refs.fieldsTreeWin.getFieldKeys()
              }
            }, 50)
          })
        } else if (res.status === 'success') {
          this.fieldList = []
          this.showLoading = false
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    }
  }
}
</script>

<style lang="css">
</style>
