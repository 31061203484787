export default {
  data () {
    return {
      columns: [
        {
          type: 'index',
          title: '序号',
          width: 65,
          align: 'center'
        },
        {
          title: '数据源名称',
          key: 'name',
          width: 120
        },
        {
          title: '数据源类型',
          key: 'type',
          width: 120
        },
        {
          title: 'followupModel',
          key: 'followupModel',
          width: 120
        },
        {
          title: 'crfId',
          key: 'crfId',
          width: 200
        },
        {
          title: 'subjectId',
          key: 'subjectId',
          width: 200
        },
        {
          title: 'doctorId',
          key: 'doctorId',
          width: 200
        },
        {
          title: 'groupCode',
          key: 'groupCode',
          width: 120
        },
        {
          title: 'dateRange',
          key: 'dateRange',
          width: 120
        },
        {
          title: '数据源URL',
          key: 'url',
          width: 200
        },
        {
          title: '数据源描述',
          key: 'desc',
          width: 120
        },
        {
          title: '操作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                nativeOn: {
                  click: () => { this.edit(params.row._idx) }
                }
              }, '编辑'),
              h('y-button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                nativeOn: {
                  'click': () => { this.delete(params.row._idx) }
                }
              }, '删除')
            ])
          }
        }
      ]
    }
  }
}
