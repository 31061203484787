<template>
  <div class="sql-table-container" :style="{width: width+'px'}">
    <div class="sql-table-box" @click="handleTableClick">
      <div class="sql-table-head" ref="head" @click="handleSort" @contextmenu.prevent="hanleShowFilter">
        <template v-for="col in showColumns">
          <div v-if="col.checked" class="sql-col head" :style="{width: col.width+'px'}" :key="col.key" :data-key="col.key">
            <span :title="col.dataType">{{col.title}}</span>
            <span class="sql-sort-actions" v-if="(col.key !== '#' && col.key !== 'action')">
              <span class="sort-icons">
                <i class="ionic-icon ionic-icon-arrow-up-b" data-action="asc" :class="{on: col.sortType === 'asc'}"></i>
                <i class="ionic-icon ionic-icon-arrow-down-b" data-action="desc" :class="{on: col.sortType === 'desc'}"></i>
              </span>
            </span>
          </div>
        </template>
        <div class="sql-col scroll-bar"></div>
      </div>
      <div class="sql-table-body" :style="{height: height+'px'}" @click="handleAction" @scroll="handleScroll">
        <div class="sql-table-body-inner"></div>
      </div>
      <div class="sql-col-filter" ref="filter" :style="{maxHeight: height+'px', display: showFilter?'display': 'none'}" @change="handleChange">
        <label class="sql-col-filter__item" v-for="col in showColumns" :key="col.key">
          <input type="checkbox" :data-key="col.key" :checked="col.checked"/>
          <span>{{col.title}}</span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    width: Number,
    height: Number,
    indexStart: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      showFilter: false,
      showColumns: []
    }
  },
  methods: {
    setShowColumns (val) {
      this.showColumns = this.getShowColumns(val)
    },
    getShowColumns (val) {
      if (this.$store.state.rds.columnMap[this.name]) {
        return this.$store.state.rds.columnMap[this.name]
      } else {
        this.$store.dispatch('updateResColumnMap', {key: this.name, columns: val})
        return val
      }
    },
    renderBody () {
      const body = this.$el.querySelector('.sql-table-body')
      const bodyInner = document.createElement('div')
      bodyInner.className = 'sql-table-body-inner'
      const fragment = document.createDocumentFragment()
      const data = window.sqlData || []
      let cnt = 0
      for (const item of data) {
        cnt += 1
        const $row = document.createElement('div')
        $row.setAttribute('data-id', item.id)
        $row.className = 'sql-row'
        for (const col of this.showColumns) {
          if (col.checked) {
            let $col = document.createElement('div')
            $col.className = 'sql-col'
            $col.style.width = col.width + 'px'
            if (col.type === 'index') {
              $col.innerText = this.indexStart + cnt
            } else if (col.key === 'action' && col.render) {
              $col.classList.add('action')
              $col.innerHTML = col.render(item, col)
            } else if (item[col.key]) {
              $col.innerText = item[col.key]
            }
            $row.appendChild($col)
          }
        }
        fragment.appendChild($row)
      }
      if (body) {
        this.removeChild(body)
        body.appendChild(bodyInner)
        bodyInner.appendChild(fragment)
      }
    },
    removeChild (el) {
      if (!el) return
      const childNodes = el.childNodes
      const len = childNodes ? childNodes.length : 0
      for (let i = len - 1; i >= 0; i--) {
        const child = childNodes[i]
        child.parentNode.removeChild(child)
      }
    },
    setData (data = []) {
      delete window.sqlData // delete old data
      window.sqlData = data
      this.renderBody()
    },
    handleAction (e) {
      const path = []
      let el = e.target
      path.push(el)
      while (el !== e.currentTarget) {
        el = el.parentElement
        path.push(el)
      }
      let id = ''
      let action = ''
      for (const el of path) {
        if (el.dataset.id) {
          id = el.dataset.id
        }
        if (el.dataset.action) {
          action = el.dataset.action
        }
      }
      if (action && id) {
        let actionData = null
        for (const item of window.sqlData) {
          if (String(item.id) === id) {
            actionData = item
            break
          }
        }
        if (actionData) {
          this.$emit(`on-action`, action, actionData)
        }
      }
    },
    handleSort (e) {
      const path = []
      let el = e.target
      path.push(el)
      while (el !== e.currentTarget) {
        el = el.parentElement
        path.push(el)
      }
      let key = ''
      let action = ''
      for (const el of path) {
        if (el.dataset.key) {
          key = el.dataset.key
        }
        if (el.dataset.action) {
          action = el.dataset.action
        }
      }
      if (action && key) {
        console.log(key, action)
        const col = this.getColumn(key)
        if (col && col.sortType !== action) {
          col.sortType = action
          this.$emit('on-sort', key, col.sortType)
        } else if (col && col.sortType === action) {
          col.sortType = 'normal'
          this.$emit('on-sort', key, col.sortType)
        }
      }
    },
    getColumn (key) {
      for (const col of this.showColumns) {
        if (col.key === key) {
          return col
        }
      }
      return null
    },
    handleScroll (e) {
      const scrollLeft = e.target.scrollLeft
      this.$refs.head.scrollLeft = scrollLeft
    },
    hanleShowFilter (e) {
      const clientLeft = e.clientX
      const elLeft = this.$el.getBoundingClientRect().left
      const offLeft = clientLeft - elLeft
      const $filter = this.$el.querySelector('.sql-col-filter')
      $filter.style.left = offLeft + 'px'
      $filter.style.display = 'flex'
      this.showFilter = true
    },
    handleChange (e) {
      let key = ''
      let checked = null
      if (e.target.dataset.key) {
        checked = e.target.checked
        key = e.target.dataset.key
        let col = null
        for (const item of this.showColumns) {
          if (item.key === key) {
            col = item
          }
        }
        if (checked) {
          col.checked = true
          this.renderBody()
        } else {
          col.checked = false
          this.renderBody()
        }
      }
    },
    handleTableClick (e) {
      e.stopPropagation()
      if (!this.$refs.filter.contains(e.target)) {
        this.$el.querySelector('.sql-col-filter').style.display = 'none'
        this.showFilter = false
      }
    }
  }
}
</script>
<style lang="less">
.sql-table-container {
  overflow: hidden;
}
.sql-table-box {
  position: relative;
}
.sql-table-head {
  position: relative;
  display: flex;
  white-space: nowrap;
  background-color: #c8d0db;
  max-height: 50px;
  overflow: hidden;
}
.sql-table-body {
  font-size: 14px;
  overflow-x: auto;
  overflow-y: auto;
}
.sql-table-body-inner {
  display: inline-block;
}
.sql-col {
  display: inline-block;
  flex: 0 0 auto;
  padding: 6px 7px;
  overflow: hidden;
  &.head {
    line-height: 18px;
  }
  &.scroll-bar {
    width: 17px;
    background-color: #aaa;
  }
  &.action {
    cursor: pointer;
    span {
      &:hover {
        color: #2374e6;
      }
    }
  }
}
.sql-row {
  display: flex;
  white-space: nowrap;
  border-bottom: 1px solid #ebeced;
}
.sql-sort-actions {
  position: relative;
  .ionic-icon {
    display: inline-block;
    height: 10px;
    color: #888;
    cursor: pointer;
    &.on {
      color: #222;
    }
  }
  .sort-icons {
    position: absolute;
    top: -5px;
    right: -20px;
    display: flex;
    flex-direction: column;
  }
}
.sql-col-filter {
  position: absolute;
  z-index: 9;
  left: 10px;
  top: 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
  min-width: 300px;
}
.sql-col-filter__item {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    background-color: #e1e9f4;
  }
}
</style>
