<template>
  <modal v-model="showModal" title="选择 dataset" width="300" :styles="{top:'200px'}" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <div class="deset-list">
      <label class="deset-item">
        <input type="checkbox" v-model="selectAll">
        <span>全选</span>
      </label>
      <label class="deset-item" v-for="item in datasetList" :key="item.id" :id="item.id">
        <input type="checkbox" v-model="selectedIds" :value="item.id">
        <span>{{item.name}}</span>
      </label>
    </div>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import { authenticate, catchExpired } from '../../../utils/auth'

export default {
  components: {Modal},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: this.show,
      selectAll: false,
      datasetList: [],
      selectedIds: []
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId // in manage questId is projectId, quest-item-list 中无效
    },
    pageHeight () {
      return this.$store.getters.getWinHeight - 160
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    projectId (val) {
      if (val) {
        this.loadDatasetList()
      }
    },
    selectAll (val) {
      if (val && this.selectedIds.length < this.datasetList.length) {
        this.selectedIds = this.datasetList.map(item => item.id)
      } else if (!val && this.selectedIds.length === this.datasetList.length) {
        this.selectedIds = []
      }
    },
    selectedIds (val) {
      if (val && val.length === this.datasetList.length && !this.selectAll) {
        this.selectAll = true
      } else if (val && val.length < this.datasetList.length && this.selectAll) {
        this.selectAll = false
      }
    }
  },
  mounted () {
    this.loadDatasetList()
  },
  methods: {
    onBeforeConfirm () {
      if (!this.selectedIds) return false
      return true
    },
    onConfirm () {
      const desets = []
      this.datasetList.forEach(item => {
        if (this.selectedIds.includes(item.id)) {
          desets.push(item)
        }
      })
      this.$emit('on-confirm-ok', desets)
    },
    loadDatasetList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/dataset/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.datasetList = res.datasetList || []
        } else {
          this.showLoading = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    }
  }
}
</script>

<style lang="less">
.deset-list {
  padding: 0 20px;
}
.deset-item {
  height: 32px;
  line-height: 32px;
  display: block;
  input {
    margin-right: 10px;
    outline: 0;
  }
}
</style>
