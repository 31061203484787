<template>
  <div class="param-item-r2c">
    <span class="param-select-label">keyField:</span>
    <select class="param-select" ref="keyField" :value="currentValue.keyField" @change="onKeyChange">
      <option v-for="item of data" :key="item.name" :value="item.name">{{item.name}}</option>
    </select>
    <span class="param-select-label">valueField:</span>
    <div class="param-item-list">
      <div class="param-item-label" v-for="item in currentValue.valueField" :key="item">
        <span class="field">{{item}}</span>
        <span class="close ionic-icon ionic-icon-ios-close" @click.stop="removeField(item)"></span>
      </div>
    </div>
    <select class="param-select" @change="onValueChange">
      <option v-for="item of data" :key="item.name">{{item.name}}</option>
    </select>
    <span class="btn action-btn" @click="onJsonPreview">JSON预览</span>
  </div>
</template>

<script>
import YSelect from '../../common/select/select'
import Emitter from '../../mixins/emitter'
export default {
  components: {YSelect},
  mixins: [Emitter],
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      currentValue: this.value || {}
    }
  },
  watch: {
    value (val, oldVal) {
      // console.log('watch value', JSON.stringify(val), JSON.stringify(this.currentValue))
      if (val && JSON.stringify(this.currentValue) !== JSON.stringify(val)) {
        this.currentValue = val
      }
    },
    currentValue (val) {
      this.emitChange(val)
    },
    data (val) {
      if (val && val.length) {
        setTimeout(() => {
          this.$refs.keyField.value = this.currentValue.keyField
        }, 50)
      }
    }
  },
  methods: {
    emitChange (val) {
      if (val && val.keyField && val.valueField) {
        // console.log('emit changed', JSON.stringify(val))
        this.$emit('input', val)
        this.dispatch('FormItem', 'on-form-change', val)
        this.$emit('on-change', val)
      }
    },
    onKeyChange (e) {
      this.currentValue.keyField = e.target.value
      this.emitChange(this.currentValue)
    },
    onValueChange (e) {
      this.currentValue.valueField = this.currentValue.valueField || []
      if (!this.currentValue.valueField.includes(e.target.value)) {
        this.currentValue.valueField.push(e.target.value)
      }
      this.emitChange(this.currentValue)
    },
    onJsonPreview () {
      this.$emit('on-rol2col-json-preview')
    },
    removeField (item) {
      const valueField = this.currentValue.valueField || []
      let idx = -1
      valueField.forEach((vitem, index) => {
        if (vitem === item) {
          idx = index
        }
      })
      if (idx >= 0) {
        valueField.splice(idx, 1)
      }
    }
  }
}
</script>

<style lang="less">
.param-item-r2c {
  display: flex;
  align-items: center;
  .param-check {
    width: 40px;
  }
  .param-label {
    margin-left: 10px;
  }
  .param-val {
    width: 30%;
    &.select {
      outline: 0;
      height: 32px;
    }
  }
  .action-btn {
    margin-left: 6px;
  }
}
.param-select-label {
  margin: 0 10px;
}
.param-select {
  width: 25%;
  outline: 0;
  height: 32px;
}
.param-item-list {
  display: flex;
  align-items: center;
}
.param-item-label {
  position: relative;
  margin-right: 10px;
  background-color: #dcdcdc;
  padding: 0 4px;
  .close {
    cursor: pointer;
    position: absolute;
    top: -15px;
    right: 0px;
  }
}
</style>
