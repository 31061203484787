<template>
  <div>
    <div class="crf-list-panel-15" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <div class="crf-db-panel">
        <div v-if="connStatus" class="conn-db">{{`${connDB.driverName}: ${connDB.datasourceName}`}}</div>
        <div class="status-tip">
          <span>{{connStatus ? '已连接' : '未连接'}}</span>
          <span v-if="connStatus" class="reconn fa fa-unlink" @click.stop="reConnDB" title="重新连接"></span>
        </div>
        <div v-if="!connStatus" class="btn" @click.stop="onShowDBConnWin">数据库连接</div>
      </div>
      <div class="crf-db-patient" @click.stop="onPDataExtract">
        <div class="label">随访数据抓取</div>
      </div>
      <dataset-tree
        ref="pdatasetTree"
        title="patientDataset"
        datasetType="patientDataset"
        :data="pDatasetList"
        :tablenameList="tablenameList"
        :crftableList="crftableList"
        @on-tree-node-click="onPDatasetClick"
        @on-tree-node-delete="onDatasetDelete"
        @on-tree-node-add="onPDatasetAdd">
      </dataset-tree>
      <dataset-tree
        ref="datasetTree"
        title="数据集dataset"
        :data="datasetList"
        :tablenameList="tablenameList"
        :crftableList="crftableList"
        @on-tree-node-click="onDatasetClick"
        @on-tree-node-delete="onDatasetDelete"
        @on-tree-node-add="onDatasetAdd">
      </dataset-tree>
    </div>
    <div class="crf-list-panel-65" :class="{'crf-list-2': panelView === 'pdataView'}" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <panel-dataset-patient v-if="panelView==='patientDataset'" :data="currentPDataset" :tablenameList="tablenameList"
        @on-json-preview="onJsonPreview"
        @on-table-preview="onTablePreview"></panel-dataset-patient>
      <panel-dataset v-if="panelView==='dataset'" :data="currentDataset" :tablenameList="tablenameList" :crftableList="crftableList"
        @on-json-preview="onJsonPreview"
        @on-table-preview="onTablePreview"></panel-dataset>
      <panel-patient-extract v-if="panelView==='pdataView'"></panel-patient-extract>
    </div>
    <div v-if="panelView !== 'pdataView'" class="crf-list-panel-20" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <preview-win :show="showJsonPreview" @on-hide="showJsonPreview=false" :data="previewData"></preview-win>
    </div>
    <preview-table-win :show="showTablePreview" @on-hide="showTablePreview=false" :data="previewData" :fields="previewFields"></preview-table-win>
    <conn-win :show="showConnWin" @on-hide="showConnWin=false" @on-confirm-ok="onDBConn"></conn-win>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>
<script>
import DatasetTree from './field/field-tree'
import YSelect from '../common/select/select'
import Loading from '../common/loading/loading'
import PanelDatasetPatient from './panel/panel-dataset-patient'
import PanelDataset from './panel/panel-dataset'
import PanelPatientExtract from './panel/panel-patient-extract.vue'
import PreviewTableWin from './win/preview-table-win.vue'
import PreviewWin from './win/preview-win.vue'
import ConnWin from './win/conn-win.vue'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  name: 'DataExtract',
  components: {DatasetTree, YSelect, Loading, PanelDatasetPatient, PanelDataset, PanelPatientExtract, PreviewTableWin, PreviewWin, ConnWin},
  data () {
    return {
      connDB: {driverName: this.$store.state.db.dbName, datasourceName: this.$store.state.db.dbSource},
      connStatus: this.$store.state.db.conn,
      showConnWin: false,
      tablenameList: [],
      columnList: [{ title: '#', type: 'index', width: 80 }],

      showLoading: false,
      crftableList: [],

      panelView: '',
      datasetList: [],
      currentDataset: {},

      currentPDataset: {},

      showTablePreview: false,
      showJsonPreview: false,
      previewData: '',
      previewFields: [],

      pDatasetList: []
    }
  },
  mounted () {
    if (this.connStatus) {
      this.loadTableList()
      this.loadDatasetList()
      this.loadCrftableList()
      this.loadPDatasetList()
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId // in manage questId is projectId, quest-item-list 中无效
    },
    pageHeight () {
      return this.$store.getters.getWinHeight - 60
    }
  },
  watch: {
    projectId (val) {
      if (val && this.connStatus) {
        this.loadDatasetList()
        this.loadPDatasetList()
        this.loadCrftableList()
      }
    }
  },
  created () {
    this.$on('on-dataset-save', (dataset) => {
      this.updateDataset(dataset)
    })
    this.$on('on-pdataset-save', (dataset) => {
      this.onPDatasetAdd(dataset)
    })
  },
  methods: {
    dbConn (data) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/rds/database/connect`, data, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          this.currentTablename = ''
          this.connStatus = true
          this.$store.dispatch('updateDBConn', true)
          this.loadTableList()
          this.loadDatasetList()
          this.loadCrftableList()
          this.loadPDatasetList()
        } else {
          this.connStatus = false
          this.$Modal.error({
            title: 'rds 数据库连接失败',
            content: `<p>${res.status} ${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadTableList () {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/rds/table/list`, {}, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          this.tablenameList = res.tablenameList || []
        } else {
          this.connStatus = false
          this.$Modal.error({
            title: 'rds 数据库连接失败',
            content: `<p>${res.status} ${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    setTableColumns (colList = []) {
      const re = [{ title: '#', key: '#', type: 'index', width: 80 }]
      colList.forEach(item => {
        re.push({
          title: `${item.name} (${item.type})`,
          key: item.name,
          width: 200,
          render: (h, params) => { return h('div', {class: 'r-nowrap', attrs: {title: params.row[item.name]}}, params.row[item.name]) },
          sortable: 'server'
        })
      })
      return re
    },
    loadCrftableList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/crf/crfTable/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success' && Array.isArray(res.crfTableList)) {
          this.crftableList = res.crfTableList
        } else if (res.status === 'success') {
          this.crftableList = []
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadPDatasetList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/dataset/patient`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.pDatasetList = res.datasetList || []
        } else {
          this.showLoading = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadDatasetList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/dataset/list`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.datasetList = res.datasetList || []
        } else {
          this.showLoading = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    updateDataset (data) {
      delete data.patientList // 保存模型删除patientList
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/dataset/update`, {
        dataset: data
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', res.statusText)
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
      })
    },
    onDatasetDelete (data) {
      console.log('delete', data)
      this.updateLoadStatus('load-start', '')
      this.$http.post(`${this.httpRoot}/dataset/delete`, {id: data.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success')
          this.loadDatasetList()
        } else {
          this.updateLoadStatus('load-faild')
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '服务异常，查询失败')
      })
    },
    onPDatasetAdd (data) {
      // patientDataset 没有add接口,供用update
      console.log('add pdataset', data)
      this.updateLoadStatus('load-start', '')
      this.$http.post(`${this.httpRoot}/dataset/patient/update`, {dataset: data}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success')
          this.loadPDatasetList()
        } else {
          this.updateLoadStatus('load-faild')
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '服务异常，查询失败')
      })
    },
    onDatasetAdd (data) {
      console.log('add dataset', data)
      this.updateLoadStatus('load-start', '')
      this.$http.post(`${this.httpRoot}/dataset/add`, {dataset: data, projectId: this.projectId}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success')
          this.loadDatasetList()
        } else {
          this.updateLoadStatus('load-faild')
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '服务异常，查询失败')
      })
    },
    onPDatasetClick (data, type) {
      // console.log(data, type)
      this.panelView = type
      this.$refs.datasetTree.unfocusAll()
      this.previewData = ''
      setTimeout(() => {
        if (this.panelView === 'patientDataset') {
          this.currentPDataset = data
          this.currentDataset = {}
        } else {
          this.currentDataset = {}
          this.currentPDataset = {}
        }
      }, 0)
    },
    onPDataExtract () {
      this.panelView = 'pdataView'
      this.currentDataset = {}
      this.currentPDataset = {}
    },
    onDatasetClick (data, type) {
      // console.log(data, type)
      this.panelView = type
      this.$refs.pdatasetTree.unfocusAll()
      this.previewData = ''
      setTimeout(() => {
        if (this.panelView === 'dataset') {
          this.currentDataset = data
          this.currentPDataset = {}
        } else {
          this.currentDataset = {}
          this.currentPDataset = {}
        }
      }, 0)
    },
    loadPreview (data, callback) {
      this.updateLoadStatus('load-start', '')
      this.$http.post(`${this.httpRoot}/rds/preview/query`, data, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', `预览成功: ${res.dataList ? res.dataList.length : 'null'}`)
          if (typeof callback === 'function') {
            callback(res.dataList || [])
          }
        } else {
          this.showLoading = false
          this.$Modal.error({
            title: '预览失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '服务异常，查询失败')
      })
    },
    onShowDBConnWin () {
      this.showConnWin = true
    },
    onDBConn (data) {
      this.connDB = data
      if (this.connDB && this.connDB.driverName && this.connDB.datasourceName) {
        this.$store.dispatch('updateDB', {dbName: this.connDB.driverName, dbSource: this.connDB.datasourceName, datasource: this.connDB.datasource})
        this.dbConn(this.connDB)
      }
    },
    reConnDB () {
      if (this.connDB && this.connDB.driverName && this.connDB.datasourceName) {
        this.$store.dispatch('updateDB', {dbName: this.connDB.driverName, dbSource: this.connDB.datasourceName, datasource: this.connDB.datasource})
        this.dbConn(this.connDB)
      }
    },
    onJsonPreview (patientList, rowToCol) {
      let param = {datasource: this.$store.state.db.datasource, patientList, dataset: this.currentDataset}
      //
      this.previewData = ''
      this.loadPreview(param, data => {
        this.previewData = data
        this.showJsonPreview = true
      })
    },
    onTablePreview (patientList, rowToCol, fieldMapping, columns) {
      let param = {datasource: this.$store.state.db.datasource, patientList, dataset: this.currentDataset}
      //
      this.previewData = ''
      // update table columns
      this.previewFields = columns
      this.loadPreview(param, data => {
        this.previewData = data
        this.showTablePreview = true
      })
    },
    updateLoadStatus (status, loadingText) {
      if (status === 'load-start') {
        this.loadingText = loadingText
        this.showLoading = true
      } else if (status === 'load-success') {
        this.showLoading = false
        if (loadingText) {
          this.$Message.success(loadingText)
        }
      } else if (status === 'load-faild') {
        this.showLoading = false
        this.$Message.error(loadingText)
      }
    }
  }
}
</script>

<style lang="less">
.crf-db-panel {
  padding: 15px;
  border-bottom: 1px solid #7d818a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  .status-tip {
    margin: 0 10px;
    white-space: nowrap;
  }
  .conn-db {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
  }
  .reconn {
    padding-left: 10px;
    cursor: pointer;
  }
}
.crf-list-panel-15 {
  width: 15%;
  border-right: 1px solid #dfdfdf;
  float: left;
}
.crf-list-panel-20 {
  width: 20%;
  border-right: 1px solid #dfdfdf;
  float: left;
}
.crf-list-panel-65 {
  width: 65%;
  border-right: 1px solid #dfdfdf;
  float: left;
  &.crf-list-2 {
    width: 85%;
  }
}
.crftable-panels * {
  box-sizing: content-box;
}
.crf-db-patient {
  cursor: pointer;
  &:hover {
    background-color: #ebf5ff;
    color: #336199;
  }
  .label {
    text-align: left;
    padding: 8px;
    font-size: 14px;
  }
}
</style>
