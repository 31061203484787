<template>
  <modal class="crfupload-edit-win" v-model="showModal" title="Project.deb导入" width="330" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <div class="file-overview-container">
      <div class="file-overview" v-if="isUploadSuccess">
        <div class="file-overview-upload-inner">
          <div class="file-overview-upload-inner-content">
            <span style="color:#fe3212;">文件上传成功</span>
          </div>
        </div>
      </div>
      <div class="file-overview" v-else>
        <upload
          ref="upload"
          :action="`${this.httpRoot}/db/backup/import`"
          :headers="headers"
          :data="{projectCode: this.projectCode}"
          :showUploadList="false"
          :beforeUpload="uploadBefore"
          :onProgress="uploadProgress"
          :onSuccess="uploadSuccess"
          :onError="uploadError"
          class="file-overview-upload">
          <div class="file-overview-upload-inner">
            <span class="ionic-icon ionic-icon-ios-cloud-upload"></span>
            <p class="tip">点击选择文件</p>
          </div>
        </upload>
      </div>
    </div>
    <div class="file-tip">
      <y-progress v-if="showProgress" :percent="percent" :strokeWidth="4"></y-progress>
      <div class="upload-error" v-if="showError">文件上传出错，请重新打开页面上传</div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YProgress from '../../common/progress/progress'
import Upload from '../../common/upload/upload'
import { authenticate } from '../../../utils/auth'

export default {
  components: { Modal, YProgress, Upload },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      headers: authenticate().headers,
      showModal: this.show,
      showProgress: false,
      percent: 0,
      showError: false,
      isUploadSuccess: false
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      } else {
        this.isUploadSuccess = false
        this.showError = false
        this.showProgress = false
      }
    }
  },
  computed: {
    projectCode () {
      return this.$store.state.project.projectId
    }
  },
  methods: {
    onBeforeConfirm () {
      if (!this.isUploadSuccess) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok')
    },
    uploadBefore () {
      this.showError = false
      this.isUploadSuccess = false
      this.showProgress = true
      this.percent = 0
    },
    uploadProgress (e, file) {
      this.percent = e.percent
    },
    uploadSuccess (e, file) {
      this.isUploadSuccess = true
      this.showProgress = false
    },
    uploadError () {
      this.showError = true
    }
  }
}
</script>

<style lang="less">
.img-exceed {
  color: #F76260;
}
</style>
