<template>
  <modal v-model="showModal" :title="title" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="subjectForm" :model="subject" :rules="subjectRule" label-width="100px" item-width="250px">
      <form-item label="课题名称" prop="name">
        <y-input v-model="subject.name"></y-input>
      </form-item>
      <form-item label="是否默认" prop="default">
        <checklist v-model="subject.default" inline :items="defaultList"></checklist>
      </form-item>
      <form-item label="课题描述">
        <y-input type="textarea" v-model="subject.desc"></y-input>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import Checklist from '../../common/checklist/checklist'

export default {
  components: { Modal, YForm, FormItem, YSelect, YInput, Checklist },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: String,
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      subject: {
        name: '',
        default: ''
      },
      subjectRule: {
        name: [{required: true, message: '不能为空', trigger: 'blur'}],
        default: [{type: 'boolean', required: true, message: '不能为空', trigger: 'blur'}]
      },
      defaultList: [
        {key: '1', title: '是', value: true},
        {key: '0', title: '否', value: false}
      ]
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    value (val) {
      if (val) {
        this.subject = val
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.subjectForm.validate()
      if (!this.$refs.subjectForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', Object.assign({}, this.subject))
    }
  }
}
</script>

<style lang="css">
</style>
