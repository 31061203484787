<template>
  <div class="box-header-col" :class="{selected: selected}" @click.stop="onSelect" :style="headerColStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    boxItemWidth: {
      type: Number,
      default: 50
    },
    boxTopLeftWidth: {
      type: Number,
      default: 20
    }
  },
  data () {
    return {
      selected: false
    }
  },
  computed: {
    headerColStyle () {
      return {
        lineHeight: this.boxTopLeftWidth + 'px',
        width: this.boxItemWidth + 'px',
        height: this.boxTopLeftWidth + 'px'
      }
    }
  },
  methods: {
    onSelect (event) {
      if (this.$parent.selectAction === 'single' && !event.shiftKey) {
        // 续选-反选
        // 记录最新选中的col
        if (this.$parent.canSelectCol(this.index) && !this.selected) {
          this.$parent.startColIndex = this.index
        }
        if (this.$parent.canSelectCol(this.index)) {
          const selected = this.selected
          this.select(!selected)
          this.$parent.selectCol(this.index, !selected, true)
          this.$emit('on-col-selected', this.selected, this.index)
        }
      } else if (this.$parent.selectAction === 'single' && !event.metaKey && !event.ctrlKey && event.shiftKey) {
        // 连选
        this.$parent.selectColBlock(this.index)
      }
    },
    select (isSelect) {
      this.selected = isSelect
    }
  }
}
</script>
