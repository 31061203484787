import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import win from './modules/win'
import route from './modules/route'
import auth from './modules/auth'
import project from './modules/project'
import container from './modules/container'
import edit from './modules/edit'
import url from './modules/url'
import desktop from './modules/desktop'
import db from './modules/db'
import rds from './modules/rds'

Vue.use(Vuex)

const store = new Vuex.Store({
  actions,
  getters,
  modules: {
    win,
    route,
    auth,
    project,
    container,
    edit,
    url,
    desktop,
    db,
    rds
  }
})

export default store
