import Node from './fieldTree-node'

export default class TreeStore {
  constructor (options) {
    this.key = ''
    this.title = ''
    this.data = null
    this.props = {}

    this.currentNode = null

    for (let option in options) {
      if (options.hasOwnProperty(option)) {
        this[option] = options[option]
      }
    }

    this.nodesMap = {}

    this.rootNode = new Node({
      type: 'root',
      data: {
        key: this.key || 'root',
        title: this.title,
        value: this.title,
        fields: JSON.parse(JSON.stringify(this.data))
      },
      store: this
    })
  }

  registerNode (node) {
    if (!node || !node.data) return
    if (node.key) this.nodesMap[node.key] = node
  }

  deregisterNode (node) {
    if (!node || !node.data) return
    delete this.nodesMap[node.key]
  }

  getNodeByKey (key) {
    return this.nodesMap[key]
  }

  focus (status, deep) {
    this.rootNode.focus(status, deep)
  }

  hasNodeChecked () {
    let hasCheck = false
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.checked) {
        hasCheck = true
      }
    })
    return hasCheck
  }

  checkOneNodes (checkNode) {
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.key !== checkNode.key && node.checked) {
        node.checked = false
      } else if (node.key === checkNode.key) {
        node.checked = true
      }
    })
  }

  getCheckedNodeKeys () {
    let cnode = null
    let re = {}
    Object.keys(this.nodesMap).forEach(key => {
      let node = this.nodesMap[key]
      if (node.checked) {
        cnode = node
      }
    })
    if (cnode) {
      if (cnode.type === 'item' || cnode.type === 'gitem') {
        re.key = cnode.data.key
        re.title = cnode.data.title
        re.type = cnode.data.type
      }
      while (cnode.parent.type !== 'root') {
        cnode = cnode.parent
        if (cnode.type === 'field') {
          re.parentKey = cnode.key
        }
      }
      return re
    }
    return re
  }
}
