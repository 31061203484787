<template>
  <div class="crf-table">
    <div class="crf-table-search">
      <div class="crf-table-search-box">
        <input v-model="query" placeholder="输入CRF表名查询"/>
        <span class="ionic-icon ionic-icon-search"></span>
      </div>
    </div>
    <crf-table-item
      v-for="item in crfTableList"
      :crftable="item"
      :selected="item.id===currentCrfTabel.id"
      :key="item.id"
      @on-crftable-click="onCrfTableClick"
      @on-crftable-share="onCrfTableShare">
    </crf-table-item>
    <div class="crf-table-toolbar">
      <span class="tool">
        <tooltip placement="top-start" content="导入新的 CRF 表">
          <span class="ionic-icon" :class="[uploadHover?'ionic-icon-ios-cloud-upload':'ionic-icon-ios-cloud-upload-outline']"
            @mouseover="onMouseoverUpload"
            @mouseout="onMouseoutUpload"
            @click.stop="onUploadClick"></span>
        </tooltip>
      </span>
      <span class="tool">
        <tooltip placement="top" content="添加新的 CRF 表">
          <span class="ionic-icon" :class="[plusHover?'ionic-icon-ios-plus':'ionic-icon-ios-plus-outline']"
            @mouseover="onMouseoverPlus"
            @mouseout="onMouseoutPlus"
            @click.stop="onPlusClick"></span>
        </tooltip>
      </span>
      <span class="tool">
        <tooltip placement="top" content="删除选择的 CRF 表">
          <span class="ionic-icon" :class="[closeHover?'ionic-icon-ios-close':'ionic-icon-ios-close-outline']"
            @mouseover="onMouseoverClose"
            @mouseout="onMouseoutClose"
            @click.stop="onDeleteClick"></span>
        </tooltip>
      </span>
      <span class="tool">
        <tooltip placement="top-end" content="更新选择的 CRF 表模型树">
          <span class="ionic-icon" :class="[checkHover?'ionic-icon-ios-checkmark':'ionic-icon-ios-checkmark-outline']"
            @mouseover="onMouseoverCheck"
            @mouseout="onMouseoutCheck"
            @click.stop="onUpdateClick"></span>
        </tooltip>
      </span>
    </div>
    <crf-table-win
      :show="showCrfTableWin"
      @on-hide="showCrfTableWin=false"
      @on-confirm-ok="onCrfTableAdd"></crf-table-win>
    <upload-crftable-win
      :projectId="projectId"
      :show="showCrfTableUploadWin"
      @on-hide="showCrfTableUploadWin=false"
      @on-confirm-ok="onUploadSuccess"></upload-crftable-win>
  </div>
</template>

<script>
import CrfTableItem from './crfTable-item'
import CrfTableWin from './win/crfTable-win'
import UploadCrftableWin from './win/upload-crftable-win'
import Tooltip from '../common/tooltip/tooltip'
export default {
  components: {CrfTableItem, CrfTableWin, UploadCrftableWin, Tooltip},
  props: {
    crfTableList: Array,
    currentCrfTabel: {
      type: Object,
      default () { return {} }
    },
    projectId: String
  },
  data () {
    return {
      uploadHover: false,
      plusHover: false,
      closeHover: false,
      checkHover: false,
      showCrfTableWin: false,
      showCrfTableUploadWin: false,
      query: ''
    }
  },
  watch: {
    query (val) {
      this.$emit('on-query-change', val)
    }
  },
  methods: {
    onCrfTableClick (crftable) {
      this.$emit('on-crftable-click', crftable)
    },
    onUploadSuccess () {
      this.$emit('on-upload-success')
    },
    onCrfTableShare (crftable) {
      this.$emit('on-crftable-share', crftable)
    },
    onMouseoverUpload () {
      this.uploadHover = true
    },
    onMouseoutUpload () {
      this.uploadHover = false
    },
    onMouseoverPlus () {
      this.plusHover = true
    },
    onMouseoutPlus () {
      this.plusHover = false
    },
    onMouseoverClose () {
      this.closeHover = true
    },
    onMouseoutClose () {
      this.closeHover = false
    },
    onMouseoverCheck () {
      this.checkHover = true
    },
    onMouseoutCheck () {
      this.checkHover = false
    },
    onUploadClick () {
      this.showCrfTableUploadWin = true
    },
    onPlusClick () {
      this.showCrfTableWin = true
    },
    onDeleteClick () {
      this.$emit('on-crftable-delete')
    },
    onUpdateClick () {
      this.$emit('on-crftable-fields-update')
    },
    onCrfTableAdd (newCrfTable) {
      this.$emit('on-crftable-add', newCrfTable)
    }
  }
}
</script>

<style lang="less">
.crf-table {
  margin-top: 32px;
  margin-bottom: 32px;
}
.crf-table-search {
  position: fixed;
  top: 60px;
  width: 15%;
  height: 32px;
  background: #fff;
}
.crf-table-search-box {
  position: relative;
  input {
    display: inline-block;
    outline: 0;
    width: 100%;
    box-sizing: border-box;
    height: 32px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 12px;
    border: 0;
    // border: 1px solid #b6c3d0;
    // border-radius: 4px;
    color: #353140;
    background-color: #e1eaf2;
    background-image: none;
    position: relative;
    cursor: text;
    transition: border 0.2s ease-in-out, background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    &:focus {
      background-color: #f7f7f3;
    }
  }
  .ionic-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 32px;
    line-height: 32px;
    margin: 0 10px;
    color: #49525c;
  }
}
.crf-table-toolbar {
  position: fixed;
  bottom: 0;
  width: 15%;
  height: 32px;
  background: #f7f7f7;
  .tool {
    width: 25%;
    height: 32px;
    line-height: 32px;
    display: inline-block;
    float: left;
    text-align: center;
    font-size: 22px;
    position: relative;
    &:after{
      position: absolute;
      content: "";
      right: 0;
      top: 5px;
      bottom: 5px;
      width: 1px;
      background: #ddd;
    }
    &:last-child {
      &:after{
        display: none;
      }
    }
  }
  .ionic-icon {
    cursor: pointer;
  }
}
</style>
