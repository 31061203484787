<template>
  <label class="tr-checkbox">
    <span class="tr-checkbox__input"
      :class="{
        'is-disabled': disabled,
        'is-checked': isChecked,
        'is-indeterminate': indeterminate,
        'is-focus': focus
      }">
      <span class="tr-checkbox__inner"></span>
      <input v-if="trueLabel || falseLabel"
        class="tr-checkbox__original"
        type="checkbox"
        :name="name"
        :disabled="disabled"
        :true-value="trueLabel"
        :false-value="falseLabel"
        v-model="model"
        @change="handleChange"
        @focus="focus = true"
        @blur="focus = false"/>
      <input v-else
        class="tr-checkbox__original"
        type="checkbox"
        :disabled="disabled"
        :value="label"
        :name="name"
        v-model="model"
        @change="handleChange"
        @focus="focus = true"
        @blur="focus = false"/>
    </span>
    <span class="tr-checkbox__label" v-if="$slots.default || label">
      <slot></slot>
      <template v-if="!$slots.default">{{label}}</template>
    </span>
  </label>
</template>

<script>
import Emitter from '../mixins/emitter'

export default {
  name: 'Checkbox',
  mixins: [Emitter],
  props: {
    value: {},
    label: {},
    indeterminate: Boolean,
    disabled: Boolean,
    checked: Boolean,
    name: String,
    trueLabel: [String, Number],
    falseLabel: [String, Number]
  },
  data () {
    return {
      selfModel: false,
      focus: false
    }
  },
  computed: {
    model: {
      get () {
        return this.value !== undefined
          ? this.value
          : this.selfModel
      },
      set (val) {
        if (this.value !== undefined) {
          this.$emit('input', val)
        } else {
          this.selfModel = val
        }
      }
    },
    isChecked () {
      if ({}.toString.call(this.model) === '[object Boolean]') {
        return this.model
      } else if (this.model !== null && this.model !== undefined) {
        return this.model === this.trueLabel
      }
    }
  },
  created () {
    if (this.checked) {
      this.model = this.trueLabel || true
    }
  },
  methods: {
    handleChange (event) {
      this.$emit('change', event)
    }
  }
}
</script>
