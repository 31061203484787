import { authenticate, catchExpired } from '../../../utils/auth'

export default {
  methods: {
    loadDatasourceList () {
      this.$http.get(`${this.httpRoot}/dataset/datasource/list`, authenticate({params: {projectId: this.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.datasourceList = res.datasourceList || []
          this.updateLoadStatus('load-success')
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '加载数据源失败')
      })
    },
    addDatasource (data, callback) {
      this.updateLoadStatus('load-start', '提交中...')
      this.$http.post(`${this.httpRoot}/dataset/datasource/add`, { datasource: data, projectId: this.projectId }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', '添加数据源成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '添加数据源失败')
      })
    },
    updateDatasource (data, callback) {
      let cData = JSON.parse(JSON.stringify(data))
      delete cData._idx
      delete cData._index
      this.updateLoadStatus('load-start', '提交中...')
      this.$http.post(`${this.httpRoot}/dataset/datasource/update`, { datasource: cData, projectId: this.projectId }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', '更新数据源成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '更新数据源失败')
      })
    },
    deleteDatasource (id, callback) {
      this.$http.post(`${this.httpRoot}/dataset/datasource/delete`, { id }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', '删除数据源成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '删除数据源失败')
      })
    },
    loadDatatableList () {
      this.$http.get(`${this.httpRoot}/dataset/datatable/list`, authenticate({params: {projectId: this.projectId}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.datatableList = res.datatableList || []
          this.updateLoadStatus('load-success')
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '加载数据表失败')
      })
    },
    addDatatable (data, callback) {
      this.updateLoadStatus('load-start', '提交中...')
      this.$http.post(`${this.httpRoot}/dataset/datatable/add`, { datatable: data, projectId: this.projectId }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', '添加数据表成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '添加数据表失败')
      })
    },
    updateDatatable (data, callback) {
      let cData = JSON.parse(JSON.stringify(data))
      delete cData._idx
      delete cData._index
      this.updateLoadStatus('load-start', '提交中...')
      this.$http.post(`${this.httpRoot}/dataset/datatable/update`, { datatable: cData, projectId: this.projectId }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', '更新数据表成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '更新数据表失败')
      })
    },
    deleteDatatable (id, callback) {
      this.$http.post(`${this.httpRoot}/dataset/datatable/delete`, { id }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.updateLoadStatus('load-success', '删除数据表成功')
          if (typeof callback === 'function') {
            callback()
          }
        } else {
          this.updateLoadStatus('load-faild', res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.updateLoadStatus('load-faild', '删除数据表失败')
      })
    },
    updateLoadStatus (status, loadingText) {
      if (status === 'load-start') {
        this.loadingText = loadingText
        this.showLoading = true
      } else if (status === 'load-success') {
        this.showLoading = false
        if (loadingText) {
          this.$Message.success(loadingText)
        }
      } else if (status === 'load-faild') {
        this.showLoading = false
        this.$Message.error(loadingText)
      }
    },
    mapParams (val) {
      let re = ''
      if (Array.isArray(val)) {
        val.forEach(item => {
          re += item.key + ':' + item.value + ','
        })
      }
      return re
    },
    mapFields (val) {
      let re = ''
      if (Array.isArray(val)) {
        val.forEach(item => {
          re += item.key + ','
        })
      }
      return re
    }
  }
}
