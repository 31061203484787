<template>
  <span>
    <transition name="fade">
      <div class="modal-mask" v-show="visible" @click="mask"></div>
    </transition>
    <div class="modal-wrap" :class="{'modal-hidden': !wrapShow, [`${className}`]: !!className}" @click="handleWrapClick">
      <transition name="ease">
        <div class="modal" :style="mainStyles" v-show="visible">
          <div class="modal-content">
            <a class="modal-close" :class="{[`${closeClass}`]: !!closeClass}" v-if="closable" @click="close">
              <slot name="close">
                <Icon type="ios-close-empty"></Icon>
              </slot>
            </a>
            <div class="modal-header" v-if="!isGlobal && ($slots.header || title)">
              <slot name="header"><div class="modal-header-inner">{{ title }}</div></slot>
            </div>
            <div class="modal-body">
              <slot></slot>
            </div>
            <div class="modal-footer" v-if="!footerHide">
              <slot name="footer">
                <y-button type="text" @click.native="cancel">{{ cancelText }}</y-button>
                <y-button type="primary" :loading="buttonLoading" @click.native="ok">{{ okText }}</y-button>
              </slot>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </span>
</template>

<script>
import Icon from '../icon/icon'
import YButton from '../button/button'
import { getScrollBarSize } from '../../../utils/assist'

export default {
  components: { Icon, YButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: true
    },
    maskClosable: {
      type: Boolean,
      default: true
    },
    title: String,
    width: {
      type: [Number, String],
      default: 520
    },
    onBeforeOk: Function,
    okText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    loading: {
      type: Boolean,
      default: false
    },
    styles: Object,
    className: String,
    closeClass: String,
    // for instance
    footerHide: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    isGlobal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      wrapShow: false,
      buttonLoading: false,
      visible: this.value
    }
  },
  computed: {
    mainStyles () {
      let style = {}
      const styleWidth = { width: this.width ? `${this.width}px` : false }
      const customStyle = this.styles ? this.styles : {}

      Object.assign(style, styleWidth, customStyle)
      return style
    }
  },
  watch: {
    value (val) {
      this.visible = val
    },
    visible (val) {
      if (val === false) {
        this.buttonLoading = false
        this.timer = setTimeout(() => {
          this.wrapShow = false
          this.removeScrollEffect()
        }, 300)
      } else {
        if (this.timer) clearTimeout(this.timer)
        this.wrapShow = true
        if (!this.scrollable) {
          this.addScrollEffect()
        }
      }
    },
    loading (val) {
      if (!val) {
        this.buttonLoading = false
      }
    },
    scrollable (val) {
      if (!val) {
        this.addScrollEffect()
      } else {
        this.removeScrollEffect()
      }
    }
  },
  mounted () {
    if (this.visible) {
      this.wrapShow = true
    }
    // ESC close
    document.addEventListener('keydown', this.EscClose)
  },
  methods: {
    close () {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
    },
    mask () {
      if (this.maskClosable) {
        this.close()
      }
    },
    handleWrapClick (event) {
      // use indexOf,do not use === ,because modal-wrap can have other custom className
      const className = event.target.getAttribute('class')
      if (className && className.indexOf('modal-wrap') > -1) this.mask()
    },
    cancel () {
      this.close()
    },
    ok () {
      if (typeof this.onBeforeOk === 'function' && !this.onBeforeOk()) return
      if (this.loading) {
        this.buttonLoading = true
      } else {
        this.visible = false
        this.$emit('input', false)
      }
      this.$emit('on-ok')
    },
    EscClose (e) {
      if (this.visible && this.closable) {
        if (e.keyCode === 27) {
          this.close()
        }
      }
    },
    checkScrollBar () {
      let fullWindowWidth = window.innerWidth
      if (!fullWindowWidth) { // workaround for missing window.innerWidth in IE8
        const documentElementRect = document.documentElement.getBoundingClientRect()
        fullWindowWidth = documentElementRect.right - Math.abs(documentElementRect.left)
      }
      this.bodyIsOverflowing = document.body.clientWidth < fullWindowWidth
      if (this.bodyIsOverflowing) {
        this.scrollBarWidth = getScrollBarSize()
      }
    },
    setScrollBar () {
      if (this.bodyIsOverflowing && this.scrollBarWidth !== undefined) {
        document.body.style.paddingRight = `${this.scrollBarWidth}px`
      }
    },
    resetScrollBar () {
      document.body.style.paddingRight = ''
    },
    addScrollEffect () {
      this.checkScrollBar()
      this.setScrollBar()
      document.body.style.overflow = 'hidden'
    },
    removeScrollEffect () {
      document.body.style.overflow = ''
      this.resetScrollBar()
    }
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.EscClose)
    this.removeScrollEffect()
  }
}
</script>
