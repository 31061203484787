<template>
  <div class="mongo-pg">
    <div class="m-left">
      <div class="m-table-panel">
        <div class="m-table-head">
          <div class="navi-label">数据库集合查询</div>
          <div class="search-icon fa fa-search" @click.stop="loadCollectionList" title="数据库集合查询"></div>
        </div>
        <div class="m-table-list" :style="{height: tableListHeight+'px'}">
          <div class="m-table-item" :class="{active: currentCollection===item}" v-for="item in collectionList" :key="item" @click.stop="onCollectionClick(item)">
            <span class="fa fa-table"></span>
            <span>{{item}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="m-right" @click.stop="onHideSort">
      <div class="m-query-panel">
        <textarea class="sql" v-model="queryStr" placeholder="请输入查询条件{key:value}"></textarea>
      </div>
      <div class="m-data-panel">
        <div class="actions">
          <div class="m-item m-btn-left">
            <y-button icon="ios-arrow-down" @click.native.stop="onToggleShowSort">优先排序</y-button>
            <div v-show="showSort" class="m-sorts-panel" ref="sortPanel">
              <query-sorts v-model="sorts" :columns="columnList"></query-sorts>
            </div>
            <y-button type="primary" :disabled="!currentCollection || !queryStr" @click.native="onSearch">查 询</y-button>
          </div>
        </div>
        <y-table ref="table" :columns="columnList" :data="dataList" :indexStart="pagination.pageSize*pagination.pageIndex" :width="tableWidth" :height="tableHeight" @on-column-sort="onSort"></y-table>
        <div class="m-pagination">
          <pagination
            :total="total"
            pageSizeChangeable
            :pageSize="pagination.pageSize"
            :pageIndex="pagination.pageIndex"
            @on-currentpage-change="onPageChange"
            @on-pagesize-change="onPagesizeChange"></pagination>
        </div>
      </div>
    </div>
    <edit-win :show="showEditWin" @on-hide="showEditWin=false" :columns="columnList" :data="editData" @on-ok="onDataEdit"></edit-win>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>
<script>
import Loading from '../common/loading/loading.vue'
import YInput from '../common/input/input.vue'
import YButton from '../common/button/button.vue'
import YTable from '../common/table/table.vue'
import Pagination from '../common/pagination/pagination.vue'
import querySorts from './query-sorts.vue'
import EditWin from './edit-win.vue'
import { authenticate, catchExpired } from '../../utils/auth'

export default {
  components: {Loading, YInput, YButton, YTable, Pagination, querySorts, EditWin},
  data () {
    return {
      showLoading: false,
      columnList: [{ title: '#', type: 'index', width: 80 }],
      collectionList: [],
      dataList: [],
      total: 0,
      pagination: {
        pageIndex: 0,
        pageSize: 50
      },
      sorts: [],
      pIndexAlterBySearch: false,
      currentCollection: '',
      query: {},
      queryStr: '',
      tableWidth: this.$store.state.win.winWidth - 300,
      tableHeight: this.$store.state.win.winHeight - 60 - 100 - 68 - 50 - 2,
      tableListHeight: this.$store.state.win.winHeight - 60 - 32,

      showSort: false,
      showEditWin: false,
      editData: {}
    }
  },
  mounted () {
    this.loadCollectionList()
  },
  methods: {
    onCollectionClick (item) {
      this.pIndexAlterBySearch = true
      this.pagination.pageIndex = 0
      this.currentCollection = item
      this.query = {}
      this.queryStr = '{}'
      this.sorts = []
      this.searchMongo()
    },
    loadCollectionList () {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/mongo/collection/list`, {}, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          this.collectionList = res.collectionList || []
        } else {
          this.$Modal.error({
            title: 'rds 数据库连接失败',
            content: `<p>${res.status} ${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
      })
    },
    updateCollectionItem (data) {
      if (!data || !this.currentCollection) {
        this.$Message.error('请选择表和数据')
        return
      }
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/mongo/collection/update`, {collection: this.currentCollection, update: data}, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success') {
          this.$Message.success(res.statusText)
          this.searchMongo()
        } else {
          this.$Modal.error({
            title: '数据更新失败',
            content: `<p>${res.status} ${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
      })
    },
    onSearch () {
      this.pIndexAlterBySearch = true
      this.pagination.pageIndex = 0
      try {
        let temp = this.queryStr.trim()
        let func = new Function(`return ${temp};`)
        this.query = func()
        this.searchMongo()
      } catch (err) {
        this.$Modal.error({
          title: '查询条件语法错误',
          content: err.message
        })
      }
    },
    searchMongo () {
      if (!this.currentCollection) {
        this.$Message.warning('请选择数据库集合')
        return
      }
      if (!this.query) {
        this.$Message.warning('请输入查询条件')
        return
      }
      this.searchMongoCollection(this.currentCollection, this.query)
    },
    searchMongoCollection (collection, query) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/mongo/collection/query`, {collection, query, sorts: this.sorts, ...this.pagination}, authenticate())
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        this.pIndexAlterBySearch = false
        if (res.status === 'success') {
          this.columnList = this.setTableColumns(res.dataList)
          this.dataList = res.dataList || []
          this.total = res.sum || res.dataList.length
        } else {
          this.$Modal.error({
            title: '查询失败',
            content: `<p>${res.statusText}</p>`
          })
        }
      })
      .catch(err => {
        this.showLoading = false
        catchExpired(err, this)
      })
    },
    setTableColumns (dataList = []) {
      const columns = [{
        title: '#',
        key: '#',
        type: 'index',
        width: 80
      }, {
        title: '操作',
        key: 'action',
        align: 'center',
        width: 80,
        render: (h, params) => {
          return h('y-button', {
            props: {
              type: 'text',
              size: 'small'
            },
            nativeOn: {
              click: () => { this.edit(params.row._idx) }
            }
          }, '编辑')
        }
      }]
      const keys = []
      if (dataList.length) {
        for (const key in dataList[0]) {
          if (!keys.includes(key)) {
            keys.push(key)
          }
        }
      }
      for (const key of keys) {
        columns.push({
          title: key,
          key: key,
          width: 150,
          render: (h, params) => { return h('div', {class: 'm-nowrap', attrs: {title: params.row[key]}}, params.row[key]) }
        })
      }
      return columns
    },
    edit (_idx) {
      this.editData = this.$refs.table.getDataById(_idx)
      this.showEditWin = true
    },
    onToggleShowSort () {
      this.showSort = !this.showSort
    },
    onHideSort (e) {
      if (!this.$refs.sortPanel.contains(e.target)) {
        this.showSort = false
      }
    },
    onPageChange (pageIndex) {
      if (this.pIndexAlterBySearch) return

      this.pagination.pageIndex = pageIndex - 1
      this.searchMongo()
    },
    onPagesizeChange (pageSize) {
      this.pagination.pageSize = pageSize
      this.searchMongo()
    },
    onDataEdit (data) {
      this.updateCollectionItem(data)
    }
  }
}
</script>
<style lang="less">
.mongo-pg {
  display: flex;
  height: 100%;
}
.m-table-panel {
  border-right: 1px solid #ccc;
}
.m-table-head {
  width: 299px;
  height: 32px;
  line-height: 32px;
  background: #dddfe3;
  display: flex;
  justify-content: space-between;
  .search-icon {
    cursor: pointer;
    font-size: 16px;
    padding: 8px 10px;
    margin: 0 6px;
  }
  &.active {
    background-color: #91aae1;
  }
}
.m-table-list {
  overflow-y: auto;
}
.m-table-item {
  padding-left: 15px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  &:hover {
    background-color: #b6c1d8;
    color: #fff;
  }
  &.active {
    background-color: #9faac2;
    color: #fff;
  }
}
.m-query-panel {
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #7d818a;
  .sql {
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid #e4edff;
    padding-top: 4px;
    padding-left: 4px;
  }
}
.m-data-panel {
  .actions {
    display: flex;
    padding-top: 10px;
    padding-bottom: 16px;
  }
}
.m-item {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 16px;
}
.m-btn-left > button{
  margin-right: 10px;
}
.m-sorts-panel {
  position: absolute;
  top: 32px;
  left: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0,0,0, 0.35);
}
.m-nowrap {
  white-space: nowrap;
  overflow: hidden;
}
.m-pagination {
  padding-top: 10px;
}
</style>
