<template>
  <span class="node-content" :class="{focused: node.focused}">
    <label class="checkbox-wrapper" :for="node.key" v-if="node.type === 'root' || (node.type === 'field' && node.data.fid)">
      <span class="checkbox" :class="{'checkbox-checked': node.checked}">
        <span class="checkbox-inner"></span>
        <input type="checkbox" class="checkbox-input" :id="node.key" v-model="node.checked">
      </span>
    </label>
    <span class="node-content-label" :class="[node.type]">{{ node.label || node.type }}
      <span class="value" v-if="node.data.valueType === 'number' && (node.data.value || node.data.value === 0)">[{{node.data.value}}]</span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    node: {
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      //
    }
  },
  watch: {
    'node.checked' (val) {
      if (val && (this.node.type === 'item' || this.node.type === 'gitem') && this.node.data.desktop) {
        this.desktop = this.node.data.desktop
      }
      this.emitCheckChange(val)
    }
  },
  methods: {
    emitCheckChange (val) {
      this.$emit('on-node-check-change', val)
    }
  }
}
</script>

<style lang="css">
</style>
