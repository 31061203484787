<template>
  <div>
    <div :style="{overflow: 'auto', height: height ? height+'px' : pageHeight + 'px'}">
      <quest-item-input-table-header v-if="showInputTableHeader"></quest-item-input-table-header>
      <template v-for="item in questItemList">
        <quest-item-input-table v-if="item.type==='input_table'"
          v-model="questItemData[item.key]"
          :id="item.fid"
          :key="item.key"
          :role="role"
          :title="item.title"
          :items="item.items"
          :triggeredItems="item.triggeredItems"
          @on-change="onChange"></quest-item-input-table>
        <quest-item-input v-if="item.type==='input'"
          v-model="questItemData[item.key]"
          :id="item.fid"
          :key="item.key"
          :role="role"
          :title="item.title"
          :items="item.items"
          :hideTitle="item.hideTitle"
          :triggeredItems="item.triggeredItems"
          :copyable="item.copyable"
          :removeable="item.removeable"
          :calcScore="item.calcScore"
          @on-change="onChange"></quest-item-input>
        <quest-item-sselect v-if="item.type==='sselect' || item.type==='sselect_inline'"
          v-model="questItemData[item.key]"
          :id="item.fid"
          :key="item.key"
          ref="sselect"
          :inline="item.type==='sselect_inline'"
          :role="role"
          :title="item.title"
          :items="item.items"
          :required="item.required"
          :triggeredItems="item.triggeredItems"
          :scoreIndex="item.scoreIndex"
          @on-change="onChange"></quest-item-sselect>
        <quest-item-sselect-show v-if="item.type==='sselect_show_quest'"
          v-model="questItemData[item.key]"
          :id="item.fid"
          :key="item.key"
          ref="sselectShow"
          :inline="item.type==='sselect_inline'"
          :role="role"
          :title="item.title"
          :items="item.items"
          :questId="questId"
          :patientId="patientId"
          :crfId="crfId"
          :triggeredItems="item.triggeredItems"
          @on-data-change="onQuestItemListDataChange"
          @on-change="onChange"></quest-item-sselect-show>
        <quest-item-mselect v-if="item.type==='mselect'"
          v-model="questItemData[item.key]"
          :id="item.fid"
          :key="item.key"
          ref="mselect"
          :role="role"
          :title="item.title"
          :items="item.items"
          :required="item.required"
          :triggeredItems="item.triggeredItems"
          @on-change="onChange"></quest-item-mselect>
        <quest-item-mselect-input v-if="item.type==='mselect_input'"
          v-model="questItemData[item.key]"
          ref="mselectInput"
          :id="item.fid"
          :key="item.key"
          :role="role"
          :title="item.title"
          :items="item.items"
          :required="item.required"
          :triggeredItems="item.triggeredItems"
          @on-change="onChange"></quest-item-mselect-input>
        <quest-item-show v-if="item.type==='show'"
          v-model="questItemData[item.key]"
          :id="item.fid"
          :key="item.key"
          :role="role"
          :title="item.title"
          :items="item.items"
          :triggeredItems="item.triggeredItems"
          @on-change="onShowChange"></quest-item-show>
        <quest-item-upload v-if="item.type==='file'"
          v-model="questItemData[item.key]"
          :id="item.fid"
          :key="item.key"
          :role="role"
          :title="item.title"
          :items="item.items"
          :triggeredItems="item.triggeredItems"
          @on-change="onChange"></quest-item-upload>
      </template>
    </div>
  </div>
</template>

<script>
import {
  QuestItemInputTableHeader,
  QuestItemInputTable,
  QuestItemInput,
  QuestItemSselect,
  QuestItemSselectShow,
  QuestItemMselect,
  QuestItemMselectInput,
  QuestItemShow,
  QuestItemUpload
} from '../../lib/questlib-all'
// Vue全局注册防止递归引用render, 供quest-item-list中使用, quest-item-list不再局部注册quest-item-sselect-show
// 因quest-item-sselect-show中使用了quest-item-list
import Vue from 'vue'
Vue.component('QuestItemSselectShow', QuestItemSselectShow)

import Emitter from './mixins/emitter'

export default {
  name: 'QuestItemList',
  mixins: [Emitter],
  props: {
    title: String,
    role: {
      type: String,
      default: 'test'
    },
    questId: {
      type: String,
      default: 'projectId'
    },
    patientId: {
      type: String,
      default: 'test'
    },
    crfId: {
      type: String,
      default: 'unknown'
    },
    hasScore: Boolean,
    modelId: String,
    questItemList: {
      type: Array,
      default () { return [] }
    },
    data: {
      type: Object,
      default () { return {} }
    },
    height: Number
  },
  data () {
    return {
      // role: '',
      // title: '',
      // questId: '',
      // modelId: '',
      // hasScore: false,
      // valueId: '',
      // collection: '',
      // questItemList: [],
      questItemData: {}
    }
  },
  computed: {
    pageHeight () {
      return this.$store.getters.getWinHeight - 60
    },
    showInputTableHeader () {
      let show = false
      this.questItemList.forEach(item => {
        if (item.type === 'input_table') {
          show = true
        }
      })
      return show
    }
  },
  watch: {
    data (val) {
      this.questItemData = val
    }
  },
  created () {
    this.$on('on-score-change', (scores) => {
      this.broadcast('ShowScore', 'on-score-update', scores)
    })
    this.$on('on-trigger', (triggerId, status) => {
      this.broadcast('QuestItemMselectInput', 'on-triggered', triggerId, status)
    })
  },
  // mounted () {
  //   this.updateRole()
  //   this.updatehasScore()
  //   this.loadItemList()
  //   this.loadItemData()
  // },
  methods: {
    onShowChange (val) {
      console.log('on-show-change')
      console.log(this.questItemData, val)
    },
    onChange (val) {
      console.log(this.questItemData, val)
      if (this.hasScore) {
        let scores = this.getScore()
        console.log('score is ', scores)
        if (this.hasAllScoreSelect(scores)) {
          this.$emit('on-score-change', scores)
        }
      }
    },
    onQuestItemListDataChange (hasChange) {
      //
    },
    getQuestItemByKey (key) {
      let _item
      this.questItemList.forEach(item => {
        if (item.key === key) {
          _item = item
        }
      })
      return _item
    },
    getScore () {
      let score = []
      Object.keys(this.questItemData || {}).forEach(key => {
        const item = this.getQuestItemByKey(key)
        if (item && item.fieldType === 'score') {
          score.push({'v': Number(this.questItemData[key].value), 'i': Number(this.questItemData[key].scoreIndex || '')})
        }
      })
      return score
    },
    hasAllScoreSelect (score) {
      let modelScoreCount = 0
      this.questItemList.forEach(item => {
        if (item.fieldType === 'score') {
          modelScoreCount++
        }
      })
      if (score.length === modelScoreCount) {
        return true
      }
      return false
    }
  },
  components: {
    QuestItemInputTableHeader,
    QuestItemInputTable,
    QuestItemSselect,
    QuestItemSselectShow,
    QuestItemMselect,
    QuestItemMselectInput,
    QuestItemInput,
    QuestItemShow,
    QuestItemUpload
  }
}
</script>
