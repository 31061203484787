let strawboxid = 500

export default class Strawbox {
  constructor (options) {
    this.id = ++strawboxid
    this._type = 'strawbox'
    this.type = 'normal'
    this.exboxType = ''
    this.label = ''
    this.index = 0 // 序号
    this.enable = true
    this.capacity = 100
    this.selected = false
    this.store = null
    this.parent = null
    this.$container = null

    for (let name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name]
      }
    }
    if (this.store) {
      this.$container = this.store.$container
    }
  }

  select (isSelect, reSelect) {
    if (!this.enable) return
    if (!this.selected && isSelect) {
      this.selected = true
      this.parent.updateSelectedStrawbox(this.id)
      this.store.updateContainerPath(this)
      this.$container.$emit('on-strawbox-code-selected', this)
      this.$container.$emit('on-path-change')
    } else if (this.selected && !isSelect) {
      this.selected = false
    } else if (this.selected && isSelect && reSelect) {
      this.parent.updateSelectedStrawbox(this.id)
      this.store.updateContainerPath(this)
      this.$container.$emit('on-strawbox-code-selected', this)
      this.$container.$emit('on-path-change')
    }
  }
}
