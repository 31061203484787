<template>
  <div class="select" :class="{'select-visible': visible, 'select-disabled': disabled,
      'select-multiple': multiple, 'select-single': !multiple, 'select-show-clear': showCloseIcon}" v-clickoutside="handleClose">
    <div class="select-selection" @click="toggleShow">
      <div class="tag" v-for="(item, index) in selectedMultiple" :key="index">
        <span class="tag-text">{{ getLabelField(item) }}</span>
        <Icon type="ios-close-empty" @click.native.stop="removeTag(index)"></Icon>
      </div>
      <span class="select-placeholder" v-show="showPlaceholder">{{ placeholder }}</span>
      <span class="select-selected-value" v-show="!showPlaceholder && !multiple">{{ getLabelField(selectedSingle) }}</span>
      <Icon type="ios-close" class="select-arrow" v-show="showCloseIcon" @click.native.stop="clearSingleSelect"></Icon>
      <Icon type="arrow-down-b" class="select-arrow"></Icon>
    </div>
    <transition name="slide-up">
      <Drop v-show="visible" ref="dropdown" class="select-tree-dropdown-list">
        <group-tree
          ref="tree"
          :data="data"
          :props="treeProps"
          :labelField="labelField"
          :valueField="valueField"
          :showField="showField"
          :multiple="multiple"
          :showRootNode="false"
          :showCheckbox="showCheckbox"
          @on-tree-node-click="onTreeNodeClick"
          @on-tree-node-check="onTreeNodeCheck"
          @on-tree-updated="onTreeUpdated">
        </group-tree>
      </Drop>
    </transition>
  </div>
</template>

<script>
import GroupTree from './group/tree'
import Icon from '../icon/icon'
import Drop from './dropdown.vue'
import clickoutside from '../directives/clickoutside'
import Emitter from '../mixins/emitter'
import dataMixin from './mixin'

export default {
  name: 'Select',
  mixins: [ Emitter, dataMixin ],
  components: { GroupTree, Icon, Drop },
  directives: { clickoutside },
  props: {
    value: {
      type: [String, Number, Array, Boolean],
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    labelField: String,
    valueField: String,
    showField: String,
    data: Array,
    showValue: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: false,
      selectedSingle: {},
      selectedMultiple: [],
      currentValue: this.initCurrentValue(this.value)  // value 字符串 或者 value 字符串数组
    }
  },
  computed: {
    showPlaceholder () {
      if (typeof this.currentValue === 'string' && this.currentValue === '') {
        return true
      } else if (Array.isArray(this.currentValue) && !this.currentValue.length) {
        return true
      }
      return false
    },
    showCloseIcon () {
      return !this.multiple && this.clearable && !this.showPlaceholder
    },
    treeProps () {
      return {
        children: 'children',
        label: this.labelField || 'label',
        value: this.valueField || 'value',
        key: 'id'
      }
    }
  },
  watch: {
    value (val) {
      if (JSON.stringify(val) !== JSON.stringify(this.currentValue)) {
        this.currentValue = val
        if (this.multiple && typeof val === 'string') {
          this.currentValue = [val]
        }
        this.initTreeNodeStatus()
      }
    },
    currentValue (val) {
      this.$emit('input', val)
      if (this.multiple) {
        this.$emit('on-select-change', this.selectedMultiple)
      } else {
        this.$emit('on-select-change', this.selectedSingle)
      }
    }
  },
  mounted () {
    this.initTreeNodeStatus()
  },
  methods: {
    initCurrentValue (value) {
      if (this.multiple) {
        if (value) {
          if (Array.isArray(value)) return value
          if (typeof value === 'string') return [value]
        } else {
          return []
        }
      } else {
        if (value) {
          if (typeof value === 'string') {
            return value
          } else {
            return ''
          }
        } else {
          return ''
        }
      }
    },
    initTreeNodeStatus () {
      if (this.multiple) {
        this.$refs.tree.clearStatus()
        let datas = []
        this.currentValue.forEach(value => {
          this.$refs.tree.focusNodeByDataValue(value)
          let data = this.$refs.tree.getNodeDataByDataValue(value)
          if (data) {
            datas.push(data)
          }
        })
        this.selectedMultiple = datas
      } else {
        this.$refs.tree.clearStatus()
        this.$refs.tree.focusNodeByDataValue(this.currentValue)
        let data = this.$refs.tree.getNodeDataByDataValue(this.currentValue)
        if (data) {
          this.selectedSingle = data
        }
      }
    },
    updateTreeNodeStatus () {
      if (this.multiple) {
        this.$refs.tree.clearStatus()
        this.currentValue.forEach(value => {
          this.$refs.tree.focusNodeByDataValue(value)
        })
      } else {
        this.$refs.tree.clearStatus()
        this.$refs.tree.focusNodeByDataValue(this.currentValue)
      }
    },
    toggleShow () {
      if (this.disabled) {
        return false
      }
      this.visible = !this.visible
    },
    hide () {
      this.visible = false
    },
    onTreeUpdated () {
      this.initTreeNodeStatus()
    },
    onTreeNodeClick (nodeData, status) {
      // status 在 multipe 的时候支持 toggle 选择
      let data = JSON.parse(JSON.stringify(nodeData))
      delete data['children']
      console.log(JSON.stringify(data))
      if (!this.multiple) {
        this.selectedSingle = data
        this.currentValue = this.getValueField(data)
        this.hide()
      } else {
        // 支持 toggle 多选
        if (status) {
          let found = false
          this.selectedMultiple.forEach(item => {
            if (JSON.stringify(item) === JSON.stringify(data)) found = true
          })
          if (!found) this.selectedMultiple.push(data)
          let values = []
          this.selectedMultiple.forEach(item => {
            values.push(this.getValueField(item))
          })
          this.currentValue = values
        } else {
          let index = -1
          this.selectedMultiple.forEach((item, idx) => {
            if (JSON.stringify(item) === JSON.stringify(data)) index = idx
          })
          if (index >= 0) {
            this.removeTag(index)
          }
        }
      }
    },
    onTreeNodeCheck () {
      //
    },
    clearSingleSelect () {
      if (this.showCloseIcon && !this.disabled) {
        this.selectedSingle = ''
        this.currentValue = ''
        this.$refs.tree.clearStatus()
      }
    },
    removeTag (index) {
      if (this.disabled) return
      this.selectedMultiple.splice(index, 1)
      this.currentValue.splice(index, 1)
      this.updateTreeNodeStatus()
    },
    handleClose () {
      this.hide()
    }
  }
}
</script>
<style lang="less">
.select-dropdown.select-tree-dropdown-list {
  padding: 0;
  max-height: 250px;
  .field-tree {
    border: 0;
    overflow-x: auto;
  }
}
</style>
