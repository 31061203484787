<template>
  <div class="patient-select">
    <div class="patient-list">
      <div class="patient-item" v-for="item in selectPatient" :key="item.id">
        <span class="name">{{item.name}}</span>
        <span class="ionic-icon ionic-icon-ios-close" @click.stop="removePatient(item)"></span>
      </div>
      <div class="patient-search">
        <y-select
          v-model="currentUniqueCode"
          :data="patientList"
          :toggleShow="false"
          placeholder="请输入"
          clearable
          show-value
          valueField="uniqueCode"
          attrInValue
          @on-select-change="onPatientChange"
          filterable
          remote
          :load-method="loadPatientList"></y-select>
      </div>
    </div>
  </div>
</template>
<script>
import YSelect from '../../common/select/select'
import Emitter from '../../mixins/emitter'
import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  components: {YSelect},
  mixins: [Emitter],
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      currentUniqueCode: '',
      selectPatient: [],
      patientList: []
    }
  },
  watch: {
    value (val, oldVal) {
      if (val && JSON.stringify(oldVal) !== JSON.stringify(val)) {
        this.selectPatient = val
        if (!val || !val.length) {
          this.currentUniqueCode = ''
        }
      }
    },
    selectPatient (val) {
      let result = val
      this.$emit('input', result)
      this.dispatch('FormItem', 'on-form-change', result)
      this.$emit('on-change', result)
    }
  },
  mounted () {
    this.loadPatientList()
  },
  methods: {
    onPatientChange (val) {
      if (val && val.id) {
        let found = false
        this.selectPatient.forEach(item => {
          if (item.id === val.id) {
            found = true
          }
        })
        if (!found) {
          this.selectPatient.push(val)
        }
      }
    },
    removePatient (val) {
      let idx = -1
      this.selectPatient.forEach((item, index) => {
        if (item.id === val.id) {
          idx = index
        }
      })
      if (idx >= 0) {
        this.selectPatient.splice(idx, 1)
      }
    },
    loadPatientList (query, callback) {
      this.$http.get(`${this.httpRoot}/exchange/patient/search`, authenticate({params: {
        query,
        projectId: this.$store.state.project.projectId
      }}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback(res.patientList)
          } else {
            this.patientList = res.patientList || []
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>
<style lang="less">
.patient-list {
  display: flex;
  flex-wrap: wrap;
}
.patient-item {
  padding: 0 10px;
  background-color: #f1f1f1;
  height: 32px;
  line-height: 32px;
  margin-right: 10px;
  margin-bottom: 4px;
  position: relative;
  .ionic-icon {
    position: absolute;
    top: -12px;
    right: -2px;
    font-size: 14px;
    color: #888;
    cursor: pointer;
  }
}
.patient-search {
  width: 250px;
}
</style>
