
'use strict';

var root = window;

// default options
var DEFAULTS = {
    // placement of the popper
    placement: 'bottom',

    gpuAcceleration: true,

    // shift popper from its origin by the given amount of pixels (can be negative)
    offset: 0,

    // the element which will act as boundary of the popper
    boundariesElement: 'viewport',

    // amount of pixel used to define a minimum distance between the boundaries and the popper
    boundariesPadding: 5,

    // popper will try to prevent overflow following this order,
    // by default, then, it could overflow on the left and on top of the boundariesElement
    preventOverflowOrder: ['left', 'right', 'top', 'bottom'],

    // the behavior used by flip to change the placement of the popper
    flipBehavior: 'flip',

    arrowElement: '[x-arrow]',

    // list of functions used to modify the offsets before they are applied to the popper
    modifiers: [ 'shift', 'offset', 'preventOverflow', 'keepTogether', 'arrow', 'flip', 'applyStyle'],

    modifiersIgnored: [],

    forceAbsolute: false
};

/**
 * Create a new Popper.js instance
 * @constructor Popper
 * @param {HTMLElement} reference - The reference element used to position the popper
 * @param {HTMLElement|Object} popper
 *      The HTML element used as popper, or a configuration used to generate the popper.
 * @param {String} [popper.tagName='div'] The tag name of the generated popper.
 * @param {Array} [popper.classNames=['popper']] Array of classes to apply to the generated popper.
 * @param {Array} [popper.attributes] Array of attributes to apply, specify `attr:value` to assign a value to it.
 * @param {HTMLElement|String} [popper.parent=window.document.body] The parent element, given as HTMLElement or as query string.
 * @param {String} [popper.content=''] The content of the popper, it can be text, html, or node; if it is not text, set `contentType` to `html` or `node`.
 * @param {String} [popper.contentType='text'] If `html`, the `content` will be parsed as HTML. If `node`, it will be appended as-is.
 * @param {String} [popper.arrowTagName='div'] Same as `popper.tagName` but for the arrow element.
 * @param {Array} [popper.arrowClassNames='popper__arrow'] Same as `popper.classNames` but for the arrow element.
 * @param {String} [popper.arrowAttributes=['x-arrow']] Same as `popper.attributes` but for the arrow element.
 * @param {Object} options
 * @param {String} [options.placement=bottom]
 *      Placement of the popper accepted values: `top(-start, -end), right(-start, -end), bottom(-start, -right),
 *      left(-start, -end)`
 *
 * @param {HTMLElement|String} [options.arrowElement='[x-arrow]']
 *      The DOM Node used as arrow for the popper, or a CSS selector used to get the DOM node. It must be child of
 *      its parent Popper. Popper.js will apply to the given element the style required to align the arrow with its
 *      reference element.
 *      By default, it will look for a child node of the popper with the `x-arrow` attribute.
 *
 * @param {Boolean} [options.gpuAcceleration=true]
 *      When this property is set to true, the popper position will be applied using CSS3 translate3d, allowing the
 *      browser to use the GPU to accelerate the rendering.
 *      If set to false, the popper will be placed using `top` and `left` properties, not using the GPU.
 *
 * @param {Number} [options.offset=0]
 *      Amount of pixels the popper will be shifted (can be negative).
 *
 * @param {String|Element} [options.boundariesElement='viewport']
 *      The element which will define the boundaries of the popper position, the popper will never be placed outside
 *      of the defined boundaries (except if `keepTogether` is enabled)
 *
 * @param {Number} [options.boundariesPadding=5]
 *      Additional padding for the boundaries
 *
 * @param {Array} [options.preventOverflowOrder=['left', 'right', 'top', 'bottom']]
 *      Order used when Popper.js tries to avoid overflows from the boundaries, they will be checked in order,
 *      this means that the last ones will never overflow
 *
 * @param {String|Array} [options.flipBehavior='flip']
 *      The behavior used by the `flip` modifier to change the placement of the popper when the latter is trying to
 *      overlap its reference element. Defining `flip` as value, the placement will be flipped on
 *      its axis (`right - left`, `top - bottom`).
 *      You can even pass an array of placements (eg: `['right', 'left', 'top']` ) to manually specify
 *      how alter the placement when a flip is needed. (eg. in the above example, it would first flip from right to left,
 *      then, if even in its new placement, the popper is overlapping its reference element, it will be moved to top)
 *
 * @param {Array} [options.modifiers=[ 'shift', 'offset', 'preventOverflow', 'keepTogether', 'arrow', 'flip', 'applyStyle']]
 *      List of functions used to modify the data before they are applied to the popper, add your custom functions
 *      to this array to edit the offsets and placement.
 *      The function should reflect the @params and @returns of preventOverflow
 *
 * @param {Array} [options.modifiersIgnored=[]]
 *      Put here any built-in modifier name you want to exclude from the modifiers list
 *      The function should reflect the @params and @returns of preventOverflow
 *
 * @param {Boolean} [options.removeOnDestroy=false]
 *      Set to true if you want to automatically remove the popper when you call the `destroy` method.
 */
function Popper(reference, popper, options) {
    this._reference = reference.jquery ? reference[0] : reference;
    this.state = { onCreateCalled: false };

    // if the popper variable is a configuration object, parse it to generate an HTMLElement
    // generate a default popper if is not defined
    var isNotDefined = typeof popper === 'undefined' || popper === null;
    var isConfig = popper && Object.prototype.toString.call(popper) === '[object Object]';
    if (isNotDefined || isConfig) {
        this._popper = this.parse(isConfig ? popper : {});
    }
    // otherwise, use the given HTMLElement as popper
    else {
        this._popper = popper.jquery ? popper[0] : popper;
    }

    // with {} we create a new object with the options inside it
    this._options = Object.assign({}, DEFAULTS, options);

    // refactoring modifiers' list
    this._options.modifiers = this._options.modifiers.map(function(modifier){
        // remove ignored modifiers
        if (this._options.modifiersIgnored.indexOf(modifier) !== -1) return;

        // set the x-placement attribute before everything else because it could be used to add margins to the popper
        // margins needs to be calculated to get the correct popper offsets
        if (modifier === 'applyStyle') {
            this._popper.setAttribute('x-placement', this._options.placement);
        }

        // return predefined modifier identified by string or keep the custom one
        return this.modifiers[modifier] || modifier;
    }.bind(this));

    // make sure to apply the popper position before any computation
    this.state.position = this._getPosition(this._popper, this._reference);
    setStyle(this._popper, { position: this.state.position});

    // determine how we should set the origin of offsets
    this.state.isParentTransformed = this._getIsParentTransformed(this._popper);

    // fire the first update to position the popper in the right place
    this.update();

    // setup event listeners, they will take care of update the position in specific situations
    this._setupEventListeners();
    return this;
}


//
// Methods
//
/**
 * Destroy the popper
 * @method
 * @memberof Popper
 */
Popper.prototype.destroy = function() {
    this._popper.removeAttribute('x-placement');
    this._popper.style.left = '';
    this._popper.style.position = '';
    this._popper.style.top = '';
    this._popper.style[getSupportedPropertyName('transform')] = '';
    this._removeEventListeners();

    // remove the popper if user explicity asked for the deletion on destroy
    if (this._options.removeOnDestroy) {
        this._popper.parentNode.removeChild(this._popper);
    }
    return this;
};

/**
 * Updates the position of the popper, computing the new offsets and applying the new style
 * @method
 * @memberof Popper
 */
Popper.prototype.update = function() {
    var data = { instance: this, styles: {} };

    // make sure to apply the popper position before any computation
    this.state.position = this._getPosition(this._popper, this._reference);
    setStyle(this._popper, { position: this.state.position});

    // to avoid useless computations we throttle the popper position refresh to 60fps
    root.requestAnimationFrame(function() {
        var now = root.performance.now();
        if(now - this.state.lastFrame <= 16) {
            // this update fired to early! drop it
            return;
        }
        this.state.lastFrame = now;

        // store placement inside the data object, modifiers will be able to edit `placement` if needed
        // and refer to _originalPlacement to know the original value
        data.placement = this._options.placement;
        data._originalPlacement = this._options.placement;

        // compute the popper and trigger offsets and put them inside data.offsets
        data.offsets = this._getOffsets(this._popper, this._reference, data.placement);

        // get boundaries
        data.boundaries = this._getBoundaries(data, this._options.boundariesPadding, this._options.boundariesElement);

        data = this.runModifiers(data, this._options.modifiers);

        if (!isFunction(this.state.createCalback)) {
            this.state.onCreateCalled = true;
        }
        if (!this.state.onCreateCalled) {
            this.state.onCreateCalled = true;
            if (isFunction(this.state.createCalback)) {
                this.state.createCalback(this);
            }
        } else if (isFunction(this.state.updateCallback)) {
            this.state.updateCallback(data);
        }
    }.bind(this));
};

/**
 * If a function is passed, it will be executed after the initialization of popper with as first argument the Popper instance.
 * @method
 * @memberof Popper
 * @param {Function} callback
 */
Popper.prototype.onCreate = function(callback) {
    // the createCallbacks return as first argument the popper instance
    this.state.createCalback = callback;
    return this;
};

/**
 * If a function is passed, it will be executed after each update of popper with as first argument the set of coordinates and informations
 * used to style popper and its arrow.
 * NOTE: it doesn't get fired on the first call of the `Popper.update()` method inside the `Popper` constructor!
 * @method
 * @memberof Popper
 * @param {Function} callback
 */
Popper.prototype.onUpdate = function(callback) {
    this.state.updateCallback = callback;
    return this;
};

/**
 * Helper used to generate poppers from a configuration file
 * @method
 * @memberof Popper
 * @param config {Object} configuration
 * @returns {HTMLElement} popper
 */
Popper.prototype.parse = function(config) {
    var defaultConfig = {
        tagName: 'div',
        classNames: [ 'popper' ],
        attributes: [],
        parent: root.document.body,
        content: '',
        contentType: 'text',
        arrowTagName: 'div',
        arrowClassNames: [ 'popper__arrow' ],
        arrowAttributes: [ 'x-arrow']
    };
    config = Object.assign({}, defaultConfig, config);

    var d = root.document;

    var popper = d.createElement(config.tagName);
    addClassNames(popper, config.classNames);
    addAttributes(popper, config.attributes);
    if (config.contentType === 'node') {
        popper.appendChild(config.content.jquery ? config.content[0] : config.content);
    }else if (config.contentType === 'html') {
        popper.innerHTML = config.content;
    } else {
        popper.textContent = config.content;
    }

    if (config.arrowTagName) {
        var arrow = d.createElement(config.arrowTagName);
        addClassNames(arrow, config.arrowClassNames);
        addAttributes(arrow, config.arrowAttributes);
        popper.appendChild(arrow);
    }

    var parent = config.parent.jquery ? config.parent[0] : config.parent;

    // if the given parent is a string, use it to match an element
    // if more than one element is matched, the first one will be used as parent
    // if no elements are matched, the script will throw an error
    if (typeof parent === 'string') {
        parent = d.querySelectorAll(config.parent);
        if (parent.length > 1) {
            console.warn('WARNING: the given `parent` query(' + config.parent + ') matched more than one element, the first one will be used');
        }
        if (parent.length === 0) {
            throw 'ERROR: the given `parent` doesn\'t exists!';
        }
        parent = parent[0];
    }
    // if the given parent is a DOM nodes list or an array of nodes with more than one element,
    // the first one will be used as parent
    if (parent.length > 1 && parent instanceof Element === false) {
        console.warn('WARNING: you have passed as parent a list of elements, the first one will be used');
        parent = parent[0];
    }

    // append the generated popper to its parent
    parent.appendChild(popper);

    return popper;

    /**
     * Adds class names to the given element
     * @function
     * @ignore
     * @param {HTMLElement} target
     * @param {Array} classes
     */
    function addClassNames(element, classNames) {
        classNames.forEach(function(className) {
            element.classList.add(className);
        });
    }

    /**
     * Adds attributes to the given element
     * @function
     * @ignore
     * @param {HTMLElement} target
     * @param {Array} attributes
     * @example
     * addAttributes(element, [ 'data-info:foobar' ]);
     */
    function addAttributes(element, attributes) {
        attributes.forEach(function(attribute) {
            element.setAttribute(attribute.split(':')[0], attribute.split(':')[1] || '');
        });
    }

};

/**
 * Helper used to get the position which will be applied to the popper
 * @method
 * @memberof Popper
 * @param config {HTMLElement} popper element
 * @returns {HTMLElement} reference element
 */
Popper.prototype._getPosition = function(popper, reference) {
    var container = getOffsetParent(reference);
    // added by zh_qd 2018-12-25
    if (this._options.forceAbsolute) {
        return 'absolute';
    }

    // Decide if the popper will be fixed
    // If the reference element is inside a fixed context, the popper will be fixed as well to allow them to scroll together
    var isParentFixed = isFixed(container);
    return isParentFixed ? 'fixed' : 'absolute';
};

/**
 * Helper used to determine if the popper's parent is transformed.
 * @param  {[type]} popper [description]
 * @return {[type]}        [description]
 */
Popper.prototype._getIsParentTransformed = function(popper) {
  return isTransformed(popper.parentNode);
};

/**
 * Get offsets to the popper
 * @method
 * @memberof Popper
 * @access private
 * @param {Element} popper - the popper element
 * @param {Element} reference - the reference element (the popper will be relative to this)
 * @returns {Object} An object containing the offsets which will be applied to the popper
 */
Popper.prototype._getOffsets = function(popper, reference, placement) {
    placement = placement.split('-')[0];
    var popperOffsets = {};

    popperOffsets.position = this.state.position;
    var isParentFixed = popperOffsets.position === 'fixed';

    var isParentTransformed = this.state.isParentTransformed;

    //
    // Get reference element position
    //
    var offsetParent = (isParentFixed && isParentTransformed) ? getOffsetParent(reference) : getOffsetParent(popper);
    var referenceOffsets = getOffsetRectRelativeToCustomParent(reference, offsetParent, isParentFixed, isParentTransformed);

    //
    // Get popper sizes
    //
    var popperRect = getOuterSizes(popper);

    //
    // Compute offsets of popper
    //

    // depending by the popper placement we have to compute its offsets slightly differently
    if (['right', 'left'].indexOf(placement) !== -1) {
        popperOffsets.top = referenceOffsets.top + referenceOffsets.height / 2 - popperRect.height / 2;
        if (placement === 'left') {
            popperOffsets.left = referenceOffsets.left - popperRect.width;
        } else {
            popperOffsets.left = referenceOffsets.right;
        }
    } else {
        popperOffsets.left = referenceOffsets.left + referenceOffsets.width / 2 - popperRect.width / 2;
        if (placement === 'top') {
            popperOffsets.top = referenceOffsets.top - popperRect.height;
        } else {
            popperOffsets.top = referenceOffsets.bottom;
        }
    }

    // Add width and height to our offsets object
    popperOffsets.width   = popperRect.width;
    popperOffsets.height  = popperRect.height;


    return {
        popper: popperOffsets,
        reference: referenceOffsets
    };
};


/**
 * Setup needed event listeners used to update the popper position
 * @method
 * @memberof Popper
 * @access private
 */
Popper.prototype._setupEventListeners = function() {
    // NOTE: 1 DOM access here
    this.state.updateBound = this.update.bind(this);
    root.addEventListener('resize', this.state.updateBound);
    // if the boundariesElement is window we don't need to listen for the scroll event
    if (this._options.boundariesElement !== 'window') {
        var target = getScrollParent(this._reference);
        // here it could be both `body` or `documentElement` thanks to Firefox, we then check both
        if (target === root.document.body || target === root.document.documentElement) {
            target = root;
        }
        target.addEventListener('scroll', this.state.updateBound);
    }
};

/**
 * Remove event listeners used to update the popper position
 * @method
 * @memberof Popper
 * @access private
 */
Popper.prototype._removeEventListeners = function() {
    // NOTE: 1 DOM access here
    root.removeEventListener('resize', this.state.updateBound);
    if (this._options.boundariesElement !== 'window') {
        var target = getScrollParent(this._reference);
        // here it could be both `body` or `documentElement` thanks to Firefox, we then check both
        if (target === root.document.body || target === root.document.documentElement) {
            target = root;
        }
        target.removeEventListener('scroll', this.state.updateBound);
    }
    this.state.updateBound = null;
};

/**
 * Computed the boundaries limits and return them
 * @method
 * @memberof Popper
 * @access private
 * @param {Object} data - Object containing the property "offsets" generated by `_getOffsets`
 * @param {Number} padding - Boundaries padding
 * @param {Element} boundariesElement - Element used to define the boundaries
 * @returns {Object} Coordinates of the boundaries
 */
Popper.prototype._getBoundaries = function(data, padding, boundariesElement) {
    // NOTE: 1 DOM access here
    var boundaries = {};
    var width, height;
    if (boundariesElement === 'window') {
        var body = root.document.body,
            html = root.document.documentElement;

        height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
        width = Math.max( body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth );

        boundaries = {
            top: 0,
            right: width,
            bottom: height,
            left: 0
        };
    } else if (boundariesElement === 'viewport') {
        var offsetParent = getOffsetParent(this._popper);
        var scrollParent = getScrollParent(this._popper);
        var offsetParentRect = getOffsetRect(offsetParent);

        // if the popper is fixed we don't have to substract scrolling from the boundaries
        var scrollTop = data.offsets.popper.position === 'fixed' ? 0 : scrollParent.scrollTop;
        var scrollLeft = data.offsets.popper.position === 'fixed' ? 0 : scrollParent.scrollLeft;

        boundaries = {
            top: 0 - (offsetParentRect.top - scrollTop),
            right: root.document.documentElement.clientWidth - (offsetParentRect.left - scrollLeft),
            bottom: root.document.documentElement.clientHeight - (offsetParentRect.top - scrollTop),
            left: 0 - (offsetParentRect.left - scrollLeft)
        };
    } else {
        if (getOffsetParent(this._popper) === boundariesElement) {
            boundaries = {
                top: 0,
                left: 0,
                right: boundariesElement.clientWidth,
                bottom: boundariesElement.clientHeight
            };
        } else {
            boundaries = getOffsetRect(boundariesElement);
        }
    }
    boundaries.left += padding;
    boundaries.right -= padding;
    boundaries.top = boundaries.top + padding;
    boundaries.bottom = boundaries.bottom - padding;
    return boundaries;
};


/**
 * Loop trough the list of modifiers and run them in order, each of them will then edit the data object
 * @method
 * @memberof Popper
 * @access public
 * @param {Object} data
 * @param {Array} modifiers
 * @param {Function} ends
 */
Popper.prototype.runModifiers = function(data, modifiers, ends) {
    var modifiersToRun = modifiers.slice();
    if (ends !== undefined) {
        modifiersToRun = this._options.modifiers.slice(0, getArrayKeyIndex(this._options.modifiers, ends));
    }

    modifiersToRun.forEach(function(modifier) {
        if (isFunction(modifier)) {
            data = modifier.call(this, data);
        }
    }.bind(this));

    return data;
};

/**
 * Helper used to know if the given modifier depends from another one.
 * @method
 * @memberof Popper
 * @returns {Boolean}
 */

Popper.prototype.isModifierRequired = function(requesting, requested) {
    var index = getArrayKeyIndex(this._options.modifiers, requesting);
    return !!this._options.modifiers.slice(0, index).filter(function(modifier) {
        return modifier === requested;
    }).length;
};

//
// Modifiers
//

/**
 * Modifiers list
 * @namespace Popper.modifiers
 * @memberof Popper
 * @type {Object}
 */
Popper.prototype.modifiers = {};

/**
 * Apply the computed styles to the popper element
 * @method
 * @memberof Popper.modifiers
 * @argument {Object} data - The data object generated by `update` method
 * @returns {Object} The same data object
 */
Popper.prototype.modifiers.applyStyle = function(data) {
    // apply the final offsets to the popper
    // NOTE: 1 DOM access here
    var styles = {
        position: data.offsets.popper.position
    };

    // round top and left to avoid blurry text
    var left = Math.round(data.offsets.popper.left);
    var top = Math.round(data.offsets.popper.top);

    // if gpuAcceleration is set to true and transform is supported, we use `translate3d` to apply the position to the popper
    // we automatically use the supported prefixed version if needed
    var prefixedProperty;
    if (this._options.gpuAcceleration && (prefixedProperty = getSupportedPropertyName('transform'))) {
        styles[prefixedProperty] = 'translate3d(' + left + 'px, ' + top + 'px, 0)';
        styles.top = 0;
        styles.left = 0;
    }
    // othwerise, we use the standard `left` and `top` properties
    else {
        styles.left =left;
        styles.top = top;
    }

    // any property present in `data.styles` will be applied to the popper,
    // in this way we can make the 3rd party modifiers add custom styles to it
    // Be aware, modifiers could override the properties defined in the previous
    // lines of this modifier!
    Object.assign(styles, data.styles);

    setStyle(this._popper, styles);

    // set an attribute which will be useful to style the tooltip (use it to properly position its arrow)
    // NOTE: 1 DOM access here
    this._popper.setAttribute('x-placement', data.placement);

    // if the arrow style has been computed, apply the arrow style
    if (data.offsets.arrow) {
        setStyle(data.arrowElement, data.offsets.arrow);
    }

    // return the data object to allow chaining of other modifiers
    return data;
};

/**
 * Modifier used to shift the popper on the start or end of its reference element side
 * @method
 * @memberof Popper.modifiers
 * @argument {Object} data - The data object generated by `update` method
 * @returns {Object} The data object, properly modified
 */
Popper.prototype.modifiers.shift = function(data) {
    var placement = data.placement;
    var basePlacement = placement.split('-')[0];
    var shiftVariation = placement.split('-')[1];

    // if shift shiftVariation is specified, run the modifier
    if (shiftVariation) {
        var reference = data.offsets.reference;
        var popper = getPopperClientRect(data.offsets.popper);

        var shiftOffsets = {
            y: {
                start:  { top: reference.top },
                end:    { top: reference.top + reference.height - popper.height }
            },
            x: {
                start:  { left: reference.left },
                end:    { left: reference.left + reference.width - popper.width }
            }
        };

        var axis = ['bottom', 'top'].indexOf(basePlacement) !== -1 ? 'x' : 'y';

        data.offsets.popper = Object.assign(popper, shiftOffsets[axis][shiftVariation]);
    }

    return data;
};


/**
 * Modifier used to make sure the popper does not overflows from it's boundaries
 * @method
 * @memberof Popper.modifiers
 * @argument {Object} data - The data object generated by `update` method
 * @returns {Object} The data object, properly modified
 */
Popper.prototype.modifiers.preventOverflow = function(data) {
    var order = this._options.preventOverflowOrder;
    var popper = getPopperClientRect(data.offsets.popper);

    var check = {
        left: function() {
            var left = popper.left;
            if (popper.left < data.boundaries.left) {
                left = Math.max(popper.left, data.boundaries.left);
            }
            return { left: left };
        },
        right: function() {
            var left = popper.left;
            if (popper.right > data.boundaries.right) {
                left = Math.min(popper.left, data.boundaries.right - popper.width);
            }
            return { left: left };
        },
        top: function() {
            var top = popper.top;
            if (popper.top < data.boundaries.top) {
                top = Math.max(popper.top, data.boundaries.top);
            }
            return { top: top };
        },
        bottom: function() {
            var top = popper.top;
            if (popper.bottom > data.boundaries.bottom) {
                top = Math.min(popper.top, data.boundaries.bottom - popper.height);
            }
            return { top: top };
        }
    };

    order.forEach(function(direction) {
        data.offsets.popper = Object.assign(popper, check[direction]());
    });

    return data;
};

/**
 * Modifier used to make sure the popper is always near its reference
 * @method
 * @memberof Popper.modifiers
 * @argument {Object} data - The data object generated by _update method
 * @returns {Object} The data object, properly modified
 */
Popper.prototype.modifiers.keepTogether = function(data) {
    var popper  = getPopperClientRect(data.offsets.popper);
    var reference = data.offsets.reference;
    var f = Math.floor;

    if (popper.right < f(reference.left)) {
        data.offsets.popper.left = f(reference.left) - popper.width;
    }
    if (popper.left > f(reference.right)) {
        data.offsets.popper.left = f(reference.right);
    }
    if (popper.bottom < f(reference.top)) {
        data.offsets.popper.top = f(reference.top) - popper.height;
    }
    if (popper.top > f(reference.bottom)) {
        data.offsets.popper.top = f(reference.bottom);
    }

    return data;
};

/**
 * Modifier used to flip the placement of the popper when the latter is starting overlapping its reference element.
 * Requires the `preventOverflow` modifier before it in order to work.
 * **NOTE:** This modifier will run all its previous modifiers everytime it tries to flip the popper!
 * @method
 * @memberof Popper.modifiers
 * @argument {Object} data - The data object generated by _update method
 * @returns {Object} The data object, properly modified
 */
Popper.prototype.modifiers.flip = function(data) {
    // check if preventOverflow is in the list of modifiers before the flip modifier.
    // otherwise flip would not work as expected.
    if (!this.isModifierRequired(this.modifiers.flip, this.modifiers.preventOverflow)) {
        console.warn('WARNING: preventOverflow modifier is required by flip modifier in order to work, be sure to include it before flip!');
        return data;
    }

    if (data.flipped && data.placement === data._originalPlacement) {
        // seems like flip is trying to loop, probably there's not enough space on any of the flippable sides
        return data;
    }

    var placement = data.placement.split('-')[0];
    var placementOpposite = getOppositePlacement(placement);
    var variation = data.placement.split('-')[1] || '';

    var flipOrder = [];
    if(this._options.flipBehavior === 'flip') {
        flipOrder = [
            placement,
            placementOpposite
        ];
    } else {
        flipOrder = this._options.flipBehavior;
    }

    flipOrder.forEach(function(step, index) {
        if (placement !== step || flipOrder.length === index + 1) {
            return;
        }

        placement = data.placement.split('-')[0];
        placementOpposite = getOppositePlacement(placement);

        var popperOffsets = getPopperClientRect(data.offsets.popper);

        // this boolean is used to distinguish right and bottom from top and left
        // they need different computations to get flipped
        var a = ['right', 'bottom'].indexOf(placement) !== -1;

        // using Math.floor because the reference offsets may contain decimals we are not going to consider here
        if (
            a && Math.floor(data.offsets.reference[placement]) > Math.floor(popperOffsets[placementOpposite]) ||
            !a && Math.floor(data.offsets.reference[placement]) < Math.floor(popperOffsets[placementOpposite])
        ) {
            // we'll use this boolean to detect any flip loop
            data.flipped = true;
            data.placement = flipOrder[index + 1];
            if (variation) {
                data.placement += '-' + variation;
            }
            data.offsets.popper = this._getOffsets(this._popper, this._reference, data.placement).popper;

            data = this.runModifiers(data, this._options.modifiers, this._flip);
        }
    }.bind(this));
    return data;
};

/**
 * Modifier used to add an offset to the popper, useful if you more granularity positioning your popper.
 * The offsets will shift the popper on the side of its reference element.
 * @method
 * @memberof Popper.modifiers
 * @argument {Object} data - The data object generated by _update method
 * @returns {Object} The data object, properly modified
 */
Popper.prototype.modifiers.offset = function(data) {
    var offset = this._options.offset;
    var popper  = data.offsets.popper;

    if (data.placement.indexOf('left') !== -1) {
        popper.top -= offset;
    }
    else if (data.placement.indexOf('right') !== -1) {
        popper.top += offset;
    }
    else if (data.placement.indexOf('top') !== -1) {
        popper.left -= offset;
    }
    else if (data.placement.indexOf('bottom') !== -1) {
        popper.left += offset;
    }
    return data;
};

/**
 * Modifier used to move the arrows on the edge of the popper to make sure them are always between the popper and the reference element
 * It will use the CSS outer size of the arrow element to know how many pixels of conjuction are needed
 * @method
 * @memberof Popper.modifiers
 * @argument {Object} data - The data object generated by _update method
 * @returns {Object} The data object, properly modified
 */
Popper.prototype.modifiers.arrow = function(data) {
    var arrow  = this._options.arrowElement;

    // if the arrowElement is a string, suppose it's a CSS selector
    if (typeof arrow === 'string') {
        arrow = this._popper.querySelector(arrow);
    }

    // if arrow element is not found, don't run the modifier
    if (!arrow) {
        return data;
    }

    // the arrow element must be child of its popper
    if (!this._popper.contains(arrow)) {
        console.warn('WARNING: `arrowElement` must be child of its popper element!');
        return data;
    }

    // arrow depends on keepTogether in order to work
    if (!this.isModifierRequired(this.modifiers.arrow, this.modifiers.keepTogether)) {
        console.warn('WARNING: keepTogether modifier is required by arrow modifier in order to work, be sure to include it before arrow!');
        return data;
    }

    var arrowStyle  = {};
    var placement   = data.placement.split('-')[0];
    var popper      = getPopperClientRect(data.offsets.popper);
    var reference   = data.offsets.reference;
    var isVertical  = ['left', 'right'].indexOf(placement) !== -1;

    var len         = isVertical ? 'height' : 'width';
    var side        = isVertical ? 'top' : 'left';
    var altSide     = isVertical ? 'left' : 'top';
    var opSide      = isVertical ? 'bottom' : 'right';
    var arrowSize   = getOuterSizes(arrow)[len];

    //
    // extends keepTogether behavior making sure the popper and its reference have enough pixels in conjuction
    //

    // top/left side
    if (reference[opSide] - arrowSize < popper[side]) {
        data.offsets.popper[side] -= popper[side] - (reference[opSide] - arrowSize);
    }
    // bottom/right side
    if (reference[side] + arrowSize > popper[opSide]) {
        data.offsets.popper[side] += (reference[side] + arrowSize) - popper[opSide];
    }

    // compute center of the popper
    var center = reference[side] + (reference[len] / 2) - (arrowSize / 2);

    // Compute the sideValue using the updated popper offsets
    var sideValue = center - getPopperClientRect(data.offsets.popper)[side];

    // prevent arrow from being placed not contiguously to its popper
    sideValue = Math.max(Math.min(popper[len] - arrowSize, sideValue), 0);
    arrowStyle[side] = sideValue;
    arrowStyle[altSide] = ''; // make sure to remove any old style from the arrow

    data.offsets.arrow = arrowStyle;
    data.arrowElement = arrow;

    return data;
};


//
// Helpers
//

/**
 * Get the outer sizes of the given element (offset size + margins)
 * @function
 * @ignore
 * @argument {Element} element
 * @returns {Object} object containing width and height properties
 */
function getOuterSizes(element) {
    // NOTE: 1 DOM access here
    var _display = element.style.display, _visibility = element.style.visibility;
    element.style.display = 'block'; element.style.visibility = 'hidden';
    var calcWidthToForceRepaint = element.offsetWidth;

    // original method
    var styles = root.getComputedStyle(element);
    var x = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
    var y = parseFloat(styles.marginLeft) + parseFloat(styles.marginRight);
    var result = { width: element.offsetWidth + y, height: element.offsetHeight + x };

    // reset element styles
    element.style.display = _display; element.style.visibility = _visibility;
    return result;
}

/**
 * Get the opposite placement of the given one/
 * @function
 * @ignore
 * @argument {String} placement
 * @returns {String} flipped placement
 */
function getOppositePlacement(placement) {
    var hash = {left: 'right', right: 'left', bottom: 'top', top: 'bottom' };
    return placement.replace(/left|right|bottom|top/g, function(matched){
        return hash[matched];
    });
}

/**
 * Given the popper offsets, generate an output similar to getBoundingClientRect
 * @function
 * @ignore
 * @argument {Object} popperOffsets
 * @returns {Object} ClientRect like output
 */
function getPopperClientRect(popperOffsets) {
    var offsets = Object.assign({}, popperOffsets);
    offsets.right = offsets.left + offsets.width;
    offsets.bottom = offsets.top + offsets.height;
    return offsets;
}

/**
 * Given an array and the key to find, returns its index
 * @function
 * @ignore
 * @argument {Array} arr
 * @argument keyToFind
 * @returns index or null
 */
function getArrayKeyIndex(arr, keyToFind) {
    var i = 0, key;
    for (key in arr) {
        if (arr[key] === keyToFind) {
            return i;
        }
        i++;
    }
    return null;
}

/**
 * Get CSS computed property of the given element
 * @function
 * @ignore
 * @argument {Eement} element
 * @argument {String} property
 */
function getStyleComputedProperty(element, property) {
    // NOTE: 1 DOM access here
    var css = root.getComputedStyle(element, null);
    return css[property];
}

/**
 * Returns the offset parent of the given element
 * @function
 * @ignore
 * @argument {Element} element
 * @returns {Element} offset parent
 */
function getOffsetParent(element) {
    // NOTE: 1 DOM access here
    var offsetParent = element.offsetParent;
    return offsetParent === root.document.body || !offsetParent ? root.document.documentElement : offsetParent;
}

/**
 * Returns the scrolling parent of the given element
 * @function
 * @ignore
 * @argument {Element} element
 * @returns {Element} offset parent
 */
function getScrollParent(element) {
    if (element === root.document) {
        // Firefox puts the scrollTOp value on `documentElement` instead of `body`, we then check which of them is
        // greater than 0 and return the proper element
        if (root.document.body.scrollTop) {
            return root.document.body;
        } else {
            return root.document.documentElement;
        }
    }

    // Firefox want us to check `-x` and `-y` variations as well
    if (
        ['scroll', 'auto'].indexOf(getStyleComputedProperty(element, 'overflow')) !== -1 ||
        ['scroll', 'auto'].indexOf(getStyleComputedProperty(element, 'overflow-x')) !== -1 ||
        ['scroll', 'auto'].indexOf(getStyleComputedProperty(element, 'overflow-y')) !== -1
    ) {
        // If the detected scrollParent is body, we perform an additional check on its parentNode
        // in this way we'll get body if the browser is Chrome-ish, or documentElement otherwise
        // fixes issue #65
        return element === root.document.body ? getScrollParent(element.parentNode) : element;
    }
    return element.parentNode ? getScrollParent(element.parentNode) : element;
}

/**
 * Check if the given element is fixed or is inside a fixed parent
 * @function
 * @ignore
 * @argument {Element} element
 * @argument {Element} customContainer
 * @returns {Boolean} answer to "isFixed?"
 */
function isFixed(element) {
    if (element === root.document.body || element.nodeName === 'HTML') {
        return false;
    }
    if (getStyleComputedProperty(element, 'position') === 'fixed') {
        return true;
    }
    return element.parentNode ? isFixed(element.parentNode) : element;
}

/**
 * Check if the given element has transforms applied to itself or a parent
 * @param  {Element} element
 * @return {Boolean} answer to "isTransformed?"
 */
function isTransformed(element) {
  if (element === root.document.body) {
      return false;
  }
  if (getStyleComputedProperty(element, 'transform') !== 'none') {
      return true;
  }
  return element.parentNode ? isTransformed(element.parentNode) : element;
}

/**
 * Set the style to the given popper
 * @function
 * @ignore
 * @argument {Element} element - Element to apply the style to
 * @argument {Object} styles - Object with a list of properties and values which will be applied to the element
 */
function setStyle(element, styles) {
    function is_numeric(n) {
        return (n !== '' && !isNaN(parseFloat(n)) && isFinite(n));
    }
    Object.keys(styles).forEach(function(prop) {
        var unit = '';
        // add unit if the value is numeric and is one of the following
        if (['width', 'height', 'top', 'right', 'bottom', 'left'].indexOf(prop) !== -1 && is_numeric(styles[prop])) {
            unit = 'px';
        }
        element.style[prop] = styles[prop] + unit;
    });
}

/**
 * Check if the given variable is a function
 * @function
 * @ignore
 * @argument {Element} element - Element to check
 * @returns {Boolean} answer to: is a function?
 */
function isFunction(functionToCheck) {
    var getType = {};
    return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
}

/**
 * Get the position of the given element, relative to its offset parent
 * @function
 * @ignore
 * @param {Element} element
 * @return {Object} position - Coordinates of the element and its `scrollTop`
 */
function getOffsetRect(element) {
    var elementRect = {
        width: element.offsetWidth,
        height: element.offsetHeight,
        left: element.offsetLeft,
        top: element.offsetTop
    };

    elementRect.right = elementRect.left + elementRect.width;
    elementRect.bottom = elementRect.top + elementRect.height;

    // position
    return elementRect;
}

/**
 * Get bounding client rect of given element
 * @function
 * @ignore
 * @param {HTMLElement} element
 * @return {Object} client rect
 */
function getBoundingClientRect(element) {
    var rect = element.getBoundingClientRect();

    // whether the IE version is lower than 11
    var isIE = navigator.userAgent.indexOf("MSIE") != -1;

    // fix ie document bounding top always 0 bug
    var rectTop = isIE && element.tagName === 'HTML'
        ? -element.scrollTop
        : rect.top;

    return {
        left: rect.left,
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        width: rect.right - rect.left,
        height: rect.bottom - rectTop
    };
}

/**
 * Given an element and one of its parents, return the offset
 * @function
 * @ignore
 * @param {HTMLElement} element
 * @param {HTMLElement} parent
 * @return {Object} rect
 */
function getOffsetRectRelativeToCustomParent(element, parent, fixed, transformed) {
    var elementRect = getBoundingClientRect(element);
    var parentRect = getBoundingClientRect(parent);

    if (fixed && !transformed) {
        var scrollParent = getScrollParent(parent);
        parentRect.top += scrollParent.scrollTop;
        parentRect.bottom += scrollParent.scrollTop;
        parentRect.left += scrollParent.scrollLeft;
        parentRect.right += scrollParent.scrollLeft;
    }

    var rect = {
        top: elementRect.top - parentRect.top ,
        left: elementRect.left - parentRect.left ,
        bottom: (elementRect.top - parentRect.top) + elementRect.height,
        right: (elementRect.left - parentRect.left) + elementRect.width,
        width: elementRect.width,
        height: elementRect.height
    };
    return rect;
}

/**
 * Get the prefixed supported property name
 * @function
 * @ignore
 * @argument {String} property (camelCase)
 * @returns {String} prefixed property (camelCase)
 */
function getSupportedPropertyName(property) {
    var prefixes = ['', 'ms', 'webkit', 'moz', 'o'];

    for (var i = 0; i < prefixes.length; i++) {
        var toCheck = prefixes[i] ? prefixes[i] + property.charAt(0).toUpperCase() + property.slice(1) : property;
        if (typeof root.document.body.style[toCheck] !== 'undefined') {
            return toCheck;
        }
    }
    return null;
}

/**
 * The Object.assign() method is used to copy the values of all enumerable own properties from one or more source
 * objects to a target object. It will return the target object.
 * This polyfill doesn't support symbol properties, since ES5 doesn't have symbols anyway
 * Source: https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
 * @function
 * @ignore
 */
if (!Object.assign) {
    Object.defineProperty(Object, 'assign', {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function(target) {
            if (target === undefined || target === null) {
                throw new TypeError('Cannot convert first argument to object');
            }

            var to = Object(target);
            for (var i = 1; i < arguments.length; i++) {
                var nextSource = arguments[i];
                if (nextSource === undefined || nextSource === null) {
                    continue;
                }
                nextSource = Object(nextSource);

                var keysArray = Object.keys(nextSource);
                for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
                    var nextKey = keysArray[nextIndex];
                    var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
                    if (desc !== undefined && desc.enumerable) {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
            return to;
        }
    });
}

if (!root.requestAnimationFrame) {
    /* jshint ignore:start */
    var lastTime = 0;
    var vendors = ['ms', 'moz', 'webkit', 'o'];
    for(var x = 0; x < vendors.length && !root.requestAnimationFrame; ++x) {
        root.requestAnimationFrame = root[vendors[x]+'RequestAnimationFrame'];
        root.cancelAnimationFrame = root[vendors[x]+'CancelAnimationFrame'] || root[vendors[x]+'CancelRequestAnimationFrame'];
    }

    if (!root.requestAnimationFrame) {
        root.requestAnimationFrame = function(callback, element) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = root.setTimeout(function() { callback(currTime + timeToCall); },
                                        timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };
    }

    if (!root.cancelAnimationFrame) {
        root.cancelAnimationFrame = function(id) {
            clearTimeout(id);
        };
    }
    /* jshint ignore:end */
}

export default Popper;