<template>
  <modal v-model="showModal" title="设置 fieldMapping" width="500" :styles="{top: '20px'}" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <div class="field-mapping-panel" :style="{overflow: 'auto', height: pageHeight + 'px'}">
      <field-tree
        ref="fieldTree"
        :title="title"
        :data="fieldList"
        :modelId="modelId"></field-tree>
    </div>
    <div class="field-mapping-item">
      <span class="field-mapping-label">fieldName:</span>
      <input class="field-mapping-input" :value="fieldName" @change="onFieldNameChange($event)">
    </div>
    <!-- <div class="field-mapping-item">
      <span class="field-mapping-label">type:</span>
      <select class="field-mapping-input" :value="type" @change="onFieldTypeChange($event)">
        <option>input</option>
      </select>
    </div> -->
    <div class="field-mapping-item">
      <span class="field-mapping-label">valueType:</span>
      <select class="field-mapping-input" :value="valueType" @change="onFieldValueTypeChange($event)">
        <option>int</option>
        <option>float</option>
        <option>string</option>
      </select>
    </div>
  </modal>
</template>
<script>
import Modal from '../../common/modal/modal.vue'
import FieldTree from '../fieldmap/field-tree'
import { authenticate, catchExpired } from '../../../utils/auth'

export default {
  components: {Modal, FieldTree},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: String,
    modelId: String // modelId会根据选择集合而变化
  },
  data () {
    return {
      showModal: this.show,
      fieldList: [],
      fieldName: '',
      // type: 'input',
      valueType: 'float'
    }
  },
  computed: {
    pageHeight () {
      return this.$store.getters.getWinHeight - 300
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    modelId (val) {
      if (val) {
        this.loadFieldList()
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      // valid parentKey, key, fieldName, valueType
      const hasChecked = this.$refs.fieldTree.hasChecked()
      if (hasChecked && this.fieldName && this.valueType) {
        return true
      }
      if (!hasChecked) {
        this.$Message.warning('请从树节点选择一项')
      } else if (!this.fieldName || !this.valueType) {
        this.$Message.warning('请填写完整 fieldName, valueType')
      }
      return false
    },
    onConfirm () {
      this.$emit('on-confirm-ok', Object.assign(this.$refs.fieldTree.getCheckedNodeKeys(), {
        fieldName: this.fieldName,
        valueType: this.valueType
      }))
    },
    loadFieldList () {
      if (!this.modelId) {
        return
      }
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/crf/crfTable/field/list/all`, authenticate({
        params: {id: this.modelId}
      }))
      .then(res => res.json())
      .then(res => {
        this.showLoading = false
        if (res.status === 'success' && Array.isArray(res.fieldList)) {
          this.fieldList = res.fieldList
        } else if (res.status === 'success') {
          this.fieldList = []
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    onFieldNameChange (e) {
      e.stopPropagation()
      this.fieldName = e.target.value
    },
    // onFieldTypeChange (e) {
    //   e.stopPropagation()
    //   this.type = e.target.value
    // },
    onFieldValueTypeChange (e) {
      e.stopPropagation()
      this.valueType = e.target.value
    }
  }
}
</script>
<style lang="less">
.field-mapping-panel {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.field-mapping-item {
  margin-bottom: 10px;
  padding-right: 15px;
  display: flex;
  align-items: center;
}
.field-mapping-label {
  display: inline-block;
  text-align: right;
  width: 80px;
  margin-right: 10px;
}
.field-mapping-input {
  outline: 0;
  padding: 4px 7px;
  flex: 1 0 auto;
  border: 0;
  border-bottom: 1px solid #888;
}
</style>
