<template>
  <div>
    <div class="container-img"
      :class="{enable: container.enable,
        'fridge-v': container.type==='fridge_v', 'fridge-h': container.type==='fridge_h',
        nitrogen: container.type==='nitrogen_sm' || container.type==='nitrogen_bg',
        'prop-1': container.proportion>=0 && container.proportion<25,
        'prop-2': container.proportion>=25 && container.proportion<50,
        'prop-3': container.proportion>=50 && container.proportion<75,
        'prop-4': container.proportion>=75 && container.proportion<=100}"
      @click.stop="onClick(container)">
      <slot name="deletemask"></slot>
    </div>
    <div class="container-desc">
      <div class="label">{{container.label}}</div>
      <div class="info">
        <span>样本数量：</span><span>{{container.sampleNum}}</span>
      </div>
      <div class="info">
        <span>样本占用空间：</span><span></span>
      </div>
      <div class="container-percent">
        <y-progress :percent="getPercent(container.proportion)" :show-value="container.proportion" :strokeWidth="5" :strokeColor="getMaskBg(container)"></y-progress>
      </div>
    </div>
  </div>
</template>

<script>
import YProgress from '../common/progress/progress'
export default {
  components: {YProgress},
  props: {
    container: Object
  },
  data () {
    return {
      siteCode: ''
    }
  },
  methods: {
    onClick (item) {
      if (item.value && item.value.length && item.enable) {
        this.$router.push({name: item.value, query: {id: item.id}})
      }
    },
    getPercent (proportion) {
      if (proportion < 50) {
        proportion = proportion * (60 - proportion) / 10
      }
      return parseInt(proportion || 0)
    },
    getMaskBg (item) {
      return (item.proportion < 25 && item.proportion >= 0
        ? '#6AE065'
        : item.proportion < 50 && item.proportion >= 25
          ? '#FCB946'
          : item.proportion < 75 && item.proportion >= 50
            ? '#70A3C7'
            : item.proportion < 100 && item.proportion >= 75
              ? '#F87E7F'
              : '#f00'
      )
    }
  }
}
</script>
