<template>
  <table cellspacing="0" cellpadding="0" border="0" :style="styleObject">
    <colgroup>
      <col v-for="(column, index) in columns" :key="column.key" :width="setCellWidth(column, index, false)">
    </colgroup>
    <tbody class="table-tbody">
      <template v-for="(row, index) in data">
        <tr
          ref='tr'
          :key="row._idx"
          class="table-row"
          :class="{'table-row-highlight': row._isHighlight, 'table-row-hover': row._isHover && !$parent.hoverDisable,
          [`${getRowClassName(row, index)}`]: getRowClassName(row, index).length}"
          @mouseenter.stop="onMouseenter(row)"
          @mouseleave.stop="onMouseleave(row)"
          @click.stop="onClickCurrentRow(row)"
          @dblclick.stop="onDblclickCurrentRow(row)">
          <td v-for="column in columns" :key="column.key" :class="alignCls(column, row)">
            <Cell
              :fixed="fixed"
              :row="row"
              :column="column"
              :natural-index="index"
              :index="row._index"
              :checked="row._isChecked"
              :disabled="row._isDisabled"
              :indexStart="indexStart">
            </Cell>
          </td>
        </tr>
        <tr v-if="row._showHideColumns && hideColumns.length" :key="'hide_col_'+row._idx">
          <td :colspan="columns.length" v-for="column in hideColumns" :key="column.key">
            <Cell
              :row="row"
              :column="column"
              :natural-index="index"
              :index="row._index"
              withExpand>
            </Cell>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import styleMethods from './mixin'
import Emitter from '../mixins/emitter'
import Cell from './cell'

export default {
  name: 'TableBody',
  components: {Cell},
  mixins: [styleMethods, Emitter],
  props: {
    columns: {
      type: Array,
      default: function () {
        return []
      }
    },
    hideColumns: {
      type: Array,
      default: function () {
        return []
      }
    },
    data: {
      type: Array,
      default: function () {
        return []
      }
    },
    styleObject: {},
    columnsWidth: {},
    hideRowSelect: {
      type: Boolean,
      default: false
    },
    noborder: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: [Boolean, String],
      default: false
    },
    indexStart: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      store: {}
    }
  },
  created () {
    this.store = this.$parent.tableStore
  },
  methods: {
    getRowClassName (row, index) {
      if (typeof this.$parent.rowClassName === 'function') {
        return this.$parent.rowClassName(row, index)
      }
      return ''
    },
    onMouseenter (fRow) {
      fRow._isHover = true
    },
    onMouseleave (fRow) {
      fRow._isHover = false
    },
    onClickCurrentRow (fRow) {
      this.highlightCurrentRow(fRow)
      this.dispatch('Table', 'on-row-click', fRow._index)
    },
    onDblclickCurrentRow (fRow) {
      this.highlightCurrentRow(fRow)
      this.dispatch('Table', 'on-row-dblclick', fRow._index)
    },
    highlightCurrentRow (fRow) {
      if (this.$parent.highlightRow) {
        this.store.highlightRow(fRow._index)
      }
    },
    scrollIntoView (_idx) {
      let $tr = ''
      this.data.forEach((item, index) => {
        if (item._idx === _idx) {
          $tr = this.$refs.tr[index]
        }
      })
      if ($tr) {
        $tr.scrollIntoView()
      }
    }
  }
}
</script>

<style lang="css">
</style>
