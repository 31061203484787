<template>
  <div class="menu-manage">
    <div class="menu-update-btn">
      <y-button @click.native="onMenuUpdate">更新菜单</y-button>
    </div>
    <div class="menu-panel">
      <tree
        class="menu-tree"
        ref="tree"
        :title="title"
        :value="selectedMenus"
        :data="menuList"
        :height="treeHeight"
        @on-tree-node-click="onTreeNodeClick"></tree>
      <auth-panel
        class="menu-group-tree"
        :projectId="projectId"
        :menuName="currentMenuName"
        v-model="currentMenuGroupCodeList"
        @on-group-change="onMenuGroupChange"
        @on-group-update="onMenuGroupUpdate"></auth-panel>
      <div style="margin-left:15px;font-size:12px;">
        <span>菜单权限配置说明：</span>
        <p>1.设置project.menuAuth属性为true</p>
        <p>2.设置菜单节点的groupCodeList, 用户过滤菜单中的groupCode采用完全匹配</p>
        <p>3.若用户组没有分配给Home菜单, 则需要给用户组的homepage属性选择一个菜单的value</p>
        <p>4.若用户组配置了子菜单, 父菜单也应该配置该用户组</p>
      </div>
    </div>
    <loading v-model="showLoading" fix icon="load-c" transparent="rgba(254,254,254,0.5)"></loading>
  </div>
</template>

<script>
import Tree from './field/field-tree'
import AuthPanel from './auth-panel'
import YButton from '../common/button/button'
import Loading from '../common/loading/loading'
import { authenticate, catchExpired } from '../../utils/auth'

export default {
  components: { Tree, AuthPanel, YButton, Loading },
  data () {
    return {
      title: '菜单选择',
      menuList: [],
      selectedMenus: {},
      showLoading: false,
      currentMenuName: undefined,
      currentMenuGroupCodeList: []
    }
  },
  computed: {
    projectId () {
      return this.$store.state.project.projectId
    },
    treeHeight () {
      return this.$store.getters.getWinHeight - 130
    }
  },
  watch: {
    projectId (val) {
      if (val) {
        this.loadMenuList()
      }
    }
  },
  mounted () {
    this.loadMenuList()
  },
  methods: {
    onMenuUpdate () {
      this.updateSelectedMenus()
    },
    loadMenuList () {
      this.showLoading = true
      this.$http.get(`${this.httpRoot}/menu/list/all`, authenticate({
        params: {projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.menuList = res.menuList || []
          this.selectedMenus = res.selectedMenus || {}
          this.showLoading = false
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    updateSelectedMenus () {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/menu/update`, {
        projectId: this.projectId,
        selectedMenus: this.$refs.tree.getCheckedData(),
        menuGroupCodes: this.$refs.tree.getGroupCodes()
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.$Message.success('更新成功')
          this.loadMenuList()
        } else {
          this.$Message.error(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    onTreeNodeClick (menuName, groupCodeList) {
      this.currentMenuName = menuName
      this.currentMenuGroupCodeList = groupCodeList || []
    },
    onMenuGroupChange (groupCodeList) {
      // console.log('groupCodeList', groupCodeList)
      this.$refs.tree.setCurrentTreeNodeGroupCodeList(groupCodeList)
    },
    onMenuGroupUpdate (groupCodeList) {
      this.$refs.tree.setCurrentTreeNodeGroupCodeList(groupCodeList)
      this.updateSelectedMenus()
    }
  }
}
</script>

<style lang="less">
.menu-manage {
  padding-left: 15px;
}
.menu-update-btn {
  height: 40px;
  line-height: 40px;
}
.menu-panel {
  display: flex;
}
.menu-tree {
  width: 300px;
}
.menu-group-tree {
  margin-left: 20px;
  width: 300px;
}
</style>
