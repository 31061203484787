<template>
  <modal v-model="showModal" title="新建冻存容器" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="freezerForm" :model="freezer" :rules="freezerRule" label-width="100px" item-width="250px">
      <form-item label="项目名称" prop="projectId">
        <y-select
          v-model="freezer.projectId"
          :data="projectList"
          labelField="name"
          valueField="code"></y-select>
      </form-item>
      <form-item label="容器类型" prop="type">
        <y-select v-model="freezer.type" :data="typeList" clearable></y-select>
      </form-item>
      <form-item label="容器区号" prop="zone">
        <input-number v-model="freezer.zone"></input-number>
      </form-item>
      <form-item label="容器编码" prop="code">
        <y-input v-model="freezer.code"></y-input>
      </form-item>
      <form-item label="容器标签" prop="label">
        <y-input v-model="freezer.label"></y-input>
      </form-item>
      <form-item label="备注" prop="desc">
        <y-input v-model="freezer.desc" type="textarea"></y-input>
      </form-item>
    </y-form>
    <y-form ref="appendForm" :model="append" :rules="appendRule" label-width="100px" item-width="250px">
      <div class="append-top" v-show="showCreateFreezerOnce">
        <span style="padding-left:100px;">一次性建立整个容器</span>
        <span><input type="checkbox" value="createFreezerOnce" v-model="createFreezerOnce"></span>
      </div>
      <div class="append-form" v-if="showAppend">
        <form-item label="容器层／排" prop="levelNum">
          <input-number v-model="append.levelNum"></input-number>
        </form-item>
        <form-item label="容器层／排架子数目" prop="shelfNum">
          <input-number v-model="append.shelfNum"></input-number>
        </form-item>
        <form-item label="每架子抽屉数" prop="layerNum">
          <input-number v-model="append.layerNum"></input-number>
        </form-item>
        <form-item label="每抽屉盒子数" prop="boxNum">
          <input-number v-model="append.boxNum"></input-number>
        </form-item>
        <form-item label="样本盒类型" prop="exboxType">
          <y-select v-model="append.exboxType" :data="exboxTypeList"></y-select>
        </form-item>
        <template v-if="append.exboxType==='strawbox'">
          <form-item label="麦管盒数" prop="strawboxCount">
            <input-number v-model="append.strawboxCount"></input-number>
          </form-item>
        </template>
        <form-item label="盒子行数" prop="rowNum">
          <input-number v-model="append.rowNum"></input-number>
        </form-item>
        <form-item label="盒子列数" prop="colNum">
          <input-number v-model="append.colNum"></input-number>
        </form-item>
        <form-item label="盒子类型" prop="sampleboxType">
          <y-select v-model="append.sampleboxType" :data="boxTypeList"></y-select>
        </form-item>
      </div>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../common/modal/modal.vue'
import YForm from '../common/form/form'
import FormItem from '../common/form/form-item'
import YInput from '../common/input/input'
import InputNumber from '../common/input/input-number'
import YSelect from '../common/select/select'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: { Modal, YForm, FormItem, YInput, InputNumber, YSelect },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {}
  },
  data () {
    return {
      showModal: this.show,
      typeList: [
        {id: '001', name: '立式冰箱', value: 'fridge_v'},
        {id: '002', name: '卧式冰箱', value: 'fridge_h'},
        {id: '003', name: '小型液氮罐', value: 'nitrogen_sm'},
        {id: '004', name: '大型液氮罐', value: 'nitrogen_bg'}
      ],
      exboxTypeList: [
        {name: '冻存管', value: 'samplebox'},
        {name: '麦管', value: 'strawbox'},
        {name: '切片', value: 'cardbox'}
      ],
      boxTypeList: [
        {name: '普通类型', value: 'normal'},
        {name: '间隔类型', value: 'skip'}
      ],
      freezer: this.value || {projectId: '', type: '', zone: 1, code: '', label: '', desc: ''},
      freezerRule: {
        projectId: [{required: true, message: '项目名称不能为空', trigger: 'change'}],
        type: [{required: true, message: '容器类型不能为空', trigger: 'change'}],
        zone: [{type: 'number', min: 1, max: 10, required: true, message: '容器区号不能为空, ≥1 且 ≤10', trigger: 'blur'}],
        code: [{required: true, max: 10, message: '容器编码不能为空,长度≤10', trigger: 'blur'}],
        label: [{required: true, max: 15, message: '容器标签不能为空,长度≤15', trigger: 'blur'}]
      },
      projectList: [],
      createFreezerOnce: [],
      showCreateFreezerOnce: true,
      append: {
        levelNum: 3,
        shelfNum: 4,
        layerNum: 4,
        boxNum: 4,
        rowNum: 9,
        colNum: 9,
        sampleboxType: 'normal',
        exboxType: '',
        strawboxCount: 0
      },
      appendRule: {
        levelNum: [{type: 'number', min: 1, max: 12, message: '容器层／排, ≥1 且 ≤12', trigger: 'change'}],
        shelfNum: [{type: 'number', min: 1, max: 20, message: '容器层／排架子数目, ≥1 且 ≤20', trigger: 'change'}],
        layerNum: [{type: 'number', min: 1, max: 18, message: '每架子抽屉数, ≥1 且 ≤18', trigger: 'change'}],
        exboxType: [{required: true, message: '样本盒类型不能为空', trigger: 'blur'}],
        boxNum: [{type: 'number', min: 1, max: 10, message: '每抽屉盒子数, ≥1 且 ≤10', trigger: 'change'}],
        rowNum: [{type: 'number', min: 1, max: 16, message: '盒子行数, ≥1 且 ≤16', trigger: 'change'}],
        colNum: [{type: 'number', min: 1, max: 12, message: '盒子列数, ≥1 且 ≤12', trigger: 'change'}]
      }
    }
  },
  computed: {
    showAppend () {
      return this.createFreezerOnce.length > 0
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    'freezer.type' (val) {
      this.$emit('input', this.freezer)
      if (val === 'nitrogen_bg') {
        this.createFreezerOnce = []
        this.showCreateFreezerOnce = false
      } else {
        this.showCreateFreezerOnce = true
      }
    },
    'freezer.label' (val) {
      this.$emit('input', this.freezer)
    },
    'freezer.zone' (val) {
      this.$emit('input', this.freezer)
    },
    'freezer.code' (val) {
      this.$emit('input', this.freezer)
    },
    'freezer.desc' (val) {
      this.$emit('input', this.freezer)
    }
  },
  mounted () {
    this.loadProjectList()
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.freezerForm.validate()
      if (!this.$refs.freezerForm.isValid) return false
      //
      this.$refs.appendForm.validate()
      if (!this.$refs.appendForm.isValid) return false
      return true
    },
    onConfirm () {
      if (this.showAppend) {
        this.$emit('on-add-ok', this.append)
      } else {
        this.$emit('on-add-ok')
      }
    },
    loadProjectList () {
      this.$http.get(`${this.httpRoot}/admin/project/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.projectList = res.projectList
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="css">
</style>
