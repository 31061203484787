<template>
  <div class="tooltip" @mouseenter="handleShowPopper" @mouseleave="handleClosePopper">
    <div class="tooltip-rel" ref="reference">
      <slot></slot>
    </div>
    <transition name="fade">
      <div class="tooltip-popper" ref="popper" v-show="!disabled && (visible || always)">
        <div class="tooltip-content">
          <div class="tooltip-arrow"></div>
          <div class="tooltip-inner">
            <slot name="content">{{ content }}</slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Popper from '../base/popper'
import { oneOf } from '../../../utils/assist'

export default {
  name: 'Tooltip',
  mixins: [Popper],
  props: {
    placement: {
      validator (value) {
        return oneOf(value, ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end'])
      },
      default: 'bottom'
    },
    content: {
      type: [String, Number],
      default: ''
    },
    delay: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    controlled: {    // under this prop,Tooltip will not close when mouseleave
      type: Boolean,
      default: false
    },
    always: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleShowPopper () {
      this.timeout = setTimeout(() => {
        this.visible = true
      }, this.delay)
    },
    handleClosePopper () {
      clearTimeout(this.timeout)
      if (!this.controlled) {
        this.visible = false
      }
    }
  }
}
</script>
