import { addClass, removeClass } from '../../../utils/dom'

const Transition = {
  beforeEnter (el) {
    addClass(el, 'collapse-transition') // used to fix safari bug
    if (!el.dataset) el.dataset = {}

    el.dataset.oldOverflow = el.style.overflow
    el.dataset.oldPaddingTop = el.style.paddingTop
    el.dataset.oldPaddingBottom = el.style.paddingBottom

    el.style.height = '0'
    el.style.paddingTop = 0
    el.style.paddingBottom = 0
  },

  enter (el, done) {
    if (el.scrollHeight !== 0) {
      el.style.height = el.scrollHeight + 'px'
      el.style.paddingTop = el.dataset.oldPaddingTop
      el.style.paddingBottom = el.dataset.oldPaddingBottom
    } else {
      el.style.height = ''
      el.style.paddingTop = el.dataset.oldPaddingTop
      el.style.paddingBottom = el.dataset.oldPaddingBottom
    }
    el.style.overflow = 'hidden'
    setTimeout(() => {
      done()
    }, 300)
  },

  afterEnter (el) {
    removeClass(el, 'collapse-transition')
    el.style.height = ''
    el.style.overflow = el.dataset.oldOverflow
  },

  beforeLeave (el) {
    if (!el.dataset) el.dataset = {}

    el.dataset.oldOverflow = el.style.overflow
    el.dataset.oldPaddingTop = el.style.paddingTop
    el.dataset.oldPaddingBottom = el.style.paddingBottom

    el.style.height = el.scrollHeight + 'px'
  },

  leave (el, done) {
    if (el.scrollHeight !== 0) {
      // for safari: add class after set height, or it will jump to zero height suddenly, weired
      addClass(el, 'collapse-transition')
      el.style.height = 0
      el.style.paddingTop = 0
      el.style.paddingBottom = 0
    }
    el.style.overflow = 'hidden'
    setTimeout(() => {
      done()
    }, 300)
  },

  afterLeave (el) {
    removeClass(el, 'collapse-transition')
    el.style.height = ''
    el.style.overflow = el.dataset.oldOverflow
    el.style.paddingTop = el.dataset.oldPaddingTop
    el.style.paddingBottom = el.dataset.oldPaddingBottom
  }
}

export default {
  functional: true,
  render (createElement, { children }) {
    var data = {
      props: {
        // name: 'collapse-expand',
        mode: 'out-in'
      },
      on: Transition
    }

    return createElement('transition', data, children)
  }
}
