<template>
  <div class="scroller" :style="styles">
    <div class="scroll-content" ref="scrollContent"
      @scroll="handleScroll"
      @mousewheel.stop="handleMouseWheel"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave">
      <div ref="scrollContentInner">
        <slot></slot>
      </div>
    </div>
    <div class="scroll-bar" :class="{hover: showScrollBarBg}"
      @mouseenter="onBarMouseEnter"
      @mouseleave="onBarMouseLeave"
      @mouseup="onBarMouseUp"
      onselectstart="return false">
      <div class="scroll-bar-bg" v-show="showScrollBarBg"></div>
      <div class="scroll-bar-inner" v-show="showScrollBarInner" id="scrollbar"
        ref="scrollBarInner"
        @mousedown="onBarMouseDown"
        :style="{top: scrollBarTop, height: scrollBarHeight}"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: [String, Number],
    height: [String, Number]
  },
  data () {
    return {
      showScrollBarBg: false,
      showScrollBarInner: false,
      scrollBarHeight: '100%',
      scrollBarTop: 0,

      scrollBarActive: false,
      oldMouseOffTop: 0
    }
  },
  computed: {
    styles () {
      let style = {}
      if (this.height && parseInt(this.height)) {
        style.height = parseInt(this.height) + 'px'
        if (typeof this.height === 'string' && this.height.indexOf('%') > 0) {
          style.height = this.height
        }
      } else {
        style.height = '100%'
      }
      if (this.width && parseInt(this.width)) {
        style.width = parseInt(this.width) + 'px'
        if (typeof this.width === 'string' && this.width.indexOf('%') > 0) {
          style.width = this.width
        }
      } else {
        style.width = '100%'
      }
      return style
    }
  },
  mounted () {
    document.addEventListener('mousemove', this.onBarMouseMove)
    document.addEventListener('mouseup', this.bindMouseUp)
  },
  methods: {
    getScrollBarHeight () {
      if (this.$refs.scrollContent && this.$refs.scrollContentInner) {
        if (this.$refs.scrollContent.offsetHeight < this.$refs.scrollContentInner.offsetHeight) {
          return Math.max(this.$refs.scrollContent.offsetHeight * this.$refs.scrollContent.offsetHeight / this.$refs.scrollContentInner.offsetHeight, 20)
        }
      }
      return 0
    },
    getScrollBarTop (scrollTop) {
      if (this.$refs.scrollContent && this.$refs.scrollContentInner) {
        if (this.$refs.scrollContent.offsetHeight < this.$refs.scrollContentInner.offsetHeight) {
          let scrollBarTopLen = this.$refs.scrollContent.offsetHeight - parseInt(this.scrollBarHeight)
          let scrollTopLen = this.$refs.scrollContentInner.offsetHeight - this.$refs.scrollContent.offsetHeight
          return scrollBarTopLen * scrollTop / scrollTopLen
        }
      }
      return 0
    },
    getScrollContentTop (scrollBarTop) {
      if (this.$refs.scrollContent && this.$refs.scrollContentInner) {
        if (this.$refs.scrollContent.offsetHeight < this.$refs.scrollContentInner.offsetHeight) {
          let scrollBarTopLen = this.$refs.scrollContent.offsetHeight - parseInt(this.scrollBarHeight)
          let scrollTopLen = this.$refs.scrollContentInner.offsetHeight - this.$refs.scrollContent.offsetHeight
          return scrollTopLen * scrollBarTop / scrollBarTopLen
        }
      }
      return 0
    },
    handleScroll (event) {
      let contentScrollTopLen = this.$refs.scrollContentInner.offsetHeight - this.$refs.scrollContent.offsetHeight
      const $content = this.$refs.scrollContent
      let contentScrollTop = $content.scrollTop
      if (contentScrollTop >= contentScrollTopLen) {
        this.$emit('on-scroll-bottom')
      } else if (contentScrollTop === 0) {
        this.$emit('on-scroll-top')
      }
    },
    handleMouseWheel (event) {
      const deltaY = event.deltaY
      const $content = this.$refs.scrollContent

      $content.scrollTop = $content.scrollTop + deltaY
      this.scrollBarTop = this.getScrollBarTop($content.scrollTop) + 'px'
    },
    onMouseEnter () {
      let scrollBarHeight = this.getScrollBarHeight()
      if (scrollBarHeight) {
        this.scrollBarHeight = scrollBarHeight + 'px'
        this.showScrollBarInner = true
      }
    },
    onMouseLeave () {
      if (!this.scrollBarActive) {
        this.showScrollBarBg = false
        this.showScrollBarInner = false
      }
    },
    onBarMouseEnter () {
      let scrollBarHeight = this.getScrollBarHeight()
      if (scrollBarHeight) {
        this.scrollBarHeight = scrollBarHeight + 'px'
        this.showScrollBarBg = true
        this.showScrollBarInner = true
      }
    },
    onBarMouseLeave () {
      if (!this.scrollBarActive) {
        this.showScrollBarBg = false
        this.showScrollBarInner = false
      }
    },
    onBarMouseDown (event) {
      this.scrollBarActive = true
      this.oldMouseOffTop = event.clientY
    },
    onBarMouseUp (event) {
      this.scrollBarActive = false
    },
    onBarMouseMove (event) {
      const $content = this.$refs.scrollContent
      let mouseOffTop = event.clientY

      let scrollBarTopLen = this.$refs.scrollContent.offsetHeight - parseInt(this.scrollBarHeight)
      let scrollBarTopVal = parseInt(this.scrollBarTop)
      if (this.scrollBarActive) {
        let deltaY = mouseOffTop - this.oldMouseOffTop
        let deltaTopMax = scrollBarTopLen - scrollBarTopVal
        if (deltaY > 0 && deltaTopMax > 0) {
          this.scrollBarTop = scrollBarTopVal + Math.min(deltaY, deltaTopMax) + 'px'
          $content.scrollTop = this.getScrollContentTop(parseInt(this.scrollBarTop))
        } else if (deltaY < 0 && scrollBarTopVal > 0) {
          this.scrollBarTop = scrollBarTopVal - Math.min(-deltaY, scrollBarTopVal) + 'px'
          $content.scrollTop = this.getScrollContentTop(parseInt(this.scrollBarTop))
        }
        this.oldMouseOffTop = mouseOffTop
      }
    },
    bindMouseUp (event) {
      if (event.target.id !== 'scrollbar') {
        this.scrollBarActive = false

        this.showScrollBarBg = false
        if (document.querySelector('.scroller') &&
            (event.target.contains(document.querySelector('.scroller')) ||
            !document.querySelector('.scroller').contains(event.target))) {
          this.showScrollBarInner = false
        }
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('mousemove', this.onBarMouseMove)
    document.removeEventListener('mouseup', this.bindMouseUp)
  }
}
</script>

<style lang="less">
.scroller {
  position: relative;
  overflow: hidden;
}
.scroll-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
}
.scroll-bar {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  width: 10px;
  &.hover {
    width: 14px;
    transition: width all 0.2s;
    .scroll-bar-inner {
      width: 10px;
      margin: 0 2px;
      border-radius: 5px;
    }
  }
}
.scroll-bar-bg {
  width: 100%;
  height: 100%;
  background: rgba(220, 220, 220, 0.5);
}
.scroll-bar-inner {
  position: absolute;
  z-index: 11;
  right: 0;
  width: 6px;
  margin: 0 2px;
  border-radius: 3px;
  background: rgba(50, 50, 50, 0.65);
  cursor: default;
}
</style>
