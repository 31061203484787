<template>
  <div>
    <div style="padding:15px;font-size:14px;">
      <p>银杏因子（Gingko Article Impact Factor）/（YXYe Article Impact Factor）是一个针对单篇论文学术价值和影响力进行评估的二次文献数据库系统，为医学科研人员筛选、评价和推荐医学领域里高质量的论文，辅助医学科研人员“快速、精准、高效”查阅高质量的论文，弥补SCI论文影响因子体系仅针对全本杂志评价的不足，促进高质量医学论文成果的推广和转化。银杏因子系统，是银杏叶网站为中国医学科研人员开发的重要科研辅助工具。</p>
      <img src="../assets/imgs/yxyehh.png"/>
      <div>长按二维码关注银杏因子</div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
