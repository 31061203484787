<template>
  <div class="sample-box" :style="{width: boxTopWidth + 'px', height: boxLeftHeight + 'px'}">
    <div class="sample-box-topleft">
      <div class="box-header-topleft" :class="{selected: topLeftSelected}" :style="topLeftStyle"
        @click.stop="onSelectAll">All</div>
    </div>
    <div class="sample-box-top" :style="boxTopStyle">
      <box-top-item v-for="colIndex in width" :index="colIndex-1" :key="colIndex"
        @on-col-selected="onColSelected" ref="topItems"
        :boxItemWidth="boxItemWidth" :boxTopLeftWidth="boxTopLeftWidth">
        {{colIndex}}
      </box-top-item>
    </div>
    <div class="sample-box-left" :style="boxLeftStyle">
      <box-left-item v-for="rowIndex in height" :index="rowIndex-1" :key="rowIndex"
        @on-row-selected="onRowSelected" ref="leftItems"
        :boxItemWidth="boxItemWidth" :boxTopLeftWidth="boxTopLeftWidth">
        {{getBoxRowSymbol(rowIndex)}}
      </box-left-item>
    </div>
    <div class="sample-box-bottomright" :style="boxBottomRightStyle">
      <sample-box-item v-for="(item, index) in items" ref="boxItems" :key="index"
        :item="item"
        :itemIndex="index"
        :boxType="boxType"
        @on-item-selected="onItemSelected"
        @on-item-delete="onItemDelete"
        :boxItemWidth="boxItemWidth" :marginWidth="marginWidth">
      </sample-box-item>
    </div>
  </div>
</template>

<script>
import BoxTopItem from './box-top-item'
import BoxLeftItem from './box-left-item'
import SampleBoxItem from './sample-box-item'
export default {
  props: {
    width: {
      type: Number,
      default: 10
    },
    height: {
      type: Number,
      default: 10
    },
    // 与css文件variable.less中变量保持一致
    boxItemWidth: {
      type: Number,
      default: 50
    },
    // 与css文件variable.less中变量保持一致
    boxTopLeftWidth: {
      type: Number,
      default: 20
    },
    marginWidth: {
      type: Number,
      default: 9
    },
    selectAction: {
      type: String,
      default: 'single' // multiple
    },
    /* 操作状态
    all            可执行所有操作
    once           可选择一次
    lookup         可选择sample_exist,sample_in_ing(ioncUrl存在),sample_out_ing,sample_trans_ing
    samplein       可添加入库
    sampleout      可添加出库
    sampletrans    可添加转库
    sampleinouttrans 可入库、出库、转库
    */
    actionType: {
      type: String,
      default: 'samplein'
    },
    boxType: {
      type: String,
      default: 'skip' // 'skip'
    },
    data: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      topLeftSelected: false,
      selectedItems: [],
      startItem: {},
      startColIndex: 0,
      startRowIndex: 0,
      selectedTimes: 0,
      items: this.initItems()
    }
  },
  computed: {
    totalSize () {
      return this.width * this.height
    },
    boxTopHeaderWidth () {
      return this.width * this.boxItemWidth
    },
    boxTopWidth () {
      return this.width * this.boxItemWidth + this.boxTopLeftWidth
    },
    boxLeftHeight () {
      return this.height * this.boxItemWidth + this.boxTopLeftWidth
    },
    topLeftStyle () {
      return {
        lineHeight: this.boxTopLeftWidth + 'px',
        width: this.boxTopLeftWidth + 'px',
        height: this.boxTopLeftWidth + 'px'
      }
    },
    boxTopStyle () {
      return {
        left: this.boxTopLeftWidth + 'px',
        top: 0,
        height: this.boxTopLeftWidth + 'px',
        width: this.boxItemWidth * this.width + 'px'
      }
    },
    boxLeftStyle () {
      return {
        left: 0,
        top: this.boxTopLeftWidth + 'px',
        width: this.boxTopLeftWidth + 'px'
      }
    },
    boxBottomRightStyle () {
      return {
        width: this.boxTopHeaderWidth + 'px',
        left: this.boxTopLeftWidth + 'px',
        top: this.boxTopLeftWidth + 'px'
      }
    }
  },
  watch: {
    data (val) {
      this.items = this.initItems()
      if (this.selectedItems.length) {
        this.unSelectAll()
        this.selectedItems = []
        this.$emit('on-item-selected')
      }
    },
    totalSize (val) {
      this.items = this.initItems()
    }
  },
  methods: {
    getBoxRowSymbol (rowIndex) {
      return rowIndex < 9 ? String.fromCharCode(64 + rowIndex) : String.fromCharCode(65 + rowIndex)
    },
    initItems () {
      let box = []
      for (let i = 0, len = this.data.length; i < len; i++) {
        const sampleItem = this.data[i]
        if (sampleItem.row < this.height && sampleItem.col < this.width) {
          const index = sampleItem.row * this.width + sampleItem.col
          box[index] = Object.assign({}, {
            id: 'sid_' + i,
            row: parseInt(i / this.width), // 下标 0 开始
            col: i % this.width,
            sampleType: '',
            iconUrl: '',
            status: 'sample_empty',
            sampleCode: ''
          }, sampleItem)
        }
      }
      for (let i = 0, len = this.width * this.height; i < len; i++) {
        if (!box[i]) {
          box[i] = {
            id: 'sid_' + i,
            row: parseInt(i / this.width),
            col: i % this.width,
            sampleType: '',
            iconUrl: '',
            status: 'sample_empty',
            sampleCode: ''
          }
        }
      }
      return box
    },
    select (row, col, isSelect) {
      const index = row * this.width + col
      let item = this.$refs.boxItems[index]
      item.select(isSelect, true)
    },
    unshiftSelect (row, col) {
      const index = row * this.width + col
      let item = this.$refs.boxItems[index]
      item.select(true, true, 'unshift')
    },
    getBoxItem (row, col) {
      const index = row * this.width + col
      return this.$refs.boxItems[index]
    },
    getBoxItemItem (row, col) {
      const index = row * this.width + col
      let boxItem = this.$refs.boxItems[index]
      return boxItem ? boxItem.iem : null
    },
    getItemByCode (code) {
      for (let i = 0; i < this.totalSize; ++i) {
        let boxItem = this.$refs.boxItems[i]
        if (boxItem.item.sampleCode === code) {
          return boxItem.item
        }
      }
      return null
    },
    onSelectAll () {
      const selected = this.topLeftSelected
      this.selectTopLeft(!selected)
      // 将可选的行和列全选中或全反选
      for (let i = 0; i < this.width; i++) {
        if (this.canSelectCol(i)) {
          let item = this.$refs.topItems[i]
          item.select(!selected)
        }
      }
      for (let i = 0; i < this.height; i++) {
        if (this.canSelectRow(i)) {
          let item = this.$refs.leftItems[i]
          item.select(!selected)
        }
      }
      this.selectAll(!selected)
      this.$emit('on-all-selected', !selected)
    },
    selectTopLeft (isSelect) {
      this.topLeftSelected = isSelect
    },
    selectAll (isSelect) {
      for (let i = 0; i < this.totalSize; ++i) {
        let item = this.$refs.boxItems[i]
        item.select(isSelect)
      }
      this.$emit('on-item-selected')
    },
    unSelectAll () {
      let i = this.selectedItems.length - 1
      while (i >= 0) {
        let itemIndex = this.selectedItems[i].row * this.width + this.selectedItems[i].col
        this.$refs.boxItems[itemIndex].select(false)
        --i
      }
    },
    selectBlock (endItem) {
      if (this.startItem !== endItem) {
        let startRow = this.startItem.row || 0
        let startCol = this.startItem.col || 0
        let endRow = endItem.row || 0
        let endCol = endItem.col || 0
        if (startRow === endRow) {
          this.selectSectionRow(startRow, Math.min(startCol, endCol), Math.max(startCol, endCol))
        } else if (startRow > endRow) {
          this.selectSectionRow(endRow, endCol, this.width - 1)
          for (let i = endRow + 1; i < startRow; i++) {
            this.selectRow(i, true)
          }
          this.selectSectionRow(startRow, 0, startCol)
        } else if (startRow < endRow) {
          this.selectSectionRow(startRow, startCol, this.width - 1)
          for (let i = startRow + 1; i < endRow; i++) {
            this.selectRow(i, true)
          }
          this.selectSectionRow(endRow, 0, endCol)
        }
      }
      this.$emit('on-item-selected')
    },
    clearTopLeft () {
      let hasItemSelected = false
      for (let i = 0; i < this.totalSize; ++i) {
        let item = this.$refs.boxItems[i]
        if (item.selected) {
          hasItemSelected = true
          break
        }
      }
      if (!hasItemSelected) {
        this.selectTopLeft(false)
      }
    },
    checkAll () {
      let hasItemSelected = false
      for (let i = 0; i < this.totalSize; ++i) {
        let item = this.$refs.boxItems[i]
        if (item.canSelect() && !item.selected) {
          hasItemSelected = true
          break
        }
      }
      if (!hasItemSelected) {
        this.selectTopLeft(true)
      }
    },
    selectSectionRow (index, startCol, endCol) {
      for (let i = index * this.width + startCol; i <= index * this.width + endCol; ++i) {
        let item = this.$refs.boxItems[i]
        if (item.rowIndex === index) {
          item.select(true)
        }
      }
    },
    selectRowBlock (index) {
      if (index < this.startRowIndex) {
        for (let i = index; i <= this.startRowIndex; i++) {
          this.selectRow(i, true, true)
        }
      } else if (index > this.startRowIndex) {
        for (let i = this.startRowIndex; i <= index; i++) {
          this.selectRow(i, true, true)
        }
      }
    },
    selectRow (index, isSelect, dispatch) {
      const beforeSelected = JSON.stringify(this.selectedItems)
      for (let i = index * this.width; i < (index + 1) * this.width; ++i) {
        let item = this.$refs.boxItems[i]
        if (item.rowIndex === index) {
          item.select(isSelect)
          // 行反选，需要判断并清除列头和左上角头的选择状态
          if (!isSelect) {
            this.clearColHeader(item.colIndex)
            this.clearTopLeft()
          }
        }
      }
      const afterSelected = JSON.stringify(this.selectedItems)
      if (dispatch && beforeSelected !== afterSelected) {
        this.$emit('on-item-selected')
      }
    },
    clearRowHeader (index) {
      let hasItemSelected = false
      for (let i = index * this.width; i < (index + 1) * this.width; ++i) {
        let item = this.$refs.boxItems[i]
        if (item.rowIndex === index && item.selected) {
          hasItemSelected = true
          break
        }
      }
      if (!hasItemSelected) {
        this.$refs.leftItems[index].select(false)
      }
    },
    checkRowHeader (index) {
      let hasItemSelected = false
      for (let i = index * this.width; i < (index + 1) * this.width; ++i) {
        let item = this.$refs.boxItems[i]
        if (item.rowIndex === index && item.canSelect() && !item.selected) {
          hasItemSelected = true
          break
        }
      }
      if (!hasItemSelected) {
        this.$refs.leftItems[index].select(true)
      }
    },
    canSelectRow (index) {
      for (let i = index * this.width; i < (index + 1) * this.width; ++i) {
        let item = this.$refs.boxItems[i]
        if (item.rowIndex === index && item.canSelect()) {
          return true
        }
      }
      return false
    },
    selectColBlock (index) {
      if (index < this.startColIndex) {
        for (let i = index; i <= this.startColIndex; i++) {
          this.selectCol(i, true, true)
        }
      } else if (index > this.startColIndex) {
        for (let i = this.startColIndex; i <= index; i++) {
          this.selectCol(i, true, true)
        }
      }
    },
    selectCol (index, isSelect, dispatch) {
      const beforeSelected = JSON.stringify(this.selectedItems)
      for (let i = index; i < this.totalSize; i += this.width) {
        let item = this.$refs.boxItems[i]
        if (item.colIndex === index) {
          item.select(isSelect)
          // 列反选，需要判断并清除行头和左上角头的选择状态
          if (!isSelect) {
            this.clearRowHeader(item.rowIndex)
            this.clearTopLeft()
          }
        }
      }
      const afterSelected = JSON.stringify(this.selectedItems)
      if (dispatch && beforeSelected !== afterSelected) {
        this.$emit('on-item-selected')
      }
    },
    clearColHeader (index) {
      let hasItemSelected = false
      for (let i = index; i < this.totalSize; i += this.width) {
        let item = this.$refs.boxItems[i]
        if (item.colIndex === index && item.selected) {
          hasItemSelected = true
          break
        }
      }
      if (!hasItemSelected) {
        this.$refs.topItems[index].select(false)
      }
    },
    checkColHeader (index) {
      let hasItemSelected = false
      for (let i = index; i < this.totalSize; i += this.width) {
        let item = this.$refs.boxItems[i]
        if (item.colIndex === index && item.canSelect() && !item.selected) {
          hasItemSelected = true
          break
        }
      }
      if (!hasItemSelected) {
        this.$refs.topItems[index].select(true)
      }
    },
    canSelectCol (index) {
      for (let i = index; i < this.totalSize; i += this.width) {
        let item = this.$refs.boxItems[i]
        if (item.colIndex === index && item.canSelect()) {
          return true
        }
      }
      return false
    },
    getSelectedItems () {
      return this.selectedItems
    },
    addSelectedItems (row, col, idx, addType) {
      const len = this.selectedItems.length
      let hasAdded = false
      for (let i = 0; i < len; ++i) {
        if (this.selectedItems[i].row === row &&
            this.selectedItems[i].col === col) {
          hasAdded = true
          break
        }
      }
      if (!hasAdded && addType === 'unshift') {
        this.selectedItems.unshift(this.items[idx])
      } else if (!hasAdded) {
        this.selectedItems.push(this.items[idx])
      }
    },
    removeSelectedItems (row, col) {
      const len = this.selectedItems.length
      let itemIndex = -1
      for (let i = 0; i < len; ++i) {
        if (this.selectedItems[i].row === row &&
            this.selectedItems[i].col === col) {
          itemIndex = i
          break
        }
      }
      if (itemIndex >= 0) {
        this.selectedItems.splice(itemIndex, 1)
      }
    },
    onItemSelected (active, row, col, idx, dispatch, addType) {
      this.selectedTimes++
      if (active) {
        this.addSelectedItems(row, col, idx, addType)
      } else {
        this.removeSelectedItems(row, col)
      }
      if (dispatch) {
        this.$emit('on-item-selected', active, row, col, idx, dispatch)
      }
    },
    onItemDelete (rowIndex, colIndex, itemIndex) {
      this.$emit('on-item-delete', rowIndex, colIndex, itemIndex)
    },
    onRowSelected (active, row) {
      this.$emit('on-row-selected', active, row)
    },
    onColSelected (active, col) {
      this.$emit('on-col-selected', active, col)
    },
    sampleIningItem (item) {
      if (item && item.status === 'sample_empty') {
        const boxItem = this.getBoxItem(item.row, item.col)
        if (boxItem) boxItem.sampleIning()
      }
    },
    sampleDeleteByItem (item) {
      const boxItem = this.getBoxItem(item.row, item.col)
      if (boxItem) boxItem.sampleDelete()
    },
    sampleDelete () {
      let i = this.selectedItems.length - 1
      while (i >= 0) {
        let itemIndex = this.selectedItems[i].row * this.width + this.selectedItems[i].col
        this.$refs.boxItems[itemIndex].sampleDelete()
        --i
      }
    },
    sampleTransing () {
      if (this.actionType !== 'sampletrans') return
      let i = this.selectedItems.length - 1
      while (i >= 0) {
        let itemIndex = this.selectedItems[i].row * this.width + this.selectedItems[i].col
        this.$refs.boxItems[itemIndex].sampleTransing()
        --i
      }
    },
    errorItem (sampleCode) {
      for (let i = 0; i < this.totalSize; i++) {
        let boxItem = this.$refs.boxItems[i]
        if (boxItem.item.sampleCode === sampleCode && !boxItem.item.iconUrl) {
          boxItem.markError()
        }
      }
    },
    hasError () {
      for (let i = 0; i < this.totalSize; i++) {
        let boxItem = this.$refs.boxItems[i]
        if (boxItem.error) {
          return true
        }
      }
      return false
    },
    setItemType (sampleCode, iconUrl) {
      for (let i = 0; i < this.totalSize; i++) {
        let boxItem = this.$refs.boxItems[i]
        if (boxItem.item.sampleCode === sampleCode) {
          boxItem.setType(iconUrl)
        }
      }
    },
    highlightItem (sampleCode) {
      for (let i = 0; i < this.totalSize; i++) {
        let boxItem = this.$refs.boxItems[i]
        if (boxItem.item.sampleCode === sampleCode) {
          boxItem.highlight()
        }
      }
    },
    emphasize (sampleCode) {
      for (let i = 0; i < this.totalSize; i++) {
        let boxItem = this.$refs.boxItems[i]
        if (boxItem.item.sampleCode === sampleCode) {
          boxItem.emphasize()
        }
      }
    },
    verifyCode (sampleList) {
      let samples = sampleList || []
      samples.forEach(sample => {
        if (sample.row + 1 > this.height || sample.col + 1 > this.width) {
          this.$Message.warning('样本盒行列数不匹配')
        } else {
          let boxItem = this.$refs.boxItems[sample.row * this.width + sample.col]
          if (boxItem.item.sampleCode && boxItem.item.sampleCode !== sample.sampleCode) {
            // 库中吗和样本盒码不一致
            boxItem.markConflict()
          } else if (!boxItem.item.sampleCode && sample.sampleCode) {
            // 库中无码，样本盒有码
            boxItem.markNoSample()
          }
        }
      })
      // 库中有码(exist, 出入库中或出库完成不考虑)，样本盒无码
      for (let i = 0; i < this.totalSize; i++) {
        let boxItem = this.$refs.boxItems[i]
        if (boxItem.item.sampleCode) {
          let hasFound = false
          samples.forEach(sample => {
            if (sample.row * this.width + sample.col === i) {
              hasFound = true
            }
          })
          if (!hasFound && (boxItem.item.status === 'sample_exist' ||
            boxItem.item.status === 'sample_in_ing')) {
            boxItem.markHasSample()
          }
        }
      }
    },
    verifyOutOrderCode (sampleList) {
      console.log('sampleList', sampleList)
      let samples = sampleList || []

      // 盒中有码，工单无码
      let moreSamples = []
      for (let i = 0; i < this.totalSize; i++) {
        let boxItem = this.$refs.boxItems[i]
        if (boxItem.item.sampleCode) {
          let hasFound = false
          samples.forEach(sample => {
            if (sample.sampleCode === boxItem.item.sampleCode) {
              sample.found = true
              hasFound = true
            }
          })
          if (!hasFound) {
            // 记录盒中多出来的管子, 从扫描仪未知类型和位置
            moreSamples.push(Object.assign({}, boxItem.item))
          }
        }
      }

      // 盒中无码，工单有码
      let lessSamples = []
      samples.forEach(sample => {
        if (!sample.found) {
          // 记录工单中的管子, 未在盒中摆出来
          lessSamples.push(Object.assign({}, sample))
        }
      })
      return {
        moreSamples,
        lessSamples
      }
    },
    clearVerify () {
      for (let i = 0; i < this.totalSize; i++) {
        let boxItem = this.$refs.boxItems[i]
        boxItem.clearVerify()
      }
    },
    clearEmphasize () {
      for (let i = 0; i < this.totalSize; i++) {
        let boxItem = this.$refs.boxItems[i]
        boxItem.clearEmphasize()
      }
    }
  },
  components: {
    BoxTopItem,
    BoxLeftItem,
    SampleBoxItem
  }
}
</script>

<style lang="less">
@import '../../../assets/ivu/mixins/clear';
.sample-box {
  // 注意： box-sizing: border-box 全局设置
  box-sizing: border-box;
  position: relative;
  border-top: 1px solid #888;
  border-left: 1px solid #888;
  font-size: 12px;
  background-image: url(../../../assets/imgs/745.jpg);
  & * {
    box-sizing: border-box;
  }
}
.sample-box-topleft {
  position: absolute;
  left: 0;
  top: 0;
  .box-header-topleft {
    text-align: center;
    border-right: 1px dotted #888;
    border-bottom: 1px dotted #888;
    cursor: default; // se-resize;
  }
}
.sample-box-top {
  position: absolute;
  &:after {
    .clearFloat()
  }
  .box-header-col {
    display: inline-block;
    text-align: center;
    font-weight: 600;
    border-right: 1px dotted #888;
    border-bottom: 1px dotted #888;
    float: left;
    cursor: url(../../../assets/imgs/arrow-down.png), auto;
  }
}
.sample-box-left {
  position: absolute;
  .box-header-row {
    display: inline-block;
    text-align: center;
    font-weight: 600;
    border-right: 1px dotted #888;
    border-bottom: 1px dotted #888;
    cursor: url(../../../assets/imgs/arrow-right.png), auto;
  }
}
.sample-box-bottomright {
  position: relative;
}
.sample-box-item {
  display: inline-block;
  position: relative;
  text-align: center;
  border-right: 1px dotted #888;
  border-bottom: 1px dotted #888;
  cursor: pointer;
  float: left; // very important
  .box-item {
    border-radius: 50%;
    border: 1px solid #aaa;
    text-align: center;
    background-color: #fff;
    &.skip {
      border: 0;
      background: transparent;
    }
  }
  &.highlight {
    color: #3399ff;
    background: #3399ff;
    &.emphasize {
      color: #fd2f2f;
      background: #fd2f2f;
    }
  }
  &.emphasize {
    color: #fd2f2f;
    background: #fd2f2f;
  }
}
.box-header-topleft,.box-header-col,.box-header-row,.sample-box-item {
  &.selected {
    // background-image: url(../../../assets/imgs/127.jpeg);
    background: #4880d4;
    color: #fc378c;
    border-color: #fff;
  }
}
.box-item {
  position: relative;
  img {
    display: block;
    width: 100%;
    height: 100%;
    // border-radius: 50%;
  }
  &.error {
    border-color: #F43530;
  }
}
.item-sample-in,
.item-sample-out,
.item-sample-out-complete,
.item-sample-trans {
  position: relative;
  width: 100%;
  height: 100%;
  .ionic-icon {
    position: absolute;
    top: -35%;
    left: 1px;
    font-size: 12px;
    color: #c23531;
  }
}
.item-sample-in {
  .ionic-icon-log-in {
    color: green;
  }
}
.item-error {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(220, 220, 220, 0.5);
  .ionic-icon {
    color: #F43530;
  }
}
</style>
