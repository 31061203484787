<template>
  <modal v-model="showModal" title="添加 crfTable CRF表" width="550" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="currentDataForm" :model="currentData" :rules="currentDataRule" label-width="100px" item-width="350px">
      <form-item label="CRF表(+共享)" prop="modelId">
        <y-select v-model="currentData.modelId"
          :data="tempCrfTableList"
          labelField="title"
          valueField="modelId"
          placeholder="请输入简拼搜索"
          showField="projectId"
          attrInValue
          @on-select-change="onCrfTableChange"
          clearable
          filterable
          remote
          :load-method="loadCrfTableList"></y-select>
      </form-item>
      <form-item label="角色">
        <y-select v-model="currentData.role" :data="roleList" multiple></y-select>
      </form-item>
      <form-item label="是否可见" prop="visible">
        <checklist inline v-model="currentData.visible" :items="visibleList"></checklist>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import Checklist from '../../common/checklist/checklist'
import { authenticate, catchExpired } from '../../../utils/auth'

export default {
  components: { Modal, YForm, FormItem, YSelect, YInput, Checklist },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    projectId: String,
    crfTableList: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      showModal: this.show,
      tempCrfTableList: this.crfTableList || [],
      currentData: {
        modelId: '',
        role: [],
        visible: true
      },
      selectedCrfTable: {},
      roleList: [
        {name: '医生', value: 'doctor'},
        {name: '患者', value: 'patient'},
        {name: '助理', value: 'assistant'}
      ],
      visibleList: [
        {key: '1', title: '可见', value: true},
        {key: '0', title: '不可见', value: false}
      ],
      currentDataRule: {
        modelId: [{required: true, message: '不能为空', trigger: 'change'}],
        visible: [{type: 'boolean', required: true, message: '不能为空', trigger: 'change'}]
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    crfTableList (val) {
      this.tempCrfTableList = val
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.currentDataForm.validate()
      if (!this.$refs.currentDataForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.recoverData())
    },
    recoverData () {
      var cData = Object.assign({
        modelId: '',
        title: '',
        collection: '',
        hasScore: false,
        icon: '',
        itemkeys: [],
        visible: true,
        role: ''
      }, this.selectedCrfTable, this.currentData)
      cData.role = cData.role.toString()
      return cData
    },
    onCrfTableChange (crfTable) {
      this.selectedCrfTable = crfTable
    },
    loadCrfTableList (query, callback) {
      this.$http.get(`${this.httpRoot}/crf/crfTable/shared/query`, authenticate({
        params: {query, projectId: this.projectId}
      }))
      .then(res => res.json())
      .then(res => {
        if (typeof callback === 'function') {
          callback(res.crfTableList)
        } else {
          this.tempCrfTableList = res.crfTableList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>

<style lang="css">
</style>
