<template>
  <div class="panel-field">
    <!-- <div class="map-edit-title">patientDataset 编辑</div> -->
    <y-form ref="itemForm" :model="item" :rules="itemRule" label-width="150px" item-width="80%">
      <div class="dataset-form-row-2">
        <form-item label="名称" prop="name">
          <y-input v-model="item.name"></y-input>
        </form-item>
        <form-item label="数据源" prop="datasourceId">
          <y-select v-model="item.datasourceId" :data="datasourceList" clearable valueField="id" attrInValue @on-select-change="onDatasourceChange"></y-select>
        </form-item>
      </div>
      <div class="dataset-form-row-2">
        <form-item label="projectId" prop="projectId">
          <y-input v-model="item.projectId"></y-input>
        </form-item>
        <form-item label="collection" prop="collection">
          <y-input v-model="item.collection"></y-input>
        </form-item>
      </div>
      <div class="dataset-form-row-3">
        <form-item label="uniqueCodeField">
          <y-input v-model="item.uniqueCodeField"></y-input>
        </form-item>
        <form-item label="codeType">
          <y-input v-model="item.codeType"></y-input>
        </form-item>
        <form-item label="nameField">
          <y-input v-model="item.nameField"></y-input>
        </form-item>
      </div>
      <callFunc-list v-model="item.callFunc" label-width="120px" item-width="80%"
        @on-json-preview="jsonPreview"></callFunc-list>
      <form-item label="fieldMap">
        <keyval-list v-model="item.fieldMap" valtype="object"></keyval-list>
      </form-item>
    </y-form>
    <div class="map-edit-save">
      <y-button type="primary" @click.native="onSaveClick">更新</y-button>
    </div>
  </div>
</template>

<script>
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'
import YButton from '../../common/button/button'
import Emitter from '../../common/mixins/emitter'
import CallFuncList from './callFunc-list'
import KeyvalList from './keyval-list'
export default {
  components: { YForm, FormItem, YSelect, YInput, YButton, CallFuncList, KeyvalList },
  mixins: [Emitter],
  props: {
    data: {
      type: Object,
      default () { return {} }
    },
    datasourceList: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      item: {
        name: '',
        type: '',
        datasourceId: '',
        datasourceName: '',
        callFunc: [],
        patientFieldMap: {}
      },
      itemRule: {
        name: [{required: true, message: '不能为空', trigger: 'blur'}],
        datasourceId: [{required: true, message: '不能为空', trigger: 'blur'}]
      }
    }
  },
  watch: {
    data (val) {
      if (val) {
        this.item = val
      }
    }
  },
  methods: {
    onSaveClick () {
      this.dispatch('DataExtract', 'on-pdataset-save', JSON.parse(JSON.stringify(this.item)))
    },
    onDatasourceChange (val) {
      if (val && val.name) {
        this.item.datasourceName = val.name
      }
    },
    jsonPreview (callFunc) {
      this.$emit('on-json-preview', this.item.datasourceId, callFunc)
    },
    tablePreview (callFunc) {
      this.$emit('on-table-preview', this.item.datasourceId, callFunc)
    }
  }
}
</script>

<style lang="css">
.panel-field {
  padding-top: 20px;
}
.map-edit-title {
  font-size: 14px;
  height: 38px;
  line-height: 38px;
  text-align: center;
}
.map-edit-save {
  padding-left: 100px;
  margin-bottom: 10px;
}
</style>
