/*
清除缓存
*/
export function clearCache () {
  localStorage.clear()
}

/*
更新缓存值{name: value}
@param name {String}
@param item {String}
@param value {Object|String|Array}
*/
export function updateStorageCache (name, value, item='cache') {
  const cache = JSON.parse(localStorage.getItem(item)) || {}
  cache[name] = value
  localStorage.setItem(item, JSON.stringify(cache))
}

export function updateStorageItem (key, item) {
  localStorage.setItem(key, JSON.stringify(item))
}


/*
获取缓存内容{name: item} get the name of item
@param name {String}
@param item {String}
@return value {Object|String|Array|Null}
*/
export function getStorageCache (name, item='cache') {
  let cache = null
  try {
    cache = JSON.parse(localStorage.getItem(item))
    if (cache && cache[name] && cache[name].length) {
      cache = cache[name]
    } else {
      cache = null
    }
  } catch (e) {
    cache = null
  }
  return cache
}

export function getStorageItem (item='cache') {
  let cache = null
  try {
    cache = JSON.parse(localStorage.getItem(item))
  } catch (e) {
    cache = null
  }
  return cache
}
