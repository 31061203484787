<template>
  <modal v-model="showModal" title="设置 filter" width="720" :styles="{top:'200px'}" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <div class="r-ft-item">
      <span class="r-ft-label">columns</span>
      <y-select :data="cols" valueLabel="key" @on-select-change="onColSelect"></y-select>
      <span class="add-btn btn" @click.stop="addFilter">+</span>
    </div>
    <div class="r-ft-item">
      <span class="r-ft-label">filters</span>
      <col-rel-val :cols="cols" :filters="filters" @on-change="onChange"></col-rel-val>
    </div>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YSelect from '../../common/select/select.vue'
import ColRelVal from './col-rel-val.vue'
export default {
  components: {Modal, YSelect, ColRelVal},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    name: String,
    cols: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      showModal: this.show,
      currentCol: '',
      filters: []
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    name (val) {
      if (val) {
        this.filters = this.getFilters(val)
      }
    }
  },
  methods: {
    getFilters (val) {
      if (this.$store.state.rds.filterMap[this.name]) {
        return this.$store.state.rds.filterMap[this.name]
      } else {
        return []
      }
    },
    onColSelect (data) {
      console.log(data)
      if (data && data !== '#') {
        this.currentCol = data
      }
    },
    addFilter () {
      if (this.currentCol) {
        this.filters.push({
          check: true,
          bool: 'and',
          col: this.currentCol,
          rel: '=',
          val: ''
        })
        this.$store.dispatch('updateFilterMap', {key: this.name, filters: this.filters})
      }
    },
    onChange () {
      console.log('updateFilterMap')
      this.$store.dispatch('updateFilterMap', {key: this.name, filters: this.filters})
    },
    onBeforeConfirm () {
      return true
    },
    onConfirm () {
      this.$store.dispatch('updateFilterMap', {key: this.name, filters: this.filters})
      this.$emit('on-confirm-ok', this.filters)
    }
  }
}
</script>

<style lang="less">
.r-ft-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .r-ft-label {
    width: 80px;
    text-align: right;
    margin-right: 10px;
    font-size: 14px;
  }
  .add-btn {
    margin-left: 10px;
  }
}
</style>
