export default {
  methods: {
    getValueField (node) {
      const valueField = this.props.value
      if (valueField && typeof node.data === 'object' &&
          node.data[valueField]) {
        return node.data[valueField]
      } else if (typeof node.data === 'string') {
        return node.data
      }
      return ''
    },
    getKeyField (node) {
      const valueField = this.props.key
      if (valueField && typeof node.data === 'object' &&
          node.data[valueField]) {
        return node.data[valueField]
      } else if (typeof node.data === 'string') {
        return node.data
      }
      return new Date().getTime().toString()
    }
  }
}
