<template>
  <div class="ds-preview">
    <textarea :value="currentData" cols="60"></textarea>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: [Object, String]
  },
  data () {
    return {
      showModal: this.show,
      currentData: ''
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    },
    data (val) {
      if (val) {
        if (typeof val === 'string') {
          this.currentData = val
        } else if (typeof val === 'object') {
          this.currentData = JSON.stringify(val, null, 2)
        }
      } else {
        this.currentData = ''
      }
    }
  }
}
</script>
<style lang="less">
.ds-preview {
  width: 100%;
  height: 100%;
  textarea {
    height: 100%;
  }
}
</style>
