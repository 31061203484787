<template>
  <div class="param-list">
    <div class="param-item-fmap head">
      <span class="param-val-7">parentKey</span>
      <span class="param-val-7">key</span>
      <span class="param-val-7">rowKey</span>
      <span class="param-val-7">fieldName</span>
      <span class="param-val-7">title</span>
      <span class="param-val-7">type</span>
      <span class="param-val-7">valueType</span>
    </div>
    <div class="param-item-fmap" v-for="(item, index) in currentValue" :key="index">
      <input class="param-val-7" disabled :value="item.parentKey" @change="onParentKeyChange(index, $event)">
      <input class="param-val-7" disabled :value="item.key" @change="onKeyChange(index, $event)">
      <input class="param-val-7" :value="item.rowKey" @change="onRowKeyChange(index, $event)">
      <input class="param-val-7" :value="item.fieldName" @change="onFieldNameChange(index, $event)">
      <input class="param-val-7" :value="item.title" @change="onTitleChange(index, $event)">
      <input class="param-val-7" :value="item.type" @change="onTypeChange(index, $event)">
      <select class="param-val-7" :value="item.valueType" @change="onValueTypeChange(index, $event)">
        <option>int</option>
        <option>float</option>
        <option>string</option>
      </select>
      <span class="ionic-icon ionic-icon-close-round" @click="remove(index)"></span>
      <span class="ionic-icon ionic-icon-edit" @click="edit(index)"></span>
    </div>
    <div class="add-mapping btn" @click.stop="onShowMapAddWin">+添加</div>
    <div class="add-mapping btn" @click="onTablePreview">Table预览</div>
  </div>
</template>

<script>
import Emitter from '../../mixins/emitter'
export default {
  mixins: [Emitter],
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      currentValue: this.value || []
    }
  },
  watch: {
    value (val, oldVal) {
      if (val && JSON.stringify(this.currentValue) !== JSON.stringify(val)) {
        this.currentValue = val
      }
    },
    currentValue (val) {
      let result = val
      this.$emit('input', result)
      this.dispatch('FormItem', 'on-form-change', result)
      this.$emit('on-change', result)
    }
  },
  methods: {
    onParentKeyChange (index, e) {
      let val = {
        parentKey: e.target.value,
        key: this.currentValue[index] ? this.currentValue[index].key : '',
        rowKey: this.currentValue[index] ? this.currentValue[index].rowKey : '',
        fieldName: this.currentValue[index] ? this.currentValue[index].fieldName : '',
        title: this.currentValue[index] ? this.currentValue[index].title : '',
        type: this.currentValue[index] ? this.currentValue[index].type : '',
        valueType: this.currentValue[index] ? this.currentValue[index].valueType : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onKeyChange (index, e) {
      let val = {
        parentKey: this.currentValue[index] ? this.currentValue[index].parentKey : '',
        key: e.target.value,
        rowKey: this.currentValue[index] ? this.currentValue[index].rowKey : '',
        fieldName: this.currentValue[index] ? this.currentValue[index].fieldName : '',
        title: this.currentValue[index] ? this.currentValue[index].title : '',
        type: this.currentValue[index] ? this.currentValue[index].type : '',
        valueType: this.currentValue[index] ? this.currentValue[index].valueType : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onRowKeyChange (index, e) {
      let val = {
        parentKey: this.currentValue[index] ? this.currentValue[index].parentKey : '',
        key: this.currentValue[index] ? this.currentValue[index].key : '',
        rowKey: e.target.value,
        fieldName: this.currentValue[index] ? this.currentValue[index].fieldName : '',
        title: this.currentValue[index] ? this.currentValue[index].title : '',
        type: this.currentValue[index] ? this.currentValue[index].type : '',
        valueType: this.currentValue[index] ? this.currentValue[index].valueType : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onTitleChange (index, e) {
      let val = {
        parentKey: this.currentValue[index] ? this.currentValue[index].parentKey : '',
        key: this.currentValue[index] ? this.currentValue[index].key : '',
        rowKey: this.currentValue[index] ? this.currentValue[index].rowKey : '',
        fieldName: this.currentValue[index] ? this.currentValue[index].fieldName : '',
        title: e.target.value,
        type: this.currentValue[index] ? this.currentValue[index].type : '',
        valueType: this.currentValue[index] ? this.currentValue[index].valueType : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onFieldNameChange (index, e) {
      let val = {
        parentKey: this.currentValue[index] ? this.currentValue[index].parentKey : '',
        key: this.currentValue[index] ? this.currentValue[index].key : '',
        rowKey: this.currentValue[index] ? this.currentValue[index].rowKey : '',
        fieldName: e.target.value,
        title: this.currentValue[index] ? this.currentValue[index].title : '',
        type: this.currentValue[index] ? this.currentValue[index].type : '',
        valueType: this.currentValue[index] ? this.currentValue[index].valueType : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onTypeChange (index, e) {
      let val = {
        parentKey: this.currentValue[index] ? this.currentValue[index].parentKey : '',
        key: this.currentValue[index] ? this.currentValue[index].key : '',
        rowKey: this.currentValue[index] ? this.currentValue[index].rowKey : '',
        fieldName: this.currentValue[index] ? this.currentValue[index].fieldName : '',
        title: this.currentValue[index] ? this.currentValue[index].title : '',
        type: e.target.value,
        valueType: this.currentValue[index] ? this.currentValue[index].valueType : ''
      }
      this.$set(this.currentValue, index, val)
    },
    onValueTypeChange (index, e) {
      let val = {
        parentKey: this.currentValue[index] ? this.currentValue[index].parentKey : '',
        key: this.currentValue[index] ? this.currentValue[index].key : '',
        rowKey: this.currentValue[index] ? this.currentValue[index].rowKey : '',
        fieldName: this.currentValue[index] ? this.currentValue[index].fieldName : '',
        title: this.currentValue[index] ? this.currentValue[index].title : '',
        type: this.currentValue[index] ? this.currentValue[index].type : '',
        valueType: e.target.value
      }
      this.$set(this.currentValue, index, val)
    },
    remove (index) {
      this.$emit('on-fieldmapping-delete', index)
    },
    edit (index) {
      this.$emit('on-fieldmapping-edit', this.currentValue[index])
    },
    onShowMapAddWin () {
      this.$emit('on-show-fieldmapping-addwin')
    },
    onTablePreview () {
      this.$emit('on-table-preview')
    }
  }
}
</script>

<style lang="less">
.param-item-fmap {
  height: 28px;
  line-height: 28px;
  margin-bottom: 4px;
  position: relative;
  .param-label {
    width: 10%
  }
  &.head {
    .param-val-7 {
      display: inline-block;
      text-align: center;
      height: 18px;
      line-height: 18px;
    }
  }
  .param-val-7 {
    width: 13%;
    height: 100%;
    outline: 0;
    border: 0;
    border-bottom: 1px solid #888;
  }
  .ionic-icon-close-round {
    position: absolute;
    top: 0;
    right: 15px;
    margin: 0 5px;
    cursor: pointer;
    &:hover {
      color: #3399ff;
    }
  }
  .ionic-icon-edit {
    position: absolute;
    top: 0;
    right: -5px;
    margin: 0 5px;
    cursor: pointer;
    &:hover {
      color: #3399ff;
    }
  }
}
.param-add {
  height: 28px;
  line-height: 28px;
  button {
    cursor: pointer;
    font-size: 12px;
    outline: 0;
    padding: 1px 7px;
    background: #fff;
    border: 1px solid #888;
    border-radius: 2px;
  }
}
.add-mapping {
  margin-top: 10px;
}
</style>
