const state = {
  dbName: '',
  dbSource: '',
  datasource: null,
  conn: false
}
const mutations = {
  UPDATE_DB (state, {dbName, dbSource, datasource}) {
    state.dbName = dbName
    state.dbSource = dbSource
    state.datasource = datasource
  },
  UPDATE_DB_CONN (state, status) {
    state.conn = status
  }
}

export default {
  state,
  mutations
}
