<template>
  <modal v-model="showModal" title="添加 subChild 换行子输入" width="450" :onBeforeOk="onBeforeConfirm" @on-ok="onConfirm">
    <y-form ref="subChildForm" :model="subChild" :rules="subChildRule" label-width="100px" item-width="250px">
      <form-item label="唯一编码" prop="key">
        <y-input v-model="subChild.key" disabled></y-input>
      </form-item>
      <form-item label="显示名称">
        <y-input v-model="subChild.title"></y-input>
      </form-item>
      <form-item label="保存值类型" prop="valueType">
        <y-select v-model="subChild.valueType" :data="valueTypeList"></y-select>
      </form-item>
      <form-item label="单位">
        <y-input v-model="subChild.unit"></y-input>
      </form-item>
    </y-form>
  </modal>
</template>

<script>
import Modal from '../../common/modal/modal.vue'
import YForm from '../../common/form/form'
import FormItem from '../../common/form/form-item'
import YSelect from '../../common/select/select'
import YInput from '../../common/input/input'

export default {
  components: { Modal, YForm, FormItem, YSelect, YInput },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: this.show,
      subChild: {
        key: '',
        title: '',
        valueType: '',
        unit: '',
        reportTitle: ''
      },
      valueTypeList: [
        {name: '字符串', value: 'string'},
        {name: '日期', value: 'date'}, // 后台存储 Date, 返回前台string
        {name: '数值', value: 'number'},
        {name: '整数', value: 'integer'}
      ],
      subChildRule: {
        key: [{required: true, message: '不能为空', trigger: 'change'}],
        valueType: [{required: true, message: '不能为空', trigger: 'change'}]
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.showModal = val
        this.setKey()
      }
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide', val)
      }
    }
  },
  methods: {
    onBeforeConfirm () {
      this.$refs.subChildForm.validate()
      if (!this.$refs.subChildForm.isValid) return false
      return true
    },
    onConfirm () {
      this.$emit('on-confirm-ok', this.subChild)
    },
    setKey () {
      const currentNode = this.$parent.$currentTreeNode
      if (!currentNode) return
      this.subChild.key = currentNode.getNewChildKey()
    }
  }
}
</script>

<style lang="css">
</style>
