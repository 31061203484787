<template>
  <div class="time-picker-cells" :class="{'time-picker-cells-with-seconds': showSeconds}">
    <div class="time-picker-cells-list" ref="hours">
      <ul class="time-picker-cells-ul" @click="handleClickHours">
        <li v-for="(item, index) in hoursList" class="time-picker-cells-cell"
          :key="index"
          :class="getCellCls(item)"
          v-show="!item.hide" :index="index">
          {{ formatTime(item.text) }}
        </li>
      </ul>
    </div>
    <div class="time-picker-cells-list" ref="minutes">
      <ul class="time-picker-cells-ul" @click="handleClickMinutes">
        <li v-for="(item, index) in minutesList" class="time-picker-cells-cell"
          :key="index"
          :class="getCellCls(item)"
          v-show="!item.hide" :index="index">
          {{ formatTime(item.text) }}
        </li>
      </ul>
    </div>
    <div class="time-picker-cells-list" v-show="showSeconds" ref="seconds">
      <ul class="time-picker-cells-ul" @click="handleClickSeconds">
        <li v-for="(item, index) in secondsList" class="time-picker-cells-cell"
          :key="index"
          :class="getCellCls(item)"
          v-show="!item.hide" :index="index">
          {{ formatTime(item.text) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Options from '../time-mixins'
import { deepCopy, scrollTop, firstUpperCase } from '../../../../utils/assist'

export default {
  mixins: [Options],
  props: {
    hours: {
      type: [Number, String],
      default: 0
    },
    minutes: {
      type: [Number, String],
      default: 0
    },
    seconds: {
      type: [Number, String],
      default: 0
    },
    showSeconds: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      compiled: false
    }
  },
  computed: {
    hoursList () {
      let hours = []
      const hourTmpl = {
        text: 0,
        selected: false,
        disabled: false,
        hide: false
      }

      for (let i = 0; i < 24; i++) {
        const hour = deepCopy(hourTmpl)
        hour.text = i

        if (this.disabledHours.length && this.disabledHours.indexOf(i) > -1) {
          hour.disabled = true
          if (this.hideDisabledOptions) hour.hide = true
        }
        if (this.hours === i) hour.selected = true
        hours.push(hour)
      }

      return hours
    },
    minutesList () {
      let minutes = []
      const minuteTmpl = {
        text: 0,
        selected: false,
        disabled: false,
        hide: false
      }

      for (let i = 0; i < 60; i++) {
        const minute = deepCopy(minuteTmpl)
        minute.text = i

        if (this.disabledMinutes.length && this.disabledMinutes.indexOf(i) > -1) {
          minute.disabled = true
          if (this.hideDisabledOptions) minute.hide = true
        }
        if (this.minutes === i) minute.selected = true
        minutes.push(minute)
      }

      return minutes
    },
    secondsList () {
      let seconds = []
      const secondTmpl = {
        text: 0,
        selected: false,
        disabled: false,
        hide: false
      }

      for (let i = 0; i < 60; i++) {
        const second = deepCopy(secondTmpl)
        second.text = i

        if (this.disabledSeconds.length && this.disabledSeconds.indexOf(i) > -1) {
          second.disabled = true
          if (this.hideDisabledOptions) second.hide = true
        }
        if (this.seconds === i) second.selected = true
        seconds.push(second)
      }

      return seconds
    }
  },
  watch: {
    hours (val) {
      if (!this.compiled) return
      this.scroll('hours', val)
    },
    minutes (val) {
      if (!this.compiled) return
      this.scroll('minutes', val)
    },
    seconds (val) {
      if (!this.compiled) return
      this.scroll('seconds', val)
    }
  },
  mounted () {
    this.updateScroll()
    this.$nextTick(() => {
      this.compiled = true
    })
  },
  methods: {
    getCellCls (cell) {
      return {
        'time-picker-cells-cell-selected': cell.selected,
        'time-picker-cells-cell-disabled': cell.disabled
      }
    },
    handleClickHours (event) {
      this.handleClick('hours', event)
    },
    handleClickMinutes (event) {
      this.handleClick('minutes', event)
    },
    handleClickSeconds (event) {
      this.handleClick('seconds', event)
    },
    handleClick (type, event) {
      const target = event.target
      if (target.tagName === 'LI') {
        const cell = this[`${type}List`][parseInt(event.target.getAttribute('index'))]
        if (cell.disabled) return
        const data = {}
        data[type] = cell.text
        this.$emit('on-change', data)
      }
      this.$emit('on-pick-click')
    },
    scroll (type, index) {
      const from = this.$refs[type].scrollTop
      const to = 24 * this.getScrollIndex(type, index)
      scrollTop(this.$refs[type], from, to, 500)
    },
    getScrollIndex (type, index) {
      const Type = firstUpperCase(type)
      const disabled = this[`disabled${Type}`]
      if (disabled.length && this.hideDisabledOptions) {
        let _count = 0
        disabled.forEach(item => item <= index ? _count++ : '')
        index -= _count
      }
      return index
    },
    updateScroll () {
      const times = ['hours', 'minutes', 'seconds']
      this.$nextTick(() => {
        times.forEach(type => {
          this.$refs[type].scrollTop = 24 * this.getScrollIndex(type, this[type])
        })
      })
    },
    formatTime (text) {
      return text < 10 ? '0' + text : text
    }
  }
}
</script>
