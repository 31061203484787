export default {
  methods: {
    iconBtnCls (direction, type = '') {
      return [
        'picker-panel-icon-btn',
        `date-picker-${direction}-btn`,
        `date-picker-${direction}-btn-arrow${type}`
      ]
    },
    handleShortcutClick (shortcut) {
      if (shortcut.value) this.$emit('on-pick', shortcut.value())
      if (shortcut.onClick) shortcut.onClick(this)
    },
    handlePickClear () {
      this.$emit('on-pick-clear')
    },
    handlePickSuccess () {
      this.$emit('on-pick-success')
    },
    handlePickClick () {
      this.$emit('on-pick-click')
    }
  }
}
