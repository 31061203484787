<template>
  <table cellspacing="0" cellpadding="0" border="0" :style="styles">
    <colgroup>
      <col v-for="(column, index) in columns" :key="column.key" :width="setCellWidth(column, index, true)"/>
    </colgroup>
    <thead>
      <tr>
        <th v-for="column in columns" :key="column.key" :class="alignCls(column)">
          <div class="table-cell" :class="{'table-hidden': !fixed && column.fixed && (column.fixed === 'left' || column.fixed === 'right')}">
            <template v-if="column.type === 'selection'">
              <checkbox v-model="isSelectAll" @change="onSelectAllChange"></checkbox>
            </template>
            <template v-else>
              <span v-html="renderHeader(column)"></span>
              <span class="table-sort" v-if="column.sortable">
                <i class="ionic-icon ionic-icon-arrow-up-b" :class="{on: column._sortType === 'asc'}" @click="handleSort(column, 'asc')"></i>
                <i class="ionic-icon ionic-icon-arrow-down-b" :class="{on: column._sortType === 'desc'}" @click="handleSort(column, 'desc')"></i>
              </span>
            </template>
          </div>
        </th>
      </tr>
    </thead>
  </table>
</template>

<script>
import styleMethods from './mixin'
import Checkbox from './checkbox'

export default {
  name: 'TableHeader',
  mixins: [styleMethods],
  props: {
    styleObject: Object,
    columns: Array,
    columnsWidth: {},
    noborder: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: [Boolean, String],
      default: false
    }
  },
  data () {
    return {
      isSelectAll: false,
      store: {}
    }
  },
  computed: {
    // set width of the table-header
    styles () {
      const style = Object.assign({}, this.styleObject)
      const width = this.$parent.bodyHeight === 0
        ? parseInt(this.styleObject.width)
        : this.$parent.bodyHeight < this.$parent.bodyRealHeight && !this.fixed
          ? parseInt(this.styleObject.width) + parseInt(this.$parent.scrollBarWidth)
          : parseInt(this.styleObject.width)
      style.width = `${width}px`
      return style
    }
  },
  created () {
    this.store = this.$parent.tableStore
  },
  methods: {
    renderHeader (column) {
      if ('renderHeader' in column) {
        return column.renderHeader(column, column._index)
      } else {
        return column.title || '#'
      }
    },
    onSelectAllChange () {
      this.$parent.selectAll(this.isSelectAll)
    },
    updateSelectAll (isSelectAll) {
      this.isSelectAll = isSelectAll
    },
    handleSort (column, type) {
      if (column._sortType === 'normal' && column.sortable !== 'server') {
        // 记录原来的排序正常情况下的sort
        this.store.memoryBeforeSortData()
      }
      if (column._sortType === type) {
        // 再次点击恢复原来的排序
        type = 'normal'
      }
      this.$parent.handleSort(column, type)
    },
    handleFilter (column) {
    },
    handleSelect (column, value) {
    },
    handleReset (column) {
    },
    handleFilterHide (column) {
    }
  },
  components: {
    Checkbox
  }
}
</script>

<style lang="css">
</style>
